import React, { PropTypes, Component } from 'react';
import ReactDOM from 'react-dom';

import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import Popup from 'reactjs-popup'
import { withStyles, makeStyles} from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import {
    ChevronsDown, ChevronsUp
  } from 'react-feather';

const StyledTableCell = withStyles(theme => ({
    head: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);





class HeaderComponent extends Component {


    static defaultProps = {
        headerColor: 'black',
        bodyColor: 'black',
    };

    constructor(props) {
        super(props);

        this.state = {
            value: this.props.defaultValue,
            open: false,
            popRef: null,
            sortAscending: false,
            sortDescending: false
        }

        this.topRef = null
    }

    componentDidMount() {
        //alert(this.props.defaultValue)
    }

    openPopover(target) {
        //console.log('target opening ')
        //console.log(target)
        this.setState({
        popRef: target, 
        open: true
})
    }

    adjustSortDescending() {
        if (this.state.sortDescending) {
            this.setState({
                sortDescending: false
            })
        } else {
            this.setState({
                sortDescending: true,
                sortAscending: false
            })
            this.props.sortSet(this.props.itemkey, false)
        }
    }

    adjustSortAscending() {
        if (this.state.sortAscending) {
            this.setState({
                sortAscending: false
            })
        } else {
            this.setState({
                sortAscending: true,
                sortDescending: false
            })
            this.props.sortSet(this.props.itemkey, true)
        }
    }

    closePopover() {
        //console.log('closing pop')
        //console.log('event')
        this.setState({ open: false, popRef: null })
    }

    attemptClose(event) {

            this.closePopover()

    }

    updateSearch(val) {
        if (val == '') {
            this.props.deactivate(this.props.itemkey)
        } else {
            this.props.activate({ key: this.props.itemkey, condition: 't', target: val, name: this.props.text })
        }

    }

    render() {


        return (
            <TableCell 
            > 
                <div style={{ flexDirection: 'row', display: 'flex',  margin: 10 }}>
                <Typography onClick={(event) => this.openPopover(event.currentTarget)}
                aria-haspopup="true" aria-owns={this.state.open ? 'mouse-over-popover' : undefined}
                ref={(v) => this.topRef = v} style={{fontWeight: 'bold', fontSize: 14}}>{this.props.text}
                </Typography>
                {
                    this.state.sortAscending ? <ChevronsDown onClick={() => this.adjustSortDescending()} color={this.state.sortAscending ? 'black': 'green'}/> : 
                    <ChevronsUp onClick={() => this.adjustSortAscending()} color={this.state.sortAscending ? 'green': 'black'}/>
                    }
                
                        
                            
 
                        </div>

                <Popover
                    id="mouse-over-popover"
                    open={this.state.open}
                    onMouseLeave={(event) => this.attemptClose()} 
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    anchorEl={this.state.popRef}
             
                    onClose={() => this.setState({ open: false })}
                    disableRestoreFocus
                >
                    <div style={{ display: 'flex', flexDirection: 'column', padding: 10 }}>
                        <p style={{ color: '#42424', fontWeight: 'bold', margin: 5 }}>{this.props.text}</p>
                        <TextField
                            style={{ backgroundColor: 'rgb(247,247,247)', borderRadius: 5, width: 200 }}
                            id={this.props.name}
                            variant={'outlined'}
                            inputProps={{
                                style: {
                                    height: 3.5,

                                },
                            }}
                            value={this.state.value}
                            onChange={(event) => this.updateSearch(event.target.value)}
                        />

                        <div style={{ flexDirection: 'row', display: 'flex', justifyContent: 'space-between', margin: 10 }}>
                        <ChevronsUp onClick={() => this.adjustSortAscending()} color={this.state.sortAscending ? 'green': 'black'}/>
                            <ChevronsDown onClick={() => this.adjustSortDescending()} color={this.state.sortAscending ? 'black': 'green'}/>
 
                        </div>
                    </div>
                </Popover>

            </TableCell>


        );
    }
}
const headerStyle = {
    backgroundColor: 'rgb(67,67,67)', flexWrap: 'never', whiteSpace: 'noWrap'
}

/*   <div onClick={() => this.adjustSortDescending()} style={{ margin: 0, display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: this.state.sortDescending ? 'orange' : 'white', border: this.state.sortDescending ? '' : '1px solid black', borderRadius: 30, padding: 0, height: 30, padding: 5 }}>
                                <p>Descending</p>
                            </div>*/

/*
 * 
 * 
 *             */

export default HeaderComponent;