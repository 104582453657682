import React, {useState, useReducer} from 'react';
import { Link as RouterLink, useNavigate, useLocation} from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';

import LocationOnIcon from '@material-ui/icons/LocationOn';
import {
  Box,
  Button,
  Checkbox,
  Container,
  FormHelperText,
  Link,
  TextField,
  Typography,
  makeStyles,
  Grid,
  Select,
  MenuItem,
  Stepper,
  StepLabel,
  Step,
  CircularProgress
} from '@material-ui/core';
import Page from 'src/components/Page';
import parse from 'autosuggest-highlight/parse';
import throttle from 'lodash/throttle';
import Firebase from 'src/Firebase'
import MuiPhoneNumber from 'material-ui-phone-number'
import { parseWithOptions } from 'date-fns/fp';
import MyContext from 'src/MyContext';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { DataGrid, GridToolbar, GridToolbarContainer, GridToolbarColumnsButton, GridToolbarFilterButton, GridToolbarDensitySelector, GridToolbarExport } from '@mui/x-data-grid';
import { arrayOf } from 'prop-types';
import AddFacilityAtTrainDialog from './AddFacilityAtTrainDialog';

function CustomToolbar(props) {

  if (props.allowExport) {
    return <GridToolbar/>
  } else {
    return (
      <GridToolbarContainer >
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector/>
      </GridToolbarContainer>
    );
  }

}

const filter = createFilterOptions();
const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/


const autocompleteService = { current: null };
const geocoder = {current: null};
const steps = ['Basic Information', 'Associated Vessel Operations', 'Associated Yearly Plans', 'Allocation Details'];



const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  }
}));


const EditTrainView = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const {state} = useLocation();
  const [loading, setLoading] = React.useState(false);
  const [addFacilityAtTrainDialog, setAddFacilityAtTrainDialog] = useState(false)
  const [autoValues, setAutoValues] = React.useState(state.customer ? state.customer : {});
  const [selection, setSelection] = useState([]);
  const [opSelection, setOpSelection] = useState(state.customer && state.customer.operations ? state.customer.operations :[]);
  const [planSelection, setPlanSelection] = useState(state.customer && state.customer.yearlyPlans ? state.customer.yearlyPlans : []);
  const [allocations, setAllocations] = useState(state.customer && state.customer.allocations ? state.customer.allocations :{});
  const [transporterAllocations, setTransporterAllocations] = useState(state.customer && state.customer.transporterAllocations ? state.customer.transporterAllocations : {})
  const [estimatedValues, setEstimatedValues] = useState({});
  const [oldCustom, setOldCustomer] = useState(JSON.parse(JSON.stringify(state.customer)))
  const [activeStep, setActiveStep] = useState(0);
  const [ignored, forceUpdate] = useReducer(x => x + 1, 0);

  const handleNext = () => {


    setActiveStep((prevActiveStep) => prevActiveStep + 1);

  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  function getType(t) {
    if (t == 'date') {
      return 'date'
    } else if (t == 'number') {
      return 'number' 
    } else if (t == 'list' || t == 'custom') {
      return t
    } else {
      return null
    }
  }

  function updateAutoValues(k, v) {
    var o = autoValues
    o[k] = v 
    setAutoValues(o)
  }

  function getAutoOptions(context, k) {
    if (k in context.barleyInCustomObjects) {
      return Object.keys(context.barleyInCustomObjects[k]).map((key, id) => {
        return context.barleyInCustomObjects[k][key]
      })
    }
    return []
  }

  function renderContent(fields, touched, errors, handleBlur, handleChange, values, setFieldValue, context) {
    var columns = Math.floor(fields.length / 3)
    if (columns * 3 < fields.length) {
      columns += 1
    }
    var colrow = [...Array(columns).keys()]
    var rows = [0,1,2]
    return <div>
      {colrow.map((item, idx) => {
        return <div style={{display: 'flex', flexDirection: 'row'}}>
        {rows.map((inner, innerI) => {
          let fieldIdx = (3*item) + inner 

          if (fieldIdx < fields.length) {
            let tp = getType(fields[fieldIdx].fieldType)
            if (tp == 'custom') {

             return <Autocomplete
              value={autoValues[fields[fieldIdx].fieldKey]}
              fullWidth
              onChange={(event, newValue) => {
                if (typeof newValue === 'string') {
                  updateAutoValues(fields[fieldIdx].fieldKey, {name: newValue})
                  setFieldValue(fields[fieldIdx].fieldKey, {'customId': newValue.customId, 'name': newValue.name})

                } else if (newValue && newValue.inputValue) {
                  // Create a new value from the user input
                  updateAutoValues(fields[fieldIdx].fieldKey, {name: newValue.inputValue})
                  setFieldValue(fields[fieldIdx].fieldKey, {'customId':-1, 'name': newValue.inputValue})
                } else {
                  updateAutoValues(fields[fieldIdx].fieldKey, newValue)
                  if (newValue == null) {
                    setFieldValue(fields[fieldIdx].fieldKey, {'customId': '0', 'name': 'None'})
                  } else {
                    setFieldValue(fields[fieldIdx].fieldKey, {'customId': newValue.customId, 'name': newValue.name})
                  }
                }
                //console.log(event.target.value)
                //console.log(newValue)
              }}
              filterOptions={(options, params) => {
                const filtered = filter(options, params);
        
                // Suggest the creation of a new value
                if (params.inputValue !== '') {
                  filtered.push({
                    inputValue: params.inputValue,
                    name: `Add "${params.inputValue}"`,
                  });
                }
        
                return filtered;
              }}
              selectOnFocus
              clearOnBlur
              handleHomeEndKeys
              id="free-solo-with-text-demo"
              options={fields[fieldIdx].fieldKey == 'warehouse' ? getAutoOptions(context, fields[fieldIdx].fieldKey).filter((item, idx) => {
                return item && item.warehousetype == 'Internal'
              }) : getAutoOptions(context, fields[fieldIdx].fieldKey)}
              getOptionLabel={(option) => {
                // Value selected with enter, right from the input
                if (typeof option === 'string') {
                  return option;
                }
                // Add "xxx" option created dynamically
                if (option.inputValue) {
                  return option.inputValue;
                }
                // Regular option
                return option.name;
              }}
              renderOption={(option) => option.name}
              freeSolo
              renderInput={(params) => (
                <TextField {...params}  error={Boolean(touched[fields[fieldIdx].fieldKey] && errors[fields[fieldIdx].fieldKey])}
                fullWidth
                style={{margin: 10}}
                id={fields[fieldIdx].fieldKey}
                helperText={touched[fields[fieldIdx].fieldKey] && errors[fields[fieldIdx].fieldKey]}
                label={fields[fieldIdx].fieldName}
                margin="normal"
                name={fields[fieldIdx].fieldKey}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values[fields[fieldIdx].fieldKey]}
                InputLabelProps={{
                  shrink: true,
                  }}
                variant="outlined"/>
              )}
            />



            } else if (tp == 'list') {
              let opts = fields[fieldIdx].listOptions
              return <TextField
              select 
              style={{margin: 10}}
              error={Boolean(touched[fields[fieldIdx].fieldKey] && errors[fields[fieldIdx].fieldKey])}
              fullWidth
              id={fields[fieldIdx].fieldKey}
              helperText={touched[fields[fieldIdx].fieldKey] && errors[fields[fieldIdx].fieldKey]}
              label={fields[fieldIdx].fieldName}
              margin="normal"
              name={fields[fieldIdx].fieldKey}
              onBlur={handleBlur}
              onChange={handleChange}
              value={values[fields[fieldIdx].fieldKey]}
              InputLabelProps={{
                shrink: true,
                }}
              variant="outlined">
              {opts.map((item, idx) => {
                return (<MenuItem value={item}>{item}</MenuItem>)
              })}
            </TextField>
            } else {
              return <TextField
              error={Boolean(touched[fields[fieldIdx].fieldKey] && errors[fields[fieldIdx].fieldKey])}
              fullWidth
              style={{margin: 10}}
              id={fields[fieldIdx].fieldKey}
              helperText={touched[fields[fieldIdx].fieldKey] && errors[fields[fieldIdx].fieldKey]}
              label={fields[fieldIdx].fieldName}
              margin="normal"
              name={fields[fieldIdx].fieldKey}
              onBlur={handleBlur}
              onChange={handleChange}
              value={values[fields[fieldIdx].fieldKey]}
              variant="outlined"
              type={getType(fields[fieldIdx].fieldType)}
              InputLabelProps={{
                shrink: true,
                }}
            />
            }

          }
          
          return null 

        })}
       </div>
      })}
    </div>
  }


  async function doEditPlanLocal(v) {
    var newVal = v 
    newVal['editDate'] = Date.now()
    console.log(newVal)
    console.log(newVal.supplierid)
    
    let docRefNew = Firebase.firestore.collection('projects').doc(Firebase.userProject).collection('trains').doc(newVal.trainId)
    let docRefOld = Firebase.firestore.collection('projects').doc(Firebase.userProject).collection('trainsArchive').doc(newVal.trainId)
    let newValId = docRefNew.id
    // check if we have to create any new field objects
    var customCreations = {}
    state.fields.map((field, idx) => {
      if (field.fieldType === 'custom') {
        if (field.fieldKey in newVal && newVal[field.fieldKey]['customId'] === -1) {
          // must create this val 
          customCreations[field.fieldKey] =  newVal[field.fieldKey]['name']
        }
      }
    })
    let arrayOfMemberIds = Object.keys(customCreations)
    console.log(arrayOfMemberIds)
    console.log(newVal)
    var oldNumericSave = null
    var newQueryId = null 
    var newQueryResult = null
    var errMessage = null 
    var transaction = null 
    transaction = await Firebase.firestore.runTransaction(t => {

      return t.get(docRefOld)
      .then(doc => {

        if (doc.exists) {
          var ox = oldCustom 
          ox['editReason'] = newVal['editReason'] ? newVal['editReason'] : ''
          var setv = {}
          setv[Date.now()] = ox
          t.update(docRefOld, setv)
        } else {
          var ox = oldCustom 
          ox['editReason'] = newVal['editReason'] ? newVal['editReason'] : ''
          var setv = {}
          setv[Date.now()] = ox
          t.set(docRefOld, setv)
        }

        arrayOfMemberIds.forEach(function(memberId) {
          let docRefNext = Firebase.firestore.collection('projects').doc(Firebase.userProject).collection('barleyInCustomObjects').doc('contents').collection(memberId).doc()
          console.log('here t first')
          console.log(t)
          t = t.set(
            docRefNext,
            { name: customCreations[memberId] }
          )
          console.log('setting id of ' + docRefNext.id)
          newVal[memberId] = {'customId': docRefNext.id, 'name': customCreations[memberId]}
            
        });
  
        return t.update(docRefNew ,newVal);
      })
      }).then(result => {
        
        console.log('Transaction success!' + result);
        return {'transaction': true, 'newVal': newVal, 'oldNumericSave': oldNumericSave, 'newValId': newValId}
      }).catch(err => {
        console.log('Transaction failure:', err);
        return {'transaction': null, 'failMessage': err}
      });

      return {'transaction': transaction, 'failureMessage': errMessage}
    
  }

    async function doSubmit(vv) {
      var v = vv 

      v['allocations'] = allocations
      v['transporterAllocations'] = transporterAllocations
      if (!v.name) {
        alert('Must specify a name.')
        return
      }
      if (opSelection.length < 1) {
        alert('Must specify at least one associated operation.')
        return 
      }
      v['operations'] = opSelection
      v['yearlyPlans'] = planSelection
     
      setLoading(true)
      //let transaction = await Firebase.createSku({'project': Firebase.userProject, 'values': v, 'fields': state.fields})
      let transaction = await doEditPlanLocal(v)

      setLoading(false)
      if (transaction && transaction.transaction == null && transaction.failureMessage) {
        alert(transaction.failureMessage)
      } else {
        navigate('/app/creationsummary', {state: {headerText: 'Updated Train Plan: ' + v.name, secondaryText: 'Successfully updated train plan.' }, replace: true})
        //navigate(-1)
      }
      console.log(transaction)
      Firebase.trackMixpanel('editTrainPlanOnline', {'result': transaction})

    }

    function getOpsColumns() {
      return [
        {
          field: 'operation',
          headerName: 'Operation',
          width: 300,
          editable: false,
        },
        {
          field: 'estimatedqty',
          headerName: 'Estimated Quantity',
          type: 'number',
          width: 150,
          editable: true,
        },
      ]
    }

    function getPlanColumns() {
      return [
        {
          field: 'plan',
          headerName: 'Plan',
          width: 250,
          editable: false,
        },
        {
          field: 'sku',
          headerName: 'SKU',
          width: 150,
          editable: true,
        },
      ]
    }

    function getPlanRows(context) {
      let wh = context.planList
      return wh.map((item, idx) => {
        return {'id': item.planId, 'plan': item.name, 'sku': item.sku ? item.sku.name : '' }
      })
    }

    function getOpField(op) {
      var s = ''
      if (op.bolnumber) {
        s += op.bolnumber
      }
      if (op.operationnumber) {
        s += ' ' + op.operationnumber
      }
      if (op.name) {
        s += ' ' + op.name
      }
      return s
    }

    function getOpsRows(context) {
      let wh = context.operationList
      return wh.map((item, idx) => {
        return {'id': item.operationId, 'operation': getOpField(item), 'estimatedQty': item.customId in estimatedValues ? estimatedValues[item.customId] : 0 }
      })
    }

    function getColumns() {
      return [
        {
          field: 'facility',
          headerName: 'Destination',
          width: 150,
          editable: false,
        },

        {
          field: 'allocation',
          headerName: 'Allocation (Metric Tons)',
          type: 'number',
          width: 220,
          editable: true,
        },

        {
          field: 'transporter',
          headerName: 'Default Transporter',
          width: 220,
          editable: true
        },
        {
          field: 'union',
        headerName: 'Union',
        width: 150,
        editable: false
      }
      ]
    }

    function getRows(context, planSelection, allocationValues, transporterAllocationsV) {

      var whOptionDict = {}
      planSelection.map((plan, idx) => {
        if (plan in context.innerPlansDict) {
          let innerDict = context.innerPlansDict[plan]
          Object.keys(innerDict).map((key, idx) => {
            let innerPlan = innerDict[key]
            console.log('check inner plan')
            console.log(innerPlan)
            if (innerPlan && innerPlan.warehouse && innerPlan.warehouse.customId) {
                whOptionDict[innerPlan.warehouse.customId] = 1
            }

          })
        }
      })
 
      let wh = getAutoOptions(context, 'warehouse').filter((item ,idx) => {
        return item && item.customId && item.customId in whOptionDict
      })
      /*console.log(row)
      if (row == null || row.sku == null || row.sku.name == null) {
        return [] 
      }
      let query = {'sku': row.sku.name}
      let dest = ''
      console.log(row)
      
      if (row.warehouse && row.warehouse.name) {
        dest = row.warehouse.name 
      } else {
        return []
      }
      console.log(query)
      let cdt = this.getAllByCategory(context.barleyStocksDict, context.barleyStocksPendingDict,
        context.barleyStocksTransitDict, this.getDictId(query), dest)
      
      return Object.keys(cdt).map((key, idx) => {
        let item = cdt[key]
        return {'stock': item, 'facility': key, 'allocation': key in allocationValues ? allocationValues[key] : 0, id: key}
      })*/
      return wh.map((item, idx) => {
        return {'id': item.customId, 'facility': item.name, 'union': item.union, 'stock': 0, 'allocation': item.customId in allocationValues ? allocationValues[item.customId] : 0,
       'transporter': item.customId in transporterAllocationsV ? transporterAllocationsV[item.customId] : ''}
      })
  
    }

    function onCellChangeOp(value/*, approvalInfo*/) {

      var al = estimatedValues
      al[value.id] = value.value 
      setEstimatedValues(al)
      forceUpdate()
    
    }

    function onCellChange(value/*, approvalInfo*/) {
      console.log(value)

      if (value.field == 'allocation') {
        var al = allocations
        al[value.id] = value.value 
        setAllocations(al)
        forceUpdate()
      } else if (value.field == 'transporter') {
        var al = transporterAllocations
        al[value.id] = value.value 
        setTransporterAllocations(al)
        forceUpdate()
      }
      /*console.log(value)
      console.log(this.state.categoryDict[value.id])
  
      var allocationSum = 0
      Object.keys(this.state.allocationValues).map((key, idx) => {
        allocationSum += this.state.allocationValues[key]
      })
  
  
      let oldVal = (value.id in this.state.allocationValues) ? this.state.allocationValues[value.id] : 0
  
      allocationSum += (value.value - oldVal)
  
      console.log('allocationsum is ' + allocationSum)
  
      if (!(value.id in this.state.categoryDict) || this.state.categoryDict[value.id] < value.value) {
        var s = this.state.allocationValues
        s[value.id] = 0
        this.setState({
          allocationValues: s
        })
        console.log('reset')
      } else if (allocationSum > approvalInfo.row.netweightkg) {
        var s = this.state.allocationValues
        s[value.id] = 0
        this.setState({
          allocationValues: s
        })
        console.log('reset ' + approvalInfo.row.netweightkg)
      } else {
        var s = this.state.allocationValues
        s[value.id] = value.value
        this.setState({
          allocationValues: s
        })
      }*/

    
    }
  


  return (
    <MyContext.Consumer>{context => (
  <Page
    className={classes.root}
    height='100%'
    title="Edit Train Plan"
    id="ff2"
    backgroundColor={classes.root.backgroundColor}
  >
    <Box
      display="flex"
      flexDirection="column"
      id="bb2"

      backgroundColor={classes.root.backgroundColor}

    >
      <Container id="cc2" backgroundColor={classes.root.backgroundColor}>
        <Formik
        id='f2'
          initialValues={state && state.customer ? state.customer : {}}
          validationSchema={
            Yup.object().shape({

            })
          }
          onSubmit={(values) => {
            doSubmit(values)
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting2,
            setSubmitting, 
            touched,
            values,
            setFieldValue
          }) => (
            <form id='fu2' onSubmit={handleSubmit}>
                  <div id='fkx1' style={{marginTop: 2}}>
                  <AddFacilityAtTrainDialog
            context={context}
        open={addFacilityAtTrainDialog}
        onClose={() => setAddFacilityAtTrainDialog(false)}
        addRow={(v) => alert(JSON.stringify(v))}
        alertTitle={'Add Facility'}
        alertCancelText={'Cancel'}
        alertConfirmText={'Confirm'}
        planSelection={planSelection}
        onConfirm={(x) => setAddFacilityAtTrainDialog(false)}
        />
              <Box mb={3}>
                <Typography
                  color="textPrimary"
                  variant="h2"
                >
                  Edit Train Plan
                </Typography>

              </Box>
              <Stepper activeStep={activeStep} style={{width: '100%', marginBottom: 15}}>
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>

            </Step>
          );
        })}
      </Stepper>


      <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'row', pt: 2 }}>



      {activeStep == 2 ?
  <div style={{height: 300, width: 600}}>
  <Typography
      color="textPrimary"
      variant="h5"
    >
      Associated Yearly Plan(s)
    </Typography>
    <DataGrid
key="yplanalloc"
style={{backgroundColor: 'white'}}
rows={getPlanRows(context)}
columns={getPlanColumns()}
pageSize={5}
rowsPerPageOptions={[5]}
hideFooterPagination={false}
hideFooter={false}
checkboxSelection={true}
disableSelectionOnClick
selectionModel={planSelection}
onSelectionModelChange={(newSelection) => {
setPlanSelection(newSelection);
}}
/>
</div>
: null}

{activeStep == 3 ?
  <div style={{height: 550, width: 800}}>
  <Typography
      color="textPrimary"
      variant="h5"
    >
      Destination Plans
    </Typography>
    <Button
  color="primary"
  size="large"
  id="sendButtonNext"
  variant="contained"
  onClick={() => setAddFacilityAtTrainDialog(true)}
  style={{margin: 5, width: 150, marginTop: 5}}
>
  Add Facility
</Button>
<DataGrid
key="plantab"
style={{backgroundColor: 'white'}}
rows={getRows(context, planSelection, allocations, transporterAllocations)}
columns={getColumns()}
density={'compact'}
pageSize={20}
rowsPerPageOptions={[20]}
onCellEditCommit={(p, e, d) => onCellChange(p, /*this.state.approvalInfo*/)}
hideFooterPagination={false}
hideFooter={false}
checkboxSelection={true}
disableSelectionOnClick
selectionModel={selection}
components={{
  Toolbar: CustomToolbar,
}}
componentsProps={{ toolbar: { allowExport: false } }}
onSelectionModelChange={(newSelection) => {
setSelection(newSelection);
}}
/>
</div>
: null}

{activeStep == 1 ?
  <div style={{height: 300, width: 600}}>
  <Typography
      color="textPrimary"
      variant="h5"
    >
      Associated Operation(s)
    </Typography>
<DataGrid
key="allocatb"
style={{backgroundColor: 'white'}}
rows={getOpsRows(context)}
columns={getOpsColumns()}
pageSize={5}
rowsPerPageOptions={[5]}
onCellEditCommit={(p, e, d) => onCellChangeOp(p, /*this.state.approvalInfo*/)}
hideFooterPagination={false}
hideFooter={false}
checkboxSelection={true}
disableSelectionOnClick
selectionModel={opSelection}
onSelectionModelChange={(newSelection) => {
setOpSelection(newSelection);
}}
/>
</div>

: null}

{activeStep == 0 ?
<div style={{width: '50%'}}>   {renderContent(state.fields.filter((item, idx) => {
return item.fieldKey != 'grn'  && item.fieldKey != 'creationDate' && item.fieldKey != 'displaybol' && item.fieldKey != 'displaydeclaration' && item.fieldKey != 'operations' && item.fieldKey != 'viewplan'
}), touched, errors, handleBlur, handleChange, values, setFieldValue, context)} 

<TextField
              id={'editReason'}
              key={'editReason'}
              
              label={'Reason for Edit'}
              margin="normal"
              name={'editReason'}
              onChange={handleChange}
              value={values.editReason}
              variant="outlined"
              type={'text'}
              InputLabelProps={{
                shrink: true,
                }}
            />
</div>
: null}





</Box>


</div>

<Button
  color="primary"
  disabled={activeStep == 0}
  size="large"
  id="sendButtonNext"
  variant="contained"
  onClick={handleBack}
  style={{margin: 5, width: 150, marginTop: 20}}
>
  Back
</Button>

{activeStep < steps.length - 1 ? 
  <Button
  color="primary"
  disabled={loading}
  size="large"
  id="sendButtonNext"
  variant="contained"
  onClick={handleNext}
  style={{margin: 5, width: 150, marginTop: 20}}
>
  Next
</Button>
: 
<Box my={2} style={{alignSelf: 'center', flexDirection: 'column', display: 'flex'}}>

<Button
color="primary"
style={{margin: 5, width: 150}}
disabled={loading }
id="sendButtonNext"
variant="contained"
onClick={() => doSubmit(values)}
>
Confirm
</Button>
</Box>
}
              {loading ? <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: 50}}><CircularProgress/></div> : null}

              
            </form>
          )}
        </Formik>
      </Container>
    </Box>
  </Page>
        )}</MyContext.Consumer>
  )
};

export default EditTrainView;
