import React from 'react';
import {useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Button,
  Card,
  CardContent,
  TextField,
  InputAdornment,
  SvgIcon,
  makeStyles,
  MenuItem
} from '@material-ui/core';
import { Search as SearchIcon } from 'react-feather';
import MyContext from 'src/MyContext';

const useStyles = makeStyles((theme) => ({
  root: {},
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  }
}));



const Toolbar = ({ className, ...rest }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [contract, setContract] = React.useState(null);
  const [freightOrder, setFreightOrder] = React.useState(null);

  function goToContract(contracts) {
    if (contract == null) {
      alert('Please select a contract to create from.')
      return 
    }

    var trueContract = contracts.filter((item, idx) => {
      return item.contractId == contract 
    })

    if (trueContract.length != 1) {
      alert('Error with contract selection. Please try again.')
      return 
    }
    var customer = trueContract[0]
    let startLat = customer.startPos.lat 
    let endLat = customer.endPos.lat 
    let startLng = customer.startPos.lng 
    let endLng = customer.endPos.lng 

    let cx = startLat + ((endLat - startLat) / 2)
    let cy = startLng + ((endLng - startLng) / 2)

    navigate('/app/createfromltc', {state: {customer: customer, centerx: cx, centery: cy}})

  }

  function goToFreightContract(contracts) {
    if (freightOrder == null) {
      alert('Please select a contract to create from.')
      return 
    }

    var trueContract = contracts.filter((item, idx) => {
      return item.contractId == freightOrder 
    })

    if (trueContract.length != 1) {
      alert('Error with contract selection. Please try again.')
      return 
    }
    var customer = trueContract[0]
    let startLat = customer.startPos.lat 
    let endLat = customer.endPos.lat 
    let startLng = customer.startPos.lng 
    let endLng = customer.endPos.lng 

    let cx = startLat + ((endLat - startLat) / 2)
    let cy = startLng + ((endLng - startLng) / 2)

    navigate('/app/createorderfromltc', {state: {customer: customer, centerx: cx, centery: cy}})

  }

  return (
    <MyContext.Consumer>{context => (
    <div
      className={clsx(classes.root, className)}
      {...rest}
    >
     <div style={{flexDirection: 'row', display: 'flex'}}>
      <div style={{marginLeft: 10, marginRight: 10}}>        
      <Button
          color="primary"
          variant="contained"
          onClick={ () => {
            navigate('/app/quote');
          }}
        >
          New Quote Request
        </Button></div>
          {context.contracts.length && false > 0 ? 
        <div style={{marginLeft: 10, marginRight: 10}}><Button
          color="primary"
          variant="contained"
          onClick={ () => {
            goToContract(context.contracts);
          }}
        >
          Request From LTC
        </Button></div>: null}
        {context.contracts.length > 0 && false ?   <div style={{marginLeft: 10, marginRight: 10, marginTop: -10}}>
        <TextField select 
                id="xi24443f"
                key="aiwaw2wwr23"
          style={{width: 200}}
          label="Contract"
          value={contract}
          onChange={(x) => {
            console.log('xe')
            console.log(x.target.value)
            setContract(x.target.value)
          }}
        >
          {context.contracts.map((item, idx) => {
            return (<MenuItem value={item.contractId}>{item.contractName}</MenuItem>)
          })}
        </TextField>
       </div>: null}
       {context.contracts.length > 0 ? 
        <div style={{marginLeft: 10, marginRight: 10}}><Button
          color="primary"
          variant="contained"
          onClick={ () => {
            goToFreightContract(context.contracts);
          }}
        >
          New Freight Order
        </Button></div>: null}
        {context.contracts.length > 0 ?   <div style={{marginLeft: 10, marginRight: 10, marginTop: -10}}>
        <TextField select 
        id="xi3f"
        key="aiw23"
          style={{width: 200}}
          label="Contract"
          value={freightOrder}
          onChange={(x) => {
            console.log('xe')
            console.log(x.target.value)
            setFreightOrder(x.target.value)
          }}
        >
          {context.contracts.map((item, idx) => {
            return (<MenuItem value={item.contractId}>{item.contractName}</MenuItem>)
          })}
        </TextField>
       </div>: null}

        </div>

    </div>
    )}</MyContext.Consumer>
  );
};

Toolbar.propTypes = {
  className: PropTypes.string
};

export default Toolbar;
