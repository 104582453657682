import React, { useState } from 'react';
import {useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Avatar,
  Box,
  Card,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  makeStyles,
  Button
} from '@material-ui/core';
import getInitials from 'src/utils/getInitials';
import {X} from 'react-feather';
import HeaderComponent from 'src/utils/HeaderComponent';
import SearchWrapper from 'src/utils/SearchWrapper';
import TableAssist from 'src/utils/TableAssist';

const searchOptions = {
  'shortId': {
      'name': 'Freight Order ID',
      'val': 'shortId'
  },
  'totalWeightRemaining': {
    'name': 'Remaining Balance',
    'val': 'totalWeightRemaining',
  },
  'companyName': {
      'name': 'Company Name',
      'val': 'companyName'
  },
  'startName': {
      'name': 'Pickup Location',
      'val': 'startName'
  },
  'endName': {
      'name': 'Dropoff Location',
      'val': 'endName'
  },
  'commodity': {
      'name': 'Commodity',
      'val': 'commodity'
  },
}

const useStyles = makeStyles((theme) => ({
  root: {},
  avatar: {
    marginRight: theme.spacing(2)
  }
}));

const FreightOrderTable = ({ className, customers, isAdmin, ...rest }) => {
  const classes = useStyles();
  const [selectedCustomerIds, setSelectedCustomerIds] = useState([]);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);
  const [text_filters, set_text_filters] = useState([]);
  const [sortOrder, setSortOrder] = useState('creationDate');
  const [sortDirection, setSortDirection] = useState(false);
  const navigate = useNavigate();

  const handleSelectAll = (event) => {
    let newSelectedCustomerIds;

    if (event.target.checked) {
      newSelectedCustomerIds = customers.map((customer) => customer.freightOrderId);
    } else {
      newSelectedCustomerIds = [];
    }

    setSelectedCustomerIds(newSelectedCustomerIds);
  };

  function activateTextFilter(val) {
    var oldFilters = text_filters
    //console.log('old text filters : ' + JSON.stringify(oldFilters))
    var found_filter = false
    oldFilters = oldFilters.map((item, idx) => {
        if (item.name == val.name) {
            found_filter = true
            item.target = val.target
            return val
        }
        return item
    })
    //console.log('oldbeforeadding')
    if (!found_filter) {
        oldFilters.push(val)
    }
    console.log('new text filters : ' + JSON.stringify(oldFilters))
    set_text_filters(oldFilters)
  
  }
  
  function deactivateTextFilter(val) {
    let newFilters = text_filters.filter((item, idx) => {
        return (item.key != val)
    })
  
    set_text_filters(newFilters)
  }
  
  function updateSortDirection(v, d) {
  setSortOrder(v)
  setSortDirection(d)
  }
  
  function applySecondaryFilters(items, filters) {
  var returnList = items.filter((v, idx) => {
      let item = v
      var returnItem = true 
      filters.map((filter, filterIdx) => {
          if (filter.condition == '>') {
              if (filter.key in item && item[filter.key] > filter.target) {
                  //returnItem = true
              } else {
                  returnItem = false
              }
          } else if (filter.condition == '<') {
              if (filter.key in item && item[filter.key] < filter.target) {
                  //returnItem = true
              } else {
                returnItem = false
              }
          } else if (filter.condition == '=') {
              if (filter.key in item && item[filter.key] === filter.target) {
                  //returnItem = true
              } else {
                returnItem = false
              }
          } else if (filter.condition == 't') {
            
              if (filter.key in item) {
  
                  let itemSet = TableAssist.fieldFormat(item, filter.key,3) 
                  console.log(itemSet)
                  console.log(filter.key)
                  console.log(item.verified)
                  let itemData = itemSet.toUpperCase();
  
                  let textData = filter.target.toUpperCase();
  
                  if (itemData.indexOf(textData) > -1) {
                      //returnItem = true
                  } else {
                    returnItem = false
                  }
  
              } else {
                //console.log(item)
                //console.log('not in' + filter.key)
                returnItem = false
              }
          } else if (filter.condition == 'd') {
              if (filter.key in item && item[filter.key] in filter.target) {
                  //returnItem = true
              } else {
                returnItem = false
              }
  
          }
      })
  
  
      return returnItem
  })
  
  return returnList
  }
  
  function compare2(aa, bb, key, reverse) {
        
  let a = aa//TableHelper.getSortValue(aa, key)
  let b = bb//TableHelper.getSortValue(bb, key)
  
  console.log('do a comparison of ' + a[key] + ' to ' + b[key])
  let aval = a[key]
  let bval = b[key]
  if (aval < bval) {
      return -1 * ((2 * reverse) - 1);
  }
  if (aval > bval) {
      return ((2 * reverse) - 1);
  }
  return 0;
  }
  
  function sortItems(v, s) {
  //console.log('try sorting')
  //console.log(v)
  //console.log(s)

  var o = v

  if (s == 0) {
      return o
  }
  else if (s == 1) {
      o.sort((a, b) => compare2(a, b, s, sortDirection))
  } else {
      o.sort((a, b) => compare2(a, b, s, sortDirection))
  }
  
  //alert(JSON.stringify(o))
  //return o
  if (text_filters.length > 0) {
    return applySecondaryFilters(o, text_filters)
  }
  return o
  }

  const handleSelectOne = (event, id) => {
    const selectedIndex = selectedCustomerIds.indexOf(id);
    let newSelectedCustomerIds = [];

    if (selectedIndex === -1) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(selectedCustomerIds, id);
    } else if (selectedIndex === 0) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(selectedCustomerIds.slice(1));
    } else if (selectedIndex === selectedCustomerIds.length - 1) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(selectedCustomerIds.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(
        selectedCustomerIds.slice(0, selectedIndex),
        selectedCustomerIds.slice(selectedIndex + 1)
      );
    }

    setSelectedCustomerIds(newSelectedCustomerIds);
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  function renderVerified(x) {
    if (x.verified) {
      return 'Verified'
    } else {
      return 'Unverified'
    }
  }

 function renderAction(customer, isAdmin) {

  if (isAdmin) {
    return <div style={{display: 'flex', flexDirection: 'column'}}>
      <Button
    color="primary"
    
    onClick={() => goToFreightRequest(customer, isAdmin)}
    id="sendButton"
    size="large"
    variant="contained"
  
  >
    Create Load
  </Button>
 
      </div>
  } else {
    return <Button
    color="primary"
   
    onClick={() => goToFreightRequest(customer, isAdmin)}
    id="sendButton"
    size="large"
    variant="contained"
  
  >
    View Order
  </Button>
  }

    if (!('state' in customer) || customer.state == 0) {
      return <Button
      color="primary"
     
      onClick={() => navigate('/app/editltc', {state: {customer: customer}})}
      id="sendButton"
      size="large"
      variant="contained"
 
    >
      Edit FreightOrder
     </Button>
    } else if (customer.state == 2) {
      return <Button
      color="primary"
     
      onClick={() => window.open('http://localhost:3000/v/' + customer.freightOrderId)}
      id="sendButton"
      size="large"
      variant="contained"
  
    >
      Assign Job
    </Button>
    } else if (customer.state > 2) {
      return <Button
      color="primary"
     
      onClick={() => alert('later stages not implemented yet')}
      id="sendButton"
      size="large"
      variant="contained"
  
    >
      Manage Job
    </Button>
    }
 }

 function goToFreightRequest(customer, isAdmin) {

  let startLat = customer.startPos.lat 
  let endLat = customer.endPos.lat 
  let startLng = customer.startPos.lng 
  let endLng = customer.endPos.lng 

  let cx = startLat + ((endLat - startLat) / 2)
  let cy = startLng + ((endLng - startLng) / 2)
  //alert(isAdmin)
  if (isAdmin) {
    navigate('/app/createfromfreightorder', {state: {customer: customer, centerx: cx, centery: cy}})
  } else {
    navigate('/app/viewfreightorder', {state: {customer: customer, centerx: cx, centery: cy}})
  }


}

  return (
<div>
       <SearchWrapper defaultSearchOption={'shortId'} defaultSearchName={'Freight Order ID'} searchOptions={searchOptions} activate={(v) => activateTextFilter(v)} deactivate={(v) => deactivateTextFilter(v)}/>
       <Card
       className={clsx(classes.root, className)}
       {...rest}
     >
      <PerfectScrollbar>
        <Box minWidth={1050}>
        <div style={{ flexDirection: 'row', display: 'flex', marginBottom: 20 }}>
                    {
                        text_filters.map((item, idx) => {
                            return (
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', backgroundColor: 'rgb(230,230,230)', borderRadius: 30, padding: 0, margin: 0, height: 30, padding: 10, margin: 5, }}>
                                    <p>{item.name + ' contains ' + item.target}</p>
                                    <X onClick={() => deactivateTextFilter(item.key)} />
                                </div>
                                )
                        })
                    }
                </div>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={selectedCustomerIds.length === customers.length}
                    color="primary"
                    indeterminate={
                      selectedCustomerIds.length > 0
                      && selectedCustomerIds.length < customers.length
                    }
                    onChange={handleSelectAll}
                  />
                </TableCell>
                <HeaderComponent sortSet={(v, d) => updateSortDirection(v, d)} text={'Freight Order ID'} itemkey={'shortId'} activate={(v) => activateTextFilter(v)} deactivate={(v) => deactivateTextFilter(v)} />
                <HeaderComponent sortSet={(v, d) => updateSortDirection(v, d)} text={'Remaining Balance'} itemkey={'totalWeightRemaining'} activate={(v) => activateTextFilter(v)} deactivate={(v) => deactivateTextFilter(v)} />
                <HeaderComponent sortSet={(v, d) => updateSortDirection(v, d)} text={'Company Name'} itemkey={'companyName'} activate={(v) => activateTextFilter(v)} deactivate={(v) => deactivateTextFilter(v)} />
                <HeaderComponent sortSet={(v, d) => updateSortDirection(v, d)} text={'Pickup Location'} itemkey={'startName'} activate={(v) => activateTextFilter(v)} deactivate={(v) => deactivateTextFilter(v)} />
                <HeaderComponent sortSet={(v, d) => updateSortDirection(v, d)} text={'Dropoff Location'} itemkey={'endName'} activate={(v) => activateTextFilter(v)} deactivate={(v) => deactivateTextFilter(v)} />
                <HeaderComponent sortSet={(v, d) => updateSortDirection(v, d)} text={'Commodity'} itemkey={'commodity'} activate={(v) => activateTextFilter(v)} deactivate={(v) => deactivateTextFilter(v)} />

                <TableCell>
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sortItems(customers, sortOrder).slice(page * limit, page * limit + limit).map((customer) => (
                <TableRow
                  hover
                  key={customer.freightOrderId}
                  selected={selectedCustomerIds.indexOf(customer.freightOrderId) !== -1}
                 
                >
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={selectedCustomerIds.indexOf(customer.freightOrderId) !== -1}
                      onChange={(event) => handleSelectOne(event, customer.freightOrderId)}
                      value="true"
                    />
                  </TableCell>
                  <TableCell>
                    {customer.shortId}
                  </TableCell>
                  <TableCell>
                    {customer.totalWeightRemaining * 10}
                  </TableCell>
                  <TableCell>
                    {customer.companyName}
                  </TableCell>
                  <TableCell>
                    {customer.startName}
                  </TableCell>
                  <TableCell>
                    {customer.endName}
                  </TableCell>
                  <TableCell>
                    {customer.commodity}
                  </TableCell>
                  <TableCell>
                    {renderAction(customer, isAdmin)}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={customers.length}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[5, 10, 25]}
      />
    </Card></div>
  );
};

FreightOrderTable.propTypes = {
  className: PropTypes.string,
  customers: PropTypes.array.isRequired
};

export default FreightOrderTable;
