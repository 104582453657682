import React, { useState } from 'react';
import {useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Avatar,
  Box,
  Card,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  makeStyles,
  Button,
  Link
} from '@material-ui/core';
import getInitials from 'src/utils/getInitials';
import {X} from 'react-feather';
import HeaderComponent from 'src/utils/HeaderComponent';
import SearchWrapper from 'src/utils/SearchWrapper';
import TableAssist from 'src/utils/TableAssist';
import Firebase from 'src/Firebase'

const searchOptions = {
  'paymentNumber': {
      'name': 'Payment Number',
      'val': 'paymentNumber'
  },
  'userName': {
      'name': 'User Name',
      'val': 'userName'
  },
  'totalPay': {
    'name': 'Net Pay',
    'val': 'totalPay'
  },
  'linkedJobs': {
    'name': 'Linked Jobs',
    'val': 'linkedJobs'
  }

}

const searchOptionsShipper = {
  'invoiceNumber': {
      'name': 'Invoice Number',
      'val': 'invoiceNumber'
  },
}


const useStyles = makeStyles((theme) => ({
  root: {},
  avatar: {
    marginRight: theme.spacing(2)
  }
}));

const InvoiceTable = ({ className, customers, usersNameDict, jobsIdDict, isAdmin, ...rest }) => {
  const classes = useStyles();
  const [selectedCustomerIds, setSelectedCustomerIds] = useState([]);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);
  const [text_filters, set_text_filters] = useState([]);
  const [sortOrder, setSortOrder] = useState('creationDate');
  const [sortDirection, setSortDirection] = useState(false);
  const navigate = useNavigate();

  const handleSelectAll = (event) => {
    let newSelectedCustomerIds;

    if (event.target.checked) {
      newSelectedCustomerIds = customers.map((customer) => customer.paymentId);
    } else {
      newSelectedCustomerIds = [];
    }

    setSelectedCustomerIds(newSelectedCustomerIds);
  };

  function activateTextFilter(val) {
    var oldFilters = text_filters
    //console.log('old text filters : ' + JSON.stringify(oldFilters))
    var found_filter = false
    oldFilters = oldFilters.map((item, idx) => {
        if (item.name == val.name) {
            found_filter = true
            item.target = val.target
            return val
        }
        return item
    })
    //console.log('oldbeforeadding')
    if (!found_filter) {
        oldFilters.push(val)
    }
    console.log('new text filters : ' + JSON.stringify(oldFilters))
    set_text_filters(oldFilters)
  
  }
  
  function deactivateTextFilter(val) {
    let newFilters = text_filters.filter((item, idx) => {
        return (item.key != val)
    })
  
    set_text_filters(newFilters)
  }
  
  function updateSortDirection(v, d) {
  setSortOrder(v)
  setSortDirection(d)
  }
  
  function applySecondaryFilters(items, filters) {
  var returnList = items.filter((v, idx) => {
      let item = v
      var returnItem = true 
      filters.map((filter, filterIdx) => {
          if (filter.condition == '>') {
              if (filter.key in item && item[filter.key] > filter.target) {
                  //returnItem = true
              } else {
                  returnItem = false
              }
          } else if (filter.condition == '<') {
              if (filter.key in item && item[filter.key] < filter.target) {
                  //returnItem = true
              } else {
                returnItem = false
              }
          } else if (filter.condition == '=') {
              if (filter.key in item && item[filter.key] === filter.target) {
                  //returnItem = true
              } else {
                returnItem = false
              }
          } else if (filter.condition == 't') {
            
              if (filter.key in item || true) {
  
                  let itemSet = TableAssist.fieldFormat(item, filter.key,4,usersNameDict, jobsIdDict) 
                  console.log(itemSet)
                  console.log(filter.key)
                  console.log(item.verified)
                  let itemData = itemSet.toUpperCase();
  
                  let textData = filter.target.toUpperCase();
  
                  if (itemData.indexOf(textData) > -1) {
                      //returnItem = true
                  } else {
                    returnItem = false
                  }
  
              } else {
                //console.log(item)
                //console.log('not in' + filter.key)
                returnItem = false
              }
          } else if (filter.condition == 'd') {
              if (filter.key in item && item[filter.key] in filter.target) {
                  //returnItem = true
              } else {
                returnItem = false
              }
  
          }
      })
  
  
      return returnItem
  })
  
  return returnList
  }
  
  function compare2(aa, bb, key, reverse) {
        
  let a = aa//TableHelper.getSortValue(aa, key)
  let b = bb//TableHelper.getSortValue(bb, key)
  
  console.log('do a comparison of ' + a[key] + ' to ' + b[key])
  let aval = TableAssist.fieldFormat(a, key,4,usersNameDict, jobsIdDict)
  let bval = TableAssist.fieldFormat(b, key,4,usersNameDict, jobsIdDict) 
 
  if (aval < bval) {
      return -1 * ((2 * reverse) - 1);
  }
  if (aval > bval) {
      return ((2 * reverse) - 1);
  }
  return 0;
  }
  
  function sortItems(v, s) {
  //console.log('try sorting')
  //console.log(v)
  //console.log(s)

  var o = v

  if (s == 0) {
      return o
  }
  else if (s == 1) {
      o.sort((a, b) => compare2(a, b, s, sortDirection))
  } else {
      o.sort((a, b) => compare2(a, b, s, sortDirection))
  }
  
  //alert(JSON.stringify(o))
  //return o
  if (text_filters.length > 0) {
    return applySecondaryFilters(o, text_filters)
  }
  return o
  }

  const handleSelectOne = (event, id) => {
    const selectedIndex = selectedCustomerIds.indexOf(id);
    let newSelectedCustomerIds = [];

    if (selectedIndex === -1) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(selectedCustomerIds, id);
    } else if (selectedIndex === 0) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(selectedCustomerIds.slice(1));
    } else if (selectedIndex === selectedCustomerIds.length - 1) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(selectedCustomerIds.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(
        selectedCustomerIds.slice(0, selectedIndex),
        selectedCustomerIds.slice(selectedIndex + 1)
      );
    }

    setSelectedCustomerIds(newSelectedCustomerIds);
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  function renderVerified(x) {
    if (x.verified) {
      return 'Verified'
    } else {
      return 'Unverified'
    }
  }

 function renderAction(customer, isAdmin) {
  return (
      <Button
    color="primary"
    style={{width: 170, marginBottom: 5}}
    onClick={() => navigate('/app/viewpayment', {state: {customer: customer}})}
    id="sendButton"
    size="large"
    variant="contained"
  
  >
    View Payment
  </Button> )

 }

 function goToFreightRequest(customer, isAdmin) {

  let startLat = customer.startPos.lat 
  let endLat = customer.endPos.lat 
  let startLng = customer.startPos.lng 
  let endLng = customer.endPos.lng 

  let cx = startLat + ((endLat - startLat) / 2)
  let cy = startLng + ((endLng - startLng) / 2)
  //alert(isAdmin)
  if (isAdmin) {
    navigate('/app/createfromInvoice', {state: {customer: customer, centerx: cx, centery: cy}})
  } else {
    navigate('/app/viewInvoice', {state: {customer: customer, centerx: cx, centery: cy}})
  }


}

  function renderComma(idx, jobs) {
 
    if (idx < jobs.length - 1) {
      return ', '
    } else {
      return ''
    }
  }


  function precisionRound(num) {
    
    return +num.toFixed(2)
  }
  

  function renderLinkSet(customer) {
    return( <div style={{flexDirection: 'column', display: 'flex'}}>
      {customer.jobs.map((item, idx) => {
        return ( <Link
          to={jobsIdDict[item.quoteId]}
          onClick={ () => window.open(Firebase.adminip + 'info/' + jobsIdDict[item.quoteId])}
          variant="h6"
        >{jobsIdDict[item.quoteId] + '' + renderComma(idx, customer.jobs)}</Link>)
      })}
    </div>)
    /*
    var columns = Math.floor(customer.jobs.length / 3)
    if (columns * 3 < customer.jobs.length) {
      columns += 1
    }
    var colrow = [...Array(columns).keys()]
    var rows = [0,1,2]
    return <div>
      {colrow.map((item, idx) => {
        return <div style={{display: 'flex', flexDirection: 'row'}}>
        {rows.map((inner, innerI) => {
          return <div>{item + inner < customer.jobs.length ? 
            <Link
            to={customer}
            onClick={ () => window.open(Firebase.adminip + 'info/' + jobsIdDict[customer.jobs[item + inner].quoteId])}
            variant="h6"
          >{jobsIdDict[customer.jobs[item + inner].quoteId]}</Link> : null
          }</div>
        })}
       </div>
      })}
    </div>*/
  }

  return (
<div>
       <SearchWrapper defaultSearchOption={'paymentNumber'} defaultSearchName={'Payment Number'} searchOptions={isAdmin ? searchOptions : searchOptionsShipper} activate={(v) => activateTextFilter(v)} deactivate={(v) => deactivateTextFilter(v)}/>
       <Card
       className={clsx(classes.root, className)}
       {...rest}
     >
      <PerfectScrollbar>
        <Box minWidth={1050}>
        <div style={{ flexDirection: 'row', display: 'flex', marginBottom: 20 }}>
                    {
                        text_filters.map((item, idx) => {
                            return (
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', backgroundColor: 'rgb(230,230,230)', borderRadius: 30, padding: 0, margin: 0, height: 30, padding: 10, margin: 5, }}>
                                    <p>{item.name + ' contains ' + item.target}</p>
                                    <X onClick={() => deactivateTextFilter(item.key)} />
                                </div>
                                )
                        })
                    }
                </div>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={selectedCustomerIds.length === customers.length}
                    color="primary"
                    indeterminate={
                      selectedCustomerIds.length > 0
                      && selectedCustomerIds.length < customers.length
                    }
                    onChange={handleSelectAll}
                  />
                </TableCell>
                <HeaderComponent sortSet={(v, d) => updateSortDirection(v, d)} text={'Payment Number'} itemkey={'paymentNumber'} activate={(v) => activateTextFilter(v)} deactivate={(v) => deactivateTextFilter(v)} />
                <HeaderComponent sortSet={(v, d) => updateSortDirection(v, d)} text={'User Name'} itemkey={'userName'} activate={(v) => activateTextFilter(v)} deactivate={(v) => deactivateTextFilter(v)} />
                <HeaderComponent sortSet={(v, d) => updateSortDirection(v, d)} text={'Net Pay'} itemkey={'totalPay'} activate={(v) => activateTextFilter(v)} deactivate={(v) => deactivateTextFilter(v)} />
                <HeaderComponent sortSet={(v, d) => updateSortDirection(v, d)} text={'Linked Jobs'} itemkey={'linkedJobs'} activate={(v) => activateTextFilter(v)} deactivate={(v) => deactivateTextFilter(v)} />


                <TableCell>
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sortItems(customers, sortOrder).slice(page * limit, page * limit + limit).map((customer) => (
                <TableRow
                  hover
                  key={customer.paymentId}
                  selected={selectedCustomerIds.indexOf(customer.paymentId) !== -1}
                 
                >
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={selectedCustomerIds.indexOf(customer.paymentId) !== -1}
                      onChange={(event) => handleSelectOne(event, customer.paymentId)}
                      value="true"
                    />
                  </TableCell>
                  <TableCell>
                    {'P-' + TableAssist.padDigits(customer.shortIdShipper)}
                  </TableCell>
                <TableCell>
                    {TableAssist.renderDriver({'assignedDriver': customer.jobs[0].recipient}, usersNameDict)}
                  </TableCell>
                  <TableCell>
                    {precisionRound(customer.totalPay)}
                  </TableCell>
                  <TableCell>
                    {renderLinkSet(customer)}
                  </TableCell>
                 
                  <TableCell>
                    {renderAction(customer, isAdmin)}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={customers.length}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[5, 10, 25]}
      />
    </Card></div>
  );
};

InvoiceTable.propTypes = {
  className: PropTypes.string,
  customers: PropTypes.array.isRequired
};

export default InvoiceTable;
