import React, { Component } from 'react';
import firebase from '@firebase/app';
import '@firebase/auth';
import '@firebase/storage';
import 'firebase/messaging';
import '@firebase/database';
import '@firebase/functions';
import '@firebase/firestore';
import mixpanel from 'mixpanel-browser';
var moment = require('moment');


// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyAPfzaVBySCO40hET42JnvWatndbgUa5Mc",
    authDomain: "garriwarehouse-91d16.firebaseapp.com",
    databaseURL: "https://garriwarehouse-91d16-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "garriwarehouse-91d16",
    storageBucket: "garriwarehouse-91d16.appspot.com",
    messagingSenderId: "557184399629",
    appId: "1:557184399629:web:4ee2049c3b85f5a609d844"
  };

  // Your web app's Firebase configuration
/*const firebaseConfig = {
    apiKey: "AIzaSyBbxGppiG74JVtYuHXokWjgNzbvrlnV4pA",
    authDomain: "garriwarehousetest.firebaseapp.com",
    databaseURL: "https://garriwarehousetest-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "garriwarehousetest",
    storageBucket: "garriwarehousetest.appspot.com",
    messagingSenderId: "62757928133",
    appId: "1:62757928133:web:e213be7566fe388dc184ac"
  };*/
  

export default class Firebase {

    static dateNow = Date.now();
    static mainApp = null;
    static didLog = true;
    static didInit = false;
    static firebaseRef = firebase;
    static requiresContextInit = false;
    static IS_ASSOCIATION = false;
    static associationId = 'notset';
    static uid = '';
    static userInfo = {};
    static awfulStuff = [];
    static ip = 'https://shipper.garrilogistics.com/';
    static adminip = 'https://admin.garrilogistics.com/';
    static mixpanel = null;
    static isAdmin = false;
    static sharedSupplierFields = ['type', 'woreda', 'kebele', 'zone', 'cluster', 'aggregatorcode', 'financesn']
    static ONLINE = true;
    static userProject = 'none'


    static padDigits(number) {
        return Array(Math.max(5 - String(number).length + 1, 0)).join(0) + number;
      }

    static setUserProject(s) {
        Firebase.userProject = s
    }

    static userInfoUpdate(s) {
        console.log('unset')
    }
 
    static setMainApp(val) {
        this.mainApp = val
    }

    static async init() {
        console.log('try init')
		firebase.initializeApp(firebaseConfig);
        firebase.firestore().settings({ experimentalForceLongPolling: true });
        console.log('using polling force true')
        Firebase.auth = firebase.auth();
        //Firebase.auth.settings.appVerificationDisabledForTesting = true;
        Firebase.database = firebase.database();
        Firebase.storage = firebase.storage();
        Firebase.functions = firebase.functions();
        /*if ( process.env.NODE_ENV == 'development') {
            Firebase.functions.useEmulator("localhost", 5001)
        }*/
        Firebase.firestore = firebase.firestore();
        Firebase.databaseSimple = firebase.database;
        Firebase.firestoreSimple = firebase.firestore
        firebase.firestore().enablePersistence().catch((err) => {
            console.log('persistence error')
            console.log(err.code)
            if (err.code == 'failed-precondition') {
                // Multiple tabs open, persistence can only be enabled
                // in one tab at a a time.
                // ...
            } else if (err.code == 'unimplemented') {
                // The current browser does not support all of the
                // features required to enable persistence
                // ...
            }
        });
                console.log(Firebase.database)
        console.log('initialized firebase')
        Firebase.didInit = true

        
        Firebase.initiazlieAwfulStuff();

        if (!Firebase.isAdmin) {
            Firebase.mixpanel = mixpanel 
            Firebase.mixpanel.init('62474fac66184c8c767d46d6c2036de5')
        }
        //Firebase.messaging = firebase.messaging();
        //Firebase.message_token = await Firebase.messaging.getToken();
    }


    static initiazlieAwfulStuff() {
        Firebase.awfulStuff.map((item, idx) => {
            item()
        })
    }

    static setUid(uid) {

        Firebase.uid = uid
        console.log('tryhere')
        /*Firebase.database.ref('users/' + Firebase.uid).once('value').then((data) => {
            console.log('yhea')
            console.log(data.val())
            if (data.exists()) {
                Firebase.userInfo = data.val()
                Firebase.userInfoUpdate(Firebase.userInfo)
            }

        }).catch((error) => {
            console.log('error')
        })*/
    }

    static logToggle() {
        
        if (this.mainApp != null) {
            this.mainApp()
        } else {
            alert(' was null')
        }
    }

    static async identifyMixpanel(id) {
        if (Firebase.mixpanel != null) {
            Firebase.mixpanel.identify(id)
        }
    }

    static async resetMixpanel() {
        if (Firebase.mixpanel != null) {
            Firebase.mixpanel.reset()
            //alert('do reset')
        } else {
            //alert('dont reset')
        }
    }

    static async trackMixpanel(event, properties) {
        if (Firebase.mixpanel != null) {
            Firebase.mixpanel.track(event, properties)
            //alert('do track')
        } else {
            //alert('dont track')
        }
    }

    static garriApiRequest(call, call_args, success, fail) {
        //console.log('trying to make a call to ' + call)
        //console.log('call args: ')
        //console.log(call_args)
        const httpsCallable = Firebase.functions.httpsCallable('api/' + call);
        httpsCallable(call_args)
            .then((result) => {
                console.log(result)
                console.log('respush')
                //console.log(JSON.stringify(result))
                //console.log(result)
                success(result)
            })
            .catch(httpsError => {
                console.log(httpsError)
                console.log(JSON.stringify(httpsError))
                console.log('errorpush')
                //console.log(httpsError.code); // invalid-argument
                //console.log(httpsError.message); // Your error message goes here
                ////console.log(httpsError.details.foo); // bar
                fail()
            })
    }
// Add a new quote in a siutation where the user may or may not exist
static addNewQuoteUnauth(data, locStart, locEnd, uid, s, f) {

    var newDat = data 
    newDat['endDateEarly'] = moment(data.earlyDrop).valueOf()
    newDat['startDateEarly'] = moment(data.earlyPick).valueOf()
    newDat['userPhone'] = data.phone 
    newDat['companyName'] = data.shipperName
    newDat['email'] = data.shipperEmail
    newDat['userId'] = uid 
    newDat['startPos'] = locStart 
    newDat['endPos'] = locEnd 
    newDat['startName'] = data.pickupLocation
    newDat['endName'] = data.dropoffLocation

    /*var dpack = {'userId': data.userId, 'userPhone': data.phone, 'companyName': data.shipperName, 
    'startDateEarly': moment(data.earlyPick).valueOf(), 'endDateEarly': moment(data.earlyDrop).valueOf(), 'cargo': data.cargoName, 'startName': data.pickupLocation, 'endName': data.dropoffLocation,
    'startPos': locStart, 'endPos': locEnd, 'userId': uid, 'cargoType': data.cargoType, 'cargoFields': data.cargoFields, 'trueWeight': data.trueWeight}
    */
    Firebase.garriApiRequest('createQuoteUnauth', newDat, () => s(), () => f())
    
}

static editQuoteAuth(data, locStart, locEnd, uid, s, f) {


    /*var dpack = {'price': data.price, 
    'startDateEarly': moment(data.earlyPick).valueOf(), 'endDateEarly': moment(data.earlyDrop).valueOf(), 'cargo': data.cargoName, 'startName': data.pickupLocation, 'endName': data.dropoffLocation,
    'startPos': locStart, 'endPos': locEnd, 'userId': uid, 'quoteId': data.quoteId, 'cargoType': data.cargoType, 'cargoFields': data.cargoFields,
    'trueWeight': data.trueWeight}*/
    var newDat = data 
    if (newDat.limitedAccessUpdate == 1) {
        newDat['userId'] = uid 
        delete newDat['limitedAccessUpdate']
        var dpack = newDat
    } else {
        newDat['endDateEarly'] = moment(data.earlyDrop).valueOf()
        newDat['startDateEarly'] = moment(data.earlyPick).valueOf()
        newDat['userPhone'] = data.phone 
        newDat['companyName'] = data.shipperName
        newDat['userId'] = uid 
        newDat['startPos'] = locStart 
        newDat['endPos'] = locEnd 
        newDat['startName'] = data.pickupLocation
        newDat['endName'] = data.dropoffLocation
        var dpack = newDat
        if ('transporterPrice' in data) {
            dpack['transporterPrice'] = data.transporterPrice
        }
        if ('state' in data)
            dpack['state'] = data['state']
        console.log(dpack)
        console.log('for send')
    }
    
    Firebase.garriApiRequest('editQuoteAuth', dpack, () => s(), () => f())
}


static async phoneLogin(phone, s, f) {
    const httpsCallable = Firebase.functions.httpsCallable('userPhoneAuthInit');
    console.log('try userpass login ')
    let call_args = { phone: phone }

    httpsCallable(call_args)
        .then(({ data }) => {
            console.log('respush')
           
            console.log(data); // hello world
            s(data['data'])
        })
        .catch(httpsError => {
            console.log('errorpush')
            console.log(httpsError)
            f(httpsError)
        })
    console.log('tried userpass login')
}

static async codeConfirm(verificationId, phone, s, f) {


  const httpsCallable = Firebase.functions.httpsCallable('userPhoneAuthCodeConfirm');
  console.log('try userpass login ')
  let call_args = { code: phone, verificationId: verificationId }

  httpsCallable(call_args)
      .then(({ data }) => {
          console.log('respush')
         
          console.log(data); // hello world
          s(data['data'])
      })
      .catch(httpsError => {
          console.log('errorpush')
          console.log(httpsError)
          f(httpsError)
      })
  console.log('tried userpass login')
}

static editQuoteBasic(data, s, f) {
    Firebase.garriApiRequest('editQuoteAuth', data, () => s(), () => f())
}

static editQuoteSimple(dpack, s, f) {
    //alert(JSON.stringify(dpack))
    //return
    Firebase.garriApiRequest('editQuoteAuth', dpack, () => s(), () => f())
}

static async offerJobToTransporters(dpack) {
    Firebase.garriApiRequest('offerJobToTransporters', dpack, () => console.log('call success'), () => console.log('call error'))
}   

static async adminAddUser(dpack, s, f) {
    Firebase.garriApiRequest('adminCreateUser', dpack, (x) => s(x['data']), () => f())
}

static async adminEditVehicle(dpack, s, f) {
    Firebase.garriApiRequest('editVehicle', dpack, (x) => s(x['data']), () => f())
}

static async adminEditUser(dpack, s, f) {
    Firebase.garriApiRequest('editUser', dpack, (x) => s(x['data']), () => f())
}

static async adminAddAssociation(dpack, s, f) {
    Firebase.garriApiRequest('addAssociation', dpack, (x) => s(x['data']), () => f())
}

static async adminEditAssociation(dpack, s, f) {
    Firebase.garriApiRequest('editAssociation', dpack, () => s(), () => f())
}

static async acceptBid(dpack) {
    Firebase.garriApiRequest('assignJobToTransporter', dpack, () => console.log('call success'), () => console.log('call error'))
}

static async getAllGpsWoxDevices(s, f) {
    Firebase.garriApiRequest('getGarriDevicesGpsWox', {}, (x) => s(x), (x) => f(x))
}

static async editVehicleGPSWox(dpack, s, f) {
    Firebase.garriApiRequest('editVehicleGpsWox', dpack, (x) => s(x), (x) => f(x))
}

static async batchArchiveData(dpack, s, f) {
    Firebase.garriApiRequest('batchDeleteOrArchiveJobs', dpack, () => s(), () => f())
}

static async batchNotifyUsers(dpack, s, f) {
    Firebase.garriApiRequest('batchNotifyUsers', dpack, () => s(), () => f())
}

static async adminSendStatus(dpack, s, f) {
    Firebase.garriApiRequest('adminSendStatusUpdate', dpack, () => s(), () => f())
}

static async editSummaryTime(dpack, s, f) {
    Firebase.garriApiRequest('editSummaryTime', dpack, () => s(), () => f())
}


static async tryAssignDriverToTransporter(dpack, s, f) {
    Firebase.garriApiRequest('tryAssignDriverToTransporter', dpack, (x) => s(x['data']), () => f())
}

static async batchRemoveDriversFromTransporter(dpack, s, f) {
    Firebase.garriApiRequest('batchRemoveDriversFromTransporter', dpack, (x) => s(x['data']), () => f())
}

static async batchUpdateSimpleJobs(dpack, s, f) {
    Firebase.garriApiRequest('batchSimpleUpdateJobs', dpack, (x) => s(x['data']), () => f())
}

static async addJobToAssociation(dpack, s, f) {
    Firebase.garriApiRequest('addJobToAssociation', dpack, (x) => s(x['data']), () => f())
}

static async addUserToAssociation(dpack, s, f) {
    Firebase.garriApiRequest('addUserToAssociation', dpack, (x) => s(x['data']), () => f())
}

static async addGenericToAssociation(dpack, s, f) {
    Firebase.garriApiRequest('genericAddToAssociation', dpack, (x) => s(x['data']), () => f())
}

static async batchGenericRemoveFromAssociation(dpack, s, f) {
    Firebase.garriApiRequest('batchGenericRemoveFromAssociation', dpack, (x) => s(x['data']), () => f())
}

static async getPdfDoc(dpack, s, f) {
    Firebase.garriApiRequest('getDocumentPdfList', dpack, (x) => s(x['data']), () => f())
}

static async batchInvoice(dpack, s, f) {
    Firebase.garriApiRequest('generateBasicInvoice', dpack, (x) => s(x['data']), () => f())
}

static async proFormaInvoice(dpack, s, f) {
    Firebase.garriApiRequest('generateProFormaInvoice', dpack, (x) => s(x['data']), () => f())
}

static async adminCreatePayment(dpack, s, f) {
    Firebase.garriApiRequest('generateBasicPayment', dpack, (x) => s(x['data']), () => f())
}

static async generateWaybill(dpack, s, f) {
    Firebase.garriApiRequest('generateWaybill', dpack, () => s(), () => f())    
}

static async generateGdn(dpack, s, f) {
    Firebase.garriApiRequest('generateGdn', dpack, () => s(), () => f())
}

static async provideGdnLink(dpack, s, f) {

    Firebase.garriApiRequest('provideGdnLink', dpack, () => s(), () => f())
}

static async generateWaybill(dpack, s, f) {

    Firebase.garriApiRequest('generateWaybill', dpack, () => s(), () => f())
}

static async provideWaybillLink(dpack, s, f) {

    Firebase.garriApiRequest('provideWaybillLink', dpack, () => s(), () => f())
}

static async generateWarehouseIn(dpack, s, f) {

    Firebase.garriApiRequest('generateWarehouseIn', dpack, () => s(), () => f())
}

static async provideWarehouseInLink(dpack, s, f) {

    Firebase.garriApiRequest('provideWarehouseInLink', dpack, () => s(), () => f())
}

static async generateWarehouseOut(dpack, s, f) {

    Firebase.garriApiRequest('generateWarehouseOut', dpack, () => s(), () => f())
}

static async provideWarehouseOutLink(dpack, s, f) {

    Firebase.garriApiRequest('provideWarehouseOutLink', dpack, () => s(), () => f())
}

static async providePdfLink(dpack, s, f) {

    Firebase.garriApiRequest('providePdfLink', dpack, () => s(), () => f())
}

static async provideInvoiceLink(dpack, s, f) {

    Firebase.garriApiRequest('provideInvoiceLink', dpack, () => s(), () => f())
}

static async updateInvoiceContract(dpack, s, f) {

    Firebase.garriApiRequest('updateInvoiceContract', dpack, () => s(), () => f())
}

static async retrieveUserSecrets(dpack, s, f) {

    Firebase.garriApiRequest('retrieveUserSecrets', dpack, (x) => s(x['data']), () => f())
}

static async retrieveUserAuthCode(dpack, s, f) {
    Firebase.garriApiRequest('retrieveUserAuthCode', dpack, (x) => s(x['data']), () => f())
}

static async sendInvoiceEmailAttachment(dpack, s, f) {
    Firebase.garriApiRequest('emailAttachmentToShipper', dpack, (x) => s(x['data']), () => f())
}


static async getReducedInfo(dpack, s, f) {
    const httpsCallable = Firebase.functions.httpsCallable('api/' + 'getReducedInfo');
        let ret = await httpsCallable(dpack)
            .then((result) => {
                console.log(result)
                console.log('respush')
                //console.log(JSON.stringify(result))
                //console.log(result)
                return result['data']
            })
            .catch(httpsError => {
                console.log(httpsError)
                console.log(JSON.stringify(httpsError))
                console.log('errorpush')
                //console.log(httpsError.code); // invalid-argument
                //console.log(httpsError.message); // Your error message goes here
                ////console.log(httpsError.details.foo); // bar
                return false
            })
        return ret 
    
}

static async authorizeTrackingFreightOrder(dpack, s, f) {
    const httpsCallable = Firebase.functions.httpsCallable('api/' + 'authorizeTrackingFreightOrder');
        let ret = await httpsCallable(dpack)
            .then((result) => {
                console.log(result)
                console.log('respush')
                //console.log(JSON.stringify(result))
                //console.log(result)
                return result['data']
            })
            .catch(httpsError => {
                console.log(httpsError)
                console.log(JSON.stringify(httpsError))
                console.log('errorpush')
                //console.log(httpsError.code); // invalid-argument
                //console.log(httpsError.message); // Your error message goes here
                ////console.log(httpsError.details.foo); // bar
                return false
            })
        return ret 
    
}

static async authorizeTrackingView(dpack, s, f) {
    const httpsCallable = Firebase.functions.httpsCallable('api/' + 'authorizeTrackingView');
        await httpsCallable(dpack)
            .then((result) => {
                console.log(result)
                console.log('respush')
                //console.log(JSON.stringify(result))
                //console.log(result)
                return true 
            })
            .catch(httpsError => {
                console.log(httpsError)
                console.log(JSON.stringify(httpsError))
                console.log('errorpush')
                //console.log(httpsError.code); // invalid-argument
                //console.log(httpsError.message); // Your error message goes here
                ////console.log(httpsError.details.foo); // bar
                return false 
            })
    
}

static async authorizeTrackingQuote(dpack, s, f) {
    const httpsCallable = Firebase.functions.httpsCallable('api/' + 'authorizeTrackingQuote');
        await httpsCallable(dpack)
            .then((result) => {
                console.log(result)
                console.log('respush')
                //console.log(JSON.stringify(result))
                //console.log(result)
                return true 
            })
            .catch(httpsError => {
                console.log(httpsError)
                console.log(JSON.stringify(httpsError))
                console.log('errorpush')
                //console.log(httpsError.code); // invalid-argument
                //console.log(httpsError.message); // Your error message goes here
                ////console.log(httpsError.details.foo); // bar
                return false 
            })
    
}

static async createNewLongTermContract(data, locStart, locEnd, uid, s, f) {
    var newDat = data 
    newDat['endDateEarly'] = moment(data.earlyDrop).valueOf()
    newDat['startDateEarly'] = moment(data.earlyPick).valueOf()
    //newDat['userPhone'] = data.phone 
    //newDat['companyName'] = data.shipperName
    //newDat['userId'] = uid 
    newDat['startPos'] = locStart 
    newDat['endPos'] = locEnd 
    newDat['startName'] = data.pickupLocation
    newDat['endName'] = data.dropoffLocation
    var dpack = newDat

    console.log(dpack)
    console.log('for send')
    Firebase.garriApiRequest('createNewLongTermContract', dpack, () => s(), () => f())
}

static async editLongTermContract(data, locStart, locEnd, uid, s, f) {
    var newDat = data 
    newDat['endDateEarly'] = moment(data.earlyDrop).valueOf()
    newDat['startDateEarly'] = moment(data.earlyPick).valueOf()
    //newDat['userPhone'] = data.phone 
    //newDat['companyName'] = data.shipperName
    //newDat['userId'] = uid 
    newDat['startPos'] = locStart 
    newDat['endPos'] = locEnd 
    newDat['startName'] = data.pickupLocation
    newDat['endName'] = data.dropoffLocation
    var dpack = newDat

    console.log(dpack)
    console.log('for send')
    Firebase.garriApiRequest('editLongTermContract', dpack, () => s(), () => f())
}

static async createRequestFromLongTermContract(data, locStart, locEnd, uid, s, f) {
    var newDat = data 
    newDat['endDateEarly'] = moment(data.earlyDrop).valueOf()
    newDat['startDateEarly'] = moment(data.earlyPick).valueOf()
    newDat['endDate'] = moment(data.endDate).valueOf()
    newDat['startDate'] = moment(data.startDate).valueOf()
    console.log(newDat)
    console.log('for send')
    Firebase.garriApiRequest('createRequestFromLongTermContract', newDat, (x) => s(x['data']), () => f())
}

static async createFreightOrderFromLongTermContract(data, locStart, locEnd, uid, s, f) {
    var newDat = data 
    newDat['endDateEarly'] = moment(data.earlyDrop).valueOf()
    newDat['startDateEarly'] = moment(data.earlyPick).valueOf()
    console.log(newDat)
    console.log('for send')
    Firebase.garriApiRequest('createFreightOrderFromLongTermContract', newDat, () => s(), () => f())
}

static newQuoteAuth(data, locStart, locEnd, uid, s, f) {

    var newDat = data 
    newDat['endDateEarly'] = moment(data.earlyDrop).valueOf()
    newDat['startDateEarly'] = moment(data.earlyPick).valueOf()
    //newDat['userPhone'] = data.phone 
    newDat['companyName'] = data.shipperName
    newDat['userId'] = uid 
    newDat['startPos'] = locStart 
    newDat['endPos'] = locEnd 
    newDat['startName'] = data.pickupLocation
    newDat['endName'] = data.dropoffLocation
    var dpack = newDat

    /*var dpack = {'startDateEarly': moment(data.earlyPick).valueOf(), 'endDateEarly': moment(data.earlyDrop).valueOf(), 'cargo': data.cargoName, 'startName': data.pickupLocation, 'endName': data.dropoffLocation,
    'startPos': locStart, 'endPos': locEnd, 'userId': uid, 'quoteId': data.quoteId, 'userPhone': data.userPhone, 'cargoType': data.cargoType, 'cargoFields': data.cargoFields,
    'trueWeight': data.trueWeight}*/

    console.log(dpack)
    console.log('for send')
    Firebase.garriApiRequest('newQuoteAuth', dpack, () => s(), () => f())
}

static async addNewUserIfNeeded(email, companyName, phone, uid) {
    console.log('tryfbcheck')
    var val =  {'email': email, 'companyName': companyName, 'phone': phone, 'userId': uid, 'userType': 2}
    var dpack = {'updateBody': val}
    Firebase.garriApiRequest('addNewUserIfNeed', dpack, () => console.log('call success'), () => console.log('call error'))
}

static async cacheRoute(key, resp) {

    Firebase.database.ref('routeCache/' + key).update(resp).then((data) => {
        console.log(data)
    }).catch((error) => {
        console.log(error)
    })
}

static async deleteWebNotification(target, isAdmin) {

    var user = Firebase.uid
    if (isAdmin) {
        user = 'admin'
    }

    Firebase.database.ref('webNotifications/' + user + '/' + target).remove().then((data) => {

    }).catch((error) => {

    })
}

static async usernamePasswordLogin(username, password, s, f) {


    const httpsCallable = Firebase.functions.httpsCallable('userPassAuth');
    console.log('try userpass login ')
    let call_args = { username: username, password: password }

    httpsCallable(call_args)
        .then(({ data }) => {
            console.log('respush')
           
            console.log(data); // hello world
            s(data)
        })
        .catch(httpsError => {
            console.log('errorpush')
            console.log(httpsError)
            f(httpsError)
        })
    console.log('tried userpass login')
}

static async usernamePasswordLoginShipper(username, password, s, f) {


    const httpsCallable = Firebase.functions.httpsCallable('userPassAuthShipper');
    console.log('try userpass login ')
    let call_args = { username: username, password: password }

    httpsCallable(call_args)
        .then(({ data }) => {
            console.log('respush')
           
            console.log(data); // hello world
            s(data)
        })
        .catch(httpsError => {
            console.log('errorpush')
            console.log(httpsError)
            f(httpsError)
        })
    console.log('tried userpass login')
}



static azfat(dpack) {

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(dpack)
    };
    fetch('http://localhost:8000/offerJobToTransporters', requestOptions)
        .then(response => response.json())
        .then(data => console.log(data));
      //Firebase.logToggle()
}

static async garriApiRequestPromise(endpoint, dpack) {
    let fbPromise = new Promise((resolve, reject) => {
        Firebase.garriApiRequest(endpoint, dpack, (x) => resolve(x['data']), () => resolve(null))
    })
    let res = await fbPromise 
    console.log('promise resolution')
    console.log(res)
    if (res && res.info) {
        return res.info 
    } else {
        return null 
    }
}

static async requestDispatchReport(dpack) {
    let r = await Firebase.garriApiRequestPromise('requestDispatchReport', dpack)
    return r
}

static async createBarleyIn(dpack) {
    let r = await Firebase.garriApiRequestPromise('createBarleyIn', dpack)
    return r
}
static async createBarleyOut(dpack) {
    let r = await Firebase.garriApiRequestPromise('createBarleyOut', dpack)
    return r
}
static async updateRowAndStocksBarleyIn(dpack) {
    let r = await Firebase.garriApiRequestPromise('updateRowAndStocksBarleyIn', dpack)
    return r
}
static async updateRowBarleyIn(dpack) {
    let r = await Firebase.garriApiRequestPromise('updateRowBarleyIn', dpack)
    return r
}
static async requestNewColumn(dpack) {
    let r = await Firebase.garriApiRequestPromise('requestNewColumn', dpack)
    return r
}
static async createSupplier(dpack) {
    let r = await Firebase.garriApiRequestPromise('createSupplier', dpack)
    return r
}
static async createFacility(dpack) {
    let r = await Firebase.garriApiRequestPromise('createFacility', dpack)
    return r
}
static async createTransporter(dpack) {
    let r = await Firebase.garriApiRequestPromise('createTransporter', dpack)
    return r
}
static async createDriver(dpack) {
    let r = await Firebase.garriApiRequestPromise('createDriver', dpack)
    return r
}
static async createVehicle(dpack) {
    let r = await Firebase.garriApiRequestPromise('createVehicle', dpack)
    return r
}
static async createSku(dpack) {
    let r = await Firebase.garriApiRequestPromise('createSku', dpack)
    return r
}
static async createProduct(dpack) {
    let r = await Firebase.garriApiRequestPromise('createProduct', dpack)
    return r
}

static async updateRowGeneric(dpack) {
    let r = await Firebase.garriApiRequestPromise('updateRowGeneric', dpack)
    return r
}

static async doApprove(dpack) {
    let r = await Firebase.garriApiRequestPromise('approveTransfer', dpack)
    return r
}

static async doApproveMulti(dpack) {
    let r = await Firebase.garriApiRequestPromise('approveTransferMulti', dpack)
    return r
}

static async doReject(dpack) {
    let r = await Firebase.garriApiRequestPromise('rejectTransfer', dpack)
    return r
}

static async voidBarleyIn(dpack) {
    let r = await Firebase.garriApiRequestPromise('voidBarleyIn', dpack)
    return r
}

static async voidTransferBarleyIn(dpack) {
    let r = await Firebase.garriApiRequestPromise('voidTransferBarleyIn', dpack)
    return r
}

static async voidTransferBarleyOut(dpack) {
    let r = await Firebase.garriApiRequestPromise('voidTransferBarleyOut', dpack)
    return r
}


static async warehouseCreateUser(dpack) {
    let r = await Firebase.garriApiRequestPromise('warehouseCreateUser', dpack)
    return r
}

static async warehouseEditUser(dpack) {
    let r = await Firebase.garriApiRequestPromise('warehouseEditUser', dpack)
    return r
}

static async warehouseEditDriver(dpack) {
    let r = await Firebase.garriApiRequestPromise('warehouseEditDriver', dpack)
    return r
}

static async warehouseEditVehicle(dpack) {
    let r = await Firebase.garriApiRequestPromise('warehouseEditVehicle', dpack)
    return r
}

static async warehouseEditTransporter(dpack) {
    let r = await Firebase.garriApiRequestPromise('warehouseEditTransporter', dpack)
    return r
}

static async getSignedUrl(dpack) {
    let r = await Firebase.garriApiRequestPromise('getSignedUrl', dpack)
    console.log('signed return')
    console.log(r)
    return r
}

static async editStock(dpack) {
    let r = await Firebase.garriApiRequestPromise('editStock', dpack)
    return r
}

static async addStock(dpack) {
    let r = await Firebase.garriApiRequestPromise('addStock', dpack)
    return r
}

static async getUserProject(dpack) {
    let r = await Firebase.garriApiRequestPromise('warehouseGetUserProject', dpack)
    return r
}

static async getServerTimestamp(dpack) {
    let r = await Firebase.garriApiRequestPromise('getServerTimestamp', dpack)
    return r
}

static async resetPasswordViaEmailWarehouseAuth(dpack) {
    let r = await Firebase.garriApiRequestPromise('resetPasswordViaEmailWarehouseAuth', dpack)
    return r
}

static async confirmNewPasswordWarehouse(dpack) {
    let r = await Firebase.garriApiRequestPromise('confirmNewPasswordWarehouse', dpack)
    return r
}
    /*
    // Add a new quote in a siutation where the user may or may not exist
    static addNewQuoteUnauth(data, locStart, locEnd, uid) {



        var dpack = {'userId': data.userId, 'userPhone': data.phone, 'companyName': data.shipperName, 
        'startDateEarly': moment(data.earlyPick).valueOf(), 'endDateEarly': moment(data.earlyDrop).valueOf(), 'startDateLate': moment(data.latePick).valueOf(),
        'endDateLate': moment(data.lateDrop).valueOf(), 'cargo': data.cargoName, 'startName': data.pickupLocation, 'endName': data.dropoffLocation,
        'startPos': locStart, 'endPos': locEnd, 'userId': uid}

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(dpack)
        };
        fetch('http://localhost:8000/createQuoteUnauth', requestOptions)
            .then(response => response.json())
            .then(data => console.log(data));
          //Firebase.logToggle()
        
    }

    static editQuoteAuth(data, locStart, locEnd, uid) {
        var dpack = {'price': data.price, 
        'startDateEarly': moment(data.earlyPick).valueOf(), 'endDateEarly': moment(data.earlyDrop).valueOf(), 'startDateLate': moment(data.latePick).valueOf(),
        'endDateLate': moment(data.lateDrop).valueOf(), 'cargo': data.cargoName, 'startName': data.pickupLocation, 'endName': data.dropoffLocation,
        'startPos': locStart, 'endPos': locEnd, 'userId': uid, 'quoteId': data.quoteId}
        console.log(dpack)
        console.log('for send')
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(dpack)
        };
        fetch('http://localhost:8000/editQuoteAuth', requestOptions)
            .then(response => response.json())
            .then(data => console.log(data));
    }

    static newQuoteAuth(data, locStart, locEnd, uid) {
        var dpack = {'startDateEarly': moment(data.earlyPick).valueOf(), 'endDateEarly': moment(data.earlyDrop).valueOf(), 'startDateLate': moment(data.latePick).valueOf(),
        'endDateLate': moment(data.lateDrop).valueOf(), 'cargo': data.cargoName, 'startName': data.pickupLocation, 'endName': data.dropoffLocation,
        'startPos': locStart, 'endPos': locEnd, 'userId': uid, 'quoteId': data.quoteId}
        console.log(dpack)
        console.log('for send')
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(dpack)
        };
        fetch('http://localhost:8000/newQuoteAuth', requestOptions)
            .then(response => response.json())
            .then(data => console.log(data));
    }

    static async addNewUserIfNeeded(companyName, phone, uid) {
        console.log('tryfbcheck')
        let vv = Firebase.database.ref('users/' + uid).once('value').then((data) => {
            if (!data.exists()) {
                console.log('noexist')
                var val =  {'companyName': companyName, 'phone': phone, 'userId': uid, 'userType': 1}
                Firebase.database.ref('users/' + uid).update(val).then((data) => {
                    Firebase.setUid(uid)
                }).catch((error) => {

                })
            } else {
                console.log('doestix')
                console.log(data.val())
            }

        }).catch((error) => {
            console.log('errorfb')
            console.log(error)
        })
    }*/


}