import React, {useState} from 'react';
import { Link as RouterLink, useNavigate, useLocation} from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';

import LocationOnIcon from '@material-ui/icons/LocationOn';
import {
  Box,
  Button,
  Checkbox,
  Container,
  FormHelperText,
  Link,
  TextField,
  Typography,
  makeStyles,
  Grid,
  Select,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  InputLabel
} from '@material-ui/core';
import Page from 'src/components/Page';
import AddBox from '@material-ui/icons/AddBox';
import Cancel from '@material-ui/icons/Cancel';
import Edit from '@material-ui/icons/Edit';
import parse from 'autosuggest-highlight/parse';
import throttle from 'lodash/throttle';
import Firebase from 'src/Firebase'
import MuiPhoneNumber from 'material-ui-phone-number'
import MyContext from 'src/MyContext'
import CircularProgress from '@material-ui/core/CircularProgress';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';

import NewSupplierViewCustom from 'src/views/customobjects/CustomObjectsListView/NewSupplierViewCustom';
const filter = createFilterOptions();


const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/


const autocompleteService = { current: null };
const geocoder = {current: null};

const necessaryFields = {
  'bankname': 1,
  'bankaccountnumber': 1,
  'kebele':1,
  'woreda': 1,
  'zone': 1,
  'cluster': 1,
  'mainaggregator': 1,
  'name': 1,
  'type': 1,
  'supplierphone': 1
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  }
}));


const AddSupplierDialog = (props) => {
  const navigate = useNavigate();
  const [dialogWorking, setDialogWorking] = React.useState(false)
  const [value, setValue] = React.useState(null);
  const [uploadsHappening, setUploadsHappening] = React.useState(false);
  const docDiaRef = React.useRef(null);
  const inputRef = React.useRef(null);
  const [showDocumentDialog, setShowDocumentDialog] = React.useState(false)
  const [documentPlaceholder, setDocumentPlaceholder] = React.useState('')
  const [targetDocumentIndex, setTargetDocumentIndex] = React.useState(-1)
  const [loading, setLoading] = React.useState(false);
  const [autoValues, setAutoValues] = React.useState({})
  

  function getType(t) {
    if (t == 'date') {
      return 'date'
    } else if (t == 'number') {
      return 'number' 
    } else if (t == 'list' || t == 'custom') {
      return t
    } else {
      return null
    }
  }

  function updateAutoValues(k, v) {
    var o = autoValues
    o[k] = v 
    setAutoValues(o)
  }

  function getAutoOptions(context, k) {
    if (k in context.barleyInCustomObjects) {
      return Object.keys(context.barleyInCustomObjects[k]).map((key, id) => {
        return context.barleyInCustomObjects[k][key]
      })
    }
    return []
  }

  function renderContent(fields, touched, errors, handleBlur, handleChange, values, setFieldValue, context) {
    var columns = Math.floor(fields.length / 3)
    if (columns * 3 < fields.length) {
      columns += 1
    }
    var colrow = [...Array(columns).keys()]
    var rows = [0,1,2]
    return <div>
      {colrow.map((item, idx) => {
        return <div style={{display: 'flex', flexDirection: 'row'}}>
        {rows.map((inner, innerI) => {
          let fieldIdx = (3*item) + inner 

          if (fieldIdx < fields.length) {
            let tp = getType(fields[fieldIdx].fieldType)
            if (tp == 'custom') {

             return <Autocomplete
              value={autoValues[fields[fieldIdx].fieldKey]}
              fullWidth
              onChange={(event, newValue) => {
                if (typeof newValue === 'string') {
                  updateAutoValues(fields[fieldIdx].fieldKey, {name: newValue})
                  setFieldValue(fields[fieldIdx].fieldKey, {'customId': newValue.customId, 'name': newValue.name})

                } else if (newValue && newValue.inputValue) {
                  // Create a new value from the user input
                  updateAutoValues(fields[fieldIdx].fieldKey, {name: newValue.inputValue})
                  setFieldValue(fields[fieldIdx].fieldKey, {'customId':-1, 'name': newValue.inputValue})
                } else {
                  updateAutoValues(fields[fieldIdx].fieldKey, newValue)
                  if (newValue == null) {
                    setFieldValue(fields[fieldIdx].fieldKey, {'customId': '0', 'name': 'None'})
                  } else {
                    setFieldValue(fields[fieldIdx].fieldKey, {'customId': newValue.customId, 'name': newValue.name})
                  }
                }
                //console.log(event.target.value)
                //console.log(newValue)
              }}
              filterOptions={(options, params) => {
                const filtered = filter(options, params);
        
                // Suggest the creation of a new value
                if (params.inputValue !== '') {
                  filtered.push({
                    inputValue: params.inputValue,
                    name: `Add "${params.inputValue}"`,
                  });
                }
        
                return filtered;
              }}
              selectOnFocus
              clearOnBlur
              handleHomeEndKeys
              id="free-solo-with-text-demo"
              options={getAutoOptions(context, fields[fieldIdx].fieldKey)}
              getOptionLabel={(option) => {
                // Value selected with enter, right from the input
                if (typeof option === 'string') {
                  return option;
                }
                // Add "xxx" option created dynamically
                if (option.inputValue) {
                  return option.inputValue;
                }
                // Regular option
                return option.name;
              }}
              renderOption={(option) => option.name}
              freeSolo
              renderInput={(params) => (
                <TextField {...params}  error={Boolean(touched[fields[fieldIdx].fieldKey] && errors[fields[fieldIdx].fieldKey])}
                fullWidth
                id={fields[fieldIdx].fieldKey}
                helperText={touched[fields[fieldIdx].fieldKey] && errors[fields[fieldIdx].fieldKey]}
                label={fields[fieldIdx].fieldName}
                margin="normal"
                name={fields[fieldIdx].fieldKey}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values[fields[fieldIdx].fieldKey]}
                InputLabelProps={{
                  shrink: true,
                  }}
                variant="outlined"/>
              )}
            />



            } else if (tp == 'list') {
              let opts = fields[fieldIdx].listOptions
              return <TextField
              select 
              error={Boolean(touched[fields[fieldIdx].fieldKey] && errors[fields[fieldIdx].fieldKey])}
              fullWidth
              id={fields[fieldIdx].fieldKey}
              helperText={touched[fields[fieldIdx].fieldKey] && errors[fields[fieldIdx].fieldKey]}
              label={fields[fieldIdx].fieldName}
              margin="normal"
              name={fields[fieldIdx].fieldKey}
              onBlur={handleBlur}
              onChange={handleChange}
              value={values[fields[fieldIdx].fieldKey]}
              InputLabelProps={{
                shrink: true,
                }}
              variant="outlined">
              {opts.map((item, idx) => {
                return (<MenuItem value={item}>{item}</MenuItem>)
              })}
            </TextField>
            } else {
              return <TextField
              error={Boolean(touched[fields[fieldIdx].fieldKey] && errors[fields[fieldIdx].fieldKey])}
              fullWidth
              id={fields[fieldIdx].fieldKey}
              helperText={touched[fields[fieldIdx].fieldKey] && errors[fields[fieldIdx].fieldKey]}
              label={fields[fieldIdx].fieldName}
              margin="normal"
              name={fields[fieldIdx].fieldKey}
              onBlur={handleBlur}
              onChange={handleChange}
              value={values[fields[fieldIdx].fieldKey]}
              variant="outlined"
              type={getType(fields[fieldIdx].fieldType)}
              InputLabelProps={{
                shrink: true,
                }}
            />
            }

          }
          
          return null 

        })}
       </div>
      })}
    </div>
  }


  async function doSubmit(v) {
    /*if (!v.supplierid) {
      alert('Must specify a supplier id.')
      return
    }*/
   
    setLoading(true)
    let transaction = await Firebase.createSupplier({'values': v, 'fields': props.fields})
    Firebase.trackMixpanel('createSupplierAdhocOnline', {'result': transaction})
    setLoading(false)
    if (transaction && transaction.transaction == null && transaction.failureMessage) {
      alert(transaction.failureMessage)
    } else {
      props.onConfirm()
    }
    console.log(transaction)

  }

  return (

      <div>
 
            <Dialog
            open={props.open}
            onClose={() => props.onClose()}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >

            <DialogTitle id="alert-dialog-title">{props.alertTitle}</DialogTitle>
            <DialogContent>   
            <Formik
        id='f2'
          initialValues={{

          }}
          validationSchema={
            Yup.object().shape({

            })
          }
          onSubmit={(values) => {
            doSubmit(values)
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting2,
            setSubmitting, 
            touched,
            values,
            setFieldValue
          }) => (
            <form id='fu2' onSubmit={handleSubmit}>
                  <div id='fkx1' style={{marginTop: 5}}>

          
             {renderContent(props.fields.filter((item, idx) => {
               return item.fieldKey != 'grn' && item.fieldKey && item.fieldKey in necessaryFields
             }), touched, errors, handleBlur, handleChange, values, setFieldValue, props.context)}
</div>
              <Box my={2}>
                <Button
                  color="primary"
                  style={{margin: 5}}
                  disabled={loading}
                  fullWidth
                  size="large"
                  id="sendButtonNext"
                  variant="contained"
                  onClick={() => doSubmit(values)}
                >
                  Confirm
                </Button>
                <Button
                  color="primary"
                  style={{margin: 5}}
                  disabled={loading}
                  fullWidth
                  size="large"
                  id="sendButtonNext"
                  variant="contained"
                  onClick={() => props.onClose()}
                >
                  Cancel
                </Button>
              </Box>

              {loading ? <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: 50}}><CircularProgress/></div> : null}

              
            </form>
          )}
        </Formik>
            </DialogContent>
            <DialogActions>

            </DialogActions>
          </Dialog>

        </div>
        )

    };

/*   <div onClick={() => this.adjustSortDescending()} style={{ margin: 0, display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: this.state.sortDescending ? 'orange' : 'white', border: this.state.sortDescending ? '' : '1px solid black', borderRadius: 30, padding: 0, height: 30, padding: 5 }}>
                                <p>Descending</p>
                            </div>*/

/*
 * 
 * 
 *             */

export default AddSupplierDialog;