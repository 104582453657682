import React, { useState } from 'react';
import { Link as RouterLink, useNavigate, useLocation} from 'react-router-dom';
import { Document, Page, pdfjs } from 'react-pdf';
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Firebase from 'src/Firebase'
import PageTwo from 'src/components/Page';
import TopBar from 'src/layouts/DashboardLayout/TopBar'
import GarriLoadingScreen from 'src/GarriLoadingScreen';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const ViewInvoiceFast = () =>  {
  const [numPages, setNumPages] = useState(0);
  const [isLoading, setIsLoading] = useState(true)
  const [pageNumber, setPageNumber] = useState(1);
  const [loadedPrep, setLoadedPrep] = useState(false);
  const [invoiceText, setInvoiceText] = useState('Garri Logistics Invoice')
  const [pdfLink, setPdfLink] = useState('');
  const [noAccess, setNoAccess] = useState(false)
  const state = useLocation();
  
  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setIsLoading(false)
  }

  function isNumeric(n) {
    return !isNaN(parseFloat(n)) && isFinite(n);
  }
  
  async function badMethod(targetJoba) {

    var targetJob = targetJoba 


 // First, check to see if there is a hyphen, for X-XXX format
 let hyphensplit = targetJob.split('-')
 console.log('hyphensplit')
 console.log(hyphensplit)
 var gotHyphen = false 
 if (hyphensplit.length > 1) {
   let hyphen_a = hyphensplit[0]
   let hyphen_b = +hyphensplit[1]
   console.log(hyphen_a)
   console.log(hyphen_b)
   let shipperUid = await Firebase.database.ref('mapInternalCodeToUid/' + hyphen_a).once('value').then((data) => {
     if (!data.exists()) {
         return null
     } else {
         return data.val()
     }
   }).catch((error) => {
       return null 
   })
   console.log(shipperUid)
   if (shipperUid != null && shipperUid == Firebase.uid) {
     let quoteIdVal = await Firebase.database.ref('mapShortIdToInvoiceIdShipper/' + shipperUid + '/' + hyphen_b).once('value').then((data) => {
       if (!data.exists()) {
           return null
       } else {
           return data.val()
       }
     }).catch((error) => {
         return null 
     })
     console.log(quoteIdVal)
     if (quoteIdVal != null) {
       gotHyphen = true 
       targetJob = quoteIdVal
     }
   } else {
    setNoAccess(true)
    return 
   }

   if (targetJob == targetJoba ) {
       setNoAccess(true)
   } else {
    Firebase.database.ref('invoices/' + targetJob).on("value", (inner_snapshot) => {
        if (inner_snapshot.exists() && inner_snapshot.val().pdflink) {
            setPdfLink(inner_snapshot.val().pdflink)
            setLoadedPrep(true)
            setInvoiceText('Garri Logistics Invoice ' + targetJoba)
        }
  
    }, function (errorObject) {
        console.log("The read failed: " + errorObject.code);
    });
   }
 } else {
     setNoAccess(true)
 }
  }

  React.useEffect(() => {
    let targetJob = state.pathname.split('/')[2]
    if (!Firebase.database) {
        console.log('yes we are here')
        Firebase.awfulStuff.push(() => badMethod(targetJob))
    } else {
     console.log('here is ' + targetJob)
        badMethod(targetJob)
    }
  }, []);

if (noAccess) {
    return <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
        <a>Either you do not have access to this invoice, or it does not exist.</a>
    </div>
}

  return (
      <div>
      {loadedPrep ?
       <PageTwo title={invoiceText}>
       <TopBar />
       <div style={{marginTop: 100, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
<Button
            color="primary"
            variant="contained"
            style={{marginTop: 5, marginBottom: 5}}
            onClick={() => window.open(pdfLink)}
          >
            Export PDF
          </Button>
          
          <Document
          file={pdfLink}
          onLoadSuccess={onDocumentLoadSuccess}
        >

{isLoading ? 
          <div style={{flexDirection: 'column', display: 'flex'}}>
              <a>Loading PDF Invoice...</a>
              <CircularProgress/>
          </div>
          : 
            <div>{[...Array(numPages).keys()].map((item, idx) => {
                return (
                <div>
   <Page pageNumber={idx + 1} />
   <p>Page {idx + 1} of {numPages}</p>
                </div>
               
                  
                  )
            })}</div>
           
        }
          
        </Document>
    </div>
    </PageTwo>
        :<GarriLoadingScreen/>}
    </div>
  );
}

export default ViewInvoiceFast