import React from 'react';
import {useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Button,
  Card,
  CardContent,
  TextField,
  InputAdornment,
  SvgIcon,
  makeStyles
} from '@material-ui/core';
import { Search as SearchIcon } from 'react-feather';
import MyContext from 'src/MyContext'
import * as XLSX from 'xlsx';
import moment from 'moment'

const useStyles = makeStyles((theme) => ({
  root: {},
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  }
}));



const Toolbar = ({ className, ...rest }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const inputRef = React.useRef(null);


  function importPayments() {

  }

  function processForKey(k,v) {
    if (k=='deductOnePercent') {
      return (v != null && ('' + v).length > 0)
    }
    if (k=='deliveryDate') {
      if (v.split('.').length != 3) {
        return null
      } else {
        return moment(v, 'DD.MM.YY').valueOf()

      }
    }
    if (k=='plate') {
      if (v.split('/').length != 2) {
        return null 
      } else {
        var splt = v.split('/')
        return {'main': 'ET-3-' + splt[0], 'trl': 'ET-3-' + splt[1]}
      }
    }
    return v
  }

  function getFieldValue(s) {
    if (s.toLowerCase() == 'name') {
      return 'name'
    }
    if (s.toLowerCase().includes('acc')) {
      return 'accountNumber'
    } 
    if (s.toLowerCase() == 'rate') {
      return 'rate'
    } 
    if (s.includes('1%')) {
      return 'deductOnePercent'
    }
    if (s.toLowerCase().includes('deduction')) {
      return 'deductExtra'
    } 
    if (s.toLowerCase().includes('net')) {
      return 'netPay'
    }
    if (s.toLowerCase().includes('amount')) {
      return 'amount'
    }
    if (s.toLowerCase().includes('date')) {
      return 'deliveryDate'
    }
    if (s.toLowerCase().includes('plate')) {
      return 'plate'
    }
    if (s.toLowerCase().includes('weight')) {
      return 'totalWeight'
    }
    return null 
  }

  function shouldPushJob(jobDict) {
    if (Object.keys(jobDict).length < 2) {
      return false
    }
    if (Object.keys(jobDict).length <= 2 && jobDict['name'] == 'Total') {
      return false 
    }
    return true 
  }

  function prepParsedDataForUsage(data) {
    var finalSheets = []
    var filtered = data.filter((sheet, idx) => {
      // examine this sheet. first look at its title columns to see if it is valid

      var titleRow = sheet.sheetValue[0]
      titleRow = titleRow.map((titleKey, idx) => {
        return getFieldValue(titleKey)
      })
      var tlen = titleRow.filter((titleKey, idx) => {
        return titleKey != null
      }).length 
      console.log('here is data for filter')
      console.log(titleRow)
      console.log(tlen)
      return tlen > 3
    })
    console.log(filtered)
    var mapped = filtered.map((item, idx) => {
      var setValue = item 
      var rows = item.sheetValue
      var idxOfKeyDict = {}
      rows = rows.map((row, idxInner) => {
        var newRow = row 
        if (idxInner == 0) {
          newRow = row.map((rowItem, rowIdxInner) => {
            
            let newKey = getFieldValue(rowItem)


            idxOfKeyDict[rowIdxInner] = newKey 

            return newKey
          })
        } else {
          newRow = row.map((rowItem, rowIdxInner) => {
            return processForKey(idxOfKeyDict[rowIdxInner], rowItem)
          })
        }
        return newRow
      })


      // now we have to convert mapped to a set of jobs for the sheet, i.e. [{recip: x, jobs: [j1, j2]}]
      var idxReverseDict = {}
      var activeRecipient = null 
      var activeRecipientAccount = null 
      var recipientJobs = []
      var baseSet = []
      rows.map((row, idxInner) => {
        var newRow = row
        if (idxInner == 0) {
          newRow.map((rowItem, rowIdxInner) => {
            idxOfKeyDict[rowIdxInner] = rowItem
            if (rowItem != null) {
              idxReverseDict[rowItem] = rowIdxInner
            }
          })
        } else {
          // check if this row has a name
          if ('name' in idxReverseDict) {
            let nameIdx = idxReverseDict['name']
            let name = newRow[nameIdx]
            let accountIdx = idxReverseDict['accountNumber']
            let account = newRow[accountIdx]
            //console.log(name)
            //console.log('therename')
            if (name == null) {
              if (activeRecipient != null) {
                // no name, if there is an active job, just append to that
                // get the job for this row
                var jobDict = {}
                newRow.map((rowItem, rowIdxInner) => {
                  if (rowIdxInner in idxOfKeyDict) {
                    if (rowItem != null) {
                      jobDict[idxOfKeyDict[rowIdxInner]] = rowItem
                    }

                  }
                })
                if (shouldPushJob(jobDict)) {
                  recipientJobs.push(jobDict)
                }
                //console.log('recipients')
                //console.log(recipientJobs)
              } 
            } else {
              if (activeRecipient != null) {
                // this is not the first recipient
                baseSet.push({'recipient': activeRecipient, 'account': activeRecipientAccount, 'jobs': recipientJobs})
                //console.log(baseSet)
                //console.log('base')
                
                recipientJobs = []
                
              } 
              activeRecipientAccount = account 
                activeRecipient = name
              var jobDict = {}
                newRow.map((rowItem, rowIdxInner) => {
                  if (rowIdxInner in idxOfKeyDict) {
                    if (rowItem != null) {
                      jobDict[idxOfKeyDict[rowIdxInner]] = rowItem
                    }
                  }
                })
                if (shouldPushJob(jobDict)) {
                  recipientJobs.push(jobDict)
                }

            }
          } else {
            // don't do anything if there is somehow no name field in this sheet
          }


        }
      })
      if (recipientJobs.length > 0 && activeRecipient != null) {
        baseSet.push({'recipient': activeRecipient, 'account': activeRecipientAccount, 'jobs': recipientJobs})
      }

      setValue.sheetValue = rows 
 
      setValue['trueSet'] = baseSet
      //console.log('2@@@@ ' + setValue.sheetName + '  ' + item.sheetName)
      return setValue
    })
    console.log(mapped)


    return mapped

  }

  async function handleSelectedFolder(e, docList, setFunction) {
    
    e.preventDefault();

    var files = e.target.files, f = files[0];
    var reader = new FileReader();
    reader.onload = function (e) {
        var data = e.target.result;
        let readedData = XLSX.read(data, {type: 'binary'});
        var parsedData = readedData.SheetNames.map((item, idx) => {
          const wsname = item//= readedData.SheetNames[0];
          const ws = readedData.Sheets[wsname];
  
          /* Convert array to json*/
          var dataParse = XLSX.utils.sheet_to_json(ws, {header:1}).filter((ini, idxi) => {
            return ini.length > 1
          })

          console.log(dataParse)
          return {'sheetName': wsname, 'sheetValue': dataParse.filter((col, idx) => {
            return col.length > 1
          })}
          
          //setFileUploaded(dataParse);
        })
        navigate('/app/newpaymentfromsheets', {state: {parsedData: prepParsedDataForUsage(parsedData)}})

    };
    reader.readAsBinaryString(f)
}

  return (
    <MyContext.Consumer>{context => (<div>{context.isAdmin ? <div
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Box
        display="flex"
        justifyContent="flex-end"
      >

<input id="myInputFolder" ref={inputRef} style={{ display: 'none' }} type="file"
accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" onChange={(e) => handleSelectedFolder(e)} />
        <Button
          color="primary"
          variant="contained"
          onClick={ () => {
            navigate('/app/newpayment');
          }}
        >
          New Payment
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={ () => {
           if (inputRef != null && inputRef.current != null) {
            inputRef.current.click()
           }
          }}
        >
          Import Payment(s)
        </Button>
      </Box>
     
    </div> : <div/>}</div> )}</MyContext.Consumer>
  );
};

Toolbar.propTypes = {
  className: PropTypes.string
};

export default Toolbar;
