import React, { PropTypes, Component } from 'react';
import ReactDOM from 'react-dom';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import { CircularProgress, Typography } from '@material-ui/core';


class GarriLoadingScreen extends Component {


    static defaultProps = {};


    constructor(props) {
        super(props);
    }

    render() {

        return  <div style={{backgroundColor: 'white', alignItems: 'center', justifyContent: 'center', display: 'flex', height: '100vh', flexDirection: 'column'}}>
                <img
      alt="Logo"
      src="/static/garri-logo-light.jpg"
      style={{marginBottom: 5}}
    />
            <CircularProgress/>
            </div>
    }
}


export default GarriLoadingScreen;