import React, { useState, useEffect } from 'react';
import { Link as RouterLink, useNavigate, useLocation} from 'react-router-dom';
import {
  Box,
  Container,
  Typography, 
  Button,
  Link,
  makeStyles
} from '@material-ui/core';
import Page from 'src/components/Page';
import CheckCircle from '@material-ui/icons/CheckCircle';
import MyContext from 'src/MyContext'
import UserDriversWrapper from '../user/UserListView/UserDriversWrapper'

import Firebase from 'src/Firebase.js'


const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));


const UserSummaryView = (props) => {
  const classes = useStyles();

  const navigate = useNavigate();
  const {state} = useLocation();
  useEffect(() => {
    // Update the document title using the browser API

    
  });

  function goManage(id, jobs) {
    var customer = null 
    jobs.map((item, idx) => {
      if (item.shortId == id) {
        customer = item 
      }
    })
    if (customer != null) {
      let startLat = customer.startPos.lat 
        let endLat = customer.endPos.lat 
        let startLng = customer.startPos.lng 
        let endLng = customer.endPos.lng 

        let cx = startLat + ((endLat - startLat) / 2)
        let cy = startLng + ((endLng - startLng) / 2)

        navigate('/app/editjobadmin', {state: {customer: customer, centerx: cx, centery: cy}, replace: true})
    } else {
      alert('Try again in a moment.')
    }
  }

  function padDigits(number) {
    return Array(Math.max(3 - String(number).length + 1, 0)).join(0) + number;
  }

  function navigaterFunc(path, props) {
    //alert('attempt navigate')
    navigate(path, props)
  }

  function renderUserName(v) {
    var rets = ''
    if ('firstName' in v) {
      rets += v.firstName
    } 
    if ('middleName' in v) {
      rets += ' ' + v.middleName
    } 
    if ('lastName' in v) {
      rets += ' ' + v.lastName
    }
    return rets
  }

  function prepVehiclePlateDict(users) {
    var pdict = {}
    users.map((item, idx) => {
      pdict[item.plate] = item 
    })
    return pdict

  }


  function prepUserPhoneDict(users) {
    var pdict = {}
    users.map((item, idx) => {
      pdict[item.phone] = item 
      if (item.secondaryPhones && item.secondaryPhones.length > 0) {
        item.secondaryPhones.map((inner, inidx) => {
          pdict[inner] = item 
        })
      }
    })
    return pdict
  }

  function prepAssociationOption(customer, assocs) {
    var ret = null 
    if (customer.associationId == null || customer.associationId == '0' || customer.associationId == 'undefined' || customer.associationId.length < 10) {
      return null 
    }

    assocs.map((item, idx) => {
      if (item.associationId == customer.associationId) {
        ret = {'associationId': item.associationId, 'companyName': item.companyName}
      }
    })

    return ret

  }

  function addDriver(customer, users, assocs) {
    navigate('/app/newuser', {state: {'customer': customer, 'fromFleet': true, 'phoneDict': prepUserPhoneDict(users), 'assoc': prepAssociationOption(customer, assocs)}})
  }

  function addVehicle(customer, vehicles, assocs) {
    navigate('/app/newvehicle', {state: {'customer': customer, 'fromFleet': true, 'plateDict': prepVehiclePlateDict(vehicles),'assoc': prepAssociationOption(customer, assocs) }});
  }

  return (
    <MyContext.Consumer>{context => (
    <Page
      className={classes.root}
      title="Jobs"
    >
      <Container maxWidth={false} style={{alignItems: 'center', flexDirection: 'column', justifyContent: 'center', display: 'flex'}}>
        
        <CheckCircle style={{color: 'green', width: 200, height: 200}}/>
        <a style={{fontSize: 24, fontWeight: 'bold'}}>Operation Complete!</a>
        <Typography variant="body2" color="textSecondary">
                {'New User Created: ' + renderUserName(state.customer)}
              </Typography>
              <a style={{fontSize: 24, fontWeight: 'bold'}}></a>
              <Link
              to={state.customer}
              onClick={ () => addDriver(state.customer, context.users, context.associations)}
              variant="h6"
            >{'Add User'}</Link>
                          <Link
              to={state.customer}
              onClick={ () => addVehicle(state.customer, context.vehicles, context.associations)}
              variant="h6"
            >{'Add Vehicle'}</Link>
                            <Button
          color="primary"
          onClick={() => navigate(-2)}
          size="large"
          id="livetrackbutton"
          type="awjeifj"
          variant="contained"
          style={{marginTop: 15}}
        >
          {'Continue'}
        </Button>

        <div>
                 <Box mb={3}>
                <Typography
                  color="textPrimary"
                  variant="h2"
                >
                  Driver Management (User Fleet)
                </Typography>
              </Box>
              <UserDriversWrapper targetUid={state.customer.userId}/>
              </div>

      </Container>
    </Page>)}</MyContext.Consumer>
  );
};

export default UserSummaryView;
