import React, {Component} from 'react';
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import TextField from "@material-ui/core/TextField";
import Help from '@material-ui/icons/Help';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import Firebase from 'src/Firebase';
import { CircularProgress } from '@material-ui/core';


class SendUpdate extends Component {


    static defaultProps = {
        headerColor: 'black',
        bodyColor: 'black',
    };

    constructor(props) {
        super(props);
        this.state = {
          notificationBody: '',
          updateLocation: false ,
          loading: false
        }

    }

    componentDidMount() {
        //alert(this.props.defaultValue)
    }

    tryConfirm() {
      if (this.state.updateLocation && (this.props.mapSelection == null || this.props.mapSelection.lat == null || this.props.mapSelection.lng == null)) {
        alert('If you want to send a location update, please click the map to choose the new location.')
        return 
      }
      if (this.state.updateLocation && (this.props.valuesProp.assignedDriver == null || this.props.valuesProp.assignedDriver == '0')) {
        alert('No driver is assigned to this job, so you cannot provide a driver location update.')
        return 
      }
      this.setState({
        loading: true 
      })

      let self = this 
      let success = () => {
        self.setState({
          loading: false
        })
        this.onClose()
        alert('Update sent!')

      } 
      let fail = () => {
        self.setState({
          loading: false
        })
        alert('Error sending update. Check your internet connection.')
      }

      var datsent = { 'quote': this.props.quoteProp}
      if (this.state.notificationBody != '') {
        datsent['message'] = this.state.notificationBody
      }
      if (this.state.updateLocation && this.props.mapSelection != null && this.props.valuesProp.assignedDriver != null && this.props.valuesProp.assignedDriver != '0') {
        datsent['updateLocation'] = true 
        datsent['locationVal'] = this.props.mapSelection
        datsent['driver'] = this.props.valuesProp.assignedDriver
      }
      Firebase.adminSendStatus(datsent, () => success(), () => fail())
      //alert(this.props.valuesProp.assignedDriver)
      //this.onClose()
    }

    onClose() {
      this.props.onClose()
    }

    getCityName(values, key, defval) {
      if (key in values) {
        let splitlist = values[key].split(',')
        if (splitlist.length >= 2) {
          return splitlist[splitlist.length - 2]
        } else {
          return defval
        }
      } else {
        return defval
      }
    }

    getAutoOptions(values) {
      if (!values ) {
        return []
      }
      let cityNameDest = this.getCityName(values, 'dropoffLocation', 'destination').trim()
      let cityNameOrigin = this.getCityName(values, 'pickupLocation', 'origin').trim()
      return ['Truck departed from ' + cityNameOrigin + '.',
      'Truck waiting for unloading at ' + cityNameDest + '.',
      'Truck arrived in ' + cityNameDest + '.',
      'The trip has been delayed.',
      'The trip has been delayed more than expected.',
      'Driver plans to arrive in ' + cityNameDest + ' tonight.',
      'Truck waiting for loading in ' + cityNameOrigin + '.',
      'Recurring mechanical problem, mechanic has been sent.',
      'Truck facing mechanical problem.',
      'Truck waiting for loading the whole day, unloaded for loader absence. Loading planned for next day.',
      'The trip has been delayed due to a truck issue.',
    ]

    }

   

    render() {
      if (this.state.loading) {
        return <CircularProgress/>
      }
        return (
            <div>
                   <Autocomplete
        id="free-solo-demo"
        freeSolo
        options={this.getAutoOptions(this.props.valuesProp)}
        onChange={(event, newValue) => {
          if (typeof newValue === 'string') {
            this.setState({
              notificationBody: newValue
            })
          } 
        }}
        renderInput={(params) => ( 
          <TextField
          {...params}
            autoFocus
            margin="dense"
            id="message"
            label="Message Text"
            fullWidth
            value={this.state.notificationBody}
            onChange={(e) => {
              this.setState({
                notificationBody: e.target.value
              })
            }}
          />
                )}
      />
            <Box
        alignItems="center"
        display="flex"
        ml={-1}
      >
        <Checkbox
        id="updateLocation"
          checked={this.state.updateLocation}
          name="updateLocation"
          onChange={(e) => this.setState({updateLocation: !this.state.updateLocation})}
        />
        <Typography
          color="textPrimary"
          variant="body1"
        >
          Update Driver Location
        </Typography>
        <Tooltip placement="right-end" title="If you check this box, the driver location will be updated with the location you select on the map.">
                                <Help />
                            </Tooltip>
      </Box>
          <Button onClick={() => this.tryConfirm()} color="primary">
                {'Confirm Update'}
              </Button>
              <Button onClick={() => this.onClose()} color="primary" autoFocus>
                {'Cancel Update'}
              </Button>
</div>
        )

    }
}
const headerStyle = {
    backgroundColor: 'rgb(67,67,67)', flexWrap: 'never', whiteSpace: 'noWrap'
}

/*   <div onClick={() => this.adjustSortDescending()} style={{ margin: 0, display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: this.state.sortDescending ? 'orange' : 'white', border: this.state.sortDescending ? '' : '1px solid black', borderRadius: 30, padding: 0, height: 30, padding: 5 }}>
                                <p>Descending</p>
                            </div>*/

/*
 * 
 * 
 *             */

export default SendUpdate;