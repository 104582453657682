import React, {Component} from 'react';
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";


class ContractDetails extends Component {


    static defaultProps = {
        headerColor: 'black',
        bodyColor: 'black',
    };

    constructor(props) {
        super(props);
        this.state = {

        }

    }

    componentDidMount() {
        //alert(this.props.defaultValue)
    }

   

    render() {
        return (
            <div>
            <a style={{fontSize: 24, fontWeight: 'bold'}}>{this.props.isFreightOrder ? "Freight Order Details" : "Contract Details"}</a>
<div style={{flexDirection: 'row', display: 'flex'}}>
<Typography
          color="textPrimary"
          variant="body1"
        >
          {this.props.isFreightOrder ? "Order Runs From " : "Contract Runs From "}
        </Typography>
        <Typography
          color="textPrimary"
          variant="body1"
          fontWeight="fontWeightBold"
        >
          <Box style={{marginLeft: 5, marginRight: 5}} fontWeight="fontWeightBold">{" " + this.props.startDate + " "}</Box>

        </Typography>
        <Typography
          color="textPrimary"
          variant="body1"
        >
          {" to "}
        </Typography>
        <Typography
          color="textPrimary"
          variant="body1"
          fontWeight="fontWeightBold"
        >
          <Box style={{marginLeft: 5, marginRight: 5}} fontWeight="fontWeightBold">{this.props.endDate}</Box>

        </Typography>

</div>
<div style={{flexDirection: 'row', display: 'flex'}}>
        <Typography
          color="textPrimary"
          variant="body1"
        >
          Used Balance is 
        </Typography>
        <Typography
          color="textPrimary"
          variant="body1"
          fontWeight="fontWeightBold"
        >
          <Box style={{marginLeft: 5, marginRight: 5}} fontWeight="fontWeightBold">{" " + this.props.used + " / " + this.props.total + " quintals"}</Box>

        </Typography>
        </div>
        <div style={{flexDirection: 'row', display: 'flex'}}>
        <Typography
          color="textPrimary"
          variant="body1"
        >
          Remaining Balance is
        </Typography>
        <Typography
          color="textPrimary"
          variant="body1"
          fontWeight="fontWeightBold"
        >
          <Box style={{marginLeft: 5, marginRight: 5}} fontWeight="fontWeightBold">{" " + this.props.remaining + " / " + this.props.total + " quintals"}</Box>

        </Typography>
        </div>
</div>
        )

    }
}
const headerStyle = {
    backgroundColor: 'rgb(67,67,67)', flexWrap: 'never', whiteSpace: 'noWrap'
}

/*   <div onClick={() => this.adjustSortDescending()} style={{ margin: 0, display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: this.state.sortDescending ? 'orange' : 'white', border: this.state.sortDescending ? '' : '1px solid black', borderRadius: 30, padding: 0, height: 30, padding: 5 }}>
                                <p>Descending</p>
                            </div>*/

/*
 * 
 * 
 *             */

export default ContractDetails;