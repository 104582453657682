import React, { Component } from "react";
// nodejs library that concatenates classes

// @material-ui/core components
import { makeStyles, withStyles } from "@material-ui/core/styles";

// @material-ui/icons

// core components

//const useStyles = makeStyles(styles);
//const classes = makeStyles(styles);


class PrivacyPolicy extends Component {

    


    constructor() {
        super();

        
  
    }

    componentDidMount() {
        
    }

    componentWillUnmount() {
        
    }




    render() {

        const { classes } = this.props
        return (
            <div>

                <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                    <div style={styles.stylepriv1}>GARRI LOGISTICS PRIVACY POLICY</div>
                    <div style={styles.stylepriv2}>Effective date: 01/02/2021</div>
                    </div>

            <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'center', flexDirection: 'column' }}>

                <div style={styles.stylepriv2}>1.	Introduction</div>
                <div style={styles.stylepriv3}>Welcome to Garri Logistics.</div>
                <div style={styles.stylepriv3}>Garri ("us", "we", or "our") operates www.garrilogistics.com (hereinafter referred to as "Service").</div>
                <div style={styles.stylepriv3}>Our Privacy Policy governs your visit to www.garrilogistics.com, and explains how we collect, safeguard and disclose information that results from your use of our Service. </div>
                <div style={styles.stylepriv3}>We use your data to provide and improve Service. By using Service, you agree to the collection and use of information in accordance with this policy. Unless otherwise defined in this Privacy Policy, the terms used in this Privacy Policy have the same meanings as in our Terms and Conditions.</div>
                <div style={styles.stylepriv3}>Our Terms and Conditions ("Terms") govern all use of our Service and together with the Privacy Policy constitutes your agreement with us ("agreement").</div>
                <div style={styles.stylepriv2}>2.	Definitions</div>
                <div style={styles.stylepriv3}>SERVICE means the www.garrilogistics.com website operated by Garri.</div>
                <div style={styles.stylepriv3}>PERSONAL DATA means data about a living individual who can be identified from those data (or from those and other information either in our possession or likely to come into our possession).</div>
                <div style={styles.stylepriv3}>USAGE DATA is data collected automatically either generated by the use of Service or from Service infrastructure itself (for example, the duration of a page visit).</div>
                <div style={styles.stylepriv3}>COOKIES are small files stored on your device (computer or mobile device).</div>
                <div style={styles.stylepriv3}>DATA CONTROLLER means a natural or legal person who (either alone or jointly or in common with other persons) determines the purposes for which and the manner in which any personal data are, or are to be, processed. For the purpose of this Privacy Policy, we are a Data Controller of your data.</div>
                <div style={styles.stylepriv3}>DATA PROCESSORS (OR SERVICE PROVIDERS) means any natural or legal person who processes the data on behalf of the Data Controller. We may use the services of various Service Providers in order to process your data more effectively.</div>
                <div style={styles.stylepriv3}>DATA SUBJECT is any living individual who is the subject of Personal Data.</div>
                <div style={styles.stylepriv3}>THE USER is the individual using our Service. The User corresponds to the Data Subject, who is the subject of Personal Data.</div>
                <div style={styles.stylepriv2}>3.	Information Collection and Use</div>
                <div style={styles.stylepriv3}>We collect several different types of information for various purposes to provide and improve our Service to you.</div>
                <div style={styles.stylepriv2}>4.	Types of Data Collected</div>
                <div style={styles.stylepriv3}>Personal Data</div>
                <div style={styles.stylepriv3}>While using our Service, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you ("Personal Data"). Personally identifiable information may include, but is not limited to:</div>
                <div style={styles.stylepriv3}>(a)	Email address</div>
                <div style={styles.stylepriv3}>(b)	First name and last name</div>
                <div style={styles.stylepriv3}>(c)	Address, State, Province, ZIP/Postal code, City</div>
                <div style={styles.stylepriv3}>(d)	Phone Number</div>
                <div style={styles.stylepriv3}>We may use your Personal Data to contact you with newsletters, marketing or promotional materials and other information that may be of interest to you. You may opt out of receiving any, or all, of these communications from us by emailing at info@garrilogistics.com.</div>
                <div style={styles.stylepriv3}>Usage Data</div>
                <div style={styles.stylepriv3}>We may also collect information that your browser sends whenever you visit our Service or when you access Service by or through a mobile device ("Usage Data").</div>
                <div style={styles.stylepriv3}>This Usage Data may include information such as your computer's Internet Protocol address (e.g. IP address), browser type, browser version, the pages of our Service that you visit, the time and date of your visit, the time spent on those pages, unique device identifiers and other diagnostic data.</div>
                <div style={styles.stylepriv3}>When you access Service with a mobile device, this Usage Data may include information such as the type of mobile device you use, your mobile device unique ID, the IP address of your mobile device, your mobile operating system, the type of mobile Internet browser you use, unique device identifiers and other diagnostic data.</div>
                <div style={styles.stylepriv3}>Tracking Cookies Data</div>
                <div style={styles.stylepriv3}>We use cookies and similar tracking technologies to track the activity on our Service and we hold certain information.</div>
                <div style={styles.stylepriv3}>Cookies are files with a small amount of data which may include an anonymous unique identifier. Cookies are sent to your browser from a website and stored on your device. Other tracking technologies are also used such as beacons, tags and scripts to collect and track information and to improve and analyze our Service.</div>
                <div style={styles.stylepriv3}>You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you may not be able to use some portions of our Service.</div>
                <div style={styles.stylepriv3}>Examples of Cookies we use:</div>
                <div style={styles.stylepriv3}>(a)	Session Cookies: We use Session Cookies to operate our Service.</div>
                <div style={styles.stylepriv3}>(b)	Preference Cookies: We use Preference Cookies to remember your preferences and various settings.</div>
                <div style={styles.stylepriv3}>(c)	Security Cookies: We use Security Cookies for security purposes.</div>
                <div style={styles.stylepriv3}>(d)	Advertising Cookies: Advertising Cookies are used to serve you with advertisements that may be relevant to you and your interests.</div>
                <div style={styles.stylepriv2}>5.	Use of Data</div>
                <div style={styles.stylepriv3}>Garri uses the collected data for various purposes:</div>
                <div style={styles.stylepriv3}>(a)	to provide and maintain our Service;</div>
                <div style={styles.stylepriv3}>(b)	to notify you about changes to our Service; </div>
                <div style={styles.stylepriv3}>(c)	to allow you to participate in interactive features of our Service when you choose to do so; </div>
                <div style={styles.stylepriv3}>(d)	to provide customer support; </div>
                <div style={styles.stylepriv3}>(e)	to gather analysis or valuable information so that we can improve our Service; </div>
                <div style={styles.stylepriv3}>(f)	to monitor the usage of our Service;</div>
                <div style={styles.stylepriv3}>(g)	to detect, prevent and address technical issues;</div>
                <div style={styles.stylepriv3}>(h)	to fulfill any other purpose for which you provide it;</div>
                <div style={styles.stylepriv3}>(i)	to carry out our obligations and enforce our rights arising from any contracts entered into between you and us, including for billing and collection;</div>
                <div style={styles.stylepriv3}>(j)	to provide you with notices about your account and/or subscription, including expiration and renewal notices, email-instructions, etc.;</div>
                <div style={styles.stylepriv3}>(k)	to provide you with news, special offers and general information about other goods, services and events which we offer that are similar to those that you have already purchased or enquired about unless you have opted not to receive such information;</div>
                <div style={styles.stylepriv3}>(l)	in any other way we may describe when you provide the information;</div>
                <div style={styles.stylepriv3}>(m)	for any other purpose with your consent. </div>
                <div style={styles.stylepriv2}>6.	Retention of Data</div>
                <div style={styles.stylepriv3}>We will retain your Personal Data only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use your Personal Data to the extent necessary to comply with our legal obligations (for example, if we are required to retain your data to comply with applicable laws), resolve disputes, and enforce our legal agreements and policies.</div>
                <div style={styles.stylepriv3}>We will also retain Usage Data for internal analysis purposes. Usage Data is generally retained for a shorter period, except when this data is used to strengthen the security or to improve the functionality of our Service, or we are legally obligated to retain this data for longer time periods.</div>
                <div style={styles.stylepriv2}>7.	Transfer of Data</div>
                <div style={styles.stylepriv3}>Your information, including Personal Data, may be transferred to - and maintained on - computers located outside of your state, province, country or other governmental jurisdiction where the data protection laws may differ from those of your jurisdiction.</div>
                <div style={styles.stylepriv3}>If you are located outside United States and choose to provide information to us, please note that we transfer the data, including Personal Data, to United States and process it there.</div>
                <div style={styles.stylepriv3}>Your consent to this Privacy Policy followed by your submission of such information represents your agreement to that transfer.</div>
                <div style={styles.stylepriv3}>Garri will take all the steps reasonably necessary to ensure that your data is treated securely and in accordance with this Privacy Policy and no transfer of your Personal Data will take place to an organisation or a country unless there are adequate controls in place including the security of your data and other personal information.</div>
                <div style={styles.stylepriv2}>8.	Disclosure of Data</div>
                <div style={styles.stylepriv3}>We may disclose personal information that we collect, or you provide:</div>
                <div style={styles.stylepriv3}>(a)	Disclosure for Law Enforcement.</div>
                <div style={styles.stylepriv3}>Under certain circumstances, we may be required to disclose your Personal Data if required to do so by law or in response to valid requests by public authorities.</div>
                <div style={styles.stylepriv3}>(b)	Business Transaction.</div>
                <div style={styles.stylepriv3}>If we or our subsidiaries are involved in a merger, acquisition or asset sale, your Personal Data may be transferred.</div>
                <div style={styles.stylepriv3}>(c)	Other cases. We may disclose your information also:</div>
                <div style={styles.stylepriv3}>(i)	to our subsidiaries and affiliates;</div>
                <div style={styles.stylepriv3}>(ii)	to contractors, service providers, and other third parties we use to support our business;</div>
                <div style={styles.stylepriv3}>(iii)	to fulfill the purpose for which you provide it;</div>
                <div style={styles.stylepriv3}>(iv)	for the purpose of including your company's logo on our website;</div>
                <div style={styles.stylepriv3}>(v)	for any other purpose disclosed by us when you provide the information;</div>
                <div style={styles.stylepriv3}>(vi)	with your consent in any other cases;</div>
                <div style={styles.stylepriv3}>(vii)	if we believe disclosure is necessary or appropriate to protect the rights, property, or safety of the Company, our customers, or others.</div>
                <div style={styles.stylepriv2}>9.	Security of Data</div>
                <div style={styles.stylepriv3}>The security of your data is important to us but remember that no method of transmission over the Internet or method of electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your Personal Data, we cannot guarantee its absolute security.</div>
                <div style={styles.stylepriv2}>10.	Your Data Protection Rights Under General Data Protection Regulation (GDPR)</div>
                <div style={styles.stylepriv3}>If you are a resident of the European Union (EU) and European Economic Area (EEA), you have certain data protection rights, covered by GDPR. - See more at https://eur-lex.europa.eu/eli/reg/2016/679/oj </div>
                <div style={styles.stylepriv3}>We aim to take reasonable steps to allow you to correct, amend, delete, or limit the use of your Personal Data.</div>
                <div style={styles.stylepriv3}>If you wish to be informed what Personal Data we hold about you and if you want it to be removed from our systems, please email us at info@garrilogistics.com. </div>
                <div style={styles.stylepriv3}>In certain circumstances, you have the following data protection rights:</div>
                <div style={styles.stylepriv3}>(a)	the right to access, update or to delete the information we have on you;</div>
                <div style={styles.stylepriv3}>(b)	the right of rectification. You have the right to have your information rectified if that information is inaccurate or incomplete;</div>
                <div style={styles.stylepriv3}>(c)	the right to object. You have the right to object to our processing of your Personal Data;</div>
                <div style={styles.stylepriv3}>(d)	the right of restriction. You have the right to request that we restrict the processing of your personal information;</div>
                <div style={styles.stylepriv3}>(e)	the right to data portability. You have the right to be provided with a copy of your Personal Data in a structured, machine-readable and commonly used format;</div>
                <div style={styles.stylepriv3}>(f)	the right to withdraw consent. You also have the right to withdraw your consent at any time where we rely on your consent to process your personal information;</div>
                <div style={styles.stylepriv3}>Please note that we may ask you to verify your identity before responding to such requests. Please note, we may not able to provide Service without some necessary data.</div>
                <div style={styles.stylepriv3}>You have the right to complain to a Data Protection Authority about our collection and use of your Personal Data. For more information, please contact your local data protection authority in the European Economic Area (EEA).</div>
                <div style={styles.stylepriv2}>11.	Your Data Protection Rights under the California Privacy Protection Act (CalOPPA)</div>
                <div style={styles.stylepriv3}>CalOPPA is the first state law in the nation to require commercial websites and online services to post a privacy policy. The law's reach stretches well beyond California to require a person or company in the United States (and conceivable the world) that operates websites collecting personally identifiable information from California consumers to post a conspicuous privacy policy on its website stating exactly the information being collected and those individuals with whom it is being shared, and to comply with this policy. - See more at: https://consumercal.org/about-cfc/cfc-education-foundation/california-online-privacy-protection-act-caloppa-3/ </div>
                <div style={styles.stylepriv3}>According to CalOPPA we agree to the following:</div>
                <div style={styles.stylepriv3}>(a)	users can visit our site anonymously;</div>
                <div style={styles.stylepriv3}>(b)	our Privacy Policy link includes the word "Privacy", and can easily be found on the page specified above on the home page of our website;</div>
                <div style={styles.stylepriv3}>(c)	users will be notified of any privacy policy changes on our Privacy Policy Page;</div>
                <div style={styles.stylepriv3}>(d)	users are able to change their personal information by emailing us at info@garrilogistics.com. </div>
                <div style={styles.stylepriv3}>Our Policy on "Do Not Track" Signals:</div>
                <div style={styles.stylepriv3}>We honor Do Not Track signals and do not track, plant cookies, or use advertising when a Do Not Track browser mechanism is in place. Do Not Track is a preference you can set in your web browser to inform websites that you do not want to be tracked. </div>
                <div style={styles.stylepriv3}>You can enable or disable Do Not Track by visiting the Preferences or Settings page of your web browser.</div>
                <div style={styles.stylepriv2}>12.	Your Data Protection Rights under the California Consumer Privacy Act (CCPA)</div>
                <div style={styles.stylepriv3}>If you are a California resident, you are entitled to learn what data we collect about you, ask to delete your data and not to sell (share) it. To exercise your data protection rights, you can make certain requests and ask us:</div>
                <div style={styles.stylepriv3}>(a)	What personal information we have about you. If you make this request, we will return to you:</div>
                <div style={styles.stylepriv3}>(i)	The categories of personal information we have collected about you.</div>
                <div style={styles.stylepriv3}>(ii)	The categories of sources from which we collect your personal information.</div>
                <div style={styles.stylepriv3}>(iii)	The business or commercial purpose for collecting or selling your personal information.</div>
                <div style={styles.stylepriv3}>(iv)	The categories of third parties with whom we share personal information.</div>
                <div style={styles.stylepriv3}>(v)	The specific pieces of personal information we have collected about you.</div>
                <div style={styles.stylepriv3}>(vi)	A list of categories of personal information that we have sold, along with the category of any other company we sold it to. If we have not sold your personal information, we will inform you of that fact.</div>
                <div style={styles.stylepriv3}>(vii)	A list of categories of personal information that we have disclosed for a business purpose, along with the category of any other company we shared it with.</div>
                <div style={styles.stylepriv3}>Please note, you are entitled to ask us to provide you with this information up to two times in a rolling twelve-month period. When you make this request, the information provided may be limited to the personal information we collected about you in the previous 12 months.</div>
                <div style={styles.stylepriv3}>(b)	To delete your personal information. If you make this request, we will delete the personal information we hold about you as of the date of your request from our records and direct any service providers to do the same. In some cases, deletion may be accomplished through de-identification of the information. If you choose to delete your personal information, you may not be able to use certain functions that require your personal information to operate. </div>
                <div style={styles.stylepriv3}>(c)	To stop selling your personal information. We don't sell or rent your personal information to any third parties for any purpose. You are the only owner of your Personal Data and can request disclosure or deletion at any time.</div>
                <div style={styles.stylepriv3}>Please note, if you ask us to delete or stop selling your data, it may impact your experience with us, and you may not be able to participate in certain programs or membership services which require the usage of your personal information to function. But in no circumstances, we will discriminate against you for exercising your rights.</div>
                <div style={styles.stylepriv3}>To exercise your California data protection rights described above, please send your request(s) by one of the following means:</div>
                <div style={styles.stylepriv3}>By email: info@garrilogistics.com</div>
                <div style={styles.stylepriv3}>Your data protection rights, described above, are covered by the CCPA, short for the California Consumer Privacy Act. To find out more, visit the official California Legislative Information website. The CCPA took effect on 01/01/2020. </div>
                <div style={styles.stylepriv2}>13.	Service Providers</div>
                <div style={styles.stylepriv3}>We may employ third party companies and individuals to facilitate our Service ("Service Providers"), provide Service on our behalf, perform Service-related services or assist us in analysing how our Service is used.</div>
                <div style={styles.stylepriv3}>These third parties have access to your Personal Data only to perform these tasks on our behalf and are obligated not to disclose or use it for any other purpose.</div>
                <div style={styles.stylepriv2}>14.	Analytics</div>
                <div style={styles.stylepriv3}>We may use third-party Service Providers to monitor and analyze the use of our Service.</div>
                <div style={styles.stylepriv3}>Google Analytics</div>
                <div style={styles.stylepriv3}>Google Analytics is a web analytics service offered by Google that tracks and reports website traffic. Google uses the data collected to track and monitor the use of our Service. This data is shared with other Google services. Google may use the collected data to contextualise and personalise the ads of its own advertising network.</div>
                <div style={styles.stylepriv3}>For more information on the privacy practices of Google, please visit the Google Privacy Terms web page: https://policies.google.com/privacy?hl=en</div>
                <div style={styles.stylepriv3}>We also encourage you to review the Google's policy for safeguarding your data: https://support.google.com/analytics/answer/6004245.</div>
                <div style={styles.stylepriv3}>Firebase</div>
                <div style={styles.stylepriv3}>Firebase is analytics service provided by Google Inc.</div>
                <div style={styles.stylepriv3}>You may opt-out of certain Firebase features through your mobile device settings, such as your device advertising settings or by following the instructions provided by Google in their Privacy Policy: https://policies.google.com/privacy?hl=en</div>
                <div style={styles.stylepriv3}>For more information on what type of information Firebase collects, please visit the Google Privacy Terms web page: https://policies.google.com/privacy?hl=en</div>
                <div style={styles.stylepriv3}>Mixpanel</div>
                <div style={styles.stylepriv3}>Mixpanel is provided by Mixpanel Inc.</div>
                <div style={styles.stylepriv3}>You can prevent Mixpanel from using your information for analytics purposes by opting-out. To opt-out of Mixpanel service, please visit this page: https://mixpanel.com/optout/</div>
                <div style={styles.stylepriv3}>For more information on what type of information Mixpanel collects, please visit the Terms of Use page of Mixpanel: https://mixpanel.com/terms/</div>
                <div style={styles.stylepriv2}>15.	CI/CD tools</div>
                <div style={styles.stylepriv3}>We may use third-party Service Providers to automate the development process of our Service. </div>
                <div style={styles.stylepriv3}>GitHub</div>
                <div style={styles.stylepriv3}>GitHub is provided by GitHub, Inc.</div>
                <div style={styles.stylepriv3}>GitHub is a development platform to host and review code, manage projects, and build software.</div>
                <div style={styles.stylepriv3}>For more information on what data GitHub collects for what purpose and how the protection of the data is ensured, please visit GitHub Privacy Policy page: https://help.github.com/en/articles/github-privacy-statement.</div>
                <div style={styles.stylepriv2}>16.	Behavioral Remarketing</div>
                <div style={styles.stylepriv3}>Garri uses remarketing services to advertise on third party websites to you after you visited our Service. We and our third-party vendors use cookies to inform, optimise and serve ads based on your past visits to our Service.</div>
                <div style={styles.stylepriv3}>Google Ads (AdWords)</div>
                <div style={styles.stylepriv3}>Google Ads (AdWords) remarketing service is provided by Google Inc.</div>
                <div style={styles.stylepriv3}>You can opt-out of Google Analytics for Display Advertising and customize the Google Display Network ads by visiting the Google Ads Settings page: http://www.google.com/settings/ads</div>
                <div style={styles.stylepriv3}>Google also recommends installing the Google Analytics Opt-out Browser Add-on - https://tools.google.com/dlpage/gaoptout - for your web browser. Google Analytics Opt-out Browser Add-on provides visitors with the ability to prevent their data from being collected and used by Google Analytics.</div>
                <div style={styles.stylepriv3}>For more information on the privacy practices of Google, please visit the Google Privacy Terms web page: https://policies.google.com/privacy?hl=en</div>
                <div style={styles.stylepriv2}>17.	Payments</div>
                <div style={styles.stylepriv3}>We may provide paid products and/or services within Service. In that case, we use third-party services for payment processing (e.g. payment processors).</div>
                <div style={styles.stylepriv3}>We will not store or collect your payment card details. That information is provided directly to our third-party payment processors whose use of your personal information is governed by their Privacy Policy. These payment processors adhere to the standards set by PCI-DSS as managed by the PCI Security Standards Council, which is a joint effort of brands like Visa, Mastercard, American Express and Discover. PCI-DSS requirements help ensure the secure handling of payment information.</div>
                <div style={styles.stylepriv3}>The payment processors we work with are:</div>
                <div style={styles.stylepriv3}>Stripe:</div>
                <div style={styles.stylepriv3}>Their Privacy Policy can be viewed at: https://stripe.com/us/privacy</div>
                <div style={styles.stylepriv2}>18.	Links to Other Sites</div>
                <div style={styles.stylepriv3}>Our Service may contain links to other sites that are not operated by us. If you click a third party link, you will be directed to that third party's site. We strongly advise you to review the Privacy Policy of every site you visit.</div>
                <div style={styles.stylepriv3}>We have no control over and assume no responsibility for the content, privacy policies or practices of any third party sites or services.</div>
                <div style={styles.stylepriv2}>19.	Children's Privacy</div>
                <div style={styles.stylepriv3}>Our Services are not intended for use by children under the age of 13 ("Children"). </div>
                <div style={styles.stylepriv3}>We do not knowingly collect personally identifiable information from Children under 13. If you become aware that a Child has provided us with Personal Data, please contact us. If we become aware that we have collected Personal Data from Children without verification of parental consent, we take steps to remove that information from our servers.</div>
                <div style={styles.stylepriv2}>20.	Changes to This Privacy Policy</div>
                <div style={styles.stylepriv3}>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.</div>
                <div style={styles.stylepriv3}>We will let you know via email and/or a prominent notice on our Service, prior to the change becoming effective and update "effective date" at the top of this Privacy Policy.</div>
                <div style={styles.stylepriv3}>You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.</div>
                <div style={styles.stylepriv2}>21.	Contact Us</div>
                <div style={styles.stylepriv3}>If you have any questions about this Privacy Policy, please contact us:</div>
                <div style={styles.stylepriv3}>By email: info@garrilogistics.com.</div>

                </div>
                </div>
        );
    }
}

const styles = {

    stylepriv1: {
        fontWeight: 'bold',
        fontSize: 24, 
        color: 'black'
    }, 
    stylepriv2: {
        fontWeight: 'bold',
        fontSize: 16,
        color: 'black',
        margin: 20,
        marginLeft: 30
    },
    stylepriv3: {

        fontSize: 14,
        color: 'black',
        margin: 5,
        marginLeft: 30
    }

}

const chat = {
    display: "flex",
    flex: 3,
    flexDirection: "column",
    borderWidth: "1px",
    borderColor: "#ccc",
    borderRightStyle: "solid",
    borderLeftStyle: "solid",
    height: 500,//window.innerHeight * 0.8,//'80%',
    width: 300,//window.innerWidth * 0.3,//'30%'

    }

const button = {
    borderRadius: 5,
    width: 250,
    height: 40,
    background: 'rgb(47,191,80)',
    color: 'white',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 0, 

}
const buttonDec = {
    borderRadius: 5,
    width: 250,
    height: 40,
    background: 'rgb(244,128,36)',
    color: 'white',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 0, 

}

export default PrivacyPolicy;