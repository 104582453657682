import React, {Component} from 'react'

import GoogleMapReact from 'google-map-react';
import Firebase from 'src/Firebase.js'
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import MapPopup from './MapPopup'
import {
    Box,
    Typography,
    Checkbox,

  } from '@material-ui/core';

class AdminMap extends Component {

    constructor() {
        super()
        this.state = {
            center: {
                lat: 8.9806,
                lng: 38.7578
            },
            zoom: 8,
            trackableVehiclesList: [],
            trackableVehiclesDict: {},
            trackableDriversList: [],
            trackableDriversDict: {},
            report_errors: false,
            hideManual: false
        }

        this.listeners = {}
    }

    componentDidMount() {
        if (Firebase.IS_ASSOCIATION) {
            
            this.loadListDualRef('associationVehicles/' + this.props.userInfo.associationId, 'trackableLocations/vehicles', 'trackableVehiclesList', 'trackableVehiclesDict', 'vehicles')
            this.loadListDualRef('associationUsers/' + this.props.userInfo.associationId, 'trackableLocations/users', 'trackableDriversList', 'trackableDriversDict', 'users')
        } else {
            this.loadListDualRef('trackableLocations/vehicles', 'trackableLocations/vehicles', 'trackableVehiclesList', 'trackableVehiclesDict', 'vehicles')
            this.loadListDualRef('trackableLocations/users', 'trackableLocations/users', 'trackableDriversList', 'trackableDriversDict', 'users')
        }

    }

    componentWillUnmount() {
        if (Firebase.IS_ASSOCIATION) {
            Firebase.database.ref('associationVehicles/' + this.props.userInfo.associationId).off('child_added')
            Firebase.database.ref('associationUsers/' + this.props.userInfo.associationId).off('child_added')
            Firebase.database.ref('associationVehicles/' + this.props.userInfo.associationId).off('child_removed')
            Firebase.database.ref('associationUsers/' + this.props.userInfo.associationId).off('child_removed')
        } else {
            Firebase.database.ref('trackableLocations/vehicles').off('child_added')
            Firebase.database.ref('trackableLocations/users').off('child_added')
            Firebase.database.ref('trackableLocations/vehicles').off('child_removed')
            Firebase.database.ref('trackableLocations/users').off('child_removed')
        }


        Object.keys(this.listeners).map((key, idx) => {
            try {
                this.listeners[key].off('value')
            } catch {

            }

        })
    }
     
functionalStateListAdd( v, listTarget, dictTarget) {

    //console.log('try doing a functional update outer for ' + key )
    this.setState(function (prevState, props) {
    var temp = prevState[listTarget]
    var temp2 = prevState[dictTarget]
    temp.push(v)
    temp2[v.garriObjectId] = 1
    var retvar = {}
    retvar[listTarget] = temp 
    retvar[dictTarget] = temp2 
    return retvar//{ tableItems: temp, existingDict: temp2 }
})
}

functionalStateListUpdate( v, listTarget) {

    //console.log('try doing a functional update outer for ' + key )
    this.setState(function (prevState, props) {
    var temp = prevState[listTarget].map((item, idx) => {
    if (item.garriObjectId != v.garriObjectId) {
    return item
    }
    return v
    })

    var retvar = {}
    retvar[listTarget] = temp 
    return retvar//{ tableItems: temp }
    })
}

async loadSingleItemByKey(secondary_target, secondary_key, listTarget, dictTarget, firebase_tertiary_target) {
    let self = this
    //alert(secondary_target + '/' + secondary_key)
    this.listeners[secondary_target + '/' + secondary_key] = Firebase.database.ref(secondary_target + '/' + secondary_key)
    //console.log('here is shipperRequests/' + secondary_key)
    this.listeners[secondary_target + '/' + secondary_key].on("value", (inner_snapshot) => {
    if (inner_snapshot.exists()) {

        Firebase.database.ref(firebase_tertiary_target + '/' + secondary_key).once('value').then((data) => {
            if (data.exists()) {
                var data_pass_target = inner_snapshot.val()
                data_pass_target['garri_data'] = data.val()
                data_pass_target['garriObjectId'] = secondary_key
                if (secondary_key in self.state[dictTarget]) {
                    //alert('this is an update')
                    self.functionalStateListUpdate(data_pass_target, listTarget)
                    } else {
                    //alert('this is an add')
                        //console.log('performing add for ' + inner_snapshot.val().garriObjectId)
                    self.functionalStateListAdd(data_pass_target, listTarget, dictTarget)
                    }
            }
        }).catch((error) => {

        })

        //alert('do exist')
            

    } else {
        //alert('noexist ' + secondary_target + '/' + secondary_key)
    console.log('container found to not exist for ' + secondary_key)
    }
    }, function (error) {
    console.log('error fetching container for ' + secondary_key)
    console.log(error)
    })
}

loadListDualRef(firebase_primary_target, firebase_secondary_target, listTarget, dictTarget, firebase_tertiary_target) {
    // Maintain reference to main component for state updates
    let self = this
    console.log(firebase_primary_target)
    console.log(firebase_secondary_target)
    if (listTarget == 'transporterAssignedList') {
        //alert(firebase_primary_target)
    }
    let addlist = Firebase.database.ref(firebase_primary_target).on("child_added", (inner_snapshot, prevkey) => {
    // Get key of secondary item
    if (listTarget == 'transporterAssignedList') {
        //alert(inner_snapshot.val())
    }
    if (inner_snapshot.exists()) {
    let secondary_key = inner_snapshot.key
    self.loadSingleItemByKey(firebase_secondary_target, secondary_key, listTarget, dictTarget, firebase_tertiary_target)
    } else {

    }
    //self.loadSingleItemByKey(firebase_secondary_target, secondary_key)

    }, function (errorObject) {
    if (self.state.report_errors) {
    alert(errorObject)
    }
    console.log("The read failed: " + errorObject.code);
    });

    Firebase.database.ref(firebase_primary_target).on("child_removed", (snapshot) => {
    let secondary_key = snapshot.key

    Firebase.database.ref(firebase_secondary_target + '/' + secondary_key).off("value")
    //alert(JSON.stringify(self.state[listTarget]))
    console.log('try to do remove fsilter operaationn for ' + firebase_secondary_target + '/' + secondary_key)

    var nextList = self.state[listTarget].filter((item, idx) => {
        console.log('filter comparison of ' + item.garriObjectId + ' to ' + secondary_key)
        return (item.garriObjectId != secondary_key)
    })

    var existingDict = self.state[dictTarget]
    delete existingDict[secondary_key]
    var retNext = {}
    retNext[listTarget] = nextList 
    retNext[dictTarget] = existingDict
    self.setState(retNext)

    }, function (errorObject) {
    if (self.state.report_errors) {
    alert(errorObject)
    }
    console.log("The read failed: " + errorObject.code);
    });
}

    handleApiLoaded(map, maps) {

    }
    getLat(item) {
        //alert(JSON.stringify(Object.keys(item)))
        if ('lat' in item) {
            return item.lat 
        } else if ('coords' in item) {
            return item.coords.latitude 
        }

        return 0

    }

    getLng(item) {
        if ('lng' in item) {
            return item.lng
        } else if ('coords' in item) {
            return item.coords.longitude 
        }

        return 0
    }


    render() {
        return (
            <div>
            <div style={{flexDirection: 'row', display: 'flex'}}>
            <Checkbox
          checked={this.state.hideManual}
          name="policy"
          onChange={(e) => this.setState({hideManual: !this.state.hideManual})}
        />
        <Typography
          color="textSecondary"
          variant="body1"
          style={{marginTop: 10}}
        >
          Show only driver data from phones (hide manually set locations)
        </Typography>

            </div>


            < div style={{ height: 600, width: 1200, marginTop: 50, marginLeft: 50, position: 'relative' }}>
            <GoogleMapReact
                    bootstrapURLKeys={{ key: 'AIzaSyAkPLP3zHG-2kctVakLZwdUQHqeLVO0Onw', libraries: 'places' }}
                    defaultCenter={this.state.center}
                    defaultZoom={this.state.zoom}
                    center={this.state.center}
                    yesIWantToUseGoogleMapApiInternals={true}
                    onClick={(val) => console.log(JSON.stringify(this.state))}
                    onGoogleApiLoaded={({ map, maps }) => this.handleApiLoaded(map, maps)}
            >
                
                {this.state.trackableDriversList.map((item, idx) => {
                    return <MapPopup lat={this.getLat(item)} lng={this.getLng(item)} item={item} hideManual={this.state.hideManual} type={'driver'}/>
                })}{this.state.trackableVehiclesList.map((item, idx) => {
                    return <MapPopup  lat={this.getLat(item)} lng={this.getLng(item)}  item={item} type={'vehicle'}/>
                })}
                </GoogleMapReact>
                </div>   
                </div>
        )
    }

}

export default AdminMap