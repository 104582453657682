import React, {useState, useEffect, useRef} from 'react';
import {
  Box,
  makeStyles,
  CircularProgress
} from '@material-ui/core';
import Page from 'src/components/Page';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  }
}));


const LoadingView = () => {
  const classes = useStyles();


  return (<Page
    className={classes.root}
    height='100%'
    title="Garri"
    id="ff2"
    backgroundColor={classes.root.backgroundColor}
  >
<div style={{
      display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: 'rgb(244,246,248)', height: '100vh'
  }}> <CircularProgress /></div>
  </Page>)
};

export default LoadingView;
