import React, { useState } from 'react';
import {useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {
  Avatar,
  Box,
  Card,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  makeStyles,
  TextField,
  Button
} from '@material-ui/core';
import getInitials from 'src/utils/getInitials';
import NotificationDialog from 'src/utils/NotificationDialog';
import Firebase from 'src/Firebase';
import {X} from 'react-feather';
import HeaderComponent from 'src/utils/HeaderComponent';
import SearchWrapper from 'src/utils/SearchWrapper';
import TableAssist from 'src/utils/TableAssist';


const searchOptions = {
  'name': {
      'name': 'Contact Name',
      'val': 'name'
  },
  'userType': {
      'name': 'Type',
      'val': 'userType'
  },
  'verified': {
      'name': 'Verified',
      'val': 'verified'
  },
  'phone': {
      'name': 'Phone',
      'val': 'phone'
  },
  'companyName': {
      'name': 'Company',
      'val': 'companyName'
  },
}

const useStyles = makeStyles((theme) => ({
  root: {},
  avatar: {
    marginRight: theme.spacing(2)
  }
}));

const UserTable = ({ className, customers, tableContext, localityMap, ...rest }) => {
  const classes = useStyles();
  const [selectedCustomerIds, setSelectedCustomerIds] = useState([]);
  const [archiveAlertOpen, setArchiveAlertOpen] = useState(false);
  const [archiveDialogWorking, setArchiveDialogWorking] = useState(false);
  const [limit, setLimit] = useState(10);
  const [text_filters, set_text_filters] = useState([]);
  const [sortOrder, setSortOrder] = useState('creationDate');
  const [sortDirection, setSortDirection] = useState(false);
  const [page, setPage] = useState(0);
  const [localityOption, setLocalityOption] = useState('Filter By Location');
  const navigate = useNavigate();

  const handleSelectAll = (event) => {
    let newSelectedCustomerIds;

    if (event.target.checked) {
      newSelectedCustomerIds = applySecondaryFilters(customers, tableContext.usersFilters, tableContext.hideTestUsers).map((item, idx) => {
        return item.userId
      })
      //customers.map((customer) => customer.quoteId);
    } else {
      newSelectedCustomerIds = [];
    }

    setSelectedCustomerIds(newSelectedCustomerIds);
  };

  const handleSelectOne = (event, id) => {
    const selectedIndex = selectedCustomerIds.indexOf(id);
    let newSelectedCustomerIds = [];

    if (selectedIndex === -1) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(selectedCustomerIds, id);
    } else if (selectedIndex === 0) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(selectedCustomerIds.slice(1));
    } else if (selectedIndex === selectedCustomerIds.length - 1) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(selectedCustomerIds.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(
        selectedCustomerIds.slice(0, selectedIndex),
        selectedCustomerIds.slice(selectedIndex + 1)
      );
    }

    setSelectedCustomerIds(newSelectedCustomerIds);
  };

  const handleLimitChange = (event) => {
    //setLimit(event.target.value);
    if (tableContext.usersLimit != event.target.value) {
      tableContext.updateFunc('usersLimit', event.target.value)
    }

  };

  const handlePageChange = (event, newPage) => {
    //setPage(newPage);
    if (tableContext.usersPage != newPage) {
      tableContext.updateFunc('usersPage',newPage)
    }

  };


  function activateTextFilter(val) {
    var oldFilters = tableContext.usersFilters //text_filters
    //console.log('old text filters : ' + JSON.stringify(oldFilters))
    var found_filter = false
    oldFilters = oldFilters.map((item, idx) => {
        if (item.name == val.name) {
            found_filter = true
            item.target = val.target
            return val
        }
        return item
    })
    //console.log('oldbeforeadding')
    if (!found_filter) {
        oldFilters.push(val)
    }
    console.log('new text filters : ' + JSON.stringify(oldFilters))
    tableContext.updateFunc('usersFilters', oldFilters)
    //set_text_filters(oldFilters)
  
  }
  
  function deactivateTextFilter(val) {
    let newFilters = tableContext.usersFilters.filter((item, idx) => {
        return (item.key != val)
    })
  
    tableContext.updateFunc('usersFilters', newFilters)
  }
  
  function updateSortDirection(v, d) {
//setSortOrder(v)
//setSortDirection(d)
tableContext.updateFunc('usersSortOrder', v)
tableContext.updateFunc('usersSortDirection', d)
  }
  
  function applySecondaryFilters(items, filters, htu) {
  var returnList = items.filter((v, idx) => {
      let item = v
      var returnItem = true 
      if (htu && item.testUser) {
        return false 
      }
      filters.map((filter, filterIdx) => {
          if (filter.condition == '>') {
              if (filter.key in item && item[filter.key] > filter.target) {
                  //returnItem = true
              } else {
                  returnItem = false
              }
          } else if (filter.condition == '<') {
              if (filter.key in item && item[filter.key] < filter.target) {
                  //returnItem = true
              } else {
                returnItem = false
              }
          } else if (filter.condition == '=') {
              if (filter.key in item && item[filter.key] === filter.target) {
                  //returnItem = true
              } else {
                returnItem = false
              }
          } else if (filter.condition == 't') {
            
              if (filter.key == 'localityMap') {
                if (item.localityMap && filter.target in item.localityMap) {

                } else {
                  returnItem = false 
                }

              } else if (filter.key in item || filter.key == 'name' || filter.key == 'verified') {
  
                  let itemSet = TableAssist.fieldFormat(item, filter.key,1) 
                  //console.log(itemSet)
                  //console.log(filter.key)
                  //console.log(item.verified)
                  let itemData = itemSet.toUpperCase();
  
                  let textData = filter.target.toUpperCase();
  
                  if (itemData.indexOf(textData) > -1) {
                      //returnItem = true
                  } else {
                    returnItem = false
                  }
  
              } else {
                //console.log(item)
                //console.log('not in' + filter.key)
                returnItem = false
              }
          } else if (filter.condition == 'd') {
              if (filter.key in item && item[filter.key] in filter.target) {
                  //returnItem = true
              } else {
                returnItem = false
              }
  
          }
      })
  
  
      return returnItem
  })
  
  return returnList
  }
  
  function compare2(aa, bb, key, reverse) {
        
  let a = aa//TableHelper.getSortValue(aa, key)
  let b = bb//TableHelper.getSortValue(bb, key)
  
  console.log('do a comparison of ' + a[key] + ' to ' + b[key])
  let aval = TableAssist.fieldFormat(a, key, 1)
  let bval = TableAssist.fieldFormat(b, key, 1)
  if (aval < bval) {
      return -1 * ((2 * reverse) - 1);
  }
  if (aval > bval) {
      return ((2 * reverse) - 1);
  }
  return 0;
  }
  
  function sortItems(v, s, htu) {
  //console.log('try sorting')
  //console.log(v)
  //console.log(s)

  var o = v
  o['name'] = renderUserName(v)
  if (s == 0) {
      return o
  }
  else if (s == 1) {
      o.sort((a, b) => compare2(a, b, s, tableContext.usersSortDirection))
  } else {
      o.sort((a, b) => compare2(a, b, s, tableContext.usersSortDirection))
  }
  
  //alert(JSON.stringify(o))
  //return o
  if (tableContext.usersFilters.length > 0 || htu) {
    let reSet = applySecondaryFilters(o, tableContext.usersFilters, htu)
    if (reSet.length != tableContext.usersCountVal) {
      //setCountVal(reSet.length)
      if (tableContext.usersPage != 0) {
        tableContext.updateFunc('usersPage', 0)
      }
      tableContext.updateFunc('usersCountVal', reSet.length)
    }
    //console.log('countaaaay ' + reSet.length)
    return reSet
  
  }
  if (o.length != tableContext.usersCountVal) {
    //setCountVal(o.length)
    if (tableContext.usersPage != 0) {
      tableContext.updateFunc('usersPage', 0)
    }
    tableContext.updateFunc('usersCountVal', o.length)
  }
  return o
  }


  function doNotify(noti) {


    setArchiveDialogWorking(true)
    let s = function () {
      setArchiveAlertOpen(false)
      setArchiveDialogWorking(false)
      setSelectedCustomerIds([])
      alert('Notification(s) Sent!')
    }
  
    let f = function () {
      setArchiveAlertOpen(false)
      setArchiveDialogWorking(false)
      alert('Notification(s) Could not Be Sent')
    }
  
    Firebase.batchNotifyUsers({'notificationIdList': trueSelectedCustomerIds(), 'notification': noti}, s, f)
  }
  
  function trueSelectedCustomerIds() {
    return selectedCustomerIds
    /*
    if (text_filters.length > 0) {
      return applySecondaryFilters(customers, text_filters).map((item, idx) => {
        return item.userId
      }).filter((item, idx) => {
        return selectedCustomerIds.includes(item)
      })
    } else {
      return selectedCustomerIds
    }*/
  }

  function getCustomersWithPush() {
    return customers.filter((item, idx) => {
      return 'token' in item 
    }).filter((item, idx) => {
      return selectedCustomerIds.includes(item.userId)
    }).length 
  }

  function getCustomersWithPhone() {
    return customers.filter((item, idx) => {
      return 'phone' in item 
    }).filter((item, idx) => {
      return selectedCustomerIds.includes(item.userId)
    }).length 
  }

  function renderType(type) {
    if (type == 1) {
      return "Transporter"
    } else if (type == 2) {
      return 'Shipper'
    } else if (type == 3) {
      return "Driver"
    } else {
      return "Unknown"
    }
  }

  function renderManageString(customer) {
    return "Manage User"
    if (customer.userType == 1) {
      return "Manage Transporter"
    } else if (customer.userType == 2) {
      return "Manage Shipper"
    } else if (customer.userType == 3) {
      return "Manage Driver"
    }


  }

  function prepUserPhoneDict(users) {
    var pdict = {}
    users.map((item, idx) => {
      pdict[item.phone] = item 
      if (item.secondaryPhones && item.secondaryPhones.length > 0) {
        item.secondaryPhones.map((inner, inidx) => {
          pdict[inner] = item 
        })
      }
    })
    return pdict
  }

 function renderAction(customer) {
    if (!('state' in customer) || customer.state == 0) {
      return <Button
      color="primary"
     
      onClick={() => navigate('/app/edituser', {state: Object.assign({}, customer, {'phoneDict': prepUserPhoneDict(customers)})})}
      id="sendButton"
      size="large"
      variant="contained"
 
    >
      {renderManageString(customer)}
    </Button>
    } else if (customer.state == 2) {
      return <Button
      color="primary"
     
      onClick={() => window.open('http://localhost:3000/v/' + customer.userId)}
      id="sendButton"
      size="large"
      variant="contained"
  
    >
      Assign Job
    </Button>
    } else if (customer.state > 2) {
      return <Button
      color="primary"
     
      onClick={() => alert('later stages not implemented yet')}
      id="sendButton"
      size="large"
      variant="contained"
  
    >
      Manage Job
    </Button>
    }
 }

 function renderVerified(x) {
  if (x.verified) {
    return 'Verified'
  } else {
    return 'Unverified'
  }
}

function renderUserName(v) {
  var rets = ''
  if ('firstName' in v) {
    rets += v.firstName
  } 
  if ('middleName' in v) {
    rets += ' ' + v.middleName
  } 
  if ('lastName' in v) {
    rets += ' ' + v.lastName
  }
  return rets
}

function renderCustomerPhones(v) {
  var rets = v.phone 

  if ('secondaryPhones' in v && v.secondaryPhones.length > 0) {
    v.secondaryPhones.map((item, idx) => {
      rets += ', ' + item
    })
  }
  return rets
}

function getWarningString() {
  var dn = 0
  var tn = 0
  var sn = 0
  customers.map((item, idx) => {
    if (selectedCustomerIds.includes(item.userId)) {
      if (item.userType == 1) {
        tn += 1
      } else if (item.userType == 2) {
        sn += 1
      } else if (item.userType == 3) {
        dn += 1
      }
    }
  })
  return 'Send notification to ' + trueSelectedCustomerIds().length + ' users? (This includes ' + sn + ' shippers, ' + tn + ' transporters, and ' + dn + ' drivers.)'
}

  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
            <NotificationDialog open={archiveAlertOpen}
      dialogWorking={archiveDialogWorking}
      onClose={() => setArchiveAlertOpen(false)}
      alertTitle={getWarningString()}
      alertBody={'Not all users have provided information for every type of notification. Of the users that you have selected, note that ' + getCustomersWithPhone() + ' have provided a phone number, and ' + getCustomersWithPush() + ' are registered for push notficiations. Unregistered users will not get the notification.'}
      alertCancelText={'Cancel Notification'}
      alertConfirmText={'Notify Users'}
      onConfirm={(x) => doNotify(x)}
      />

            <div style={{alignItems: 'flex-end', display: 'flex', flexDirection: 'row'}}>
              <div><Button
            color="primary"
            variant="contained"
            style={{marginLeft: 10, marginRight: 10, backgroundColor: 'mediumspringgreen'}}
            onClick={() => trueSelectedCustomerIds().length <= 0 ? alert('Select at least one item to archive.'): setArchiveAlertOpen(true)}
          >
            Batch Notify
          </Button>

          <Box
                  alignItems="center"
                  display="flex"
                  ml={-1}
                >
                  <Checkbox
                    checked={tableContext.hideTestUsers}
                    name="testUser"
                    onChange={(e) => tableContext.updateFunc('hideTestUsers', !tableContext.hideTestUsers)}
                  />
                  <Typography
                    color="textSecondary"
                    variant="body1"
                  >
                   Hide Test Users
                  </Typography>
                </Box></div>

                {Object.keys(localityMap).length > 0 ?
                <div style={{width: 300, marginLeft: 10}}>
                <Autocomplete
                id="combo-box-transporter"
                options={Object.keys(localityMap)}
                getOptionLabel={(option) =>option}
                value={localityOption}
                defaultValue={'Filter By Location'} 
                width={'30%'}
                onChange={(e, value) => {
                  if (value != null) {
                    //setLocalityOption(value)
                    setLocalityOption('Filter By Location')
                    activateTextFilter({'key': 'localityMap', 'condition': 't', 'target': value, 'name': 'Past Locations'})
                  } else {
                    
                  }
              
              
                }}
                renderInput={(params) => <TextField {...params}
                width={'30%'}
                label="Cities"
                margin="normal"
                id="roundTripLink"
                name="job"
                variant="outlined"
                value={localityOption}
                defaultValue={'Filter By Location'} />}
              /></div>
              : null}
          </div>
          <SearchWrapper defaultSearchOption={'name'} defaultSearchName={'name'} searchOptions={searchOptions} activate={(v) => activateTextFilter(v)} deactivate={(v) => deactivateTextFilter(v)}/>
          
      <PerfectScrollbar>


        <Box minWidth={1050}>
        <div style={{ flexDirection: 'row', display: 'flex', marginBottom: 20 }}>
                    {
                        tableContext.usersFilters.map((item, idx) => {
                            return (
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', backgroundColor: 'rgb(230,230,230)', borderRadius: 30, padding: 0, margin: 0, height: 30, padding: 10, margin: 5, }}>
                                    <p>{item.name + ' contains ' + item.target}</p>
                                    <X onClick={() => deactivateTextFilter(item.key)} />
                                </div>
                                )
                        })
                    }
                </div>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={selectedCustomerIds.length === customers.length}
                    color="primary"
                    indeterminate={
                      selectedCustomerIds.length > 0
                      && selectedCustomerIds.length < customers.length
                    }
                    onChange={handleSelectAll}
                  />
                </TableCell>
                <HeaderComponent sortSet={(v, d) => updateSortDirection(v, d)} text={'Contact Name'} itemkey={'name'} activate={(v) => activateTextFilter(v)} deactivate={(v) => deactivateTextFilter(v)} />
                <HeaderComponent sortSet={(v, d) => updateSortDirection(v, d)} text={'Type'} itemkey={'userType'} activate={(v) => activateTextFilter(v)} deactivate={(v) => deactivateTextFilter(v)} />
                <HeaderComponent sortSet={(v, d) => updateSortDirection(v, d)} text={'Verified'} itemkey={'verified'} activate={(v) => activateTextFilter(v)} deactivate={(v) => deactivateTextFilter(v)} />
                <HeaderComponent sortSet={(v, d) => updateSortDirection(v, d)} text={'Phone'} itemkey={'phone'} activate={(v) => activateTextFilter(v)} deactivate={(v) => deactivateTextFilter(v)} />
                <HeaderComponent sortSet={(v, d) => updateSortDirection(v, d)} text={'Company'} itemkey={'companyName'} activate={(v) => activateTextFilter(v)} deactivate={(v) => deactivateTextFilter(v)} />

                <TableCell>
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sortItems(customers, tableContext.usersSortOrder, tableContext.hideTestUsers).slice(tableContext.usersPage * tableContext.usersLimit, tableContext.usersPage * tableContext.usersLimit + tableContext.usersLimit).map((customer) => (
                <TableRow
                  hover
                  key={customer.userId}
                  selected={selectedCustomerIds.indexOf(customer.userId) !== -1}
                 
                >
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={selectedCustomerIds.indexOf(customer.userId) !== -1}
                      onChange={(event) => handleSelectOne(event, customer.userId)}
                      value="true"
                    />
                  </TableCell>
                  <TableCell>
                   {renderUserName(customer)}
                  </TableCell>
                  <TableCell>
                    {renderType(customer.userType)}
                  </TableCell>
                  <TableCell>
                    {renderVerified(customer)}
                  </TableCell>
                  <TableCell>
                    {renderCustomerPhones(customer)}
                  </TableCell>
                  <TableCell>
                    {customer.companyName}
                  </TableCell>
                  <TableCell>
                    {renderAction(customer)}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={tableContext.usersCountVal}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handleLimitChange}
        page={tableContext.usersPage}
        rowsPerPage={tableContext.usersLimit}
        rowsPerPageOptions={[5, 10, 25]}
      />
    </Card>
  );
};

UserTable.propTypes = {
  className: PropTypes.string,
  customers: PropTypes.array.isRequired
};

export default UserTable;
