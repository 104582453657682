import React, { useState, useEffect, Component } from 'react';
import {
  Box,
  Container,
  makeStyles
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { Link as RouterLink, useNavigate, useLocation} from 'react-router-dom';
import Firebase from 'src/Firebase.js'
import SubPlanTable from './SubPlanTable';
import MyContext from 'src/MyContext.js'
import { DataGrid } from '@mui/x-data-grid';

const columns = [
  { field: 'id', headerName: 'ID', width: 90 },
  {
    field: 'firstName',
    headerName: 'First name',
    width: 150,
    editable: true,
  },
  {
    field: 'lastName',
    headerName: 'Last name',
    width: 150,
    editable: true,
  },
  {
    field: 'age',
    headerName: 'Age',
    type: 'number',
    width: 110,
    editable: true,
  },
  {
    field: 'fullName',
    headerName: 'Full name',
    description: 'This column has a value getter and is not sortable.',
    sortable: false,
    width: 160,
    valueGetter: (params) =>
      `${params.getValue(params.id, 'firstName') || ''} ${
        params.getValue(params.id, 'lastName') || ''
      }`,
  },
];

const rows = [
  { id: 1, lastName: 'Snow', firstName: 'Jon', age: 35 },
  { id: 2, lastName: 'Lannister', firstName: 'Cersei', age: 42 },
  { id: 3, lastName: 'Lannister', firstName: 'Jaime', age: 45 },
  { id: 4, lastName: 'Stark', firstName: 'Arya', age: 16 },
  { id: 5, lastName: 'Targaryen', firstName: 'Daenerys', age: null },
  { id: 6, lastName: 'Melisandre', firstName: null, age: 150 },
  { id: 7, lastName: 'Clifford', firstName: 'Ferrara', age: 44 },
  { id: 8, lastName: 'Frances', firstName: 'Rossini', age: 36 },
  { id: 9, lastName: 'Roxie', firstName: 'Harvey', age: 65 },
];


const of = [

  {'fieldKey': 'sku', 'fieldName': 'SKU',
'fieldType': 'custom', 'editable': true},
  {'fieldKey': 'estimatedQty',
'fieldName': 'Estimated Qty (Quintals)',
'fieldType': 'number', 'width': 200,
'editable': true},
{'fieldKey': 'unitPrice',
'fieldName': 'Unit Price (Quintals)', 'width': 200,
'fieldType': 'number', 'editable': true},
{'fieldKey': 'receivedqty',
'fieldName': 'Qty Received (Quintals)',
'fieldType': 'number','editable': false, width: 200},
{'fieldKey': 'warehouse',
'fieldName': 'Destination',
'fieldType': 'custom', 'editable': true, 'width': 200},
{'fieldKey': 'union',
'fieldName': 'Union',
'fieldType': 'text','editable': false},
{'fieldKey': 'zone',
'fieldName': 'Zone',
'fieldType': 'text','editable': true},
{'fieldKey': 'region',
'fieldName': 'Region',
'fieldType': 'text','editable': true},

]

const SubPlanTableWrapper = ({ className, ...rest }) => {

  const {state} = useLocation()

 function dictToList(d) {
   if (d) {
    return Object.keys(d).map((key, idx) => {
      return d[key]
    })
   } else {
     return []
   }
 }



    return (
      <MyContext.Consumer>{context => (
        <div style={{marginTop: 5, marginLeft: 5}}>

<SubPlanTable customKey={state.stateId}
          objectFields={of} 
          objectList={dictToList(context.innerPlansDict[state.stateId])} barleyInCustomObjects={context.barleyInCustomObjects}
          context={context} workflow={false} stateProp={state}/>

    </div>
      )}</MyContext.Consumer>
    )

  /*

<SubPlanTable customKey={''} 
          objectFields={[]} 
          objectList={dictToList(context.innerPlansDict[state.stateId])} barleyInCustomObjects={context.barleyInCustomObjects}
          context={context} workflow={false}/>
    


  */

};

SubPlanTableWrapper.propTypes = {
  className: PropTypes.string
};

export default SubPlanTableWrapper;
