import React, { useState } from 'react';
import {useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Avatar,
  Box,
  Card,
  CardContent,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  makeStyles,
  Button,
  TextField,
  InputAdornment,
  SvgIcon,
  Link
} from '@material-ui/core';
import {X} from 'react-feather';
import getInitials from 'src/utils/getInitials';
import HeaderComponent from 'src/utils/HeaderComponent';
import SearchWrapper from 'src/utils/SearchWrapper';
import TableAssist from '../utils/TableAssist';
import { pad } from 'lodash';
import Firebase from 'src/Firebase'

const useStyles = makeStyles((theme) => ({
  root: {},
  avatar: {
    marginRight: theme.spacing(2)
  }
}));




const ShipperOnlyQuoteTable = ({ className, keyProp, customers, ...rest }) => {
  const classes = useStyles();
  const [selectedCustomerIds, setSelectedCustomerIds] = useState([]);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);
  const [text_filters, set_text_filters] = useState([]);
  const [sortOrder, setSortOrder] = useState('creationDate');
  const [sortDirection, setSortDirection] = useState(false);
  const navigate = useNavigate();


  const processNav = (customer) => {
    if (customer.state == 1 || customer.state == -1) {
      navigate('/app/confirmquote', {state: {customer: customer}})
    } else {
        let startLat = customer.startPos.lat 
        let endLat = customer.endPos.lat 
        let startLng = customer.startPos.lng 
        let endLng = customer.endPos.lng 

        let cx = startLat + ((endLat - startLat) / 2)
        let cy = startLng + ((endLng - startLng) / 2)

        navigate('/app/editquote', {state: {customer: customer, centerx: cx, centery: cy}})

      //navigate('/app/editquote', {state: customer})
    }

  }

  const handleSelectAll = (event) => {
    let newSelectedCustomerIds;

    if (event.target.checked) {
      newSelectedCustomerIds = customers.map((customer) => customer.quoteId);
    } else {
      newSelectedCustomerIds = [];
    }

    setSelectedCustomerIds(newSelectedCustomerIds);
  };

  const handleSelectOne = (event, id) => {
    const selectedIndex = selectedCustomerIds.indexOf(id);
    let newSelectedCustomerIds = [];

    if (selectedIndex === -1) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(selectedCustomerIds, id);
    } else if (selectedIndex === 0) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(selectedCustomerIds.slice(1));
    } else if (selectedIndex === selectedCustomerIds.length - 1) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(selectedCustomerIds.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(
        selectedCustomerIds.slice(0, selectedIndex),
        selectedCustomerIds.slice(selectedIndex + 1)
      );
    }

    setSelectedCustomerIds(newSelectedCustomerIds);
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };
  function renderStatus(customer) {
    let s = customer.state 
    if (customer.isDelayed) {
      return 'Temporarily Delayed'
    }
    if (s == -1) {
      return 'Quote Rejeceted'
    } else if (s == 0) {
      return 'Quote Requested'
    } else if (s==1) {
      return 'Quote Offered'
    } else if (s==2) {
      return 'Quote Accepted'
    } else if (s==3) {
      return 'Offered to Transporters'
    } else if (s==4) {
      return 'Transporter Placed Bid'
    } else if (s==5) {
      return 'Transporter Assigned'
    } else if (s==6) {
      return 'Job Assigned To Driver'
    } else if (s==7) {
      return 'Driver en Route'
    } else if (s==8) {
      return 'Driver Loaded Truck'
    } else if (s==9) {
      return 'Driver Delivered Load'
    }
  }

  function activateTextFilter(val) {
    var oldFilters = text_filters
    //console.log('old text filters : ' + JSON.stringify(oldFilters))
    var found_filter = false
    oldFilters = oldFilters.map((item, idx) => {
        if (item.name == val.name) {
            found_filter = true
            item.target = val.target
            return val
        }
        return item
    })
    //console.log('oldbeforeadding')
    if (!found_filter) {
        oldFilters.push(val)
    }
    console.log('new text filters : ' + JSON.stringify(oldFilters))
    set_text_filters(oldFilters)

}

function deactivateTextFilter(val) {
    let newFilters = text_filters.filter((item, idx) => {
        return (item.key != val)
    })

    set_text_filters(newFilters)
}

function updateSortDirection(v, d) {
  setSortOrder(v)
  setSortDirection(d)
}

function renderQuoteAction(item) {
  if (item.state == 0) {
    return 'Review Request'
  } else if (item.state == 1 || item.state == -1) {
    return 'Review Quote'
  } else {
    return 'View Job'
  }

}

function applySecondaryFilters(items, filters) {
  var returnList = items.filter((v, idx) => {
      let item = v
      var returnItem = true 
      filters.map((filter, filterIdx) => {
          if (filter.condition == '>') {
              if (filter.key in item && item[filter.key] > filter.target) {
                  //returnItem = true
              } else {
                  returnItem = false
              }
          } else if (filter.condition == '<') {
              if (filter.key in item && item[filter.key] < filter.target) {
                  //returnItem = true
              } else {
                returnItem = false
              }
          } else if (filter.condition == '=') {
              if (filter.key in item && item[filter.key] === filter.target) {
                  //returnItem = true
              } else {
                returnItem = false
              }
          } else if (filter.condition == 't') {
              if (filter.key in item) {

                  var itemSet = TableAssist.fieldFormat(item, filter.key) 
                  /*if (filter.key == 'shortId') {
                    if ('shortIdShipper' in item) {
                      itemSet = padDigits(item.shortIdShipper + '')
                    }
                  }*/
                  let itemData = itemSet.toUpperCase();

                  let textData = filter.target.toUpperCase();

                  if (itemData.indexOf(textData) > -1) {
                      //returnItem = true
                  } else {
                    returnItem = false
                  }

              } else {
                //console.log(item)
                //console.log('not in' + filter.key)
                returnItem = false
              }
          } else if (filter.condition == 'd') {
              if (filter.key in item && item[filter.key] in filter.target) {
                  //returnItem = true
              } else {
                returnItem = false
              }

          }
      })


      return returnItem
  })

  return returnList
}

function compare2(aa, bb, key, reverse) {
        
  let a = aa//TableHelper.getSortValue(aa, key)
  let b = bb//TableHelper.getSortValue(bb, key)

  if (a[key] < b[key]) {
      return -1 * ((2 * reverse) - 1);
  }
  if (a[key] > b[key]) {
      return ((2 * reverse) - 1);
  }
  return 0;
}

function padDigits(number) {
  return Array(Math.max(3 - String(number).length + 1, 0)).join(0) + number;
}

function sortItems(v, s) {
  //console.log('try sorting')
  //console.log(v)
  //console.log(s)
  var o = v

  if (s == 0) {
      return o
  }
  else if (s == 1) {
      o.sort((a, b) => compare2(a, b, s, sortDirection))
  } else {
      o.sort((a, b) => compare2(a, b, s, sortDirection))
  }

  //alert(JSON.stringify(o))
  //return o
  if (text_filters.length > 0) {
    return applySecondaryFilters(o, text_filters)
  }
  return o
}

  return (
    <div id={'awe2aw3eff' + keyProp} key={'awe2aw3eff' + keyProp} style={{flexDirection: 'column'}}>
          <SearchWrapper isShipper={true} id={'baz344' + keyProp} key={'baz344' + keyProp} activate={(v) => activateTextFilter(v)} deactivate={(v) => deactivateTextFilter(v)}/>
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >

      <PerfectScrollbar>

        <Box minWidth={1050}>

          <div key={'fkiai23jf' + keyProp} style={{ flexDirection: 'row', display: 'flex', marginBottom: 20 }}>
                    {
                        text_filters.map((item, idx) => {
                            return (
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', backgroundColor: 'rgb(230,230,230)', borderRadius: 30, padding: 0, margin: 0, height: 30, padding: 10, margin: 5, }}>
                                    <p>{item.name + ' contains ' + item.target}</p>
                                    <X onClick={() => deactivateTextFilter(item.key)} />
                                </div>
                                )
                        })
                    }
                </div>


          <Table>
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={selectedCustomerIds.length === customers.length}
                    color="primary"
                    indeterminate={
                      selectedCustomerIds.length > 0
                      && selectedCustomerIds.length < customers.length
                    }
                    onChange={handleSelectAll}
                  />
                </TableCell>
                <HeaderComponent sortSet={(v, d) => updateSortDirection(v, d)} text={'ID'} itemkey={'shortId'} activate={(v) => activateTextFilter(v)} deactivate={(v) => deactivateTextFilter(v)} />
                <HeaderComponent sortSet={(v, d) => updateSortDirection(v, d)} text={'Commodity'} itemkey={'commodity'} activate={(v) => activateTextFilter(v)} deactivate={(v) => deactivateTextFilter(v)} />
                <HeaderComponent sortSet={(v, d) => updateSortDirection(v, d)} text={'Pickup'} itemkey={'startName'} activate={(v) => activateTextFilter(v)} deactivate={(v) => deactivateTextFilter(v)} />
                <HeaderComponent sortSet={(v, d) => updateSortDirection(v, d)} text={'Dropoff'} itemkey={'endName'} activate={(v) => activateTextFilter(v)} deactivate={(v) => deactivateTextFilter(v)} />
                <HeaderComponent sortSet={(v, d) => updateSortDirection(v, d)} text={'Created'} itemkey={'creationDate'} activate={(v) => activateTextFilter(v)} deactivate={(v) => deactivateTextFilter(v)} />
                <HeaderComponent sortSet={(v, d) => updateSortDirection(v, d)} text={'Status'} itemkey={'state'} activate={(v) => activateTextFilter(v)} deactivate={(v) => deactivateTextFilter(v)} />
                <TableCell>
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sortItems(customers, sortOrder).slice(page * limit, page * limit + limit).map((customer) => (
                <TableRow
                  hover
                  key={customer.quoteId}
                  selected={selectedCustomerIds.indexOf(customer.quoteId) !== -1}
                  onClick={() => /*processNav(customer)*/ console.log('didclick')}
                >
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={selectedCustomerIds.indexOf(customer.quoteId) !== -1}
                      onChange={(event) => handleSelectOne(event, customer.quoteId)}
                      value="true"
                    />
                  </TableCell>
                  <TableCell>
                  <Link
              to={customer}
              onClick={ () => window.open(Firebase.ip + 'info/' + TableAssist.renderIdValue(customer))}
              variant="h6"
            >{TableAssist.renderIdDisplay(customer)}</Link>
                  </TableCell>
                  <TableCell>
                    {customer.commodity}
                  </TableCell>
                  <TableCell>
                    {customer.startName}
                  </TableCell>
                  <TableCell >
                    {customer.endName}
                  </TableCell>
                  <TableCell>
                    {customer.creationDate ? moment(customer.creationDate).format('DD/MM/YYYY') : 'Not Set'}
                  </TableCell>
                  <TableCell>
                    {renderStatus(customer)}
                  </TableCell>
                  <TableCell >
                   <Button
      color="primary"
     
      onClick={() => processNav(customer)}
      id="sendButton"
      size="large"
      variant="contained"
  
    >
      {renderQuoteAction(customer)}
    </Button>
                  </TableCell>
                  
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={customers.length}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[5, 10, 25]}
      />
    </Card></div>
  );
};

ShipperOnlyQuoteTable.propTypes = {
  className: PropTypes.string,
  customers: PropTypes.array.isRequired
};

export default ShipperOnlyQuoteTable;
