import React, { useState } from 'react';
import {useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Avatar,
  Box,
  Card,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  makeStyles,
  Button,
  Link,
  CircularProgress
} from '@material-ui/core';
import Firebase from 'src/Firebase'
import {X} from 'react-feather';
import HeaderComponent from 'src/utils/HeaderComponent';
import SearchWrapper from 'src/utils/SearchWrapper';
import TableAssist from 'src/utils/TableAssist';
import AlertDialog from 'src/utils/AlertDialog';
import { CSVLink, CSVDownload } from "react-csv";

const useStyles = makeStyles((theme) => ({
  root: {},
  avatar: {
    marginRight: theme.spacing(2)
  }
}));

const UserQuoteTable = ({ className, customers, navigater, tableContext, usersNameDict, isLimited, ...rest }) => {
  const classes = useStyles();
  const [selectedCustomerIds, setSelectedCustomerIds] = useState([]);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);
  //const [tableContext.jobsFilters, set_XtextX_filters] = useState([]);
  const [sortOrder, setSortOrder] = useState('creationDate');
  const [sortDirection, setSortDirection] = useState(false);
  const [generatedCsvData, setGeneratedCsvData] = useState(null);
  const [generatedCsvTime, setGeneratedCsvTime] = useState(Date.now())
  const [archiveAlertOpen, setArchiveAlertOpen] = useState(false);
  const [archiveDialogWorking, setArchiveDialogWorking] = useState(false);
  const [invoiceLoading, setInvoiceLoading] = useState(false);
  const [countVal, setCountVal] = useState(-1)
  const navigate = useNavigate();

  const handleSelectAll = (event) => {
    let newSelectedCustomerIds;

    if (event.target.checked) {
      newSelectedCustomerIds = applySecondaryFilters(customers, tableContext.jobsFilters).map((item, idx) => {
        return item.quoteId
      })
      //customers.map((customer) => customer.quoteId);
    } else {
      newSelectedCustomerIds = [];
    }

    setSelectedCustomerIds(newSelectedCustomerIds);
  };

  const handleSelectOne = (event, id) => {
    const selectedIndex = selectedCustomerIds.indexOf(id);
    let newSelectedCustomerIds = [];

    if (selectedIndex === -1) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(selectedCustomerIds, id);
    } else if (selectedIndex === 0) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(selectedCustomerIds.slice(1));
    } else if (selectedIndex === selectedCustomerIds.length - 1) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(selectedCustomerIds.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(
        selectedCustomerIds.slice(0, selectedIndex),
        selectedCustomerIds.slice(selectedIndex + 1)
      );
    }

    setSelectedCustomerIds(newSelectedCustomerIds);
  };

  const handleLimitChange = (event) => {
    //setLimit(event.target.value);
    if (tableContext.limit != event.target.value) {
      tableContext.updateFunc('limit', event.target.value)
    }

  };

  const handlePageChange = (event, newPage) => {
    //setPage(newPage);
    if (tableContext.page != newPage) {
      tableContext.updateFunc('page',newPage)
    }

  };

 function renderAction(customer) {
  if (customer.hasBid) {
    return <Button
    color="primary"
   
    onClick={() => navigate('/app/evaluatebid', {state: {customer: customer}})}
    id="sendButton"
    size="large"
    variant="contained"

  >
    Evaluate Bid
  </Button>

  } else if (!('state' in customer) || customer.state == 0 || customer.state == -1) {
      return <Button
      color="primary"
     
      onClick={() => navigate('/app/editquoteadmin', {state: {customer: customer}})}
      id="sendButton"
      size="large"
      variant="contained"
 
    >
      Review Quote
    </Button>
    } else if (customer.state == 2) {
      return <div>
 <Button
      color="primary"
     
      onClick={() =>  navigate('/app/assigntransporter', {state: {customer: customer}})}
      id="sendButton"
      size="large"
      variant="contained"
      style={{marginBottom: 2, width: 110}}
  
    >
      Offer   
    </Button>
    <Button
      color="primary"
     
      onClick={() =>{

        let startLat = customer.startPos.lat 
        let endLat = customer.endPos.lat 
        let startLng = customer.startPos.lng 
        let endLng = customer.endPos.lng 

        let cx = startLat + ((endLat - startLat) / 2)
        let cy = startLng + ((endLng - startLng) / 2)

        navigate('/app/editjobadmin', {state: {customer: customer, centerx: cx, centery: cy}})

      }}
      id="sendButton"
      size="large"
      variant="contained"
      style={{width: 110, marginTop: 2}}
  
    >
      Assign
    </Button>
      </div>
     
    } else if (customer.state > 2) {
      return <Button
      color="primary"
     
      onClick={() =>{

        let startLat = customer.startPos.lat 
        let endLat = customer.endPos.lat 
        let startLng = customer.startPos.lng 
        let endLng = customer.endPos.lng 

        let cx = startLat + ((endLat - startLat) / 2)
        let cy = startLng + ((endLng - startLng) / 2)

        navigate('/app/editjobadmin', {state: {customer: customer, centerx: cx, centery: cy}})

      }}
      id="sendButton"
      size="large"
      variant="contained"
  
    >
      Manage Job
    </Button>
    }
 }

 function renderStatus(customer) {
   let s = customer.state 
   if (s == -1) {
     return 'Quote Rejected'
   } else if (s == 0) {
     return 'Quote Requested'
   } else if (s==1) {
     return 'Quote Offered'
   } else if (s==2) {
     return 'Quote Accepted'
   } else if (s==3) {
     return 'Offered to Transporters'
   } else if (s==4) {
     return 'Transporter Placed Bid'
   } else if (s==5) {
     return 'Transporter Accepted Job'
   } else if (s==6) {
     return 'Assigned To Driver'
   } else if (s==7) {
     return 'En Route'
   } else if (s==8) {
     return 'Loaded'
   } else if (s==9 && !customer.jobIsPaid) {
     return 'Delivered (UNPAID)'
   } else if (s==9) {
     return 'Delivered (PAID)'
   }
 }

 function activateTextFilter(val) {
  var oldFilters = tableContext.jobsFilters
  //console.log('old text filters : ' + JSON.stringify(oldFilters))
  var found_filter = false
  oldFilters = oldFilters.map((item, idx) => {
      if (item.name == val.name) {
          found_filter = true
          item.target = val.target
          return val
      }
      return item
  })
  //console.log('oldbeforeadding')
  if (!found_filter) {
      oldFilters.push(val)
  }
  console.log('new text filters : ' + JSON.stringify(oldFilters))
  tableContext.updateFunc('jobsFilters', oldFilters)
  //set_XtextX_filters(oldFilters)

}

function deactivateTextFilter(val) {
  let newFilters = tableContext.jobsFilters.filter((item, idx) => {
      return (item.key != val)
  })
  tableContext.updateFunc('jobsFilters', newFilters)
  //set_XtextX_filters(newFilters)
}

function updateSortDirection(v, d) {
//setSortOrder(v)
//setSortDirection(d)
tableContext.updateFunc('jobsSortOrder', v)
tableContext.updateFunc('jobsSortDirection', d)
}

function applySecondaryFilters(items, filters) {
var returnList = items.filter((v, idx) => {
    let item = v
    var returnItem = true 
    filters.map((filter, filterIdx) => {
        if (filter.condition == '>') {
            if (filter.key in item && item[filter.key] > filter.target) {
                //returnItem = true
            } else {
                returnItem = false
            }
        } else if (filter.condition == '<') {
            if (filter.key in item && item[filter.key] < filter.target) {
                //returnItem = true
            } else {
              returnItem = false
            }
        } else if (filter.condition == '=') {
            if (filter.key in item && item[filter.key] === filter.target) {
                //returnItem = true
            } else {
              returnItem = false
            }
        } else if (filter.condition == 't') {
            if (filter.key in item || filter.key == 'transporter' || filter.key == 'driver') {
              

                let itemSet = TableAssist.fieldFormat(item, filter.key, 0, usersNameDict) 
                console.log(itemSet)
                console.log(filter.key)
                let itemData = itemSet.toUpperCase();

                let textData = filter.target.toUpperCase();

                if (itemData.indexOf(textData) > -1) {
                    //returnItem = true
                } else {
                  returnItem = false
                }

            } else {
              //console.log(item)
              //console.log('not in' + filter.key)
              returnItem = false
            }
        } else if (filter.condition == 'd') {
            if (filter.key in item && item[filter.key] in filter.target) {
                //returnItem = true
            } else {
              returnItem = false
            }

        }
    })


    return returnItem
})

return returnList
}

function compare2(aa, bb, key, reverse) {
      
let a = aa//TableHelper.getSortValue(aa, key)
let b = bb//TableHelper.getSortValue(bb, key)

//console.log('do a comparison of ' + a[key] + ' to ' + b[key] + ' for ' + TableAssist.renderIdDisplay(a) + ' to ' + TableAssist.renderIdDisplay(b))
var aval = a[key]
var bval = b[key]
if (key == 'transporter' || key == 'driver') {
  aval = TableAssist.fieldFormat(a,key, 0, usersNameDict)
  bval = TableAssist.fieldFormat(b,key, 0, usersNameDict)
}

if (aval < bval) {
    return -1 * ((2 * reverse) - 1);
}
if (aval > bval) {
    return ((2 * reverse) - 1);
}
return 0;
}

function sortItems(v, s) {
  //alert('sort a lsit of length' + v.length)
//console.log('try sorting')
//console.log(v)
//console.log(s)
var o = v

if (s == 0) {
  //console.log('dont sort')
    return o
}
else if (s == 1) {
  //console.log('sort a')
    o.sort((a, b) => compare2(a, b, s, tableContext.jobsSortDirection))
} else {
  //console.log('sort b')
    o.sort((a, b) => compare2(a, b, s, tableContext.jobsSortDirection))
}

//alert(JSON.stringify(o))
//return o
if (tableContext.jobsFilters.length > 0) {
  let reSet =  applySecondaryFilters(o, tableContext.jobsFilters)
  if (reSet.length != tableContext.countVal) {
    //setCountVal(reSet.length)
    if (tableContext.page != 0) {
      tableContext.updateFunc('page', 0)
    }
    tableContext.updateFunc('countVal', reSet.length)
  }
  //console.log('countaaaay ' + reSet.length)
  return reSet
}
if (o.length != tableContext.countVal) {
  //setCountVal(o.length)
  if (tableContext.page != 0) {
    tableContext.updateFunc('page', 0)
  }
  tableContext.updateFunc('countVal', o.length)
}
return o
}

function doArchiveData() {


  setArchiveDialogWorking(true)
  let s = function () {
    setArchiveAlertOpen(false)
    setArchiveDialogWorking(false)
    setSelectedCustomerIds([])
    alert('Archive Complete!')
  }

  let f = function () {
    setArchiveAlertOpen(false)
    setArchiveDialogWorking(false)
    alert('Archive Failed')
  }

  Firebase.batchArchiveData({'deletionIdList': trueSelectedCustomerIds()}, s, f)
}

function doInvoiceGen() {
  setInvoiceLoading(true)
  var jobList = {'jobs': trueSelectedJobs()}

  var userId = null
  var shouldExit = false 
  jobList.jobs.map((item, idx) => {
    if (userId != null && item.user != userId) {
      shouldExit = true 
    } else {
      userId = item.user
    }
  })

  if (shouldExit) {
    setInvoiceLoading(false)
    alert('All jobs on the invoice must be from the same shipper.')
    return 
  }

  var targetId = jobList.jobs[0].user
  var targetInvoice = TableAssist.renderIdDisplay(jobList.jobs[0])
  jobList['invoiceNumber'] = targetInvoice
  let s = async function (retval) {
    if (retval.code == -1) {
      alert('User not specified for invoicing.')
      setInvoiceLoading(false)
      return 
    }
    if (retval.code == -2) {
      alert('At least one selected job is already invoiced.')
      setInvoiceLoading(false)
      return 
    }
    console.log('succ')
    const imageRef = Firebase.storage.ref(retval.invoiceUid).child('pdf_for_invoice_' + retval.invoiceNumber + '.pdf')
    let v = await imageRef.getDownloadURL();
    console.log(v)
    console.log('got it')
    var updateSet = {'invoiceId': retval.invoiceId, 'pdflink': v}

    let s1 = async function () {
      setInvoiceLoading(false)
    }
    let f2 = async function() {
      setInvoiceLoading(false)
    }

    Firebase.provideInvoiceLink(updateSet, s1, f2)

    //window.open(v)
  }
  let f = function () {
    setInvoiceLoading(false)
    console.log('failed')
  }
  Firebase.batchInvoice(jobList, s, f)



}

function trueSelectedJobs() {

  let ids = trueSelectedCustomerIds()
  return applySecondaryFilters(customers, tableContext.jobsFilters).map((item, idx) => {
    return item
  }).filter((item, idx) => {
    return selectedCustomerIds.includes(item.quoteId)
  })
}

function trueSelectedCustomerIds() {
  if (tableContext.jobsFilters.length > 0) {
    return applySecondaryFilters(customers, tableContext.jobsFilters).map((item, idx) => {
      return item.quoteId
    }).filter((item, idx) => {
      return selectedCustomerIds.includes(item)
    })
  } else {
    return selectedCustomerIds
  }
}

function exportTableToCsv(customers) {
  let cols = [['Job Id', 
  'Shipper',
  'Driver', 
  'Transporter', 
  'Pickup Location', 
  'Dropoff Location', 
  'Commodity', 
  'Creation Date', 
  'Shipper Price/Quintal', 
  'Transporter Price/Quintal',
  'Weight',
  'Final Weight',
  'Check Number',
  'Advance Payment',
  'Advance Payment Method',
  'Final Payment Method',
  'Job Complete',
  'Job Paid']]

  let content = customers.filter((item,idx) => {
    return item.countThisJob
  }).map((item, idx) => {
    return [TableAssist.renderIdDisplay(item),
      item.shipperName,
      TableAssist.renderDriver(item, usersNameDict),
      TableAssist.renderOwner(item, usersNameDict),
      item.startName,
      item.endName,
      item.commodity,
      item.creationDate ? moment(item.creationDate).format('DD/MM/YYYY') : 'Not Set',
      item.pricePerQuintal,
      item.transporterPricePerQuintal,
      item.trueWeight * 10,
      item.finalWeight * 10,
      item.checkNumber,
      item.advancePayment,
      item.paymentMethodInitial,
      item.paymentMethod,
      item.state >= 9 ? 'YES' : 'NO',
      item.jobIsPaid ? 'YES' : 'NO'
    ]
  })

  let csvfull = cols.concat(content)
  setGeneratedCsvData(csvfull)
  setGeneratedCsvTime(Date.now())

}


function renderBody(isLimited) {
  if (isLimited < 1) {
    return (
      <TableBody>
              {sortItems(customers, tableContext.jobsSortOrder).slice(tableContext.page * tableContext.limit, tableContext.page * tableContext.limit + tableContext.limit).map((customer) => (
                <TableRow
                  hover
                  key={customer.quoteId}
                  selected={selectedCustomerIds.indexOf(customer.quoteId) !== -1}
                 
                >
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={selectedCustomerIds.indexOf(customer.quoteId) !== -1}
                      onChange={(event) => handleSelectOne(event, customer.quoteId)}
                      value="true"
                    />
                  </TableCell>
                  <TableCell>
                  <Link
              to={customer}
              onClick={ () => window.open(Firebase.adminip + 'info/' + TableAssist.renderIdValue(customer))}
              variant="h6"
            >{TableAssist.renderIdDisplay(customer)}</Link>
                  </TableCell>
                  <TableCell>
                    {customer.commodity}
                  </TableCell>
                  <TableCell>
                    {customer.startName}
                  </TableCell>
                  <TableCell >
                    {customer.endName}
                  </TableCell>
                  <TableCell>
                    {customer.creationDate ? moment(customer.creationDate).format('DD/MM/YYYY') : 'Not Set'}
                  </TableCell>
                  <TableCell>
                    {renderStatus(customer)}
                  </TableCell>
                  <TableCell>
                    {renderAction(customer)}
                  </TableCell>    
                 <TableCell>
                    {TableAssist.renderOwner(customer, usersNameDict)}
                  </TableCell>
                  <TableCell>
                    {TableAssist.renderDriver(customer, usersNameDict)}
                  </TableCell>
                  <TableCell >
                    {customer.vehiclePlate}
                  </TableCell>
                  <TableCell >
                    {customer.vehicleTrailerPlate}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
    )
  } else {
    return (
      <TableBody>
              {sortItems(customers, tableContext.jobsSortOrder).slice(tableContext.page * tableContext.limit, tableContext.page * tableContext.limit + tableContext.limit).map((customer) => (
                <TableRow
                  hover
                  key={customer.quoteId}
                  selected={selectedCustomerIds.indexOf(customer.quoteId) !== -1}
                 
                >
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={selectedCustomerIds.indexOf(customer.quoteId) !== -1}
                      onChange={(event) => handleSelectOne(event, customer.quoteId)}
                      value="true"
                    />
                  </TableCell>
                  <TableCell>
                  <Link
              to={customer}
              onClick={ () => window.open(Firebase.adminip + 'info/' + TableAssist.renderIdValue(customer))}
              variant="h6"
            >{TableAssist.renderIdDisplay(customer)}</Link>
                  </TableCell>
                  <TableCell>
                    {customer.commodity}
                  </TableCell>
                  <TableCell>
                    {customer.startName}
                  </TableCell>
                  <TableCell >
                    {customer.endName}
                  </TableCell>
                  <TableCell>
                    {customer.creationDate ? moment(customer.creationDate).format('DD/MM/YYYY') : 'Not Set'}
                  </TableCell>
                  <TableCell>
                    {renderStatus(customer)}
                  </TableCell>
                  <TableCell>
                    {renderAction(customer)}
                  </TableCell>    
                  <TableCell >
                    {customer.vehiclePlate}
                  </TableCell>
                  <TableCell >
                    {customer.vehicleTrailerPlate}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
    )
  }
}

function renderHeaders(isLimited) {
  if (isLimited < 1) {
    return (<TableRow>
      <TableCell padding="checkbox">
        <Checkbox
          checked={selectedCustomerIds.length === customers.length}
          color="primary"
          indeterminate={
            selectedCustomerIds.length > 0
            && selectedCustomerIds.length < customers.length
          }
          onChange={handleSelectAll}
        />
      </TableCell>
      <HeaderComponent sortSet={(v, d) => updateSortDirection(v, d)} text={'ID'} itemkey={'shortId'} activate={(v) => activateTextFilter(v)} deactivate={(v) => deactivateTextFilter(v)} />
      <HeaderComponent sortSet={(v, d) => updateSortDirection(v, d)} text={'Commodity'} itemkey={'commodity'} activate={(v) => activateTextFilter(v)} deactivate={(v) => deactivateTextFilter(v)} />
      <HeaderComponent sortSet={(v, d) => updateSortDirection(v, d)} text={'Pickup'} itemkey={'startName'} activate={(v) => activateTextFilter(v)} deactivate={(v) => deactivateTextFilter(v)} />
      <HeaderComponent sortSet={(v, d) => updateSortDirection(v, d)} text={'Dropoff'} itemkey={'endName'} activate={(v) => activateTextFilter(v)} deactivate={(v) => deactivateTextFilter(v)} />
      <HeaderComponent sortSet={(v, d) => updateSortDirection(v, d)} text={'Created'} itemkey={'creationDate'} activate={(v) => activateTextFilter(v)} deactivate={(v) => deactivateTextFilter(v)} />
      <HeaderComponent sortSet={(v, d) => updateSortDirection(v, d)} text={'Status'} itemkey={'state'} activate={(v) => activateTextFilter(v)} deactivate={(v) => deactivateTextFilter(v)} />
      <TableCell>
        Action
      </TableCell>
     <HeaderComponent sortSet={(v, d) => updateSortDirection(v, d)} text={'Transporter'} itemkey={'transporter'} activate={(v) => activateTextFilter(v)} deactivate={(v) => deactivateTextFilter(v)} />
       <HeaderComponent sortSet={(v, d) => updateSortDirection(v, d)} text={'Driver'} itemkey={'driver'} activate={(v) => activateTextFilter(v)} deactivate={(v) => deactivateTextFilter(v)} />
      <HeaderComponent sortSet={(v, d) => updateSortDirection(v, d)} text={'Vehicle'} itemkey={'vehiclePlate'} activate={(v) => activateTextFilter(v)} deactivate={(v) => deactivateTextFilter(v)} />
      <HeaderComponent sortSet={(v, d) => updateSortDirection(v, d)} text={'Trailer'} itemkey={'vehicleTrailerPlate'} activate={(v) => activateTextFilter(v)} deactivate={(v) => deactivateTextFilter(v)} />

    </TableRow>)
  } else {
    return (<TableRow>
      <TableCell padding="checkbox">
        <Checkbox
          checked={selectedCustomerIds.length === customers.length}
          color="primary"
          indeterminate={
            selectedCustomerIds.length > 0
            && selectedCustomerIds.length < customers.length
          }
          onChange={handleSelectAll}
        />
      </TableCell>
      <HeaderComponent sortSet={(v, d) => updateSortDirection(v, d)} text={'ID'} itemkey={'shortId'} activate={(v) => activateTextFilter(v)} deactivate={(v) => deactivateTextFilter(v)} />
      <HeaderComponent sortSet={(v, d) => updateSortDirection(v, d)} text={'Commodity'} itemkey={'commodity'} activate={(v) => activateTextFilter(v)} deactivate={(v) => deactivateTextFilter(v)} />
      <HeaderComponent sortSet={(v, d) => updateSortDirection(v, d)} text={'Pickup'} itemkey={'startName'} activate={(v) => activateTextFilter(v)} deactivate={(v) => deactivateTextFilter(v)} />
      <HeaderComponent sortSet={(v, d) => updateSortDirection(v, d)} text={'Dropoff'} itemkey={'endName'} activate={(v) => activateTextFilter(v)} deactivate={(v) => deactivateTextFilter(v)} />
      <HeaderComponent sortSet={(v, d) => updateSortDirection(v, d)} text={'Created'} itemkey={'creationDate'} activate={(v) => activateTextFilter(v)} deactivate={(v) => deactivateTextFilter(v)} />
      <HeaderComponent sortSet={(v, d) => updateSortDirection(v, d)} text={'Status'} itemkey={'state'} activate={(v) => activateTextFilter(v)} deactivate={(v) => deactivateTextFilter(v)} />
      <TableCell>
        Action
      </TableCell>
      <HeaderComponent sortSet={(v, d) => updateSortDirection(v, d)} text={'Vehicle'} itemkey={'vehiclePlate'} activate={(v) => activateTextFilter(v)} deactivate={(v) => deactivateTextFilter(v)} />
      <HeaderComponent sortSet={(v, d) => updateSortDirection(v, d)} text={'Trailer'} itemkey={'vehicleTrailerPlate'} activate={(v) => activateTextFilter(v)} deactivate={(v) => deactivateTextFilter(v)} />

    </TableRow>)
  }
}

  return (
    <div  style={{flexDirection: 'column'}}>
      <AlertDialog open={archiveAlertOpen}
      dialogWorking={archiveDialogWorking}
      onClose={() => setArchiveAlertOpen(false)}
      alertTitle={'Archive ' + trueSelectedCustomerIds().length + ' items?'}
      alertBody={'If you choose to proceed, none of these quotes, requests, or jobs will be accessible to customers. The data will not be lost, but will be switched to archive mode and be invisible to customers and administrators until it is retrieved again.'}
      alertCancelText={'Cancel Archive'}
      alertConfirmText={'Archive Data'}
      onConfirm={() => doArchiveData()}
      />
      {isLimited >= 0 ? null :
      <div style={{display: 'flex', flexDirection: 'row', alignItems: 'flex-end', justifyContent: 'space-between'}}><Button
      color="primary"
      variant="contained"
      style={{marginLeft: 10, marginRight: 10, backgroundColor: 'red'}}
      onClick={() => trueSelectedCustomerIds().length <= 0 ? alert('Select at least one item to archive.'): setArchiveAlertOpen(true)}
    >
      Batch Archive
    </Button>
    {invoiceLoading ? <div style={{display: 'flex', flexDirection: 'row'}}>
      <a>Generating Invoice...</a>
      <CircularProgress/>
    </div>
  : <Button
  color="primary"
  variant="contained"
  style={{marginLeft: 10, marginRight: 10, backgroundColor: 'green'}}
  onClick={() => trueSelectedCustomerIds().length <= 0 ? alert('Select at least one item to invoice.'): doInvoiceGen()}
>
  Batch Invoice
</Button>}
<div style={{flexDirection: 'row', display: 'flex'}}>
<Button
      color="primary"
      variant="contained"
      style={{marginLeft: 10, marginRight: 10, backgroundColor: 'mediumspringgreen'}}
      onClick={() => exportTableToCsv(customers)}
    >
      Export to CSV
    </Button>
{generatedCsvData != null ? 
    <CSVLink filename={'jobs-garri.csv'} data={generatedCsvData}>{'Click to download CSV Generated at ' + (generatedCsvTime ? moment(generatedCsvTime).format('DD/MM/YYYY hh:mm') : 'NULL')}</CSVLink>
  : null}
</div>

    </div>
      }
      
          


          <SearchWrapper  isLimited={isLimited} activate={(v) => activateTextFilter(v)} deactivate={(v) => deactivateTextFilter(v)}/>
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <PerfectScrollbar>
        <Box minWidth={1050}>
        <div style={{ flexDirection: 'row', display: 'flex', marginBottom: 20 }}>
                    {
                        tableContext.jobsFilters.map((item, idx) => {
                            return (
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', backgroundColor: 'rgb(230,230,230)', borderRadius: 30, padding: 0, margin: 0, height: 30, padding: 10, margin: 5, }}>
                                    <p>{item.name + ' contains ' + item.target}</p>
                                    <X onClick={() => deactivateTextFilter(item.key)} />
                                </div>
                                )
                        })
                    }
                </div>
          <Table>
            <TableHead>
              {renderHeaders(isLimited)}
            </TableHead>
            {renderBody(isLimited)}
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={tableContext.countVal}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handleLimitChange}
        page={tableContext.page}
        rowsPerPage={tableContext.limit}
        rowsPerPageOptions={[5, 10, 25]}
      />
    </Card></div>
  );
};

UserQuoteTable.propTypes = {
  className: PropTypes.string,
  customers: PropTypes.array.isRequired
};

export default UserQuoteTable;
