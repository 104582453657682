import React, {useState, useReducer} from 'react';
import { Link as RouterLink, useNavigate, useLocation} from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';

import LocationOnIcon from '@material-ui/icons/LocationOn';
import {
  Box,
  Button,
  Checkbox,
  Container,
  FormHelperText,
  Link,
  TextField,
  Typography,
  makeStyles,
  Grid,
  Select,
  MenuItem,
  Stepper,
  StepLabel,
  Step,
  CircularProgress
} from '@material-ui/core';
import Page from 'src/components/Page';
import parse from 'autosuggest-highlight/parse';
import throttle from 'lodash/throttle';
import Firebase from 'src/Firebase'
import MuiPhoneNumber from 'material-ui-phone-number'
import { parseWithOptions } from 'date-fns/fp';
import MyContext from 'src/MyContext';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { DataGrid, GridToolbar, GridToolbarContainer, GridToolbarColumnsButton, GridToolbarFilterButton, GridToolbarDensitySelector, GridToolbarExport } from '@mui/x-data-grid';
import { arrayOf } from 'prop-types';

function CustomToolbar(props) {

  if (props.allowExport) {
    return <GridToolbar/>
  } else {
    return (
      <GridToolbarContainer >
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector/>
      </GridToolbarContainer>
    );
  }

}

const filter = createFilterOptions();
const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/


const autocompleteService = { current: null };
const geocoder = {current: null};
const steps = ['Basic Information', 'Associated Vessel Operations', 'Associated Yearly Plans', 'Allocation Details'];



const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  }
}));


const ViewTrainPlanView = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const {state} = useLocation();
  const [loading, setLoading] = React.useState(false);
  const [autoValues, setAutoValues] = React.useState({});
  const [selection, setSelection] = useState([]);
  const [opSelection, setOpSelection] = useState([]);
  const [planSelection, setPlanSelection] = useState([]);
  const [allocations, setAllocations] = useState(state && state.stateVal && state.stateVal.allocations ? state.stateVal.allocations : {});
  const [sentAmounts, setSentAmounts] = useState(state && state.stateVal && state.stateVal.sentAmounts ? state.stateVal.sentAmounts : {});
  
  const [transporterAllocations, setTransporterAllocations] = useState(state && state.stateVal && state.stateVal.transporterAllocations ? state.stateVal.transporterAllocations : {})
  const [estimatedValues, setEstimatedValues] = useState({});
  const [activeStep, setActiveStep] = useState(0);
  const [ignored, forceUpdate] = useReducer(x => x + 1, 0);

  const [sortModel, setSortModel] = React.useState([
    {
      field: 'allocation',
      sort: 'desc',
    },
  ]);

  const handleNext = () => {


    setActiveStep((prevActiveStep) => prevActiveStep + 1);

  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  function getType(t) {
    if (t == 'date') {
      return 'date'
    } else if (t == 'number') {
      return 'number' 
    } else if (t == 'list' || t == 'custom') {
      return t
    } else {
      return null
    }
  }

  function updateAutoValues(k, v) {
    var o = autoValues
    o[k] = v 
    setAutoValues(o)
  }

  function getAutoOptions(context, k) {
    if (k in context.barleyInCustomObjects) {
      return Object.keys(context.barleyInCustomObjects[k]).map((key, id) => {
        return context.barleyInCustomObjects[k][key]
      })
    }
    return []
  }

 
    

    function getColumns() {
      return [
        {
          field: 'facility',
          headerName: 'Destination',
          width: 150,
          editable: false,
        },

        {
          field: 'allocation',
          headerName: 'Allocation (Metric Tons)',
          type: 'number',
          width: 220,
          editable: false,
        },

        {
          field: 'sentAmount',
          headerName: 'Amount Sent (Metric Tons)',
          type: 'number',
          width: 220,
          editable: false,
        },

        {
          field: 'transporter',
          headerName: 'Default Transporter',
          width: 220,
          editable: false
        },
        {
          field: 'union',
        headerName: 'Union',
        width: 150,
        editable: false
      }
      ]
    }

    function kgToTons(x) {
      if (!x) {
        return 0
      }
      return (x/1000).toFixed(2)
    }

    function getRows(context, allocationValues, transporterAllocationsV, sentAmts) {

      let wh = getAutoOptions(context, 'warehouse') 
      /*console.log(row)
      if (row == null || row.sku == null || row.sku.name == null) {
        return [] 
      }
      let query = {'sku': row.sku.name}
      let dest = ''
      console.log(row)
      
      if (row.warehouse && row.warehouse.name) {
        dest = row.warehouse.name 
      } else {
        return []
      }
      console.log(query)
      let cdt = this.getAllByCategory(context.barleyStocksDict, context.barleyStocksPendingDict,
        context.barleyStocksTransitDict, this.getDictId(query), dest)
      
      return Object.keys(cdt).map((key, idx) => {
        let item = cdt[key]
        return {'stock': item, 'facility': key, 'allocation': key in allocationValues ? allocationValues[key] : 0, id: key}
      })*/
      return wh.map((item, idx) => {
        return {'id': item.customId, 'facility': item.name, 'union': item.union, 'stock': 0, 'allocation': item.customId in allocationValues ? allocationValues[item.customId] : 0,
       'transporter': item.customId in transporterAllocationsV ? transporterAllocationsV[item.customId] : '', 'sentAmount': item.customId in sentAmts ? kgToTons(sentAmts[item.customId]) : 0}
      })
  
    }

    function onCellChangeOp(value/*, approvalInfo*/) {

      var al = estimatedValues
      al[value.id] = value.value 
      setEstimatedValues(al)
      forceUpdate()
    
    }

    function onCellChange(value/*, approvalInfo*/) {
      console.log(value)

      if (value.field == 'allocation') {
        var al = allocations
        al[value.id] = value.value 
        setAllocations(al)
        forceUpdate()
      } else if (value.field == 'transporter') {
        var al = transporterAllocations
        al[value.id] = value.value 
        setTransporterAllocations(al)
        forceUpdate()
      }
      /*console.log(value)
      console.log(this.state.categoryDict[value.id])
  
      var allocationSum = 0
      Object.keys(this.state.allocationValues).map((key, idx) => {
        allocationSum += this.state.allocationValues[key]
      })
  
  
      let oldVal = (value.id in this.state.allocationValues) ? this.state.allocationValues[value.id] : 0
  
      allocationSum += (value.value - oldVal)
  
      console.log('allocationsum is ' + allocationSum)
  
      if (!(value.id in this.state.categoryDict) || this.state.categoryDict[value.id] < value.value) {
        var s = this.state.allocationValues
        s[value.id] = 0
        this.setState({
          allocationValues: s
        })
        console.log('reset')
      } else if (allocationSum > approvalInfo.row.netweightkg) {
        var s = this.state.allocationValues
        s[value.id] = 0
        this.setState({
          allocationValues: s
        })
        console.log('reset ' + approvalInfo.row.netweightkg)
      } else {
        var s = this.state.allocationValues
        s[value.id] = value.value
        this.setState({
          allocationValues: s
        })
      }*/

    
    }
  


  return (
    <MyContext.Consumer>{context => (
  <Page
    className={classes.root}
    height='100%'
    title="New Train Plan"
    id="ff2"
    backgroundColor={classes.root.backgroundColor}
  >
    <Box
      display="flex"
      flexDirection="column"
      id="bb2"

      backgroundColor={classes.root.backgroundColor}

    >
      <Container id="cc2" backgroundColor={classes.root.backgroundColor}>
       
  <div style={{height: 550, width: 1000}}>
    <div style={{flexDirection: 'row'}}>
    <Typography
      color="textPrimary"
      variant="h5"
    >
      {state && state.stateVal ? 'Destination Plans: ' + state.stateVal.name : 'Destination Plans'}
    </Typography>
    <Button
          color="primary"
          variant="contained"
          onClick={ () => navigate('/app/edittrain', {state: {customer: state.stateVal, fields: state.fields}, replace: true})}
        >
         Edit Train Plan
        </Button>

    </div>

<DataGrid
key="plantab"
density='compact'
style={{backgroundColor: 'white'}}
rows={getRows(context,allocations, transporterAllocations, sentAmounts)}
columns={getColumns()}
density={'compact'}
pageSize={20}
rowsPerPageOptions={[20]}
onCellEditCommit={(p, e, d) => onCellChange(p, /*this.state.approvalInfo*/)}
hideFooterPagination={false}
hideFooter={false}
checkboxSelection={true}
disableSelectionOnClick
selectionModel={selection}
sortModel={sortModel}
components={{
  Toolbar: CustomToolbar,
}}
componentsProps={{ toolbar: { allowExport: false } }}
onSelectionModelChange={(newSelection) => {
setSelection(newSelection);
}}
/>
</div>

      </Container>
    </Box>
  </Page>
        )}</MyContext.Consumer>
  )
};

export default ViewTrainPlanView;
