import React, {useState} from 'react';
import { Link as RouterLink, useNavigate, useLocation} from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';

import LocationOnIcon from '@material-ui/icons/LocationOn';
import {
  Box,
  Button,
  Checkbox,
  Container,
  FormHelperText,
  Link,
  TextField,
  Typography,
  makeStyles,
  Grid,
  Select,
  MenuItem,
  CircularProgress
} from '@material-ui/core';
import Page from 'src/components/Page';
import parse from 'autosuggest-highlight/parse';
import throttle from 'lodash/throttle';
import Firebase from 'src/Firebase'
import MuiPhoneNumber from 'material-ui-phone-number'
import { parseWithOptions } from 'date-fns/fp';
import MyContext from 'src/MyContext';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { arrayOf } from 'prop-types';
import { v4 as uuidv4 } from 'uuid';

const filter = createFilterOptions();
const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
/*

Traveler // Variety purity >=90% // Moisture =<15%
Traveler //  Variety purity <90% // Moisture =<15%
Traveler // Variety all purity // Moisture >15%
Fatima // Variety purity >=90% // Moisture =<15%
Fatima // Variety purity <90% // Moisture =<15%
Fatima //  Variety all purity // Moisture >15%
Planet // Variety purity >=90% // Moisture =<15%
Planet // Variety purity <90% // Moisture =<15%
Planet //  Variety all purity // Moisture >15%
All other varieties // Variety all purity // Moisture =<15%
All other varieties // Variety all purity // Moisture >15%
*/


const outOptions = [
{'name': 'Traveler // Variety purity >=90% // Moisture =<15%', 'customId': 0, 'query': {'variety': 'Traveler', 'moisture': 'below', 'purity': 'above', 'uncategorized': false}},
{'name': 'Traveler //  Variety purity <90% // Moisture =<15%', 'customId': 1, 'query': {'variety': 'Traveler', 'moisture': 'below', 'purity': 'below', 'uncategorized': false}},
{'name': 'Traveler // Variety all purity // Moisture >15%', 'customId': 2, 'query': {'variety': 'Traveler', 'moisture': 'above', 'uncategorized': false}},
{'name': 'Fatima // Variety purity >=90% // Moisture =<15%', 'customId': 3, 'query': {'variety': 'Fatima', 'moisture': 'below', 'purity': 'above', 'uncategorized': false}},
{'name': 'Fatima // Variety purity <90% // Moisture =<15%', 'customId': 4, 'query': {'variety': 'Fatima', 'moisture': 'below', 'purity': 'below', 'uncategorized': false}},
{'name': 'Fatima //  Variety all purity // Moisture >15%', 'customId': 5, 'query': {'variety': 'Fatima', 'moisture': 'above', 'uncategorized': false}},
{'name': 'Planet // Variety purity >=90% // Moisture =<15%', 'customId': 6, 'query': {'variety': 'Planet', 'moisture': 'below', 'purity': 'above', 'uncategorized': false}},
{'name': 'Planet // Variety purity <90% // Moisture =<15%', 'customId': 7, 'query':  {'variety': 'Planet', 'moisture': 'below', 'purity': 'below', 'uncategorized': false}},
{'name': 'Planet //  Variety all purity // Moisture >15%', 'customId': 8, 'query': {'variety': 'Planet', 'moisture': 'above', 'uncategorized': false}},
{'name': 'All other varieties // Variety all purity // Moisture =<15%', 'customId': 9, 'query': {'variety': 'other', 'moisture': 'below', 'uncategorized': false}},
{'name': 'All other varieties // Variety all purity // Moisture >15%', 'customId': 10, 'query': {'variety': 'other', 'moisture': 'above', 'uncategorized': false}},
{'name': 'Uncategorized', 'customId': 11, 'query': {'uncategorized': true}}]
const autocompleteService = { current: null };
const geocoder = {current: null};



const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  }
}));


const NewBarleyOutView = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const {state} = useLocation();
  const [loading, setLoading] = React.useState(false);
  const [autoValues, setAutoValues] = React.useState({});
  const [source, setSource] = React.useState(null);
  const [category, setCategory] = React.useState(null);
  const [sku, setSku] = React.useState(null);
  const [destination, setDestination] = React.useState(null);
  const [amount, setAmount] = React.useState(null);
  const [totalVal, setTotalVal] = React.useState(0);


  function determineStockMembership(val) {
    console.log('determine membership for')
    console.log(val)
    /**
     *  1. Traveler // Variety purity >=90% // Moisture =<15%
        2. Traveler //  Variety purity <90% // Moisture =<15%
        3. Traveler // Variety all purity // Moisture >15%
        4. Fatima // Variety purity >=90% // Moisture =<15%
        5. Fatima // Variety purity <90% // Moisture =<15%
        6. Fatima //  Variety all purity // Moisture >15%
        7. Planet // Variety purity >=90% // Moisture =<15%
        8. Planet // Variety purity <90% // Moisture =<15%
        9. Planet //  Variety all purity // Moisture >15%
        10. All other varieties // Variety all purity // Moisture =<15%
        11. All other varieties // Variety all purity // Moisture >15%
     */
    var warehouse = 'None'
    if (val.warehouse && val.warehouse.name) {
      warehouse = val.warehouse.name
    }

    var uncategorized = false 
    
    if (!val.moisture || !val.variety) {
      // if moisture or variety is not defined, we are done, we just need uncategorized to be true
      uncategorized = true 
      return {'uncategorized': true, 'warehouse': warehouse}
    } 

    var variety = val.variety
      if (val.variety == 'Traveler' || val.variety == 'Fatima' || val.variety == 'Planet') {
        variety = val.variety 
      } else {
        variety = 'other'
      }

    if (val.moisture > 15) {
      // if moisture is above 15, the only other characteristic we need is variety
      // this accounts for the following options, where purity does not matter:
      /*
      3. Traveler // Variety all purity // Moisture >15%
      6. Fatima //  Variety all purity // Moisture >15%
      9. Planet //  Variety all purity // Moisture >15%
      11. All other varieties // Variety all purity // Moisture >15%
      */

      return {'uncategorized': false, 'warehouse': warehouse, 'variety': variety, 'moisture': 'above'}
    } else if (!val.purity) {
      // if purity is not defined and moisture is <= 15
      // this makes it case 10 IF variety is already at 'other', else it becomes uncategorized
      if (variety == 'other') {
        // 10. All other varieties // Variety all purity // Moisture =<15%
        return {'uncategorized': false, 'warehouse': warehouse, 'variety': variety, 'moisture': 'below'}
      } else {
        return {'uncategorized': true, 'warehouse': warehouse}
      }

    } else {
      // purity, variety, and moisture are all defined
      // moisture is below 

      if (variety == 'other') {
        // 10. All other varieties // Variety all purity // Moisture =<15%
        return {'uncategorized': false, 'warehouse': warehouse, 'variety': variety, 'moisture': 'below'}
      }

      var purity = 'above'
      if (val.purity < 90) {
        purity = 'below'
      }
      /*
      1. Traveler // Variety purity >=90% // Moisture =<15%
      2. Traveler //  Variety purity <90% // Moisture =<15%
      4. Fatima // Variety purity >=90% // Moisture =<15%
      5. Fatima // Variety purity <90% // Moisture =<15%
      7. Planet // Variety purity >=90% // Moisture =<15%
      8. Planet // Variety purity <90% // Moisture =<15%
      */
      return {'uncategorized': false, 'warehouse': warehouse, 'variety': variety, 'moisture': 'below', 'purity': purity }
    }

  }

  function getDictId(dct) {
    var keys = Object.keys(dct)
    keys.sort()
    var s = ''
    for (var i = 0; i < keys.length; i++) {
      let key = keys[i]
      if (i > 0) {
        s += '-'
      }
      s += key 
      s += ':'
      s += dct[key]
    }

    return s 
  }


  function getType(t) {
    if (t == 'date') {
      return 'date'
    } else if (t == 'number') {
      return 'number' 
    } else if (t == 'list' || t == 'custom') {
      return t
    } else {
      return null
    }
  }

  function updateAutoValues(k, v) {
    var o = autoValues
    o[k] = v 
    setAutoValues(o)
  }

  function getAutoOptions(context, k) {
    if (k in context.barleyInCustomObjects) {
      return Object.keys(context.barleyInCustomObjects[k]).map((key, id) => {
        return context.barleyInCustomObjects[k][key]
      })
    }
    return []
  }

  function matchTarget(context) {
    var ret = {'name': 'Loading...'}
    let k = 'warehouse'
    if (k in context.barleyInCustomObjects) {
      Object.keys(context.barleyInCustomObjects[k]).map((key, id) => {
        if (context.barleyInCustomObjects[k][key].name == context.targetWarehouse.name) {
          ret = context.barleyInCustomObjects[k][key]
        }
      })
    }
    return ret
  }

  function renderContent(fields, touched, errors, handleBlur, handleChange, values, setFieldValue, context) {
    var columns = Math.floor(fields.length / 3)
    if (columns * 3 < fields.length) {
      columns += 1
    }
    var colrow = [...Array(columns).keys()]
    var rows = [0,1,2]
    return <div>
      {colrow.map((item, idx) => {
        return <div style={{display: 'flex', flexDirection: 'row'}}>
        {rows.map((inner, innerI) => {
          let fieldIdx = (3*item) + inner 

          if (fieldIdx < fields.length) {
            let tp = getType(fields[fieldIdx].fieldType)
            if (tp == 'custom') {

             return <Autocomplete
              value={autoValues[fields[fieldIdx].fieldKey]}
              fullWidth
              onChange={(event, newValue) => {
                if (typeof newValue === 'string') {
                  updateAutoValues(fields[fieldIdx].fieldKey, {name: newValue})
                  setFieldValue(fields[fieldIdx].fieldKey, {'customId': newValue.customId, 'name': newValue.name})

                } else if (newValue && newValue.inputValue) {
                  // Create a new value from the user input
                  updateAutoValues(fields[fieldIdx].fieldKey, {name: newValue.inputValue})
                  setFieldValue(fields[fieldIdx].fieldKey, {'customId':-1, 'name': newValue.inputValue})
                } else {
                  updateAutoValues(fields[fieldIdx].fieldKey, newValue)
                  if (newValue == null) {
                    setFieldValue(fields[fieldIdx].fieldKey, {'customId': '0', 'name': 'None'})
                  } else {
                    setFieldValue(fields[fieldIdx].fieldKey, {'customId': newValue.customId, 'name': newValue.name})
                  }
                }
                //console.log(event.target.value)
                //console.log(newValue)
              }}
              filterOptions={(options, params) => {
                const filtered = filter(options, params);
        
                // Suggest the creation of a new value
                if (params.inputValue !== '') {
                  filtered.push({
                    inputValue: params.inputValue,
                    name: `Add "${params.inputValue}"`,
                  });
                }
        
                return filtered;
              }}
              selectOnFocus
              clearOnBlur
              handleHomeEndKeys
              id="free-solo-with-text-demo"
              options={getAutoOptions(context, fields[fieldIdx].fieldKey)}
              getOptionLabel={(option) => {
                // Value selected with enter, right from the input
                if (typeof option === 'string') {
                  return option;
                }
                // Add "xxx" option created dynamically
                if (option.inputValue) {
                  return option.inputValue;
                }
                // Regular option
                return option.name;
              }}
              renderOption={(option) => option.name}
              freeSolo
              renderInput={(params) => (
                <TextField {...params}  error={Boolean(touched[fields[fieldIdx].fieldKey] && errors[fields[fieldIdx].fieldKey])}
                fullWidth
                id={fields[fieldIdx].fieldKey}
                helperText={touched[fields[fieldIdx].fieldKey] && errors[fields[fieldIdx].fieldKey]}
                label={fields[fieldIdx].fieldName}
                margin="normal"
                name={fields[fieldIdx].fieldKey}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values[fields[fieldIdx].fieldKey]}
                variant="outlined"/>
              )}
            />



            } else if (tp == 'list') {
              let opts = fields[fieldIdx].listOptions
              return <TextField
              select 
              error={Boolean(touched[fields[fieldIdx].fieldKey] && errors[fields[fieldIdx].fieldKey])}
              fullWidth
              id={fields[fieldIdx].fieldKey}
              helperText={touched[fields[fieldIdx].fieldKey] && errors[fields[fieldIdx].fieldKey]}
              label={fields[fieldIdx].fieldName}
              margin="normal"
              name={fields[fieldIdx].fieldKey}
              onBlur={handleBlur}
              onChange={handleChange}
              value={values[fields[fieldIdx].fieldKey]}
              variant="outlined">
              {opts.map((item, idx) => {
                return (<MenuItem value={item}>{item}</MenuItem>)
              })}
            </TextField>
            } else {
              return <TextField
              error={Boolean(touched[fields[fieldIdx].fieldKey] && errors[fields[fieldIdx].fieldKey])}
              fullWidth
              id={fields[fieldIdx].fieldKey}
              helperText={touched[fields[fieldIdx].fieldKey] && errors[fields[fieldIdx].fieldKey]}
              label={fields[fieldIdx].fieldName}
              margin="normal"
              name={fields[fieldIdx].fieldKey}
              onBlur={handleBlur}
              onChange={handleChange}
              value={values[fields[fieldIdx].fieldKey]}
              variant="outlined"
              type={getType(fields[fieldIdx].fieldType)}
              InputLabelProps={{
                shrink: true,
                }}
            />
            }

          }
          
          return null 

        })}
       </div>
      })}
    </div>
  }

  async function doSubmit(context, v) {

    let destinationVal = destination
    if (context.targetWarehouse != -1 && context.targetWarehouse && context.targetWarehouse.name ) {
      destinationVal = matchTarget(context)
    }

    var actualAmount = parseInt(amount)// * (907.185)
    //actualAmount = actualAmount.toFixed(2)

    if (sku == null ||  destinationVal == null || actualAmount == null || actualAmount <= 0) {
      alert('Please select a, category, destination, and amount of barley to request.')
      return
    }
    /*if (source == destination) {
      alert('Source and destination must differ.')
      return
    }*/

    if (actualAmount > totalVal || totalVal == 0 || actualAmount <= 0) {
      alert('Not enough stock for this selection.')
      return
    }

    var transaction = null

    if (Firebase.ONLINE) {

      setLoading(true)


      var newVal = v 
      newVal['netweightkg'] = actualAmount
      newVal['usedkg'] = 0
      newVal['warehouse'] = destinationVal
      newVal['creationDate'] = Date.now()
      newVal['category'] = category
      newVal['sku'] = sku
  
      var baseSet = {'sku': sku.name}
      //baseSet['warehouse'] = source.name
      var newSet = {'sku': sku.name}
      newSet['warehouse'] = destinationVal.name
  
      //newVal['source'] = source.name
      console.log(baseSet)
      console.log(newSet)
      console.log(newVal)
      //return 
      transaction = await Firebase.createBarleyOut({'project': Firebase.userProject, 'newVal': newVal, 'newSet': newSet, 'baseSet': baseSet, 'fields': state.fields})
      console.log('resulting')
      console.log(transaction)
      Firebase.trackMixpanel('createRequestOnline', {'result': transaction})

    } else {
      setLoading(true)


      var newVal = v 
      newVal['netweightkg'] = actualAmount
      newVal['warehouse'] = destinationVal
      newVal['creationDate'] = Date.now()
      newVal['category'] = category
  
      var baseSet = JSON.parse(JSON.stringify(category.query))
     // baseSet['warehouse'] = source.name
      var newSet = JSON.parse(JSON.stringify(category.query)) 
      newSet['warehouse'] = destinationVal.name
  
      //newVal['source'] = source.name
      let stockKey = getDictId(newSet)
      let oldStockKey = getDictId(baseSet)
      let docRef1 = Firebase.firestore.collection('barleyConstants').doc('grnOutNumber');
      let docRef2 = Firebase.firestore.collection('barleyOut').doc()
      let stockDocRef = Firebase.firestore.collection('barleyStocksPending').doc(oldStockKey)
      let stockDocRefOld = Firebase.firestore.collection('barleyStocks').doc(oldStockKey)
      let newValId = docRef2.id
      var oldNumericSave = null;
      var newNumericValue = null;
      var oldNumericValue = null;
      var oldQueryResult = null; 
      var newQueryResult = null;
  
  
      var customCreations = {}
      state.fields.map((field, idx) => {
        if (field.fieldType == 'custom') {
          if (field.fieldKey in newVal && newVal[field.fieldKey]['customId'] == -1) {
            // must create this val 
            customCreations[field.fieldKey] =  newVal[field.fieldKey]['name']
          }
        }
      })
  
      let arrayOfMemberIds = Object.keys(customCreations)
      var transaction = null 
      var failMessage = 'Error creating report, try again.'

      let previousStock = await stockDocRefOld.get().then((docx) => {
        if (docx.exists) {
            //console.log("Document data:", doc.data());
            return docx.data()
        } else {
            // doc.data() will be undefined in this case
            //console.log("No such document!");
            return null 
        }
      }).catch((error) => {
          console.log("Error getting document:", error);
          return null 
      });

      if (!previousStock){
        alert('The specified stock does not exist.')
        setLoading(false)
        return 
      } else if (!previousStock['netweightkg'] || previousStock['netweightkg'] < newVal['netweightkg']) {
        alert("Not enough barley. Stock contains " + previousStock['netweightkg'] + "kg, while request was for " + newVal['netweightkg'] + "kg.")
        setLoading(false)
        return 
      }

      try {
  
      var t = Firebase.firestore.batch()
      oldNumericSave = uuidv4()
      arrayOfMemberIds.forEach(function(memberId) {
        let docRefNext = Firebase.firestore.collection('barleyInCustomObjects').doc('contents').collection(memberId).doc()
        console.log('here t first')
        console.log(t)
        t = t.set(
          docRefNext,
          { name: customCreations[memberId] }
        )
        console.log('setting id of ' + docRefNext.id)
        newVal[memberId] = {'customId': docRefNext.id, 'name': customCreations[memberId]}    
      });
      newVal['grnOutNumber'] = oldNumericSave
      t.set(docRef2 ,newVal);
      t.set(Firebase.firestore.collection('offlinePendingBarleyStocks').doc(oldNumericSave), 
      {'oldStockKey': oldStockKey, 'baseSet': baseSet, 'newVal': newVal})
      console.log('prep commit batch')
      transaction = t.commit().then(result => {
         Firebase.trackMixpanel('createRequestOffline', {'result': docRef2.id})
        console.log('Batch success!' + result);
        return true
      }).catch(err => {
        console.log('Batch failure:', err);
        return false
      });
  } catch(e) {
    failMessage = e
  }
  


    }

 



  setLoading(false)
  if (transaction && transaction.transaction == null && transaction.failMessage) {
    alert(transaction.failMessage.msg)
  } else if (!transaction) {
    alert('Error creating request, try again.')
  } else {
    navigate('/app/creationsummary', {state: {headerText: 'Barley Request: ' + transaction.newVal.grnOutNumber, secondaryText: 'Successfully created request.' }, replace: true})
    //navigate(-1)
  }
  console.log(transaction)
  //console.log(transaction)


  }



  function getAllByCategory(bDct, pDct, tDct, ourTarget) {

    var categoryDict = {}
    var total = 0
    var available = 0
    var pending = 0
    var transit = 0

    console.log('our target is ' + ourTarget)

    Object.keys(bDct).map((warehouse, idx) => {
      let itemOuter = bDct[warehouse]
      Object.keys(itemOuter).map((key, idxx) => {
        if (key != ourTarget + '-warehouse:' + warehouse) {
          return
        }
        console.log('allow ' + key)
        let targ = itemOuter[key]

        total += targ.netweightkg ? targ.netweightkg : 0
        available += targ.netweightkg ? targ.netweightkg : 0

        if (warehouse in pDct) {
          if (key in pDct[warehouse]) {
            let pendingItem = pDct[warehouse][key]
            if (pendingItem.netweightkg) {
              total += pendingItem.netweightkg ? pendingItem.netweightkg : 0
              pending = pendingItem.netweightkg ? pendingItem.netweightkg : 0
            }
          }
        }
    
        if (warehouse in tDct) {
          if (key in tDct[warehouse]) {
            let pendingItem = tDct[warehouse][key]
            if (pendingItem.netweightkg) {
              transit = pendingItem.netweightkg ? pendingItem.netweightkg : 0
            }
          }
        }
      })
    })
    if (total != totalVal) {
      setTotalVal(total)
    }
    //var tonstotal = total * (0.00110231)
    //tonstotal = tonstotal.toFixed(2)
    return 'Total Availability of ' + sku.name + ': ' + total + ' kg'
  }



  return (
    <MyContext.Consumer>{context => (
  <Page
    className={classes.root}
    height='100%'
    title="Request Barley Out"
    id="ff2"
    backgroundColor={classes.root.backgroundColor}
  >
    <Box
      display="flex"
      flexDirection="column"
      height="90vh"
      id="bb2"
      justifyContent="center"
      backgroundColor={classes.root.backgroundColor}

    >
      <Container id="cc2" maxWidth="sm" backgroundColor={classes.root.backgroundColor}>
        <Formik
        id='f2'
          initialValues={{

          }}
          validationSchema={
            Yup.object().shape({

            })
          }
          onSubmit={(values) => {
            doSubmit(values)
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting2,
            setSubmitting, 
            touched,
            values,
            setFieldValue
          }) => (
            <form id='fu2' onSubmit={handleSubmit}>
                  <div id='fkx1' style={{marginTop: 150}}>
              <Box mb={3}>
                <Typography
                  color="textPrimary"
                  variant="h2"
                >
                  Create Barley Transfer Request
                </Typography>
                <a>{sku && sku.name ? getAllByCategory(context.barleyStocksDict, context.barleyStocksPendingDict, context.barleyStocksTransitDict,
                 getDictId({'sku': sku.name})) : null}</a>

              </Box>

<div>


<TextField
select 
fullWidth
style={{margin: 5}}
label={'SKU'}
onChange={(e) => setSku(e.target.value)}
value={sku}
variant="outlined">
{getAutoOptions(context, 'sku').map((item, idx) => {
  return (<MenuItem value={item}>{item.name}</MenuItem>)
})}
</TextField>

            <TextField
              fullWidth
              style={{margin: 5}}
              label={'Quantity (Kg)'}
              onChange={(e) => setAmount(e.target.value)}
              value={amount}
              type={'number'}
              variant="outlined">
            </TextField>

{context.targetWarehouse != -1 && context.targetWarehouse && context.targetWarehouse.name 
?
<TextField
select 
disabled
fullWidth
style={{margin: 5}}
label={'Destination Facility'}
onChange={(e) => setDestination(e.target.value)}
value={matchTarget(context)}
variant="outlined">
{[matchTarget(context)].map((item, idx) => {
  return (<MenuItem value={item}>{item.name}</MenuItem>)
})}
</TextField>
: 
<TextField
select 
fullWidth
style={{margin: 5}}
label={'Destination Facility'}
onChange={(e) => setDestination(e.target.value)}
value={destination}
variant="outlined">
{getAutoOptions(context, 'warehouse').map((item, idx) => {
  return (<MenuItem value={item}>{item.name}</MenuItem>)
})}
</TextField>
}



  </div>

</div>
              <Box my={2}>
                <Button
                  color="primary"
                  disabled={loading}
                  fullWidth
                  size="large"
                  id="sendButtonNext"
                  variant="contained"
                  onClick={() => doSubmit(context, values)}
                >
                  Confirm
                </Button>
              </Box>

              {loading ? <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: 50}}><CircularProgress/></div> : null}

              
            </form>
          )}
        </Formik>
      </Container>
    </Box>
  </Page>
        )}</MyContext.Consumer>
  )
};

export default NewBarleyOutView;
