import React, {useState, useReducer} from 'react';
import { Link as RouterLink, useNavigate, useLocation} from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';

import LocationOnIcon from '@material-ui/icons/LocationOn';
import {
  Box,
  Button,
  Checkbox,
  Container,
  FormHelperText,
  Link,
  TextField,
  Typography,
  makeStyles,
  Grid,
  Select,
  MenuItem,
  Stepper,
  StepLabel,
  Step,
  CircularProgress,
  Tooltip
} from '@material-ui/core';
import {X as XIcon} from 'react-feather';
import Help from '@material-ui/icons/Help';
import Page from 'src/components/Page';
import parse from 'autosuggest-highlight/parse';
import throttle from 'lodash/throttle';
import Firebase from 'src/Firebase'
import DateTimeMaster from 'src/utils/DateTimeMaster';
import MuiPhoneNumber from 'material-ui-phone-number'
import { parseWithOptions } from 'date-fns/fp';
import MyContext from 'src/MyContext';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { arrayOf } from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import { gridColumnLookupSelector } from '@mui/x-data-grid';
import AddTransporterDialog from './AddTransporterDialog';
import AddDriverDialog from './AddDriverDialog';
import AddVehicleDialog from './AddVehicleDialog';
import moment from 'moment';
const filter = createFilterOptions();
const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
/*

Traveler // Variety purity >=90% // Moisture =<15%
Traveler //  Variety purity <90% // Moisture =<15%
Traveler // Variety all purity // Moisture >15%
Fatima // Variety purity >=90% // Moisture =<15%
Fatima // Variety purity <90% // Moisture =<15%
Fatima //  Variety all purity // Moisture >15%
Planet // Variety purity >=90% // Moisture =<15%
Planet // Variety purity <90% // Moisture =<15%
Planet //  Variety all purity // Moisture >15%
All other varieties // Variety all purity // Moisture =<15%
All other varieties // Variety all purity // Moisture >15%
*/


const outOptions = [
{'name': 'Traveler // Variety purity >=90% // Moisture =<15%', 'customId': 0, 'query': {'variety': 'Traveler', 'moisture': 'below', 'purity': 'above', 'uncategorized': false}},
{'name': 'Traveler //  Variety purity <90% // Moisture =<15%', 'customId': 1, 'query': {'variety': 'Traveler', 'moisture': 'below', 'purity': 'below', 'uncategorized': false}},
{'name': 'Traveler // Variety all purity // Moisture >15%', 'customId': 2, 'query': {'variety': 'Traveler', 'moisture': 'above', 'uncategorized': false}},
{'name': 'Fatima // Variety purity >=90% // Moisture =<15%', 'customId': 3, 'query': {'variety': 'Fatima', 'moisture': 'below', 'purity': 'above', 'uncategorized': false}},
{'name': 'Fatima // Variety purity <90% // Moisture =<15%', 'customId': 4, 'query': {'variety': 'Fatima', 'moisture': 'below', 'purity': 'below', 'uncategorized': false}},
{'name': 'Fatima //  Variety all purity // Moisture >15%', 'customId': 5, 'query': {'variety': 'Fatima', 'moisture': 'above', 'uncategorized': false}},
{'name': 'Planet // Variety purity >=90% // Moisture =<15%', 'customId': 6, 'query': {'variety': 'Planet', 'moisture': 'below', 'purity': 'above', 'uncategorized': false}},
{'name': 'Planet // Variety purity <90% // Moisture =<15%', 'customId': 7, 'query':  {'variety': 'Planet', 'moisture': 'below', 'purity': 'below', 'uncategorized': false}},
{'name': 'Planet //  Variety all purity // Moisture >15%', 'customId': 8, 'query': {'variety': 'Planet', 'moisture': 'above', 'uncategorized': false}},
{'name': 'All other varieties // Variety all purity // Moisture =<15%', 'customId': 9, 'query': {'variety': 'other', 'moisture': 'below', 'uncategorized': false}},
{'name': 'All other varieties // Variety all purity // Moisture >15%', 'customId': 10, 'query': {'variety': 'other', 'moisture': 'above', 'uncategorized': false}},
{'name': 'Uncategorized', 'customId': 11, 'query': {'uncategorized': true}}]
const autocompleteService = { current: null };
const geocoder = {current: null};

const specOptions = {'numberofbags': 1, 'averagebagweight': 1}

const sharedKeys = ['drivername', 'driverphone', 'platenumber', 'transportservice', 'weighbridge']

const steps = ['Basic Details', 'Transport Details', 'Cargo Details'];
const stepFields = {
  0: [],
  1: [ 'dlnumber', 'freightorder', /*'unitprice',*/ 'dispatchdate'],
  2: ['vehicleweight', 'vehicletare', 'numberofbags', 'averagebagweight', 'weighbridge', 'stacknumber', 'inspectedby', 'silonumber'],
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  }
}));


const NewDispatchView = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const {state} = useLocation();
  const [loading, setLoading] = React.useState(false);
  const [autoValues, setAutoValues] = React.useState({});
  const [source, setSource] = React.useState(null);
  const [category, setCategory] = React.useState(null);
  const [operation, setOperation] = React.useState(null);
  const [sku, setSku] = React.useState(null);
  const [destination, setDestination] = React.useState(null);
  const [amount, setAmount] = React.useState(null);
  const [amountWh, setAmountWh] = React.useState(null);
  const [transferOut, setTransferOut] = React.useState(null);
  const [activeStep, setActiveStep] = React.useState(0);
  const [arrivalType, setArrivalType] = React.useState('Train')
  const [train, setTrain] = React.useState(null);
  const [isFinal, setIsFinal] = React.useState(true);
  const [wagons, setWagons] = React.useState([{'number': '', 'qty': '', 'bag': 50}])
  const [laborers, setLaborers] = React.useState([{'name': '', 'qty': ''}])
  const [innerPlanId, setInnerPlanId] = React.useState(null);
  const [addTransporterDialog, setAddTransporterDialog] = React.useState(false)
  const [addDriverDialog, setAddDriverDialog] = React.useState(false)
  const [addVehicleDialog, setAddVehicleDialog] = React.useState(false)
  const [ignored, forceUpdate] = useReducer(x => x + 1, 0);
  const handleNext = () => {


    setActiveStep((prevActiveStep) => prevActiveStep + 1);

  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  
  function  dictToList(d) {
    if (d) {
     return Object.keys(d).map((key, idx) => {
       return d[key]
     })
    } else {
      return []
    }
  }

  function determineStockMembership(val) {

    /**
     *  1. Traveler // Variety purity >=90% // Moisture =<15%
        2. Traveler //  Variety purity <90% // Moisture =<15%
        3. Traveler // Variety all purity // Moisture >15%
        4. Fatima // Variety purity >=90% // Moisture =<15%
        5. Fatima // Variety purity <90% // Moisture =<15%
        6. Fatima //  Variety all purity // Moisture >15%
        7. Planet // Variety purity >=90% // Moisture =<15%
        8. Planet // Variety purity <90% // Moisture =<15%
        9. Planet //  Variety all purity // Moisture >15%
        10. All other varieties // Variety all purity // Moisture =<15%
        11. All other varieties // Variety all purity // Moisture >15%
     */
    var warehouse = 'None'
    if (val.warehouse && val.warehouse.name) {
      warehouse = val.warehouse.name
    }

    var uncategorized = false 
    
    if (!val.moisture || !val.variety) {
      // if moisture or variety is not defined, we are done, we just need uncategorized to be true
      uncategorized = true 
      return {'uncategorized': true, 'warehouse': warehouse}
    } 

    var variety = val.variety
      if (val.variety == 'Traveler' || val.variety == 'Fatima' || val.variety == 'Planet') {
        variety = val.variety 
      } else {
        variety = 'other'
      }

    if (val.moisture > 15) {
      // if moisture is above 15, the only other characteristic we need is variety
      // this accounts for the following options, where purity does not matter:
      /*
      3. Traveler // Variety all purity // Moisture >15%
      6. Fatima //  Variety all purity // Moisture >15%
      9. Planet //  Variety all purity // Moisture >15%
      11. All other varieties // Variety all purity // Moisture >15%
      */

      return {'uncategorized': false, 'warehouse': warehouse, 'variety': variety, 'moisture': 'above'}
    } else if (!val.purity) {
      // if purity is not defined and moisture is <= 15
      // this makes it case 10 IF variety is already at 'other', else it becomes uncategorized
      if (variety == 'other') {
        // 10. All other varieties // Variety all purity // Moisture =<15%
        return {'uncategorized': false, 'warehouse': warehouse, 'variety': variety, 'moisture': 'below'}
      } else {
        return {'uncategorized': true, 'warehouse': warehouse}
      }

    } else {
      // purity, variety, and moisture are all defined
      // moisture is below 

      if (variety == 'other') {
        // 10. All other varieties // Variety all purity // Moisture =<15%
        return {'uncategorized': false, 'warehouse': warehouse, 'variety': variety, 'moisture': 'below'}
      }

      var purity = 'above'
      if (val.purity < 90) {
        purity = 'below'
      }
      /*
      1. Traveler // Variety purity >=90% // Moisture =<15%
      2. Traveler //  Variety purity <90% // Moisture =<15%
      4. Fatima // Variety purity >=90% // Moisture =<15%
      5. Fatima // Variety purity <90% // Moisture =<15%
      7. Planet // Variety purity >=90% // Moisture =<15%
      8. Planet // Variety purity <90% // Moisture =<15%
      */
      return {'uncategorized': false, 'warehouse': warehouse, 'variety': variety, 'moisture': 'below', 'purity': purity }
    }

  }

  function getDictId(dct) {
    var keys = Object.keys(dct)
    keys.sort()
    var s = ''
    for (var i = 0; i < keys.length; i++) {
      let key = keys[i]
      if (i > 0) {
        s += '-'
      }
      s += key 
      s += ':'
      s += dct[key]
    }

    s += '-yearcode:25'

    return s 
  }


  function getType(t) {
    if (t == 'date') {
      return 'date'
    } else if (t == 'number') {
      return 'number' 
    } else if (t == 'list' || t == 'custom') {
      return t
    } else {
      return null
    }
  }

  function updateAutoValues(k, v) {
    var o = autoValues
    o[k] = v 
    setAutoValues(o)
  }

  function getAutoOptions(context, k) {
    if (k in context.barleyInCustomObjects) {
      return Object.keys(context.barleyInCustomObjects[k]).map((key, id) => {
        return context.barleyInCustomObjects[k][key]
      })
    }
    return []
  }

  function slashReplace(s) {
    return s.split('/').join('*@*@SLASH@*@*')
  }


  function specialUpdate(key, val, setFieldValue, values) {

    setFieldValue(key, val)
    if (key == 'numberofbags') {
      if (values.averagebagweight) {
        setAmount(val * values.averagebagweight)
      }

    } else if (key == 'averagebagweight') {
      if (values.numberofbags) {
        setAmount(val * values.numberofbags)
      }
    }

    
  } 

  function specialUpdateWh(key, val, setFieldValue, values) {

    setFieldValue(key, val)
    if (key == 'numberofbagswh') {
      if (values.averagebagweightwh) {
        setAmountWh(val * values.averagebagweightwh)
      }

    } else if (key == 'averagebagweightwh') {
      if (values.numberofbagswh) {
        setAmountWh(val * values.numberofbagswh)
      }
    }

    
  } 

  function specialUpdateTwo(context, setFieldValue, val) {

    console.log('try set spec update')
    console.log(val)
    console.log(context.transporterMappingDict)
    setFieldValue('transportservice', val)
    var qv = val.split('/').join('*@*@SLASH@*@*')
    qv = qv.toLowerCase()
    console.log(qv)
    if (qv in context.transporterMappingDict) {
      setFieldValue('transporterphone', context.transporterMappingDict[qv].value)
    }
    
  } 

  function compare2(a,b) {
    let as = a.sortNumCreate ? a.sortNumCreate : -1000
    let bs = b.sortNumCreate ? b.sortNumCreate : -1000

    if (as > bs) {
      return -1
    } else if (bs > as) {
      return 1
    } else {
      return 0
    }
}

  function renderContent(fieldsmain, touched, errors, handleBlur, handleChange, values, setFieldValue, context, activeStep) {
    
    
    var fields = fieldsmain 

      fields.sort((a,b) => compare2(a,b))

    var columns = Math.floor(fields.length / 3)
    if (columns * 3 < fields.length) {
      columns += 1
    }
    var colrow = [...Array(columns).keys()]
    var rows = [0,1,2]
    return <div style={{width: activeStep == 1 ? '100%' : '50%', alignSelf: 'center'}}>
      {colrow.map((item, idx) => {
        return <div style={{display: 'flex', flexDirection: 'row'}}>
        {rows.map((inner, innerI) => {
          let fieldIdx = (3*item) + inner 

          if (fieldIdx < fields.length) {
            let tp = getType(fields[fieldIdx].fieldType)
            if (tp == 'custom') {

             return <Autocomplete
              value={autoValues[fields[fieldIdx].fieldKey]}
              fullWidth
              style={{margin: 10}}
              onChange={(event, newValue) => {
                if (typeof newValue === 'string') {
                  updateAutoValues(fields[fieldIdx].fieldKey, {name: newValue})
                  setFieldValue(fields[fieldIdx].fieldKey, {'customId': newValue.customId, 'name': newValue.name})

                } else if (newValue && newValue.inputValue) {
                  // Create a new value from the user input
                  updateAutoValues(fields[fieldIdx].fieldKey, {name: newValue.inputValue})
                  setFieldValue(fields[fieldIdx].fieldKey, {'customId':-1, 'name': newValue.inputValue})
                } else {
                  updateAutoValues(fields[fieldIdx].fieldKey, newValue)
                  if (newValue == null) {
                    setFieldValue(fields[fieldIdx].fieldKey, {'customId': '0', 'name': 'None'})
                  } else {
                    setFieldValue(fields[fieldIdx].fieldKey, {'customId': newValue.customId, 'name': newValue.name})
                  }
                }
                //console.log(event.target.value)
                //console.log(newValue)
              }}
              filterOptions={(options, params) => {
                const filtered = filter(options, params);
        
                // Suggest the creation of a new value
                if (params.inputValue !== '') {
                  filtered.push({
                    inputValue: params.inputValue,
                    name: `Add "${params.inputValue}"`,
                  });
                }
        
                return filtered;
              }}
              selectOnFocus
              clearOnBlur
              handleHomeEndKeys
              id="free-solo-with-text-demo"
              options={getAutoOptions(context, fields[fieldIdx].fieldKey)}
              getOptionLabel={(option) => {
                // Value selected with enter, right from the input
                if (typeof option === 'string') {
                  return option;
                }
                // Add "xxx" option created dynamically
                if (option.inputValue) {
                  return option.inputValue;
                }
                // Regular option
                return option.name;
              }}
              renderOption={(option) => option.name}
              freeSolo
              renderInput={(params) => (
                <TextField {...params}  error={Boolean(touched[fields[fieldIdx].fieldKey] && errors[fields[fieldIdx].fieldKey])}
                fullWidth
                key={fields[fieldIdx].fieldKey}
                id={fields[fieldIdx].fieldKey}
                helperText={touched[fields[fieldIdx].fieldKey] && errors[fields[fieldIdx].fieldKey]}
                label={fields[fieldIdx].fieldName}
                margin="normal"
                name={fields[fieldIdx].fieldKey}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values[fields[fieldIdx].fieldKey]}
                variant="outlined"/>
              )}
            />



            } else if (tp == 'list') {
              let opts = fields[fieldIdx].listOptions
              return <TextField
              select 
              error={Boolean(touched[fields[fieldIdx].fieldKey] && errors[fields[fieldIdx].fieldKey])}
              fullWidth
              style={{margin: 10}}
              key={fields[fieldIdx].fieldKey}
              id={fields[fieldIdx].fieldKey}
              helperText={touched[fields[fieldIdx].fieldKey] && errors[fields[fieldIdx].fieldKey]}
              label={fields[fieldIdx].fieldName}
              margin="normal"
              name={fields[fieldIdx].fieldKey}
              onBlur={handleBlur}
              onChange={handleChange}
              value={values[fields[fieldIdx].fieldKey]}
              variant="outlined">
              {opts.map((item, idx) => {
                return (<MenuItem value={item}>{item}</MenuItem>)
              })}
            </TextField>
            } else {
              return <TextField
              error={Boolean(touched[fields[fieldIdx].fieldKey] && errors[fields[fieldIdx].fieldKey])}
              fullWidth
              style={{marginLeft: 0, marginTop: 10, marginBottom: 10}}
              id={fields[fieldIdx].fieldKey}
              key={fields[fieldIdx].fieldKey}
              helperText={touched[fields[fieldIdx].fieldKey] && errors[fields[fieldIdx].fieldKey]}
              label={fields[fieldIdx].fieldName}
              margin="normal"
              name={fields[fieldIdx].fieldKey}
              onBlur={handleBlur}
              onChange={fields[fieldIdx].fieldKey in specOptions ? (e) => specialUpdate(fields[fieldIdx].fieldKey, e.target.value, setFieldValue, values) : fields[fieldIdx].fieldKey == 'transportservice' ? (e) => specialUpdateTwo(context, setFieldValue, e.target.value) : handleChange}
              value={values[fields[fieldIdx].fieldKey]}
              variant="outlined"
              type={getType(fields[fieldIdx].fieldType)}
              InputLabelProps={{
                shrink: true,
                }}
            />
            }

          }
          
          return null 

        })}
       </div>
      })}
    </div>
  }
  async function doSubmitVessel(v, context) {

    let firebaseServerTimeStamp = await Firebase.getServerTimestamp({})
    if (!firebaseServerTimeStamp || !firebaseServerTimeStamp.success || !firebaseServerTimeStamp.timestamp ) {
      alert('Connection issue, try again.')
      return 
    }
    
    let creationDateTimeStamp = firebaseServerTimeStamp.timestamp 

    if (sku == null || amount == null || amount <= 0 || operation == null || destination == null) {
      alert('Please select a corresponding transfer out request.')
      return
    }
    let typeKey = 'VE'
    var transaction = null

    if (Firebase.ONLINE) {

      setLoading(true)


      var newVal = v 
      newVal['yearCodeForFiltering'] = '25'
      newVal['fromVessel'] = true 
      newVal['dispatchdate'] = newVal.dispatchdate ? moment(newVal.dispatchdate).valueOf() : Date.now()
      newVal['operation'] = operation
      newVal['train'] = operation
      newVal['sku'] = sku 
      newVal['isFinal'] = isFinal
      newVal['warehouse'] = destination 
      newVal['source'] = operation
      newVal['wagons'] = wagons
      newVal['laborers'] = laborers
      newVal['prepared'] = context.warehouseUserInfo ? context.warehouseUserInfo.name : ''
      newVal['creationDate'] = creationDateTimeStamp
      newVal['creationDateLocal'] = Date.now()
      newVal['transporteractual'] = newVal['transportservice']
      newVal['transportservice'] = newVal['transportservice'] ? newVal['transportservice']['name'] : ''
      newVal['driveractual'] = newVal['drivername']
      newVal['drivername'] = newVal['drivername'] ? newVal['drivername']['name'] : ''
      newVal['vehicleactual'] = newVal['platenumber']
      newVal['platenumber'] = newVal['platenumber'] ? newVal['platenumber']['plate'] : ''
      var newSet = {'sku': sku.name}
      newSet['destination'] = destination.name 
      var oldSet = {'sku': sku.name}
      oldSet['vessel'] = operation.name 

      // SCRATCH@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
      if (newVal['purity'] === '') {
        delete newVal['purity']
      }
      if (newVal['moisture'] === '') {
        delete newVal['moisture']
      }

      let stockKey = getDictId(newSet)
      let oldStockKey = getDictId(oldSet)
      console.log(oldStockKey)
      console.log(stockKey)
      newVal['finalweightkg'] = parseInt(amount) 
      newVal['netweightkg'] = parseInt(amount)
      
      let yearCode = '25' // moment(Date.now()).format('YY')

    // transfer in number
    let docRef1 = Firebase.firestore.collection('projects').doc(Firebase.userProject).collection('barleyConstants').doc(yearCode + '-dispatchNumber');

      // reference of old barley transfer out request, which should be updated to be in completed state 
      //var docRefOld = Firebase.firestore.collection('projects').doc(Firebase.userProject).collection('transferBarleyOut').doc(transferOut.id)
      // reference of new barley transfer in request, which will be created now
      var docRef = Firebase.firestore.collection('projects').doc(Firebase.userProject).collection("arrivals").doc()
    

      var docRefTrain = Firebase.firestore.collection('projects').doc(Firebase.userProject).collection("operations").doc(operation.operationId)

      // Reference of stock in the train to decrease
      let stockDocRefPending = Firebase.firestore.collection('projects').doc(Firebase.userProject).collection('barleyStocks').doc(oldStockKey)
      // Reference of stock in new warehouse (this will be increased)
      let stockDocRef = Firebase.firestore.collection('projects').doc(Firebase.userProject).collection('barleyStocks').doc(stockKey)
      

      
      var newValId = docRef.id 

      var newQueryResult = null 
      var oldQueryResult = null 
      var oldNumericSave = null;
      var oldTransferValue = null; 
      var oldNumericValue = null;
      var newNumericValue = null;

      var trainVal = null;

      var innerPlanRef = null 
      var innerPlanVal = null
      if (newVal['planId'] != null && newVal['innerPlanId'] != null) {
        innerPlanRef = Firebase.firestore.collection('projects').doc(Firebase.userProject).collection('plansInner').doc(newVal['planId']).collection('contents').doc(newVal['innerPlanId'])
      }
      console.log('inne rplan ref')
      console.log(innerPlanRef)
      console.log(newVal)
 
  

      var customCreations = {}
      /*state.fields.map((field, idx) => {
      if (field.fieldType === 'custom') {
          if (field.fieldKey in newVal && newVal[field.fieldKey]['customId'] === -1) {
          // must create this val 
          customCreations[field.fieldKey] =  newVal[field.fieldKey]['name']
          }
      }
      })*/

      let arrayOfMemberIds = Object.keys(customCreations)

      let bd = context.barleyInCustomObjects['warehouse']
      var mayHaveShortCode = null
      if (operation.warehouse && operation.warehouse.customId && operation.warehouse.customId in bd) {
        mayHaveShortCode = bd[operation.warehouse.customId]
      }

      let userCode = context.warehouseUserInfo && context.warehouseUserInfo.idwaybill ? context.warehouseUserInfo.idwaybill : '00'

      let shortCode = mayHaveShortCode && mayHaveShortCode['shortcode'] ? mayHaveShortCode['shortcode'] : null
      var updCode = {}
     transaction = await Firebase.firestore.runTransaction(async t => {

      return t.get(docRef1)
      .then(async doc => {

        if (shortCode) {
          if (shortCode in doc.data()) {
            newNumericValue = doc.data()[shortCode] + 1;  //You calculate the new value
            
            let shortCodeValue = shortCode +  '3760' + Firebase.padDigits(doc.data()[shortCode]) + '-' + userCode + '-' + yearCode
            oldNumericValue = shortCodeValue
            oldNumericSave = shortCodeValue
            updCode[shortCode] = newNumericValue
            //return t.update(docRef1 , updCode);
          } else {
            newNumericValue = 2;  //You calculate the new value
            let shortCodeValue = shortCode +  '3760' + Firebase.padDigits(1) + '-' + userCode + '-' + yearCode
            oldNumericValue = shortCodeValue
            oldNumericSave = shortCodeValue
            updCode[shortCode] = newNumericValue
           // return t.update(docRef1 , updCode);
          }
          
        } else {
          newNumericValue = doc.data().value + 1;  //You calculate the new value
          oldNumericValue =  '3760' + Firebase.padDigits(doc.data().value) + '-' + userCode + '-' + yearCode
          oldNumericSave = oldNumericValue
          updCode['value'] = newNumericValue
          //return t.update(docRef1 , {value : newNumericValue});
        }
        console.log(shortCode)
        console.log(doc.data())
        console.log(newNumericValue)
        console.log(oldNumericSave)
        console.log(oldNumericSave)

        if (innerPlanRef) {
          let innerPlanRes = await t.get(innerPlanRef)
          if (innerPlanRes.exists) {
            innerPlanVal = innerPlanRes.data()
          }
        }

        let trainRes = await t.get(docRefTrain)
        if (trainRes.exists) {
          trainVal = trainRes.data()
        } else {
          const errM = {'msg': "Train does not exist."}
          throw errM
        }


        return t.get(stockDocRef )
      }).then(querySnapshotNew => {
          // Get new stock ref for the in transit barley to dest
          if (querySnapshotNew.exists) {
            newQueryResult = querySnapshotNew.data()
  
          } else {
            newQueryResult = null 
          }
  
         /* return t.get(docRefOld)
          
        })
        .then(querySnapshotTransfer => {
          // Get new stock ref for the in transit barley to dest
          if (querySnapshotTransfer.exists) {
            oldTransferValue = querySnapshotTransfer.data()
  
          } else {
            const errM = {'msg': "Transfer out request does not exist."}
            throw errM
          }*/
  
          return t.get(stockDocRefPending)
          
        }).then(querySnapshotOld => {
          // Get old stock ref for the pending barley to remove from source
          if (querySnapshotOld.exists) {
            oldQueryResult = querySnapshotOld.data()
          } else {
              const errM = {'msg': "Pending stock does not exist."}
              console.log('SKIPPING ERROR')
              console.log(errM)
              //throw errM
          }
            // update custom IDs if needed
            arrayOfMemberIds.forEach(function(memberId) {
                let docRefNext = Firebase.firestore.collection('projects').doc(Firebase.userProject).collection('barleyInCustomObjects').doc('contents').collection('projects').doc(Firebase.userProject).collection(memberId).doc()
                console.log('here t first')
                console.log(t)
                t = t.set(
                docRefNext,
                { name: customCreations[memberId] }
                )
                console.log('setting id of ' + docRefNext.id)
                newVal[memberId] = {'customId': docRefNext.id, 'name': customCreations[memberId]}
                
            });
            return t
            }).then(t => {



            // Update inner plan if needed
            if (innerPlanVal) {
              let current = innerPlanVal.receivedqty ? innerPlanVal.receivedqty : 0
              var updateValInnerPlan = {'receivedqty': current + parseInt(amount)}
              t.update(innerPlanRef, updateValInnerPlan)
            }



              // make the new transfer out item 
            newVal['dispatchNumber'] = oldNumericValue
            console.log(newVal)


            var newAllocTrain = trainVal.sentAmounts ? trainVal.sentAmounts : {}
            let whid = destination.customId 
            let newAmt = newAllocTrain[whid] ? newAllocTrain[whid] : 0
            let finAmt = newVal['netweightkg'] + newAmt
            newAllocTrain[whid] = finAmt 
            t.update(docRefTrain, {'sentAmounts': newAllocTrain})
            //newVal['transferOutNumber'] = transferOut['transferOutNumber']
            return t.set(docRef ,newVal);
            })/*.then(t => {
              // make the new transfer out item 
            if (oldTransferValue.status == 'Completed') {
              const errM = {'msg': "This transfer has already been completed."}
              throw errM
            }
            return t.update(docRefOld ,{'status': 'Completed'});
            })*/.then(t => {
              return t.update(docRef1 , updCode);
            }).then(t => {
            // Update the pending stock

            var updateValOld = {}
            if (!newVal['finalweightkg']) {
              const errM = {'msg': "No barley being transferred."}
              throw errM
            }
            if (!oldQueryResult || !oldQueryResult['netweightkg'] || oldQueryResult['netweightkg'] < newVal['finalweightkg']) {
              console.log('completely skipping error for old result barley oldqueryresult etc train')
              
              //const errM = {'msg': "Not enough barley in transit. Amount in transit is " + oldQueryResult['netweightkg'] + "kg, while trying to transfer " + newVal['finalweightkg'] + "kg."}
              //throw errM
            }
            updateValOld['netweightkg'] = (oldQueryResult && oldQueryResult['netweightkg'] ? oldQueryResult['netweightkg'] : 0) - newVal['netweightkg']
            updateValOld['yearCodeForFiltering'] = '25'
            if (oldQueryResult) {
              return t.update(stockDocRefPending ,updateValOld);
            } else {
              return t.set(stockDocRefPending ,updateValOld);
            }

  
          })
      }).then(result => {
        
        console.log('Transaction success!' + result);
        return {'transaction': true, 'newVal': newVal, 'oldNumericSave': oldNumericSave, 'newValId': newValId}
      }).catch(err => {
        console.log('Transaction failure:', err);
        return {'transaction': null, 'failMessage': err}
      });

      Firebase.trackMixpanel('createDispatchVesselOnline', {'result': transaction})








      // SCRATCH@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@



    } else {
      setLoading(true)
      alert('no offline implementation yet')

    }
    console.log('here transact')
    console.log(transaction)
  
    if (transaction && transaction.transaction != null) {
      newVal = transaction.newVal 
      var oldNumericSave = transaction.oldNumericSave
      var newValId = transaction.newValId
  
      let s = async () => {
          var datPack = {}
          const imageRef = Firebase.storage.ref('test').child('pdf_for_wab_' + oldNumericSave + '.pdf')
          let v = await imageRef.getDownloadURL();
          console.log(v)
          console.log('got it')
          datPack['pdflink'] = v 
          datPack['dispatchId'] = newValId
          let snone = () => console.log('x')
          Firebase.provideWaybillLink(datPack, snone, snone)
          //window.open(v)
  
      } 
  
      let f = () => {
       
      }
      let s2 = async () => {
        var datPack = {}
        const imageRef = Firebase.storage.ref('test').child('pdf_for_whout_' + oldNumericSave + '.pdf')
        let v = await imageRef.getDownloadURL();
        console.log(v)
        console.log('got it')
        datPack['pdflink'] = v 
        datPack['dispatchId'] = newValId
        let snone = () => console.log('x')
        Firebase.provideWarehouseOutLink(datPack, snone, snone)
        //window.open(v)

    } 

    let f2 = () => {
     
    }
    var datxx = {}
    datxx['val'] = newVal
    datxx['userId'] = 'test'
    
    //Firebase.generateWarehouseOut(datxx, s2, f2)
      
      Firebase.generateWaybill(datxx, s, f)
  
  }
  setLoading(false)
  if (transaction && transaction.transaction == null && transaction.failMessage) {
    alert(transaction.failMessage.msg)
  } else {
    //navigate(-1)
    navigate('/app/creationsummary', {state: {headerText: 'Dispatch: ' + transaction.oldNumericSave, secondaryText: 'Successfully created report.' }, replace: true})
  }
  }

  function validateBagCountsTrain(newVal, wagons) {
    let bagsTotal = parseInt(newVal['numberofbags'])
    var sumFromWagons = 0
    wagons.map((item, idx) => {
      sumFromWagons += item.qty && item.qty != '' && item.qty >= 0 ? parseInt(item.qty) : 0
    })

    if (bagsTotal != sumFromWagons) {
      alert('Total number of bags (' + bagsTotal + ') should equal the sum of bags from all the wagons (' + sumFromWagons + ').')
      return false 
    }

    return true 

  }

  function validateBagCountsTrainAndWarehouse(newVal, wagons) {
    console.log('check newval')
    console.log(newVal)
    let bagsTotal = parseInt(newVal['numberofbags'])
    var sumFromWagons = 0
    wagons.map((item, idx) => {
      sumFromWagons += item.qty && item.qty != '' && item.qty >= 0 ? parseInt(item.qty) : 0
    })

    var sumFromWarehouse = newVal['numberofbagswh'] ? parseInt(newVal['numberofbagswh']) : 0
    var sumTotal = sumFromWarehouse + sumFromWagons
    if (bagsTotal != sumTotal) {
      alert('Total number of bags (' + bagsTotal + ') should equal the sum of bags from all the wagons (' + sumFromWagons + ') plus the bags from the warehouse (' + sumFromWarehouse + ') for a total of ' + sumTotal + ' bags.')
      return false 
    }

    return true 
  }

  async function doSubmitTrainAndWarehouse(v, context) {

    let firebaseServerTimeStamp = await Firebase.getServerTimestamp({})
    if (!firebaseServerTimeStamp || !firebaseServerTimeStamp.success || !firebaseServerTimeStamp.timestamp ) {
      alert('Connection issue, try again.')
      return 
    }
    
    let creationDateTimeStamp = firebaseServerTimeStamp.timestamp 


    if (sku == null || amount == null || amount <= 0 || amountWh <= 0 || train == null || destination == null) {
      alert('Make sure you have specified a train, commodity, destination, and quantity.')
      return
    }

    if (!train.warehouse || !train.warehouse.name) {
      alert('This train does not have an assigned warehouse.')
      return
    }

    let typeKey = 'TR'
    var transaction = null

    if (Firebase.ONLINE) {

      setLoading(true)

      console.log('input value')
      console.log(v)
      var newVal = v 
      newVal['numberofbags'] = parseInt(newVal['numberofbags']) /*+ (newVal['numberofbagswh'] ? parseInt(newVal['numberofbagswh']) : 0)*/
      let bags_validated = validateBagCountsTrainAndWarehouse(newVal, wagons)
      if (!bags_validated) {
        setLoading(false)
        return 
      }
      newVal['yearCodeForFiltering'] = '25'
      newVal['dispatchdate'] = newVal.dispatchdate ? moment(newVal.dispatchdate).valueOf() : Date.now()
      newVal['operation'] = operation
      newVal['train'] = train
      newVal['sku'] = sku 
      newVal['isFinal'] = isFinal
      newVal['warehouse'] = destination 
      newVal['source'] = train
      newVal['wagons'] = wagons
      newVal['laborers'] = laborers
      newVal['trainAndWarehouse'] = true
      newVal['prepared'] = context.warehouseUserInfo ? context.warehouseUserInfo.name : ''
      newVal['creationDate'] = creationDateTimeStamp
      newVal['creationDateLocal'] = Date.now()
      newVal['transporteractual'] = newVal['transportservice']
      newVal['transportservice'] = newVal['transportservice'] ? newVal['transportservice']['name'] : ''
      newVal['driveractual'] = newVal['drivername']
      newVal['drivername'] = newVal['drivername'] ? newVal['drivername']['name'] : ''
      newVal['vehicleactual'] = newVal['platenumber']
      newVal['platenumber'] = newVal['platenumber'] ? newVal['platenumber']['plate'] : ''
      newVal['numberofbagswh'] = newVal['numberofbagswh']
      newVal['numberofbags'] = parseInt(newVal['numberofbags']) /*+ (newVal['numberofbagswh'] ? parseInt(newVal['numberofbagswh']) : 0)*/

      var newSet = {'sku': sku.name}
      newSet['destination'] = destination.name 
      var oldSet = {'sku': sku.name}
      oldSet['train'] = train.name 

      var warehouseSet = {'sku': sku.name}
      warehouseSet['warehouse'] = train.warehouse.name  

      // SCRATCH@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
      if (newVal['purity'] === '') {
        delete newVal['purity']
      }
      if (newVal['moisture'] === '') {
        delete newVal['moisture']
      }

      let stockKey = getDictId(newSet)
      let oldStockKey = getDictId(oldSet)
      let warehouseStockKey = getDictId(warehouseSet)

      console.log(oldStockKey)
      console.log(stockKey)
      // with update as of 4/6/2022, we are already accounting for the wh quantity so do not need to add it here
      newVal['finalweightkg'] = parseInt(amount) // + parseInt(amountWh)
      newVal['netweightkg'] = parseInt(amount) // + parseInt(amountWh)
      newVal['netweightkgwh'] = parseInt(amountWh)
      newVal['finalweightkgwh'] = parseInt(amountWh)
      
      let yearCode = '25' // moment(Date.now()).format('YY')

    // transfer in number
    let docRef1 = Firebase.firestore.collection('projects').doc(Firebase.userProject).collection('barleyConstants').doc(yearCode + '-dispatchNumber');

      // reference of old barley transfer out request, which should be updated to be in completed state 
      //var docRefOld = Firebase.firestore.collection('projects').doc(Firebase.userProject).collection('transferBarleyOut').doc(transferOut.id)
      // reference of new barley transfer in request, which will be created now
      var docRef = Firebase.firestore.collection('projects').doc(Firebase.userProject).collection("arrivals").doc()
    

      var docRefTrain = Firebase.firestore.collection('projects').doc(Firebase.userProject).collection("trains").doc(train.trainId)

      // Reference of stock in the train to decrease
      let stockDocRefPending = Firebase.firestore.collection('projects').doc(Firebase.userProject).collection('barleyStocks').doc(oldStockKey)
      // Reference of stock in new warehouse (this will be increased)
      let stockDocRef = Firebase.firestore.collection('projects').doc(Firebase.userProject).collection('barleyStocks').doc(stockKey)
      
      // Reference of stock in the warehouse to decrease in addition to the train
      let stockDocRefWarehouse = Firebase.firestore.collection('projects').doc(Firebase.userProject).collection('barleyStocks').doc(warehouseStockKey)
      
      var newValId = docRef.id 

      var newQueryResult = null 
      var oldQueryResult = null 
      var oldNumericSave = null;
      var oldTransferValue = null; 
      var oldNumericValue = null;
      var newNumericValue = null;

      var warehouseStockVal = null 

      var trainVal = null;

      var innerPlanRef = null 
      var innerPlanVal = null
      if (newVal['planId'] != null && newVal['innerPlanId'] != null) {
        innerPlanRef = Firebase.firestore.collection('projects').doc(Firebase.userProject).collection('plansInner').doc(newVal['planId']).collection('contents').doc(newVal['innerPlanId'])
      }
      console.log('inne rplan ref')
      console.log(innerPlanRef)
      console.log(newVal)
 
  

      var customCreations = {}
      state.fields.map((field, idx) => {
      if (field.fieldType === 'custom') {
          if (field.fieldKey in newVal && newVal[field.fieldKey]['customId'] === -1) {
          // must create this val 
          customCreations[field.fieldKey] =  newVal[field.fieldKey]['name']
          }
      }
      })

      let arrayOfMemberIds = Object.keys(customCreations)

      let bd = context.barleyInCustomObjects['warehouse']
      var mayHaveShortCode = null
      if (train.warehouse && train.warehouse.customId && train.warehouse.customId in bd) {
        mayHaveShortCode = bd[train.warehouse.customId]
      }

      let shortCode = mayHaveShortCode && mayHaveShortCode['shortcode'] ? mayHaveShortCode['shortcode'] : null
      var updCode = {}
     transaction = await Firebase.firestore.runTransaction(async t => {

      return t.get(docRef1)
      .then(async doc => {

        if (shortCode) {
          if (shortCode in doc.data()) {
            newNumericValue = doc.data()[shortCode] + 1;  //You calculate the new value
            
            let shortCodeValue = shortCode + '-' + typeKey + Firebase.padDigits(doc.data()[shortCode]) + '-' + yearCode
            oldNumericValue = shortCodeValue
            oldNumericSave = shortCodeValue
            updCode[shortCode] = newNumericValue
            //return t.update(docRef1 , updCode);
          } else {
            newNumericValue = 2;  //You calculate the new value
            let shortCodeValue = shortCode + '-' + typeKey + Firebase.padDigits(1) + '-' + yearCode
            oldNumericValue = shortCodeValue
            oldNumericSave = shortCodeValue
            updCode[shortCode] = newNumericValue
           // return t.update(docRef1 , updCode);
          }
          
        } else {
          newNumericValue = doc.data().value + 1;  //You calculate the new value
          oldNumericValue = typeKey + Firebase.padDigits(doc.data().value) + '-' + yearCode
          oldNumericSave = oldNumericValue
          updCode['value'] = newNumericValue
          //return t.update(docRef1 , {value : newNumericValue});
        }
        console.log(shortCode)
        console.log(doc.data())
        console.log(newNumericValue)
        console.log(oldNumericSave)
        console.log(oldNumericSave)

        if (innerPlanRef) {
          let innerPlanRes = await t.get(innerPlanRef)
          if (innerPlanRes.exists) {
            innerPlanVal = innerPlanRes.data()
          }
        }

        let trainRes = await t.get(docRefTrain)
        if (trainRes.exists) {
          trainVal = trainRes.data()
        } else {
          const errM = {'msg': "Train does not exist."}
          throw errM
        }

        let warehouseStockRes = await t.get(stockDocRefWarehouse)
        if (warehouseStockRes.exists) {
          warehouseStockVal = warehouseStockRes.data()

        } else {
          const errM = {'msg': "None of the specified stock in this warehouse."}
          throw errM
        }

        return t.get(stockDocRef )
      }).then(querySnapshotNew => {
          // Get new stock ref for the in transit barley to dest
          if (querySnapshotNew.exists) {
            newQueryResult = querySnapshotNew.data()
  
          } else {
            newQueryResult = null 
          }
  
         /* return t.get(docRefOld)
          
        })
        .then(querySnapshotTransfer => {
          // Get new stock ref for the in transit barley to dest
          if (querySnapshotTransfer.exists) {
            oldTransferValue = querySnapshotTransfer.data()
  
          } else {
            const errM = {'msg': "Transfer out request does not exist."}
            throw errM
          }*/
  
          return t.get(stockDocRefPending)
          
        }).then(querySnapshotOld => {
          // Get old stock ref for the pending barley to remove from source
          if (querySnapshotOld.exists) {
            oldQueryResult = querySnapshotOld.data()
          } else {
              const errM = {'msg': "Pending stock does not exist."}
              console.log('SKIPPING ERROR')
              console.log(errM)
              //throw errM
          }
            // update custom IDs if needed
            arrayOfMemberIds.forEach(function(memberId) {
                let docRefNext = Firebase.firestore.collection('projects').doc(Firebase.userProject).collection('barleyInCustomObjects').doc('contents').collection('projects').doc(Firebase.userProject).collection(memberId).doc()
                console.log('here t first')
                console.log(t)
                t = t.set(
                docRefNext,
                { name: customCreations[memberId] }
                )
                console.log('setting id of ' + docRefNext.id)
                newVal[memberId] = {'customId': docRefNext.id, 'name': customCreations[memberId]}
                
            });
            return t
            }).then(t => {



            // Update inner plan if needed
            if (innerPlanVal) {
              let current = innerPlanVal.receivedqty ? innerPlanVal.receivedqty : 0
              var updateValInnerPlan = {'receivedqty': current + newVal['netweightkg']}
              t.update(innerPlanRef, updateValInnerPlan)
            }



              // make the new transfer out item 
            newVal['dispatchNumber'] = oldNumericValue
            console.log(newVal)


            var newAllocTrain = trainVal.sentAmounts ? trainVal.sentAmounts : {}
            let whid = destination.customId 
            let newAmt = newAllocTrain[whid] ? newAllocTrain[whid] : 0
            let finAmt = newVal['netweightkg'] + newAmt
            newAllocTrain[whid] = finAmt 
            t.update(docRefTrain, {'sentAmounts': newAllocTrain})
            //newVal['transferOutNumber'] = transferOut['transferOutNumber']
            return t.set(docRef ,newVal);
            })/*.then(t => {
              // make the new transfer out item 
            if (oldTransferValue.status == 'Completed') {
              const errM = {'msg': "This transfer has already been completed."}
              throw errM
            }
            return t.update(docRefOld ,{'status': 'Completed'});
            })*/.then(t => {
              return t.update(docRef1 , updCode);
            }).then(t => {
            // Update the pending stock





                var updateValOldWarehouse = {}
                if (!newVal['finalweightkgwh']) {
                  const errM = {'msg': "No stock being transferred out of warehouse."}
                  throw errM
                }
                if (!warehouseStockVal || !warehouseStockVal['netweightkg'] || warehouseStockVal['netweightkg'] < newVal['finalweightkgwh']) {
                  console.log('completely skipping error for old result barley oldqueryresult etc train')
                  
                  const errM = {'msg': "Not enough stock has been deposited in this warehouse. Amount in warehouse is " + warehouseStockVal['netweightkg'] + "kg, while trying to transfer " + newVal['finalweightkgwh'] + "kg."}
                  throw errM
                }
                updateValOldWarehouse['netweightkg'] = (warehouseStockVal && warehouseStockVal['netweightkg'] ? warehouseStockVal['netweightkg'] : 0) - newVal['netweightkgwh']
                updateValOldWarehouse['yearCodeForFiltering'] = '25'
                if (warehouseStockVal) {
                  t.update(stockDocRefWarehouse, updateValOldWarehouse);
                } else {
                 t.set(stockDocRefWarehouse, updateValOldWarehouse);
                }

     

            var updateValOld = {}
            if (!newVal['finalweightkg']) {
              const errM = {'msg': "No barley being transferred."}
              throw errM
            }
            if (!oldQueryResult || !oldQueryResult['netweightkg'] || oldQueryResult['netweightkg'] < newVal['finalweightkg']) {
              console.log('completely skipping error for old result barley oldqueryresult etc train')
              
              //const errM = {'msg': "Not enough barley in transit. Amount in transit is " + oldQueryResult['netweightkg'] + "kg, while trying to transfer " + newVal['finalweightkg'] + "kg."}
              //throw errM
            }
            updateValOld['netweightkg'] = (oldQueryResult && oldQueryResult['netweightkg'] ? oldQueryResult['netweightkg'] : 0) - newVal['netweightkg']
            updateValOld['yearCodeForFiltering'] = '25'
            if (oldQueryResult) {
              return t.update(stockDocRefPending ,updateValOld);
            } else {
              return t.set(stockDocRefPending ,updateValOld);
            }

  
          })
      }).then(result => {
        
        console.log('Transaction success!' + result);
        return {'transaction': true, 'newVal': newVal, 'oldNumericSave': oldNumericSave, 'newValId': newValId}
      }).catch(err => {
        console.log('Transaction failure:', err);
        return {'transaction': null, 'failMessage': err}
      });

      Firebase.trackMixpanel('createDispatchTrainAndWarehouseOnline', {'result': transaction})

    } else {
      setLoading(true)
      alert('no offline implementation yet')

    }
    console.log('here transact')
    console.log(transaction)
  
    if (transaction && transaction.transaction != null) {
      newVal = transaction.newVal 
      var oldNumericSave = transaction.oldNumericSave
      var newValId = transaction.newValId
  
      let s = async () => {
          var datPack = {}
          const imageRef = Firebase.storage.ref('test').child('pdf_for_wab_' + oldNumericSave + '.pdf')
          let v = await imageRef.getDownloadURL();
          console.log(v)
          console.log('got it')
          datPack['pdflink'] = v 
          datPack['dispatchId'] = newValId
          let snone = () => console.log('x')
          Firebase.provideWaybillLink(datPack, snone, snone)
          //window.open(v)
  
      } 
  
      let f = () => {
       
      }
      let s2 = async () => {
        var datPack = {}
        const imageRef = Firebase.storage.ref('test').child('pdf_for_whout_' + oldNumericSave + '.pdf')
        let v = await imageRef.getDownloadURL();
        console.log(v)
        console.log('got it')
        datPack['pdflink'] = v 
        datPack['dispatchId'] = newValId
        let snone = () => console.log('x')
        Firebase.provideWarehouseOutLink(datPack, snone, snone)
        //window.open(v)

    } 

    let f2 = () => {
     
    }
    var datxx = {}
    datxx['val'] = newVal
    datxx['userId'] = 'test'
    
    Firebase.generateWarehouseOut(datxx, s2, f2)
      
      Firebase.generateWaybill(datxx, s, f)
  
  }
  setLoading(false)
  if (transaction && transaction.transaction == null && transaction.failMessage) {
    alert(transaction.failMessage.msg)
  } else {
    //navigate(-1)
    navigate('/app/creationsummary', {state: {headerText: 'Dispatch: ' + transaction.oldNumericSave, secondaryText: 'Successfully created report.' }, replace: true})
  }
  }

  async function doSubmitTrain(v, context) {

    let firebaseServerTimeStamp = await Firebase.getServerTimestamp({})
    if (!firebaseServerTimeStamp || !firebaseServerTimeStamp.success || !firebaseServerTimeStamp.timestamp ) {
      alert('Connection issue, try again.')
      return 
    }
    
    let creationDateTimeStamp = firebaseServerTimeStamp.timestamp 
    
    if (sku == null || amount == null || amount <= 0 || train == null || destination == null) {
      alert('Please select a corresponding transfer out request.')
      return
    }
    let typeKey = 'TR'
    var transaction = null

    if (Firebase.ONLINE) {

      setLoading(true)
      let bags_validated = validateBagCountsTrain(v, wagons)
      if (!bags_validated) {
        setLoading(false)
        return 
      }

      var newVal = v 
      newVal['yearCodeForFiltering'] = '25'
      newVal['dispatchdate'] = newVal.dispatchdate ? moment(newVal.dispatchdate).valueOf() : Date.now()
      newVal['operation'] = operation
      newVal['train'] = train
      newVal['sku'] = sku 
      newVal['isFinal'] = isFinal
      newVal['warehouse'] = destination 
      newVal['source'] = train
      newVal['wagons'] = wagons
      newVal['laborers'] = laborers
      newVal['prepared'] = context.warehouseUserInfo ? context.warehouseUserInfo.name : ''
      newVal['creationDate'] = creationDateTimeStamp
      newVal['creationDateLocal'] = Date.now()
      newVal['transporteractual'] = newVal['transportservice']
      newVal['transportservice'] = newVal['transportservice'] ? newVal['transportservice']['name'] : ''
      newVal['driveractual'] = newVal['drivername']
      newVal['drivername'] = newVal['drivername'] ? newVal['drivername']['name'] : ''
      newVal['vehicleactual'] = newVal['platenumber']
      newVal['platenumber'] = newVal['platenumber'] ? newVal['platenumber']['plate'] : ''
      var newSet = {'sku': sku.name}
      newSet['destination'] = destination.name 
      var oldSet = {'sku': sku.name}
      oldSet['train'] = train.name 

      // SCRATCH@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
      if (newVal['purity'] === '') {
        delete newVal['purity']
      }
      if (newVal['moisture'] === '') {
        delete newVal['moisture']
      }

      let stockKey = getDictId(newSet)
      let oldStockKey = getDictId(oldSet)
      console.log(oldStockKey)
      console.log(stockKey)
      newVal['finalweightkg'] = parseInt(amount) 
      newVal['netweightkg'] = parseInt(amount)
      
      let yearCode = '25' // moment(Date.now()).format('YY')

    // transfer in number
    let docRef1 = Firebase.firestore.collection('projects').doc(Firebase.userProject).collection('barleyConstants').doc(yearCode + '-dispatchNumber');

      // reference of old barley transfer out request, which should be updated to be in completed state 
      //var docRefOld = Firebase.firestore.collection('projects').doc(Firebase.userProject).collection('transferBarleyOut').doc(transferOut.id)
      // reference of new barley transfer in request, which will be created now
      var docRef = Firebase.firestore.collection('projects').doc(Firebase.userProject).collection("arrivals").doc()
    

      var docRefTrain = Firebase.firestore.collection('projects').doc(Firebase.userProject).collection("trains").doc(train.trainId)

      // Reference of stock in the train to decrease
      let stockDocRefPending = Firebase.firestore.collection('projects').doc(Firebase.userProject).collection('barleyStocks').doc(oldStockKey)
      // Reference of stock in new warehouse (this will be increased)
      let stockDocRef = Firebase.firestore.collection('projects').doc(Firebase.userProject).collection('barleyStocks').doc(stockKey)
      

      
      var newValId = docRef.id 

      var newQueryResult = null 
      var oldQueryResult = null 
      var oldNumericSave = null;
      var oldTransferValue = null; 
      var oldNumericValue = null;
      var newNumericValue = null;

      var trainVal = null;

      var innerPlanRef = null 
      var innerPlanVal = null
      if (newVal['planId'] != null && newVal['innerPlanId'] != null) {
        innerPlanRef = Firebase.firestore.collection('projects').doc(Firebase.userProject).collection('plansInner').doc(newVal['planId']).collection('contents').doc(newVal['innerPlanId'])
      }
      console.log('inne rplan ref')
      console.log(innerPlanRef)
      console.log(newVal)
 
  

      var customCreations = {}
      state.fields.map((field, idx) => {
      if (field.fieldType === 'custom') {
          if (field.fieldKey in newVal && newVal[field.fieldKey]['customId'] === -1) {
          // must create this val 
          customCreations[field.fieldKey] =  newVal[field.fieldKey]['name']
          }
      }
      })

      let arrayOfMemberIds = Object.keys(customCreations)

      let bd = context.barleyInCustomObjects['warehouse']
      var mayHaveShortCode = null
      if (train.warehouse && train.warehouse.customId && train.warehouse.customId in bd) {
        mayHaveShortCode = bd[train.warehouse.customId]
      }

      let shortCode = mayHaveShortCode && mayHaveShortCode['shortcode'] ? mayHaveShortCode['shortcode'] : null
      var updCode = {}
     transaction = await Firebase.firestore.runTransaction(async t => {

      return t.get(docRef1)
      .then(async doc => {

        if (shortCode) {
          if (shortCode in doc.data()) {
            newNumericValue = doc.data()[shortCode] + 1;  //You calculate the new value
            
            let shortCodeValue = shortCode + '-' + typeKey + Firebase.padDigits(doc.data()[shortCode]) + '-' + yearCode
            oldNumericValue = shortCodeValue
            oldNumericSave = shortCodeValue
            updCode[shortCode] = newNumericValue
            //return t.update(docRef1 , updCode);
          } else {
            newNumericValue = 2;  //You calculate the new value
            let shortCodeValue = shortCode + '-' + typeKey + Firebase.padDigits(1) + '-' + yearCode
            oldNumericValue = shortCodeValue
            oldNumericSave = shortCodeValue
            updCode[shortCode] = newNumericValue
           // return t.update(docRef1 , updCode);
          }
          
        } else {
          newNumericValue = doc.data().value + 1;  //You calculate the new value
          oldNumericValue = typeKey + Firebase.padDigits(doc.data().value) + '-' + yearCode
          oldNumericSave = oldNumericValue
          updCode['value'] = newNumericValue
          //return t.update(docRef1 , {value : newNumericValue});
        }
        console.log(shortCode)
        console.log(doc.data())
        console.log(newNumericValue)
        console.log(oldNumericSave)
        console.log(oldNumericSave)

        if (innerPlanRef) {
          let innerPlanRes = await t.get(innerPlanRef)
          if (innerPlanRes.exists) {
            innerPlanVal = innerPlanRes.data()
          }
        }

        let trainRes = await t.get(docRefTrain)
        if (trainRes.exists) {
          trainVal = trainRes.data()
        } else {
          const errM = {'msg': "Train does not exist."}
          throw errM
        }


        return t.get(stockDocRef )
      }).then(querySnapshotNew => {
          // Get new stock ref for the in transit barley to dest
          if (querySnapshotNew.exists) {
            newQueryResult = querySnapshotNew.data()
  
          } else {
            newQueryResult = null 
          }
  
         /* return t.get(docRefOld)
          
        })
        .then(querySnapshotTransfer => {
          // Get new stock ref for the in transit barley to dest
          if (querySnapshotTransfer.exists) {
            oldTransferValue = querySnapshotTransfer.data()
  
          } else {
            const errM = {'msg': "Transfer out request does not exist."}
            throw errM
          }*/
  
          return t.get(stockDocRefPending)
          
        }).then(querySnapshotOld => {
          // Get old stock ref for the pending barley to remove from source
          if (querySnapshotOld.exists) {
            oldQueryResult = querySnapshotOld.data()
          } else {
              const errM = {'msg': "Pending stock does not exist."}
              console.log('SKIPPING ERROR')
              console.log(errM)
              //throw errM
          }
            // update custom IDs if needed
            arrayOfMemberIds.forEach(function(memberId) {
                let docRefNext = Firebase.firestore.collection('projects').doc(Firebase.userProject).collection('barleyInCustomObjects').doc('contents').collection('projects').doc(Firebase.userProject).collection(memberId).doc()
                console.log('here t first')
                console.log(t)
                t = t.set(
                docRefNext,
                { name: customCreations[memberId] }
                )
                console.log('setting id of ' + docRefNext.id)
                newVal[memberId] = {'customId': docRefNext.id, 'name': customCreations[memberId]}
                
            });
            return t
            }).then(t => {



            // Update inner plan if needed
            if (innerPlanVal) {
              let current = innerPlanVal.receivedqty ? innerPlanVal.receivedqty : 0
              var updateValInnerPlan = {'receivedqty': current + parseInt(amount)}
              t.update(innerPlanRef, updateValInnerPlan)
            }



              // make the new transfer out item 
            newVal['dispatchNumber'] = oldNumericValue
            console.log(newVal)


            var newAllocTrain = trainVal.sentAmounts ? trainVal.sentAmounts : {}
            let whid = destination.customId 
            let newAmt = newAllocTrain[whid] ? newAllocTrain[whid] : 0
            let finAmt = newVal['netweightkg'] + newAmt
            newAllocTrain[whid] = finAmt 
            t.update(docRefTrain, {'sentAmounts': newAllocTrain})
            //newVal['transferOutNumber'] = transferOut['transferOutNumber']
            return t.set(docRef ,newVal);
            })/*.then(t => {
              // make the new transfer out item 
            if (oldTransferValue.status == 'Completed') {
              const errM = {'msg': "This transfer has already been completed."}
              throw errM
            }
            return t.update(docRefOld ,{'status': 'Completed'});
            })*/.then(t => {
              return t.update(docRef1 , updCode);
            }).then(t => {
            // Update the pending stock

            var updateValOld = {}
            if (!newVal['finalweightkg']) {
              const errM = {'msg': "No barley being transferred."}
              throw errM
            }
            if (!oldQueryResult || !oldQueryResult['netweightkg'] || oldQueryResult['netweightkg'] < newVal['finalweightkg']) {
              console.log('completely skipping error for old result barley oldqueryresult etc train')
              
              //const errM = {'msg': "Not enough barley in transit. Amount in transit is " + oldQueryResult['netweightkg'] + "kg, while trying to transfer " + newVal['finalweightkg'] + "kg."}
              //throw errM
            }
            updateValOld['netweightkg'] = (oldQueryResult && oldQueryResult['netweightkg'] ? oldQueryResult['netweightkg'] : 0) - newVal['netweightkg']
            updateValOld['yearCodeForFiltering'] = '25'
            if (oldQueryResult) {
              return t.update(stockDocRefPending ,updateValOld);
            } else {
              return t.set(stockDocRefPending ,updateValOld);
            }

  
          })
      }).then(result => {
        
        console.log('Transaction success!' + result);
        return {'transaction': true, 'newVal': newVal, 'oldNumericSave': oldNumericSave, 'newValId': newValId}
      }).catch(err => {
        console.log('Transaction failure:', err);
        return {'transaction': null, 'failMessage': err}
      });

      Firebase.trackMixpanel('createDispatchTrainOnline', {'result': transaction})

    } else {
      setLoading(true)
      alert('no offline implementation yet')

    }
    console.log('here transact')
    console.log(transaction)
  
    if (transaction && transaction.transaction != null) {
      newVal = transaction.newVal 
      var oldNumericSave = transaction.oldNumericSave
      var newValId = transaction.newValId
  
      let s = async () => {
          var datPack = {}
          const imageRef = Firebase.storage.ref('test').child('pdf_for_wab_' + oldNumericSave + '.pdf')
          let v = await imageRef.getDownloadURL();
          console.log(v)
          console.log('got it')
          datPack['pdflink'] = v 
          datPack['dispatchId'] = newValId
          let snone = () => console.log('x')
          Firebase.provideWaybillLink(datPack, snone, snone)
          //window.open(v)
  
      } 
  
      let f = () => {
       
      }
      let s2 = async () => {
        var datPack = {}
        const imageRef = Firebase.storage.ref('test').child('pdf_for_whout_' + oldNumericSave + '.pdf')
        let v = await imageRef.getDownloadURL();
        console.log(v)
        console.log('got it')
        datPack['pdflink'] = v 
        datPack['dispatchId'] = newValId
        let snone = () => console.log('x')
        Firebase.provideWarehouseOutLink(datPack, snone, snone)
        //window.open(v)

    } 

    let f2 = () => {
     
    }
    var datxx = {}
    datxx['val'] = newVal
    datxx['userId'] = 'test'
    
    //Firebase.generateWarehouseOut(datxx, s2, f2)
      
      Firebase.generateWaybill(datxx, s, f)
  
  }
  setLoading(false)
  if (transaction && transaction.transaction == null && transaction.failMessage) {
    alert(transaction.failMessage.msg)
  } else {
    //navigate(-1)
    navigate('/app/creationsummary', {state: {headerText: 'Dispatch: ' + transaction.oldNumericSave, secondaryText: 'Successfully created report.' }, replace: true})
  }
  }

  async function doSubmitWarhouse(v, context) {

    let firebaseServerTimeStamp = await Firebase.getServerTimestamp({})
    if (!firebaseServerTimeStamp || !firebaseServerTimeStamp.success || !firebaseServerTimeStamp.timestamp ) {
      alert('Connection issue, try again.')
      return 
    }
    
    let creationDateTimeStamp = firebaseServerTimeStamp.timestamp 


    if (sku == null || amount == null || amount <= 0 || source == null || destination == null || source.name == destination.name) {
      alert('Please select a source and destination (these two cannot be the same).')
      return
    }

    let typeKey = 'WH'
    var transaction = null

    if (Firebase.ONLINE) {
      setLoading(true)


      var newVal = v 
      newVal['yearCodeForFiltering'] = '25'
      newVal['dispatchdate'] = newVal.dispatchdate ? moment(newVal.dispatchdate).valueOf() : Date.now()
      newVal['fromWarehouse'] = true 
      newVal['fromVessel'] = source && source.warehousetype == 'Port'
      newVal['operation'] = operation
      newVal['source'] = source
      newVal['sku'] = sku 
      newVal['warehouse'] = destination
      newVal['laborers'] = laborers
      newVal['isFinal'] = isFinal
      newVal['train'] = train 
      if (!train) {
        newVal['train'] = {'warehouse': source}
      }
      newVal['transporteractual'] = newVal['transportservice']
      newVal['transportservice'] = newVal['transportservice'] ? newVal['transportservice']['name'] : ''
      newVal['driveractual'] = newVal['drivername']
      newVal['drivername'] = newVal['drivername'] ? newVal['drivername']['name'] : ''
      newVal['vehicleactual'] = newVal['platenumber']
      newVal['platenumber'] = newVal['platenumber'] ? newVal['platenumber']['plate'] : ''
      newVal['prepared'] = context.warehouseUserInfo ? context.warehouseUserInfo.name : ''
      //newVal['wagons'] = wagons

      newVal['creationDate'] = creationDateTimeStamp
      newVal['creationDateLocal'] = Date.now()
  
      var newSet = {'sku': sku.name}
      newSet['destination'] = destination.name 
      var oldSet = {'sku': sku.name}
      oldSet['warehouse'] = source.name 

      // SCRATCH@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
      if (newVal['purity'] === '') {
        delete newVal['purity']
      }
      if (newVal['moisture'] === '') {
        delete newVal['moisture']
      }

      let stockKey = getDictId(newSet)
      let oldStockKey = getDictId(oldSet)

      newVal['finalweightkg'] = parseInt(amount) 
      newVal['netweightkg'] = parseInt(amount)

      let yearCode = '25' // moment(Date.now()).format('YY')

    // transfer in number
    let docRef1 = Firebase.firestore.collection('projects').doc(Firebase.userProject).collection('barleyConstants').doc(yearCode + '-dispatchNumber');

      // reference of old barley transfer out request, which should be updated to be in completed state 
      //var docRefOld = Firebase.firestore.collection('projects').doc(Firebase.userProject).collection('transferBarleyOut').doc(transferOut.id)
      // reference of new barley transfer in request, which will be created now
      var docRef = Firebase.firestore.collection('projects').doc(Firebase.userProject).collection("arrivals").doc()
    

      var docRefTrain = newVal['fromVessel'] ? Firebase.firestore.collection('projects').doc(Firebase.userProject).collection("operations").doc(operation.operationId) : Firebase.firestore.collection('projects').doc(Firebase.userProject).collection("trains").doc(train.trainId)
      // Reference of stock in the train to decrease
      let stockDocRefPending = Firebase.firestore.collection('projects').doc(Firebase.userProject).collection('barleyStocks').doc(oldStockKey)
      // Reference of stock in new warehouse (this will be increased)
      let stockDocRef = Firebase.firestore.collection('projects').doc(Firebase.userProject).collection('barleyStocks').doc(stockKey)
      
      
      var newValId = docRef.id 

      var newQueryResult = null 
      var oldQueryResult = null 
      var oldNumericSave = null;
      var oldTransferValue = null; 
      var oldNumericValue = null;
      var newNumericValue = null;
  
      var trainVal = null;
      var innerPlanRef = null 
      var innerPlanVal = null
      if (newVal['planId'] != null && newVal['innerPlanId'] != null) {
        innerPlanRef = Firebase.firestore.collection('projects').doc(Firebase.userProject).collection('plansInner').doc(newVal['planId']).collection('contents').doc(newVal['innerPlanId'])
      }

      var customCreations = {}
      /*state.fields.map((field, idx) => {
      if (field.fieldType === 'custom') {
          if (field.fieldKey in newVal && newVal[field.fieldKey]['customId'] === -1) {
          // must create this val 
          customCreations[field.fieldKey] =  newVal[field.fieldKey]['name']
          }
      }
      })*/

      let arrayOfMemberIds = Object.keys(customCreations)
      let shortCode = source && source['shortcode'] ? source['shortcode'] : null
      var updCode = {}
     transaction = await Firebase.firestore.runTransaction(async t => {

      return t.get(docRef1)
      .then(async doc => {

        if (shortCode) {
          if (shortCode in doc.data()) {
            newNumericValue = doc.data()[shortCode] + 1;  //You calculate the new value
            let shortCodeValue = shortCode + '-' + typeKey +  Firebase.padDigits(doc.data()[shortCode]) + '-' + yearCode
            oldNumericValue = shortCodeValue
            oldNumericSave = shortCodeValue
            updCode[shortCode] = newNumericValue
            //return t.update(docRef1 , updCode);
          } else {
            newNumericValue = 2;  //You calculate the new value
            let shortCodeValue = shortCode + '-' + typeKey + Firebase.padDigits(1) + '-' + yearCode
            oldNumericValue = shortCodeValue
            oldNumericSave = shortCodeValue
            updCode[shortCode] = newNumericValue
           // return t.update(docRef1 , updCode);
          }
          
        } else {
          newNumericValue = doc.data().value + 1;  //You calculate the new value
          oldNumericValue = typeKey + Firebase.padDigits(doc.data().value) + '-' + yearCode 
          oldNumericSave = oldNumericValue
          updCode['value'] = newNumericValue
          //return t.update(docRef1 , {value : newNumericValue});
        }


        if (innerPlanRef) {
          let innerPlanRes = await t.get(innerPlanRef)
          if (innerPlanRes.exists) {
            innerPlanVal = innerPlanRes.data()
          }
        }
        let trainRes = await t.get(docRefTrain)
        if (trainRes.exists) {
          trainVal = trainRes.data()
        } else {
          const errM = {'msg': "Train does not exist."}
          throw errM
        }


        return t.get(stockDocRef )
      }).then(querySnapshotNew => {
          // Get new stock ref for the in transit barley to dest
          if (querySnapshotNew.exists) {
            newQueryResult = querySnapshotNew.data()
  
          } else {
            newQueryResult = null 
          }
  
         /* return t.get(docRefOld)
          
        })
        .then(querySnapshotTransfer => {
          // Get new stock ref for the in transit barley to dest
          if (querySnapshotTransfer.exists) {
            oldTransferValue = querySnapshotTransfer.data()
  
          } else {
            const errM = {'msg': "Transfer out request does not exist."}
            throw errM
          }*/
  
          return t.get(stockDocRefPending)
          
        }).then(querySnapshotOld => {
          // Get old stock ref for the pending barley to remove from source
          if (querySnapshotOld.exists) {
            oldQueryResult = querySnapshotOld.data()
          } else {
              const errM = {'msg': "Pending stock does not exist."}
              console.log('SKIPPING ERROR')
              console.log(errM)
              //throw errM
          }
            // update custom IDs if needed
            arrayOfMemberIds.forEach(function(memberId) {
                let docRefNext = Firebase.firestore.collection('projects').doc(Firebase.userProject).collection('barleyInCustomObjects').doc('contents').collection('projects').doc(Firebase.userProject).collection(memberId).doc()
                console.log('here t first')
                console.log(t)
                t = t.set(
                docRefNext,
                { name: customCreations[memberId] }
                )
                console.log('setting id of ' + docRefNext.id)
                newVal[memberId] = {'customId': docRefNext.id, 'name': customCreations[memberId]}
                
            });
            return t
            }).then(t => {
              // make the new transfer out item 
            newVal['dispatchNumber'] = oldNumericValue
            //newVal['transferOutNumber'] = transferOut['transferOutNumber']
            return t.set(docRef ,newVal);
            })/*.then(t => {
              // make the new transfer out item 
            if (oldTransferValue.status == 'Completed') {
              const errM = {'msg': "This transfer has already been completed."}
              throw errM
            }
            return t.update(docRefOld ,{'status': 'Completed'});
            })*/.then(t => {


            // Update inner plan if needed
            if (innerPlanVal) {
              let current = innerPlanVal.receivedqty ? innerPlanVal.receivedqty : 0
              var updateValInnerPlan = {'receivedqty': current + parseInt(amount)}
              t.update(innerPlanRef, updateValInnerPlan)
            }



              var newAllocTrain = trainVal.sentAmounts ? trainVal.sentAmounts : {}
              let whid = destination.customId 
              let newAmt = newAllocTrain[whid] ? newAllocTrain[whid] : 0
              let finAmt = newVal['netweightkg'] + newAmt
              newAllocTrain[whid] = finAmt 
              t.update(docRefTrain, {'sentAmounts': newAllocTrain})
              return t.update(docRef1 , updCode);
            }).then(t => {
            // Update the pending stock

            var updateValOld = {}
            if (!newVal['finalweightkg']) {
              const errM = {'msg': "No barley being transferred."}
              throw errM
            }
            if (!oldQueryResult || !oldQueryResult['netweightkg'] || oldQueryResult['netweightkg'] < newVal['finalweightkg']) {
              console.log('completely skipping error for old result barley oldqueryresult etc train')
              
              const errM = {'msg': "Not enough stock has been deposited in this warehouse. Amount in warehouse is " + oldQueryResult['netweightkg'] + "kg, while trying to transfer " + newVal['finalweightkg'] + "kg."}
              throw errM
            }
            updateValOld['netweightkg'] = (oldQueryResult && oldQueryResult['netweightkg'] ? oldQueryResult['netweightkg'] : 0) - newVal['netweightkg']
            updateValOld['yearCodeForFiltering'] = '25'
            if (oldQueryResult) {
              return t.update(stockDocRefPending ,updateValOld);
            } else {
              return t.set(stockDocRefPending ,updateValOld);
            }

  
          })
      }).then(result => {
        
        console.log('Transaction success!' + result);
        return {'transaction': true, 'newVal': newVal, 'oldNumericSave': oldNumericSave, 'newValId': newValId}
      }).catch(err => {
        console.log('Transaction failure:', err);
        return {'transaction': null, 'failMessage': err}
      });

      Firebase.trackMixpanel('createDispatchWarehouseOnline', {'result': transaction})








      // SCRATCH@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@


    } else {
      setLoading(true)
      alert('no offline implementation yet')

    }
    console.log('here transact')
    console.log(transaction)
  
    if (transaction && transaction.transaction != null) {
      newVal = transaction.newVal 
      var oldNumericSave = transaction.oldNumericSave
      var newValId = transaction.newValId
  
      let s = async () => {
          var datPack = {}
          const imageRef = Firebase.storage.ref('test').child('pdf_for_wab_' + oldNumericSave + '.pdf')
          let v = await imageRef.getDownloadURL();
          console.log(v)
          console.log('got it')
          datPack['pdflink'] = v 
          datPack['dispatchId'] = newValId
          let snone = () => console.log('x')
          Firebase.provideWaybillLink(datPack, snone, snone)
          //window.open(v)
  
      } 
  
      let f = () => {
       
      }
      var datxx = {}
      datxx['val'] = newVal
      datxx['userId'] = 'test'

      let s2 = async () => {
        var datPack = {}
        const imageRef = Firebase.storage.ref('test').child('pdf_for_whout_' + oldNumericSave + '.pdf')
        let v = await imageRef.getDownloadURL();
        console.log(v)
        console.log('got it')
        datPack['pdflink'] = v 
        datPack['dispatchId'] = newValId
        let snone = () => console.log('x')
        Firebase.provideWarehouseOutLink(datPack, snone, snone)
        //window.open(v)

    } 

    let f2 = () => {
     
    }
    var datxx = {}
    datxx['val'] = newVal
    datxx['userId'] = 'test'
    
    Firebase.generateWarehouseOut(datxx, s2, f2)
      
      Firebase.generateWaybill(datxx, s, f)
  
    }

  setLoading(false)
  if (transaction && transaction.transaction == null && transaction.failMessage) {
    alert(transaction.failMessage.msg)
  } else {
    //navigate(-1)
    navigate('/app/creationsummary', {state: {headerText: 'Arrival At Destination: ' + transaction.oldNumericSave, secondaryText: 'Successfully created report.' }, replace: true})
  }
  //console.log(transaction)
  //console.log(transaction)


  }

  function updateWagons(k, idx, v) {
    var wag = wagons.map((item, id) => {
      if (id == idx) {
        var ret = item 
        ret[k] = v 
        return ret 
      }
      return item 
    })
    setWagons(wag)
  }
  
  function updateLaborers(k, idx, v) {
    var wag = laborers.map((item, id) => {
      if (id == idx) {
        var ret = item 
        ret[k] = v 
        return ret 
      }
      return item 
    })
    setLaborers(wag)
  }

  function filterDestinationsVessel(whOptions, operation, context, values) {

    if (!operation) {
      return []
    }
    var s = ''
    var planIds = []
    var consignee = null
    if (operation && operation.yearlyPlans) {
      s = 'Associated Plan(s): '
      operation.yearlyPlans.map((item, idx) => {
        if (idx > 0) {
          s += ', '
        }

        planIds.push(item)
        
        if (item in context.planDict) {
          s += context.planDict[item].name
          consignee = context.planDict[item].consignee
        }
      })
      //return s
    }
    var planId = null
    if (planIds.length > 0) {
      planId = planIds[0]
    }

    console.log(operation)
    console.log(planId)
    

    if (!planId) {
      return []
    }

    var dsdct = {}
    if (planId && planId in context.innerPlansDict) {
      let innerDict = context.innerPlansDict[planId]
      Object.keys(innerDict).map((key, idx) => {
        let innerPlan = innerDict[key]
        //console.log('check inner plan')
        //console.log(innerPlan)
        if (innerPlan && innerPlan.warehouse) {
            let whs = innerPlan.warehouse.name + '-' + innerPlan.warehouse.union
            dsdct[whs] = 1
        }
      })
      console.log('here is dsdct')
      console.log(dsdct)
      return whOptions.filter((item, idx) => {
        let whs = item.name + '-' + item.union 
        return whs in dsdct
      })
    } else {
      console.log('checking if it is in')
      console.log(values.planId)
      console.log(context.innerPlansDict)
      return []
    }

  }

  function renderPlanInformation(operation, destination, sku, context, setFieldValue, values) {

    if (!operation) {
      return ''
    }
    var s = ''
    var planIds = []
    var consignee = null
    if (operation && operation.yearlyPlans) {
      s = 'Associated Plan(s): '
      operation.yearlyPlans.map((item, idx) => {
        if (idx > 0) {
          s += ', '
        }

        planIds.push(item)
        
        if (item in context.planDict) {
          s += context.planDict[item].name
          consignee = context.planDict[item].consignee
        }
      })
      //return s
    }
    var innerPlanIds = []
    var outerPlanIds = []
    if (destination && sku && destination.union) {
      let nameTarget = destination.name 
      let unionTarget = destination.union 
      let skuTarget = sku.name 
      var priceList = []

      planIds.map((plan, idx) => {
        if (plan in context.innerPlansDict) {
          let innerDict = context.innerPlansDict[plan]
          Object.keys(innerDict).map((key, idx) => {
            let innerPlan = innerDict[key]
            //console.log('check inner plan')
            //console.log(innerPlan)
            if (innerPlan && innerPlan.sku && innerPlan.warehouse
              && innerPlan.sku.name == skuTarget && innerPlan.warehouse.name == nameTarget
              && innerPlan.warehouse.union == unionTarget /*&& innerPlan.unitPrice*/) {
                priceList.push(innerPlan.unitPrice)
                innerPlanIds.push(key)
                outerPlanIds.push(plan)
            }

          })
        }
      })
      /*console.log('INSIDE HERE')
      console.log(planIds)
      console.log(nameTarget)
      console.log(unionTarget)
      console.log(skuTarget)*/
      if (priceList.length > 0 && innerPlanIds.length > 0) {
        var priceStr = '. Planned Price(s) per Quintal: '
        priceList.map((price, idx) => {
          if (idx > 0) {
            priceStr += ', '
          }
          priceStr += price 
        })
        //s += priceStr 
        if (values.unitprice != priceList[0] || values.innerPlanId != innerPlanIds[0] || values.planId != outerPlanIds[0]) {
          /*console.log(values.unitprice)
          console.log(priceList[0])
          console.log(values.innerPlanId)
          console.log(innerPlanIds[0])*/
          setFieldValue('planId', outerPlanIds[0])
          setFieldValue('innerPlanId', innerPlanIds[0])
          setFieldValue('unitprice', priceList[0])

        }

      } else if (values.unitprice != null || values.innerPlanId != null || values.planId != null) {
        //console.log(values.unitprice)
        //console.log(values.innerPlanId)
        setFieldValue('planId', null)
        setFieldValue('innerPlanId', null)
        setFieldValue('unitprice', null)
      
      }
     
    }

    if (consignee != values.consignee) {
      setFieldValue('consignee', consignee)
    }


    /*if (innerPlanIds.length < 1) {
      s += ' Note: There is not a plan corresponding to the current destination, sku, and operation.'
    }*/

    return s

  }

  function testShort(train, context) {
    let bd = context.barleyInCustomObjects['warehouse']
    var mayHaveShortCode = null
    if (train && train.warehouse && train.warehouse.customId && train.warehouse.customId in bd) {
      //alert(train.warehouse.customId)
      mayHaveShortCode = bd[train.warehouse.customId]
      //alert(JSON.stringify(mayHaveShortCode))
    }
    return JSON.stringify(mayHaveShortCode)
  }

  function destFilter(warehouse, train) {
    if (!train || !train.allocations) {
      return true 
    } else {
      return (warehouse.customId in train.allocations)
    }
  }

  function renderTrainStats(trn, dst) {

    if (!dst || !dst.customId) {
      return null
    }
    if (!trn || !trn.allocations) {
      return null 
    }
    let cid = dst.customId
    let allocAmount = trn.allocations[cid] ? trn.allocations[cid] : 0
    var sndAmount = trn.sentAmounts && trn.sentAmounts[cid] ? trn.sentAmounts[cid]: 0
    sndAmount = (sndAmount / 1000).toFixed(2)
    let remainingAmount = (allocAmount - sndAmount).toFixed(2)
    return <div style={{marginLeft: 50, alignSelf: 'center', flexDirection: 'columm', alignItems: 'center', flex: 1, justifyContent: 'center'}}>
      <Typography variant="h4">{'Amount Sent to ' + dst.name + ' / ' + dst.union}</Typography>
      <Typography style={{marginTop: 10}}>
        {sndAmount + ' / ' + allocAmount + ' Tons Sent'}
      </Typography>
      <Typography style={{marginTop: 10}}>
        {remainingAmount + ' / ' + allocAmount + ' Tons Remaining'}
      </Typography>

    </div>
  }

  function momentFormat(date) {
    return moment(date).format('yyyy-MM-DD') + 'T' + moment(date).format('HH:mm')
  }

  function renderLaborers(laborers) {
    return <div style={{width: '50%'}}>
  {laborers.map((item, idx) => {
    return <div>
                    <TextField
              id={'laborerName'}
              key={idx + 'laborerName'}
              
              label={'Laborer Group'}
              margin="normal"
              name={'laborerName'}
              onChange={(e) => updateLaborers('name', idx, e.target.value)}
              value={laborers[idx].name}
              variant="outlined"
              type={'text'}
              InputLabelProps={{
                shrink: true,
                }}
            />
                                <TextField
              id={'laborerQty'}
              key={idx + 'laborerQty'}
              label={'Amount Moved by Group'}
              margin="normal"
              name={'wagonQty'}
              onChange={(e) => updateLaborers('qty', idx, e.target.value)}
              value={laborers[idx].qty}
              variant="outlined"
              type={'number'}
              InputLabelProps={{
                shrink: true,
                }}
            />

            <XIcon  style={{marginTop: 30}} onClick={(e) => {
              setLaborers(laborers.filter((item, idxx) => {
                return idxx != idx
              }))
            }}/>
      </div>
  })}
  <Button
                       color="primary"
                    
                       size="large"
                       id="sendButtonNext"
                       variant="contained"
                       onClick={() => {
                         var wag = laborers 
                         wag.push({'name': '', 'qty': ''})
                         setLaborers(wag)
                         forceUpdate()
                       }}
                       style={{margin: 5, width: 150}}
                     >
                       Add Group
                     </Button>
  
</div>
  }

  function renderOnlyTransporter(fields, touched, errors, handleBlur, handleChange, values, setFieldValue, context) {
    let fieldIdx = 0
    let fieldIdx2 = 1
    if (!fields || !fields[fieldIdx] || !fields[fieldIdx2]) {
      return null
    }
    return <div style={{flexDirection: 'row', display: 'flex'}}><Autocomplete
    value={values[fields[fieldIdx].fieldKey]}
    style={{width: 200, marginRight: 5}}
    onChange={(event, newValue) => {
      if (typeof newValue === 'string') {
        updateAutoValues(fields[fieldIdx].fieldKey, {name: newValue})
        setFieldValue(fields[fieldIdx].fieldKey, newValue/*{'customId': newValue.customId, 'name': newValue.name}*/)
        if (fields[fieldIdx].fieldKey == 'transportservice') {
          
          if ('phone' in newValue) {
            setFieldValue('transporterphone', newValue['phone'])
          }
        }
      } else if (newValue && newValue.inputValue) {
        // Create a new value from the user input
        updateAutoValues(fields[fieldIdx].fieldKey, {name: newValue.inputValue})
        setFieldValue(fields[fieldIdx].fieldKey, {'customId':-1, 'name': newValue.inputValue})
      } else {
        updateAutoValues(fields[fieldIdx].fieldKey, newValue)
        if (newValue == null) {
          setFieldValue(fields[fieldIdx].fieldKey, {'customId': '0', 'name': 'None'})
        } else {
          setFieldValue(fields[fieldIdx].fieldKey, newValue/*{'customId': newValue.customId, 'name': newValue.name}*/)
          if (fields[fieldIdx].fieldKey == 'transportservice') {
          
            if ('phone' in newValue) {
              setFieldValue('transporterphone', newValue['phone'])
            }
          }
        }
      }
      //console.log(event.target.value)
      //console.log(newValue)
    }}
    selectOnFocus
    clearOnBlur
    handleHomeEndKeys
    id="free-solo-with-text-demo"
    options={getAutoOptions(context, 'transporters')}
    getOptionLabel={(option) => {
      // Value selected with enter, right from the input
      if (typeof option === 'string') {
        return option;
      }
      // Add "xxx" option created dynamically
      if (option.inputValue) {
        return option.inputValue;
      }
      // Regular option
      return option.name ? option.name : '';
    }}
    renderOption={(option) => option.name ? option.name : ''}
    freeSolo
    renderInput={(params) => (
      <TextField {...params}  error={Boolean(touched[fields[fieldIdx].fieldKey] && errors[fields[fieldIdx].fieldKey])}

      key={fields[fieldIdx].fieldKey}
      id={fields[fieldIdx].fieldKey}
      helperText={touched[fields[fieldIdx].fieldKey] && errors[fields[fieldIdx].fieldKey]}
      label={fields[fieldIdx].fieldName}
      margin="normal"
   
      name={fields[fieldIdx].fieldKey}
      onBlur={handleBlur}
      onChange={handleChange}
      value={values[fields[fieldIdx].fieldKey]}
      variant="outlined"/>
    )}
  />

              <TextField
              error={Boolean(touched[fields[fieldIdx2].fieldKey] && errors[fields[fieldIdx2].fieldKey])}
              style={{width: 200, marginLeft: 5}}
              id={fields[fieldIdx2].fieldKey}
              key={fields[fieldIdx2].fieldKey}
              helperText={touched[fields[fieldIdx2].fieldKey] && errors[fields[fieldIdx2].fieldKey]}
              label={fields[fieldIdx2].fieldName}
              margin="normal"
              name={fields[fieldIdx2].fieldKey}
              onBlur={handleBlur}
              onChange={handleChange}
              value={values[fields[fieldIdx2].fieldKey]}
              variant="outlined"
              type={getType(fields[fieldIdx2].fieldType)}
              InputLabelProps={{
                shrink: true,
                }}
            />

                            <Button
                        color="primary"
                        style={{margin: 5, marginLeft: 20, width: 185, alignSelf: 'center'}}
                        disabled={loading}
                        id="sendButtonNext"
                        variant="contained"
                        onClick={() => setAddTransporterDialog(true)}
                      >
                        New Transporter
                      </Button>
                      </div>

  }

  function renderOnlyVehicle(fields, touched, errors, handleBlur, handleChange, values, setFieldValue, context) {
    let fieldIdx = 0
    let fieldIdx2 = 1
    if (!fields || !fields[fieldIdx] || !fields[fieldIdx2]) {
      return null
    }
    return <div style={{flexDirection: 'row', display: 'flex'}}><Autocomplete
    value={values[fields[fieldIdx].fieldKey]}
    style={{width: 200, marginRight: 5}}
    onChange={(event, newValue) => {
      if (typeof newValue === 'string') {
        updateAutoValues(fields[fieldIdx].fieldKey, {name: newValue})
        setFieldValue(fields[fieldIdx].fieldKey, newValue/*{'customId': newValue.customId, 'name': newValue.name}*/)
        if (fields[fieldIdx].fieldKey == 'platenumber') {
          
          if ('trailerplate' in newValue) {
            setFieldValue('trailerplatenumber', newValue['trailerplate'])
          } else if ('trailerPlate' in newValue) {
            setFieldValue('trailerplatenumber', newValue['trailerPlate'])
          }
        }
      } else if (newValue && newValue.inputValue) {
        // Create a new value from the user input
        updateAutoValues(fields[fieldIdx].fieldKey, {name: newValue.inputValue})
        setFieldValue(fields[fieldIdx].fieldKey, {'customId':-1, 'name': newValue.inputValue})
      } else {
        updateAutoValues(fields[fieldIdx].fieldKey, newValue)
        if (newValue == null) {
          setFieldValue(fields[fieldIdx].fieldKey, {'customId': '0', 'name': 'None'})
        } else {
          setFieldValue(fields[fieldIdx].fieldKey, newValue/*{'customId': newValue.customId, 'name': newValue.name}*/)
          if (fields[fieldIdx].fieldKey == 'platenumber') {
          
            if ('trailerplate' in newValue) {
              setFieldValue('trailerplatenumber', newValue['trailerplate'])
            } else if ('trailerPlate' in newValue) {
              setFieldValue('trailerplatenumber', newValue['trailerPlate'])
            }
          }
        }
      }
      //console.log(event.target.value)
      //console.log(newValue)
    }}

    selectOnFocus
    clearOnBlur
    handleHomeEndKeys
    id="free-solo-with-text-demo"
    options={getAutoOptions(context, 'vehicles')}
    getOptionLabel={(option) => {
      // Value selected with enter, right from the input
      if (typeof option === 'string') {
        return option;
      }
      // Add "xxx" option created dynamically
      if (option.inputValue) {
        return option.inputValue;
      }
      // Regular option
      return option.plate ? option.plate : '';
    }}
    renderOption={(option) => option.plate ? option.plate : ''}
    freeSolo
    renderInput={(params) => (
      <TextField {...params}  error={Boolean(touched[fields[fieldIdx].fieldKey] && errors[fields[fieldIdx].fieldKey])}

      key={fields[fieldIdx].fieldKey}
      id={fields[fieldIdx].fieldKey}
      helperText={touched[fields[fieldIdx].fieldKey] && errors[fields[fieldIdx].fieldKey]}
      label={fields[fieldIdx].fieldName}
      margin="normal"
   
      name={fields[fieldIdx].fieldKey}
      onBlur={handleBlur}
      onChange={handleChange}
      value={values[fields[fieldIdx].fieldKey]}
      variant="outlined"/>
    )}
  />

              <TextField
              error={Boolean(touched[fields[fieldIdx2].fieldKey] && errors[fields[fieldIdx2].fieldKey])}
              style={{width: 200, marginLeft: 5}}
              id={fields[fieldIdx2].fieldKey}
              key={fields[fieldIdx2].fieldKey}
              helperText={touched[fields[fieldIdx2].fieldKey] && errors[fields[fieldIdx2].fieldKey]}
              label={fields[fieldIdx2].fieldName}
              margin="normal"
              name={fields[fieldIdx2].fieldKey}
              onBlur={handleBlur}
              onChange={handleChange}
              value={values[fields[fieldIdx2].fieldKey]}
              variant="outlined"
              type={getType(fields[fieldIdx2].fieldType)}
              InputLabelProps={{
                shrink: true,
                }}
            />

                            <Button
                        color="primary"
                        style={{margin: 5, marginLeft: 20, width: 185, alignSelf: 'center'}}
                        disabled={loading}
                        id="sendButtonNext"
                        variant="contained"
                        onClick={() => setAddVehicleDialog(true)}
                      >
                        New Vehicle
                      </Button>
                      </div>

  }

  function getTotalAmount(a,b) {
    var ret = 0
    if (a) {
      ret += a
    } 
    /*if (b) {
      ret += b
    }*/
    return ret
  }

  function renderOnlyDriver(fields, touched, errors, handleBlur, handleChange, values, setFieldValue, context) {
    let fieldIdx = 0
    let fieldIdx2 = 1
    if (!fields || !fields[fieldIdx] || !fields[fieldIdx2]) {
      return null
    }
    return <div style={{flexDirection: 'row', display: 'flex'}}><Autocomplete
    value={values[fields[fieldIdx].fieldKey]}
    style={{width: 200, marginRight: 5}}
    onChange={(event, newValue) => {
      if (typeof newValue === 'string') {
       
        updateAutoValues(fields[fieldIdx].fieldKey, {name: newValue})
        setFieldValue(fields[fieldIdx].fieldKey, newValue/*{'customId': newValue.customId, 'name': newValue.name}*/)
        if (fields[fieldIdx].fieldKey == 'drivername') {

          if ('phone' in newValue) {
            setFieldValue('driverphone', newValue['phone'])
          }
          if ('dlnumber' in newValue) {
            setFieldValue('dlnumber', newValue['dlnumber'])
          }
        }
      } else if (newValue && newValue.inputValue) {
        // Create a new value from the user input
        updateAutoValues(fields[fieldIdx].fieldKey, {name: newValue.inputValue})
        setFieldValue(fields[fieldIdx].fieldKey, {'customId':-1, 'name': newValue.inputValue})
      } else {
        updateAutoValues(fields[fieldIdx].fieldKey, newValue)
        if (newValue == null) {
          setFieldValue(fields[fieldIdx].fieldKey, {'customId': '0', 'name': 'None'})
        } else {
          setFieldValue(fields[fieldIdx].fieldKey, newValue/*{'customId': newValue.customId, 'name': newValue.name}*/)
          if (fields[fieldIdx].fieldKey == 'drivername') {

            if ('phone' in newValue) {
              setFieldValue('driverphone', newValue['phone'])
            }
            if ('dlnumber' in newValue) {
              setFieldValue('dlnumber', newValue['dlnumber'])
            }
          }
        }
      }
      //console.log(event.target.value)
      //console.log(newValue)
    }}

    selectOnFocus
    clearOnBlur
    handleHomeEndKeys
    id="free-solo-with-text-demo"
    options={getAutoOptions(context, 'drivers')}
    getOptionLabel={(option) => {
      // Value selected with enter, right from the input
      if (typeof option === 'string') {
        return option;
      }
      // Add "xxx" option created dynamically
      if (option.inputValue) {
        return option.inputValue;
      }
      // Regular option
      return option.name ? option.name : '';
    }}
    renderOption={(option) => option.name ? option.name : ''}
    freeSolo
    renderInput={(params) => (
      <TextField {...params}  error={Boolean(touched[fields[fieldIdx].fieldKey] && errors[fields[fieldIdx].fieldKey])}

      key={fields[fieldIdx].fieldKey}
      id={fields[fieldIdx].fieldKey}
      helperText={touched[fields[fieldIdx].fieldKey] && errors[fields[fieldIdx].fieldKey]}
      label={fields[fieldIdx].fieldName}
      margin="normal"
   
      name={fields[fieldIdx].fieldKey}
      onBlur={handleBlur}
      onChange={handleChange}
      value={values[fields[fieldIdx].fieldKey]}
      variant="outlined"/>
    )}
  />

              <TextField
              error={Boolean(touched[fields[fieldIdx2].fieldKey] && errors[fields[fieldIdx2].fieldKey])}
              style={{width: 200, marginLeft: 5}}
              id={fields[fieldIdx2].fieldKey}
              key={fields[fieldIdx2].fieldKey}
              helperText={touched[fields[fieldIdx2].fieldKey] && errors[fields[fieldIdx2].fieldKey]}
              label={fields[fieldIdx2].fieldName}
              margin="normal"
              name={fields[fieldIdx2].fieldKey}
              onBlur={handleBlur}
              onChange={handleChange}
              value={values[fields[fieldIdx2].fieldKey]}
              variant="outlined"
              type={getType(fields[fieldIdx2].fieldType)}
              InputLabelProps={{
                shrink: true,
                }}
            />

                            <Button
                        color="primary"
                        style={{margin: 5, marginLeft: 20, width: 185, alignSelf: 'center'}}
                        disabled={loading}
                        id="sendButtonNext"
                        variant="contained"
                        onClick={() => setAddDriverDialog(true)}
                      >
                        New Driver
                      </Button>
                      </div>

  }

  return (
    <MyContext.Consumer>{context => (
  <Page
    className={classes.root}
    height='100%'
    title="Dispatch"
    id="ff2"
    backgroundColor={classes.root.backgroundColor}
  >
    <Box
      display="flex"
      flexDirection="column"
      id="bb2"
      backgroundColor={classes.root.backgroundColor}

    >
                  <AddTransporterDialog
            context={context}
            fields={dictToList(context.barleyInCustomObjectFields['transporters'])}
        open={addTransporterDialog}
        onClose={() => setAddTransporterDialog(false)}
        alertTitle={'Add Transporter'}
        alertCancelText={'Cancel'}
        alertConfirmText={'Confirm'}
        onConfirm={(x) => setAddTransporterDialog(false)}
        />
                          <AddDriverDialog
            context={context}
            fields={dictToList(context.barleyInCustomObjectFields['drivers'])}
        open={addDriverDialog}
        onClose={() => setAddDriverDialog(false)}
        alertTitle={'Add Driver'}
        alertCancelText={'Cancel'}
        alertConfirmText={'Confirm'}
        onConfirm={(x) => setAddDriverDialog(false)}
        />
                          <AddVehicleDialog
            context={context}
            fields={dictToList(context.barleyInCustomObjectFields['vehicles'])}
        open={addVehicleDialog}
        onClose={() => setAddVehicleDialog(false)}
        alertTitle={'Add Vehicle'}
        alertCancelText={'Cancel'}
        alertConfirmText={'Confirm'}
        onConfirm={(x) => setAddVehicleDialog(false)}
        />
      <Container id="cc2"  backgroundColor={classes.root.backgroundColor}>
        <Formik
        id='f2'
          initialValues={{
            averagebagweight: 50,
            averagebagweightwh: 50, 
            warehouseoutlink: 'x',
            dispatchdate: Date.now()
          }}
          validationSchema={
            Yup.object().shape({

            })
          }
          onSubmit={(values) => {
            //doSubmit(values)
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting2,
            setSubmitting, 
            touched,
            values,
            setFieldValue
          }) => (
            <form id='fu2' onSubmit={handleSubmit}>
                  <div id='fkx1' style={{marginTop: 5}}>
              <Box mb={3}>
                <Typography
                  color="textPrimary"
                  variant="h2"
                >
                 {'Dispatch Truck'}
                </Typography> 
              </Box>

              <Stepper activeStep={activeStep} style={{width: '100%', marginBottom: 15}}>
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>

            </Step>
          );
        })}
      </Stepper>

      {activeStep == 0 ? 

<div>
  <div style={{flexDirection: 'row'}}>

  {context.targetWarehouse == -1 || (context && context.targetWarehouse && context.targetWarehouse.warehousetype == 'Port') ? 
    <TextField select
    disabled={false}
    style={{minWidth: 250}}
    label="Source Type"
    margin="normal"
    id="arrivalType"
    variant="outlined"
    name="arrivalType"
    value={arrivalType}
    onChange={(e) => setArrivalType(e.target.value)}
  >
    {['Train', 'Warehouse', 'Vessel'].map((item, idx) => {
      return (<MenuItem value={item}>{item}</MenuItem>)
    })}
  </TextField>
  :
  <TextField select
  disabled={false}
  style={{minWidth: 250}}
  label="Source Type"
  margin="normal"
  id="arrivalType"
  variant="outlined"
  name="arrivalType"
  value={arrivalType}
  onChange={(e) => setArrivalType(e.target.value)}
>
  {['Train', 'Warehouse'].map((item, idx) => {
    return (<MenuItem value={item}>{item}</MenuItem>)
  })}
</TextField>
  } 



        <Typography>{renderPlanInformation(operation, destination, sku, context, (x,y) => setFieldValue(x,y), values)}</Typography>
    </div>



    {arrivalType == 'Vessel' 
? 
<div style={{flexDirection: 'row', display: 'flex'}}>
<div style={{width: '40%'}}>


<Autocomplete
  id="operation"
  disabled={false}
  options={context.operationList}
  getOptionLabel={(option) => option.operationnumber + ' - ' + option.name}
  value={operation}
  onChange={(e, value) => {   
    if (value != null) {
      setOperation(value)
      setTrain(null)
      setSku(null)
    }
  }}
  renderInput={(params) => <TextField {...params}
  disabled={false}
  label="Operation"
  margin="normal"
  id="operation"
  name="operation"
  variant="outlined"
  value={operation}
  defaultValue={operation} />}
/>


<Autocomplete
  id="destination"
  disabled={false}
  options={filterDestinationsVessel(getAutoOptions(context, 'warehouse'), operation, context, values)}
  getOptionLabel={(option) => option.union ? option.name + ' / ' + option.union : option.name}
  value={destination}
  onChange={(e, value) => {   
    if (value != null) {
      setDestination(value)
    }
  }}
  renderInput={(params) => <TextField {...params}
  disabled={false}
  label="Destination"
  margin="normal"
  id="destination"
  name="destination"
  variant="outlined"
  value={destination}
  defaultValue={destination} />}
/>

<Autocomplete
  id="sku"
  disabled={false}
  options={getAutoOptions(context, 'sku').filter((item, idx) => {
  return operation == null || (operation.sku && item && operation.sku.name == item.name)
})}
  getOptionLabel={(option) => option.name}
  value={sku}
  onChange={(e, value) => {   
    if (value != null) {
      setSku(value)
    }
  }}
  renderInput={(params) => <TextField {...params}
  disabled={false}
  label="SKU"
  margin="normal"
  id="sku"
  name="sku"
  variant="outlined"
  value={sku}
  defaultValue={sku} />}
/>
</div>
  </div>
: null}


{arrivalType == 'Train' 
? 
<div style={{flexDirection: 'row', display: 'flex'}}>
<div style={{width: '40%'}}>


<Autocomplete
  id="operation"
  disabled={false}
  options={context.operationList}
  getOptionLabel={(option) => option.operationnumber + ' - ' + option.name}
  value={operation}
  onChange={(e, value) => {   
    if (value != null) {
      setOperation(value)
      setTrain(null)
      setSku(null)
    }
  }}
  renderInput={(params) => <TextField {...params}
  disabled={false}
  label="Operation"
  margin="normal"
  id="operation"
  name="operation"
  variant="outlined"
  value={operation}
  defaultValue={operation} />}
/>


<Autocomplete
  id="train"
  disabled={state.requestPreset != null}
  options={context.trainList.filter((item, idx) => {
  return operation == null || (item.operations && item.operations.includes(operation.operationId))
})}
  getOptionLabel={(option) => option.name}
  value={train}
  onChange={(e, value) => {
    
    if (value != null) {
     
  
       setTrain(value)
       setSku(null)
    }
    

  }}
  renderInput={(params) => <TextField {...params}
  disabled={state.requestPreset != null}
  label="Train"
  margin="normal"
  id="train"
  name="train"
  variant="outlined"
  value={train}
  defaultValue={train} />}
/>

<Autocomplete
  id="destination"
  disabled={false}
  options={getAutoOptions(context, 'warehouse').filter((item, idx) => {
    return destFilter(item, train)
  })}
  getOptionLabel={(option) => option.union ? option.name + ' / ' + option.union : option.name}
  value={destination}
  onChange={(e, value) => {   
    if (value != null) {
      setDestination(value)
    }
  }}
  renderInput={(params) => <TextField {...params}
  disabled={false}
  label="Destination"
  margin="normal"
  id="destination"
  name="destination"
  variant="outlined"
  value={destination}
  defaultValue={destination} />}
/>

<Autocomplete
  id="sku"
  disabled={false}
  options={getAutoOptions(context, 'sku').filter((item, idx) => {
  return operation == null || (operation.sku && item && operation.sku.name == item.name)
})}
  getOptionLabel={(option) => option.name}
  value={sku}
  onChange={(e, value) => {   
    if (value != null) {
      setSku(value)
    }
  }}
  renderInput={(params) => <TextField {...params}
  disabled={false}
  label="SKU"
  margin="normal"
  id="sku"
  name="sku"
  variant="outlined"
  value={sku}
  defaultValue={sku} />}
/>
</div>
{renderTrainStats(train, destination)}
  </div>
: null}
{arrivalType == 'Warehouse'
? 
<div style={{flexDirection: 'row', display: 'flex'}}>
<div style={{width: '40%'}}>

<Autocomplete
  id="source"
  disabled={false}
  options={getAutoOptions(context, 'warehouse').filter((item, idx) => {
    return item && (item.warehousetype == 'Internal' || item.warehousetype == 'Port') && (context.targetWarehouse == -1 || item.name in context.targetWarehouseSet)
  })}
  getOptionLabel={(option) => option.name}
  value={source}
  onChange={(e, value) => {   
    if (value != null) {
      setSource(value)
      setOperation(null)
      setTrain(null)
      setDestination(null)
      setSku(null)
    }
  }}
  renderInput={(params) => <TextField {...params}
  disabled={false}
  label="Source"
  margin="normal"
  id="source"
  name="source"
  variant="outlined"
  value={source}
  defaultValue={source} />}
/>


<Autocomplete
  id="operation"
  disabled={false}
  options={context.operationList}
  getOptionLabel={(option) => option.operationnumber + ' - ' + option.name}
  value={operation}
  onChange={(e, value) => {   
    if (value != null) {
      setOperation(value)
      setTrain(null)
      setSku(null)
    }
  }}
  renderInput={(params) => <TextField {...params}
  disabled={false}
  label="Operation"
  margin="normal"
  id="operation"
  name="operation"
  variant="outlined"
  value={operation}
  defaultValue={operation} />
  
  
  }
/>

{source && source.warehousetype == 'Port' ? null

:<Autocomplete
id="train"
disabled={state.requestPreset != null}
options={context.trainList.filter((item, idx) => {
  if (source && source.name && item.warehouse && item.warehouse.name != source.name) {
    return false
  }
return operation == null || (item.operations && item.operations.includes(operation.operationId))
})}
getOptionLabel={(option) => option.name}
value={train}
onChange={(e, value) => {
  
  if (value != null) {
   

     setTrain(value)
     setSku(null)
  }
  

}}
renderInput={(params) => <TextField {...params}
disabled={state.requestPreset != null}
label="Train"
margin="normal"
id="train"
name="train"
variant="outlined"
value={train}
defaultValue={train} />}
/>
}




<Autocomplete
  id="destination"
  disabled={false}
  options={getAutoOptions(context, 'warehouse').filter((item, idx) => {
    return destFilter(item, train)
  })}
  getOptionLabel={(option) => option.union ? option.name + ' / ' + option.union : option.name}
  value={destination}
  onChange={(e, value) => {   
    if (value != null) {
      setDestination(value)
    }
  }}
  renderInput={(params) => <TextField {...params}
  disabled={false}
  label="Destination"
  margin="normal"
  id="destination"
  name="destination"
  variant="outlined"
  value={destination}
  defaultValue={destination} />}
/>

<Autocomplete
  id="sku"
  disabled={false}
  options={getAutoOptions(context, 'sku').filter((item, idx) => {
  return operation == null || (operation.sku && item && operation.sku.name == item.name)
})}
  getOptionLabel={(option) => option.name}
  value={sku}
  onChange={(e, value) => {   
    if (value != null) {
      setSku(value)
    }
  }}
  renderInput={(params) => <TextField {...params}
  disabled={false}
  label="SKU"
  margin="normal"
  id="sku"
  name="sku"
  variant="outlined"
  value={sku}
  defaultValue={sku} />}
/>

</div>
{renderTrainStats(train, destination)}
  </div>
: null}


  </div>


: <div style={{display: 'flex', flexDirection: 'row', width: activeStep == 2 ? '90%' : '100%'}}>
  <div style={{width: '50%'}}>
    {activeStep == 1 ? 
    renderOnlyTransporter(state.fields.filter((item, idx) => {
      return item.fieldKey == 'transportservice' || item.fieldKey == 'transporterphone'
    }), touched, errors, handleBlur, handleChange, values, setFieldValue, context)
  : null}
      {activeStep == 1 ? 
    renderOnlyVehicle(state.fields.filter((item, idx) => {
      return item.fieldKey == 'platenumber' || item.fieldKey == 'trailerplatenumber'
    }), touched, errors, handleBlur, handleChange, values, setFieldValue, context)
  : null}
      {activeStep == 1 ? 
    renderOnlyDriver(state.fields.filter((item, idx) => {
      return item.fieldKey == 'drivername' || item.fieldKey == 'driverphone'
    }), touched, errors, handleBlur, handleChange, values, setFieldValue, context)
  : null}
  {renderContent(state.fields.filter((item, idx) => {
  return stepFields[activeStep].includes(item.fieldKey) && item.fieldKey != 'displaybol' && item.fieldKey != 'displaydeclaration' && item.fieldKey != 'dispatchdate' && item.fieldKey != 'transportservice'
}), touched, errors, handleBlur, handleChange, values, setFieldValue, context, activeStep)}

{activeStep == 1 ? 
  <DateTimeMaster label={'Dispatch Date & Time'} value={momentFormat(values.dispatchdate)} disabled={false} onChange={(e) => setFieldValue('dispatchdate', e)}/>
: null}
</div>

{activeStep == 1 && arrivalType != 'Train' ? renderLaborers(laborers) : null}

</div>}
{activeStep == 2 && arrivalType == 'Train' ?
  <div>
  {wagons.map((item, idx) => {
    return <div>
                    <TextField
              id={'wagonName'}
              key={idx + 'wagonName'}
              
              label={'Wagon Number'}
              margin="normal"
              name={'wagonName'}
              onChange={(e) => updateWagons('name', idx, e.target.value)}
              value={wagons[idx].name}
              variant="outlined"
              type={'text'}
              InputLabelProps={{
                shrink: true,
                }}
            />
                                <TextField
              id={'wagonQty'}
              key={idx + 'wagonQty'}
              label={'Number of Bags From Wagon'}
              margin="normal"
              name={'wagonQty'}
              onChange={(e) => updateWagons('qty', idx, e.target.value)}
              value={wagons[idx].qty}
              variant="outlined"
              type={'number'}
              InputLabelProps={{
                shrink: true,
                }}
            />
                                            <TextField
              id={'wagonBag'}
              key={idx + 'wagonBag'}
              label={'Average Bag Weight'}
              margin="normal"
              name={'wagonBag'}
              onChange={(e) => updateWagons('bag', idx, e.target.value)}
              value={wagons[idx].bag}
              variant="outlined"
              type={'number'}
              InputLabelProps={{
                shrink: true,
                }}
            />
            <XIcon  style={{marginTop: 30}} onClick={(e) => {
              setWagons(wagons.filter((item, idxx) => {
                return idxx != idx
              }))
            }}/>
      </div>
  })}
  <Button
                       color="primary"
                    
                       size="large"
                       id="sendButtonNext"
                       variant="contained"
                       onClick={() => {
                         var wag = wagons 
                         wag.push({'name': '', 'qty': '', 'bag': 50})
                         setWagons(wag)
                         forceUpdate()
                       }}
                       style={{margin: 5, width: 150}}
                     >
                       Add Wagon
                     </Button>
  
                     <Box
        alignItems="center"
        display="flex"
        ml={-1}
      >
        <Checkbox
        id="showAllVehicles"
          checked={values.trainAndWarehouse}
          name="showAllVehicles"
          onChange={(e) =>{
            if (!values.trainAndWarehouse) {
              setFieldValue('trainAndWarehouse', true)
            } else {
              setFieldValue('trainAndWarehouse', false)
            }
          }}
        />
        <Typography
          color="textPrimary"
          variant="body1"
        >
          Include Warehouse Stock
        </Typography>
        <Tooltip placement="right-end" title="Check this box to include some cargo from the warehouse.">
                                <Help />
                            </Tooltip>
      </Box>
      {values.trainAndWarehouse ?

    <div style={{flexDirection: 'row'}}>
      <TextField
                         id={'twhQty'}
                         key={'twhQty'}
                         label={'Number of Bags (Warehouse)'}
                         margin="normal"
                         name={'numberofbagswh'}
                         onChange={(e) => specialUpdateWh('numberofbagswh', e.target.value, setFieldValue, values)}
                         value={values.numberofbagswh}
                         variant="outlined"
                         type={'number'}
                         InputLabelProps={{
                           shrink: true,
                           }}
                       />
                                                       <TextField
                         id={'twhBag'}
                         key={'twhBag'}
                         label={'Average Bag Weight (Warehouse)'}
                         margin="normal"
                         name={'wagonBag'}
                         onChange={(e) => specialUpdateWh('averagebagweightwh', e.target.value, setFieldValue, values)}
                         value={values.averagebagweightwh}
                         variant="outlined"
                         type={'number'}
                         InputLabelProps={{
                           shrink: true,
                           }}
                       />

      </div>
    
    : null}
  </div>
  : null

}
</div>


{activeStep == 2 ? 
              <div>
                <Typography>
                  {'Net Weight: ' + getTotalAmount(amount, amountWh) + 'kg'}
                </Typography>
                </div>

                : null}

<Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'row', pt: 2 }}>


<Button
                       color="primary"
                       disabled={activeStep == 0}
                       size="large"
                       id="sendButtonNext"
                       variant="contained"
                       onClick={handleBack}
                       style={{margin: 5, width: 150}}
                     >
                       {'Back'}
                     </Button>



{activeStep < steps.length - 1 ? 
                       <Button
                       color="primary"
                       disabled={operation == null || loading || arrivalType == null ||  (arrivalType == 'Warehouse' && (source == null || destination == null || source.name == destination.name)) || (arrivalType == 'Train' && (train == null || sku == null || destination == null))}
                       size="large"
                       id="sendButtonNext"
                       variant="contained"
                       onClick={handleNext}
                       style={{margin: 5, width: 150}}
                     >
                       Next
                     </Button>
: 
<Box my={2} style={{alignSelf: 'center', flexDirection: 'column', display: 'flex'}}>

<Button
color="primary"
style={{margin: 5, width: 150}}
disabled={loading || amount == null || amount <= 0}
id="sendButtonNext"
variant="contained"
onClick={() => {
  if (arrivalType == 'Train') {
    if (values.trainAndWarehouse) {
      doSubmitTrainAndWarehouse(values, context)
    } else {
      doSubmitTrain(values, context)
    }

  } else if (arrivalType == 'Warehouse') {
    doSubmitWarhouse(values, context)
  } else if (arrivalType == 'Vessel') {
    doSubmitVessel(values, context)
  }
} }
>
Confirm
</Button>
</Box>
}
</Box>


              {loading ? <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: 50}}><CircularProgress/></div> : null}

              
            </form>
          )}
        </Formik>
      </Container>
    </Box>
  </Page>
        )}</MyContext.Consumer>
  )
};

export default NewDispatchView;
