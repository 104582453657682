
import React, { useState, useEffect, Component } from 'react';
import { Link as RouterLink} from 'react-router-dom';
import {
  Box,
  Container,
  makeStyles,
  Button,
  Typography,
  CircularProgress,
  Stepper,
  StepLabel,
  StepContent,
  Step,
  InputLabel,
  Link,
  Grid
} from '@material-ui/core';
import Page from 'src/components/Page';
import TopBar from 'src/layouts/DashboardLayout/TopBar'
import TransporterTable from '../customer/CustomerListView/TransporterTable'
import Firebase from 'src/Firebase.js'
import GoogleMapReact from 'google-map-react';
import { HotTub } from '@material-ui/icons';
import HoverMarker from 'src/HoverMarker';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import {isMobile} from 'react-device-detect';
import moment from 'moment';
import ContractDetails from '../../utils/ContractDetails';
import JobCard from '../reports/DashboardView/JobCard';

const WAIT_THRESH = 5;
const sleep = (milliseconds) => {
    return new Promise(resolve => setTimeout(resolve, milliseconds))
  }

class FreightSummaryPane extends Component {


  constructor() {
      super()
    //console.log(props)
    this.state = {

      tableItems: [],
      existingDict: {},
      initialSelectedIds: [],
      targetJob: '',
      summaryItems: {},
      driverData: {},
      vehicleData: {},
      vehicleTrackData: {},
      userTrackData: {},
      noExist: false,
      noExistData: false, 
      summaryLoaded: true, 
      jobLoaded: false, 
      actualJobData: {},
      zoomUpdate: 7,
      zoom: 7,
      drawnPoly: false,
      dirPolyRendered: false, 
      dirPolyline: null,
      didDoLoadDual: false,
      driveTime: 'none',
      jobsList: [],
      jobsListDict: {},
      center: {
        lat: 40,
        lng: 40
      }

    }
    this.listeners = {}
    this.autocompleteService = { current: null };
    this.geocoder = {current: null};
    this.directionsService = {current: null};
    this.directionsDisplay = {current: null};
    this.bounds = {current: null};
    this.mapHolder = null;
    this.parentReference = this;
    this.lastFire = new Date();
    

  }

  updateState(state) {
    this.setState(state)
  }


  componentWillUnmount() {
    Object.keys(this.listeners).map((item, idx) => {
      this.listeners[item].off('value')
  })
  if (this.state.didDoLoadDual && this.state.actualJobData) {
    //Firebase.database.ref('jobsByFreightOrder/' + this.state.actualJobData.freightOrderId).off('child_added')
    //Firebase.database.ref('jobsByFreightOrder/' + this.state.actualJobData.freightOrderId).off('child_removed')
  }
  }

  async functionalStateListAdd( v, listTarget, dictTarget, idTarget) {



        
    //console.log('try doing a functional update outer for ' + v[idTarget] )
    var prevState = this.state
    var temp = prevState[listTarget]
    var temp2 = prevState[dictTarget]
    temp.push(v)
    temp2[v[idTarget]] = 1
    var retvar = {}
    this.state[listTarget] = temp 
    this.state[dictTarget] = temp2 
    if (this.allowParentUpdates) {
        if (this.parentReference != null) {
            this.parentReference.updateState(this.state)
        }
    }

    //return retvar//{ tableItems: temp, existingDict: temp2 }

}

async functionalStateListUpdate( v, listTarget, idTarget) {

    //console.log('try doing a functional update outer for ' + key )
    //this.setState(function (prevState, props) {
    var prevState = this.state 
    var temp = prevState[listTarget].map((item, idx) => {
    if (item[idTarget] != v[idTarget]) {
    return item
    }
    return v
    })

    
    this.state[listTarget] = temp 
    //return retvar//{ tableItems: temp }
    //})
    if (this.allowParentUpdates) {
        if (this.parentReference != null) {
            this.parentReference.updateState(this.state)
        }
    }
}

async loadSingleItemByKey(secondary_target, secondary_key, listTarget, dictTarget, idTarget) {
    this.numCalls += 1
    //console.log(this.numCalls)
    if (this.numCalls > WAIT_THRESH) {
        await sleep(Math.floor(Math.random() * 200))
        
    }
    console.log('done sleeping')
    this.lastFire = new Date()
    let self = this
    console.log(secondary_target + '/' + secondary_key)
    this.listeners[secondary_target + '/' + secondary_key] = Firebase.database.ref(secondary_target + '/' + secondary_key)
    console.log('here is shipperRequests/' + secondary_key)
    console.log('but with sec target of/' + secondary_target)
    this.listeners[secondary_target + '/' + secondary_key].on("value", async (inner_snapshot) => {
    if (inner_snapshot.exists()) {
        //alert('do exist')
    if (inner_snapshot.val()[idTarget] in self.state[dictTarget]) {
      //alert('this is an update')
    self.functionalStateListUpdate(inner_snapshot.val(), listTarget, idTarget)
    } else {
      //alert('this is an add')
        console.log('performing add for ' + inner_snapshot.val()[idTarget])
    self.functionalStateListAdd(inner_snapshot.val(), listTarget, dictTarget, idTarget)
    }

    } else {
        //alert('noexist ' + secondary_target + '/' + secondary_key)
    console.log('container found to not exist for ' + secondary_key)
    }
    }, function (error) {
    console.log('error fetching container for ' + secondary_key)
    console.log(error)
    })
}

loadListDualRef(firebase_primary_target, firebase_secondary_target, listTarget, dictTarget, idTarget) {
    // Maintain reference to main component for state updates
    let self = this
    console.log(firebase_primary_target)
    console.log(firebase_secondary_target)
    if (listTarget == 'transporterAssignedList') {
        //alert(firebase_primary_target)
    }
    let addlist = Firebase.database.ref(firebase_primary_target).on("child_added", async (inner_snapshot, prevkey) => {
       
    // Get key of secondary item
    if (listTarget == 'transporterAssignedList') {
        //alert(inner_snapshot.val())
    }
    if (inner_snapshot.exists()) {
    let secondary_key = inner_snapshot.key
    self.loadSingleItemByKey(firebase_secondary_target, secondary_key, listTarget, dictTarget, idTarget)
    } else {

    }
    //self.loadSingleItemByKey(firebase_secondary_target, secondary_key)

    }, function (errorObject) {
    if (self.state.report_errors) {
    alert(errorObject)
    }
    console.log("The read failed: " + errorObject.code);
    });

    Firebase.database.ref(firebase_primary_target).on("child_removed", (snapshot) => {
    let secondary_key = snapshot.key

    Firebase.database.ref(firebase_secondary_target + '/' + secondary_key).off("value")
    //alert(JSON.stringify(self.state[listTarget]))
    console.log('try to do remove fsilter operaationn for ' + firebase_secondary_target + '/' + secondary_key)

    var nextList = self.state[listTarget].filter((item, idx) => {
        console.log('filter comparison of ' + item[idTarget] + ' to ' + secondary_key)
        return (item[idTarget] != secondary_key)
    })

    var existingDict = self.state[dictTarget]
    delete existingDict[secondary_key]
    var retNext = {}
    retNext[listTarget] = nextList 
    retNext[dictTarget] = existingDict
    self.setState(retNext)

    }, function (errorObject) {
    if (self.state.report_errors) {
    alert(errorObject)
    }
    console.log("The read failed: " + errorObject.code);
    });
}

  polyRender(jobData, userData, vehicleData, map, maps) {

    console.log('try poly render')
    console.log(userData)
    console.log(vehicleData)
    var latTarget = null 
    var lngTarget = null 
    if (vehicleData != null && vehicleData != {} && Object.keys(vehicleData).length > 0) {
      latTarget = vehicleData.lat 
      lngTarget = vehicleData.lng 
    } else if (userData != null && userData != {} && 'coords' in userData) {
      console.log('set these things')
      latTarget = userData.coords.latitude
      lngTarget = userData.coords.longitude
    } else {
      console.log('dont set???')
    }

    console.log(latTarget)
    console.log(lngTarget)
    console.log(userData.coords)
    console.log(userData != null && userData != {} && 'coords' in userData)
    if (latTarget == null || lngTarget == null) {
      console.log('ret1')
      return 
    }

    if (this.state.actualJobData == null || this.state.actualJobData == {}) {
      console.log('ret11')
      return 
    }

    if (map == null || maps == null ) {
      console.log('ret111')
      return
    }

    if (jobData.state != 7 && jobData.state != 8) {
      return 
    }

    let strstart = latTarget + ', ' + lngTarget
    var strend = this.state.actualJobData.endPos.lat + ', ' + this.state.actualJobData.endPos.lng
    if (jobData.state == 7) {
      strend = jobData.startPos.lat + ', ' + jobData.startPos.lng
    }
    let strkey = strstart + '_' + strend
    console.log(strkey)
    if (this.directionsService.current) {
      this.directionsService.current.route({
        origin: strstart,
        destination: strend,
        travelMode: 'DRIVING'
      }, (response, status) => {
        if (status === 'OK') {
          console.log(response)
          console.log(response.routes[0].legs[0].start_location.lat())  
          console.log(response.routes[0].legs[0].start_location.lng())
    
          /*this.setState({
            startLat: response.routes[0].legs[0].start_location.lat(),
            startLng: response.routes[0].legs[0].start_location.lng(),
            endLat: response.routes[0].legs[0].end_location.lat(),
            endLng: response.routes[0].legs[0].end_location.lng()
          })*/
          //setStartLat(response.routes[0].legs[0].start_location.lat())
          //setStartLng(response.routes[0].legs[0].start_location.lng())
          //setEndLat(response.routes[0].legs[0].end_location.lat())
          //setEndLng(response.routes[0].legs[0].end_location.lng())
  
    
          this.directionsDisplay.current.setDirections(response);
          console.log(response.routes[0].overview_path)
          console.log('drawpoly')
          const routePolyline = new maps.Polyline({
            path: response.routes[0].overview_path
          });
          if (this.state.dirPolyline != null) {
            this.state.dirPolyline.setMap(null)
          }
          if (jobData.state == 7) {
                routePolyline.setOptions({strokeColor: 'rgb(25,188,156)', strokeWeight: 3});
          } else {
            routePolyline.setOptions({strokeColor: 'rgb(66,163,253)', strokeWeight: 3});
          }
          
          routePolyline.setMap(map);
    
          let mainRoute = response.routes[0]
          var retMiles = 0
          var time = 0 
          mainRoute.legs.map((leg, legIdx) => {
              let distMeters = leg.distance.value
              let distMiles = distMeters / 1000
              retMiles += distMiles
              time += leg.duration.text 
          })
  
          
    
          let milesStr = (Math.round(retMiles * 10) / 10) + ' Km'
          let dist = (Math.round(retMiles * 10) / 10)
    
          this.setState({
            dirPolyline: routePolyline,
            dirPolyRendered: true, 
            driveTime: time 
          
          })
  
    
        } else {
          //window.alert('Directions request failed due to ' + status);
          console.log('Directions request failed due to ' + status)
          }
        });
  
    }
    


  }



  async componentDidMount() {
    let self = this 
    this.loadingInterval = setInterval(function() {
      console.log(self.testStr)
      let curDat = new Date()
      let dif = curDat - self.lastFire
      console.log(curDat)
      console.log(self.lastFire)
      if ((dif) > 250) {
          if (self.parentReference != null) {
              //console.log(' did load in time ' + dif)
              //console.log(self.state)
              self.allowParentUpdates = true 
              self.parentReference.updateState(self.state)
              clearInterval(self.loadingInterval)
          }
          //clearInterval(self.loadingInterval)
      } else {
          console.log('did not load in time because time was ' + dif )
      }
  }, 250)
    console.log(this.props)
    console.log(JSON.stringify(this.props))
    console.log(this.props.location.pathname.split('/')[2])
    let targetJob = this.props.location.pathname.split('/')[2]
    //alert(this.props.location.pathname)
    console.log(targetJob)
    this.setState({
        targetJob: targetJob
    })

    console.log(Firebase.database)
    console.log('faj2039993ef99f9f9')
    if (!Firebase.database) {
        Firebase.awfulStuff.push(() => this.badMethod(targetJob))
    } else {
     // console.log('here is ' + targetJob)
        this.badMethod(targetJob)
    }
  }

  deactivateListenerIfNeeded(s) {
    if (s in this.listeners) {
      this.listeners[s].off('value')
    }
  }

  isNumeric(n) {
    return !isNaN(parseFloat(n)) && isFinite(n);
  }

  async badMethod(targetJoba) {
    let self = this 
    var targetJob = targetJoba 
    if (self.isNumeric(targetJoba) && targetJoba.length < 20) {
      let nextvt = await Firebase.database.ref('mapShortIdToFreightOrderId/' + targetJoba).once('value').then((data) => {
        if (!data.exists()) {
            return null
        } else {
            return data.val()
        }

    }).catch((error) => {
        return null 
    })
    if (nextvt != null) {
      targetJob = nextvt
    }
    }

    var authorizationData = {}
    authorizationData['freightOrderId'] = targetJob
    // give auth to read quote
    let results = await Firebase.authorizeTrackingFreightOrder(authorizationData, () => console.log('succeeded auth req'), () => console.log('failed auth req'))
    //alert(JSON.stringify(results))
    if (results != false) {
      results.list.map((item, idx) => {
        self.loadSingleItemByKey('shipperRequests', item, 'jobsList', 'jobsListDict', 'quoteId')
      })
    }


    console.log(targetJob)
    self.setState({
      didDoLoadDual: true
    })
    //self.loadListDualRef('jobsByFreightOrder/' + targetJob, 'shipperRequests', 'jobsList', 'jobsListDict', 'quoteId')
  
    Firebase.database.ref('shipperFreightOrders/' + targetJob).on("value", (inner_snapshot) => {
      // Get key of secondary item
      //alert(inner_snapshot.val())
      if (inner_snapshot.exists()) {
        console.log(inner_snapshot.val())

        if (self.state.dirPolyRendered || (self.state.actualJobData != null && self.state.actualJobData.state != 7 && self.state.actualJobData.state != 8) ) {
          self.polyRender(inner_snapshot.val(), self.state.userTrackData, self.state.vehicleTrackData, self.state.map, self.state.maps)
        }
          self.setState({
              actualJobData: inner_snapshot.val(),
              jobLoaded: true, 
              noExistData: false  
          })



          let itemVal = inner_snapshot.val()
          if (itemVal.state < 9 && itemVal.state > 5 && 'assignedDriver' in itemVal) {

           this.deactivateListenerIfNeeded('driver')
              this.listeners['driver'] = Firebase.database.ref('users/' + itemVal.assignedDriver)
              this.listeners['driver'].on("value", (driver_snapshot) => {
                if (driver_snapshot.exists()) {
                  self.setState({
                    driverData: driver_snapshot.val()
                  })
                  let driver_snap = driver_snapshot.val()
                  if ('assignedVehicle' in driver_snap) {
                    this.deactivateListenerIfNeeded('vehicle')
                    this.listeners['vehicle'] = Firebase.database.ref('vehicles/' + driver_snap.assignedVehicle) 
                    this.listeners['vehicle'].on("value", (vehicle_snapshot) => {
                      if (vehicle_snapshot.exists()) {
                        self.setState({
                          vehicleData: vehicle_snapshot.val()
                        })
                        let vehicle_snap = vehicle_snapshot.val()
                        self.deactivateListenerIfNeeded('vehicleTrack')
                        this.listeners['vehicleTrack'] = Firebase.database.ref('trackableLocations/vehicles/' + vehicle_snap.vehicleId)
                        this.listeners['vehicleTrack'].on("value", (loc_snapshot) => {
                          if (loc_snapshot.exists()) {
                            self.setState({
                              vehicleTrackData: loc_snapshot.val()
                            })
                            
                          } else {
                
                          }
                          
                      }, function (errorObject) {
          
                      });
                      } else {
            
                      }
                      
                  }, function (errorObject) {
      
                  });

                  }
                  self.deactivateListenerIfNeeded('driverTrack')
                  this.listeners['driverTrack'] = Firebase.database.ref('trackableLocations/users/' + driver_snap.userId)
                  this.listeners['driverTrack'].on("value", (loc_snapshot) => {
                    if (loc_snapshot.exists()) {
                      self.setState({
                        userTrackData: loc_snapshot.val()
                      })
                      
                    } else {
          
                    }
                    
                }, function (errorObject) {
    
                });
                } else {
      
                }
                
            }, function (errorObject) {

            });
           


          } else {

          }

      } else {
        self.setState({
          noExistData: true,
          noExist: true
        })
        console.log('no exist')
      }
      //self.loadSingleItemByKey(firebase_secondary_target, secondary_key)


  }, function (errorObject) {
      if (this.state.report_errors) {
          alert(errorObject)
      }
      console.log("The read failed: " + errorObject.code);
  });

  }

  componentWillUnmount() {
    Firebase.database.ref('summaries/' + this.state.targetJob).off('value')
    }

    getState(item) {
      let state = item.summaryType
      if (state == 0) {
        return "Quote Requested"
      } else if (state == 1) {
        return "Quote Offered by Garri"
      } else if (state == 2) {
        if (item.isLtc) {
         return "Job Created From Long Term Contract" 
        }
        return "Quote Accepted"
      } else if (state == 3) {
        return "Job Offered to Transporters"
      } else if (state == 4) {
        return "Transporter Placed Bid"
      } else if (state == 5) {
        return "Transporter Accepted Offer"
      } else if (state == 6) {
        return "Transporter Assigned Job To Driver"
      } else if (state == 7) {
        return "Driver En Route to Load"
      } else if (state == 8) {  
        return "Driver Loaded"
      } else if (state == 9) {
        return "Driver Delivered Load"
      } else if (state == -1) {
        return "Quote Declined By Shipper"
      }
    }

    renderItem(item) {
      return (
        <div style={{borderRadius: 10, backgroundColor: 'white',  justifyContent: 'center', display: 'flex',
        flexDirection: 'column', margin: 10, padding: 10}}>
        
                   <a style={{fontSize: 24, fontWeight: 'bold', marginBottom: 20}}>{this.getState(item)}</a>

                   <a>{moment(item.time).format('DD/MM/YYYY h:mm a')}</a>
                  </div>
      )
    }

    latRad(lat) {
      var sin = Math.sin(lat * Math.PI / 180);
      var radX2 = Math.log((1 + sin) / (1 - sin)) / 2;
      return Math.max(Math.min(radX2, Math.PI), -Math.PI) / 2;
    }

    handleApiLoaded(newMap, newMaps) {
      console.log('api load')
      //alert(newMap)
      this.setState({
        map: newMap, 
        maps: newMaps
      })

       if (!this.autocompleteService.current || !this.state.drawnPoly) {
         this.autocompleteService.current = new newMaps.places.AutocompleteService();
         this.geocoder.current = new newMaps.Geocoder();
   
         this.directionsService.current = new newMaps.DirectionsService();
         this.directionsDisplay.current = new newMaps.DirectionsRenderer();
         this. bounds.current  = new newMaps.LatLngBounds()
         if (this.state.actualJobData) {
           this.updateMapDraw(this.state.actualJobData.startPos, this.state.actualJobData.endPos, newMap, newMaps);
           this.polyRender(this.state.actualJobData, this.state.userTrackData, this.state.vehicleTrackData, newMap, newMaps)
         }
         
       }
     }

     momentFormatDate(date) {
      return moment(date).format('yyyy-MM-DD')
    }

     updateMapDraw(startPos, endPos, mapObj, mapsObj ) {
      //alert('upda')
      if (startPos == null || endPos == null || this.directionsDisplay.current == null || this.directionsService.current == null) {
        return 
      }
    
    
      console.log('here a holder')
  
      console.log(startPos)
      console.log(endPos)
      let latDif = Math.abs(this.latRad(startPos.lat) - this.latRad(endPos.lat))
      let lngDif = Math.abs(startPos.lng - endPos.lng)
      console.log('heredifs')
      console.log(lngDif)
      console.log(latDif)
      //let latDifRaf = letRad(latDif)
      //let lngDifRad = 
    
      let latFrac = latDif / Math.PI 
      let lngFrac = lngDif / 360 
    
    
      let lngZoom = Math.log(1/latFrac) / Math.log(2)
      let latZoom = Math.log(1/lngFrac) / Math.log(2)
      console.log(latFrac)
      console.log(lngFrac)
      console.log(Math.log(1/latFrac) / Math.log(2))
      console.log(Math.log(1/lngFrac) / Math.log(2))
      //alert('new zoom ' + Math.min(lngZoom, latZoom))
      this.setState({
        zoom: Math.min(lngZoom, latZoom)
      })
      //setZoom()
    
      let cx = startPos.lat + ((endPos.lat - startPos.lat) / 2)
      let cy = startPos.lng + ((endPos.lng - startPos.lng) / 2)
      //setCenter()
      this.setState({
        center: {lat: cx, lng: cy}
      })
    
      //setZoom(getBoundsZoomLevel(bounds, { height: mapHolder.current.clientHeight, width: mapHolder.current.clientWidth }))
    
      let strstart = startPos.lat + ', ' + startPos.lng
      let strend = endPos.lat + ', ' + endPos.lng
      let strkey = strstart + '_' + strend
      console.log(strkey)
      this.directionsService.current.route({
        origin: strstart,
        destination: strend,
        travelMode: 'DRIVING'
      }, (response, status) => {
        if (status === 'OK') {
          console.log(response)
          console.log(response.routes[0].legs[0].start_location.lat())  
          console.log(response.routes[0].legs[0].start_location.lng())
    
          this.setState({
            startLat: response.routes[0].legs[0].start_location.lat(),
            startLng: response.routes[0].legs[0].start_location.lng(),
            endLat: response.routes[0].legs[0].end_location.lat(),
            endLng: response.routes[0].legs[0].end_location.lng()
          })
          //setStartLat(response.routes[0].legs[0].start_location.lat())
          //setStartLng(response.routes[0].legs[0].start_location.lng())
          //setEndLat(response.routes[0].legs[0].end_location.lat())
          //setEndLng(response.routes[0].legs[0].end_location.lng())

    
          this.directionsDisplay.current.setDirections(response);
          console.log(response.routes[0].overview_path)
          console.log('drawpoly')
          const routePolyline = new mapsObj.Polyline({
            path: response.routes[0].overview_path
          });
          if (this.state.lastPolyline != null) {
            this.state.lastPolyline.setMap(null)
          }
          routePolyline.setMap(mapObj);
    
          let mainRoute = response.routes[0]
          var retMiles = 0
          mainRoute.legs.map((leg, legIdx) => {
              let distMeters = leg.distance.value
              let distMiles = distMeters / 1000
              retMiles += distMiles
          })
    
          let milesStr = (Math.round(retMiles * 10) / 10) + ' Km'
          let dist = (Math.round(retMiles * 10) / 10)
    
          this.setState({
            lastPolyline: routePolyline,
            drawnPoly: true, 
            distance: dist 
          })

    
        } else {
          console.log('Directions request failed due to ' + status)
          //window.alert('Directions request failed due to ' + status);
          }
        });
    }

    renderStepContent(item, step) {

      if (step.summaryType == 2 && step.isLtc || step.summaryType == 0) {
        return <div style={{flexDirection: 'column', display: 'flex'}}>
          <a style={{fontSize: 24, fontWeight: 'bold', marginTop: 5, marginBottom: 5}}>Pickup Details</a>
          <a >{item.pickupLocation}</a>
          <a style={{fontSize: 24, fontWeight: 'bold',  marginTop: 5, marginBottom: 5}}>Dropoff Details</a>
          <a>{item.dropoffLocation}</a>
     
        </div>
      } else if (step.summaryType == 3) {
        return <div style={{flexDirection: 'column', display: 'flex'}}>
       
        </div>
      } else if (step.summaryType == 5) {
        return <div style={{flexDirection: 'column', display: 'flex'}}>
        <a >{'The transporter for your job is: ' + item.assignedTransporterName}</a>
        </div>
      } else if (step.summaryType == 6) {
        return <div style={{flexDirection: 'column', display: 'flex'}}>
        <a >{'The driver for your job is: ' + item.assignedDriverName}</a>
        </div>
      }  else if (step.summaryType == 7) {
        return <div style={{flexDirection: 'column', display: 'flex'}}>
        <a >{'The driver is en route to the pickup location!'}</a>
        {this.state.driveTime != 'none' && this.state.actualJobData != null && this.state.actualJobData.state == 7 ? <a>{'Driver ETA At Pickup: ' + this.state.driveTime}</a> : null}
        </div>
      }  else if (step.summaryType == 8) {
        return <div style={{flexDirection: 'column', display: 'flex'}}>
        <a >{'The driver has picked up the load!'}</a>
        {this.state.driveTime != 'none' && this.state.actualJobData != null && this.state.actualJobData.state == 8 ? <a>{'Driver ETA At Dropoff: ' + this.state.driveTime}</a> : null}
        </div>
      }  else if (step.summaryType == 9) {
        return <div style={{flexDirection: 'column', display: 'flex'}}>
        <a >{'The driver has deliviered the load!'}</a>
        </div>
      }


    }


    renderStep(item, summary) {
         return <Step active={true} key={summary.summaryType}>
            <StepLabel>{this.getState(summary) + ' (' + moment(summary.time).format('DD/MM/YYYY h:mm a') + ')'}</StepLabel>
            <StepContent>{this.renderStepContent(item, summary)}</StepContent>
          </Step>
    }

    renderSteps(baseItem, summaries) {
      var stepSet = []
      var stepDict = {}
      Object.keys(summaries).map((sumKey, idx) => {
        let item = summaries[sumKey]
        stepDict[item.summaryType] = item 
      })
      var keys = Object.keys(stepDict)
      keys.sort()
      keys.map((item, idx) => {
        stepSet.push(stepDict[item])
      })

      return <Stepper style={{backgroundColor: 'white', borderRadius: 10}}  orientation="vertical">
          {stepSet.map((item, idx) => {
            return this.renderStep(baseItem, item)
          })}
        </Stepper>
    }


  render() {

    if (this.state.noExist) {
      return (
        <Page>
         <TopBar />
         <div style={{marginTop: 100, marginLeft: 20}}>
         <Box mb={3}>
                          <Typography
                            color="textPrimary"
                            variant="h2"
                          >
                           Freight Order Tracking
                          </Typography>
                         
                        </Box>
                        <Box mb={3}>
             
                         
                        </Box>
                        
         <Box mt={3}>
        
           <div>
           <Typography
                            color="textSecondary"
                            gutterBottom
                            variant="body2"
                          >
                            {'Request Id:' + this.state.targetJob + ' does not exist.'}
                          </Typography>
        
           </div>
         </Box>
               </div>
                </Page>)
    }

    if (!this.state.jobLoaded || !this.state.summaryLoaded) {
        return (<div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}><CircularProgress/></div>)

    }

    return (
<Page>
 <TopBar />
 <div style={{marginTop: 100, marginLeft: 20, marginBottom: 20}}>
 <Box mb={3}>
                  <Typography
                    color="textPrimary"
                    variant="h2"
                  >
                   {'Freight Order Tracking: ' + this.state.actualJobData.shortId}
                  </Typography>
                 
                </Box>
                <Box mb={3}>
     
                  <Typography
                    color="textSecondary"
                    gutterBottom
                    variant="body2"
                  >
                    {'Request Id: ' + this.state.targetJob}
                  </Typography>
                </Box>
                
 <Box mt={3}>

   <div style={{flexDirection: isMobile ? 'column' : 'row', display: 'flex', justifyContent: 'space-between',}}>


     <div style={{flexDirection: 'column', display: 'flex', marginBottom: 20, paddingBottom: 20}}>
      
     <ContractDetails isFreightOrder={true} startDate={this.momentFormatDate(this.state.actualJobData.startDate)} 
     endDate={this.momentFormatDate(this.state.actualJobData.contractEndDate)} 
     used={this.state.actualJobData.totalWeightUsed * 10} total={this.state.actualJobData.totalWeightValue * 10} remaining={(this.state.actualJobData.totalWeightValue - this.state.actualJobData.totalWeightUsed) * 10}/>
      

   <Box mb={3}>
                  <Typography
                    color="textPrimary"
                    variant="h2"
                  >
                   {this.state.jobsList.length + ' Associated Jobs For This Order'}
                  </Typography>

                </Box>
                {this.state.jobsList.map((item, idx) => {
         return <Grid
         item
         key={item.quoteId}

       >
         <JobCard itemProp={item}/>
         </Grid>
       })}
            

</div>

< div id='mapElementId' ref={(r) => this.mapHolder = r} style={{ height: 500, width: isMobile ? '99%' : '50%', marginLeft: 10, }}>
      <GoogleMapReact
          bootstrapURLKeys={{ key: 'AIzaSyAkPLP3zHG-2kctVakLZwdUQHqeLVO0Onw', libraries: 'places' }}
          defaultCenter={this.state.center}
          defaultZoom={this.state.defaultZoom}
          center={this.state.center}
          zoom={this.state.zoom}
          onChange={(x) => this.setState({zoomUpdate: x.zoom})}
          yesIWantToUseGoogleMapApiInternals={true}
          onClick={(val) => console.log(val)}
          onGoogleApiLoaded={({ map, maps }) => this.handleApiLoaded(map, maps)}
      >
        {this.state.actualJobData.pickupLocation != '' && this.state.actualJobData.dropoffLocation != '' ? <HoverMarker zoomSet={this.state.zoomUpdate} lat={this.state.actualJobData.startPos.lat} lng={this.state.actualJobData.startPos.lng} text={'Location'} idx={0} clickMarker={() => console.log('rmm')} /> : <div/>}
        {this.state.actualJobData.pickupLocation != '' && this.state.actualJobData.dropoffLocation != '' ? <HoverMarker zoomSet={this.state.zoomUpdate} lat={this.state.actualJobData.endPos.lat} lng={this.state.actualJobData.endPos.lng} text={'Location'} idx={1} clickMarker={() => console.log('rmm')} /> : <div/>}
        {this.state.vehicleTrackData != {} && this.state.vehicleTrackData != null && Object.keys(this.state.vehicleTrackData).length > 0 ? 
        <div lat={this.state.vehicleTrackData.lat} lng={this.state.vehicleTrackData.lng}>
          <LocalShippingIcon/>
        </div>: null}
        {(this.state.vehicleTrackData == {} || Object.keys(this.state.vehicleTrackData).length < 1) && this.state.userTrackData != {} && this.state.userTrackData != null && this.state.userTrackData.coords != null ? 
        <div lat={this.state.userTrackData.coords.latitude} lng={this.state.userTrackData.coords.longitude}>
          <LocalShippingIcon/>
          {this.polyRender(this.state.actualJobData, this.state.userTrackData, this.state.vehicleTrackData, this.state.map, this.state.maps)}
        
      </div>
        : null}


        </GoogleMapReact>
      <Typography>{this.state.actualJobData.distance ? this.state.actualJobData.distance + ' km' : ''}</Typography>
      </div>

   </div>
 </Box>
       </div>
        </Page>
    )
  }


}

export default FreightSummaryPane;