import React, {Component} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import MuiPhoneNumber from 'material-ui-phone-number'
import Firebase from 'src/Firebase'


class AddDriverDialog extends Component {


    static defaultProps = {
        headerColor: 'black',
        bodyColor: 'black',
    };

    constructor(props) {
        super(props);

        this.state = {
            value: this.props.defaultValue,
            open: false,
            popRef: null,
            sortAscending: false,
            sortDescending: false,
            notificationType: '',
            notificationHeader: '',
            notificationBody: '',
            phoneNumber: ''
        }

        this.topRef = null
    }

    componentDidMount() {
        //alert(this.props.defaultValue)
    }

    tryConfirm() {
      if (this.state.phoneNumber == '') {
        alert('Please enter a phone number.')
        return
      }

      this.setState({
        dialogWorking: true
      })
      let suc = (x) => {
        this.setState({
          dialogWorking: false
        })
        if (x == 0) {
          alert('This driver is already a part of this fleet.')
        } else if (x == 1) {
          alert('This driver is already a member of another fleet. Please remove it first.')
        } else if (x == 2) {
          alert('This driver has not signed up yet, but they will be added to this fleet when they do.')
        } else if (x == 3) {
          alert('The user with this phone number is not a driver.')
        } else if (x == 4) {
          alert('Driver added to fleet!')
        } else {
          alert('An error occurred while adding driver.')
        }
      }

      let fail = () => {
        this.setState({
          dialogWorking: false
        })
        alert('An error occurred. Check your connection and try again.')
      }

      Firebase.tryAssignDriverToTransporter({'phone': this.state.phoneNumber, 'transporterId': this.props.transporterId}, suc, fail)

    }

   

    render() {
        return (
            <Dialog
            open={this.props.open}
            onClose={() => this.props.onClose()}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{this.props.alertTitle}</DialogTitle>
            <DialogContent>
                
              <DialogContentText id="alert-dialog-description">
                {this.state.dialogWorking ? 'Performing Operation...' : this.props.alertBody}
                {this.state.dialogWorking ? <div style={{alignItems: 'center', justifyContent: 'center'}}><CircularProgress/></div> : <div/>}
              </DialogContentText>
  
              <MuiPhoneNumber defaultCountry={'et'}
                regions={['america','africa']}
                onlyCountries={['us', 'et']}
                autoFormat={false}
                disableAreaCodes={true}
                  fullWidth
                  margin="normal"
                  onChange={(e) => this.setState({
                    phoneNumber: e
                  })}
                  variant="outlined"
                />

            </DialogContent>
            <DialogActions>
              <Button onClick={() => this.props.onClose()} color="primary">
                {this.props.alertCancelText}
              </Button>
              <Button onClick={() => this.tryConfirm()} color="primary" autoFocus>
                {this.props.alertConfirmText}
              </Button>
            </DialogActions>
          </Dialog>
        )

    }
}
const headerStyle = {
    backgroundColor: 'rgb(67,67,67)', flexWrap: 'never', whiteSpace: 'noWrap'
}

/*   <div onClick={() => this.adjustSortDescending()} style={{ margin: 0, display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: this.state.sortDescending ? 'orange' : 'white', border: this.state.sortDescending ? '' : '1px solid black', borderRadius: 30, padding: 0, height: 30, padding: 5 }}>
                                <p>Descending</p>
                            </div>*/

/*
 * 
 * 
 *             */

export default AddDriverDialog;