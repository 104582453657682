import React, { useState, useEffect } from 'react';
import {
  Box,
  Container,
  makeStyles,
  Switch,
  FormControlLabel,
  Grid
} from '@material-ui/core';
import Page from 'src/components/Page';
//import Results from './Results';
import Toolbar from './Toolbar';
//import data from './data';
import Firebase from 'src/Firebase.js'
import MyContext from 'src/MyContext.js'
import JobCardPayment from '../views/reports/DashboardView/JobCardPayment';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));


const PaymentWrapper = (data) => {
  const classes = useStyles();
  const [customers] = useState(data);
  const [toggleSwitch, setToggleSwitch] = useState(true);

  useEffect(() => {
    // Update the document title using the browser API

    //alert(JSON.stringify(data))
  });

  function getTitle(keyProp) {
    if (keyProp == 1) {
      return 'Quote Requests'
    } else if (keyProp == 2) {
      return 'Incoming Quotes'
    } else if (keyProp == 3) {
      return 'Scheduled Shipments'
    } else if (keyProp == 4) {
      return 'In Progress / Delivered'
    } 
    return 'Garri'
  }

  return (
    <MyContext.Consumer>{context => (
    <Page
      className={classes.root}
      title={getTitle(data.keyProp)}
    >
  

      <Container maxWidth={false}>
        <FormControlLabel
                control={<Switch
                checked={toggleSwitch}
                onChange={(e) => setToggleSwitch(!toggleSwitch)}
                color="primary"
                name="checkedB"
                inputProps={{ 'aria-label': 'primary checkbox' }}
              />}
              label={toggleSwitch ? "Showing Unpaid Jobs" : "Showing Paid Jobs"}
        />
  {toggleSwitch ? 
  
  <Grid
    container
    spacing={3}
  >{context.adminJobs.map((item, idx) => {
    if (!item.jobIsPaid && item.countThisJob) {
      return <Grid
      item
      lg={3}
      sm={6}
      xl={3}
      xs={12}
    >
      <JobCardPayment resistProp={true} itemProp={item}/>
      </Grid>
    } else {
      return null 
    }
         }) }</Grid>
  : 
    <Grid
    container
    spacing={3}
  >{context.adminJobs.map((item, idx) => {
    if (item.jobIsPaid && item.countThisJob) {
      return <Grid
      item
      lg={3}
      sm={6}
      xl={3}
      xs={12}
    > 
      <JobCardPayment resistProp={true} itemProp={item}/>
      </Grid>
    } else {
      return null 
    }
         }) }</Grid>
          }

      </Container>
    </Page>
    )}</MyContext.Consumer>
  );
};

export default PaymentWrapper;
