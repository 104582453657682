import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import moment from 'moment';
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Typography,
  makeStyles
} from '@material-ui/core';
import Firebase from 'src/Firebase'

const user = {
  avatar: '/static/images/avatars/avatar_6.png',
  city: 'Los Angeles',
  country: 'USA',
  jobTitle: 'Senior Developer',
  name: 'Katarina Smith',
  timezone: 'GTM-7'
};

const useStyles = makeStyles(() => ({
  root: {},
  avatar: {
    height: 100,
    width: 100
  }
}));

const Profile = ({ className, userInfo, warehouseUserInfo, ...rest }) => {
  const classes = useStyles();


  var refa = React.useRef();

  async function uploadImageByUri(uri, mime = 'image/jpg') {
    const imageRef = Firebase.storage.ref(Firebase.uid).child('profpic')
    let aa = await imageRef.put(uri, { contentType: mime }).then(() => {
        return imageRef.getDownloadURL()
    }).then((url) => {
        return url
    })
        .catch((error) => {
            this.setState({
                isAuthenticating: false,
            });
            alert('Error uploading photos. Check internet connection')
            console.log("error" + error);
        })
    console.log('w' + aa)
    return aa
}

async function handleSelectedFolder(event) {

   
    console.log(event.target.files)
    let resv = await uploadImageByUri(event.target.files[0])
    console.log(resv)
    var userId = userInfo.userId 
    var dat = {}
    if (!userId) {
      dat['userId'] = Firebase.uid
    } else {
      dat['userId'] = userId 
    }
    dat['updateBody'] = {}
    dat['updateBody']['profilePic'] = resv
    let docRefNew = Firebase.firestore.collection('projects').doc(Firebase.userProject).collection('barleyInCustomObjects/contents/warehousesoftwareusers').doc(Firebase.uid)
    await docRefNew.update({'profilePic': resv})
    //this.uploadFile(targetFile)

}

function renderUserAvatar(v) {
  if ('profilePic' in v) {
    return v.profilePic
  } else {
    return '/static/images/avatars/plc.png'
  }

}

function renderUserName(v) {
  var rets = ''
  if ('firstName' in v) {
    rets += v.firstName
  } 
  if ('middleName' in v) {
    rets += ' ' + v.middleName
  } 
  if ('lastName' in v) {
    rets += ' ' + v.lastName
  }
  return rets
}


  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <CardContent>
        <Box
          alignItems="center"
          display="flex"
          flexDirection="column"
        >
          <Avatar
            className={classes.avatar}
            src={renderUserAvatar(warehouseUserInfo)}
          />
          <Typography
            color="textPrimary"
            gutterBottom
            variant="h3"
          >
            {renderUserName(userInfo)}
          </Typography>

        </Box>
      </CardContent>
      <Divider />
      <CardActions>
      <input id="myInputFolder" accept="image/png, image/jpeg, image/jpg" ref={(ref) => refa = ref} style={{ display: 'none' }} type="file" onChange={handleSelectedFolder} />
        <Button
          color="primary"
          fullWidth
          variant="text"
          onClick={() => refa.click()}
        >
          Upload picture
        </Button>
      </CardActions>
    </Card>
  );
};

Profile.propTypes = {
  className: PropTypes.string
};

export default Profile;
