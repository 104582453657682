import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Bar } from 'react-chartjs-2';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  useTheme,
  makeStyles,
  colors,
  TextField,
  MenuItem
} from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import MyContext from 'src/MyContext'

var moment = require('moment');
const day =  24 * 3600 * 1000
const week = Date.now() - (7 * day)
const month = Date.now() - (30 * day)
const year = Date.now() - (365 * day)

const time_options = [{'name': 'Past 7 Days', 'value': week}, {'name': 'Past Month', 'value': month}, {'name': 'Past Year', 'value': year}]
const metric_options = [{'name': 'Profit', 'value': 'profit'}, {'name': 'Revenue', 'value': 'revenue'}, {'name': 'Jobs Completed', 'value': 'num_jobs'}]
const metrics_dict = {'profit': 'Profit', 'revenue': 'Revenue', 'num_jobs': 'Jobs Completed'}

const useStyles = makeStyles(() => ({
  root: {}
}));

const AdminMetricsHolder = ({ className, ...rest }) => {
  const classes = useStyles();  
  const theme = useTheme();
  const [timeValue, setTimeValue] = React.useState(week)
  const [timeName, setTimeName] = React.useState('Past 7 Days')
  const [targetKey, setTargetKey] = React.useState('profit')
  const [targetName, setTargetName] = React.useState('Profit')
  const [totalValue, setTotalValue] = React.useState('Calculating...')
  const [targetUser, setTargetUser] = React.useState('All Users')
  const [targetUserName, setTargetUserName] = React.useState('All Users')


  function getShipperOptions(users) {
    let usersx =  users.filter((item, idx) => {
      return item.userType == 2
    }).map((item, idx) => {
      return {'name': item.companyName, 'value': item.userId}
    })

    return [{'name': 'All Users', 'value': 'All Users'}].concat(usersx)
  }

  function compare2(a, b) {

      
      if (a.val > b.val) {
        return 1
      } else if (b.val > a.val) {
        return -1
      }
      return 0;
  }
    
  function sortItems(v, s) {
      //console.log('try sorting')
      //console.log(v)
      //console.log(s)
      var o = v
      o.sort((a, b) => compare2(a, b))
      return o
  }

  function getIncrementForThisKey(key, item) {


    if (key == 'profit') {
      if ('price' in item && 'transporterPrice' in item) {
        return Math.round(item.price - item.transporterPrice)
      } else {
        return 0
      }
    } else if (key == 'revenue') {
      if ('price' in item) {
        console.log('FOR ITEM ' + item.shortIdShipper + ' value is ' + item.price)
        return Math.round(item.price)
      } else {
        return 0
      }
    } else if (key == 'num_jobs') {
      return 1
    }
  }

  function produceData(dateRange, lst, targetKey, targetName) {
    //alert('range is' + dateRange + ' and now is ' + Date.now())
    var metricDict = {}
    var pickupDict = {}
    var labelsList = []
    var labelsDict = {}

    var total = 0

    lst.map((item, idx) => {

      if (!(item.countThisJob) || item.state < 9) {
        return
      }

      if (targetUser != 'All Users' && item.user != targetUser) {
        return
      }

      if ('earlyDrop' in item && 'earlyPick' in item) {
        let vDrop = moment(item.earlyDrop).valueOf()
        let vPick = moment(item.earlyPick).valueOf()
        
        // This uses job delivery as the date
        if (vDrop > dateRange) {
          let monthFormat = moment(item.earlyDrop).format('DD/MM')
          if (monthFormat in metricDict) {
            let increment = getIncrementForThisKey(targetKey, item)
            metricDict[monthFormat] += increment 
            total += increment 
          } else {
            let increment = getIncrementForThisKey(targetKey, item)
            metricDict[monthFormat] = increment
            total += increment 
          }
          if (monthFormat in labelsDict) {

          } else {
            labelsDict[monthFormat] = 1
            labelsList.push({'text': monthFormat, 'val': vDrop})
          }
        }

        // This would be if we want to treat the date of a job as the pickup, but we will use delivery as the date
        /*if (vPick > dateRange) {
          let monthFormat = moment(item.earlyPick).format('DD/MM')
          if (monthFormat in pickupDict) {
            pickupDict[monthFormat] += 1
          } else {
            pickupDict[monthFormat] = 1
          }
          if (monthFormat in labelsDict) {

          } else {
            labelsDict[monthFormat] = 1
            labelsList.push({'text': monthFormat, 'val': vPick})
          }
        }*/
      }
    })

    let sorted = sortItems(labelsList)

    var final_dropoff = []
    var final_pickup = []
    var final_labels = []

    sorted.map((item, idx) => {
      final_labels.push(item.text)
      if (item.text in metricDict) {
        final_dropoff.push(metricDict[item.text])
      } else {
        final_dropoff.push(0)
      }
      // Again this is if we want to track multiple things or track by pickup date or something like that
      /*if (item.text in pickupDict) {
        final_pickup.push(pickupDict[item.text])
      } else {
        final_pickup.push(0)
      }*/

    })

    setTotalValue(total)

    return {
      datasets: [
        {
          backgroundColor: colors.indigo[500],
          data: final_dropoff,
          label: targetName
        },
      ],
      labels: final_labels
    };

  }

  const data = {
    datasets: [
      {
        backgroundColor: colors.indigo[500],
        data: [18, 5, 19, 27, 29, 19, 20],
        label: 'This year'
      },
      {
        backgroundColor: colors.grey[200],
        data: [11, 20, 12, 29, 30, 25, 13],
        label: 'Last year'
      }
    ],
    labels: ['1 Aug', '2 Aug', '3 Aug', '4 Aug', '5 Aug', '6 Aug']
  };

  const options = {
    animation: false,
    cornerRadius: 20,
    layout: { padding: 0 },
    legend: { display: false },
    maintainAspectRatio: false,
    responsive: true,
    scales: {
      xAxes: [
        {
          barThickness: 12,
          maxBarThickness: 10,
          barPercentage: 0.5,
          categoryPercentage: 0.5,
          ticks: {
            fontColor: theme.palette.text.secondary
          },
          gridLines: {
            display: false,
            drawBorder: false
          }
        }
      ],
      yAxes: [
        {
          ticks: {
            fontColor: theme.palette.text.secondary,
            beginAtZero: true,
            min: 0
          },
          gridLines: {
            borderDash: [2],
            borderDashOffset: [2],
            color: theme.palette.divider,
            drawBorder: false,
            zeroLineBorderDash: [2],
            zeroLineBorderDashOffset: [2],
            zeroLineColor: theme.palette.divider
          }
        }
      ]
    },
    tooltips: {
      backgroundColor: theme.palette.background.default,
      bodyFontColor: theme.palette.text.secondary,
      borderColor: theme.palette.divider,
      borderWidth: 1,
      enabled: true,
      footerFontColor: theme.palette.text.secondary,
      intersect: false,
      mode: 'index',
      titleFontColor: theme.palette.text.primary
    }
  };

  return (
    <MyContext.Consumer>{context => (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <CardHeader
        action={(
          <div>

<TextField select 
          style={{width: 120, marginLeft: 10}}
          value={targetUser}
          onChange={(x) => {
            setTargetUser(x.target.value)
            setTargetUserName(x.target.name)
          }}
        >
          {getShipperOptions(context.users).map((item, idx) => {
            return (<MenuItem value={item.value}>{item.name}</MenuItem>)
          })}
        </TextField>

            <TextField select 
          style={{width: 120}}
          value={timeValue}
          onChange={(x) => {
            if (x.target.value == week) {
              setTimeName('Past 7 Days')
            } else if (x.target.value == month) {
              setTimeName('Past Month')
            } else if (x.target.value == year) {
              setTimeName('Past Year')
            }
            setTimeValue(x.target.value)
          }}
        >
          {time_options.map((item, idx) => {
            return (<MenuItem value={item.value}>{item.name}</MenuItem>)
          })}
        </TextField>
        <TextField select 
          style={{width: 120, marginLeft: 10}}
          value={targetKey}
          onChange={(x) => {
            setTargetKey(x.target.value)
            setTargetName(x.target.name)
            console.log('yesxset')
            console.log(x)
            console.log(x.target.value)
            console.log(x.target.name)
          }}
        >
          {metric_options.map((item, idx) => {
            return (<MenuItem value={item.value}>{item.name}</MenuItem>)
          })}
        </TextField>
        
        </div>

        )}
        title={metrics_dict[targetKey] + ' Over ' + timeName + ': ' + totalValue}
      />
      <Divider />
      <CardContent>
        <Box
          height={400}
          position="relative"
        >
          <Bar
            data={produceData(timeValue, context.adminJobs, targetKey, metrics_dict[targetKey])}
            options={options}
          />
        </Box>
      </CardContent>
      <Divider />

    </Card>
    )}</MyContext.Consumer>
  );
};

AdminMetricsHolder.propTypes = {
  className: PropTypes.string
};

export default AdminMetricsHolder;
