import React, {useEffect, useState} from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {useNavigate } from 'react-router-dom';
import {
  Avatar,
  Box,
  Card,
  CardContent,
  Grid,
  Typography,
  colors,
  makeStyles,
  Stepper,
  Step,
  StepLabel, 
  StepContent 
} from '@material-ui/core';
import QuoteStatus from 'src/QuoteStatus'
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import MoneyIcon from '@material-ui/icons/Money';
import moment from 'moment';
import Firebase from 'src/Firebase'

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%'
  },
  avatar: {
    backgroundColor: colors.red[600],
    height: 56,
    width: 56
  },
  differenceIcon: {
    color: colors.red[900]
  },
  differenceValue: {
    color: colors.red[900],
    marginRight: theme.spacing(1)
  }
}));

function renderStatus(s) {
  if (s == 0) {
    return 'Quote Requested'
  } else if (s==1) {
    return 'Quote Offered'
  } else if (s==2) {
    return 'Quote Accepted'
  } else if (s==3) {
    return 'Offered to Transporters'
  } else if (s==4) {
    return 'Transporter Placed Bid'
  } else if (s==5) {
    return 'Transporter Accepted Job'
  } else if (s==6) {
    return 'Job Assigned To Driver'
  } else if (s==7) {  
    return 'Driver en Route'
  } else if (s==8) {
    return 'Driver Loaded Truck'
  } else if (s==9) {
    return 'Driver Delivered Load'
  } else {
    return 'N/A'
  }
}

function getColor(s) {

  var color = 'rgb(222,222,222)'

  if (s == 1) {
    
    color = 'rgb(63,81,181)'
  } else if (s == 2){

    color = '#F32013'
  } else if (s == 3) {

    color = 'rgb(255,12,39)'
  } else if (s==4) {

    color = '#F32013'
  } else if (s==5) {

    color = 'rgb(237,28,36)'
  } else if (s == 6) {

    color = 'rgb(128,255,255)'
  } else if (s == 7) {

    color = 'rgb(0,128,0)'
  } else if (s == 8) {

    color = 'rgb(128,64,0)'
  } else if (s==9) {

    color = 'rgb(67,160,71)'
  }
  return color 
}

function renderNameNoCountry(name, t1, t2) {
  if (t1 != null && t2 != null) {
    if (t1 == t2) {
      if (name.endsWith(t1) && t1 != name && name.length > 2 + t1.length) {
        return name.substring(0, name.length - (2 + t1.length)) 
      }
    }
  }
  return name
}

function calculateRemainingPayment(itemProp) {
  if (!itemProp.trueWeight || itemProp.trueWeight <= 0) {
    return 'Cannot calculate payment without weight.'
  }
  if (!itemProp.transporterPricePerQuintal) {
    return 'Cannot calculate payment without transporter price.'
  }

  var weightVal = itemProp.trueWeight
  if (itemProp.finalWeight && itemProp.finalWeight > 0 && itemProp.finalWeight != '') {
    weightVal = itemProp.finalWeight
  }

  // Total quintals of the load
  var weightQuintals = weightVal * 10 

  var totalPay = weightQuintals * itemProp.transporterPricePerQuintal

  if (itemProp.advancePayment && itemProp.advancePayment > 0 && itemProp.advancePayment != '') {
    var totalPay = totalPay - itemProp.advancePayment
  }

  return 'Remaining Payment: ETB ' + totalPay


}

function padDigits(number) {
  return Array(Math.max(3 - String(number).length + 1, 0)).join(0) + number;
}

function getIdDisplay(val) {
  if ('internalShipperId' in val && 'shortIdShipper' in val) {
    return val.internalShipperId + '-' + padDigits(val.shortIdShipper)
  } else {
    return val.shortId
  }
}

function gonavigate(customer, navigatefunc) {
  let startLat = customer.startPos.lat 
        let endLat = customer.endPos.lat 
        let startLng = customer.startPos.lng 
        let endLng = customer.endPos.lng 

        let cx = startLat + ((endLat - startLat) / 2)
        let cy = startLng + ((endLng - startLng) / 2)

        navigatefunc('/app/editjobadmin', {state: {customer: customer, centerx: cx, centery: cy}})

}

const JobCardPayment = ({ className, etest, itemProp, resistProp, ...rest }) => {
  const classes = useStyles();
  const [txt, setTxt] = useState({'ww': 45, 'aa': 'jfia3i'})
  const [num, setNum] = useState(53)
  const navigate = useNavigate()

  useEffect(() => {

  });

  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}

    >
      <CardContent style={{ display: 'flex', flexDirection: 'column'}}>
        <Grid
          container
          flexDirection="column"
          spacing={3}
        >
          <Grid item>
            <Typography
              color="textSecondary"
              gutterBottom
              variant="h3"
            >
              {getIdDisplay(itemProp)}
            </Typography>
            <div style={{display: 'inline-block', backgroundColor: getColor(itemProp.state), alignItems: 'center', justifyContent: 'center',
       borderRadius: 10,  height: 40, padding: 10}}><a style={{color: 'white', fontSize: 14}}>{renderStatus(itemProp.state)}</a></div>

          </Grid>
          <Typography
          color="textPrimary"
          variant="body1"
        >
          {itemProp.advancePayment ? 'Advance Payment: ' + itemProp.advancePayment : 'No Payment Yet'}
        </Typography>
        <Typography
          color="textPrimary"
          variant="body1"
        >
          {'Weight Estimate: ' + itemProp.trueWeight * 10 + ' Quintals'}
        </Typography>
        <Typography
          color="textPrimary"
          variant="body1"
        >
          {itemProp.finalWeight ? 'Final Weight: ' + itemProp.finalWeight * 10 + ' Quintals' : 'Final Weight Unchanged'}
        </Typography>
          <Typography
          color="textPrimary"
          variant="body1"
        >
          {!itemProp.jobIsPaid ? calculateRemainingPayment(itemProp) : ''}
        </Typography>

        </Grid>
        <div style={{alignSelf: 'center'}}onClick={() => gonavigate(itemProp, (xx,yy) => navigate(xx,yy))} style={{display: 'inline-block', backgroundColor: 'green', alignItems: 'center', justifyContent: 'center', display: 'flex',
       borderRadius: 10,  height: 35, width: 'calc(100% - 10px)', padding: 10,marginTop: 20}}><a style={{color: 'white', fontSize: 14}}>{'Manage Job'}</a></div>

        <div style={{alignSelf: 'center'}}onClick={() => window.open(Firebase.ip + 'info/' + itemProp.shortId)} style={{display: 'inline-block', backgroundColor: 'rgb(252,197,37)', alignItems: 'center', justifyContent: 'center', display: 'flex',
       borderRadius: 10,  height: 35, width: 'calc(100% - 10px)', padding: 10,marginTop: 20}}><a style={{color: 'white', fontSize: 14}}>{itemProp.state < 9 ? 'Live Track' : 'View Summary'}</a></div>

       
      </CardContent>
    </Card>
  );
};

JobCardPayment.propTypes = {
  className: PropTypes.string
};

export default JobCardPayment;
