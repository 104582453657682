import React, { useState, useRef, useEffect }  from 'react';
import {useNavigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Button,
  Card,
  CardContent,
  TextField,
  InputAdornment,
  SvgIcon,
  Link,
  Typography
} from '@material-ui/core';
import { Search as SearchIcon } from 'react-feather';
import MyContext from 'src/MyContext'
import moment from 'moment'

import Firebase from 'src/Firebase'
import AddBarleyFieldDialog from 'src/utils/AddBarleyFieldDialog';

import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import VoidNoteDialog from 'src/views/barley/BarleyListView/VoidNoteDialog';
import { makeStyles, darken, lighten } from '@material-ui/core/styles';
import { DataGrid, GridToolbar, GridToolbarContainer, GridToolbarColumnsButton, GridToolbarFilterButton, GridToolbarDensitySelector, GridToolbarExport } from '@mui/x-data-grid';


function CustomToolbar(props) {

  if (props.allowExport) {
    return       <GridToolbarContainer >
    <GridToolbarColumnsButton />
    <GridToolbarFilterButton />
    <GridToolbarDensitySelector/>
    <GridToolbarExport/>
  </GridToolbarContainer>
  } else {
    return (
      <GridToolbarContainer >
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector/>
      </GridToolbarContainer>
    );
  }

}

const useStyles = makeStyles((theme) => ({
  root: {},
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  }
}));

const filter = createFilterOptions();

const DispatchHistoryTable = ({ className, ...rest }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const {state} = useLocation();
  const inputRef = React.useRef(null);
  const [rows, setRows] = useState([]);
  const [selection, setSelection] = useState([]);
  const [loading, setLoading] = useState(true)
  const [sortModel, setSortModel] = React.useState([
    {
      field: 'creationDate',
      sort: 'desc',
    },
  ]);
  function numericValueNoComma(params) {
    if (params.value) {
      return params.value + ''
    } else {
      return params.value
    }
    //return params.value + ''
  }


  function categoryGetter(params) {
    if (params.row['category'] && params.row['category']['name']) {
      return JSON.stringify(params.row['category']['name']).replace('\"', '').replace('\"', '')
    } else {
      return ''
    }
  }

  function compare2(a,b) {

    let as = a.sortNum ? a.sortNum : -1
    let bs = b.sortNum ? b.sortNum : -1

    if (as > bs) {
      return -1
    } else if (bs > as) {
      return 1
    } else {
      return 0
    }
  }


  function renderColumns(fields) {
    return fields.sort((a,b) => compare2(a,b)).map((item, idx) => {


      if (item.fieldKey == 'operation') {
        return ({field: item.fieldKey, headerName: item.fieldName, width: item.width ? item.width : 150, editable: false,
  
  valueGetter: (params) => {

    if (params.row['operation']) {
      return params.row['operation'].operationnumber
    } else {
      return ''
    }

  } })
}

      if (item.fieldType == 'custom') {
        return {field: item.fieldKey, headerName: item.fieldName, width: item.width ? item.width : 200, editable: false,
           valueGetter: ({ value }) => value && value.name ? item.fieldKey != 'warehouse' ? value.name : value.name + '-' + value.union: '',
          renderEditCell: (params) => {              
            return  <Autocomplete
            value={params.value}
            onChange={(event, newValue) => {
              console.log(newValue)
              console.log(params)
              console.log(typeof newValue)
              //console.log(newValue.inputValue)
              if (typeof newValue === 'string') {

                //setFieldValue(fields[fieldIdx].fieldKey, {'customId': newValue.customId, 'name': newValue.name})
                //doApiUpdate(params.api, params.id, item.fieldKey, {'customId': newValue.customId, 'name': newValue.name}, event)

              } else if (newValue && newValue.inputValue) {
                // Create a new value from the user input

                //setFieldValue(fields[fieldIdx].fieldKey, {'customId':-1, 'name': newValue.inputValue})
                //doApiUpdate(params.api, params.id, item.fieldKey, {'customId': -1, 'name': newValue.inputValue}, event)
              } else {

                if (newValue == null) {
                  //setFieldValue(fields[fieldIdx].fieldKey, {'customId': '0', 'name': 'None'})
                  //doApiUpdate(params.api, params.id, item.fieldKey, {'customId': '0', 'name': 'None'}, event)
                } else {
                  //doApiUpdate(params.api, params.id, item.fieldKey, {'customId': newValue.customId, 'name': newValue.name}, event)
                  //setFieldValue(fields[fieldIdx].fieldKey, {'customId': newValue.customId, 'name': newValue.inputValue})
                }
              }
              //console.log(event.target.value)
              //console.log(newValue)
            }}
            filterOptions={(options, params) => {
              const filtered = filter(options, params);
      
              // Suggest the creation of a new value
              if (params.inputValue !== '') {
                filtered.push({
                  inputValue: params.inputValue,
                  name: `Add "${params.inputValue}"`,
                });
              }
      
              return filtered;
            }}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            id="free-solo-with-text-demo"
            options={[]}
            getOptionLabel={(option) => {
              // Value selected with enter, right from the input
              if (typeof option === 'string') {
                return option;
              }
              // Add "xxx" option created dynamically
              if (option.inputValue) {
                return option.inputValue;
              }
              // Regular option
              return option.name;
            }}
            renderOption={(option) => option.name}
            freeSolo
            renderInput={(params) => <TextField {...params} style={{ width: 150, height: 150}}  autoFocus  />}
          />}
          
                      
        }
      } else if (item.fieldType == 'list') {
        return {field: item.fieldKey, headerName: item.fieldName, width: item.width ? item.width : 150, editable: false,
          type: 'singleSelect', valueOptions: item.listOptions                      
        }
      }

      if (item.fieldKey == 'warehouseinlink') {
        return ({field: item.fieldKey, headerName: item.fieldName, width: item.width ? item.width : 150, editable: false,
        type: item.fieldType != 'text' ? item.fieldType : null,
        renderCell: (params) => {
          if (params.value && params.value.length > 0) {
            return   <Link
            to={params.value}
            style={{cursor: 'pointer'}}
            onClick={ () => window.open(params.value)}
            variant="h6"
          >{'View'}</Link>
          } else {
            return <div/>
          }
        }
      })
      }


      if (item.fieldKey == 'warehouseoutlink' ) {
        /*return ({field: item.fieldKey, headerName: item.fieldName, width: item.width ? item.width : 150, editable: false,
        type: item.fieldType != 'text' ? item.fieldType : null,
        renderCell: (params) => {
          if (params.row['fromWarehouse'] || params.row['trainAndWarehouse']) {
          //if (params.row['warehouseinlink'] && params.row['warehouseinlink'].length) {
            return   <Link
            to={params.value}
            style={{cursor: 'pointer', textDecorationLine: params.row['voided'] ? 'line-through' : 'none'}}
            onClick={async () => {
              let b = await Firebase.getSignedUrl({'bucket': 'test', 'file': 'pdf_for_whout_' + params.row['dispatchNumber'] + '.pdf'}) 
              if (b && b.length && b.length > 0) {
                window.open(b[0])
              } else {
                alert('Error retrieving file.')
              }

            }}
            variant="h6"
          >{[params.row['dispatchNumber']]}</Link>
          } else {
            return params.value 
          }
          } else {
            return ''
          }


        }, 
      })*/

      return ({field: item.fieldKey, headerName: 'Editor', width: item.width ? item.width : 150, editable: false,
        type: item.fieldType != 'text' ? item.fieldType : null, renderCell: (params) => {
          return params.row['editCommittedBy']
        }})

      }

      if (item.fieldKey == 'dispatchNumber' ) {
        /*return ({field: item.fieldKey, headerName: item.fieldName, width: item.width ? item.width : 150, editable: false,
        type: item.fieldType != 'text' ? item.fieldType : null,
        renderCell: (params) => {

          //if (params.row['warehouseinlink'] && params.row['warehouseinlink'].length) {
            return  <Link
            to={params.value}
            style={{cursor: 'pointer', textDecorationLine: params.row['voided'] ? 'line-through' : 'none'}}
            onClick={async () => {
              let b = await Firebase.getSignedUrl({'bucket': 'test', 'file': 'pdf_for_wab_' + params.row['dispatchNumber'] + '.pdf'}) 
              if (b && b.length && b.length > 0) {
                window.open(b[0])
              } else {
                alert('Error retrieving file.')
              }

            }}
            variant="h6"
          >{[params.value]}</Link>
         
          } else {
            return params.value 
          }
        }, 
      })*/

      return ({field: item.fieldKey, headerName: 'Edit Time', width: item.width ? item.width : 150, editable: false,
        type: item.fieldType != 'text' ? item.fieldType : null, renderCell: (params) => {
          return params.row['editTime'] ? moment(parseInt(params.row['editTime'])).format('DD/MM/YYYY HH:mm') : ''
        }})



      }

      if (item.fieldKey == 'category') {
        return ({field: item.fieldKey, headerName: item.fieldName, width: item.width ? item.width : 150, editable: false,
        type: item.fieldType != 'text' ? item.fieldType : null, valueGetter: categoryGetter
      })
      }

      if (item.fieldType == 'date' && item.fieldKey == 'creationDate') {
        return ({field: item.fieldKey, headerName: item.fieldName, width: item.width ? item.width : 150, editable: false,
          type: item.fieldType != 'text' ? item.fieldType : null, valueFormatter: (params) => {
            return params.value ? moment(params.value).format('DD/MM/YYYY') : ''
          }, renderCell: (params) => {
            return params.value ? moment(params.value).format('DD/MM/YYYY HH:mm') : ''
          }})
      } else if (item.fieldType == 'date' && item.fieldKey == 'creationDateHour') {

        return ({field: item.fieldKey, headerName: item.fieldName, width: item.width ? item.width : 150, editable: false,
          type: item.fieldType != 'text' ? item.fieldType : null, hide: true, valueGetter: (params) => {
            return params && params.row && params.row['creationDate'] ? moment(params.row['creationDate']).format('HH:mm') : ''
          }})
      } else if (item.fieldType == 'date') {
        return ({field: item.fieldKey, headerName: item.fieldName, width: item.width ? item.width : 150, editable: false,
          type: item.fieldType != 'text' ? item.fieldType : null, valueFormatter: (params) => {
            return params.value ? moment(params.value).format('DD/MM/YYYY HH:mm') : ''
          }})
      }

      if (item.fieldType == 'number') {
        return ({field: item.fieldKey, headerName: item.fieldName, width: item.width ? item.width : 150, editable: false,
          type: item.fieldType != 'text' ? item.fieldType : null,
        valueGetter: numericValueNoComma })
      }




      if (item.fieldKey == 'displaybol') {
                return ({field: item.fieldKey, headerName: item.fieldName, width: item.width ? item.width : 150, editable: false,
          type: item.fieldType != 'text' ? item.fieldType : null,
          valueGetter: (params) => {
  
            if (params.row['operation']) {
              return params.row['operation'].bolnumber
            } else {
              return ''
            }
  
          } })
      }
      if (item.fieldKey == 'displaydeclaration') {
        return ({field: item.fieldKey, headerName: item.fieldName, width: item.width ? item.width : 150, editable: false,
        type: item.fieldType != 'text' ? item.fieldType : null,
        valueGetter: (params) => {

          if (params.row['operation']) {
            return params.row['operation'].declarationnumber
          } else {
            return ''
          }

        } })
      }

      return ({field: item.fieldKey, headerName: item.fieldName, width: item.width ? item.width : 150, editable: false,
      type: item.fieldType != 'text' ? item.fieldType : null})
    })
  }

  async function loadData() {
    let tempRes = await Firebase.firestore.collection('projects').doc(Firebase.userProject).collection("arrivalsArchive").doc(state.id).get()
    var item_list = [state.current_data]
    if (tempRes.exists) {
      let dx = tempRes.data()
      
      Object.keys(dx).map((key, idx) => {
        var item = dx[key]
        item['editTime'] = key 
        item['id'] = key
        item_list.push(item)
        //item_list.push(dx[key])
      })
      
      console.log('hhhh83')
      console.log(dx)
      console.log(item_list)
    }

    setRows(item_list)
    setLoading(false)
  }

  useEffect(() => {
    loadData()

  }, []);

  return (
    <MyContext.Consumer>{context => (<div
      className={clsx(classes.root, className)}
      {...rest}
    >
                     <Typography
                  color="textPrimary"
                  variant="h2"
                >
                 {'Edit History ' + state.current_data.dispatchNumber}
                </Typography> 

          <div style={{height: 500, width: '99%'}}>

        <DataGrid

        className={classes.root}
        rows={rows}
        columns={renderColumns(state.dispatchFields)}
        density={'compact'}
        pageSize={20}
        rowsPerPageOptions={[20]}

        hideFooterPagination={false}
        hideFooter={false}
        checkboxSelection={true}
        disableSelectionOnClick
        sortModel={sortModel}
        getRowClassName={(params) => `super-app-theme--${params.row.voided ? 'Void' : 'NotVoid'}`}
        onSelectionModelChange={(newSelection) => {
        setSelection(newSelection);
        }}
        components={{
        Toolbar: CustomToolbar,
        }}
        componentsProps={{ toolbar: { allowExport: context.warehouseRole == 'Admin' || context.warehouseRole == 'Facility' } }}
        />
        </div>
     
    </div>)}</MyContext.Consumer>
  );
};

DispatchHistoryTable.propTypes = {
  className: PropTypes.string
};

export default DispatchHistoryTable;
