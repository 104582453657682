import React, { useState, useEffect, Component } from 'react';
import {
  Box,
  Container,
  makeStyles
} from '@material-ui/core';
import Page from 'src/components/Page';
import Results from './Results';
import Toolbar from './Toolbar';
import data from './data';
import Firebase from 'src/Firebase.js'
import UserVehiclesTable from './UserVehiclesTable';
import MyContext from 'src/MyContext.js'


class UserVehiclesWrapper extends Component {


  constructor() {
    super()
    this.state = {

      offersDict: {}

    }

    this.offersListener = null

  }

  componentDidMount() {

  }

  componentWillUnmount() {
 
  }


  getJobs(jobs, offersDict) {
    let j = jobs.filter((item, idx) => {
        return item.assignedTransporter == this.props.targetUid || item.quoteId in offersDict 
    }) 
    console.log(j)
    console.log('yea')
    console.log(this.props.targetUid)
    //console.log(jobs)
    console.log(offersDict)
    return j
  }

  getVehicles(users) {
    let j = users.filter((item, idx) => {
      return item.assignedTransporter == this.props.targetUid || item.assignedTransporterId == this.props.targetUid
    })
    return j
  }


  render() {

    return (
      <MyContext.Consumer>{context => (
      <UserVehiclesTable targetTransporter={this.props.targetUid} customers={this.getVehicles(context.vehicles)}/>
      )}</MyContext.Consumer>
    )
  }


}

export default UserVehiclesWrapper