import Firebase from './Firebase'



export default class StateManager {

    static existingDict = {}
    static tableItems = []
    static listeners = {}
    static functionalStateListAdd(x) {
        console.log('notset')
    }
    static functionalStateListUpdate(x) {
        console.log('notset')
    }
    static functionalStateUpdateSet(x, y) {
        console.log('notset')
    }

    static async loadSingleItemByKey(secondary_target, secondary_key) {
        let self = this
        StateManager.listeners['shipperRequests/' + secondary_key] = Firebase.database.ref('shipperRequests/' + secondary_key)
        //console.log('here is   shipperRequests/' + secondary_key)
        StateManager.listeners['shipperRequests/' + secondary_key].on("value", (inner_snapshot) => {
            if (inner_snapshot.exists()) {
    
              if (inner_snapshot.val().quoteId in StateManager.existingDict) {
                StateManager.functionalStateListUpdate(inner_snapshot.val())
              } else {
                StateManager.functionalStateListAdd(inner_snapshot.val())
              }
                
            } else {
                //console.log('container found to not exist for ' + secondary_key)
            }
        }, function (error) {
            console.log('error fetching container for ' + secondary_key)
            console.log(error)
        })
    }
    
      static loadListDualRef(firebase_primary_target, firebase_secondary_target) {
        // Maintain reference to main component for state updates
        let self = this
    
        let addlist = Firebase.database.ref(firebase_primary_target).on("child_added", (inner_snapshot, prevkey) => {
            // Get key of secondary item
    
            if (inner_snapshot.exists()) {
                let secondary_key = inner_snapshot.key
                //console.log(secondary_key)
                StateManager.loadSingleItemByKey(firebase_secondary_target, secondary_key)
            } else {
    
            }
            //StateManager.loadSingleItemByKey(firebase_secondary_target, secondary_key)
    
    
        }, function (errorObject) {
            if (StateManager.report_errors) {
                alert(errorObject)
            }
            console.log("The read failed: " + errorObject.code);
        });
    
        Firebase.database.ref(firebase_primary_target).on("child_removed", (snapshot) => {
            let secondary_key = snapshot.key
    
            Firebase.database.ref(firebase_secondary_target + '/' + secondary_key).off("value")
    
            var nextList = StateManager.tableItems.filter((item, idx) => {
                return (item.quoteId != snapshot.val().quoteId)
            })
           
            var existingDict = StateManager.existingDict
            delete existingDict[secondary_key]
                StateManager.functionalStateUpdateSet(existingDict, nextList)
    
        }, function (errorObject) {
            if (StateManager.report_errors) {
                alert(errorObject)
            }
            console.log("The read failed: " + errorObject.code);
        });
      }


}