import React, {useState} from 'react';
import {
  Container,
  Grid,
  makeStyles,
  CircularProgress,
  Typography,
  TextField,
  MenuItem,
  Button
} from '@material-ui/core';
import Page from 'src/components/Page';
import Budget from './Budget';
import LatestOrders from './LatestOrders';
import LatestProducts from './LatestProducts';
import Sales from './Sales';
import TasksProgress from './TasksProgress';
import TotalCustomers from './TotalCustomers';
import TotalProfit from './TotalProfit';
import TrafficByDevice from './TrafficByDevice';
import DashboardCard from './DashboardCard';
import MyContext from 'src/MyContext';
import JobCard from './JobCard';
import Toolbar from '../../../shipper/Toolbar';
import DashboardCardHolder from './DashboardCardHolder';
import JobCardHolder from './JobCardHolder';
import { Doughnut } from 'react-chartjs-2';
import DoughnutBarley from './DoughnutBarley';
import QuickInfo from './QuickInfo';
import AdminMetricsHolder from './AdminMetricsHolder';
import TableContext from 'src/TableContext'
import BarleyStocks from './BarleyStocks';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import AddStockDialog from './AddStockDialog';
import moment from 'moment';
import Firebase from 'src/Firebase';
import { setYear } from 'date-fns';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));


const AnalyticsScreen = (data) => {



    const classes = useStyles();
  const [totalBarley, setTotalBarley] = React.useState(0);
  const [source, setSource] = React.useState({'customId': '-1', 'id': '-1', 'name': 'All Facilities'})
  const [addStockDialog, setAddStockDialog] = React.useState(false)

  const [startDate, setStartDate] = useState(Date.now() - (1000 * 3600 * 24))
  const [endDate, setEndDate] = useState(Date.now())
  const [day, setDay] = useState(Date.now())
  const [year, setYear] = useState((new Date()).getFullYear())

  async function doGetReport() {
    //alert(moment(endDate).format('DD/MM/YYYY'))
        let res = await Firebase.requestDispatchReport({endTime: moment(endDate).format('DD/MM/YYYY'), startTime: moment(startDate).format('DD/MM/YYYY'), year: null, day: null})
        let b = await Firebase.getSignedUrl({'bucket': res.bucket, 'file': res.path}) 
        if (b && b.length && b.length > 0) {
            window.open(b[0])
          } else {
            alert('Error retrieving file.')
          }
    }
    async function doGetDailyReport() {
      let res = await Firebase.requestDispatchReport({endTime: moment(day + (1000 * 3600 * 24)).format('DD/MM/YYYY'), startTime: moment(day).format('DD/MM/YYYY'), year: null, day: true})
      let b = await Firebase.getSignedUrl({'bucket': res.bucket, 'file': res.path}) 
      if (b && b.length && b.length > 0) {
          window.open(b[0])
        } else {
          alert('Error retrieving file.')
        }
  }
  async function doGetYearlyReport() {
    let res = await Firebase.requestDispatchReport({endTime: moment(endDate).format('DD/MM/YYYY'), startTime: moment(startDate).format('DD/MM/YYYY'), year: year, day: null})
    let b = await Firebase.getSignedUrl({'bucket': res.bucket, 'file': res.path}) 
    if (b && b.length && b.length > 0) {
        window.open(b[0])
      } else {
        alert('Error retrieving file.')
      }
}

function getYears() {

  console.log('get year start and end')
  let startDateOfTheYear = moment(['2015']).valueOf();
let endDateOfTheYear = moment(['2015']).endOf('year').valueOf();
console.log(startDateOfTheYear)
console.log(endDateOfTheYear)
  const currentYear = (new Date()).getFullYear();
const range = (start, stop, step) => Array.from({ length: (stop - start) / step + 1}, (_, i) => start + (i * step));
  return range(currentYear, currentYear - 50, -1)
}


    return (

           <Page
           className={classes.root}
           title="Analytics"
         >
  <Container>
  <Typography variant='h1' style={{marginLeft: 20}}>
             {'Analytics'}
           </Typography>
           <div style={{flexDirection: 'column'}}>
<Typography variant='h3' style={{marginLeft: 20}}>
             {'Daily Report'}
           </Typography>
<div style={{flexDirection: 'row'}}>
<TextField
        label={'Day'}
        type="date"
        variant="outlined"
        margin="normal"
        name="day"
        onChange={(e) => {
        
         setDay(moment(e.target.value).valueOf())
        }}
        value={moment(day).format('yyyy-MM-DD')}
        InputLabelProps={{
        shrink: true,
        }}
        style={{marginLeft: 15}}
        />
               

<Button
          color="primary"
          variant="contained"
          style={{marginLeft: 5, marginTop: 25,  marginBottom: -20, height: 40}}
          onClick={() => doGetDailyReport()}
        >
         {'Generate Report'}
        </Button>
</div>

    </div>


    <div style={{flexDirection: 'column'}}>
<Typography variant='h3' style={{marginLeft: 20}}>
             {'Yearly Report'}
           </Typography>

<div style={{flexDirection: 'row', display: 'flex'}}>

<Autocomplete
  id="year"
  style={{width: 200, marginLeft: 15}}
  width={200}
  disabled={false}
  options={getYears()}
  getOptionLabel={(option) => option + ''}
  value={year}
  onChange={(e, value) => {   
    if (value != null) {
      setYear(value)
    }
  }}
  renderInput={(params) => <TextField {...params}
  style={{width: 200}}
  width={200}
  disabled={false}
  label="Year"
  margin="normal"
  id="year"
  name="year"
  variant="outlined"
  value={year}
  defaultValue={year} />}
/>
<Button
          color="primary"
          variant="contained"
          style={{marginLeft: 5, marginTop: 25,  marginBottom: -20, height: 40}}
          onClick={() => doGetYearlyReport()}
        >
         {'Generate Report'}
        </Button>

</div>

    </div>

<div style={{flexDirection: 'column'}}>
<Typography variant='h3' style={{marginLeft: 20}}>
             {'Custom Report (Includes data occurring on both dates and every date in between them)'}
           </Typography>

<div style={{flexDirection: 'row'}}>
<TextField
        label={'Start Date'}
        type="date"
        variant="outlined"
        margin="normal"
        name="startDate"
        onChange={(e) => {
        
         setStartDate(moment(e.target.value).valueOf())
        }}
        value={moment(startDate).format('yyyy-MM-DD')}
        InputLabelProps={{
        shrink: true,
        }}
        style={{marginLeft: 15}}
        />
                <TextField
        label={'End Date'}
        type="date"
        variant="outlined"
        margin="normal"
        name="endDate"
        onChange={(e) => {
        
         setEndDate(moment(e.target.value).valueOf())
        }}
        value={moment(endDate).format('yyyy-MM-DD')}
        InputLabelProps={{
        shrink: true,
        }}
        />

<Button
          color="primary"
          variant="contained"
          style={{marginLeft: 5, marginTop: 25,  marginBottom: -20, height: 40}}
          onClick={() => doGetReport()}
        >
         {'Generate Report'}
        </Button>

</div>

    </div>

 




        </Container>
      </Page>


      
    );

  
};

/**
 * 
 *  {Object.keys(context.barleyStocksDict).map((key, idx) => {
          let item = context.barleyStocksDict[key]
          return             <Grid
          item
          lg={4}
          md={6}
          xl={3}
          xs={12}
        >
         <BarleyStocks contents={item} warehouse={key} pDct={context.barleyStockspDct} tDct={context.barleyStockstDct}/></Grid>
        })}
 * 
 */
//<JobCardHolder/> was remove for shipper, it held all those job cards
export default AnalyticsScreen;
