import React, { useState, useRef, useEffect } from 'react';
import {useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Avatar,
  Box,
  Card,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  makeStyles,
  Button,
  Link,
  MenuItem
} from '@material-ui/core';
import { TextField } from "@mui/material";

import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import getInitials from 'src/utils/getInitials';
import {X} from 'react-feather';
import HeaderComponent from 'src/utils/HeaderComponent';
import SearchWrapper from 'src/utils/SearchWrapper';
import TableAssist from 'src/utils/TableAssist';
import Firebase from 'src/Firebase'
import AddBarleyFieldDialog from 'src/utils/AddBarleyFieldDialog';
import { DataGrid, GridToolbar, GridToolbarContainer, GridToolbarColumnsButton, GridToolbarFilterButton, GridToolbarDensitySelector, GridToolbarExport } from '@mui/x-data-grid';
import AuthDialog from './AuthDialog'

const filter = createFilterOptions();

function CustomToolbar(props) {

  if (props.allowExport) {
    return <GridToolbar/>
  } else {
    return (
      <GridToolbarContainer >
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector/>
      </GridToolbarContainer>
    );
  }

}

const movies = [
  {
    id: 1,
    movie: "HP"
  },
  {
    id: 2,
    movie: "Matrix"
  },
  {
    id: 3,
    movie: "LOTR"
  }
];

var columns = [
  { field: 'id', headerName: 'ID', width: 90 },
  {
    field: 'firstName',
    headerName: 'First name',
    width: 150,
    editable: true,
  },
  {
    field: 'lastName',
    headerName: 'Last name',
    width: 150,
    editable: true,
  },
  {
    field: 'age',
    headerName: 'Age',
    type: 'number',
    width: 110,
    editable: true,
  },
  {field: 'date',
headerName: 'Date',
type: 'date',
width: 150,
editable: true},
  {
    field: 'fullName',
    headerName: 'Full name',
    description: 'This column has a value getter and is not sortable.',
    sortable: false,
    width: 160,
    valueGetter: (params) =>
      `${params.getValue(params.id, 'firstName') || ''} ${
        params.getValue(params.id, 'lastName') || ''
      }`,
  },
];

var rows = [
  { id: 1, lastName: 'Snow', firstName: 'Jon', age: 35 },
  { id: 2, lastName: 'Lannister', firstName: 'Cersei', age: 42 },
  { id: 3, lastName: 'Lannister', firstName: 'Jaime', age: 45 },
  { id: 4, lastName: 'Stark', firstName: 'Arya', age: 16 },
  { id: 5, lastName: 'Targaryen', firstName: 'Daenerys', age: null, date: Date.now() },
  { id: 6, lastName: 'Melisandre', firstName: null, age: 150 },
  { id: 7, lastName: 'Clifford', firstName: 'Ferrara', age: 44 },
  { id: 8, lastName: 'Frances', firstName: 'Rossini', age: 36 },
  { id: 9, lastName: 'Roxie', firstName: 'Harvey', age: 65 },
];


const useStyles = makeStyles((theme) => ({
  root: {},
  avatar: {
    marginRight: theme.spacing(2)
  }
}));


/*
          renderEditCell: (params) => {              
<Autocomplete
                id={params.id}
                options={movies}
                style={{height: 200}}
                autoHighlight
                getOptionLabel={(option) => option.movie}
                value={params.getValue(params.id, "movie")}
                renderInput={(params) => <TextField {...params} style={{ width: 150, height: 150}}  autoFocus  />}
              />
          }
*/



const CustomObjectsTable = ({ className, customKey, objectFields, objectList, barleyInCustomObjects, context, workflow, customFieldsKey, ...rest }) => {
  const classes = useStyles();
  const [selectedCustomerIds, setSelectedCustomerIds] = useState([]);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);
  const [text_filters, set_text_filters] = useState([]);
  const [sortOrder, setSortOrder] = useState('creationDate');
  const [sortDirection, setSortDirection] = useState(false);
  const [addColumnDialog, setAddColumnDialog] = useState(false);
  const [rows, setRows] = useState(objectList);
  const [selection, setSelection] = useState([]);
  const [passwordDialog, setPasswordDialog] = React.useState(false);
  const [targetName, setTargetName] = React.useState('')
  const [targetId, setTargetId] = React.useState('')
  const docDiaRef = useRef(null);

  const navigate = useNavigate();
  const [sortModel, setSortModel] = React.useState([
    {
      field: 'creationDate',
      sort: 'desc',
    },
  ]);


  function getAutoOptions(barleyInCustomObjects, k) {
    console.log('check now ' + k)
    console.log(barleyInCustomObjects)
    if (k in barleyInCustomObjects) {
      console.log('fucking yes')
      console.log(barleyInCustomObjects[k])
      return Object.keys(barleyInCustomObjects[k]).map((key, id) => {
        return barleyInCustomObjects[k][key]
      })
    }
    console.log('fucking no')
    console.log(barleyInCustomObjects[k])
    return []
  }


  function doApiUpdate(api, id, field, val, event) {
    console.log('incoming update with ')
    console.log(val)
    if (!api) {
      return
    }
    api.setEditCellValue({ id, field, value: val }, event);
    // Check if the event is not from the keyboard
    // https://github.com/facebook/react/issues/7407
    if (event.nativeEvent.clientX !== 0 && event.nativeEvent.clientY !== 0) {
      api.commitCellChange({ id, field });
      api.setCellMode(id, field, 'view');
    }
  }

  function compare2(a,b) {
    if (customKey == 'plans' || customKey == 'supplier' || customKey == 'operations' || customKey == 'trains' || customKey == 'arrivals') {
      let as = a.sortNum ? a.sortNum : -1
      let bs = b.sortNum ? b.sortNum : -1
  
      if (as > bs) {
        return -1
      } else if (bs > as) {
        return 1
      } else {
        return 0
      }
    }

    if (customKey == 'warehouse') {
      if (a.fieldKey == 'name') {
        return -1
      } else if (b.fieldKey != 'name' && (a.fieldKey == 'locationid' )) {
        return -1
      } {
        return 0
      }
    }

  }
  function getOpField(op) {
    var s = ''
    if (op.bolnumber) {
      s += op.bolnumber
    }
    if (op.operationnumber) {
      s += ' ' + op.operationnumber
    }
    if (op.name) {
      s += ' ' + op.name
    }
    return s
  }

  function dateValueGetter(params) {
    return params.value ? moment(params.value).format('DD/MM/YYYY HH:mm') : ''
  }

  function renderColumns(fields, wh, context) {
    var f = fields 
    if (customKey == 'plans' || customKey == 'supplier' || customKey == 'warehouse' || customKey == 'operations' || customKey == 'trains' || customKey == 'arrivals') {
      f.sort((a,b) => compare2(a,b))
    }

    if (customKey == 'plans') {
      f = f.filter((item, idx) => {
        return item.fieldKey != 'allocations'
      })
    }

    let resA =  f.map((item, idx) => {

      if (customKey == 'trains' && item.fieldKey == 'operations') {
        return {field: item.fieldKey, headerName: item.fieldName,width: item.width ? item.width : 150, editable: false, type: 'text',
        valueGetter: (params) => {
          if (params.row['operations']) {
            //return JSON.stringify(context.operationDict)
            var s = ''
            params.row['operations'].map((item, idx) => {
              if (idx > 0) {
                s += ', '
              }
              if (item in context.operationDict) {
                s += context.operationDict[item].operationnumber
              }
            })
            return s 
          } 
          return null 
  
        }}
      }

      if (item.fieldKey == 'displaybol') {
        return {field: item.fieldKey, headerName: item.fieldName,width: item.width ? item.width : 150, editable: false,
        valueGetter: (params) => {
          if (params.row['operations']) {
            //return JSON.stringify(context.operationDict)
            var s = ''
            params.row['operations'].map((item, idx) => {
              if (idx > 0) {
                s += ', '
              }
              if (item in context.operationDict) {
                s += context.operationDict[item].bolnumber ? context.operationDict[item].bolnumber : ''
              }
            })
            return s 
          } 
          return null 
  
        }}
      }

      if (customKey == 'warehousesoftwareusers' && item.fieldKey == 'warehouse') {
        return {field: item.fieldKey, headerName: item.fieldName,width: item.width ? item.width : 150, editable: false, 
        valueGetter: (params) => {
          if (params.row['targetWarehouseSet']) {
            //return JSON.stringify(context.operationDict)
            var s = ''
            Object.keys(params.row['targetWarehouseSet']).map((item, idx) => {
              if (idx > 0) {
                s += ', '
              }
              s += item
            })
            return s 
          } 
          return null
  
        }}
      }


      if (item.fieldKey == 'displaydeclaration') {
        return {field: item.fieldKey, headerName: item.fieldName,width: item.width ? item.width : 150, editable: false,
        valueGetter: (params) => {
          if (params.row['operations']) {
            //return JSON.stringify(context.operationDict)
            var s = ''
            params.row['operations'].map((item, idx) => {
              if (idx > 0) {
                s += ', '
              }
              if (item in context.operationDict) {
                s += context.operationDict[item].declarationnumber ? context.operationDict[item].declarationnumber : ''
              }
            })
            return s 
          } 
          return null 
  
        }}
      }

      if (item.fieldType == 'custom') {
        return {field: item.fieldKey, headerName: item.fieldName, width: item.width ? item.width : 150, editable: 'editable' in item && context.warehouseRole == 'Admin' ? item.editable : false,
          type: item.fieldType != 'text' ? item.fieldType : null, valueGetter: ({ value }) => value && value.name ? value.name : '',
          renderEditCell: (params) => {              
            return  <Autocomplete
            value={params.value}
            onChange={(event, newValue) => {
              console.log(newValue)
              console.log(params)
              console.log(typeof newValue)
              //console.log(newValue.inputValue)
              if (typeof newValue === 'string') {

                //setFieldValue(fields[fieldIdx].fieldKey, {'customId': newValue.customId, 'name': newValue.name})
                doApiUpdate(params.api, params.id, item.fieldKey, {'customId': newValue.customId, 'name': newValue.name}, event)

              } else if (newValue && newValue.inputValue) {
                // Create a new value from the user input

                //setFieldValue(fields[fieldIdx].fieldKey, {'customId':-1, 'name': newValue.inputValue})
                doApiUpdate(params.api, params.id, item.fieldKey, {'customId': -1, 'name': newValue.inputValue}, event)
              } else {

                if (newValue == null) {
                  //setFieldValue(fields[fieldIdx].fieldKey, {'customId': '0', 'name': 'None'})
                  doApiUpdate(params.api, params.id, item.fieldKey, {'customId': '0', 'name': 'None'}, event)
                } else {
                  doApiUpdate(params.api, params.id, item.fieldKey, {'customId': newValue.customId, 'name': newValue.name}, event)
                  //setFieldValue(fields[fieldIdx].fieldKey, {'customId': newValue.customId, 'name': newValue.inputValue})
                }
              }
              //console.log(event.target.value)
              //console.log(newValue)
            }}
            filterOptions={(options, params) => {
              const filtered = filter(options, params);
      
              // Suggest the creation of a new value
              if (params.inputValue !== '') {
                filtered.push({
                  inputValue: params.inputValue,
                  name: `Add "${params.inputValue}"`,
                });
              }
      
              return filtered;
            }}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            id="free-solo-with-text-demo"
            options={getAutoOptions(barleyInCustomObjects, item.fieldKey)}
            getOptionLabel={(option) => {
              // Value selected with enter, right from the input
              if (typeof option === 'string') {
                return option;
              }
              // Add "xxx" option created dynamically
              if (option.inputValue) {
                return option.inputValue;
              }
              // Regular option
              return option.name;
            }}
            renderOption={(option) => option.name}
            freeSolo
            renderInput={(params) => <TextField {...params} style={{ width: 150, height: 150}}  autoFocus  />}
          />}
          
                      
        }
      } else if (item.fieldType == 'list') {
        return {field: item.fieldKey, headerName: item.fieldName, width: item.width ? item.width : 150, editable: 'editable' in item && (context.warehouseRole == 'Admin' || (customKey == 'supplier' && context.warehouseRole == 'Finance')) ? item.editable : false,
          type: 'singleSelect', valueOptions: item.listOptions                      
        }
      } else if (item.fieldKey == 'bolFile' ) {
        return ({field: item.fieldKey, headerName: item.fieldName, width: item.width ? item.width : 150, editable: false,
        type: item.fieldType != 'text' ? item.fieldType : null,
        renderCell: (params) => {
          if (!params.row['bolFile']) {
            return null 
          }
          //if (params.row['gdnlink'] && params.row['gdnlink'].length) {
            return   <Link
            style={{cursor: 'pointer'}}
            to={params.value}
            onClick={ async () => {
              let b = await Firebase.getSignedUrl({'bucket': 'BOL-', 'file': params.row['bolFile']}) 
              if (b && b.length && b.length > 0) {
                window.open(b[0])
              } else {
                alert('Error retrieving file.')
              }
            }/*window.open(params.row['gdnlink'])*/}
            variant="h6"
          >{'View BoL'}</Link>
          /*} else {
            return params.value 
          }*/
        }, 
      })
      }

      if (item.fieldKey == 'edit') {
        return ({field: item.fieldKey, headerName: item.fieldName, width: item.width ? item.width : 150, editable: 'editable' in item && context.warehouseRole == 'Admin' ? item.editable : false,
        type: item.fieldType != 'text' ? item.fieldType : null, renderCell: (params) => {
          if (params.row['noreset']) {
            return ''
          }
          return <Button
          color="primary"
          variant="contained"
          onClick={ () => navigate('/app/edituser', {state: {fields: objectFields, stateVal: params.row}})}
        >
         Edit User
        </Button>
        } })
      }
      if (item.fieldKey == 'editdriver') {
        return ({field: item.fieldKey, headerName: item.fieldName, width: item.width ? item.width : 150, editable: 'editable' in item && context.warehouseRole == 'Admin' ? item.editable : false,
        type: item.fieldType != 'text' ? item.fieldType : null, renderCell: (params) => {
          if (params.row['noreset']) {
            return ''
          }
          return <Button
          color="primary"
          variant="contained"
          onClick={ () => navigate('/app/editdriver', {state: {fields: objectFields, stateVal: params.row}})}
        >
         Edit Driver
        </Button>
        } })
      } 
      if (item.fieldKey == 'editTransporter') {
        return ({field: item.fieldKey, headerName: item.fieldName, width: item.width ? item.width : 150, editable: 'editable' in item && context.warehouseRole == 'Admin' ? item.editable : false,
        type: item.fieldType != 'text' ? item.fieldType : null, renderCell: (params) => {
          if (params.row['noreset']) {
            return ''
          }
          return <Button
          color="primary"
          variant="contained"
          onClick={ () => navigate('/app/edittransporter', {state: {fields: objectFields, stateVal: params.row}})}
        >
         Edit 
        </Button>
        } })
      }
      if (item.fieldKey == 'editvehicle') {
        return ({field: item.fieldKey, headerName: item.fieldName, width: item.width ? item.width : 150, editable: 'editable' in item && context.warehouseRole == 'Admin' ? item.editable : false,
        type: item.fieldType != 'text' ? item.fieldType : null, renderCell: (params) => {
          if (params.row['noreset']) {
            return ''
          }
          return <Button
          color="primary"
          variant="contained"
          onClick={ () => navigate('/app/editvehicle', {state: {fields: objectFields, stateVal: params.row}})}
        >
         Edit Vehicle
        </Button>
        } })
      }

      if (item.fieldKey == 'viewplan') {
        return ({field: item.fieldKey, headerName: item.fieldName, width: item.width ? item.width : 150, editable: 'editable' in item && context.warehouseRole == 'Admin' ? item.editable : false,
        type: item.fieldType != 'text' ? item.fieldType : null, renderCell: (params) => {
          if (params.row['noreset']) {
            return ''
          }
          return <Button
          color="primary"
          variant="contained"
          onClick={ () => navigate('/app/viewplan', {state: {fields: objectFields, stateVal: params.row, stateId: params.id}})}
        >
         View Plan
        </Button>
        } })
      }

      if (item.fieldKey == 'editplan') {
        return ({field: item.fieldKey, headerName: item.fieldName, width: item.width ? item.width : 150, editable: 'editable' in item && context.warehouseRole == 'Admin' ? item.editable : false,
        type: item.fieldType != 'text' ? item.fieldType : null, renderCell: (params) => {
          if (params.row['noreset']) {
            return ''
          }
          return <Button
          color="primary"
          variant="contained"
          onClick={ () => navigate('/app/editplan', {state: {fields: objectFields, stateVal: params.row, stateId: params.id}})}
        >
         Edit Plan
        </Button>
        } })
      }

      if (item.fieldKey == 'password') {
        return ({field: item.fieldKey, headerName: item.fieldName, width: item.width ? item.width : 150, editable: 'editable' in item && context.warehouseRole == 'Admin' ? item.editable : false,
        type: item.fieldType != 'text' ? item.fieldType : null, renderCell: (params) => {
          if (params.row['noreset']) {
            return ''
          }
          return <Button
          color="primary"
          variant="contained"
          onClick={ () => {
            setTargetName(params.row['name'])
            setTargetId(params.id)
            setPasswordDialog(true)
          }}
        >
         Reset Password
        </Button>
        } })

      }
      if (item.fieldType == 'date') {
        return ({field: item.fieldKey, headerName: item.fieldName, width: item.width ? item.width : 150, editable: false,
          type: item.fieldType != 'text' ? item.fieldType : null, valueFormatter: (params) => {
            return params.value ? moment(params.value).format('DD/MM/YYYY HH:mm') : ''
          }})
      }
      return ({field: item.fieldKey, headerName: item.fieldName, width: item.width ? item.width : 150, editable: 'editable' in item && (context.warehouseRole == 'Admin' || (customKey == 'supplier' && context.warehouseRole == 'Finance')) ? item.editable : false,
      type: item.fieldType != 'text' ? item.fieldType : null})
    })


    if (false && /*customKey == 'plans' ||*/ customKey == 'trains') {
     
      var whRes = wh.map((item, idx) => {
        return {field: item.customId, headerName: '(Alloc) ' +  item.name, width:150, editable: false, type: 'text',
      valueGetter: (params) => {
        if (params.row['allocations'] && params.row['allocations'][item.customId]) {
          return params.row['allocations'] && params.row['allocations'][item.customId]
        } 
        return null 

      }}
      })

      return resA.concat(whRes)
    } else {
      return resA 
    }
  }


  async function tryNewColumn(dat) {


    let result = await Firebase.requestNewColumn({'project': Firebase.userProject, 'dat': dat, 'fieldsCollection': workflow ? customFieldsKey : "barleyInCustomObjects/fields/" + customKey})
    console.log(result)
    if (!result) {
      alert('This field already exists.')
    }
  }

 


  async function updateRow(id, updateVal, field) {

    let transaction = await Firebase.updateRowGeneric({'id': id, 'updateVal': updateVal, 'field': field, 'collectionKey': workflow ? customKey : "barleyInCustomObjects/contents/" + customKey, 'project': Firebase.userProject})
    console.log('transaction')
    console.log(transaction)
    if (transaction && transaction.transaction) {
      return true 
    } else if (transaction && transaction.failureMessage) {
      alert(transaction.failureMessage)
    }
    return false 


  }

  async function onCellChange(value, objectFields)  {
    // Get the row
    console.log('osc')
    console.log(value)
    console.log(value.field)
    console.log(value.value)
    if (value !== 0 &&(!value.value || value.value == 'undefined')) {
      console.log('retnowundef')
      return
    }
    //console.log(value)

    const rowIndex = rows.findIndex(row => row.id === value.id);
    const fieldIndex = objectFields.findIndex(item => value.field === item.fieldKey)

    if (rowIndex >= 0 && fieldIndex >= 0) {
      const row = rows[rowIndex];
      const field = objectFields[fieldIndex]
      // Validate if changed
      
      if (!(value.field in row) || (value.field in row && row[value.field] !== value.value)) {
        const data = { [value.field]: value.value };
        console.log('did change, here is data')
        console.log(data)
        console.log(row.id)



        if (value.field == 'sku' || value.field == 'warehouse') {
          if (typeof(value.value) == 'string') {
            // exit early to prevent custom bug
            console.log('EXITEARLY')
            const newRows = [...rows];

          
            newRows[rowIndex][value.field] = row[value.field];

             setRows(newRows);
             return
          }
        }

        // Sending to API
        const newRows = [...rows];
        //await prepUpdate(row, value.field, value.value)
        var ret = null 

          ret = await updateRow(row.id, data, field)
        console.log('result of ret is as follows')
        console.log(ret)
        if (ret) {
          newRows[rowIndex][value.field] = value.value;
        } else {
          console.log('oldset')
          console.log('ent val is')
          console.log(row[value.field])
          if (value.field in row) {
            newRows[rowIndex][value.field] = row[value.field];
          } else {
            delete newRows[rowIndex][value.field]
          }

        }
        console.log(newRows)
        setRows(newRows);
        /*Api.product.update(data).then(res => {
          const newRows = [...rows];

          if (res.success) {
            // Change to new value
            newRows[rowIndex][value.field] = value.value;
          } else {
            // Change to old value
            newRows[rowIndex][value.field] = row[value.field];
          }

          setRows(newRows);
        });*/
      } else {
        console.log(rows)
        console.log('no change')
      }
    }
  };

  async function updatePass(x) {
    if (!x || x.length < 8) {
      alert('Invalid password option.')
      return 
    }

    await Firebase.warehouseEditUser({'userId': targetId, 'updateBody': {'password': x}, 'project': Firebase.userProject} )
    alert('Updated password!')
  }

  function hasCreationField(r) {
    if (customKey == 'plans' || customKey == 'operations' || customKey == 'trains') {
      var f = false 
      r.map((item, idx) => {
        if (item.fieldKey == 'creationDate') {
          f = true 
        }
      })
  
      return f
    }
    return true

  }


  useEffect(() => {
    setRows(objectList);
  }, [objectList]);



  return (
    <div>
      {customKey == 'warehousesoftwareusers'
      ? 
      <AuthDialog
  open={passwordDialog}
  targetName={targetName}
  onClose={() => setPasswordDialog(false)}
  alertTitle={'Approve Request'}
  alertCancelText={'Cancel'}
  alertConfirmText={'Confirm'}
  onConfirm={(x) => {
    updatePass(x.password)
  }}
  />
      : null
      }
        <AddBarleyFieldDialog
        open={addColumnDialog}
        ref={docDiaRef}
        onClose={() => setAddColumnDialog(false)}
        alertTitle={'Add A New Field'}
        alertCancelText={'Cancel'}
        alertConfirmText={'Confirm'}
        onConfirm={(x) => tryNewColumn(x)}
        />

<div style={{flexDirection: 'row', display: 'flex', justifyContent: 'space-between', marginBottom: 5}}>


        {customKey == 'supplier' && context.warehouseRole == 'Admin' || context.warehouseRole == 'Finance' ?
        <Button
        color="primary"
        variant="contained"
        onClick={ () => {
          navigate('/app/newsupplier', {state: {fields: objectFields}})
        }}
      >
       Add Supplier
      </Button>
      : null}

{customKey == 'warehousesoftwareusers' && context.warehouseRole == 'Admin' ?
        <Button
        color="primary"
        variant="contained"
        onClick={ () => {
          navigate('/app/newuser', {state: {fields: objectFields}})
        }}
      >
       Add User
      </Button>
      : null}

{customKey == 'warehouse' /*&& context.warehouseRole == 'Admin'*/ ?
        <Button
        color="primary"
        variant="contained"
        onClick={ () => {
          navigate('/app/newfacility', {state: {fields: objectFields}})
        }}
      >
       Add Facility
      </Button>
      : null}

{customKey == 'sku'/*&& context.warehouseRole == 'Admin'*/ ?
        <Button
        color="primary"
        variant="contained"
        onClick={ () => {
          navigate('/app/newsku', {state: {fields: objectFields}})
        }}
      >
       Add SKU
      </Button>
      : null}
      {customKey == 'product'/*&& context.warehouseRole == 'Admin'*/ ?
        <Button
        color="primary"
        variant="contained"
        onClick={ () => {
          navigate('/app/newproduct', {state: {fields: objectFields}})
        }}
      >
       Add Commodity Type
      </Button>
      : null}
{customKey == 'plans' /*&& context.warehouseRole == 'Admin'*/ ?
        <Button
        color="primary"
        variant="contained"
        onClick={ () => {
          navigate('/app/newplan', {state: {fields: objectFields}})
        }}
      >
       Add Yearly Plan
      </Button>
      : null}
      {customKey == 'operations' /*&& context.warehouseRole == 'Admin'*/ ?
        <Button
        color="primary"
        variant="contained"
        onClick={ () => {
          navigate('/app/newoperation', {state: {fields: objectFields}})
        }}
      >
       Add Vessel Operation
      </Button>
      : null}
            {customKey == 'trains' /*&& context.warehouseRole == 'Admin'*/ ?
        <Button
        color="primary"
        variant="contained"
        onClick={ () => {
          navigate('/app/newtrain', {state: {fields: objectFields}})
        }}
      >
       Add Train Plan
      </Button>
      : null}
              {context.warehouseRole == 'Admin' 
        ?
        <Button
          color="primary"
          variant="contained"
          onClick={ () => {
           setAddColumnDialog(true)
          }}
        >
         Add Column
        </Button>
        : null}
      </div>
      {hasCreationField(objectFields) && (context.warehouseRole == 'Admin' || customKey != 'warehousesoftwareusers') ? 
          <div style={{height: 650, width: '99%'}}>
            {
              (customKey == 'plans' || customKey == 'operations' || customKey == 'trains') ?

              <DataGrid
              style={{backgroundColor: 'white'}}
              
          rows={rows}
          columns={renderColumns(objectFields, customKey == 'plans' || customKey == 'trains' ? getAutoOptions(context.barleyInCustomObjects, 'warehouse') : [], context)}
          pageSize={20}
          rowsPerPageOptions={[20]}
          density={'compact'}
          onCellEditCommit={(p, e, d) => onCellChange(p, objectFields)}
          hideFooterPagination={false}
          hideFooter={false}
          onFilterModelChange={(mo) => {
            Firebase.trackMixpanel('filter' + customKey, mo)
  
          }}
          checkboxSelection
          sortModel={sortModel}
          disableSelectionOnClick
          onSelectionModelChange={(newSelection) => {
            setSelection(newSelection);
          }}
          components={{
            Toolbar: CustomToolbar,
          }}
          componentsProps={{ toolbar: { allowExport: context.warehouseRole == 'Admin' || context.warehouseRole == 'Facility' } }}
        />

              : 

              <DataGrid
              style={{backgroundColor: 'white'}}
              
          rows={rows}
          columns={renderColumns(objectFields, customKey == 'plans' || customKey == 'trains' ? getAutoOptions(context.barleyInCustomObjects, 'warehouse') : [], context)}
          pageSize={20}
          rowsPerPageOptions={[20]}
          density={'compact'}
          onCellEditCommit={(p, e, d) => onCellChange(p, objectFields)}
          hideFooterPagination={false}
          hideFooter={false}
          onFilterModelChange={(mo) => {
            Firebase.trackMixpanel('filter' + customKey, mo)
  
          }}
          checkboxSelection
          disableSelectionOnClick
          onSelectionModelChange={(newSelection) => {
            setSelection(newSelection);
          }}
          components={{
            Toolbar: CustomToolbar,
          }}
          componentsProps={{ toolbar: { allowExport: context.warehouseRole == 'Admin' || context.warehouseRole == 'Facility' } }}
        />
            }

      </div>
      : null }
    </div>
  )
};

CustomObjectsTable.propTypes = {
  className: PropTypes.string,
  customers: PropTypes.array.isRequired
};

export default CustomObjectsTable;
