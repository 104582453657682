import 'react-perfect-scrollbar/dist/css/styles.css';
import { BrowserRouter, Switch, Route, useNavigate } from 'react-router-dom';
import React, {useState, useEffect} from 'react';
import { useRoutes } from 'react-router-dom';
import { CircularProgress, ThemeProvider } from '@material-ui/core';
import GlobalStyles from 'src/components/GlobalStyles';
import 'src/mixins/chartjs';
import theme from 'src/theme';
import routes from 'src/routes';
import routesShipper from 'src/routesShipper';
import routesAssoc from 'src/routesAssoc';
import Firebase from 'src/Firebase.js';
import DataFetcher from './DataFetcher';
import StateManager from './StateManager';
import ContextProvider from 'src/ContextProvider';
import TableContextProvider from './TableContextProvider';
import GarriLoadingScreen from './GarriLoadingScreen';



const App = () => {


  const contextRef = React.useRef(null);

  useEffect(() => {
    // Update the document title using the browser API
    /*Firebase.setMainApp(() => {
      setUserLoggedIn(!userLoggedIn)
      //alert('set modifier')
      //alert(ss) 
    })*/
    StateManager.existingDict = existingDict
    StateManager.tableItems = tableItems
    if (!Firebase.didInit) {
      console.log('please init')

      StateManager.functionalStateListAdd = (x) => {
        var temp = existingDict
        temp[x.quoteId] = x.quoteId 
        var templst = tableItems
        templst.push(x)
        setTableItems(templst)
        setExistingDict(temp)
      }

      StateManager.functionalStateListUpdate = (x) => {
        var temp = tableItems.map((item, idx) => {
          if (item.quoteId == x.quoteId) {
            return x 
          }
          return item 
        })
        setTableItems(temp)
        //console.log(JSON.stringify(x))
        //console.log('did update at top level')
      }

      StateManager.functionalStateUpdateSet = (y, x) => {
        setTableItems(x)
        setExistingDict(y)
      }
      Firebase.userInfoUpdate = (x) => {
        setUserInfo(x)
      }

      DataFetcher.setCargoTypes = (x) => {
        setCargoTypes(x)
      }

      Firebase.awfulStuff.push(() => DataFetcher.fetchTransporters())
      Firebase.awfulStuff.push(() => DataFetcher.fetchCargoTypes())
      Firebase.awfulStuff.push(() => DataFetcher.fetchDrivers())
      console.log('right here sir')
      DataFetcher.onComplete = () => {
        //console.log('tryoc')
        //console.log(DataFetcher.transportersListeners)
        //console.log(DataFetcher.cargoTypeInfo)
        if (true) {
          console.log('jss here')
          Firebase.auth.onAuthStateChanged(user => {
            //alert(JSON.stringify(user) + ' ' + !!user)
      
            /*if (!!user) {
                Firebase.initComplex()
            }*/
            console.log('authstatechange here')
            if (!!user) {
              setLoaded(false)
              console.log('set uid here')
              Firebase.setUid(user.uid)
              Firebase.identifyMixpanel(user.uid)
              if (contextRef != null && contextRef.current != null) {
                console.log(contextRef)
                contextRef.current.init(() => setLoaded(true))
              } else {
                Firebase.requiresContextInit = true 
              }


              StateManager.loadListDualRef('shipperRequestsByUid/' + Firebase.uid, 'shipperRequests')
                setUserLoggedIn(1)
            } else {
              console.log('user not logged in')
              setUserLoggedIn(0)
              if (contextRef != null && contextRef.current != null) {
                console.log('try reset')
                contextRef.current.reset()
              }
              console.log('set logout')
      
            }
        });
        }
      }
      Firebase.init()


    }

  });

    const [userLoggedIn, setUserLoggedIn] = React.useState(-1)
    const [existingDict, setExistingDict] = React.useState({})
    const [tableItems, setTableItems] = React.useState([])
    const [userInfo, setUserInfo] = React.useState({})
    const [cargoTypes, setCargoTypes] = React.useState({})
    const [loaded, setLoaded] = React.useState(true)



    const navigation = useNavigate()
    const routing = useRoutes(routesShipper(userLoggedIn, tableItems, userInfo, cargoTypes, navigation))

  return (

      
      <ContextProvider ref={contextRef}>
        <TableContextProvider>
      {loaded ? 
      <ThemeProvider theme={theme}>
        <GlobalStyles />
        {routing}
      </ThemeProvider> 
 
    : <GarriLoadingScreen/>}
    </TableContextProvider>
         </ContextProvider>

  
  )


 

};

export default App;
