import React, {Component} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import MuiPhoneNumber from 'material-ui-phone-number'
import Firebase from 'src/Firebase'
import ChipInput from 'material-ui-chip-input'



class VoidNoteDialog extends Component {


    static defaultProps = {
        headerColor: 'black',
        bodyColor: 'black',
    };

    constructor(props) {
        super(props);

        let sfz = this.props.documentName
        this.state = {
            value: this.props.defaultValue,
            open: false,
            popRef: null,
            sortAscending: false,
            sortDescending: false,
            notificationType: '',
            notificationHeader: '',
            notificationBody: sfz,
            phoneNumber: '',
            listChips: []
        }

        this.topRef = null
    }

    componentDidMount() {
        //alert('mounted')
    }

    tryConfirm() {
      this.props.onConfirm(this.state.voidRemark)
      this.props.onClose()
    }


    handleAddChip(chip) {
      var chips = this.state.listChips
      this.setState({
          listChips: chips.concat(chip),
          chipAdded: true 
      })
  }

  handleDeleteChip(chip, idx) {
      let chips = this.state.listChips.filter((item, ii) => {
          return (ii != idx)
      })

      this.setState({
          listChips: chips
      })
  }

    render() {
        return (
            <Dialog
            open={this.props.open}
            onClose={() => this.props.onClose()}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{this.props.alertTitle}</DialogTitle>
            <DialogContent>
                
              <TextField
            autoFocus
            margin="dense"
            label="Remark"
            fullWidth
            placeholder={''}
            defaultValue={''}
            value={this.state.voidRemark}
            onChange={(e) => {
              this.setState({
                voidRemark: e.target.value
              })
            }}
          />
            
            </DialogContent>
            <DialogActions>
              <Button onClick={() => this.props.onClose()} color="primary">
                {this.props.alertCancelText}
              </Button>
              <Button onClick={() => this.tryConfirm()} disabeld={this.state.fieldType == 'list' && this.state.listChips.length < 1} color="primary" autoFocus>
                {this.props.alertConfirmText}
              </Button>
            </DialogActions>
          </Dialog>
        )

    }
}
const headerStyle = {
    backgroundColor: 'rgb(67,67,67)', flexWrap: 'never', whiteSpace: 'noWrap'
}

/*   <div onClick={() => this.adjustSortDescending()} style={{ margin: 0, display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: this.state.sortDescending ? 'orange' : 'white', border: this.state.sortDescending ? '' : '1px solid black', borderRadius: 30, padding: 0, height: 30, padding: 5 }}>
                                <p>Descending</p>
                            </div>*/

/*
 * 
 * 
 *             */

export default VoidNoteDialog;