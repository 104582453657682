import React, {useState, useEffect, useRef} from 'react';
import {
  Box,
  makeStyles,
  CircularProgress
} from '@material-ui/core';
import Page from 'src/components/Page';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  }
}));


function renderStatus(s) {
  if (s == 0) {
    return 'Quote Requested'
  } else if (s==1) {
    return 'Quote Offered'
  } else if (s==2) {
    return 'Quote Accepted'
  } else if (s==3) {
    return 'Offered to Transporters'
  } else if (s==4) {
    return 'Transporter Placed Bid'
  } else if (s==5) {
    return 'Transporter Accepted Job'
  } else if (s==6) {
    return 'Job Assigned To Driver'
  } else if (s==7) {
    return 'Driver en Route'
  } else if (s==8) {
    return 'Driver Loaded Truck'
  } else if (s==9) {
    return 'Driver Delivered Load'
  }
}

const QuoteStatus = (state) => {
  const classes = useStyles();

  

  return  <div ><div style={{backgroundColor: 'cyan', alignItems: 'center', justifyContent: 'center', display: 'flex',
       borderRadius: 10, width: 240, height: 40}}><a style={{color: 'white', fontSize: 20}}>{renderStatus(state.customerState)}</a></div></div>
};

export default QuoteStatus;
