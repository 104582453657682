import React, {Component} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import MuiPhoneNumber from 'material-ui-phone-number'
import Firebase from 'src/Firebase'
import Typography from '@material-ui/core/Typography';

class AuthHelperDialog extends Component {


    static defaultProps = {
        headerColor: 'black',
        bodyColor: 'black',
    };

    constructor(props) {
        super(props);

        this.state = {
            value: this.props.defaultValue,
            open: false,
            popRef: null,
            sortAscending: false,
            sortDescending: false,
            notificationType: '',
            notificationHeader: '',
            notificationBody: '',
            phoneNumber: '',
            resultText: '',
        }

        this.topRef = null
    }

    componentDidMount() {
        //alert(this.props.defaultValue)
    }

    tryConfirm() {
      if (this.state.phoneNumber == '') {
        alert('Please enter a phone number.')
        return
      }

      this.setState({
        dialogWorking: true
      })
      let suc = (x) => {
        this.setState({
          dialogWorking: false
        })
        //console.log(JSON.stringify(x))
        if (x.result == -1) {
          this.setState({
            resultText: 'Could not find a verification code for this user. Have the user generate another code and try again.'
          })
        } else if (x.result == 200) {
          let info = x.info 

          if (Date.now() - info.time > (3600 * 1000)) {
            this.setState({
              resultText: 'The latest code for this user has expired. Have the user generate another code and try again.'
            })
          } else if (info.attempts > 5) {
            this.setState({
              resultText: 'The user has entered too many wrong answers for this code. Have the user generate another code and try again.'
            })
          } else {
            this.setState({
              resultText: 'The code is: ' + info.code + '. It expires in ' + Math.round(((info.time + (1000 * 3600)) - Date.now())/(1000*60)) + ' minute(s).'
            })
          }
        }
      }

      let fail = () => {
        this.setState({
          dialogWorking: false
        })
        alert('An error occurred. Check your connection and try again.')
      }

      Firebase.retrieveUserAuthCode({'phone': this.state.phoneNumber, 'userId': Firebase.uid}, suc, fail)

    }

   

    render() {
        return (
            <Dialog
            open={this.props.open}
            onClose={() => this.props.onClose()}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{this.props.alertTitle}</DialogTitle>
            <DialogContent>
                
              <DialogContentText id="alert-dialog-description">
                {this.state.dialogWorking ? 'Performing Operation...' : this.props.alertBody}
                {this.state.dialogWorking ? <div style={{alignItems: 'center', justifyContent: 'center'}}><CircularProgress/></div> : <div/>}
              </DialogContentText>
  
              <MuiPhoneNumber defaultCountry={'et'}
                regions={['america','africa']}
                onlyCountries={['us', 'et']}
                autoFormat={false}
                disableAreaCodes={true}
                  fullWidth
                  margin="normal"
                  onChange={(e) => this.setState({
                    phoneNumber: e
                  })}
                  variant="outlined"
                />
            <Typography>{this.state.resultText}</Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => this.props.onClose()} color="primary">
                {this.props.alertCancelText}
              </Button>
              <Button onClick={() => this.tryConfirm()} color="primary" autoFocus>
                {this.props.alertConfirmText}
              </Button>
            </DialogActions>
          </Dialog>
        )

    }
}
const headerStyle = {
    backgroundColor: 'rgb(67,67,67)', flexWrap: 'never', whiteSpace: 'noWrap'
}

/*   <div onClick={() => this.adjustSortDescending()} style={{ margin: 0, display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: this.state.sortDescending ? 'orange' : 'white', border: this.state.sortDescending ? '' : '1px solid black', borderRadius: 30, padding: 0, height: 30, padding: 5 }}>
                                <p>Descending</p>
                            </div>*/

/*
 * 
 * 
 *             */

export default AuthHelperDialog;