
import moment from 'moment'


export default class TableAssist {

  static renderLinkedJobs(customer, dct) {
    var s = ''
    customer.jobs.map((item ,idx) => {
      s += dct[item.quoteId]
      if (customer.jobs.length > 1 && idx < customer.jobs.length - 1) {
        s += ', '
      }
    })
    return s
  }

  static renderJobs(customer, dct) {
    var jobs = ''
    customer.jobs.map((item, idx) => {
      if (idx != 0) {
        jobs += ', '
      }
      jobs += dct[item]
    })
  }

  static renderOwner(customer, dct) {
    if (dct != null && 'assignedTransporter' in customer && customer.assignedTransporter != null && customer.assignedTransporter != 'undefined' && customer.assignedTransporter != '0') {
      if (customer.assignedTransporter in dct) {
        return dct[customer.assignedTransporter]
      } else {
        return ''
      }
    } else {
      return ''
    }
  }

  static renderDriver(customer, dct) {
    if (dct != null && 'assignedDriver' in customer && customer.assignedDriver != null && customer.assignedDriver != 'undefined' && customer.assignedDriver != '0') {
      if (customer.assignedDriver in dct) {
        return dct[customer.assignedDriver]
      } else {
        return ''
      }
    } else {
      return ''
    }
  }

  static renderCustomerPhones(v) {
    var rets = v.phone 
  
    if ('secondaryPhones' in v && v.secondaryPhones.length > 0) {
      v.secondaryPhones.map((item, idx) => {
        rets += ', ' + item
      })
    }
    return rets
  }

  static padDigits(number) {
    return Array(Math.max(3 - String(number).length + 1, 0)).join(0) + number;
  }

  static renderIdDisplay(customer) {

    var shortId = customer.shortId 
    var shipperSideId = null 
    if ('shortIdShipper' in customer) {
      shipperSideId = TableAssist.padDigits(customer.shortIdShipper)
    }
    var internalShipperId = null 
    if ('internalShipperId' in customer) {
      internalShipperId = customer.internalShipperId
    }

    if (shipperSideId == null) {
      return 'GAR-' + shortId 
    } else if (internalShipperId == null) {
      return 'GAR-' + shortId + ' (' + shipperSideId + ')'
    } else {
      if (customer.roundTripLink && customer.roundTripLink != -1) {
        return internalShipperId + '-' + shipperSideId + ' (Roundtrip: ' + internalShipperId + '-' + TableAssist.padDigits(customer.shortIdShipperLINK) + ')'
      }
      return internalShipperId + '-' + shipperSideId 
    }

  }

  static renderIdValue(customer) {
    var shortId = customer.shortId 
    var shipperSideId = null 
    if ('shortIdShipper' in customer) {
      shipperSideId = TableAssist.padDigits(customer.shortIdShipper)
    }
    var internalShipperId = null 
    if ('internalShipperId' in customer) {
      internalShipperId = customer.internalShipperId
    }

    if (shipperSideId == null) {
      return shortId
    } else if (internalShipperId == null) {
      return shortId
    } else {
      return  internalShipperId + '-' + shipperSideId 
    }
  }


    static fieldFormat(x, k, table=0, dct=null, dctjob=null) {
        //console.log(table)
        if (table == 0) {
          if (k == 'shortId') {
            return TableAssist.renderIdDisplay(x)
          }
          // Job table
          if (k == 'creationDate') {
            return moment(x[k]).format('DD/MM/YYYY')
        } 
        //console.log('format for ' + k)
        if (k == 'state') {
            let s = x[k]
            if (s == 0) {
                return 'Quote Requested'
              } else if (s==1) {
                return 'Quote Offered'
              } else if (s==2) {
                return 'Quote Accepted'
              } else if (s==3) {
                return 'Offered to Transporters'
              } else if (s==4) {
                return 'Transporter Placed Bid'
              } else if (s==5) {
                return 'Transporter Accepted Job'
              } else if (s==6) {
                return 'Job Assigned To Driver'
              } else if (s==7) {
                return 'Driver en Route'
              } else if (s==8) {
                return 'Driver Loaded Truck'
              } else if (s==9 && !x.jobIsPaid) {
                return 'Driver Delivered Load (UNPAID)'
              } else if (s==9) {
                return 'Driver Delivered Load (PAID)'
              } else if (s==-1) {
                return 'Quote Rejected'
              }
        }
        if (k == 'transporter') {
          return TableAssist.renderOwner(x, dct)
        }
        if (k == 'driver') {
          return TableAssist.renderDriver(x, dct)
        }

        return x[k]
        } else if (table == 1) {
           // User table 
           if (k == 'verified') {
             return x[k] ? 'Verified' : 'Unverified'
           } else if (k == 'userType') {
            if (x[k] == 1) {
              return "Transporter"
            } else if (x[k] == 2) {
              return 'Shipper'
            } else if (x[k] == 3) {
              return "Driver"
            } else {
              return "Unknown"
            }
           } else if (k == 'name') {
            var rets = ''
            if ('firstName' in x) {
              rets += x.firstName
            } 
            if ('middleName' in x) {
              rets += ' ' + x.middleName
            } 
            if ('lastName' in x) {
              rets += ' ' + x.lastName
            }
            return rets
           } else if (k == 'phone') {
             return TableAssist.renderCustomerPhones(x)
           }

           return x[k]
        } else if (table == 2) {
          // vehicle
          if (k == 'verified') {
            return x[k] ? 'Verified' : 'Unverified'
          } else if (k == 'gpsWoxName') {
            return x[k] ? x[k] : 'Unassigned'
          } else if (k == 'owner') {
            return TableAssist.renderOwner(x, dct)
          } else if (!(k in x)) {
            return ''
          }
          return x[k]
        } else if (table == 3) {
          if (k == 'totalWeightRemaining') {
            return '' + (x[k] * 10)
          }
          return x[k]
        } else if (table == 4) {
          //alert(k)
          if (k=='paymentNumber') {
            return 'P-' + TableAssist.padDigits(x['shortIdShipper'])
          } else if (k=='userName') {
            return TableAssist.renderDriver({'assignedDriver': x.jobs && x.jobs.length > 0 ? x.jobs[0].recipient : ''}, dct)
          } else if (k=='linkedJobs') {
            return TableAssist.renderLinkedJobs(x,dctjob)
          }
            return '' + x[k] 
        }

        

    }

}