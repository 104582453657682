import React, { useState } from 'react';
import {useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Avatar,
  Box,
  Card,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  MenuItem,
  Typography,
  makeStyles,
  Button,
  Link
} from '@material-ui/core';
import getInitials from 'src/utils/getInitials';
import {X} from 'react-feather';
import HeaderComponent from 'src/utils/HeaderComponent';
import SearchWrapper from 'src/utils/SearchWrapper';
import TableAssist from 'src/utils/TableAssist';
import AlertDialog from 'src/utils/AlertDialog';
import Firebase from 'src/Firebase';
import CollapseableSummary from './CollapseableSummary'

const targetStates = [{'name': 'Driver Assigned', 'value': 6}, {'name': 'Driver en Route', 'value': 7},
{'name': 'Driver Loaded Truck', 'value': 8}, {'name': 'Driver Delivered Load', 'value': 9}]
const statesDict = {6: 'Driver Assigned', 7: 'Driver en Route', 8: 'Driver Loaded Truck', 9: 'Driver Delivered Load'}

const useStyles = makeStyles((theme) => ({
  root: {},
  avatar: {
    marginRight: theme.spacing(2)
  }
}));

const UserJobsTable = ({ className, customers,users, ...rest }) => {
  const classes = useStyles();
  const [selectedCustomerIds, setSelectedCustomerIds] = useState([]);
  const [expandedCustomerIds, setExpandedCustomerIds] = useState([]);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);
  const [text_filters, set_text_filters] = useState([]);
  const [sortOrder, setSortOrder] = useState('creationDate');
  const [sortDirection, setSortDirection] = useState(false);
  const [targetState, setTargetState] = useState(6);
  const [updateAlertOpen, setUpdateAlertOpen] = useState(false);
  const [updateDialogWorking, setUpdateDialogWorking] = useState(false);
  const [expandedDict, setExpandedDict] = useState({});
  const navigate = useNavigate();

  const handleSelectAll = (event) => {
    let newSelectedCustomerIds;

    if (event.target.checked) {
      newSelectedCustomerIds = customers.map((customer) => customer.quoteId);
    } else {
      newSelectedCustomerIds = [];
    }

    setSelectedCustomerIds(newSelectedCustomerIds);
  };

  function activateTextFilter(val) {
    var oldFilters = text_filters
    //console.log('old text filters : ' + JSON.stringify(oldFilters))
    var found_filter = false
    oldFilters = oldFilters.map((item, idx) => {
        if (item.name == val.name) {
            found_filter = true
            item.target = val.target
            return val
        }
        return item
    })
    //console.log('oldbeforeadding')
    if (!found_filter) {
        oldFilters.push(val)
    }
    console.log('new text filters : ' + JSON.stringify(oldFilters))
    set_text_filters(oldFilters)
  
  }
  
  function deactivateTextFilter(val) {
    let newFilters = text_filters.filter((item, idx) => {
        return (item.key != val)
    })
  
    set_text_filters(newFilters)
  }
  
  function updateSortDirection(v, d) {
  setSortOrder(v)
  setSortDirection(d)
  }
  
  function applySecondaryFilters(items, filters) {
  var returnList = items.filter((v, idx) => {
      let item = v
      var returnItem = true 
      filters.map((filter, filterIdx) => {
          if (filter.condition == '>') {
              if (filter.key in item && item[filter.key] > filter.target) {
                  //returnItem = true
              } else {
                  returnItem = false
              }
          } else if (filter.condition == '<') {
              if (filter.key in item && item[filter.key] < filter.target) {
                  //returnItem = true
              } else {
                returnItem = false
              }
          } else if (filter.condition == '=') {
              if (filter.key in item && item[filter.key] === filter.target) {
                  //returnItem = true
              } else {
                returnItem = false
              }
          } else if (filter.condition == 't') {
            
              if (filter.key in item) {
  
                  let itemSet = TableAssist.fieldFormat(item, filter.key) 
                  console.log(itemSet)
                  console.log(filter.key)
                  console.log(item.verified)
                  let itemData = itemSet.toUpperCase();
  
                  let textData = filter.target.toUpperCase();
  
                  if (itemData.indexOf(textData) > -1) {
                      //returnItem = true
                  } else {
                    returnItem = false
                  }
  
              } else {
                //console.log(item)
                //console.log('not in' + filter.key)
                returnItem = false
              }
          } else if (filter.condition == 'd') {
              if (filter.key in item && item[filter.key] in filter.target) {
                  //returnItem = true
              } else {
                returnItem = false
              }
  
          }
      })
  
  
      return returnItem
  })
  
  return returnList
  }
  
  function compare2(aa, bb, key, reverse) {
        
  let a = aa//TableHelper.getSortValue(aa, key)
  let b = bb//TableHelper.getSortValue(bb, key)
  
  console.log('do a comparison of ' + a[key] + ' to ' + b[key])
  let aval = a[key]
  let bval = b[key]
  if (aval < bval) {
      return -1 * ((2 * reverse) - 1);
  }
  if (aval > bval) {
      return ((2 * reverse) - 1);
  }
  return 0;
  }
  
  function sortItems(v, s) {
  //console.log('try sorting')
  //console.log(v)
  //console.log(s)

  var o = v

  if (s == 0) {
      return o
  }
  else if (s == 1) {
      o.sort((a, b) => compare2(a, b, s, sortDirection))
  } else {
      o.sort((a, b) => compare2(a, b, s, sortDirection))
  }
  
  //alert(JSON.stringify(o))
  //return o
  if (text_filters.length > 0) {
    return applySecondaryFilters(o, text_filters)
  }
  return o
  }

  const handleSelectOne = (event, id) => {
    const selectedIndex = selectedCustomerIds.indexOf(id);
    let newSelectedCustomerIds = [];

    if (selectedIndex === -1) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(selectedCustomerIds, id);
    } else if (selectedIndex === 0) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(selectedCustomerIds.slice(1));
    } else if (selectedIndex === selectedCustomerIds.length - 1) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(selectedCustomerIds.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(
        selectedCustomerIds.slice(0, selectedIndex),
        selectedCustomerIds.slice(selectedIndex + 1)
      );
    }

    setSelectedCustomerIds(newSelectedCustomerIds);
  };

  const handleExpandOne = (event, id) => {
    const selectedIndex = expandedCustomerIds.indexOf(id);
    let newexpandedCustomerIds = [];
  
    if (selectedIndex === -1) {
      newexpandedCustomerIds = newexpandedCustomerIds.concat(expandedCustomerIds, id);
    } else if (selectedIndex === 0) {
      newexpandedCustomerIds = newexpandedCustomerIds.concat(expandedCustomerIds.slice(1));
    } else if (selectedIndex === expandedCustomerIds.length - 1) {
      newexpandedCustomerIds = newexpandedCustomerIds.concat(expandedCustomerIds.slice(0, -1));
    } else if (selectedIndex > 0) {
      newexpandedCustomerIds = newexpandedCustomerIds.concat(
        expandedCustomerIds.slice(0, selectedIndex),
        expandedCustomerIds.slice(selectedIndex + 1)
      );
    }
  
    setExpandedCustomerIds(newexpandedCustomerIds);
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  function renderVerified(x) {
    if (x.verified) {
      return 'Verified'
    } else {
      return 'Unverified'
    }
  }


  function renderStatus(customer) {
    let s = customer.state 
    if (s == -1) {
      return 'Quote Rejected'
    } else if (s == 0) {
      return 'Quote Requested'
    } else if (s==1) {
      return 'Quote Offered'
    } else if (s==2) {
      return 'Quote Accepted'
    } else if (s==3) {
      return 'Offered to Transporters'
    } else if (s==4) {
      return 'Transporter Placed Bid'
    } else if (s==5) {
      return 'Transporter Accepted Job'
    } else if (s==6) {
      return 'Job Assigned To Driver'
    } else if (s==7) {
      return 'Driver en Route'
    } else if (s==8) {
      return 'Driver Loaded Truck'
    } else if (s==9 && !customer.jobIsPaid) {
      return 'Driver Delivered Load (UNPAID)'
    } else if (s==9) {
      return 'Driver Delivered Load (PAID)'
    }
  }

 function renderAction(customer) {
    if (!('state' in customer) || customer.state == 0) {
      return <Button
      color="primary"
     
      onClick={() => navigate('/app/editltc', {state: {customer: customer}})}
      id="sendButton"
      size="large"
      variant="contained"
 
    >
      Edit Contract
     </Button>
    } else if (customer.state == 2) {
      return <Button
      color="primary"
     
      onClick={() => window.open('http://localhost:3000/v/' + customer.quoteId)}
      id="sendButton"
      size="large"
      variant="contained"
  
    >
      Assign Job
    </Button>
    } else if (customer.state > 2) {
      return <Button
      color="primary"
     
      onClick={() => alert('later stages not implemented yet')}
      id="sendButton"
      size="large"
      variant="contained"
  
    >
      Manage Job
    </Button>
    }
 }

 function trueSelectedCustomerIds() {
  if (text_filters.length > 0) {
    return applySecondaryFilters(customers, text_filters).map((item, idx) => {
      return item.quoteId
    }).filter((item, idx) => {
      return selectedCustomerIds.includes(item)
    })
  } else {
    return selectedCustomerIds
  }
}

 function doUpdateJobState() {
   if (trueSelectedCustomerIds().length < 1) {
     alert('Select at least one job.')
     return 
   }

   setUpdateDialogWorking(true)
   let s = function (x) {
     setUpdateAlertOpen(false)
     setUpdateDialogWorking(false)
     setSelectedCustomerIds([])
     alert('Progress updated for selected jobs!')
    
   }
 
   let f = function () {
    setUpdateAlertOpen(false)
    setUpdateDialogWorking(false)
     alert('Operation Failed')
   }

   Firebase.batchUpdateSimpleJobs({'jobList': trueSelectedCustomerIds(), 'stateTarget': targetState}, s, f)

 }

  return (
<div style={{marginLeft: 10, width: '100%'}}>
<AlertDialog open={updateAlertOpen}
      dialogWorking={updateDialogWorking}
      onClose={() => setUpdateAlertOpen(false)}
      alertTitle={'Update the progress of ' + trueSelectedCustomerIds().length + ' job(s)?'}
      alertBody={'If you choose to proceed, the progress of the selected jobs will be changed to ' + statesDict[targetState] + '. This may affect the assigned driver and transporter.'}
      alertCancelText={'Cancel'}
      alertConfirmText={'Confirm'}
      onConfirm={() => doUpdateJobState()}
      />
       <SearchWrapper activate={(v) => activateTextFilter(v)} deactivate={(v) => deactivateTextFilter(v)}/>

   
       <Card
       className={clsx(classes.root, className)}
       {...rest}
     >
<div style={{marginTop: 10}}>
<Button
            color="primary"
            variant="contained"
            style={{marginLeft: 10, marginRight: 10, backgroundColor: 'red'}}
            onClick={() => setUpdateAlertOpen(true)}
          >
            Set Progress
          </Button>
          <TextField select 
          style={{width: 240}}
          label="Progress Options"
          variant="outlined"
          value={targetState}
          onChange={(x) => {
            setTargetState(x.target.value)            
          }}
        >
          {targetStates.map((item, idx) => {
            return (<MenuItem value={item.value}>{item.name}</MenuItem>)
          })}
        </TextField></div>
      <PerfectScrollbar>
        <Box maxHeight={400}>
        <div style={{ flexDirection: 'row', display: 'flex', marginBottom: 20 }}>
                    {
                        text_filters.map((item, idx) => {
                            return (
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', backgroundColor: 'rgb(230,230,230)', borderRadius: 30, padding: 0, margin: 0, height: 30, padding: 10, margin: 5, }}>
                                    <p>{item.name + ' contains ' + item.target}</p>
                                    <X onClick={() => deactivateTextFilter(item.key)} />
                                </div>
                                )
                        })
                    }
                </div>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={selectedCustomerIds.length === customers.length}
                    color="primary"
                    indeterminate={
                      selectedCustomerIds.length > 0
                      && selectedCustomerIds.length < customers.length
                    }
                    onChange={handleSelectAll}
                  />
                </TableCell>
                <HeaderComponent sortSet={(v, d) => updateSortDirection(v, d)} text={'ID'} itemkey={'shortId'} activate={(v) => activateTextFilter(v)} deactivate={(v) => deactivateTextFilter(v)} />
                <HeaderComponent sortSet={(v, d) => updateSortDirection(v, d)} text={'Commodity'} itemkey={'commodity'} activate={(v) => activateTextFilter(v)} deactivate={(v) => deactivateTextFilter(v)} />
                <HeaderComponent sortSet={(v, d) => updateSortDirection(v, d)} text={'Status'} itemkey={'state'} activate={(v) => activateTextFilter(v)} deactivate={(v) => deactivateTextFilter(v)} />
                

              </TableRow>
            </TableHead>
            <TableBody>
              {sortItems(customers, sortOrder).slice(page * limit, page * limit + limit).map((customer) => (
                <React.Fragment>
                <TableRow
                  hover
                  key={customer.quoteId}
                  selected={selectedCustomerIds.indexOf(customer.quoteId) !== -1}
                 onClick={() => handleExpandOne('', customer.quoteId)}
                >
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={selectedCustomerIds.indexOf(customer.quoteId) !== -1}
                      onChange={(event) => handleSelectOne(event, customer.quoteId)}
                      value="true"
                    />
                  </TableCell>
                  <TableCell>
                  <Link
              to={customer}
              onClick={ () => window.open(Firebase.adminip + 'info/' + customer.shortId)}
              variant="h6"
            >{TableAssist.renderIdDisplay(customer)}</Link>
                  </TableCell>
                  <TableCell>
                    {customer.commodity}
                  </TableCell>
                  <TableCell>
                    {renderStatus(customer)}
                  </TableCell>

                </TableRow>
                <TableRow><TableCell style={{ paddingBottom: 0, paddingTop: 0, backgroundColor: 'white'}} colSpan={4}>
                <CollapseableSummary usersProp={users} customer={customer} isOpen={expandedCustomerIds.indexOf(customer.quoteId) !== -1}/>
                </TableCell></TableRow>
               
                </React.Fragment>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={customers.length}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[5, 10, 25]}
      />
    </Card></div>
  );
};

UserJobsTable.propTypes = {
  className: PropTypes.string,
  customers: PropTypes.array.isRequired
};

export default UserJobsTable;
