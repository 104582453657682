import React from 'react';
import {useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Button,
  Card,
  CardContent,
  TextField,
  InputAdornment,
  SvgIcon,
  makeStyles
} from '@material-ui/core';
import { Search as SearchIcon } from 'react-feather';
import MyContext from 'src/MyContext'
import AuthHelperDialog from 'src/utils/AuthHelperDialog'

const useStyles = makeStyles((theme) => ({
  root: {},
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  }
}));



const Toolbar = ({ className, ...rest }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [userAuthDialog, setUserAuthDialog] = React.useState(false)


  function prepUserPhoneDict(users) {
    var pdict = {}
    users.map((item, idx) => {
      pdict[item.phone] = item 
      if (item.secondaryPhones && item.secondaryPhones.length > 0) {
        item.secondaryPhones.map((inner, inidx) => {
          pdict[inner] = item 
        })
      }
    })
    return pdict
  }

  function test(users) {
    var newData = {}
    users.map((item, idx) => {
      if (item.companyName == 'Transport Co' && item.firstName == 'Alan') {
        newData = item 
      }
    })
    
    navigate('/app/usersummary', {state: {customer: newData}})
  }

  return (
    <MyContext.Consumer>{context => (
    <div
      className={clsx(classes.root, className)}
      {...rest}
    >
      <AuthHelperDialog open={userAuthDialog}
      onClose={() => setUserAuthDialog(false)}
      alertTitle={'Phone Auth Helper'}
      alertBody={'Type the phone number of a user to look up their current auth code.'}
      alertCancelText={'Cancel'}
      alertConfirmText={'Confirm'}
      />
      <Box
        display="flex"
        justifyContent="space-between"
      >
        <Button
          color="primary"
          variant="contained"
          onClick={ () => {
            setUserAuthDialog(true)
          }}
        >
         Auth Helper
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={ () => {
            navigate('/app/newuser', {state: {'customer': {}, 'phoneDict': prepUserPhoneDict(context.users)}});
          }}
        >
          New User
        </Button>
      </Box>
     
    </div>
    )}</MyContext.Consumer>
  );
};

Toolbar.propTypes = {
  className: PropTypes.string
};

/**
 *                <Button
          color="primary"
          variant="contained"
          onClick={ () => {
            test(context.users);
          }}
        >
          test
        </Button>
 * 
 */

export default Toolbar;
