import React, {Component} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';


class EmailDialog extends Component {


    static defaultProps = {
        headerColor: 'black',
        bodyColor: 'black',
    };

    constructor(props) {
        super(props);

        this.state = {
            value: this.props.defaultValue,
            open: false,
            popRef: null,
            sortAscending: false,
            sortDescending: false,
            notificationType: '',
            notificationHeader: 'Pro Forma Invoice [' + this.props.routeString + ']',
            notificationBody: 'Hello ' + this.props.name + ',\n\nWe have prepared a quote for you, you will find the invoice attached to this message.\n\nPlease let us know if you have any questions or concerns.\n\nThanks, and we look forward to doing business with you!\n\n-The Garri Team'
        }

        this.topRef = null
    }

    componentDidMount() {
        //alert(this.props.defaultValue)
    }

    tryConfirm() {

      if (this.state.notificationBody == '') {
        alert('Message body cannot be emtpy.')
        return 
      }
        if (this.state.notificationHeader == '') {
          alert('Subject cannot be emtpy')
          return 
        }

      this.props.onConfirm(this.state)
    }

   

    render() {
        return (
            <Dialog
            open={this.props.open}
            onClose={() => this.props.onClose()}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{this.props.alertTitle}</DialogTitle>
            <DialogContent>
                
              <DialogContentText id="alert-dialog-description">
                {this.props.dialogWorking ? 'Performing Operation...' : this.props.alertBody}
                {this.props.dialogWorking ? <div style={{alignItems: 'center', justifyContent: 'center'}}><CircularProgress/></div> : <div/>}
              </DialogContentText>
          

        <div>
        <TextField
            autoFocus
            margin="dense"
            id="message"
            label="Email Subject"
            fullWidth
            value={this.state.notificationHeader}
            onChange={(e) => {
              this.setState({
                notificationHeader: e.target.value
              })
            }}
          />
          <TextField
            autoFocus
            multiline
            margin="dense"
            id="message"
            label="Email Body"
            fullWidth
            value={this.state.notificationBody}
            onChange={(e) => {
              this.setState({
                notificationBody: e.target.value
              })
            }}
          />
        </div>

            </DialogContent>
            <DialogActions>
              <Button onClick={() => this.props.onClose()} color="primary">
                {this.props.alertCancelText}
              </Button>
              <Button onClick={() => this.tryConfirm()} color="primary" autoFocus>
                {this.props.alertConfirmText}
              </Button>
            </DialogActions>
          </Dialog>
        )

    }
}
const headerStyle = {
    backgroundColor: 'rgb(67,67,67)', flexWrap: 'never', whiteSpace: 'noWrap'
}

/*   <div onClick={() => this.adjustSortDescending()} style={{ margin: 0, display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: this.state.sortDescending ? 'orange' : 'white', border: this.state.sortDescending ? '' : '1px solid black', borderRadius: 30, padding: 0, height: 30, padding: 5 }}>
                                <p>Descending</p>
                            </div>*/

/*
 * 
 * 
 *             */

export default EmailDialog;