import React, {useState} from 'react';
import { Link as RouterLink, useNavigate, useLocation} from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';

import LocationOnIcon from '@material-ui/icons/LocationOn';
import {
  Box,
  Button,
  Checkbox,
  Container,
  FormHelperText,
  Link,
  TextField,
  Typography,
  makeStyles,
  Grid,
  Select,
  MenuItem,
  Stepper,
  Step,
  StepLabel,
  CircularProgress
} from '@material-ui/core';
import Page from 'src/components/Page';
import parse from 'autosuggest-highlight/parse';
import throttle from 'lodash/throttle';
import Firebase from 'src/Firebase'
import MuiPhoneNumber from 'material-ui-phone-number'
import { parseWithOptions } from 'date-fns/fp';
import MyContext from 'src/MyContext';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { DataGrid, GridToolbar, GridToolbarContainer, GridToolbarColumnsButton, GridToolbarFilterButton, GridToolbarDensitySelector, GridToolbarExport } from '@mui/x-data-grid';
import { arrayOf } from 'prop-types';

const filter = createFilterOptions();
const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

const steps = ['Basic Information', 'Associated Yearly Plans',];

const autocompleteService = { current: null };
const geocoder = {current: null};



const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  }
}));


const NewOperationView = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  var refa = React.useRef();
  const {state} = useLocation();
  const [loading, setLoading] = React.useState(false);
  const [autoValues, setAutoValues] = React.useState({});
  const [selection, setSelection] = useState([]);
  const [allocations, setAllocations] = useState({});
  const [activeStep, setActiveStep] = useState(0);
  const [planSelection, setPlanSelection] = useState([]);
  const [bolLink, setBolLink] = useState(null);
  const [bolUpload, setBolUpload] = useState(false);
  const [bolName, setBolName] = useState(null);
  const [fileBucket, setFileBucket] = useState(null);
  const [warehouse, setWarehouse] = useState(null);


  const handleNext = () => {


    setActiveStep((prevActiveStep) => prevActiveStep + 1);

  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  function getPlanColumns() {
    return [
      {
        field: 'plan',
        headerName: 'Plan',
        width: 150,
        editable: false,
      },
      {
        field: 'sku',
        headerName: 'SKU',
        width: 150,
        editable: true,
      },
    ]
  }

  function getPlanRows(context) {
    let wh = context.planList
    return wh.map((item, idx) => {
      return {'id': item.planId, 'plan': item.name, 'sku': item.sku ? item.sku.name : '' }
    })
  }

  function getType(t) {
    if (t == 'date') {
      return 'date'
    } else if (t == 'number') {
      return 'number' 
    } else if (t == 'list' || t == 'custom') {
      return t
    } else {
      return null
    }
  }

  function updateAutoValues(k, v) {
    var o = autoValues
    o[k] = v 
    setAutoValues(o)
  }

  function getAutoOptions(context, k) {
    if (k in context.barleyInCustomObjects) {
      return Object.keys(context.barleyInCustomObjects[k]).map((key, id) => {
        return context.barleyInCustomObjects[k][key]
      })
    }
    return []
  }

  function compare2(a,b) {
    let as = a.sortNumCreate ? a.sortNumCreate : -1000
    let bs = b.sortNumCreate ? b.sortNumCreate : -1000

    if (as > bs) {
      return -1
    } else if (bs > as) {
      return 1
    } else {
      return 0
    }
}

  function renderContent(fieldsmain, touched, errors, handleBlur, handleChange, values, setFieldValue, context) {
    
    var fields = fieldsmain 

    fields.sort((a,b) => compare2(a,b))
    
    var columns = Math.floor(fields.length / 3)
    if (columns * 3 < fields.length) {
      columns += 1
    }
    var colrow = [...Array(columns).keys()]
    var rows = [0,1,2]
    return <div>
      {colrow.map((item, idx) => {
        return <div style={{display: 'flex', flexDirection: 'row'}}>
        {rows.map((inner, innerI) => {
          let fieldIdx = (3*item) + inner 

          if (fieldIdx < fields.length) {
            let tp = getType(fields[fieldIdx].fieldType)
            if (tp == 'custom') {

             return <Autocomplete
              value={autoValues[fields[fieldIdx].fieldKey]}
              fullWidth
              style={{margin: 10}}
              onChange={(event, newValue) => {
                if (typeof newValue === 'string') {
                  updateAutoValues(fields[fieldIdx].fieldKey, {name: newValue})
                  setFieldValue(fields[fieldIdx].fieldKey, {'customId': newValue.customId, 'name': newValue.name})

                } else if (newValue && newValue.inputValue) {
                  // Create a new value from the user input
                  updateAutoValues(fields[fieldIdx].fieldKey, {name: newValue.inputValue})
                  setFieldValue(fields[fieldIdx].fieldKey, {'customId':-1, 'name': newValue.inputValue})
                } else {
                  updateAutoValues(fields[fieldIdx].fieldKey, newValue)
                  if (newValue == null) {
                    setFieldValue(fields[fieldIdx].fieldKey, {'customId': '0', 'name': 'None'})
                  } else {
                    setFieldValue(fields[fieldIdx].fieldKey, {'customId': newValue.customId, 'name': newValue.name})
                  }
                }
                //console.log(event.target.value)
                //console.log(newValue)
              }}
              filterOptions={(options, params) => {
                const filtered = filter(options, params);
        
                // Suggest the creation of a new value
                if (params.inputValue !== '') {
                  filtered.push({
                    inputValue: params.inputValue,
                    name: `Add "${params.inputValue}"`,
                  });
                }
        
                return filtered;
              }}
              selectOnFocus
              clearOnBlur
              handleHomeEndKeys
              id="free-solo-with-text-demo"
              options={getAutoOptions(context, fields[fieldIdx].fieldKey)}
              getOptionLabel={(option) => {
                // Value selected with enter, right from the input
                if (typeof option === 'string') {
                  return option;
                }
                // Add "xxx" option created dynamically
                if (option.inputValue) {
                  return option.inputValue;
                }
                // Regular option
                return option.name;
              }}
              renderOption={(option) => option.name}
              freeSolo
              renderInput={(params) => (
                <TextField {...params}  error={Boolean(touched[fields[fieldIdx].fieldKey] && errors[fields[fieldIdx].fieldKey])}
                fullWidth
                id={fields[fieldIdx].fieldKey}
                helperText={touched[fields[fieldIdx].fieldKey] && errors[fields[fieldIdx].fieldKey]}
                label={fields[fieldIdx].fieldName}
                margin="normal"
                name={fields[fieldIdx].fieldKey}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values[fields[fieldIdx].fieldKey]}
                InputLabelProps={{
                  shrink: true,
                  }}
                variant="outlined"/>
              )}
            />



            } else if (tp == 'list') {
              let opts = fields[fieldIdx].listOptions
              return <TextField
              select 
              style={{margin: 10}}
              error={Boolean(touched[fields[fieldIdx].fieldKey] && errors[fields[fieldIdx].fieldKey])}
              fullWidth
              id={fields[fieldIdx].fieldKey}
              helperText={touched[fields[fieldIdx].fieldKey] && errors[fields[fieldIdx].fieldKey]}
              label={fields[fieldIdx].fieldName}
              margin="normal"
              name={fields[fieldIdx].fieldKey}
              onBlur={handleBlur}
              onChange={handleChange}
              value={values[fields[fieldIdx].fieldKey]}
              InputLabelProps={{
                shrink: true,
                }}
              variant="outlined">
              {opts.map((item, idx) => {
                return (<MenuItem value={item}>{item}</MenuItem>)
              })}
            </TextField>
            } else {
              return <TextField
              error={Boolean(touched[fields[fieldIdx].fieldKey] && errors[fields[fieldIdx].fieldKey])}
              fullWidth
              style={{margin: 10}}
              id={fields[fieldIdx].fieldKey}
              helperText={touched[fields[fieldIdx].fieldKey] && errors[fields[fieldIdx].fieldKey]}
              label={fields[fieldIdx].fieldName}
              margin="normal"
              name={fields[fieldIdx].fieldKey}
              onBlur={handleBlur}
              onChange={handleChange}
              value={values[fields[fieldIdx].fieldKey]}
              variant="outlined"
              type={getType(fields[fieldIdx].fieldType)}
              InputLabelProps={{
                shrink: true,
                }}
            />
            }

          }
          
          return null 

        })}
       </div>
      })}
    </div>
  }

  async function uploadImageByUri(uri, ftarg, mime = 'image/jpg') {

    const imageRef = Firebase.storage.ref('BOL-').child(ftarg)
    let aa = await imageRef.put(uri, { contentType: mime }).then(() => {
        return imageRef.getDownloadURL()
    }).then((url) => {
        return url
    })
        .catch((error) => {
            this.setState({
                isAuthenticating: false,
            });
            alert('Error uploading photos. Check internet connection')
            console.log("error" + error);
        })
    console.log('w' + aa)
    return aa
}


  async function handleSelectedFolder(event) {

   
    console.log(event.target.files)
    let fl = event.target.files[0]
    let fn = fl.name 
    var fbucket = null
    if (fl.type == 'application/pdf') {
      fbucket = Firebase.uid + '_' + Date.now() + '.pdf'
    } else {
      fbucket = Firebase.uid + '_' + Date.now() + '.jpg'
    }

    setBolUpload(true)
    let resv = await uploadImageByUri(event.target.files[0], fbucket, fl.type == 'application/pdf' ? fl.type : 'image/jpg')
    setFileBucket(fbucket)
    setBolUpload(false)
    setBolLink(resv)
    setBolName(fn)
    //updateRow(clickTarget, nf, 'weighbridgephoto')
    //this.uploadFile(targetFile)

}


  async function doNewPlanLocal(v) {
    var newVal = v 
    newVal['creationDate'] = Date.now()
    console.log(newVal)
    console.log(newVal.supplierid)
    
    let docRefNew = Firebase.firestore.collection('projects').doc(Firebase.userProject).collection('operations').doc()

    let newValId = docRefNew.id
    // check if we have to create any new field objects
    var customCreations = {}
    state.fields.map((field, idx) => {
      if (field.fieldType === 'custom') {
        if (field.fieldKey in newVal && newVal[field.fieldKey]['customId'] === -1) {
          // must create this val 
          customCreations[field.fieldKey] =  newVal[field.fieldKey]['name']
        }
      }
    })
    let arrayOfMemberIds = Object.keys(customCreations)
    console.log(arrayOfMemberIds)
    console.log(newVal)
    var oldNumericSave = null
    var newQueryId = null 
    var newQueryResult = null
    var errMessage = null 

      var t = Firebase.firestore.batch() 

      arrayOfMemberIds.forEach(function(memberId) {
        let docRefNext = Firebase.firestore.collection('projects').doc(Firebase.userProject).collection('barleyInCustomObjects').doc('contents').collection(memberId).doc()
        console.log('here t first')
        console.log(t)
        t = t.set(
          docRefNext,
          { name: customCreations[memberId] }
        )
        console.log('setting id of ' + docRefNext.id)
        newVal[memberId] = {'customId': docRefNext.id, 'name': customCreations[memberId]}
          
      });

      t.set(docRefNew ,newVal);

      let transaction = await t.commit().then(result => {
        
        console.log('Transaction success!' + result);
        return true
      }).catch(err => {
        console.log('Transaction failure:', err);
        errMessage = err.msg
        return false
      });
      return {'transaction': transaction, 'failureMessage': errMessage}
    
  }

  

    async function doSubmit(vv) {
      var v = vv 
      //v['allocations'] = allocations
      if (!v.name) {
        alert('Must specify a name.')
        return
      }
      if (!warehouse) {
        alert('Must specify associated facility.')
        return 
      }
      v['warehouse'] = warehouse
      v['yearlyPlans'] = planSelection
      v['bolFile'] = fileBucket
      v['bolName'] = bolName
      v['yearCodeForFiltering'] = '24'
      setLoading(true)
      //let transaction = await Firebase.createSku({'project': Firebase.userProject, 'values': v, 'fields': state.fields})
      let transaction = await doNewPlanLocal(v)

      setLoading(false)
      if (transaction && transaction.transaction == null && transaction.failureMessage) {
        alert(transaction.failureMessage)
      } else {
        navigate('/app/creationsummary', {state: {headerText: 'New Operation: ' + v.name, secondaryText: 'Successfully created operation.' }, replace: true})
        //navigate(-1)
      }
      console.log(transaction)

    }



    function getColumns() {
      return [
        {
          field: 'facility',
          headerName: 'Facility',
          width: 150,
          editable: false,
        },
        {
          field: 'stock',
          headerName: 'Stock',
          width: 150,
          editable: false,
        },
        {
          field: 'allocation',
          headerName: 'Allocation',
          type: 'number',
          width: 110,
          editable: true,
        },
      ]
    }

    function getRows(context, allocationValues) {

      let wh = getAutoOptions(context, 'warehouse') 
      /*console.log(row)
      if (row == null || row.sku == null || row.sku.name == null) {
        return [] 
      }
      let query = {'sku': row.sku.name}
      let dest = ''
      console.log(row)
      
      if (row.warehouse && row.warehouse.name) {
        dest = row.warehouse.name 
      } else {
        return []
      }
      console.log(query)
      let cdt = this.getAllByCategory(context.barleyStocksDict, context.barleyStocksPendingDict,
        context.barleyStocksTransitDict, this.getDictId(query), dest)
      
      return Object.keys(cdt).map((key, idx) => {
        let item = cdt[key]
        return {'stock': item, 'facility': key, 'allocation': key in allocationValues ? allocationValues[key] : 0, id: key}
      })*/
      return wh.map((item, idx) => {
        return {'id': item.customId, 'facility': item.name, 'stock': 0, 'allocation': item.name in allocationValues ? allocationValues[item.name] : 0 }
      })
  
    }

    function onCellChange(value/*, approvalInfo*/) {
      /*console.log(value)
      console.log(this.state.categoryDict[value.id])
  
      var allocationSum = 0
      Object.keys(this.state.allocationValues).map((key, idx) => {
        allocationSum += this.state.allocationValues[key]
      })
  
  
      let oldVal = (value.id in this.state.allocationValues) ? this.state.allocationValues[value.id] : 0
  
      allocationSum += (value.value - oldVal)
  
      console.log('allocationsum is ' + allocationSum)
  
      if (!(value.id in this.state.categoryDict) || this.state.categoryDict[value.id] < value.value) {
        var s = this.state.allocationValues
        s[value.id] = 0
        this.setState({
          allocationValues: s
        })
        console.log('reset')
      } else if (allocationSum > approvalInfo.row.netweightkg) {
        var s = this.state.allocationValues
        s[value.id] = 0
        this.setState({
          allocationValues: s
        })
        console.log('reset ' + approvalInfo.row.netweightkg)
      } else {
        var s = this.state.allocationValues
        s[value.id] = value.value
        this.setState({
          allocationValues: s
        })
      }*/
      var al = allocations
      al[value.id] = value.value 
      setAllocations(al)
  
    
    }
  


  return (
    <MyContext.Consumer>{context => (
  <Page
    className={classes.root}
    height='100%'
    title="Request Quote"
    id="ff2"
    backgroundColor={classes.root.backgroundColor}
  >
    <Box
      display="flex"
      flexDirection="column"

      id="bb2"

      backgroundColor={classes.root.backgroundColor}

    >
      <Container id="cc2" backgroundColor={classes.root.backgroundColor}>
        <Formik
        id='f2'
          initialValues={{

          }}
          validationSchema={
            Yup.object().shape({

            })
          }
          onSubmit={(values) => {
            doSubmit(values)
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting2,
            setSubmitting, 
            touched,
            values,
            setFieldValue
          }) => (
            <form id='fu2' onSubmit={handleSubmit}>
                  <div id='fkx1' style={{marginTop: 10}}>
              <Box mb={3}>
                <Typography
                  color="textPrimary"
                  variant="h2"
                >
                  Create New Vessel Operation
                </Typography>
                

              </Box>

              <Stepper activeStep={activeStep} style={{width: '100%', marginBottom: 15}}>
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>

            </Step>
          );
        })}
      </Stepper>
          
      {activeStep == 0 ?
<div style={{width: '45%'}}>   {renderContent(state.fields.filter((item, idx) => {
return item.fieldKey != 'grn'  && item.fieldKey != 'creationDate' && item.fieldKey != 'bolFile'
}), touched, errors, handleBlur, handleChange, values, setFieldValue, context)} 

<Autocomplete
  id="warehouse"
  disabled={false}
  options={getAutoOptions(context, 'warehouse').filter((item, idx) => {
    return item && item.warehousetype == 'Port'
  })}
  getOptionLabel={(option) => option.union ? option.name + ' / ' + option.union : option.name}
  value={warehouse}
  onChange={(e, value) => {   
    if (value != null) {
      setWarehouse(value)
    }
  }}
  renderInput={(params) => <TextField {...params}
  disabled={false}
  label="Warehouse"
  margin="normal"
  id="warehouse"
  name="warehouse"
  variant="outlined"
  value={warehouse}
  defaultValue={warehouse} />}
/>
</div>
: null}

{activeStep == 1 ?
  <div style={{height: 300, width: 600}}>
  <Typography
      color="textPrimary"
      variant="h5"
    >
      Associated Yearly Plan(s)
    </Typography>
    <DataGrid
key="yplanalloc"
style={{backgroundColor: 'white'}}
rows={getPlanRows(context)}
columns={getPlanColumns()}
pageSize={5}
rowsPerPageOptions={[5]}
hideFooterPagination={false}
hideFooter={false}
checkboxSelection={true}
disableSelectionOnClick
selectionModel={planSelection}
onSelectionModelChange={(newSelection) => {
setPlanSelection(newSelection);
}}
/>
</div>
: null}


</div>

{activeStep == 0 
?
<div style={{flexDirection: 'row'}}>
<input id="myInputFolder" accept="image/png, image/jpeg, image/jpg, application/pdf" ref={(ref) => refa = ref} style={{ display: 'none' }} type="file" onChange={handleSelectedFolder} />
<Button
color="primary"
disabled={loading}
size="large"
id="sendButtonNext"
variant="contained"
onClick={() => refa.click()}
style={{margin: 5, width: 150, marginTop: 20}}
>
Upload BOL
</Button>

{bolUpload ? <CircularProgress/> : null}

{bolLink != null && !bolUpload ? <Link
            to={bolLink}
            onClick={ async () => {
              let b = await Firebase.getSignedUrl({'bucket': 'BOL-', 'file': fileBucket}) 
              if (b && b.length && b.length > 0) {
                window.open(b[0])
              } else {
                alert('Error retrieving file.')
              }
            }}
            variant="h6"
          >{bolName}</Link> : null}
</div>
: null
}


              <Button
  color="primary"
  disabled={activeStep == 0}
  size="large"
  id="sendButtonNext"
  variant="contained"
  onClick={handleBack}
  style={{margin: 5, width: 150, marginTop: 20}}
>
  Back
</Button>



{activeStep < steps.length - 1 ? 
  <Button
  color="primary"
  disabled={loading}
  size="large"
  id="sendButtonNext"
  variant="contained"
  onClick={handleNext}
  style={{margin: 5, width: 150, marginTop: 20}}
>
  Next
</Button>
: 
<Box my={2} style={{alignSelf: 'center', flexDirection: 'column', display: 'flex'}}>

<Button
color="primary"
style={{margin: 5, width: 150}}
disabled={loading }
id="sendButtonNext"
variant="contained"
onClick={() => doSubmit(values)}
>
Confirm
</Button>
</Box>
}

              {loading ? <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: 50}}><CircularProgress/></div> : null}

              
            </form>
          )}
        </Formik>
      </Container>
    </Box>
  </Page>
        )}</MyContext.Consumer>
  )
};

export default NewOperationView;
