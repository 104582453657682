import React, {useEffect} from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {useNavigate } from 'react-router-dom';
import {
  Avatar,
  Box,
  Card,
  CardContent,
  Grid,
  Typography,
  colors,
  makeStyles
} from '@material-ui/core';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import AssignmentIcon from '@material-ui/icons/Assignment';


const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%'
  },
  avatar: {
    backgroundColor: colors.green[600],
    height: 56,
    width: 56
  },
  differenceIcon: {
    color: colors.green[900]
  },
  differenceValue: {
    color: colors.green[900],
    marginRight: theme.spacing(1)
  }
}));

const DashboardCard = ({ className, stateProp, title, count, bodyText, colorProp, navTarget, tableTarget, tableContext, ...rest }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <CardContent onClick={() => {
        if (tableContext) {
          let filters = [{ key: 'state', condition: 't', target: tableTarget, name: 'Status' }]
          tableContext.updateFunc('jobsFilters', filters)
          navigate(navTarget)
        } else {
          navigate(navTarget)
        }

      }}>
        <Grid
          container
          justify="space-between"
          spacing={1}
        >
          <Grid item>
            <Typography
              color="textSecondary"
              gutterBottom
              variant="h6"
            >
              {title}
            </Typography>
            <Typography
              color="textPrimary"
              variant="h3"
            >
              {count}
            </Typography>
          </Grid>
          <Grid item>
            <Avatar style={{backgroundColor: colorProp}} className={classes.avatar}>
              <AssignmentIcon />
            </Avatar>
          </Grid>
        </Grid>
        <Box
          mt={2}
          display="flex"
          alignItems="center"
        >
          <Typography
            className={classes.differenceValue}
            variant="body2"
          >
            {''}
          </Typography>
          <Typography
            color="textSecondary"
            variant="caption"
          >
            {bodyText}
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
};

DashboardCard.propTypes = {
  className: PropTypes.string
};

export default DashboardCard;
