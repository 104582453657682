import React, {Component} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import MuiPhoneNumber from 'material-ui-phone-number'
import Firebase from 'src/Firebase'
import ChipInput from 'material-ui-chip-input'



class AuthDialog extends Component {


    static defaultProps = {
        headerColor: 'black',
        bodyColor: 'black',
    };

    constructor(props) {
        super(props);

        let sfz = this.props.documentName
        this.state = {
            value: this.props.defaultValue,
            open: false,
            popRef: null,
            sortAscending: false,
            sortDescending: false,
            notificationType: '',
            notificationHeader: '',
            notificationBody: sfz,
            phoneNumber: '',
            listChips: [],
            source: null,
            approvalInfo: this.props.approveInfo,
            password: '',
            targetName: this.props.targetName,
        }

        this.topRef = null
    }

    componentWillReceiveProps(nextProps) {
      // You don't have to do this check first, but it can help prevent an unneeded render
      if (nextProps.targetName !== this.state.targetName) {
        this.setState({ targetName: nextProps.targetName});
      }
    }

    componentDidMount() {
        //alert('mounted')
    }

    tryConfirm() {
      this.props.onConfirm(this.state)
      this.props.onClose()
    }


    handleAddChip(chip) {
      var chips = this.state.listChips
      this.setState({
          listChips: chips.concat(chip),
          chipAdded: true 
      })
  }

  handleDeleteChip(chip, idx) {
      let chips = this.state.listChips.filter((item, ii) => {
          return (ii != idx)
      })

      this.setState({
          listChips: chips
      })
  }

 getAutoOptions(context, k) {
    if (k in context.barleyInCustomObjects) {
      return Object.keys(context.barleyInCustomObjects[k]).map((key, id) => {
        return context.barleyInCustomObjects[k][key]
      })
    }
    return []
  }

  getDictId(dct) {
    var keys = Object.keys(dct)
    keys.sort()
    var s = ''
    for (var i = 0; i < keys.length; i++) {
      let key = keys[i]
      if (i > 0) {
        s += '-'
      }
      s += key 
      s += ':'
      s += dct[key]
    }

    return s 
  }

  getBarleyStockTitle(item) {
    var variety = ''
    var purity = ''
    var moisture = ''
    if (item.variety == 'other') {
      variety = 'All other varieties'
    } else {
      variety = item.variety 
    }
    if (item.uncategorized) {
      return 'Uncategorized'
    } else if (item.moisture == 'above') {
      return variety + ' // Variety all purity // Moisture > 15%'
    } else if (item.variety == 'other') {
      return variety + ' // Variety all purity // Moisture <= 15%'
    } else {
      if (item.purity == 'above') {
        purity = 'Variety purity >= 90%'
      } else if (item.purity == 'below') {
        purity = 'Variety purity < 90%'
      } 
      return variety + ' // ' + purity + ' // Moisture <=15%'
    }
  }

    render() {
        return (
            <Dialog
            open={this.props.open}
            onClose={() => this.props.onClose()}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{'Set Password for ' + this.state.targetName}</DialogTitle>
            <DialogContent>

<TextField
            autoFocus
            margin="dense"
            label="New Password"
            fullWidth
            placeholder={''}
            defaultValue={''}
            value={this.state.password}
            InputLabelProps={{
              shrink: true,
              }}
            onChange={(e) => {
              this.setState({
                password: e.target.value
              })
              
            }}
          />

            </DialogContent>
            <DialogActions>
              <Button onClick={() => this.props.onClose()} color="primary">
                {this.props.alertCancelText}
              </Button>
              <Button disabled={this.state.password == null || this.state.password.length < 8} onClick={() => this.tryConfirm(this.state.password)} color="primary" autoFocus>
                {this.props.alertConfirmText}
              </Button>
            </DialogActions>
          </Dialog>
        )

    }
}
const headerStyle = {
    backgroundColor: 'rgb(67,67,67)', flexWrap: 'never', whiteSpace: 'noWrap'
}

/*   <div onClick={() => this.adjustSortDescending()} style={{ margin: 0, display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: this.state.sortDescending ? 'orange' : 'white', border: this.state.sortDescending ? '' : '1px solid black', borderRadius: 30, padding: 0, height: 30, padding: 5 }}>
                                <p>Descending</p>
                            </div>*/

/*
 * 
 * 
 *             */

export default AuthDialog;