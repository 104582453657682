import React, {useState} from 'react';
import { Link as RouterLink, useNavigate, useLocation} from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import Autocomplete from '@material-ui/lab/Autocomplete';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import {
  Box,
  Button,
  Checkbox,
  Container,
  FormHelperText,
  Link,
  TextField,
  Typography,
  makeStyles,
  Grid,
  Select,
  MenuItem,
  CircularProgress
} from '@material-ui/core';
import Page from 'src/components/Page';
import parse from 'autosuggest-highlight/parse';
import throttle from 'lodash/throttle';
import Firebase from 'src/Firebase';
import DataFetcher from 'src/DataFetcher';
import JobMasterView from 'src/utils/JobMasterView';
var moment = require('moment');


const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

function loadScript(src, position, id) {
  if (!position) {
    return;
  }

  const script = document.createElement('script');
  script.setAttribute('async', '');
  script.setAttribute('id', id);
  script.src = src;
  position.appendChild(script);
}

const autocompleteService = { current: null };
const geocoder = {current: null};



const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  }
}));


const EvaluateBidView = () => {
  const classes = useStyles();
  const navigate = useNavigate();


  const [value, setValue] = React.useState(null);
  const [valueTwo, setValueTwo] = React.useState(null);
  const [inputValue, setInputValue] = React.useState('');
  const [options, setOptions] = React.useState([]);
  const [optionsTwo, setOptionsTwo] = React.useState([]);
  const [numVal, setNumVal] = React.useState(false);
  const [actions, setActions] = React.useState(null);
  const [locationStart, setLocationStart] = React.useState(null);
  const [locationEnd, setLocationEnd] = React.useState(null);
  const [finalSet, setFinalSet] = React.useState(null);
  const [tableItems, setTableItems] = React.useState(DataFetcher.cargoTypeData);
  const [isAuthenticating, setIsAuthenticating] = React.useState(false);

  const {state} = useLocation();

  const loaded = React.useRef(false);
  if (typeof window !== 'undefined' && !loaded.current) {
    if (!document.querySelector('#google-maps')) {
      loadScript(
        'https://maps.googleapis.com/maps/api/js?key=AIzaSyAkPLP3zHG-2kctVakLZwdUQHqeLVO0Onw&libraries=places',
        document.querySelector('head'),
        'google-maps',
      );
    }

    loaded.current = true;
  }

  function doSubmit(values, b, c, d) {


    console.log('get fired once')
    //return 
 
    if (d) {
      doAccept()
    } else {
      doReject()
    }
    return

    /*setIsAuthenticating(true)
    console.log(values)
    console.log(Firebase.userInfo)

    var v = values 
    v['quoteId'] = state.quoteId
    let suc = function () {
      setIsAuthenticating(false)
      navigate(-1)
    }

    let f  = function () {
      setIsAuthenticating(false)
      alert('Error saving changes. Check internet connection and try again.')
    }
    Firebase.editQuoteAuth(v, locationStart, locationEnd, Firebase.uid, suc, f)*/
  }

  function doAccept() {

    var v = {'quoteId': state.customer.quoteId, 'transporterId': state.customer.bidder, 'transporterName': state.customer.bidderName, 'acceptedBidAmount': state.customer.bidAmount,
    'fromAdmin': true}
    Firebase.acceptBid(v)
    navigate(-1)
  }

  function doReject() {
    var v = {'quoteId': state.customer.quoteId,  'hasBid': false, 'userId': Firebase.uid, 'biddingTime': true}
    //alert(JSON.stringify(v))
    //1return
    console.log(v)
    let suc = function () {
      setIsAuthenticating(false)
      navigate(-1)
    }

    let f  = function () {
      setIsAuthenticating(false)
      alert('Error saving changes. Check internet connection and try again.')
    }
    Firebase.editQuoteSimple(v, suc, f)
    //navigate(-1)
  }

  function mathRound(num) {
    if (num < 0.01) {
      return num 
    }

    return Math.round((num + Number.EPSILON) * 100) / 100
  }


  const fetch = React.useMemo(
    () =>
      throttle((request, callback) => {
        autocompleteService.current.getPlacePredictions(request, callback);
      }, 200),
    [],
  );

  React.useEffect(() => {
    let active = true;

    console.log('state')
    console.log(state)

    setLocationStart(state.startPos)
    setLocationEnd(state.endPos)

    console.log(moment(state.endDateEarly).format('yyyy-MM-DDThh:mm'))
    if (!autocompleteService.current && window.google) {
      autocompleteService.current = new window.google.maps.places.AutocompleteService();
      geocoder.current = new window.google.maps.Geocoder();
      console.log(autocompleteService.current)
    }
    if (!autocompleteService.current || !geocoder.current) {
      return undefined;
    }

    if (inputValue === '') {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetch({ input: inputValue }, (results) => {

      console.log(results)
      if (active) {
        let newOptions = [];

        if (value) {
          newOptions = [value];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      }
    });

    if (typeof window !== 'undefined' && typeof Firebase.auth !== 'undefined') {
      console.log('dothis')
      window.recaptchaVerifier = new Firebase.firebaseRef.auth.RecaptchaVerifier('get-quote-button', {
        'size': 'invisible',
        'callback': function(response) {
          // reCAPTCHA solved, allow signInWithPhoneNumber.
          //phoneVerify(2);
          alert('solved')
        }
      });
    }


    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);

  if (isAuthenticating) {
    return (<Page
      className={classes.root}
      title="Request Quote"
      backgroundColor={classes.root.backgroundColor}
    ><div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}><CircularProgress/></div></Page>)
  }

  return (<JobMasterView editQuoteShipper={false} adminEdit={false} provideQuote={false} privateQuote={false} publicQuote={false} quoteOffer={false} evaluateBid={true} parentSubmit={(v, ls, le, d) => doSubmit(v, ls, le, d)} parentState={state} jobProps={{editable: false, priceEditable: true, showPrices: true, showAssignments: false}} />)


  return (
    <Page
      className={classes.root}
      title="Request Quote"
      backgroundColor={classes.root.backgroundColor}
    >
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        backgroundColor={classes.root.backgroundColor}
 
      >
        <Container maxWidth="sm" backgroundColor={classes.root.backgroundColor}>
          <Formik
            initialValues={{
              price: state.price,
              cargoName: state.cargo,
              pickupLocation: state.startName,
              dropoffLocation: state.endName,
              earlyDrop: moment(state.endDateEarly).format('yyyy-MM-DDThh:mm'),
              earlyPick: moment(state.startDateEarly).format('yyyy-MM-DDThh:mm'),
              transporterPrice: state.transporterPrice,
              cargoType: state.cargoType,
              cargoFields: state.cargoFields,
              trueWeight: state.trueWeight,
              pricePerQuintal: state.price && state.trueWeight ? mathRound(state.price / (state.trueWeight * 10)) : 0
            }}
            validationSchema={
              Yup.object().shape({
                cargoName: Yup.string().max(255).required('Cargo is required'),
                pickupLocation: Yup.string().max(255).required('Pickup location is required'),
                dropoffLocation: Yup.string().max(255).required('Dropoff location is required'),
                earlyPick: Yup.string().max(255).required('Pickup and dropoff times are required.'),
                latePick: Yup.string().max(255).required('Pickup and dropoff times are required.'),
                earlyDrop: Yup.string().max(255).required('Pickup and dropoff times are required.'),
                lateDrop: Yup.string().max(255).required('Pickup and dropoff times are required.'),
                cargoType: Yup.string().max(255).required('Cargo type is required'),
                trueWeight: Yup.number().min(.00001).required('Weight (metric tons) is required')
              })
            }
            onSubmit={(values, acts) => {
              //alert('tt')
              //console.log('sub')
              //setNumVal(false)
              doSubmit(values)
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
              setFieldValue
            }) => (
              <form onSubmit={handleSubmit}>
                <Box mb={3}>
                  <Typography
                    color="textPrimary"
                    variant="h2"
                  >{'Quote #' + state.quoteId}
                  </Typography>
                  <Typography
                    color="textPrimary"
                    variant="h2"
                  >{'Current Bid: ' + state.bidAmount}
                  </Typography>
                </Box>
                <TextField disabled={true}
                  error={Boolean(touched.cargoName && errors.cargoName)}
                  fullWidth
                  helperText={touched.cargoName && errors.cargoName}
                  label="Cargo"
                  margin="normal"
                  name="cargoName"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.cargoName}
                  variant="outlined"
                />
 <TextField
 disabled
                  error={Boolean(touched.phone && errors.phone)}
                  fullWidth
                  label="Price"
                  margin="normal"
                  name="price"

                  onChange={(e) => {
                    if (values.trueWeight <= 0) {
                      alert('Weight cannot be zero or less.')
                    } else {
                      setFieldValue('price', e.target.value)
                      setFieldValue('pricePerQuintal', mathRound(e.target.value / (values.trueWeight * 10)))
                    }
                    
                  }}
                  type="number"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={values.price}
                  variant="outlined"
                />
                 <TextField
                 disabled
                  error={Boolean(touched.phone && errors.phone)}
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                  label="Price/Quintal"
                  margin="normal"
                  name="price/quintal"

                  onChange={(e) => {
                    if (values.trueWeight <= 0) {
                      alert('Weight cannot be zero or less.')
                    } else {
                      setFieldValue('price', e.target.value * (values.trueWeight * 10))
                      setFieldValue('pricePerQuintal', e.target.value)
                    }
                    
                  }
                    }
                  type="number"
                  value={values.pricePerQuintal}
                  variant="outlined"
                />
                 <TextField
 disabled
                  error={Boolean(touched.phone && errors.phone)}
                  fullWidth
                  label="Price Offered to Transporter"
                  margin="normal"
                  name="transporterPrice"

                  onChange={handleChange}
                  type="number"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={values.transporterPrice}
                  variant="outlined"
                />
                 <Select
          fullWidth
          disabled 
          label="Cargo Type"
          margin="normal"
          id="cargoType"
          name="cargoType"
          variant="outlined"
          value={values.cargoType}
          onChange={(e) => {
            console.log(e)
            setFieldValue('cargoType', e.target.value)
            //setFieldValue('cargoTypeDisp', e.target.value.name)
          }}
        >
          {Object.keys(tableItems).map((item, idx) => {
            return (<MenuItem value={tableItems[item].val}>{tableItems[item].name}</MenuItem>)
          })}

        </Select>
        {values.cargoType != '' ? <div>{Object.keys(tableItems[values.cargoType].fields).map((item, idx) => {
            return <TextField
            disabled
            error={Boolean(touched.cargoName && errors.cargoName)}
            fullWidth
            helperText={touched.cargoName && errors.cargoName}
            label={tableItems[values.cargoType].fields[item].name}
            margin="normal"
            name={tableItems[values.cargoType].fields[item].val}
            onBlur={handleBlur}
            onChange={(e) => {
              var temp = values.cargoFields
              temp[item] = e.target.value 
              setFieldValue('cargoFields', temp)
            }}
            value={values.cargoFields[item]}
            variant="outlined"
          />
          })}</div>: <div/>}
           <TextField
           disabled
                  error={Boolean(touched.trueWeight && errors.trueWeight)}
                  fullWidth
                  helperText={touched.trueWeight && errors.trueWeight}
                  label="Weight (Metric Tons)"
                  margin="normal"
                  name="trueWeight"
                  type="number"
                  onBlur={handleBlur}
                  onChange={(e) => {
                    if (e.target.value <= 0) {
                      alert('Weight cannot be zero or less.')
                    } else {
                      setFieldValue('trueWeight', e.target.value)
                      setFieldValue('pricePerQuintal', mathRound(values.price / (e.target.value * 10)) )
                    } 
                  }
                  }
                  value={values.trueWeight}
                  variant="outlined"
                />
                <Autocomplete
                disabled={true}
      id="pickupLocation"
      name="pickupLocation"
      style={{ width: '100%' }}
      getOptionLabel={(option) => (typeof option === 'string' ? option : option.description)}
      filterOptions={(x) => x}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={values.pickupLocation}
      onChange={(event, newValue) => {
        setOptions(newValue ? [newValue, ...options] : options);
        
        setValue(newValue);
        if (geocoder.current != null && newValue != null) {
          geocoder.current.geocode({ 
            'placeId': newValue.place_id
        }, 
        function(responses, status) {
            if (status == 'OK') {
                var lat = responses[0].geometry.location.lat();
                var lng = responses[0].geometry.location.lng();
                setLocationStart({'lat': lat, 'lng': lng})
                console.log(lat, lng);
            }
        });
        }
        setFieldValue('pickupLocation', newValue.description)

      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => (
        <TextField disabled={true} {...params} label="Dropoff Location" variant="outlined" margin="normal" fullWidth onBlur={handleBlur}
        name="dropoffLocation"
        error={Boolean(touched.dropoffLocation && errors.dropoffLocation)}
        value={values.dropoffLocation}/>
      )}
      renderOption={(option) => {
        const matches = option.structured_formatting.main_text_matched_substrings;
        const parts = parse(
          option.structured_formatting.main_text,
          matches.map((match) => [match.offset, match.offset + match.length]),
        );

        return (
          <Grid container alignItems="center">
            <Grid item>
              <LocationOnIcon className={classes.icon} />
            </Grid>
            <Grid item xs>
              {parts.map((part, index) => (
                <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                  {part.text}
                </span>
              ))}

              <Typography variant="body2" color="textSecondary">
                {option.structured_formatting.secondary_text}
              </Typography>
            </Grid>
          </Grid>
        );
      }}
    />
                <Grid container justify="space-between">
                      <TextField disabled={true}
        label="Earliest Pickup"
        type="datetime-local"
        variant="outlined"
        margin="normal"
        name="earlyPick"
        onBlur={handleBlur}
        onChange={handleChange}
        value={values.earlyPick}
        error={Boolean(touched.earlyPick && errors.earlyPick)}
        className={classes.textField}
        InputLabelProps={{
          shrink: true,
        }}
      />
         </Grid>
<Autocomplete
      id="dropoffLocation"
      name="dropoffLocation"
      disabled={true}
      style={{ width: '100%' }}
      getOptionLabel={(option) => (typeof option === 'string' ? option : option.description)}
      filterOptions={(x) => x}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={values.dropoffLocation}
      onChange={(event, newValue) => {
        setOptions(newValue ? [newValue, ...options] : options);
        
        setValueTwo(newValue);
        if (geocoder.current != null && newValue != null) {
          geocoder.current.geocode({ 
            'placeId': newValue.place_id
        }, 
        function(responses, status) {
            if (status == 'OK') {
                var lat = responses[0].geometry.location.lat();
                var lng = responses[0].geometry.location.lng();
                setLocationEnd({'lat': lat, 'lng': lng})
                console.log(lat, lng);
            }
        });
        }
       
        setFieldValue('dropoffLocation', newValue.description)
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => (
        <TextField disabled={true} {...params} label="Pickup Location" variant="outlined" margin="normal" fullWidth onBlur={handleBlur}

        name="pickupLocation"
        error={Boolean(touched.pickupLocation && errors.pickupLocation)}
        value={values.pickupLocation}/>
      )}
      renderOption={(option) => {
        const matches = option.structured_formatting.main_text_matched_substrings;
        const parts = parse(
          option.structured_formatting.main_text,
          matches.map((match) => [match.offset, match.offset + match.length]),
        );

        return (
          <Grid container alignItems="center">
            <Grid item>
              <LocationOnIcon className={classes.icon} />
            </Grid>
            <Grid item xs>
              {parts.map((part, index) => (
                <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                  {part.text}
                </span>
              ))}

              <Typography variant="body2" color="textSecondary">
                {option.structured_formatting.secondary_text}
              </Typography>
            </Grid>
          </Grid>
        );
      }}
    />

                          
      <Grid container justify="space-between">
              <TextField disabled={true}
        label="Earliest Dropoff"
        type="datetime-local"
        margin="normal"
        variant="outlined"
        name="earlyDrop"
        onBlur={handleBlur}
        onChange={handleChange}
        className={classes.textField}
        error={Boolean(touched.earlyDrop && errors.earlyDrop)}
        value={values.earlyDrop}
        InputLabelProps={{
        shrink: true,
        }}
        />
       </Grid>
                
                {Boolean(touched.policy && errors.policy) && (
                  <FormHelperText error>
                    {errors.policy}
                  </FormHelperText>
                )}
                <Box my={2}>
                  <Button
                    color="primary"
                    disabled={isSubmitting}
                    fullWidth
                    size="large"
                    id="get-quote-button"
                    type="submit"
                    variant="contained"
                    onClick={() => doAccept()}
                  >
                    {'Accept Bid: ' + state.bidAmount}
                  </Button>
                  </Box>
                  <Box my={2}>
                  <Button
                    color="primary"
                    disabled={isSubmitting}
                    fullWidth
                    size="large"
                    id="get-quote-button"
                    type="submit"
                    variant="contained"
                    onClick={() => doReject()}
                  >
                    Reject Bid
                  </Button>
                </Box>
                
              </form>
            )}
          </Formik>
        </Container>
      </Box>
    </Page>
  );
};

export default EvaluateBidView;
