import React, { useState } from 'react';
import {useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Avatar,
  Box,
  Card,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  makeStyles,
  Button,
  Link
} from '@material-ui/core';
import getInitials from 'src/utils/getInitials';
import {X} from 'react-feather';
import HeaderComponent from 'src/utils/HeaderComponent';
import SearchWrapper from 'src/utils/SearchWrapper';
import TableAssist from 'src/utils/TableAssist';
import AddDriverDialog from 'src/utils/AddDriverDialog'
import AlertDialog from 'src/utils/AlertDialog'
import Firebase from 'src/Firebase'


const searchOptions = {
  'plate': {
    'name': 'Plate',
    'val': 'plate'
},
  'truckType': {
      'name': 'Truck Type',
      'val': 'truckType'
  },
  'vin': {
      'name': 'VIN',
      'val': 'vin'
  },

}

const useStyles = makeStyles((theme) => ({
  root: {},
  avatar: {
    marginRight: theme.spacing(2)
  }
}));

const UserVehiclesTable = ({ className, customers, targetTransporter, ...rest }) => {
  const classes = useStyles();
  const [selectedCustomerIds, setSelectedCustomerIds] = useState([]);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);
  const [addDriverAlertOpen, setAddDriverAlertOpen] = useState(false);
  const [addDriverDialogWorking, setAddDriverAlertWorking] = useState(false);
  const [deleteAlertOpen, setDeleteAlertOpen] = useState(false);
  const [deleteDialogWorking, setDeleteDialogWorking] = useState(false);
  const [text_filters, set_text_filters] = useState([]);
  const [sortOrder, setSortOrder] = useState('creationDate');
  const [sortDirection, setSortDirection] = useState(false);
  const navigate = useNavigate();

  const handleSelectAll = (event) => {
    let newSelectedCustomerIds;

    if (event.target.checked) {
      newSelectedCustomerIds = customers.map((customer) => customer.vehicleId);
    } else {
      newSelectedCustomerIds = [];
    }

    setSelectedCustomerIds(newSelectedCustomerIds);
  };

  function activateTextFilter(val) {
    var oldFilters = text_filters
    //console.log('old text filters : ' + JSON.stringify(oldFilters))
    var found_filter = false
    oldFilters = oldFilters.map((item, idx) => {
        if (item.name == val.name) {
            found_filter = true
            item.target = val.target
            return val
        }
        return item
    })
    //console.log('oldbeforeadding')
    if (!found_filter) {
        oldFilters.push(val)
    }
    console.log('new text filters : ' + JSON.stringify(oldFilters))
    set_text_filters(oldFilters)
  
  }
  
  function deactivateTextFilter(val) {
    let newFilters = text_filters.filter((item, idx) => {
        return (item.key != val)
    })
  
    set_text_filters(newFilters)
  }
  
  function updateSortDirection(v, d) {
  setSortOrder(v)
  setSortDirection(d)
  }
  
  function applySecondaryFilters(items, filters) {
  var returnList = items.filter((v, idx) => {
      let item = v
      var returnItem = true 
      filters.map((filter, filterIdx) => {
          if (filter.condition == '>') {
              if (filter.key in item && item[filter.key] > filter.target) {
                  //returnItem = true
              } else {
                  returnItem = false
              }
          } else if (filter.condition == '<') {
              if (filter.key in item && item[filter.key] < filter.target) {
                  //returnItem = true
              } else {
                returnItem = false
              }
          } else if (filter.condition == '=') {
              if (filter.key in item && item[filter.key] === filter.target) {
                  //returnItem = true
              } else {
                returnItem = false
              }
          } else if (filter.condition == 't') {
            
              if (filter.key in item || filter.key == 'name' || filter.key == 'verified') {
  
                  let itemSet = TableAssist.fieldFormat(item, filter.key,1) 
                  console.log(itemSet)
                  console.log(filter.key)
                  console.log(item.verified)
                  let itemData = itemSet.toUpperCase();
  
                  let textData = filter.target.toUpperCase();
  
                  if (itemData.indexOf(textData) > -1) {
                      //returnItem = true
                  } else {
                    returnItem = false
                  }
  
              } else {
                //console.log(item)
                //console.log('not in' + filter.key)
                returnItem = false
              }
          } else if (filter.condition == 'd') {
              if (filter.key in item && item[filter.key] in filter.target) {
                  //returnItem = true
              } else {
                returnItem = false
              }
  
          }
      })
  
  
      return returnItem
  })
  
  return returnList
  }
  
  function compare2(aa, bb, key, reverse) {
        
    let a = aa//TableHelper.getSortValue(aa, key)
    let b = bb//TableHelper.getSortValue(bb, key)
    
    console.log('do a comparison of ' + a[key] + ' to ' + b[key])
    let aval = TableAssist.fieldFormat(a, key, 1)
    let bval = TableAssist.fieldFormat(b, key, 1)
    if (aval < bval) {
        return -1 * ((2 * reverse) - 1);
    }
    if (aval > bval) {
        return ((2 * reverse) - 1);
    }
    return 0;
    }
  
  function sortItems(v, s) {
  //console.log('try sorting')
  //console.log(v)
  //console.log(s)

  var o = v
  o['name'] = renderUserName(v)
  if (s == 0) {
      return o
  }
  else if (s == 1) {
      o.sort((a, b) => compare2(a, b, s, sortDirection))
  } else {
      o.sort((a, b) => compare2(a, b, s, sortDirection))
  }
  
  //alert(JSON.stringify(o))
  //return o
  if (text_filters.length > 0) {
    return applySecondaryFilters(o, text_filters)
  }
  return o
  }

  const handleSelectOne = (event, id) => {
    const selectedIndex = selectedCustomerIds.indexOf(id);
    let newSelectedCustomerIds = [];

    if (selectedIndex === -1) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(selectedCustomerIds, id);
    } else if (selectedIndex === 0) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(selectedCustomerIds.slice(1));
    } else if (selectedIndex === selectedCustomerIds.length - 1) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(selectedCustomerIds.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(
        selectedCustomerIds.slice(0, selectedIndex),
        selectedCustomerIds.slice(selectedIndex + 1)
      );
    }

    setSelectedCustomerIds(newSelectedCustomerIds);
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };


  function renderVerified(x) {
    if (x.verified) {
      return 'Verified'
    } else {
      return 'Unverified'
    }
  }
  
  function renderUserName(v) {
    var rets = ''
    if ('firstName' in v) {
      rets += v.firstName
    } 
    if ('middleName' in v) {
      rets += ' ' + v.middleName
    } 
    if ('lastName' in v) {
      rets += ' ' + v.lastName
    }
    return rets
  }

  function trueSelectedCustomerIds() {
    if (text_filters.length > 0) {
      return applySecondaryFilters(customers, text_filters).map((item, idx) => {
        return item.vehicleId
      }).filter((item, idx) => {
        return selectedCustomerIds.includes(item)
      })
    } else {
      return selectedCustomerIds
    }
  }


  return (
<div style={{marginLeft: 10}}>

<SearchWrapper defaultSearchOption={'plate'} defaultSearchName={'plate'} searchOptions={searchOptions} activate={(v) => activateTextFilter(v)} deactivate={(v) => deactivateTextFilter(v)}/>

   
       <Card
       className={clsx(classes.root, className)}
       {...rest}
     >


      <PerfectScrollbar>
        <Box maxHeight={400}>
        <div style={{ flexDirection: 'row', display: 'flex', marginBottom: 20 }}>
                    {
                        text_filters.map((item, idx) => {
                            return (
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', backgroundColor: 'rgb(230,230,230)', borderRadius: 30, padding: 0, margin: 0, height: 30, padding: 10, margin: 5, }}>
                                    <p>{item.name + ' contains ' + item.target}</p>
                                    <X onClick={() => deactivateTextFilter(item.key)} />
                                </div>
                                )
                        })
                    }
                </div>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={selectedCustomerIds.length === customers.length}
                    color="primary"
                    indeterminate={
                      selectedCustomerIds.length > 0
                      && selectedCustomerIds.length < customers.length
                    }
                    onChange={handleSelectAll}
                  />
                </TableCell>
                <HeaderComponent sortSet={(v, d) => updateSortDirection(v, d)} text={'Plate'} itemkey={'plate'} activate={(v) => activateTextFilter(v)} deactivate={(v) => deactivateTextFilter(v)} />
                <HeaderComponent sortSet={(v, d) => updateSortDirection(v, d)} text={'Truck Type'} itemkey={'truckType'} activate={(v) => activateTextFilter(v)} deactivate={(v) => deactivateTextFilter(v)} />
                <HeaderComponent sortSet={(v, d) => updateSortDirection(v, d)} text={'VIN'} itemkey={'vin'} activate={(v) => activateTextFilter(v)} deactivate={(v) => deactivateTextFilter(v)} />

         </TableRow>
            </TableHead>
            <TableBody>
              {sortItems(customers, sortOrder).slice(page * limit, page * limit + limit).map((customer) => (
                <TableRow
                  hover
                  key={customer.vehicleId}
                  selected={selectedCustomerIds.indexOf(customer.vehicleId) !== -1}
                 
                >
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={selectedCustomerIds.indexOf(customer.vehicleId) !== -1}
                      onChange={(event) => handleSelectOne(event, customer.vehicleId)}
                      value="true"
                    />
                  </TableCell>
                  <TableCell>
                  <Link
              to={customer}
              onClick={ () => {
                //alert('Direct driver navigation temporarily disabled pending bug fix, instead open a new tab and find the driver or contact Mike.')
                navigate('/app/editvehicle', {state: customer})
              }}
              variant="h6"
            >{customer.plate}</Link>
                  </TableCell>
                  <TableCell>
                    {customer.truckType}
                  </TableCell>
                  <TableCell>
                    {customer.vin}
                  </TableCell>


                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={customers.length}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[5, 10, 25]}
      />
    </Card></div>
  );
};

UserVehiclesTable.propTypes = {
  className: PropTypes.string,
  customers: PropTypes.array.isRequired
};

export default UserVehiclesTable;
