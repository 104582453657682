import React, { PropTypes, Component } from 'react';
import ReactDOM from 'react-dom';
import LocationOnIcon from '@material-ui/icons/LocationOn';


class HoverMarker extends Component {


    static defaultProps = {};


    constructor(props) {
        super(props);
    }

    render() {

        return (
            <div style={{borderRadius: 10, width: 20, height: 20, marginTop: -1 * this.props.zoomSet, marginLeft: -1 * this.props.zoomSet,
            alignItems: 'center', justifyContent: 'center', display: 'flex'}} >
                <LocationOnIcon style={{color: this.props.idx == 0 ? 'rgb(25,188,156)':'rgb(66,163,253)'}}/>
            </div>
        );

        return (
            <div style={{borderRadius: 10, width: 20, height: 20, backgroundColor: 'black', marginTop: -1 * this.props.zoomSet, marginLeft: -1 * this.props.zoomSet,
            alignItems: 'center', justifyContent: 'center', display: 'flex'}} >
                <a style={{color: 'white'}}>{this.props.idx}</a>
            </div>
        );
    }
}

const K_SIZE = 40;

const greatPlaceStyle = {
    // initially any map object has left top corner at lat lng coordinates
    // it's on you to set object origin to 0,0 coordinates
    position: 'absolute',
    width: K_SIZE,
    height: K_SIZE,
    left: -K_SIZE / 2,
    top: -K_SIZE / 2,

    border: '5px solid #f44336',
    borderRadius: K_SIZE,
    backgroundColor: 'white',
    textAlign: 'center',
    color: '#3f51b5',
    fontSize: 16,
    fontWeight: 'bold',
    padding: 4,
    cursor: 'pointer'
};

const greatPlaceStyleHover = {
    ...greatPlaceStyle,
    border: '5px solid #3f51b5',
    color: '#f44336'
};

export default HoverMarker;