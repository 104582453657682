import React, {useState, useEffect} from 'react';
import { Link as RouterLink, useNavigate, useLocation} from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';

import LocationOnIcon from '@material-ui/icons/LocationOn';
import {
  Box,
  Button,
  Checkbox,
  Container,
  FormHelperText,
  Link,
  TextField,
  Typography,
  makeStyles,
  Grid,
  Select,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions
} from '@material-ui/core';
import Page from 'src/components/Page';
import parse from 'autosuggest-highlight/parse';
import throttle from 'lodash/throttle';
import Firebase from 'src/Firebase'
import MuiPhoneNumber from 'material-ui-phone-number'
import MyContext from 'src/MyContext'
import CircularProgress from '@material-ui/core/CircularProgress';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';

const filter = createFilterOptions();


const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/


const autocompleteService = { current: null };
const geocoder = {current: null};
const cargoTypeOptions = [{'val': 'Fluid', 'name': 'Fluid'}, {'val': 'Dry', 'name': 'Dry'}, {'val': 'Container', 'name': 'Container'}]
const truckTypeOptions = [{'val': 'Shelto', 'name': 'Shelto'},{'val': 'Tesabi', 'name': 'Tesabi'},{'val': 'High Bed', 'name': 'High Bed'},{'val': 'Low Bed', 'name': 'Low Bed'}, {'val': 'Cement Tank', 'name': 'Cement Tank'}]




const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  }
}));


const AddVehicleDialog = (props) => {
  const navigate = useNavigate();
  const [dialogWorking, setDialogWorking] = React.useState(false)
  const [value, setValue] = React.useState(props.assignedTransporter ? getAssociationFull(props.assignedTransporter, props.adminUsers ? props.adminUsers : [], props.adminAssocs ? props.adminAssocs : []) : null);

  function valueSet(userId, users, assocs) {
    //alert('attempting valueset')
    //console.log(userId)
    //console.log(users)
    //console.log(assocs)
    var us = users ? users : []
    var as = assocs ? assocs : []
    var assocFull = getAssociationFull(userId, us, as)
    console.log(assocFull)
    if (assocFull != null) {
      setValue(assocFull)
    }
  }

  function getAssociationFull(userId, users, assocs) {
    var ret = null 
    var retnext = null 
    users.map((item, idx) => {
      if (item.userId == userId) {
        ret = item.associationId
      }
    })
    //console.log(ret)
    if (ret != null) {
      assocs.map((item, idx) => {
        if (item.associationId == ret) {
          retnext = item  
        }
      })
    }
    //console.log(retnext)
    return retnext 
  }

  function getAssociationId(userId, users) {
    var ret = null 
    users.map((item, idx) => {
      if (item.userId == userId) {
        ret = item.associationId
      }
    })

    return ret 
  } 

  function getAssociationOptionName(userId, users, assocs) {
    var ret = null 
    let assocId = getAssociationId(userId, users)
    if (assocId != null) {
      assocs.map((item, idx) => {
        if (item.associationId == assocId) {
          ret = item.companyName
        }
      })
    }

    return ret 
  }

  function getAssociationOptions(adminAssoc) {
    
    return [{'associationId': '0', 'companyName': 'None'}].concat(adminAssoc)
  }

 
  function getTransporterOptions(adminUsers) {


    let ret = adminUsers.filter((item, idx) => {
      return item.userType == 1 && item.companyName
    })
    return [{'userId': '0', 'companyName': 'Unassigned'}].concat(ret)

  }

  function getTransporterOption(userId, adminUsers) {
    var ret = {'userId': '0', 'companyName': 'Unassigned'}
    adminUsers.map((item, idx) => {
      if (item.userId == userId) {
        ret = {'userId': userId, 'companyName': item.companyName}
      }
    })
    return ret 
  }
  

  function updateAssocIfPossible(userId, users, assocs, setFieldValue) {
    if (userId != '0' && userId != -1 && userId && userId != 'undefined') {
      var targassoc = null 
      users.map((item, idx) => {
        if (item.userId == userId && item.associationId && item.associationId != '0' && item.associationId != -1 && item.associationId.length > 5) {
          targassoc = item.associationId 
        }
      })
      if (targassoc != null) {
        assocs.map((item, idx) => {
          if (item.associationId == targassoc) {
            setValue({
              companyName: item.companyName,
            });
            setFieldValue('associationId', targassoc)
            setFieldValue('associationName', item.companyName)
          }
        })
      }
    }
  }
  

  function addUser(values) {
    setDialogWorking(true)

    

    console.log(values)
    console.log(Firebase.userInfo)
    //Firebase.gt()
    var v = {}
    v['updateBody'] = values

    let s = async (xv) => {
      
      if (xv.result == -1) {
        setDialogWorking(false)
        alert('A vehicle with this plate number already exists.')
      } else {
        setDialogWorking(false)
        if (props.limitedAccessFunction) {
          await props.limitedAccessFunction()
        }
        let newData = xv.update 
        props.onConfirm(newData)
        props.onClose()
      }
    }
    let f = () => {
      setDialogWorking(false)
      alert('Could not create vehicle.')
    }

    console.log(v)

    Firebase.adminEditVehicle(v, (x) => s(x), () => f())

  }

    // Similar to componentDidMount and componentDidUpdate:
    useEffect(() => {
      // Update the document title using the browser API
      //alert('trash')
      if (props.updateFunction) {
       // alert('setting now')
        props.updateFunction(() => (x, y, z) => valueSet(x, y, z))
      }
    }, []);
  

  return (
    <MyContext.Consumer>{context => (
      <div>
      <Formik
      enableReinitialize
id='f2'
  initialValues={{
   vin: '',
    plate: 'ET-3-',
    trailerPlate: 'ET-3-',
    verified: false,
    assignedTransporter: props.assignedTransporter ? props.assignedTransporter : '0',
   associationId: props.assignedTransporter ? getAssociationId(props.assignedTransporter, context.users ? context.users : []) : null,
    associationName: props.assignedTransporter ? getAssociationOptionName(props.assignedTransporter, context.users ? context.users : [], context.associations ? context.associations: []) : null,
  }}
  validationSchema={
    Yup.object().shape({
      plate: Yup.string().max(255).required('Plate required'),
    })
  }
  onSubmit={(values) => {
    //alert('try')
    console.log('trysub')
    console.log(values)
    addUser(values)
    //verifyCode(values.code)
    //navigate('/app/dashboard', { replace: true });
  }}
>
  {({
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    isSubmitting2,
    touched,
    values,
    setFieldValue
  }) => (
    <form id='fu2' onSubmit={handleSubmit}>
            <Dialog
            open={props.open}
            onClose={() => props.onClose()}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{props.alertTitle}</DialogTitle>
            <DialogContent>   

              <DialogContentText id="alert-dialog-description">
                {dialogWorking ? 'Performing Operation...' : props.alertBody}
                {dialogWorking ? <div style={{alignItems: 'center', justifyContent: 'center'}}><CircularProgress/></div> : <div/>}
              </DialogContentText>

              <div style={{display: 'flex', flexDirection: 'row'}}><div style={{marginRight: 10}}>
                      
                      <Autocomplete
    id="combo-box-transporter"
    options={getTransporterOptions(context.users ? context.users : [])}
    getOptionLabel={(option) => option.companyName}
    value={{'userId': values.assignedTransporter, 'companyName': getTransporterOption(values.assignedTransporter, context.users ? context.users : []).companyName}}
    defaultValue={props.assignedTransporter ? getTransporterOption(props.assignedTransporter, context.users ? context.users : []) : {'userId': 0, 'companyName': 'Unassigned'}} 
    onChange={(e, value) => {
      if (value != null) {
        setFieldValue('assignedTransporter', value.userId)
        if (value.userId != '0') {
          updateAssocIfPossible(value.userId, context.users ? context.users : [], context.associations ? context.associations : [], (x, y) => setFieldValue(x, y))
        }
      } else {
        setFieldValue('assignedTransporter', '0')
      }
  
    }}
    renderInput={(params) => <TextField {...params}  fullWidth
    label="Choose Transporter"
    margin="normal"
    id="assignedTransporter"
    name="transporter"
    variant="outlined"
    value={values.assignedTransporter}
    defaultValue={values.assignedTransporter} />}
  />
  
          <TextField
                  error={Boolean(touched.plate && errors.plate)}
                  fullWidth
                  id="plate"
                  helperText={touched.plate && errors.plate}
                  label="Plate Number"
                  margin="normal"
                  name="plate"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.plate}
                  variant="outlined"
                />
                                {values.plate in props.plateDict ? <Link
                onClick={ () => navigate('/app/editvehicle', {state: props.plateDict[values.plate], replace: true})}
                variant="h6"
              >{'This vehicle already exists. Click here to edit vehicle.'}</Link> : null}
                <TextField
                  error={Boolean(touched.vin && errors.vin)}
                  fullWidth
                  id="vin"
                  helperText={touched.vin && errors.vin}
                  label="Vehicle Information Number (VIN)"
                  margin="normal"
                  name="vin"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.vin}
                  variant="outlined"
                />
  
  
                   <TextField
                error={Boolean(touched.make && errors.make)}
                fullWidth
                id="make"
                helperText={touched.make && errors.make}
                label="Make"
                margin="normal"
                name="make"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.make}
                variant="outlined"
                />
                <TextField
                error={Boolean(touched.model && errors.model)}
                fullWidth
                id="model"
                helperText={touched.model && errors.model}
                label="Model"
                margin="normal"
                name="model"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.model}
                variant="outlined"
                />
                          <TextField select
            fullWidth
            label="Truck Type"
            margin="normal"
            id="truckType"
            name="truckType"
            variant="outlined"
            value={values.truckType}
            onChange={handleChange}
          >
            {truckTypeOptions.map((item, idx) => {
              return (<MenuItem value={item.val}>{item.name}</MenuItem>)
            })}
          </TextField>
  
          <TextField select
            fullWidth
            label="Trailer"
            margin="normal"
            id="trailer"
            name="trailer"
            variant="outlined"
            value={values.trailer}
            onChange={handleChange}
          >
            {[{'val': 'No Trailer', 'name': 'No Trailer'}, {'val': 'Has Trailer', 'name': 'Has Trailer'}].map((item, idx) => {
              return (<MenuItem value={item.val}>{item.name}</MenuItem>)
            })}
          </TextField>
  </div>
                <div>            
                <TextField
                error={Boolean(touched.year && errors.year)}
                fullWidth
                id="year"
                helperText={touched.year && errors.year}
                label="Year"
                margin="normal"
                name="year"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.year}
                variant="outlined"
                type="number"/>
  <Autocomplete
        value={value}
        onChange={(event, newValue) => {
          if (typeof newValue === 'string') {
            setValue({
              companyName: newValue,
            });
            setFieldValue('associationId', newValue.associationId)
            setFieldValue('associationName', newValue.companyName)
          } else if (newValue && newValue.inputValue) {
            // Create a new value from the user input
            setValue({
              companyName: newValue.inputValue,
            });
            setFieldValue('associationId', -1)
            setFieldValue('associationName', newValue.inputValue)
          } else {
            setValue(newValue);
            if (newValue == null) {
              setFieldValue('associationId', '0')
              setFieldValue('associationName', 'None')
            } else {
              setFieldValue('associationId', newValue.associationId)
              setFieldValue('associationName', newValue.companyName)
            }
          }
          //console.log(event.target.value)
          //console.log(newValue)
        }}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);
  
          // Suggest the creation of a new value
          if (params.inputValue !== '') {
            filtered.push({
              inputValue: params.inputValue,
              companyName: `Add "${params.inputValue}"`,
            });
          }
  
          return filtered;
        }}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        id="free-solo-with-text-demo"
        options={getAssociationOptions(context.associations ? context.associations : [])}
        getOptionLabel={(option) => {
          // Value selected with enter, right from the input
          if (typeof option === 'string') {
            return option;
          }
          // Add "xxx" option created dynamically
          if (option.inputValue) {
            return option.inputValue;
          }
          // Regular option
          return option.companyName;
        }}
        renderOption={(option) => option.companyName}
        freeSolo
        renderInput={(params) => (
          <TextField {...params} error={Boolean(touched.association && errors.association)}
          fullWidth
          id="associationId"
          helperText={touched.association && errors.association}
          label="Association"
          margin="normal"
          name="associationId"
          onBlur={handleBlur}
          onChange={(e) => {
            console.log(e.target)
          }}
          value={values.associationId}
          variant="outlined"/>
        )}
      />
                <TextField
                error={Boolean(touched.color && errors.color)}
                fullWidth
                id="color"
                helperText={touched.color && errors.color}
                label="Color"
                margin="normal"
                name="color"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.color}
                variant="outlined"
                /><TextField
                  error={Boolean(touched.numAxles && errors.numAxles)}
                  fullWidth
                  id="numAxles"
                  helperText={touched.numAxles && errors.numAxles}
                  label="Number of Axles"
                  margin="normal"
                  name="numAxles"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.numAxles}
                  variant="outlined"
                  type="number"/>
                  <TextField
                  error={Boolean(touched.numWeight && errors.numWeight)}
                  fullWidth
                  id="maxWeight"
                  helperText={touched.maxWeight && errors.maxWeight}
                  label="Max Weight per Tone"
                  margin="normal"
                  name="maxWeight"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.maxWeight}
                  variant="outlined"
                  type="number"/>
            <TextField select
            fullWidth
            label="Cargo Type"
            margin="normal"
            id="cargoType"
            name="cargoType"
            variant="outlined"
            value={values.cargoType}
            onChange={handleChange}
          >
            {cargoTypeOptions.map((item, idx) => {
              return (<MenuItem value={item.val}>{item.name}</MenuItem>)
            })}
          </TextField>
          <TextField
          disabled={values.trailer != 'Has Trailer'}
                error={Boolean(touched.trailerPlate && errors.trailerPlate)}
                fullWidth
                id="trailerPlate"
                helperText={touched.trailerPlate && errors.trailerPlate}
                label="Trailer Plate"
                margin="normal"
                name="trailerPlate"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.trailerPlate}
                variant="outlined"
                />
          </div>
  
  </div>
            
            
  
          <Box
                    alignItems="center"
                    display="flex"
                    ml={-1}
                  >
                    <Checkbox
                      checked={values.verified}
                      name="verified"
                      onChange={handleChange}
                    />
                    <Typography
                      color="textSecondary"
                      variant="body1"
                    >
                      Verified Vehicle
                    </Typography>
                  </Box>

  

            </DialogContent>
            <DialogActions>
              <Button onClick={() => props.onClose()} color="primary">
                {props.alertCancelText}
              </Button>
              <Button onClick={() => addUser(values)} color="primary" autoFocus>
                {props.alertConfirmText}
              </Button>
            </DialogActions>
          </Dialog>
          </form>
          )}
        </Formik>
        </div>
    )}
          </MyContext.Consumer>
        )

    };

/*   <div onClick={() => this.adjustSortDescending()} style={{ margin: 0, display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: this.state.sortDescending ? 'orange' : 'white', border: this.state.sortDescending ? '' : '1px solid black', borderRadius: 30, padding: 0, height: 30, padding: 5 }}>
                                <p>Descending</p>
                            </div>*/

/*
 * 
 * 
 *             */

export default AddVehicleDialog;