
import React, { useState, useEffect, Component } from 'react';

import {
  Box,
  Container,
  makeStyles,
  Button,
  TextField,
  CircularProgress
} from '@material-ui/core';
import Page from 'src/components/Page';
import TopBar from 'src/layouts/DashboardLayout/TopBar'
import TransporterTable from '../customer/CustomerListView/TransporterTable'
import Firebase from 'src/Firebase.js'
import { HotTub } from '@material-ui/icons';
import LoadingView from 'src/LoadingView';
import MyContext from 'src/MyContext';



class TransportersTableWrapper extends Component {


  constructor() {
      super()
    //console.log(props)
    this.state = {

      tableItems: [],
      existingDict: {},
      initialSelectedIds: [],
      targetJob: '',
      jobData: {},
      transporterPrice: 0,
      isAuthenticating: false, 

    }
    this.listeners = {}

  }



  async componentDidMount() {
    console.log(this.props)
    console.log(JSON.stringify(this.props))
    //console.log(this.props.location.pathname.split('/')[2])
    //let targetJob = this.props.location.pathname.split('/')[2]
    //alert(this.props.location.pathname)
    //console.log(targetJob)
    let targetJob = this.props.location.quoteId
    let jobData = this.props.location



    /*let jobData = await Firebase.database.ref('shipperRequests/' + targetJob).once("value").then((inner_snapshot) => {
        if (inner_snapshot.exists()) {
            return inner_snapshot.val()
        } else {
            return null
        }
    }).catch((error) => {
        return null
    })

    if (jobData == null) {
        alert('Invalid id')
        return 
    } */

    var jobMapping = await Firebase.database.ref('mapOffersToTransporters/' + targetJob).once("value").then((inner_snapshot) => {
        if (inner_snapshot.exists()) {
            return inner_snapshot.val()
        } else {
            return null
        }
    }).catch((error) => {
        return null
    })

    if (jobMapping == null) {
        var jobMapping = {}
    }


    let idmap = Object.keys(jobMapping).map((item, idx) => {
        return item
    })

    var targetTransporterPrice = 0
    /*if ('transporterPrice' in jobData) {
        targetTransporterPrice = jobData.transporterPrice
    }*/
    if (targetTransporterPrice == 0) {
        targetTransporterPrice = this.props.transporterPrice
    }
    this.setState({
        jobData: jobData,
        transporterPrice: targetTransporterPrice,
        jobMapping: jobMapping,
        initialSelectedIds: idmap,
        currentSelectedIds: idmap,
        targetJob: targetJob
    })

    console.log(JSON.stringify(jobData))
    console.log(JSON.stringify(jobMapping))
    //this.loadListDualRef('transporterUids', 'users') 
  }

  componentWillUnmount() {
    Object.keys(this.listeners).map((key, idx) => {
        try {
            this.listeners[key].off('value')
        } catch (e) {

        }

    })
    //Firebase.database.ref('transporterUids/').off('child_added')
    //Firebase.database.ref('transporterUids/').off('child_removed')
}

  functionalStateListAdd( v) {

    //console.log('try doing a functional update outer for ' + key )
    this.setState(function (prevState, props) {
        var temp = prevState.tableItems
        var tempDict = prevState.existingDict
        temp.push(v)
        tempDict[v.userId] = v.userId
        return { tableItems: temp, existingDict: tempDict }
    })
}

functionalStateListUpdate( v) {

  //console.log('try doing a functional update outer for ' + key )
  this.setState(function (prevState, props) {
      var temp = prevState.tableItems.map((item, idx) => {
        if (item.userId != v.userId) {
          return item 
        }
        return v 
      })
      return { tableItems: temp }
  })
}

  async loadSingleItemByKey(secondary_target, secondary_key) {
    let self = this
    this.listeners['users/' + secondary_key] = Firebase.database.ref('users/' + secondary_key)
    //console.log('here is   shipperRequests/' + secondary_key)
    this.listeners['users/' + secondary_key].on("value", (inner_snapshot) => {
        if (inner_snapshot.exists()) {

          if (inner_snapshot.val().userId in self.state.existingDict) {
              console.log(inner_snapshot.val().userId + 'in alrady so update')
            self.functionalStateListUpdate(inner_snapshot.val())
          } else {
            console.log(inner_snapshot.val().userId + 'out so asdd')
            self.functionalStateListAdd(inner_snapshot.val())
          }
            
        } else {
            console.log('container found to not exist for ' + secondary_key)
        }
    }, function (error) {
        console.log('error fetching container for ' + secondary_key)
        console.log(error)
    })
}

  loadListDualRef(firebase_primary_target, firebase_secondary_target) {
    // Maintain reference to main component for state updates
    let self = this

    let addlist = Firebase.database.ref(firebase_primary_target).on("child_added", (inner_snapshot, prevkey) => {
        // Get key of secondary item

        if (inner_snapshot.exists()) {
            let secondary_key = inner_snapshot.key
            self.loadSingleItemByKey(firebase_secondary_target, secondary_key)
        } else {

        }
        //self.loadSingleItemByKey(firebase_secondary_target, secondary_key)


    }, function (errorObject) {
        if (this.state.report_errors) {
            alert(errorObject)
        }
        console.log("The read failed: " + errorObject.code);
    });

    Firebase.database.ref(firebase_primary_target).on("child_removed", (snapshot) => {
        let secondary_key = snapshot.key

        Firebase.database.ref(firebase_secondary_target + '/' + secondary_key).off("value")

        var nextList = self.state.tableItems.filter((item, idx) => {
            return (item.userId != snapshot.val().userId)
        })
       
        var existingDict = self.state.existingDict
        delete existingDict[secondary_key]
            self.setState({
                tableItems: nextList,
                existingDict: existingDict
            })

    }, function (errorObject) {
        if (this.state.report_errors) {
            alert(errorObject)
        }
        console.log("The read failed: " + errorObject.code);
    });
  }


  sendDataFunc() {
    if (this.state.transporterPrice == 0) {
        alert('Set a price for the transporter payment before continuing.')
        return 
    }
    this.setState({
        isAuthenticating: true 
    })

    let s = () => {
        var newSelections = []
        var newDeselections = []
    
        this.state.initialSelectedIds.map((item, idx) => {
            if(this.state.currentSelectedIds.indexOf(item) >= 0) {
                // This item was selected before, and is still selected. No action
            } else {
                // This item was selected before, and is no longer selected
                newDeselections.push(item)
            }
        })
    
        this.state.currentSelectedIds.map((item, idx) => {
            if (this.state.initialSelectedIds.indexOf(item) >= 0) {
                // This item was selected before, and is still selected. No action
            } else {
                // This item was not selected before, but is now selected
                newSelections.push(item)
            }
        })
    
        //alert(JSON.stringify(newSelections))
        //alert(JSON.stringify(newDeselections))
        var selDict = {}
        this.state.currentSelectedIds.map((item, ixd) => {
            selDict[item] = item 
        })
        newDeselections.map((item, idx) => {
            selDict[item] = null
        })
    
        var data = {}
        data['quoteId'] = this.state.targetJob
        data['transporterIdList'] = newSelections
        data['transporterIdListDesel'] = newDeselections
        data['transporterDict'] = selDict
        console.log('sending with ')
        console.log(JSON.stringify(data))
        Firebase.offerJobToTransporters(data)
        //Firebase.azfat(data)
        this.setState({
            initialSelectedIds: this.state.currentSelectedIds,
            isAuthenticating: false 
        })
        this.props.onComplete()
        alert('Offer has been updated. This job is now offered to ' + this.state.currentSelectedIds.length + ' transporters.')
    }

    let f = () => {
        this.setState({
            isAuthenticating: false 
        })
        alert('Error updating data, check internet connection.')
    }

    
    var dpack = {}
    dpack['transporterPrice'] = this.state.transporterPrice
    dpack['userId'] = Firebase.uid 
    dpack['quoteId'] = this.state.targetJob
    Firebase.editQuoteSimple(dpack, s, f)
  }

  updateIds(v) {
    this.setState({
        currentSelectedIds: v
    })
  }

    mathRound(num) {
    if (num < 0.01) {
      return num 
    }

    return Math.round((num + Number.EPSILON) * 100) / 100
  }

  getTransporters(users) {
      let j = users.filter((item, idx) => {
          return item.userType == 1
      })
      return j
  }


  render() {

    return (
<Page>
 <TopBar />
 <div style={{marginTop: 100, marginBottom: 100}}>
 <Box mt={3}>
<div style={{margin: 20, flexDirection: 'column'}}>

    {this.state.jobData.price && this.state.jobData.trueWeight && this.state.jobData.trueWeight > 0 ? <div style={{flexDirection: 'column', display: 'flex'}}>
        <a style={{fontSize: 18, fontFamily: 'Helvetica', marginBottom: 20}}>{'Price Paid By Shipper: ETB ' + this.mathRound(this.state.jobData.price / (this.state.jobData.trueWeight * 10)) + '/Quintal' }</a>
        <a style={{fontSize: 18, fontFamily: 'Helvetica', marginBottom: 20}}>{'Price Offered to Transporter: ETB ' + this.mathRound(this.state.transporterPrice / (this.state.jobData.trueWeight * 10)) + '/Quintal' }</a>
    </div>: <div/>}


</div>

   <div id='fk24' style={{padding: 10, paddingBottom: 30}}>
   <MyContext.Consumer>{context => (
   <TransporterTable customers={this.getTransporters(context.users)} initialSelectedIds={this.state.initialSelectedIds} updateParentIds={(v) => this.updateIds(v)}/>
   )}</MyContext.Consumer>
   </div>
   {this.state.isAuthenticating ? <div style={{alignItems: 'center', justifyContent: 'center', alignSelf: 'center', marginLeft: 10}}><CircularProgress/></div> : <div/>}
   <Button
                    color="primary"
                   
                    style={{marginLeft: 10}}
                    id="sendButton"
                    size="large"
                    type="submit"
                    variant="contained"
                    onClick={() => this.sendDataFunc()}
                  >
                    Update Offers
                  </Button>
 </Box>
       </div>
        </Page>
    )
  }


}

/**
 *  <TextField

                  fullWidth

                  label="Transporter Payment"
                  margin="normal"
                  name="transporterPrice"
                  onChange={(v) => this.setState({transporterPrice: v.target.value})}
                  type="number"
                  value={this.state.transporterPrice}
                  variant="outlined"
                />
 */

export default TransportersTableWrapper;