import React, {Component} from 'react'
import TableContext from './TableContext';





export default class TableContextProvider extends Component {

    constructor(props) {
        super(props)
        this.state = {
            jobsFilters: [],
            jobsSortOrder: 'creationDate',
            jobsSortDirection: false,
            page: 0, 
            limit: 10,
            countVal: 0,
            usersFilters: [],
            usersSortOrder: 'creationDate',
            usersSortDirection: false,
            usersPage: 0,
            usersLimit: 10, 
            usersCountVal: 0,
            hideTestUsers: true,
            
        }
    }

    updateState(x,y) {
        var updateDict = {}
        updateDict[x] = y
        this.setState(updateDict)
    }


    render() {

            return (    <TableContext.Provider value={{
                jobsFilters: this.state.jobsFilters,
                jobsSortOrder: this.state.jobsSortOrder,
                jobsSortDirection: this.state.jobsSortDirection,
                countVal: this.state.countVal,
                page: this.state.page,
                limit: this.state.limit,
                usersFilters: this.state.usersFilters,
                usersSortOrder: this.state.usersSortOrder,
                usersSortDirection: this.state.usersSortDirection,
                usersCountVal: this.state.usersCountVal,
                usersPage: this.state.usersPage,
                usersLimit: this.state.usersLimit,
                hideTestUsers: this.state.hideTestUsers,
                updateFunc: (key, val) => this.updateState(key, val)
            }}>
                {this.props.children}
            </TableContext.Provider>)
        }
    
       

}