import React, {useState, useReducer} from 'react';
import { Link as RouterLink, useNavigate, useLocation} from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';

import LocationOnIcon from '@material-ui/icons/LocationOn';
import {
  Box,
  Button,
  Checkbox,
  Container,
  FormHelperText,
  Link,
  TextField,
  Typography,
  makeStyles,
  Grid,
  Select,
  MenuItem,
  Stepper,
  StepLabel,
  Step,
  CircularProgress,
  Tooltip
} from '@material-ui/core';
import {X as XIcon} from 'react-feather';
import Help from '@material-ui/icons/Help';
import Page from 'src/components/Page';
import parse from 'autosuggest-highlight/parse';
import throttle from 'lodash/throttle';
import Firebase from 'src/Firebase'
import DateTimeMaster from 'src/utils/DateTimeMaster';
import MuiPhoneNumber from 'material-ui-phone-number'
import { parseWithOptions } from 'date-fns/fp';
import MyContext from 'src/MyContext';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { arrayOf } from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import { gridColumnLookupSelector } from '@mui/x-data-grid';
import AddTransporterDialog from './AddTransporterDialog';
import AddDriverDialog from './AddDriverDialog';
import AddVehicleDialog from './AddVehicleDialog';
import moment from 'moment';
const filter = createFilterOptions();
const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
/*

Traveler // Variety purity >=90% // Moisture =<15%
Traveler //  Variety purity <90% // Moisture =<15%
Traveler // Variety all purity // Moisture >15%
Fatima // Variety purity >=90% // Moisture =<15%
Fatima // Variety purity <90% // Moisture =<15%
Fatima //  Variety all purity // Moisture >15%
Planet // Variety purity >=90% // Moisture =<15%
Planet // Variety purity <90% // Moisture =<15%
Planet //  Variety all purity // Moisture >15%
All other varieties // Variety all purity // Moisture =<15%
All other varieties // Variety all purity // Moisture >15%
*/


const outOptions = [
{'name': 'Traveler // Variety purity >=90% // Moisture =<15%', 'customId': 0, 'query': {'variety': 'Traveler', 'moisture': 'below', 'purity': 'above', 'uncategorized': false}},
{'name': 'Traveler //  Variety purity <90% // Moisture =<15%', 'customId': 1, 'query': {'variety': 'Traveler', 'moisture': 'below', 'purity': 'below', 'uncategorized': false}},
{'name': 'Traveler // Variety all purity // Moisture >15%', 'customId': 2, 'query': {'variety': 'Traveler', 'moisture': 'above', 'uncategorized': false}},
{'name': 'Fatima // Variety purity >=90% // Moisture =<15%', 'customId': 3, 'query': {'variety': 'Fatima', 'moisture': 'below', 'purity': 'above', 'uncategorized': false}},
{'name': 'Fatima // Variety purity <90% // Moisture =<15%', 'customId': 4, 'query': {'variety': 'Fatima', 'moisture': 'below', 'purity': 'below', 'uncategorized': false}},
{'name': 'Fatima //  Variety all purity // Moisture >15%', 'customId': 5, 'query': {'variety': 'Fatima', 'moisture': 'above', 'uncategorized': false}},
{'name': 'Planet // Variety purity >=90% // Moisture =<15%', 'customId': 6, 'query': {'variety': 'Planet', 'moisture': 'below', 'purity': 'above', 'uncategorized': false}},
{'name': 'Planet // Variety purity <90% // Moisture =<15%', 'customId': 7, 'query':  {'variety': 'Planet', 'moisture': 'below', 'purity': 'below', 'uncategorized': false}},
{'name': 'Planet //  Variety all purity // Moisture >15%', 'customId': 8, 'query': {'variety': 'Planet', 'moisture': 'above', 'uncategorized': false}},
{'name': 'All other varieties // Variety all purity // Moisture =<15%', 'customId': 9, 'query': {'variety': 'other', 'moisture': 'below', 'uncategorized': false}},
{'name': 'All other varieties // Variety all purity // Moisture >15%', 'customId': 10, 'query': {'variety': 'other', 'moisture': 'above', 'uncategorized': false}},
{'name': 'Uncategorized', 'customId': 11, 'query': {'uncategorized': true}}]
const autocompleteService = { current: null };
const geocoder = {current: null};

const specOptions = {'numberofbags': 1, 'averagebagweight': 1}

const sharedKeys = ['drivername', 'driverphone', 'platenumber', 'transportservice', 'weighbridge']

const steps = ['Basic Details', 'Transport Details', 'Cargo Details'];
const stepFields = {
  0: [],
  1: [ 'dlnumber', 'freightorder', /*'unitprice',*/ 'dispatchdate'],
  2: ['vehicleweight', 'vehicletare', 'numberofbags', 'averagebagweight', 'weighbridge', 'stacknumber', 'inspectedby', 'silonumber'],
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  }
}));



const EditDispatchView = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const {state} = useLocation();
  const [loading, setLoading] = React.useState(false);
  const [autoValues, setAutoValues] = React.useState({});
  const [source, setSource] = React.useState(state.customer && state.customer.fromWarehouse ? state.customer.source : null);
  const [category, setCategory] = React.useState(null);
  const [operation, setOperation] = React.useState(state.customer && state.customer.operation ? state.customer.operation : null);
  const [sku, setSku] = React.useState(state.customer && state.customer.sku ? state.customer.sku : null);
  const [destination, setDestination] = React.useState(state.customer && state.customer.warehouse ? state.customer.warehouse : null);
  const [amount, setAmount] = React.useState(state.customer && state.customer.netweightkg ? state.customer.netweightkg : null);
  const [amountWh, setAmountWh] = React.useState(state.customer && state.customer.netweightkgwh ? state.customer.netweightkgwh : null);
  const [transferOut, setTransferOut] = React.useState(null);
  const [activeStep, setActiveStep] = React.useState(0);
  const [arrivalType, setArrivalType] = React.useState(state.customer ? getDispatchType(state.customer) : 'Train')
  const [train, setTrain] = React.useState(state.customer && state.customer.train ? state.customer.train : null);
  const [isFinal, setIsFinal] = React.useState(true);
  const [wagons, setWagons] = React.useState(state.customer && state.customer.wagons ? state.customer.wagons : [{'number': '', 'qty': '', 'bag': 50}]);
  const [laborers, setLaborers] = React.useState(state.customer && state.customer.laborers ? state.customer.laborers : [{'name': '', 'qty': ''}])
  const [innerPlanId, setInnerPlanId] = React.useState(null);
  const [addTransporterDialog, setAddTransporterDialog] = React.useState(false)
  const [addDriverDialog, setAddDriverDialog] = React.useState(false)
  const [addVehicleDialog, setAddVehicleDialog] = React.useState(false)
  const [ignored, forceUpdate] = useReducer(x => x + 1, 0);
  const handleNext = () => {


    setActiveStep((prevActiveStep) => prevActiveStep + 1);

  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };



  async function getValueForRef(t, ref, msg) {

    if (!ref) {
      return null 
    }

    let result = await t.get(ref)
    var value = null 
    if (result.exists) {

      value = result.data()
    } else {
      if (msg) {
        const errM = {'msg': msg}
        throw errM
      }

    }
    return value 
  }

  async function transactionStockUpdate(t, old_value, old_ref, old_set, oldVal, newVal, block_sub_zero, old_weight_key = 'netweightkg', new_weight_key = 'netweightkg') {
    if (old_value == null ) {
      let new_value = oldVal[old_weight_key] - newVal[new_weight_key]
      if (new_value < 0 && block_sub_zero) {
        const errM = {'msg': "Not enough stock exists in this warehouse to withdraw the specified amount."}
        throw errM
      }
      old_set['netweightkg'] = new_value 
      await t.set(old_ref, old_set)
    } else {
      let new_value = old_value['netweightkg'] + (oldVal[old_weight_key] - newVal[new_weight_key])
      if (new_value < 0 && block_sub_zero) {
        const errM = {'msg': "Not enough stock exists in this warehouse to withdraw the specified amount."}
        throw errM
      }
      var update_same_old_pending = {}
      update_same_old_pending['netweightkg'] = new_value 
      await t.update(old_ref, update_same_old_pending)
    }
  }

  async function transactionStockUpdateAdd(t, value, ref, set_val, newOrOldVal, weight_key = 'netweightkg') {
    if (value == null) {
      let new_value = newOrOldVal[weight_key]
      set_val['netweightkg'] = new_value 
      await t.set(ref, set_val)
    } else {
      var new_update = {}
      new_update['netweightkg'] = value['netweightkg'] + newOrOldVal[weight_key]

      await t.update(ref, new_update)
    }
  }

  async function transactionStockUpdateSubtract(t, value, ref, newOrOldVal, block_sub_zero, weight_key = 'netweightkg') {
    if (!value || !value['netweightkg'] || value['netweightkg'] < newOrOldVal[weight_key]) {
      if (block_sub_zero) {
        const errM = {'msg': "Not enough stock exists in this warehouse to withdraw the specified amount."}
        throw errM
      } else {
        console.log('[EDIT] completely skipping error for old result barley oldqueryresult etc train')
      }
    }
    var updateValOld = {}
    updateValOld['netweightkg'] = (value && value['netweightkg'] ? value['netweightkg'] : 0) - newOrOldVal[weight_key]
    if (value) {
      await t.update(ref, updateValOld);
    } else {
      await t.set(ref, updateValOld);
    }
  }

  function getCustomObjectName(v, key1, key2) {
    if (v[key1]) {
      if (v[key1][key2]) {
        return v[key1][key2]
      } else {
        return v[key1]
      }
    } else {
      return ''
    }

  }

  async function doSubmitEdit(vSend, oldVal, submissionType, context) {

    if (context.warehouseRole != 'Admin') {
      let firebaseServerTimeStamp = await Firebase.getServerTimestamp({})
      if (!firebaseServerTimeStamp || !firebaseServerTimeStamp.success || !firebaseServerTimeStamp.timestamp ) {
        alert('Connection issue, try again.')
        return 
      }
        let creationDateTimeStamp = firebaseServerTimeStamp.timestamp 
        if (creationDateTimeStamp - vSend.creationDate > (1000 * 60 * 10)) {
          alert('You cannot edit waybills more than 10 minutes after their creation.')
          return 
        }
    }


    if (loading) {
      return
    }

    setLoading(true)
    var newTypeKey = 'UU'
    let v = JSON.parse(JSON.stringify(vSend))
    var newVal = v 
    newVal['sku'] = sku 
    newVal['isFinal'] = isFinal
    newVal['operation'] = operation
    newVal['dispatchdate'] = newVal.dispatchdate ? moment(newVal.dispatchdate).valueOf() : Date.now()
    newVal['laborers'] = laborers
    newVal['transporteractual'] = newVal['transportservice']
    newVal['transportservice'] = getCustomObjectName(newVal, 'transportservice', 'name') //newVal['transportservice'] ? newVal['transportservice']['name'] : ''
    newVal['driveractual'] = newVal['drivername']
    newVal['drivername'] = getCustomObjectName(newVal, 'drivername', 'name')//newVal['drivername'] ? newVal['drivername']['name'] : ''
    newVal['vehicleactual'] = newVal['platenumber']
    newVal['platenumber'] = getCustomObjectName(newVal, 'platenumber', 'plate')//newVal['platenumber'] ? newVal['platenumber']['plate'] : ''
    newVal['warehouse'] = destination 
    newVal['finalweightkg'] = parseInt(amount)
    newVal['netweightkg'] = parseInt(amount) 
    newVal['updateTime'] = Date.now()

    newVal['fromWarehouse'] = false
    newVal['fromVessel'] = false
    newVal['trainAndWarehouse'] = false 

    // In the case of warehouse, these are the same thing
    var oldStockKey = null 
    var previousOldStockKey = null 
    var warehouseStockKey = null 
    var previousWarehouseStockKey = null

    var previousSet = {'sku': oldVal.sku.name}
    var previousWarehouseSet = null 

    var oldSet = null
    var warehouseSet = null 

    if (oldVal.fromWarehouse) {
      previousSet['warehouse'] = oldVal.source.name 
    } else if (oldVal.fromVessel) {
      previousSet['vessel'] = oldVal.operation.name 
    } else {
      previousSet['train'] = oldVal.train.name 
    }
    if (oldVal.trainAndWarehouse) {
      previousWarehouseSet = {'sku': oldVal.sku.name}
      previousWarehouseSet['warehouse'] = oldVal.train.warehouse.name
    }

    previousOldStockKey = getDictId(previousSet)
    previousWarehouseStockKey = previousWarehouseSet ? getDictId(previousWarehouseSet) : null

    if (submissionType == 'Train') {

      if (sku == null || amount == null || amount <= 0 || train == null || destination == null) {
        alert('Please select a corresponding transfer out request.')
        setLoading(false)
        return
      }

      let bags_validated = validateBagCountsTrain(v, wagons)
      if (!bags_validated) {
        setLoading(false)
        return 
      }

      newTypeKey = 'TR'

      newVal['train'] = train
      newVal['source'] = train
      newVal['wagons'] = wagons
      newVal['numberofbags'] = parseInt(newVal['numberofbags']) /*+ (newVal['numberofbagswh'] ? parseInt(newVal['numberofbagswh']) : 0)*/

      oldSet = {'sku': sku.name}
      oldSet['train'] = train.name 

      oldStockKey = getDictId(oldSet)

    } else if (submissionType == 'Warehouse') {
      if (sku == null || amount == null || amount <= 0 || source == null || destination == null || source.name == destination.name) {
        alert('Please select a source and destination (these two cannot be the same).')
        setLoading(false)
        return
      }

      newTypeKey = 'WH'

      newVal['fromWarehouse'] = true 
      newVal['fromVessel'] = source && source.warehousetype == 'Port'
      newVal['source'] = source
      newVal['train'] = train 
      if (!train) {
        newVal['train'] = {'warehouse': source}
      }
      var oldSet = {'sku': sku.name}
      oldSet['warehouse'] = source.name 
      oldStockKey = getDictId(oldSet)

    } else if (submissionType == 'TrainAndWarehouse') {
      if (sku == null || amount == null || amount <= 0 || amountWh <= 0 || train == null || destination == null) {
        alert('Make sure you have specified a train, commodity, destination, and quantity.')
        setLoading(false)
        return
      }
  
      if (!train.warehouse || !train.warehouse.name) {
        alert('This train does not have an assigned warehouse.')
        setLoading(false)
        return
      }

      newTypeKey = 'TR'

      newVal['numberofbags'] = parseInt(newVal['numberofbags']) /*+ (newVal['numberofbagswh'] ? parseInt(newVal['numberofbagswh']) : 0)*/
      let bags_validated = validateBagCountsTrainAndWarehouse(newVal, wagons)
      if (!bags_validated) {
        setLoading(false)
        return 
      }

      newVal['train'] = train
      newVal['source'] = train
      newVal['wagons'] = wagons
      newVal['trainAndWarehouse'] = true
      newVal['numberofbags'] = parseInt(newVal['numberofbags']) /*+ (newVal['numberofbagswh'] ? parseInt(newVal['numberofbagswh']) : 0)*/

      oldSet = {'sku': sku.name}
      oldSet['train'] = train.name 

      warehouseSet = {'sku': sku.name}
      warehouseSet['warehouse'] = train.warehouse.name  

      oldStockKey = getDictId(oldSet)
      warehouseStockKey = getDictId(warehouseSet)

      newVal['netweightkgwh'] = parseInt(amountWh)
      newVal['finalweightkgwh'] = parseInt(amountWh)

    } else if (submissionType == 'Vessel') {

      if (sku == null || amount == null || amount <= 0 || operation == null || destination == null) {
        alert('Please select a corresponding transfer out request.')
        return
      }

      newTypeKey = 'VE'

      newVal['fromVessel'] = true 
      newVal['source'] = operation
      newVal['train'] = operation
      oldSet = {'sku': sku.name}
      oldSet['vessel'] = operation.name 
      oldStockKey = getDictId(oldSet)
    }

    newVal['dispatchNumber'] = newVal['dispatchNumber'].replace('TR', '@@').replace('WH', '@@').replace('VE', '@@').replace('@@', newTypeKey)
    newVal['editCommittedBy'] = context.warehouseUserInfo ? context.warehouseUserInfo.name : ''

    var dispatchRef = Firebase.firestore.collection('projects').doc(Firebase.userProject).collection("arrivals").doc(v.id)
    let editHistoryRef = Firebase.firestore.collection('projects').doc(Firebase.userProject).collection('arrivalsArchive').doc(v.id)
    // Reference of train or vessel for which we will DECREASE sent amounts
    // Both new and old MUST have this set, as everything will be associated with a train or vessel
    var docRefPlanEntityOld = oldVal['fromVessel'] ? Firebase.firestore.collection('projects').doc(Firebase.userProject).collection("operations").doc(oldVal.operation.operationId) : Firebase.firestore.collection('projects').doc(Firebase.userProject).collection("trains").doc(oldVal.train.trainId)
    var docRefPlanEntityNew = newVal['fromVessel'] ? Firebase.firestore.collection('projects').doc(Firebase.userProject).collection("operations").doc(newVal.operation.operationId) : Firebase.firestore.collection('projects').doc(Firebase.userProject).collection("trains").doc(newVal.train.trainId)

    // Reference of warehouse for which we will decrease sent amounts. This will occur either if 
    // the old set was from a warehouse directly, or if it was a "trainAndWarehouse"
    // It is possible that neither new or old will have this set, or that only one will. Because not everything is 
    // associated with a warehouse.
    var oldStockDocRef = Firebase.firestore.collection('projects').doc(Firebase.userProject).collection('barleyStocks').doc(oldStockKey)
    var oldStockDocRefPrevious = Firebase.firestore.collection('projects').doc(Firebase.userProject).collection('barleyStocks').doc(previousOldStockKey)

    var oldWarehouseRef = warehouseStockKey ? Firebase.firestore.collection('projects').doc(Firebase.userProject).collection('barleyStocks').doc(warehouseStockKey) : null 
    var oldWarehouseRefPrevious = previousWarehouseStockKey ? Firebase.firestore.collection('projects').doc(Firebase.userProject).collection('barleyStocks').doc(previousWarehouseStockKey) : null 


    var innerPlanRefOld = null 
    var innerPlanRefNew = null
    if (newVal['planId'] != null && newVal['innerPlanId'] != null) {
      innerPlanRefNew = Firebase.firestore.collection('projects').doc(Firebase.userProject).collection('plansInner').doc(newVal['planId']).collection('contents').doc(newVal['innerPlanId'])
    }
    if (oldVal['planId'] != null && oldVal['innerPlanId'] != null) {
      innerPlanRefOld = Firebase.firestore.collection('projects').doc(Firebase.userProject).collection('plansInner').doc(oldVal['planId']).collection('contents').doc(oldVal['innerPlanId'])
    }

    // Reference of inner plan for which we will DECREASE sent amounts

    var same_plan_entity = false 
    var same_old_stock_entity = false 

    var same_inner_plan = false 

    /**
     * If both warehouses are secondary and match, then both entites
     * MUST be "trainAndWarehouse"
     * 
     * If one warehouse is secondary and matches the primary on the other,
     * then the secondary MUST be "trainAndWarehouse" and the primary
     * MUST be "Warehouse"
     * 
     * We currently don't have "vesselAndWarehouse" but if we did,
     * this logic should still work because the oldStockKeys are 
     * agnostic to the vessel vs train discrepancy
     * 
     * If oldStockKeys match, then neither of the oldStockKeys can possibly
     * match one of the secondary warehouse keys (though the secondary warehouse keys
     * could still match eachother). 
     */

    // Both old and new are "trainAndWarehouse" variety and the warehouses match
    var warehouse_match_secondary = false 
    // Old is "trainAndWarehouse" and new is "warehouse" and the warehouses match, therefore the primary oldstocks DO NOT MATCH
    var old_warehouse_match_new_primary = false 
    // New is "trainandWarehouse" and old is "warehouse" and the warehouses match, therefore the primary oldstocks DO NOT MATCH
    var new_warehouse_match_old_primary = false 

    if (oldStockKey == previousOldStockKey) {
      same_old_stock_entity = true 
    }

    if (warehouseStockKey != null && (warehouseStockKey == previousWarehouseStockKey)) {
      warehouse_match_secondary = true 
    }

    if (warehouseStockKey != null && (warehouseStockKey == previousOldStockKey)) {
      new_warehouse_match_old_primary = true 
    }

    if (previousWarehouseStockKey != null && (previousWarehouseStockKey == oldStockKey)) {
      old_warehouse_match_new_primary = true 
    }

    // If the mid level plan (train or vessel) is shared between the pre and post edit versions
    if ((newVal['fromVessel'] && oldVal['fromVessel'] && newVal.operation.operationId == oldVal.operation.operationId) || 
    (!newVal['fromVessel'] && !oldVal['fromVessel'] && newVal.train.trainId == oldVal.train.trainId) ) {
      same_plan_entity = true 
    }

    if ((newVal['planId'] != null && newVal['innerPlanId'] != null) && (oldVal['planId'] != null && oldVal['innerPlanId'] != null)
    && newVal['planId'] == oldVal['planId'] && newVal['innerPlanId'] == oldVal['innerPlanId']) {
      same_inner_plan = true 
    }

    var oldWarehouseValue = null 
    var newWarehouseValue = null 

    var oldStockValue = null 
    var newStockValue = null 

    var oldPlanEntityValue = null 
    var newPlanEntityValue = null 

    var oldInnerPlanValue = null 
    var newInnerPlanValue = null 

    console.log('sets')
    console.log(previousSet)
    console.log(previousWarehouseSet)
    console.log(oldSet)
    console.log(warehouseSet)

    console.log(previousOldStockKey)
    console.log(previousWarehouseStockKey)
    console.log(oldStockKey)
    console.log(warehouseStockKey)

    let transaction = await Firebase.firestore.runTransaction(async t => {

      // We could optimize on reads a bit here by checking for matches,
      // but it is simpler to read everything every time

      oldWarehouseValue = await getValueForRef(t, oldWarehouseRefPrevious, 
        'The warehouse associated with the existing waybill no longer has any stock.')
      newWarehouseValue = await getValueForRef(t, oldWarehouseRef, 
        'The warehouse associated with the planned edit no longer has any stock.')
  
      oldStockValue = await getValueForRef(t, oldStockDocRefPrevious, 
        'The train, operation, or warehouse associated with the existing waybill no longer has any stock.') 
      // This might not have to exist yet, error for not existing will be determined later
      newStockValue = await getValueForRef(t, oldStockDocRef, 
        null) 
  
      oldPlanEntityValue = await getValueForRef(t, docRefPlanEntityOld, 
        'The train, or operation associated with the existing waybill no longer exists.')  
      newPlanEntityValue = await getValueForRef(t, docRefPlanEntityNew, 
        'The train, or operation associated with the planned edit no longer exists.')  
  
      oldInnerPlanValue = await getValueForRef(t, innerPlanRefOld, 
        'The yearly plan row associated with the existing waybill no longer exists.')  
      newInnerPlanValue = await getValueForRef(t, innerPlanRefNew, 
        'The train, or operation associated with the planned edit no longer exists.')  
    
      let editHistoryRes = await t.get(editHistoryRef)
      
      // Now we need to update everything. This time we have to check for matches
      // and process each scenario uniquely. 
      // For cases where both values are different, we perform the equivalent of 
      // "void old and edit/create new"
      // For cases where the values are the same, we perform "edit"
      // We can't do "void old and edit new" when they are the same, because
      // we would have to do a read after the void, and we can't read, then write, then read
      // in a firestore transaction
      
      // Modify the quantities sent in the train or vessel plan(s)
      if (same_plan_entity) {
        // Just edit the quantity in the existing plan
        // Even if the warehouses are the same, this should work, 
        // as in this case we'll just subtract and then add again.

        var newAllocTrain = oldPlanEntityValue.sentAmounts ? oldPlanEntityValue.sentAmounts : {}
        let whidOld = oldVal.warehouse.customId 
        let newAmtOld = newAllocTrain[whidOld] ? newAllocTrain[whidOld] : 0
        let finAmtOld = Math.max(newAmtOld - oldVal['netweightkg'], 0)
        newAllocTrain[whidOld] = finAmtOld

        let whidNew = newVal.warehouse.customId
        let newAmtNew = newAllocTrain[whidNew] ? newAllocTrain[whidNew] : 0
        let finAmtNew = Math.max(newAmtNew + newVal['netweightkg'], 0)
        newAllocTrain[whidNew] = finAmtNew

        await t.update(docRefPlanEntityOld, {'sentAmounts': newAllocTrain})
      } else {
        var oldAllocTrain = oldPlanEntityValue.sentAmounts ? oldPlanEntityValue.sentAmounts : {}
        let whidOld = oldVal.warehouse.customId 
        let newAmtOld = oldAllocTrain[whidOld] ? oldAllocTrain[whidOld] : 0
        let finAmtOld = Math.max(newAmtOld - oldVal['netweightkg'], 0)
        oldAllocTrain[whidOld] = finAmtOld
        console.log(oldAllocTrain)
        console.log(whidOld)
        console.log(newAmtOld)
        console.log(finAmtOld)
        await t.update(docRefPlanEntityOld, {'sentAmounts': oldAllocTrain})

        var newAllocTrain = newPlanEntityValue.sentAmounts ? newPlanEntityValue.sentAmounts : {}
        let whid = newVal.warehouse.customId 
        let newAmt = newAllocTrain[whid] ? newAllocTrain[whid] : 0
        let finAmt = Math.max(newAmt + newVal['netweightkg'], 0)
        newAllocTrain[whid] = finAmt 
        await t.update(docRefPlanEntityNew, {'sentAmounts': newAllocTrain})
      }

      // Inner plans are easier to handle the cases for
      // We can either add/subtract on one (where we know it is the same warehouse
      // because otherwise the inner plans would be different) or subtract 
      // on one and add on the other
      if (same_inner_plan) {
        let current = oldInnerPlanValue.receivedqty ? oldInnerPlanValue.receivedqty : 0
        let amountDifference = (current - oldVal['netweightkg']) + newVal['netweightkg']
        let updateValInnerPlan = {'receivedqty': Math.max(amountDifference, 0)}

        await t.update(innerPlanRefOld, updateValInnerPlan)
      } else {
        if (oldInnerPlanValue != null) {
          let current = oldInnerPlanValue.receivedqty ? oldInnerPlanValue.receivedqty : 0
          let updateValInnerPlan = {'receivedqty': Math.max(current - oldVal['netweightkg'], 0)}
          await t.update(innerPlanRefOld, updateValInnerPlan)
        }
        if (newInnerPlanValue != null) {
          let current = newInnerPlanValue.receivedqty ? newInnerPlanValue.receivedqty : 0
          let updateValInnerPlan = {'receivedqty': Math.max(current + newVal['netweightkg'], 0)}
          await t.update(innerPlanRefNew, updateValInnerPlan)
        }
      }




      if (same_old_stock_entity) {
        /**
         * We can just add/subtract on this old stock entity.
         * We know that the warehouse entities (if they exists)
         * CANNOT match either of these old stock entities,
         * since the only way for either of these entites to be a 
         * warehouse entity and match would be if they are both warehouses,
         * which would mean neither are "xxxAndWarehouse"
         */
        await transactionStockUpdate(t, oldStockValue, oldStockDocRef, oldSet, oldVal, newVal, newVal['fromWarehouse'])  
        if (warehouse_match_secondary) {
          await transactionStockUpdate(t, oldWarehouseValue, oldWarehouseRef, warehouseSet, oldVal, newVal, true, 'netweightkgwh', 'netweightkgwh')
        } else {
          // We might need to update one or both warehouses here alone, with addition/subtraction
          if (oldWarehouseRef) {
            await transactionStockUpdateSubtract(t, newWarehouseValue, oldWarehouseRef, newVal, true,'netweightkgwh') 
          } 
          if (oldWarehouseRefPrevious) {
            await transactionStockUpdateAdd(t, oldWarehouseValue, oldWarehouseRefPrevious, previousWarehouseSet, oldVal, 'netweightkgwh') 
          }

        }
      } else if (old_warehouse_match_new_primary) {
        await transactionStockUpdate(t, newStockValue, oldStockDocRef, oldSet, oldVal, newVal, true, 'netweightkgwh', 'netweightkg')
        // We need to update old primary alone here, with addition
        // We may need to update new warehouse alone here, with subtraction
        await transactionStockUpdateAdd(t, oldStockValue, oldStockDocRefPrevious, previousSet, oldVal, 'netweightkg') 
        if (oldWarehouseRef) {
          await transactionStockUpdateSubtract(t, newWarehouseValue, oldWarehouseRef, newVal, true,'netweightkgwh') 
        } 

      } else if (new_warehouse_match_old_primary) {
        await transactionStockUpdate(t, oldStockValue, oldStockDocRefPrevious, previousSet, oldVal, newVal, true, 'netweightkg', 'netweightkgwh')
        // We need to update new primary alone here, with subtraction
        // We may need to update old warehouse alone here, with addition
        await transactionStockUpdateSubtract(t, newStockValue, oldStockDocRef, newVal, newVal['fromWarehouse'],'netweightkg') 
        if (oldWarehouseRefPrevious) {
          await transactionStockUpdateAdd(t, oldWarehouseValue, oldWarehouseRefPrevious, previousWarehouseSet, oldVal, 'netweightkgwh') 
        }

      } else if (warehouse_match_secondary) {
        await transactionStockUpdate(t, oldWarehouseValue, oldWarehouseRef, warehouseSet, oldVal, newVal, true, 'netweightkgwh', 'netweightkgwh')
        // We need to update both NON-MATCHING primaries here, with addition and subtraction
        await transactionStockUpdateAdd(t, oldStockValue, oldStockDocRefPrevious, previousSet, oldVal, 'netweightkg') 
        await transactionStockUpdateSubtract(t, newStockValue, oldStockDocRef, newVal, newVal['fromWarehouse'],'netweightkg') 
      } else {
        // If we reach this case, NOTHING has been update yet and NOTHING matches
        // We need to update both primaries and possibly both warehouses with mix of addition and subtraction
        await transactionStockUpdateAdd(t, oldStockValue, oldStockDocRefPrevious, previousSet, oldVal, 'netweightkg') 
        await transactionStockUpdateSubtract(t, newStockValue, oldStockDocRef, newVal, newVal['fromWarehouse'],'netweightkg') 
        if (oldWarehouseRef) {
          await transactionStockUpdateSubtract(t, newWarehouseValue, oldWarehouseRef, newVal, true,'netweightkgwh') 
        } 
        if (oldWarehouseRefPrevious) {
          await transactionStockUpdateAdd(t, oldWarehouseValue, oldWarehouseRefPrevious, previousWarehouseSet, oldVal, 'netweightkgwh') 
        }
      }

      // We have FINALLY done every auxiliary update necessary! Really need to move to relational db for this kind of thing...
      
      await t.update(dispatchRef, newVal)

      var ox = JSON.parse(JSON.stringify(oldVal))
      var setv = {}
      setv[Date.now()] = ox 

      if (editHistoryRes.exists) {
        await t.update(editHistoryRef, setv)
      } else {
        await t.set(editHistoryRef, setv)
      }

      return t 
    }).then(result => {
        
      console.log('Transaction success!' + result);
      return {'transaction': true,  'newVal': newVal, 'oldNumericSave': newVal['dispatchNumber'], 'newValId': v.id}
    }).catch(err => {
      console.log('Transaction failure:', err);
      return {'transaction': null, 'failMessage': err}
    });


    if (!newVal.dontRegenerateDocs && transaction && transaction.transaction != null) {
      newVal = transaction.newVal 
      var oldNumericSave = transaction.oldNumericSave
      var newValId = transaction.newValId
  
      let s = async () => {
          var datPack = {}
          const imageRef = Firebase.storage.ref('test').child('pdf_for_wab_' + oldNumericSave + '.pdf')
          let v = await imageRef.getDownloadURL();
          console.log(v)
          console.log('got it')
          datPack['pdflink'] = v 
          datPack['dispatchId'] = newValId
          let snone = () => console.log('x')
          Firebase.provideWaybillLink(datPack, snone, snone)
          //window.open(v)
  
      } 
  
      let f = () => {
       
      }
      let s2 = async () => {
        var datPack = {}
        const imageRef = Firebase.storage.ref('test').child('pdf_for_whout_' + oldNumericSave + '.pdf')
        let v = await imageRef.getDownloadURL();
        console.log(v)
        console.log('got it')
        datPack['pdflink'] = v 
        datPack['dispatchId'] = newValId
        let snone = () => console.log('x')
        Firebase.provideWarehouseOutLink(datPack, snone, snone)
        //window.open(v)

    } 

    let f2 = () => {
     
    }
    var datxx = {}
    datxx['val'] = newVal
    datxx['userId'] = 'test'
    if (newVal['fromWarehouse'] || newVal['trainAndWarehouse']) {
      Firebase.generateWarehouseOut(datxx, s2, f2)
    } 
      Firebase.generateWaybill(datxx, s, f)
    navigate(-1)
  } else if (transaction && transaction.failMessage) {
    alert(transaction.failMessage.msg)
  } else {
    navigate(-1)
  }

    setLoading(false)
    // Account for case of different typeKeys (i.e. WH to TR)
    


  }

  function getDispatchType(v) {
    if (v.fromWarehouse) {
      return 'Warehouse'
    } 
    if (v.fromVessel) {
      return 'Vessel'
    }
    return 'Train'
  }

  function  dictToList(d) {
    if (d) {
     return Object.keys(d).map((key, idx) => {
       return d[key]
     })
    } else {
      return []
    }
  }


  function getDictId(dct) {
    var keys = Object.keys(dct)
    keys.sort()
    var s = ''
    for (var i = 0; i < keys.length; i++) {
      let key = keys[i]
      if (i > 0) {
        s += '-'
      }
      s += key 
      s += ':'
      s += dct[key]
    }

    s += '-yearcode:25'

    return s 
  }


  function getType(t) {
    if (t == 'date') {
      return 'date'
    } else if (t == 'number') {
      return 'number' 
    } else if (t == 'list' || t == 'custom') {
      return t
    } else {
      return null
    }
  }

  function updateAutoValues(k, v) {
    var o = autoValues
    o[k] = v 
    setAutoValues(o)
  }

  function getAutoOptions(context, k) {
    if (k in context.barleyInCustomObjects) {
      return Object.keys(context.barleyInCustomObjects[k]).map((key, id) => {
        return context.barleyInCustomObjects[k][key]
      })
    }
    return []
  }


  function specialUpdate(key, val, setFieldValue, values) {

    setFieldValue(key, val)
    if (key == 'numberofbags') {
      if (values.averagebagweight) {
        setAmount(val * values.averagebagweight)
      }

    } else if (key == 'averagebagweight') {
      if (values.numberofbags) {
        setAmount(val * values.numberofbags)
      }
    }

    
  } 

  function specialUpdateWh(key, val, setFieldValue, values) {

    setFieldValue(key, val)
    if (key == 'numberofbagswh') {
      if (values.averagebagweightwh) {
        setAmountWh(val * values.averagebagweightwh)
      }

    } else if (key == 'averagebagweightwh') {
      if (values.numberofbagswh) {
        setAmountWh(val * values.numberofbagswh)
      }
    }

    
  } 

  function specialUpdateTwo(context, setFieldValue, val) {

    console.log('try set spec update')
    console.log(val)
    console.log(context.transporterMappingDict)
    setFieldValue('transportservice', val)
    var qv = val.split('/').join('*@*@SLASH@*@*')
    qv = qv.toLowerCase()
    console.log(qv)
    if (qv in context.transporterMappingDict) {
      setFieldValue('transporterphone', context.transporterMappingDict[qv].value)
    }
    
  } 

  function compare2(a,b) {
    let as = a.sortNumCreate ? a.sortNumCreate : -1000
    let bs = b.sortNumCreate ? b.sortNumCreate : -1000

    if (as > bs) {
      return -1
    } else if (bs > as) {
      return 1
    } else {
      return 0
    }
}

  function renderContent(fieldsmain, touched, errors, handleBlur, handleChange, values, setFieldValue, context, activeStep) {
    
    
    var fields = fieldsmain 

      fields.sort((a,b) => compare2(a,b))

    var columns = Math.floor(fields.length / 3)
    if (columns * 3 < fields.length) {
      columns += 1
    }
    var colrow = [...Array(columns).keys()]
    var rows = [0,1,2]
    return <div style={{width: activeStep == 1 ? '100%' : '50%', alignSelf: 'center'}}>
      {colrow.map((item, idx) => {
        return <div style={{display: 'flex', flexDirection: 'row'}}>
        {rows.map((inner, innerI) => {
          let fieldIdx = (3*item) + inner 

          if (fieldIdx < fields.length) {
            let tp = getType(fields[fieldIdx].fieldType)
            if (tp == 'custom') {

             return <Autocomplete
              value={autoValues[fields[fieldIdx].fieldKey]}
              fullWidth
              style={{margin: 10}}
              onChange={(event, newValue) => {
                if (typeof newValue === 'string') {
                  updateAutoValues(fields[fieldIdx].fieldKey, {name: newValue})
                  setFieldValue(fields[fieldIdx].fieldKey, {'customId': newValue.customId, 'name': newValue.name})

                } else if (newValue && newValue.inputValue) {
                  // Create a new value from the user input
                  updateAutoValues(fields[fieldIdx].fieldKey, {name: newValue.inputValue})
                  setFieldValue(fields[fieldIdx].fieldKey, {'customId':-1, 'name': newValue.inputValue})
                } else {
                  updateAutoValues(fields[fieldIdx].fieldKey, newValue)
                  if (newValue == null) {
                    setFieldValue(fields[fieldIdx].fieldKey, {'customId': '0', 'name': 'None'})
                  } else {
                    setFieldValue(fields[fieldIdx].fieldKey, {'customId': newValue.customId, 'name': newValue.name})
                  }
                }
                //console.log(event.target.value)
                //console.log(newValue)
              }}
              filterOptions={(options, params) => {
                const filtered = filter(options, params);
        
                // Suggest the creation of a new value
                if (params.inputValue !== '') {
                  filtered.push({
                    inputValue: params.inputValue,
                    name: `Add "${params.inputValue}"`,
                  });
                }
        
                return filtered;
              }}
              selectOnFocus
              clearOnBlur
              handleHomeEndKeys
              id="free-solo-with-text-demo"
              options={getAutoOptions(context, fields[fieldIdx].fieldKey)}
              getOptionLabel={(option) => {
                // Value selected with enter, right from the input
                if (typeof option === 'string') {
                  return option;
                }
                // Add "xxx" option created dynamically
                if (option.inputValue) {
                  return option.inputValue;
                }
                // Regular option
                return option.name;
              }}
              renderOption={(option) => option.name}
              freeSolo
              renderInput={(params) => (
                <TextField {...params}  error={Boolean(touched[fields[fieldIdx].fieldKey] && errors[fields[fieldIdx].fieldKey])}
                fullWidth
                key={fields[fieldIdx].fieldKey}
                id={fields[fieldIdx].fieldKey}
                helperText={touched[fields[fieldIdx].fieldKey] && errors[fields[fieldIdx].fieldKey]}
                label={fields[fieldIdx].fieldName}
                margin="normal"
                name={fields[fieldIdx].fieldKey}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values[fields[fieldIdx].fieldKey]}
                variant="outlined"/>
              )}
            />



            } else if (tp == 'list') {
              let opts = fields[fieldIdx].listOptions
              return <TextField
              select 
              error={Boolean(touched[fields[fieldIdx].fieldKey] && errors[fields[fieldIdx].fieldKey])}
              fullWidth
              style={{margin: 10}}
              key={fields[fieldIdx].fieldKey}
              id={fields[fieldIdx].fieldKey}
              helperText={touched[fields[fieldIdx].fieldKey] && errors[fields[fieldIdx].fieldKey]}
              label={fields[fieldIdx].fieldName}
              margin="normal"
              name={fields[fieldIdx].fieldKey}
              onBlur={handleBlur}
              onChange={handleChange}
              value={values[fields[fieldIdx].fieldKey]}
              variant="outlined">
              {opts.map((item, idx) => {
                return (<MenuItem value={item}>{item}</MenuItem>)
              })}
            </TextField>
            } else {
              return <TextField
              error={Boolean(touched[fields[fieldIdx].fieldKey] && errors[fields[fieldIdx].fieldKey])}
              fullWidth
              style={{marginLeft: 0, marginTop: 10, marginBottom: 10}}
              id={fields[fieldIdx].fieldKey}
              key={fields[fieldIdx].fieldKey}
              helperText={touched[fields[fieldIdx].fieldKey] && errors[fields[fieldIdx].fieldKey]}
              label={fields[fieldIdx].fieldName}
              margin="normal"
              name={fields[fieldIdx].fieldKey}
              onBlur={handleBlur}
              onChange={fields[fieldIdx].fieldKey in specOptions ? (e) => specialUpdate(fields[fieldIdx].fieldKey, e.target.value, setFieldValue, values) : fields[fieldIdx].fieldKey == 'transportservice' ? (e) => specialUpdateTwo(context, setFieldValue, e.target.value) : handleChange}
              value={values[fields[fieldIdx].fieldKey]}
              variant="outlined"
              type={getType(fields[fieldIdx].fieldType)}
              InputLabelProps={{
                shrink: true,
                }}
            />
            }

          }
          
          return null 

        })}
       </div>
      })}
    </div>
  }
 

  function validateBagCountsTrain(newVal, wagons) {
    let bagsTotal = parseInt(newVal['numberofbags'])
    var sumFromWagons = 0
    wagons.map((item, idx) => {
      sumFromWagons += item.qty && item.qty != '' && item.qty >= 0 ? parseInt(item.qty) : 0
    })

    if (bagsTotal != sumFromWagons) {
      alert('Total number of bags (' + bagsTotal + ') should equal the sum of bags from all the wagons (' + sumFromWagons + ').')
      return false 
    }

    return true 

  }

  function validateBagCountsTrainAndWarehouse(newVal, wagons) {
    console.log('check newval')
    console.log(newVal)
    let bagsTotal = parseInt(newVal['numberofbags'])
    var sumFromWagons = 0
    wagons.map((item, idx) => {
      sumFromWagons += item.qty && item.qty != '' && item.qty >= 0 ? parseInt(item.qty) : 0
    })

    var sumFromWarehouse = newVal['numberofbagswh'] ? parseInt(newVal['numberofbagswh']) : 0
    var sumTotal = sumFromWarehouse + sumFromWagons
    if (bagsTotal != sumTotal) {
      alert('Total number of bags (' + bagsTotal + ') should equal the sum of bags from all the wagons (' + sumFromWagons + ') plus the bags from the warehouse (' + sumFromWarehouse + ') for a total of ' + sumTotal + ' bags.')
      return false 
    }

    return true 
  }

  function updateWagons(k, idx, v) {
    var wag = wagons.map((item, id) => {
      if (id == idx) {
        var ret = item 
        ret[k] = v 
        return ret 
      }
      return item 
    })
    setWagons(wag)
  }
  
  function updateLaborers(k, idx, v) {
    var wag = laborers.map((item, id) => {
      if (id == idx) {
        var ret = item 
        ret[k] = v 
        return ret 
      }
      return item 
    })
    setLaborers(wag)
  }

  function filterDestinationsVessel(whOptions, operation, context, values) {

    if (!operation) {
      return []
    }
    var s = ''
    var planIds = []
    var consignee = null
    if (operation && operation.yearlyPlans) {
      s = 'Associated Plan(s): '
      operation.yearlyPlans.map((item, idx) => {
        if (idx > 0) {
          s += ', '
        }

        planIds.push(item)
        
        if (item in context.planDict) {
          s += context.planDict[item].name
          consignee = context.planDict[item].consignee
        }
      })
      //return s
    }
    var planId = null
    if (planIds.length > 0) {
      planId = planIds[0]
    }

    console.log(operation)
    console.log(planId)
    

    if (!planId) {
      return []
    }

    var dsdct = {}
    if (planId && planId in context.innerPlansDict) {
      let innerDict = context.innerPlansDict[planId]
      Object.keys(innerDict).map((key, idx) => {
        let innerPlan = innerDict[key]
        //console.log('check inner plan')
        //console.log(innerPlan)
        if (innerPlan && innerPlan.warehouse) {
            let whs = innerPlan.warehouse.name + '-' + innerPlan.warehouse.union
            dsdct[whs] = 1
        }
      })
      console.log('here is dsdct')
      console.log(dsdct)
      return whOptions.filter((item, idx) => {
        let whs = item.name + '-' + item.union 
        return whs in dsdct
      })
    } else {
      console.log('checking if it is in')
      console.log(values.planId)
      console.log(context.innerPlansDict)
      return []
    }

  }

  function renderPlanInformation(operation, destination, sku, context, setFieldValue, values) {

    if (!operation) {
      return ''
    }
    var s = ''
    var planIds = []
    var consignee = null
    if (operation && operation.yearlyPlans) {
      s = 'Associated Plan(s): '
      operation.yearlyPlans.map((item, idx) => {
        if (idx > 0) {
          s += ', '
        }

        planIds.push(item)
        
        if (item in context.planDict) {
          s += context.planDict[item].name
          consignee = context.planDict[item].consignee
        }
      })
      //return s
    }
    var innerPlanIds = []
    var outerPlanIds = []
    if (destination && sku && destination.union) {
      let nameTarget = destination.name 
      let unionTarget = destination.union 
      let skuTarget = sku.name 
      var priceList = []

      planIds.map((plan, idx) => {
        if (plan in context.innerPlansDict) {
          let innerDict = context.innerPlansDict[plan]
          Object.keys(innerDict).map((key, idx) => {
            let innerPlan = innerDict[key]
            //console.log('check inner plan')
            //console.log(innerPlan)
            if (innerPlan && innerPlan.sku && innerPlan.warehouse
              && innerPlan.sku.name == skuTarget && innerPlan.warehouse.name == nameTarget
              && innerPlan.warehouse.union == unionTarget /*&& innerPlan.unitPrice*/) {
                priceList.push(innerPlan.unitPrice)
                innerPlanIds.push(key)
                outerPlanIds.push(plan)
            }

          })
        }
      })
      /*console.log('INSIDE HERE')
      console.log(planIds)
      console.log(nameTarget)
      console.log(unionTarget)
      console.log(skuTarget)*/
      if (priceList.length > 0 && innerPlanIds.length > 0) {
        var priceStr = '. Planned Price(s) per Quintal: '
        priceList.map((price, idx) => {
          if (idx > 0) {
            priceStr += ', '
          }
          priceStr += price 
        })
        //s += priceStr 
        if (values.unitprice != priceList[0] || values.innerPlanId != innerPlanIds[0] || values.planId != outerPlanIds[0]) {
          /*console.log(values.unitprice)
          console.log(priceList[0])
          console.log(values.innerPlanId)
          console.log(innerPlanIds[0])*/
          setFieldValue('planId', outerPlanIds[0])
          setFieldValue('innerPlanId', innerPlanIds[0])
          setFieldValue('unitprice', priceList[0])

        }

      } else if (values.unitprice != null || values.innerPlanId != null || values.planId != null) {
        //console.log(values.unitprice)
        //console.log(values.innerPlanId)
        setFieldValue('planId', null)
        setFieldValue('innerPlanId', null)
        setFieldValue('unitprice', null)
      
      }
     
    }

    if (consignee != values.consignee) {
      setFieldValue('consignee', consignee)
    }


    /*if (innerPlanIds.length < 1) {
      s += ' Note: There is not a plan corresponding to the current destination, sku, and operation.'
    }*/

    return s

  }

  function testShort(train, context) {
    let bd = context.barleyInCustomObjects['warehouse']
    var mayHaveShortCode = null
    if (train && train.warehouse && train.warehouse.customId && train.warehouse.customId in bd) {
      //alert(train.warehouse.customId)
      mayHaveShortCode = bd[train.warehouse.customId]
      //alert(JSON.stringify(mayHaveShortCode))
    }
    return JSON.stringify(mayHaveShortCode)
  }

  function destFilter(warehouse, train) {
    if (!train || !train.allocations) {
      return true 
    } else {
      return (warehouse.customId in train.allocations)
    }
  }

  function renderTrainStats(trn, dst) {

    if (!dst || !dst.customId) {
      return null
    }
    if (!trn || !trn.allocations) {
      return null 
    }
    let cid = dst.customId
    let allocAmount = trn.allocations[cid] ? trn.allocations[cid] : 0
    var sndAmount = trn.sentAmounts && trn.sentAmounts[cid] ? trn.sentAmounts[cid]: 0
    sndAmount = (sndAmount / 1000).toFixed(2)
    let remainingAmount = (allocAmount - sndAmount).toFixed(2)
    return <div style={{marginLeft: 50, alignSelf: 'center', flexDirection: 'columm', alignItems: 'center', flex: 1, justifyContent: 'center'}}>
      <Typography variant="h4">{'Amount Sent to ' + dst.name + ' / ' + dst.union}</Typography>
      <Typography style={{marginTop: 10}}>
        {sndAmount + ' / ' + allocAmount + ' Tons Sent'}
      </Typography>
      <Typography style={{marginTop: 10}}>
        {remainingAmount + ' / ' + allocAmount + ' Tons Remaining'}
      </Typography>

    </div>
  }

  function momentFormat(date) {
    return moment(date).format('yyyy-MM-DD') + 'T' + moment(date).format('HH:mm')
  }

  function renderLaborers(laborers) {
    return <div style={{width: '50%'}}>
  {laborers.map((item, idx) => {
    return <div>
                    <TextField
              id={'laborerName'}
              key={idx + 'laborerName'}
              
              label={'Laborer Group'}
              margin="normal"
              name={'laborerName'}
              onChange={(e) => updateLaborers('name', idx, e.target.value)}
              value={laborers[idx].name}
              variant="outlined"
              type={'text'}
              InputLabelProps={{
                shrink: true,
                }}
            />
                                <TextField
              id={'laborerQty'}
              key={idx + 'laborerQty'}
              label={'Amount Moved by Group'}
              margin="normal"
              name={'wagonQty'}
              onChange={(e) => updateLaborers('qty', idx, e.target.value)}
              value={laborers[idx].qty}
              variant="outlined"
              type={'number'}
              InputLabelProps={{
                shrink: true,
                }}
            />

            <XIcon  style={{marginTop: 30}} onClick={(e) => {
              setLaborers(laborers.filter((item, idxx) => {
                return idxx != idx
              }))
            }}/>
      </div>
  })}
  <Button
                       color="primary"
                    
                       size="large"
                       id="sendButtonNext"
                       variant="contained"
                       onClick={() => {
                         var wag = laborers 
                         wag.push({'name': '', 'qty': ''})
                         setLaborers(wag)
                         forceUpdate()
                       }}
                       style={{margin: 5, width: 150}}
                     >
                       Add Group
                     </Button>
  
</div>
  }

  function renderOnlyTransporter(fields, touched, errors, handleBlur, handleChange, values, setFieldValue, context) {
    let fieldIdx = 0
    let fieldIdx2 = 1

    if (!fields || !fields[fieldIdx] || !fields[fieldIdx2]) {
      return null
    }


    return <div style={{flexDirection: 'row', display: 'flex'}}><Autocomplete
    value={values[fields[fieldIdx].fieldKey]}
    style={{width: 200, marginRight: 5}}
    onChange={(event, newValue) => {
   
      if (typeof newValue === 'string') {
        updateAutoValues(fields[fieldIdx].fieldKey, {name: newValue})
        setFieldValue(fields[fieldIdx].fieldKey, newValue/*{'customId': newValue.customId, 'name': newValue.name}*/)
        if (fields[fieldIdx].fieldKey == 'transportservice') {
          
          if ('phone' in newValue) {
            setFieldValue('transporterphone', newValue['phone'])
          }
        }
      } else if (newValue && newValue.inputValue) {
        // Create a new value from the user input
        updateAutoValues(fields[fieldIdx].fieldKey, {name: newValue.inputValue})
        setFieldValue(fields[fieldIdx].fieldKey, {'customId':-1, 'name': newValue.inputValue})
      } else {
        updateAutoValues(fields[fieldIdx].fieldKey, newValue)
        if (newValue == null) {
          setFieldValue(fields[fieldIdx].fieldKey, {'customId': '0', 'name': ''})
        } else {
          setFieldValue(fields[fieldIdx].fieldKey, newValue/*{'customId': newValue.customId, 'name': newValue.name}*/)
          if (fields[fieldIdx].fieldKey == 'transportservice') {
          
            if ('phone' in newValue) {
              setFieldValue('transporterphone', newValue['phone'])
            }
          }
        }
      }
      //console.log(event.target.value)
      //console.log(newValue)
    }}
    selectOnFocus
    clearOnBlur
    handleHomeEndKeys
    freeSolo
    id={values[fields[fieldIdx].fieldKey]}
    options={getAutoOptions(context, 'transporters')}
    getOptionLabel={(option) => option.name ? option.name : ''}
    renderOption={(option) => option.name ? option.name : ''}
    renderInput={(params) => <TextField {...params}
    disabled={false}
    label={fields[fieldIdx].fieldName}
    margin="normal"
    variant="outlined"
    name={fields[fieldIdx].fieldKey}
    onBlur={handleBlur}
    value={values[fields[fieldIdx].fieldKey]}
    defaultValue={values[fields[fieldIdx].fieldKey]} />}
  />

              <TextField
              error={Boolean(touched[fields[fieldIdx2].fieldKey] && errors[fields[fieldIdx2].fieldKey])}
              style={{width: 200, marginLeft: 5}}
              id={fields[fieldIdx2].fieldKey}
              key={fields[fieldIdx2].fieldKey}
              helperText={touched[fields[fieldIdx2].fieldKey] && errors[fields[fieldIdx2].fieldKey]}
              label={fields[fieldIdx2].fieldName}
              margin="normal"
              name={fields[fieldIdx2].fieldKey}

              value={values[fields[fieldIdx2].fieldKey]}
              variant="outlined"
              type={getType(fields[fieldIdx2].fieldType)}
              InputLabelProps={{
                shrink: true,
                }}
            />

                            <Button
                        color="primary"
                        style={{margin: 5, marginLeft: 20, width: 185, alignSelf: 'center'}}
                        disabled={loading}
                        id="sendButtonNext"
                        variant="contained"
                        onClick={() => setAddTransporterDialog(true)}
                      >
                        New Transporter
                      </Button>
                      </div>

  }

  function renderOnlyVehicle(fields, touched, errors, handleBlur, handleChange, values, setFieldValue, context) {
    let fieldIdx = 0
    let fieldIdx2 = 1
    if (!fields || !fields[fieldIdx] || !fields[fieldIdx2]) {
      return null
    }
    return <div style={{flexDirection: 'row', display: 'flex'}}><Autocomplete
    value={values[fields[fieldIdx].fieldKey]}
    style={{width: 200, marginRight: 5}}
    onChange={(event, newValue) => {
      if (typeof newValue === 'string') {
        updateAutoValues(fields[fieldIdx].fieldKey, {name: newValue})
        setFieldValue(fields[fieldIdx].fieldKey, newValue/*{'customId': newValue.customId, 'name': newValue.name}*/)
        if (fields[fieldIdx].fieldKey == 'platenumber') {
          
          if ('trailerplate' in newValue) {
            setFieldValue('trailerplatenumber', newValue['trailerplate'])
          } else if ('trailerPlate' in newValue) {
            setFieldValue('trailerplatenumber', newValue['trailerPlate'])
          }
        }
      } else if (newValue && newValue.inputValue) {
        // Create a new value from the user input
        updateAutoValues(fields[fieldIdx].fieldKey, {name: newValue.inputValue})
        setFieldValue(fields[fieldIdx].fieldKey, {'customId':-1, 'name': newValue.inputValue})
      } else {
        updateAutoValues(fields[fieldIdx].fieldKey, newValue)
        if (newValue == null) {
          setFieldValue(fields[fieldIdx].fieldKey, {'customId': '0', 'name': ''})
        } else {
          setFieldValue(fields[fieldIdx].fieldKey, newValue/*{'customId': newValue.customId, 'name': newValue.name}*/)
          if (fields[fieldIdx].fieldKey == 'platenumber') {
          
            if ('trailerplate' in newValue) {
              setFieldValue('trailerplatenumber', newValue['trailerplate'])
            } else if ('trailerPlate' in newValue) {
              setFieldValue('trailerplatenumber', newValue['trailerPlate'])
            }
          }
        }
      }
      //console.log(event.target.value)
      //console.log(newValue)
    }}
    selectOnFocus
    clearOnBlur
    handleHomeEndKeys
    freeSolo
    id={values[fields[fieldIdx].fieldKey]}
    options={getAutoOptions(context, 'vehicles')}
    getOptionLabel={(option) => option.plate ? option.plate : ''}
    renderOption={(option) => option.plate ? option.plate : ''}
    renderInput={(params) => <TextField {...params}
    disabled={false}
    label={fields[fieldIdx].fieldName}
    margin="normal"
    variant="outlined"
    name={fields[fieldIdx].fieldKey}
    onBlur={handleBlur}
    value={values[fields[fieldIdx].fieldKey]}
    defaultValue={values[fields[fieldIdx].fieldKey]} />}
  />

              <TextField
              error={Boolean(touched[fields[fieldIdx2].fieldKey] && errors[fields[fieldIdx2].fieldKey])}
              style={{width: 200, marginLeft: 5}}
              id={fields[fieldIdx2].fieldKey}
              key={fields[fieldIdx2].fieldKey}
              helperText={touched[fields[fieldIdx2].fieldKey] && errors[fields[fieldIdx2].fieldKey]}
              label={fields[fieldIdx2].fieldName}
              margin="normal"
              name={fields[fieldIdx2].fieldKey}
              onBlur={handleBlur}
              onChange={handleChange}
              value={values[fields[fieldIdx2].fieldKey]}
              variant="outlined"
              type={getType(fields[fieldIdx2].fieldType)}
              InputLabelProps={{
                shrink: true,
                }}
            />

                            <Button
                        color="primary"
                        style={{margin: 5, marginLeft: 20, width: 185, alignSelf: 'center'}}
                        disabled={loading}
                        id="sendButtonNext"
                        variant="contained"
                        onClick={() => setAddVehicleDialog(true)}
                      >
                        New Vehicle
                      </Button>
                      </div>

  }

  function getTotalAmount(a,b) {
    var ret = 0
    if (a) {
      ret += a
    } 
    /*if (b) {
      ret += b
    }*/
    return ret
  }

  function renderOnlyDriver(fields, touched, errors, handleBlur, handleChange, values, setFieldValue, context) {
    let fieldIdx = 0
    let fieldIdx2 = 1
    if (!fields || !fields[fieldIdx] || !fields[fieldIdx2]) {
      return null
    }
    return <div style={{flexDirection: 'row', display: 'flex'}}><Autocomplete
    value={values[fields[fieldIdx].fieldKey]}
    style={{width: 200, marginRight: 5}}
    onChange={(event, newValue) => {
      if (typeof newValue === 'string') {
       
        updateAutoValues(fields[fieldIdx].fieldKey, {name: newValue})
        setFieldValue(fields[fieldIdx].fieldKey, newValue/*{'customId': newValue.customId, 'name': newValue.name}*/)
        if (fields[fieldIdx].fieldKey == 'drivername') {

          if ('phone' in newValue) {
            setFieldValue('driverphone', newValue['phone'])
          }
          if ('dlnumber' in newValue) {
            setFieldValue('dlnumber', newValue['dlnumber'])
          }
        }
      } else if (newValue && newValue.inputValue) {
        // Create a new value from the user input
        updateAutoValues(fields[fieldIdx].fieldKey, {name: newValue.inputValue})
        setFieldValue(fields[fieldIdx].fieldKey, {'customId':-1, 'name': newValue.inputValue})
      } else {
        updateAutoValues(fields[fieldIdx].fieldKey, newValue)
        if (newValue == null) {
          setFieldValue(fields[fieldIdx].fieldKey, {'customId': '0', 'name': ''})
        } else {
          setFieldValue(fields[fieldIdx].fieldKey, newValue/*{'customId': newValue.customId, 'name': newValue.name}*/)
          if (fields[fieldIdx].fieldKey == 'drivername') {

            if ('phone' in newValue) {
              setFieldValue('driverphone', newValue['phone'])
            }
            if ('dlnumber' in newValue) {
              setFieldValue('dlnumber', newValue['dlnumber'])
            }
          }
        }
      }
      //console.log(event.target.value)
      //console.log(newValue)
    }}
    selectOnFocus
    clearOnBlur
    handleHomeEndKeys
    freeSolo
    id={values[fields[fieldIdx].fieldKey]}
    options={getAutoOptions(context, 'drivers')}
    getOptionLabel={(option) => option.name ? option.name : ''}
    renderOption={(option) => option.name ? option.name : ''}
    renderInput={(params) => <TextField {...params}
    disabled={false}
    label={fields[fieldIdx].fieldName}
    margin="normal"
    variant="outlined"
    name={fields[fieldIdx].fieldKey}
    onBlur={handleBlur}
    value={values[fields[fieldIdx].fieldKey]}
    defaultValue={values[fields[fieldIdx].fieldKey]} />}
  />

              <TextField
              error={Boolean(touched[fields[fieldIdx2].fieldKey] && errors[fields[fieldIdx2].fieldKey])}
              style={{width: 200, marginLeft: 5}}
              id={fields[fieldIdx2].fieldKey}
              key={fields[fieldIdx2].fieldKey}
              helperText={touched[fields[fieldIdx2].fieldKey] && errors[fields[fieldIdx2].fieldKey]}
              label={fields[fieldIdx2].fieldName}
              margin="normal"
              name={fields[fieldIdx2].fieldKey}
              onBlur={handleBlur}
              onChange={handleChange}
              value={values[fields[fieldIdx2].fieldKey]}
              variant="outlined"
              type={getType(fields[fieldIdx2].fieldType)}
              InputLabelProps={{
                shrink: true,
                }}
            />

                            <Button
                        color="primary"
                        style={{margin: 5, marginLeft: 20, width: 185, alignSelf: 'center'}}
                        disabled={loading}
                        id="sendButtonNext"
                        variant="contained"
                        onClick={() => setAddDriverDialog(true)}
                      >
                        New Driver
                      </Button>
                      </div>

  }

  function lookupValue(v, k, k2, k3, context) {

    if (v[k] && typeof v[k] == 'object' && 'customId' in v[k]) {
      return v[k]
    }

    var ret = null 
    let opt = getAutoOptions(context, k2)
    opt.map((item, idx) => {
      if (item && item[k3] && item[k3] == v[k]) {
        ret = item 
      }
    })

    return ret 
  }

  function extractDriverTransporterVehicle(v, context) {
    var f = v
    console.log(v)
    console.log('rep')
    f['transportservice'] = lookupValue(f, 'transportservice', 'transporters', 'name', context)
    f['drivername'] = lookupValue(f, 'drivername', 'drivers', 'name', context)
    f['platenumber'] = lookupValue(f, 'platenumber', 'vehicles', 'plate', context)
    if (!f['transportservice']) {
      delete f['transportservice']
    }
    if (!f['platenumber']) {
      delete f['platenumber']
    }
    if (!f['drivername']) {
      delete f['drivername']
    }
    console.log(f)
    return f 
  }

  return (
    <MyContext.Consumer>{context => (
  <Page
    className={classes.root}
    height='100%'
    title="Dispatch"
    id="ff2"
    backgroundColor={classes.root.backgroundColor}
  >
    <Box
      display="flex"
      flexDirection="column"
      id="bb2"
      backgroundColor={classes.root.backgroundColor}

    >
                  <AddTransporterDialog
            context={context}
            fields={dictToList(context.barleyInCustomObjectFields['transporters'])}
        open={addTransporterDialog}
        onClose={() => setAddTransporterDialog(false)}
        alertTitle={'Add Transporter'}
        alertCancelText={'Cancel'}
        alertConfirmText={'Confirm'}
        onConfirm={(x) => setAddTransporterDialog(false)}
        />
                          <AddDriverDialog
            context={context}
            fields={dictToList(context.barleyInCustomObjectFields['drivers'])}
        open={addDriverDialog}
        onClose={() => setAddDriverDialog(false)}
        alertTitle={'Add Driver'}
        alertCancelText={'Cancel'}
        alertConfirmText={'Confirm'}
        onConfirm={(x) => setAddDriverDialog(false)}
        />
                          <AddVehicleDialog
            context={context}
            fields={dictToList(context.barleyInCustomObjectFields['vehicles'])}
        open={addVehicleDialog}
        onClose={() => setAddVehicleDialog(false)}
        alertTitle={'Add Vehicle'}
        alertCancelText={'Cancel'}
        alertConfirmText={'Confirm'}
        onConfirm={(x) => setAddVehicleDialog(false)}
        />
      <Container id="cc2"  backgroundColor={classes.root.backgroundColor}>
        <Formik
        id='f2'
          initialValues={state && state.customer ? extractDriverTransporterVehicle(JSON.parse(JSON.stringify(state.customer)), context) : {
            averagebagweight: 50,
            averagebagweightwh: 50, 
            warehouseoutlink: 'x',
            dispatchdate: Date.now()
          }}
          validationSchema={
            Yup.object().shape({

            })
          }
          onSubmit={(values) => {
            //doSubmit(values)
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting2,
            setSubmitting, 
            touched,
            values,
            setFieldValue
          }) => (
            <form id='fu2' onSubmit={handleSubmit}>
                  <div id='fkx1' style={{marginTop: 5}}>
              <Box mb={3}>
                <Typography
                  color="textPrimary"
                  variant="h2"
                >
                 {'Editing ' + state.customer.dispatchNumber}
                </Typography> 
              </Box>

              <Stepper activeStep={activeStep} style={{width: '100%', marginBottom: 15}}>
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>

            </Step>
          );
        })}
      </Stepper>

      {activeStep == 0 ? 

<div>
  <div style={{flexDirection: 'row'}}>

  {context.targetWarehouse == -1 || (context && context.targetWarehouse && context.targetWarehouse.warehousetype == 'Port') ? 
    <TextField select
    disabled={false}
    style={{minWidth: 250}}
    label="Source Type"
    margin="normal"
    id="arrivalType"
    variant="outlined"
    name="arrivalType"
    value={arrivalType}
    onChange={(e) => setArrivalType(e.target.value)}
  >
    {['Train', 'Warehouse', 'Vessel'].map((item, idx) => {
      return (<MenuItem value={item}>{item}</MenuItem>)
    })}
  </TextField>
  :
  <TextField select
  disabled={false}
  style={{minWidth: 250}}
  label="Source Type"
  margin="normal"
  id="arrivalType"
  variant="outlined"
  name="arrivalType"
  value={arrivalType}
  onChange={(e) => setArrivalType(e.target.value)}
>
  {['Train', 'Warehouse'].map((item, idx) => {
    return (<MenuItem value={item}>{item}</MenuItem>)
  })}
</TextField>
  } 



        <Typography>{renderPlanInformation(operation, destination, sku, context, (x,y) => setFieldValue(x,y), values)}</Typography>
    </div>



    {arrivalType == 'Vessel' 
? 
<div style={{flexDirection: 'row', display: 'flex'}}>
<div style={{width: '40%'}}>


<Autocomplete
  id="operation"
  disabled={false}
  options={context.operationList}
  getOptionLabel={(option) => option.operationnumber + ' - ' + option.name}
  value={operation}
  onChange={(e, value) => {   
    if (value != null) {
      setOperation(value)
      setTrain(null)
      setSku(null)
    }
  }}
  renderInput={(params) => <TextField {...params}
  disabled={false}
  label="Operation"
  margin="normal"
  id="operation"
  name="operation"
  variant="outlined"
  value={operation}
  defaultValue={operation} />}
/>


<Autocomplete
  id="destination"
  disabled={false}
  options={filterDestinationsVessel(getAutoOptions(context, 'warehouse'), operation, context, values)}
  getOptionLabel={(option) => option.union ? option.name + ' / ' + option.union : option.name}
  value={destination}
  onChange={(e, value) => {   
    if (value != null) {
      setDestination(value)
    }
  }}
  renderInput={(params) => <TextField {...params}
  disabled={false}
  label="Destination"
  margin="normal"
  id="destination"
  name="destination"
  variant="outlined"
  value={destination}
  defaultValue={destination} />}
/>

<Autocomplete
  id="sku"
  disabled={false}
  options={getAutoOptions(context, 'sku').filter((item, idx) => {
  return operation == null || (operation.sku && item && operation.sku.name == item.name)
})}
  getOptionLabel={(option) => option.name}
  value={sku}
  onChange={(e, value) => {   
    if (value != null) {
      setSku(value)
    }
  }}
  renderInput={(params) => <TextField {...params}
  disabled={false}
  label="SKU"
  margin="normal"
  id="sku"
  name="sku"
  variant="outlined"
  value={sku}
  defaultValue={sku} />}
/>
</div>
  </div>
: null}


{arrivalType == 'Train' 
? 
<div style={{flexDirection: 'row', display: 'flex'}}>
<div style={{width: '40%'}}>


<Autocomplete
  id="operation"
  disabled={false}
  options={context.operationList}
  getOptionLabel={(option) => option.operationnumber + ' - ' + option.name}
  value={operation}
  onChange={(e, value) => {   
    if (value != null) {
      setOperation(value)
      setTrain(null)
      setSku(null)
    }
  }}
  renderInput={(params) => <TextField {...params}
  disabled={false}
  label="Operation"
  margin="normal"
  id="operation"
  name="operation"
  variant="outlined"
  value={operation}
  defaultValue={operation} />}
/>


<Autocomplete
  id="train"
  disabled={state.requestPreset != null}
  options={context.trainList.filter((item, idx) => {
  return operation == null || (item.operations && item.operations.includes(operation.operationId))
})}
  getOptionLabel={(option) => option.name}
  value={train}
  onChange={(e, value) => {
    
    if (value != null) {
     
  
       setTrain(value)
       setSku(null)
    }
    

  }}
  renderInput={(params) => <TextField {...params}
  disabled={state.requestPreset != null}
  label="Train"
  margin="normal"
  id="train"
  name="train"
  variant="outlined"
  value={train}
  defaultValue={train} />}
/>

<Autocomplete
  id="destination"
  disabled={false}
  options={getAutoOptions(context, 'warehouse').filter((item, idx) => {
    return destFilter(item, train)
  })}
  getOptionLabel={(option) => option.union ? option.name + ' / ' + option.union : option.name}
  value={destination}
  onChange={(e, value) => {   
    if (value != null) {
      setDestination(value)
    }
  }}
  renderInput={(params) => <TextField {...params}
  disabled={false}
  label="Destination"
  margin="normal"
  id="destination"
  name="destination"
  variant="outlined"
  value={destination}
  defaultValue={destination} />}
/>

<Autocomplete
  id="sku"
  disabled={false}
  options={getAutoOptions(context, 'sku').filter((item, idx) => {
  return operation == null || (operation.sku && item && operation.sku.name == item.name)
})}
  getOptionLabel={(option) => option.name}
  value={sku}
  onChange={(e, value) => {   
    if (value != null) {
      setSku(value)
    }
  }}
  renderInput={(params) => <TextField {...params}
  disabled={false}
  label="SKU"
  margin="normal"
  id="sku"
  name="sku"
  variant="outlined"
  value={sku}
  defaultValue={sku} />}
/>
</div>
{renderTrainStats(train, destination)}
  </div>
: null}
{arrivalType == 'Warehouse'
? 
<div style={{flexDirection: 'row', display: 'flex'}}>
<div style={{width: '40%'}}>

<Autocomplete
  id="source"
  disabled={false}
  options={getAutoOptions(context, 'warehouse').filter((item, idx) => {
    return item && (item.warehousetype == 'Internal' || item.warehousetype == 'Port') && (context.targetWarehouse == -1 || item.name in context.targetWarehouseSet)
  })}
  getOptionLabel={(option) => option.name}
  value={source}
  onChange={(e, value) => {   
    if (value != null) {
      setSource(value)
      setOperation(null)
      setTrain(null)
      setDestination(null)
      setSku(null)
    }
  }}
  renderInput={(params) => <TextField {...params}
  disabled={false}
  label="Source"
  margin="normal"
  id="source"
  name="source"
  variant="outlined"
  value={source}
  defaultValue={source} />}
/>


<Autocomplete
  id="operation"
  disabled={false}
  options={context.operationList}
  getOptionLabel={(option) => option.operationnumber + ' - ' + option.name}
  value={operation}
  onChange={(e, value) => {   
    if (value != null) {
      setOperation(value)
      setTrain(null)
      setSku(null)
    }
  }}
  renderInput={(params) => <TextField {...params}
  disabled={false}
  label="Operation"
  margin="normal"
  id="operation"
  name="operation"
  variant="outlined"
  value={operation}
  defaultValue={operation} />
  
  
  }
/>

{source && source.warehousetype == 'Port' ? null

:<Autocomplete
id="train"
disabled={state.requestPreset != null}
options={context.trainList.filter((item, idx) => {
  if (source && source.name && item.warehouse && item.warehouse.name != source.name) {
    return false
  }
return operation == null || (item.operations && item.operations.includes(operation.operationId))
})}
getOptionLabel={(option) => option.name}
value={train}
onChange={(e, value) => {
  
  if (value != null) {
   

     setTrain(value)
     setSku(null)
  }
  

}}
renderInput={(params) => <TextField {...params}
disabled={state.requestPreset != null}
label="Train"
margin="normal"
id="train"
name="train"
variant="outlined"
value={train}
defaultValue={train} />}
/>
}




<Autocomplete
  id="destination"
  disabled={false}
  options={getAutoOptions(context, 'warehouse').filter((item, idx) => {
    return destFilter(item, train)
  })}
  getOptionLabel={(option) => option.union ? option.name + ' / ' + option.union : option.name}
  value={destination}
  onChange={(e, value) => {   
    if (value != null) {
      setDestination(value)
    }
  }}
  renderInput={(params) => <TextField {...params}
  disabled={false}
  label="Destination"
  margin="normal"
  id="destination"
  name="destination"
  variant="outlined"
  value={destination}
  defaultValue={destination} />}
/>

<Autocomplete
  id="sku"
  disabled={false}
  options={getAutoOptions(context, 'sku').filter((item, idx) => {
  return operation == null || (operation.sku && item && operation.sku.name == item.name)
})}
  getOptionLabel={(option) => option.name}
  value={sku}
  onChange={(e, value) => {   
    if (value != null) {
      setSku(value)
    }
  }}
  renderInput={(params) => <TextField {...params}
  disabled={false}
  label="SKU"
  margin="normal"
  id="sku"
  name="sku"
  variant="outlined"
  value={sku}
  defaultValue={sku} />}
/>

</div>
{renderTrainStats(train, destination)}
  </div>
: null}


  </div>


: <div style={{display: 'flex', flexDirection: 'row', width: activeStep == 2 ? '90%' : '100%'}}>
  <div style={{width: '50%'}}>
    {activeStep == 1 ? 
    renderOnlyTransporter(state.fields.filter((item, idx) => {
      return item.fieldKey == 'transportservice' || item.fieldKey == 'transporterphone'
    }), touched, errors, handleBlur, handleChange, values, setFieldValue, context)
  : null}
      {activeStep == 1 ? 
    renderOnlyVehicle(state.fields.filter((item, idx) => {
      return item.fieldKey == 'platenumber' || item.fieldKey == 'trailerplatenumber'
    }), touched, errors, handleBlur, handleChange, values, setFieldValue, context)
  : null}
      {activeStep == 1 ? 
    renderOnlyDriver(state.fields.filter((item, idx) => {
      return item.fieldKey == 'drivername' || item.fieldKey == 'driverphone'
    }), touched, errors, handleBlur, handleChange, values, setFieldValue, context)
  : null}
  {renderContent(state.fields.filter((item, idx) => {
  return stepFields[activeStep].includes(item.fieldKey) && item.fieldKey != 'displaybol' && item.fieldKey != 'displaydeclaration' && item.fieldKey != 'dispatchdate' && item.fieldKey != 'transportservice'
}), touched, errors, handleBlur, handleChange, values, setFieldValue, context, activeStep)}

{activeStep == 1 ? 
  <DateTimeMaster label={'Dispatch Date & Time'} value={momentFormat(values.dispatchdate)} disabled={false} onChange={(e) => setFieldValue('dispatchdate', e)}/>
: null}
</div>

{activeStep == 1 && arrivalType != 'Train' ? renderLaborers(laborers) : null}

</div>}
{activeStep == 2 && arrivalType == 'Train' ?
  <div>
  {wagons.map((item, idx) => {
    return <div>
                    <TextField
              id={'wagonName'}
              key={idx + 'wagonName'}
              
              label={'Wagon Number'}
              margin="normal"
              name={'wagonName'}
              onChange={(e) => updateWagons('name', idx, e.target.value)}
              value={wagons[idx].name}
              variant="outlined"
              type={'text'}
              InputLabelProps={{
                shrink: true,
                }}
            />
                                <TextField
              id={'wagonQty'}
              key={idx + 'wagonQty'}
              label={'Number of Bags From Wagon'}
              margin="normal"
              name={'wagonQty'}
              onChange={(e) => updateWagons('qty', idx, e.target.value)}
              value={wagons[idx].qty}
              variant="outlined"
              type={'number'}
              InputLabelProps={{
                shrink: true,
                }}
            />
                                            <TextField
              id={'wagonBag'}
              key={idx + 'wagonBag'}
              label={'Average Bag Weight'}
              margin="normal"
              name={'wagonBag'}
              onChange={(e) => updateWagons('bag', idx, e.target.value)}
              value={wagons[idx].bag}
              variant="outlined"
              type={'number'}
              InputLabelProps={{
                shrink: true,
                }}
            />
            <XIcon  style={{marginTop: 30}} onClick={(e) => {
              setWagons(wagons.filter((item, idxx) => {
                return idxx != idx
              }))
            }}/>
      </div>
  })}
  <Button
                       color="primary"
                    
                       size="large"
                       id="sendButtonNext"
                       variant="contained"
                       onClick={() => {
                         var wag = wagons 
                         wag.push({'name': '', 'qty': '', 'bag': 50})
                         setWagons(wag)
                         forceUpdate()
                       }}
                       style={{margin: 5, width: 150}}
                     >
                       Add Wagon
                     </Button>
  
                     <Box
        alignItems="center"
        display="flex"
        ml={-1}
      >
        <Checkbox
        id="showAllVehicles"
          checked={values.trainAndWarehouse}
          name="showAllVehicles"
          onChange={(e) =>{
            if (!values.trainAndWarehouse) {
              setFieldValue('trainAndWarehouse', true)
            } else {
              setFieldValue('trainAndWarehouse', false)
            }
          }}
        />
        <Typography
          color="textPrimary"
          variant="body1"
        >
          Include Warehouse Stock
        </Typography>
        <Tooltip placement="right-end" title="Check this box to include some cargo from the warehouse.">
                                <Help />
                            </Tooltip>
      </Box>
      {values.trainAndWarehouse ?

    <div style={{flexDirection: 'row'}}>
      <TextField
                         id={'twhQty'}
                         key={'twhQty'}
                         label={'Number of Bags (Warehouse)'}
                         margin="normal"
                         name={'numberofbagswh'}
                         onChange={(e) => specialUpdateWh('numberofbagswh', e.target.value, setFieldValue, values)}
                         value={values.numberofbagswh}
                         variant="outlined"
                         type={'number'}
                         InputLabelProps={{
                           shrink: true,
                           }}
                       />
                                                       <TextField
                         id={'twhBag'}
                         key={'twhBag'}
                         label={'Average Bag Weight (Warehouse)'}
                         margin="normal"
                         name={'wagonBag'}
                         onChange={(e) => specialUpdateWh('averagebagweightwh', e.target.value, setFieldValue, values)}
                         value={values.averagebagweightwh}
                         variant="outlined"
                         type={'number'}
                         InputLabelProps={{
                           shrink: true,
                           }}
                       />

      </div>
    
    : null}
  </div>
  : null

}
</div>


{activeStep == 2 ? 
              <div>
                <Typography>
                  {'Net Weight: ' + getTotalAmount(amount, amountWh) + 'kg'}
                </Typography>
                </div>

                : null}

<Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'row', pt: 2 }}>


<Button
                       color="primary"
                       disabled={activeStep == 0}
                       size="large"
                       id="sendButtonNext"
                       variant="contained"
                       onClick={handleBack}
                       style={{margin: 5, width: 150}}
                     >
                       {'Back'}
                     </Button>



{activeStep < steps.length - 1 ? 
                       <Button
                       color="primary"
                       disabled={operation == null || loading || arrivalType == null ||  (arrivalType == 'Warehouse' && (source == null || destination == null || source.name == destination.name)) || (arrivalType == 'Train' && (train == null || sku == null || destination == null))}
                       size="large"
                       id="sendButtonNext"
                       variant="contained"
                       onClick={handleNext}
                       style={{margin: 5, width: 150}}
                     >
                       Next
                     </Button>
: 
<Box my={2} style={{flexDirection: 'row', display: 'flex'}}>
<Button
color="primary"
style={{margin: 5, width: 150}}
disabled={loading || amount == null || amount <= 0}
id="sendButtonNext"
variant="contained"
onClick={() => {
  if (arrivalType == 'Train') {
    if (values.trainAndWarehouse) {
      doSubmitEdit(values, JSON.parse(JSON.stringify(state.customer)), 'TrainAndWarehouse', context)
    } else {
      doSubmitEdit(values, JSON.parse(JSON.stringify(state.customer)), 'Train', context)
    }

  } else if (arrivalType == 'Warehouse') {
    doSubmitEdit(values, JSON.parse(JSON.stringify(state.customer)), 'Warehouse', context)
  } else if (arrivalType == 'Vessel') {
    doSubmitEdit(values, JSON.parse(JSON.stringify(state.customer)), 'Vessel', context)
  }
} }
>
Confirm
</Button>

<Box
        alignItems="center"
        display="flex"
        ml={-1}
      >
        <Checkbox
        id="showAllVehicles"
          checked={!values.dontRegenerateDocs}
          name="showAllVehicles"
          onChange={(e) =>{
            setFieldValue('dontRegenerateDocs', values.dontRegenerateDocs ? false : true)
          }}
        />
        <Typography
          color="textPrimary"
          variant="body1"
        >
          Regenerate Document(s)
        </Typography>
        <Tooltip placement="right-end" title="Check this box to have the waybill/transferout documents regenerated upon edit completion.">
                                <Help />
                            </Tooltip>
      </Box>


</Box>
}
</Box>

              {loading ? <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: 50}}><CircularProgress/></div> : null}

              
            </form>
          )}
        </Formik>
      </Container>
    </Box>
  </Page>
        )}</MyContext.Consumer>
  )
};

export default EditDispatchView;
