import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Doughnut } from 'react-chartjs-2';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Typography,
  colors,
  makeStyles,
  useTheme
} from '@material-ui/core';
import LaptopMacIcon from '@material-ui/icons/LaptopMac';
import PhoneIcon from '@material-ui/icons/Phone';
import TabletIcon from '@material-ui/icons/Tablet';
import MyContext from 'src/MyContext';

const RATING_REQ = 10 ;

const colorBase = [colors.indigo[500],
colors.red[600],
colors.orange[600], colors.blue[600], colors.green[600], colors.deepPurple[600], colors.pink[600], colors.lightBlue[600],
colors.lightGreen[600], colors.grey[600], colors.amber[600] ]


const useStyles = makeStyles(() => ({
  root: {
    height: '100%'
  }
}));

const BarleyStocks = ({ className, contents, warehouse, pendingDict, transitDict, ...rest }) => {
  const classes = useStyles();
  const theme = useTheme();

  const [maxLoc, setMaxLoc] = React.useState('');
  const [maxName, setMaxName] = React.useState('');
  const [maxColor, setMaxColor] = React.useState('black');

  var chartVar = {}

  

  const options = {
    animation: false,
    cutoutPercentage: 80,
    layout: { padding: 0 },
    legend: {
      display: false
    },
    maintainAspectRatio: false,
    responsive: true,
    tooltips: {
      backgroundColor: theme.palette.background.default,
      bodyFontColor: theme.palette.text.secondary,
      borderColor: theme.palette.divider,
      borderWidth: 1,
      enabled: true,
      footerFontColor: theme.palette.text.secondary,
      intersect: false,
      mode: 'index',
      titleFontColor: theme.palette.text.primary
    }
  };

  const devices = [
    {
      title: 'Desktop',
      value: 63,
      icon: LaptopMacIcon,
      color: colors.indigo[500]
    },
    {
      title: 'Tablet',
      value: 15,
      icon: TabletIcon,
      color: colors.red[600]
    },
    {
      title: 'Mobile',
      value: 23,
      icon: PhoneIcon,
      color: colors.orange[600]
    }
  ];

  const data = {
    datasets: [
      {
        data: [63, 15, 22],
        backgroundColor: [
          colors.indigo[500],
          colors.red[600],
          colors.orange[600]
          
        ],
        borderWidth: 8,
        borderColor: colors.common.white,
        hoverBorderColor: colors.common.white
      }
    ],
    labels: ['Desktop', 'Tablet', 'Mobile']
  };

  function mathRound(a, b) {
    return Math.round((a/b) * 100) / 100
  }

  function ratingCalc(userInfo, type) {
    var defText = 'No rating yet!'
    //alert(userInfo.pickupRatings + ' ' +  userInfo.totalRatings + mathRound(userInfo.pickupRatings/userInfo.totalRatings))
    if (userInfo && userInfo.totalRatings) {
      if (userInfo.totalRatings < RATING_REQ) {
        return defText
      }
      if (type) {
        //pickup
        if (!userInfo.pickupRatings) {
          return defText
        }
        return mathRound(userInfo.pickupRatings, userInfo.totalRatings) + ' / 5.00'
      } else {
        //dropoff
        if (!userInfo.dropoffRatings) {
          return defText
        }
        return mathRound(userInfo.dropoffRatings, userInfo.totalRatings) + ' / 5.00'
      }

    }
    return defText
  }

  function totalRatingCalc(userInfo) {
    var defText = 'No rating yet!'

    if (userInfo && userInfo.totalRatings) {
      if (userInfo.totalRatings < RATING_REQ) {
        return defText
      }

        if (!userInfo.pickupRatings || !userInfo.dropoffRatings) {
          return defText
        }
        return mathRound((userInfo.pickupRatings + userInfo.dropoffRatings), (2 * userInfo.totalRatings)) + ' / 5.00'
     

    }
    return defText
  }

  function trueRound(x) {
    if (typeof(x) == 'number') {
      return x.toFixed(2)
    } else {
      return x
    }

  }

  function getBarleyStockTitle(item) {
    var variety = ''
    var purity = ''
    var moisture = ''
    if (item.variety == 'other') {
      variety = 'All other varieties'
    } else {
      variety = item.variety 
    }
    if (item.uncategorized) {
      return 'Uncategorized'
    } else if (item.moisture == 'above') {
      return variety + ' // Variety all purity // Moisture > 15%'
    } else if (item.variety == 'other') {
      return variety + ' // Variety all purity // Moisture <= 15%'
    } else {
      if (item.purity == 'above') {
        purity = 'Variety purity >= 90%'
      } else if (item.purity == 'below') {
        purity = 'Variety purity < 90%'
      } 
      return variety + ' // ' + purity + ' // Moisture <=15%'
    }
  }

  function getKg(item, key) {
    var total = 0
    var available = 0
    var pending = 0
    var transit = 0
    if (item.netweightkg) {
      available = item.netweightkg
      total = item.netweightkg
    } 

    if (warehouse in pendingDict) {
      if (key in pendingDict[warehouse]) {
        let pendingItem = pendingDict[warehouse][key]
        if (pendingItem.netweightkg) {
          total += pendingItem.netweightkg
          pending = pendingItem.netweightkg
        }
      }
    }

    if (warehouse in transitDict) {
      if (key in transitDict[warehouse]) {
        let pendingItem = transitDict[warehouse][key]
        if (pendingItem.netweightkg) {
          transit = pendingItem.netweightkg
        }
      }
    }

    return total + 'kg Total (' + available + 'kg available, ' + pending + 'kg pending), ' + transit + 'kg IN TRANSIT'
  }

  return (
  
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <CardHeader title={'Warehouse: ' + warehouse} />
      <Divider />
      <CardContent>
       <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', margin: 10, flexDirection: 'column'}}>
         {Object.keys(contents).map((key, idx) => {
           let item = contents[key]
          return <div style={{alignItems: 'flex-start', alignSelf: 'flex-start'}}>
       <Typography
                color="textPrimary"
                variant="body1"
                
              >
                {getBarleyStockTitle(item)}
              </Typography>
              <Typography
                style={{ color: colors.indigo[600], marginTop: 8, marginBottom: 8 }}
                variant="body2"
              >
                {getKg(item, key)}
             
              </Typography>
              
            </div>
         })}

             
       </div>

        


      </CardContent>
    </Card>
  );
};

BarleyStocks.propTypes = {
  className: PropTypes.string
};

export default BarleyStocks;
