import React, {useState} from 'react';
import { Link as RouterLink, useNavigate} from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import Autocomplete from '@material-ui/lab/Autocomplete';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import {
  Box,
  Button,
  Checkbox,
  Container,
  FormHelperText,
  Link,
  TextField,
  Typography,
  makeStyles,
  Grid,
  Select,
  MenuItem,
  InputLabel,
  CircularProgress
} from '@material-ui/core';
import Page from 'src/components/Page';
import parse from 'autosuggest-highlight/parse';
import throttle from 'lodash/throttle';
import Firebase from 'src/Firebase'
import MuiPhoneNumber from 'material-ui-phone-number'
import DataFetcher from 'src/DataFetcher'
import LoadingView from 'src/LoadingView'
import JobMasterView from 'src/utils/JobMasterView'


const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

function loadScript(src, position, id) {
  if (!position) {
    return;
  }

  const script = document.createElement('script');
  script.setAttribute('async', '');
  script.setAttribute('id', id);
  script.src = src;
  position.appendChild(script);
}

const autocompleteService = { current: null };
const geocoder = {current: null};



const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  }
}));


const PublicQuoteView = ({cargoProps, ...rest}) => {
  const classes = useStyles();
  const navigate = useNavigate();

  const [value, setValue] = React.useState(null);
  const [valueTwo, setValueTwo] = React.useState(null);
  const [inputValue, setInputValue] = React.useState('');
  const [options, setOptions] = React.useState([]);
  const [optionsTwo, setOptionsTwo] = React.useState([]);
  const [numVal, setNumVal] = React.useState(true);
  const [actions, setActions] = React.useState(null);
  const [locationStart, setLocationStart] = React.useState(null);
  const [locationEnd, setLocationEnd] = React.useState(null);
  const [finalSet, setFinalSet] = React.useState(null);
  const [tableItems, setTableItems] = React.useState(cargoProps);
  const [isAuthenticating, setIsAuthenticating] = React.useState(false);
  const [verificationId, setVerificationId] = React.useState('');
  const [phone, setPhone] = React.useState('');

  const loaded = React.useRef(false);
  if (typeof window !== 'undefined' && !loaded.current) {
    if (!document.querySelector('#google-maps')) {
      loadScript(
        'https://maps.googleapis.com/maps/api/js?key=AIzaSyAkPLP3zHG-2kctVakLZwdUQHqeLVO0Onw&libraries=places',
        document.querySelector('head'),
        'google-maps',
      );
    }

    loaded.current = true;
  }

  function setuprecaptcha (phone, confirm){
    window.recaptchaVerifier = new Firebase.firebaseRef.auth.RecaptchaVerifier('get-quote-button', {
        size: 'invisible',
        callback: function (response) {
            console.log("recature resolved")
            this.onSignInSubmit(phone, false, confirm);
        }
    });

}

  const phoneVerify = (values, locStart, locEnd, isFirst, confirm, setVerificationId, setPhone) => {
    //console.log(phoneNumber)
    //console.log(typeof(phoneNumber))
    setLocationStart(locStart)
    setLocationEnd(locEnd)
    console.log(values)
    console.log(locationEnd)
    console.log(locationStart)
    setFinalSet(values)
    /*
    if (isFirst) {
      setuprecaptcha(values, confirm)
    }

    var appVerifier = window.recaptchaVerifier;
    console.log(appVerifier)
    Firebase.auth.signInWithPhoneNumber(values.phone.toString(), appVerifier)
    .then(function (confirmationResult) {
      // SMS sent. Prompt user to type the code from the message, then sign the
      // user in with confirmationResult.confirm(code).
      confirm()
      console.log('sent text')
      window.confirmationResult = confirmationResult;
      
      //alert(window.confirmationResult)
    }).catch(function (error) {
      console.log(error)
      // Error; SMS not sent
      // ...
    });*/

    let success = (dxx) => {
      console.log(dxx)
      if (dxx.result == 0) {
        alert('Error sending text message, please double check the phone number you are using.')
       //self.props.navigation.navigate('PhoneCodePrompt', {verificationId: phoneAuthSnapshot.verificationId,
        //  phoneNumber: self.state.phone})
      } else if (dxx.result == 1) {
        setVerificationId(dxx.verificationId)
        confirm()
        
      } else {
        alert('Error sending text message, please double check the phone number you are using.')
      } 
    }
  
    let fail = (dxx) => {
      alert('Error sending text message, please double check the phone number you are using.')
      //alert(JSON.stringify(dxx))
    }
    setPhone(values.phone.toString())
    Firebase.phoneLogin(values.phone.toString(), success, fail)
  }

  const phoneVerifyResend = (values, confirm, setVerificationId) => {

    let success = (dxx) => {
      console.log(dxx)
      if (dxx.result == 0) {
        alert('Error sending text message, please double check the phone number you are using.')
       //self.props.navigation.navigate('PhoneCodePrompt', {verificationId: phoneAuthSnapshot.verificationId,
        //  phoneNumber: self.state.phone})
      } else if (dxx.result == 1) {
        setVerificationId(dxx.verificationId)
        confirm()
        
      } else {
        alert('Error sending text message, please double check the phone number you are using.')
      } 
    }
  
    let fail = (dxx) => {
      alert('Error sending text message, please double check the phone number you are using.')
      //alert(JSON.stringify(dxx))
    }
    Firebase.trackMixpanel('tryPhoneLoginQuote', {'phone': values.phone.toString()})
    Firebase.phoneLogin(phone, success, fail)
  }

  function verifyCode(code, verificationId) {
   /* window.confirmationResult.confirm(code).then(function (result) {
      // User signed in successfully.
      var user = result.user;
      console.log(user.uid)
      console.log(finalSet)
      console.log(locationEnd)
      console.log(locationStart)
      console.log(Firebase.auth.currentUser.uid)
      //alert('logged in')
      Firebase.addNewQuoteUnauth(finalSet, locationStart, locationEnd, user.uid, () => console.log('s'), () => console.log('f'))
      // ...
    }).catch(function (error) {
      console.log(error)
      alert('bad code')
      // User couldn't sign in (bad verification code?)
      // ...
    });*/

    let success = async (dxx) => {
      //alert(JSON.stringify(dxx))
        if (dxx.result == 0) {
          Firebase.trackMixpanel('failCodeEntryQuote', {'code': code, 'phone': phone, })
          alert('Incorrect code.')
          return 
        } else if (dxx.result == -1) {
          Firebase.trackMixpanel('failCodeEntryQuote', {'code': code, 'phone': phone, })
          alert('Incorrect code.')
          return 
        } else if (dxx.result == -2) {
          Firebase.trackMixpanel('failCodeEntryQuote', {'code': code, 'phone': phone, })
          alert('Incorrect code.')
          return 
        } else {
          // Do something with your new credential, e.g.:
          await Firebase.auth.signInWithCustomToken(dxx.credential)//Firebase.auth.signInWithCredential(dxx.credential)
          let firebase_user_id = Firebase.auth.currentUser.uid;
          Firebase.uid = firebase_user_id
          console.log('FUUUUUUUUUUUUUUUUCK' + firebase_user_id)
          //alert(firebase_user_id)
    
          //var updateBody = {'userId': firebase_user_id, 'phone': selfRef.props.route.params.phoneNumber, 'userType': 3}
          //var datpack = {'updateBody': updateBody}
          Firebase.trackMixpanel('successCodeEntryQuote', {'code': code, 'phone': phone, 'uid': Firebase.auth.currentUser.uid})
          Firebase.addNewQuoteUnauth(finalSet, locationStart, locationEnd, firebase_user_id, () => console.log('s'), () => console.log('f'))
          //Firebase.addNewUserIfNeeded(email, companyName, phone + '', firebase_user_id)
          //return
          return
        }
  
    }
  
    let fail = (dxx) => {
      Firebase.trackMixpanel('failCodeEntryQuote', {'code': code, 'phone': phone, })
      alert('Incorrect code.')
      return 
    }
  
    Firebase.codeConfirm(verificationId, code + '', success, fail)
  }

  const fetch = React.useMemo(
    () =>
      throttle((request, callback) => {
        autocompleteService.current.getPlacePredictions(request, callback);
      }, 200),
    [],
  );

  React.useEffect(() => {
    //alert(JSON.stringify(cargoProps))
    setTableItems(cargoProps)

    let active = true;

    if (!autocompleteService.current && window.google) {
      autocompleteService.current = new window.google.maps.places.AutocompleteService();
      geocoder.current = new window.google.maps.Geocoder();
      console.log(autocompleteService.current)
    }
    if (!autocompleteService.current || !geocoder.current) {
      return undefined;
    }

    if (inputValue === '') {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetch({ input: inputValue }, (results) => {

      console.log(results)
      if (active) {
        let newOptions = [];

        if (value) {
          newOptions = [value];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      }
    });
    console.log('reached this point')
    if (typeof window !== 'undefined' && typeof Firebase.auth !== 'undefined') {
      console.log('dothis')
      window.recaptchaVerifier = new Firebase.firebaseRef.auth.RecaptchaVerifier('get-quote-button', {
        'size': 'invisible',
        'callback': function(response) {
          // reCAPTCHA solved, allow signInWithPhoneNumber.
          //phoneVerify(2);
          //alert('solved')
        }
      });
    }

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch, cargoProps]);


  if (isAuthenticating) {
    return (<Page
      className={classes.root}
      title="Request Quote"
      backgroundColor={classes.root.backgroundColor}
    ><div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}><CircularProgress/></div></Page>)
  }

  if (!numVal) {
    return (<Page
      className={classes.root}
      height='100%'
      title="Request Quote"
      backgroundColor={classes.root.backgroundColor}
    >
      <Box
        display="flex"
        flexDirection="column"
        height="90vh"
        justifyContent="center"
        backgroundColor={classes.root.backgroundColor}
 
      >
        <Container maxWidth="sm" backgroundColor={classes.root.backgroundColor}>
          <Formik
            initialValues={{
              code: '',
              policy: false
            }}
            validationSchema={
              Yup.object().shape({
                code: Yup.string().max(255).required('Enter the code from your phone'),
              })
            }
            onSubmit={(values) => {
              verifyCode(values.code, verificationId)
              //navigate('/app/dashboard', { replace: true });
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting2,
              touched,
              values
            }) => (
              <form onSubmit={handleSubmit}>
                <div id="fksa">
                <Box mb={3}>
                  <Typography
                    color="textPrimary"
                    variant="h2"
                  >
                    Verify Your Phone Number
                  </Typography>
                  <Typography
                    color="textSecondary"
                    gutterBottom
                    variant="body2"
                  >
                    We sent a text to verify your number. Enter the code here
                  </Typography>
                </Box>

                <TextField
                  error={Boolean(touched.cargoName && errors.cargoName)}
                  fullWidth
                  helperText={touched.cargoName && errors.cargoName}
                  label="Verification Code"
                  margin="normal"
                  name="code"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.code}
                  variant="outlined"
                />
 
                <Box my={2}>
                  <Button
                    color="primary"
                    disabled={isSubmitting2}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    Confirm
                  </Button>
                </Box>
                <Box my={2}>
                  <Button
                    color="primary"
                    disabled={isSubmitting2}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    onClick={() => {
                      if (actions != null) {
                        actions.setSubmitting(false)
                      }
                      phoneVerifyResend(phone, () => setNumVal(false), (x) => setVerificationId(x))
                    }}
                  >
                    Resend Text
                  </Button>

                </Box>
                </div>
                <Typography
                  color="textSecondary"
                  variant="body1"
                >
                  Use different number
                  {' '}
                  <Link
                    component={RouterLink}
                    to="/login"
                    variant="h6"
                  >
                    Go back
                  </Link>
                </Typography>
              </form>
            )}
          </Formik>
        </Container>
      </Box>
    </Page>)
  }

  if (!tableItems || tableItems == {} || isAuthenticating) {
    return (<LoadingView/>)
  }
  return (<JobMasterView editQuoteShipper={false} adminEdit={false} provideQuote={false} privateQuote={false} publicQuote={true} quoteOffer={false} evaluateBid={false} parentSubmit={(v, ls, le) => phoneVerify(v, ls, le, true, () => setNumVal(false), (x) => setVerificationId(x), (xy) => setPhone(xy))} parentState={{}} jobProps={{editable: true, priceEditable: false, showPrices: false, showAssignments: false}} />)


  return (
    <Page
      className={classes.root}
      title="Request Quote"
      backgroundColor={classes.root.backgroundColor}
    >
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        backgroundColor={classes.root.backgroundColor}
 
      >
        <Container maxWidth="sm" backgroundColor={classes.root.backgroundColor}>
          <Formik
            initialValues={{
              phone: '',
              shipperName: '',
              cargoName: '',
              pickupLocation: '',
              dropoffLocation: '',
              earlyDrop: '',
              lateDrop: '',
              earlyPick: '',
              latePick: '',
              policy: false,
              cargoType: '',
              cargoTypeDisp: '',
              cargoFields: {},
              trueWeight: ''
            }}
            validationSchema={
              Yup.object().shape({
                phone: Yup.string().max(255).required('Phone number is required'),
                shipperName: Yup.string().max(255).required('Shipper name is required'),
                cargoName: Yup.string().max(255).required('Cargo is required'),
                pickupLocation: Yup.string().max(255).required('Dropoff location is required'),
                dropoffLocation: Yup.string().max(255).required('Pickup location is required'),
                earlyPick: Yup.string().max(255).required('Pickup and dropoff times are required.'),
                earlyDrop: Yup.string().max(255).required('Pickup and dropoff times are required.'),
                cargoType: Yup.string().max(255).required('Cargo type is required'),
                policy: Yup.boolean().oneOf([true], 'This field must be checked'),
                trueWeight: Yup.number().min(1).required('Weight (metric tons) is required')
              })
            }
            onSubmit={(values, acts) => {
              //alert('tt')
              //console.log('sub')
              //setNumVal(false)
              phoneVerify(values, true, () => setNumVal(false))
              //navigate('/app/dashboard', { replace: true });
              setActions(acts)
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
              setFieldValue
            }) => (
              <form onSubmit={handleSubmit}>
                <Box mb={3}>
                  <Typography
                    color="textPrimary"
                    variant="h2"
                  >
                    Get a quote
                  </Typography>
                  <Typography
                    color="textSecondary"
                    gutterBottom
                    variant="body2"
                  >
                    Request a quote for your shipment. You will be contacted via phone within 24 hours
                  </Typography>
                </Box>
                <div id='fko'>
                <TextField
                  error={Boolean(touched.shipperName && errors.shipperName)}
                  fullWidth
                  helperText={touched.shipperName && errors.shipperName}
                  label="Shipper Name"
                  margin="normal"
                  name="shipperName"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.shipperName}
                  variant="outlined"
                />
               <InputLabel>Cargo</InputLabel>
                  <Select
          fullWidth
          label="Cargo Type"
          margin="normal"
          id="cargoType"
          name="cargoType"
          variant="outlined"
          value={values.cargoType}
          onChange={(e) => {
            console.log(e)
            setFieldValue('cargoType', e.target.value)
            setFieldValue('cargoName', tableItems[e.target.value].name)
            //setFieldValue('cargoTypeDisp', e.target.value.name)
          }}
        >
          {Object.keys(tableItems).map((item, idx) => {
            return (<MenuItem value={tableItems[item].val}>{tableItems[item].name}</MenuItem>)
          })}

        </Select>
        {values.cargoType != '' ? <div>{Object.keys(tableItems[values.cargoType].fields).map((item, idx) => {
            return <TextField
            id={values.cargoType + 'seldiv' + idx}
            key={values.cargoType + 'seldiv' + idx}
            error={Boolean(touched.cargoName && errors.cargoName)}
            fullWidth
            helperText={touched.cargoName && errors.cargoName}
            label={tableItems[values.cargoType].fields[item].name}
            margin="normal"
            name={tableItems[values.cargoType].fields[item].val}
            onBlur={handleBlur}
            onChange={(e) => {
              var temp = values.cargoFields
              temp[item] = e.target.value 
              setFieldValue('cargoFields', temp)
            }}
            value={values.cargoFields[item]}
            variant="outlined"
          />
          })}</div>: <div/>}
          <TextField
                  error={Boolean(touched.trueWeight && errors.trueWeight)}
                  fullWidth
                  helperText={touched.trueWeight && errors.trueWeight}
                  label="Weight (Metric Tons)"
                  margin="normal"
                  name="trueWeight"
                  type="number"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.trueWeight}
                  variant="outlined"
                />
                <MuiPhoneNumber defaultCountry={'et'}
                regions={['america','africa']}
                onlyCountries={['us', 'et']}
                autoFormat={false}
                disableAreaCodes={true}
                  error={Boolean(touched.email && errors.email)}
                  fullWidth
                  helperText={touched.email && errors.email}
                  label="Phone"
                  id="phone"
                  margin="normal"
                  name="phone"
                  onBlur={handleBlur}
                  onChange={(e) => setFieldValue('phone', e)}
                  value={values.phone}
                  variant="outlined"
                />
                </div>
                <Autocomplete
      id="pickupLocation"
      name="pickupLocation"
      style={{ width: '100%' }}
      getOptionLabel={(option) => (typeof option === 'string' ? option : option.description)}
      filterOptions={(x) => x}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={values.pickupLocation}
      onChange={(event, newValue) => {
        setOptions(newValue ? [newValue, ...options] : options);
        
        setValue(newValue);
        if (geocoder.current != null && newValue != null) {
          geocoder.current.geocode({ 
            'placeId': newValue.place_id
        }, 
        function(responses, status) {
            if (status == 'OK') {
                var lat = responses[0].geometry.location.lat();
                var lng = responses[0].geometry.location.lng();
                setLocationStart({'lat': lat, 'lng': lng})
                console.log(lat, lng);
            }
        });
        }
        setFieldValue('pickupLocation', newValue.description)

      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => (
        <TextField {...params} label="Pickup Location" variant="outlined" margin="normal" fullWidth onBlur={handleBlur}
        name="dropoffLocation"
        error={Boolean(touched.dropoffLocation && errors.dropoffLocation)}
        value={values.dropoffLocation}/>
      )}
      renderOption={(option) => {
        const matches = option.structured_formatting.main_text_matched_substrings;
        const parts = parse(
          option.structured_formatting.main_text,
          matches.map((match) => [match.offset, match.offset + match.length]),
        );

        return (
          <Grid container alignItems="center">
            <Grid item>
              <LocationOnIcon className={classes.icon} />
            </Grid>
            <Grid item xs>
              {parts.map((part, index) => (
                <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                  {part.text}
                </span>
              ))}

              <Typography variant="body2" color="textSecondary">
                {option.structured_formatting.secondary_text}
              </Typography>
            </Grid>
          </Grid>
        );
      }}
    />
                <Grid container justify="space-between">
                      <TextField
        label="Earliest Pickup"
        type="datetime-local"
        variant="outlined"
        margin="normal"
        name="earlyPick"
        onBlur={handleBlur}
        onChange={handleChange}
        value={values.earlyPick}
        error={Boolean(touched.earlyPick && errors.earlyPick)}
        className={classes.textField}
        InputLabelProps={{
          shrink: true,
        }}
      />
        </Grid>
<Autocomplete
      id="dropoffLocation"
      name="dropoffLocation"
      style={{ width: '100%' }}
      getOptionLabel={(option) => (typeof option === 'string' ? option : option.description)}
      filterOptions={(x) => x}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={values.dropoffLocation}
      onChange={(event, newValue) => {
        setOptions(newValue ? [newValue, ...options] : options);
        
        setValueTwo(newValue);
        if (geocoder.current != null && newValue != null) {
          geocoder.current.geocode({ 
            'placeId': newValue.place_id
        }, 
        function(responses, status) {
            if (status == 'OK') {
                var lat = responses[0].geometry.location.lat();
                var lng = responses[0].geometry.location.lng();
                setLocationEnd({'lat': lat, 'lng': lng})
                console.log(lat, lng);
            }
        });
        }
       
        setFieldValue('dropoffLocation', newValue.description)
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => (
        <TextField {...params} label="Dropoff Location" variant="outlined" margin="normal" fullWidth onBlur={handleBlur}

        name="pickupLocation"
        error={Boolean(touched.pickupLocation && errors.pickupLocation)}
        value={values.pickupLocation}/>
      )}
      renderOption={(option) => {
        const matches = option.structured_formatting.main_text_matched_substrings;
        const parts = parse(
          option.structured_formatting.main_text,
          matches.map((match) => [match.offset, match.offset + match.length]),
        );

        return (
          <Grid container alignItems="center">
            <Grid item>
              <LocationOnIcon className={classes.icon} />
            </Grid>
            <Grid item xs>
              {parts.map((part, index) => (
                <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                  {part.text}
                </span>
              ))}

              <Typography variant="body2" color="textSecondary">
                {option.structured_formatting.secondary_text}
              </Typography>
            </Grid>
          </Grid>
        );
      }}
    />

                          
      <Grid container justify="space-between">
              <TextField
        label="Earliest Dropoff"
        type="datetime-local"
        margin="normal"
        variant="outlined"
        name="earlyDrop"
        onBlur={handleBlur}
        onChange={handleChange}
        className={classes.textField}
        error={Boolean(touched.earlyDrop && errors.earlyDrop)}
        value={values.earlyDrop}
        InputLabelProps={{
        shrink: true,
        }}
        />
    </Grid>
                <Box
                  alignItems="center"
                  display="flex"
                  ml={-1}
                >
                  <Checkbox
                    checked={values.policy}
                    name="policy"
                    onChange={handleChange}
                  />
                  <Typography
                    color="textSecondary"
                    variant="body1"
                  >
                    I have read the
                    {' '}
                    <Link
                      color="primary"
                      component={RouterLink}
                      to="#"
                      underline="always"
                      variant="h6"
                    >
                      Terms and Conditions
                    </Link>
                  </Typography>
                </Box>
                {Boolean(touched.policy && errors.policy) && (
                  <FormHelperText error>
                    {errors.policy}
                  </FormHelperText>
                )}
                <div id='fkwi3e'>
                <Box my={2}>
                  <Button
                    color="primary"
                    disabled={isSubmitting}
                    fullWidth
                    size="large"
                    id="get-quote-button"
                    type="submit"
                    variant="contained"
                  >
                    Sign up now
                  </Button>
                </Box>
                <Typography
                  color="textSecondary"
                  variant="body1"
                >
                  Have an account?
                  {' '}
                  <Link
                    component={RouterLink}
                    to="/login"
                    variant="h6"
                  >
                    Sign in
                  </Link>
                </Typography>
                </div>
              </form>
            )}
          </Formik>
        </Container>
      </Box>
    </Page>
  );
};

export default PublicQuoteView;
