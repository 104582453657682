import React, {useEffect, useState} from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Card,
  CardContent,
  Grid,
  Typography,
  colors,
  makeStyles,
  Stepper,
  Step,
  StepLabel, 
  StepContent 
} from '@material-ui/core';
import QuoteStatus from 'src/QuoteStatus'
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import MoneyIcon from '@material-ui/icons/Money';
import moment from 'moment'
import Firebase from 'src/Firebase'
import TableAssist from 'src/utils/TableAssist'

const fieldVals = [{'name': 'Rate', 'val': 'rate'}, {'name': 'Extra Deduction', 'val': 'deductExtra'}, 
{'name': 'Amount', 'val': 'amount'}, {'name': 'Final Weight', 'val': 'totalWeight'}, {'name': 'Net Payment', 'val': 'netPay'}]

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%'
  },
  avatar: {
    backgroundColor: colors.red[600],
    height: 56,
    width: 56
  },
  differenceIcon: {
    color: colors.red[900]
  },
  differenceValue: {
    color: colors.red[900],
    marginRight: theme.spacing(1)
  }
}));

function renderStatus(s) {
  if (s == 0) {
    return 'Quote Requested'
  } else if (s==1) {
    return 'Quote Offered'
  } else if (s==2) {
    return 'Quote Accepted'
  } else if (s==3) {
    return 'Offered to Transporters'
  } else if (s==4) {
    return 'Transporter Placed Bid'
  } else if (s==5) {
    return 'Transporter Accepted Job'
  } else if (s==6) {
    return 'Job Assigned To Driver'
  } else if (s==7) {  
    return 'Driver en Route'
  } else if (s==8) {
    return 'Driver Loaded Truck'
  } else if (s==9) {
    return 'Driver Delivered Load'
  } else {
    return 'N/A'
  }
}

function getColor(s) {

  var color = 'rgb(222,222,222)'

  if (s == 1) {
    
    color = 'rgb(63,81,181)'
  } else if (s == 2){

    color = '#F32013'
  } else if (s == 3) {

    color = 'rgb(255,12,39)'
  } else if (s==4) {

    color = '#F32013'
  } else if (s==5) {

    color = 'rgb(237,28,36)'
  } else if (s == 6) {

    color = 'rgb(128,255,255)'
  } else if (s == 7) {

    color = 'rgb(0,128,0)'
  } else if (s == 8) {

    color = 'rgb(128,64,0)'
  } else if (s==9) {

    color = 'rgb(67,160,71)'
  }
  return color 
}

function renderNameNoCountry(name, t1, t2) {
  if (t1 != null && t2 != null) {
    if (t1 == t2) {
      if (name.endsWith(t1) && t1 != name && name.length > 2 + t1.length) {
        return name.substring(0, name.length - (2 + t1.length)) 
      }
    }
  }
  return name
}

function padDigits(number) {
  return Array(Math.max(3 - String(number).length + 1, 0)).join(0) + number;
}

function getIdDisplay(val) {
  if ('internalShipperId' in val && 'shortIdShipper' in val) {
    return val.internalShipperId + '-' + padDigits(val.shortIdShipper)
  } else {
    return val.shortId
  }
}

function precisionRound(num) {
    
  return +num.toFixed(2)
}

function roundRender(val) {
  if (Number.isFinite(val)) {
    return precisionRound(val)
  }
  return val
}

const PaymentCard = ({ className, itemProp, selection, ...rest }) => {
  const classes = useStyles();
  const [txt, setTxt] = useState({'ww': 45, 'aa': 'jfia3i'})
  const [num, setNum] = useState(53)

  useEffect(() => {

  });

  return (
    <Card
      className={clsx(classes.root, className)}
      style={{position: 'relative',}}
      {...rest}

    >
      <CardContent style={{ display: 'flex', flexDirection: 'column'}}>
      {selection == null ? null : 
         <div style={{backgroundColor: 'mediumspringgreen', padding: 10, borderRadius: 10, width: 100}}>
         <a>{'Payment: ' + (selection )}</a>
       </div>
         } 
        <Grid
          container
          flexDirection="column"
          spacing={3}
        >
          <Grid item>
            <Typography
              color="textSecondary"
              gutterBottom
              variant="h3"
            >
              {itemProp.deliveryDate ? 'Delivery Date: ' + moment(itemProp.deliveryDate).format('DD.MM.YY') : null}
            </Typography>
            <Typography
              color="textSecondary"
              gutterBottom
              variant="h3"
            >
              {itemProp.plate && itemProp.plate.main ? 'Vehicle: ' + itemProp.plate.main : null}
            </Typography>
            <Typography
              color="textSecondary"
              gutterBottom
              variant="h3"
            >
              {itemProp.plate && itemProp.plate.trl ? 'Trailer: ' + itemProp.plate.trl : null}
            </Typography>
            {fieldVals.map((item, idx) => {
              if (itemProp[item.val]) {
                return <Typography
                color="textSecondary"
                gutterBottom
                variant="h3"
              >
                {item.name + ': ' + roundRender(itemProp[item.val])}
              </Typography>
              } 
              return null 
            })}
            </Grid>
            </Grid>

      </CardContent>
    </Card>
  );
};

PaymentCard.propTypes = {
  className: PropTypes.string
};

export default PaymentCard;
