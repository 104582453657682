import React, {useState, useEffect, useRef} from 'react';
import { Link as RouterLink, useNavigate, useLocation} from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';

import LocationOnIcon from '@material-ui/icons/LocationOn';
import {
  Box,
  Button,
  Checkbox,
  Container,
  FormHelperText,
  Link,
  TextField,
  Typography,
  makeStyles,
  Grid,
  Select,
  MenuItem,
  CircularProgress
} from '@material-ui/core';
import Page from 'src/components/Page';
import parse from 'autosuggest-highlight/parse';
import throttle from 'lodash/throttle';
import Firebase from 'src/Firebase'
import DataFetcher from 'src/DataFetcher';
import MyContext from 'src/MyContext';
import TableAssist from 'src/utils/TableAssist';
import JobPaymentComponent from './JobPaymentComponent';
import { TrainOutlined } from '@material-ui/icons';


const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
const filter = createFilterOptions();

const autocompleteService = { current: null };
const geocoder = {current: null};



const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  }
}));


const NewPaymentView = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const jobsRef = useRef([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [fullValues, setFullValues] = React.useState([])
  const [totalNet, setTotalNet] = React.useState({})
  const [arraySet, setArraySet] = React.useState([])
  const {state} = useLocation();

  function doCreatePayment(val) {
    setIsLoading(TrainOutlined)
    var v = {}
    v['jobs'] = val

    let s = (xv) => {
      setIsLoading(false)
      if (xv.code != 200) {
        alert('Could not create payment due to rule violation. Error code: ' + xv.code)
        return 
      } else {
        navigate(-1)
      }
    }

    let f = () => {
      setIsLoading(false)
      alert('Error creating payment.')
    }

    console.log(v)
    Firebase.adminCreatePayment(v, (x) => s(x), f)
  }

  function submitForPayment(values) {
    var block = false
    var bankBlock = false
    var recipientSet = null
    var blockKey = ''
    var subset = {}
    if (values.associatedJobs.length < 1) {
      alert('Submit at least one job for payment.')
      return
    }
    let map1 = ['assignedJob', 'recipient', 'bankValue', 'accountNumber']
    let map2 = ['rate', 'finalWeight']
    values.associatedJobs.map((item, idx) => {

      map1.map((innerItem, innerIdx) => {
        if (!item[innerItem] || item[innerItem] == '') {
          block = true
          blockKey = innerItem
        }
        subset[innerItem] = item[innerItem]
      })
      map2.map((innerItem, innerIdx) => {
        if (!item[innerItem] || item[innerItem] <= 0 || item[innerItem] == '') {
          block = true
         
          blockKey = innerItem
        }
        subset[innerItem] = item[innerItem]
      })
      if (item.bankValue != 2) {
        
        bankBlock = true
        block = true 
        blockKey = 'BANK'
      }
      if (recipientSet == null) {
        recipientSet = item.recipient
      } else if (recipientSet != item.recipient) {
        block = true 
        blockKey = 'recipient'
      }

    })

    //alert(JSON.stringify(subset))
    if (bankBlock) {
      alert('Currently only bank of Abyssinia is supported for payments.')
      return
    }
    if (block) {
      alert('Before submitting, make sure that each job has a recipient, bank account, bank account number, rate, and weight specified. In addition, make sure all jobs on the payment were done by the same recipient.')
      return
    }
    //alert(JSON.stringify(values.associatedJobs))
    //return
    doCreatePayment(values.associatedJobs)
  }

  function updateTotalNet(v, k,setFunc) {
    //alert(v + ' ' + k)
    
    if ((k in totalNet) && totalNet[k] == v) {
      return
    }
    var va = totalNet
    va[k] = v

    setTotalNet(va)
    setArraySet([v])
    //setFunc('total', JSON.stringify(va))
    //alert(JSON.stringify(totalNet))
  }

  function sumTotalNet(total) {
    //alert('doing a sum' + JSON.stringify(total))
    var sum = 0
    Object.keys(total).map((item, idx) => {
      sum += total[item]
    })
    return sum 
  }

  function updateFullValues(val, idx, vassoc, setFunc ) {
    //alert(JSON.stringify(val) + ' aaaand ' + idx)
    var vv = vassoc.map((item, idxx) => {
      if (idx == idxx) {
        var nv = item 
        Object.keys(val).map((innerKey, innerIdx) => {
          nv[innerKey] = val[innerKey]
        })
        return nv
      }
      return item 
    }) 

    setFunc('associatedJobs', vv)
  }

  
function precisionRound(num) {
    
  return +num.toFixed(2)
}


  function deleteAtIdx(reactKey, idx, vassoc, setFunc) {
    var v = fullValues.filter((item, idxxx) => {
      return idx != idxxx
    })
    var vv = vassoc.filter((item, idxxx) => {
      return idx != idxxx
    })
    if (reactKey in totalNet) {
      var vnet = totalNet
      delete vnet[reactKey]
      setTotalNet(vnet)
    }
    setFunc('associatedJobs', vv)
  }
  
 

  return ( <MyContext.Consumer>{context => (<Page
    className={classes.root}
    height='100%'
    title="New Payment"
    id="ff2"
    backgroundColor={classes.root.backgroundColor}
  >
    <Box
      display="flex"
      flexDirection="column"
      height="90vh"
      id="bb2"
      justifyContent="center"
      backgroundColor={classes.root.backgroundColor}

    >
     
      <Container id="cc2"  backgroundColor={classes.root.backgroundColor}>
        <Formik

        id='f2'
          initialValues={{
           associatedJobs: [],
           total: 0
          }}
          validationSchema={
            Yup.object().shape({
             
              
            })
          }
          onSubmit={(values) => {
            //alert('x')
            submitForPayment(values)
            //verifyCode(values.code)
            //navigate('/app/dashboard', { replace: true });
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            setFieldValue, 
            isSubmitting2,
            touched,
            values
          }) => (
            <form id='fu2' onSubmit={handleSubmit}>
                  <div id='fkx1' style={{marginTop: 230}}>
                    {isLoading ? <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: 50}}><CircularProgress/></div> : null}
                  <Box mb={3}>
                <Typography
                  color="textPrimary"
                  variant="h2"
                >
                  New Payment
                </Typography>
                

              </Box>
       <div>
       {values.associatedJobs.map((item, idx) => {

         return (<div key={item.reactKey + 'div'}>
           <JobPaymentComponent sheetMode={false} viewMode={false} key={item.reactKey} id={item.reactKey} itemId={item.reactKey} deleteThis={() => deleteAtIdx(item.reactKey, idx, values.associatedJobs, (x,y) => setFieldValue(x,y))} parentValuesUpdate={(vals) => {updateFullValues(vals, idx, values.associatedJobs, (x,y) => setFieldValue(x,y))}} sendParentNet={(x) => updateTotalNet(x, item.reactKey, (x,y) => setFieldValue(x,y))} context={context}/></div>)
})}

       </div>
       <Typography>{'Total Payment: ' + precisionRound(sumTotalNet(totalNet))}</Typography>

       <Button
                  color="primary"
                  disabled={isLoading}
                  onClick={() => {
                    var v = values.associatedJobs
                    var fullv = fullValues
                    v.push({'assignedJob': 'empty', 'reactKey': Date.now() + ' ' + Math.random()})
                    setFieldValue('associatedJobs', v)
                  }}
                  id="addJobButton"
                  variant="contained"
                >
                  Add Job
                </Button>

              <Box my={2}>
                <Button
                  color="primary"
                  disabled={isLoading}
                  fullWidth
                  size="large"
                  type="submit"
                  id="sendButtonNext"
                  variant="contained"
                >
                  Confirm
                </Button>
              </Box>

              </div>
            </form>
          )}
        </Formik>
      </Container>
    </Box>
  </Page>)}</MyContext.Consumer>)
};

export default NewPaymentView;
