import React, { useState, useEffect } from 'react';
import { Link as RouterLink, useNavigate, useLocation} from 'react-router-dom';
import {
  Box,
  Container,
  Typography, 
  Button,
  Link,
  makeStyles
} from '@material-ui/core';
import Page from 'src/components/Page';
import CheckCircle from '@material-ui/icons/CheckCircle';
import MyContext from 'src/MyContext'


import Firebase from 'src/Firebase.js'


const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));


const JobSummaryView = (props) => {
  const classes = useStyles();

  const navigate = useNavigate();
  const {state} = useLocation();
  useEffect(() => {
    // Update the document title using the browser API

    
  });

  function goManage(id, jobs) {
    var customer = null 
    jobs.map((item, idx) => {
      if (item.shortId == id) {
        customer = item 
      }
    })
    if (customer != null) {
      let startLat = customer.startPos.lat 
        let endLat = customer.endPos.lat 
        let startLng = customer.startPos.lng 
        let endLng = customer.endPos.lng 

        let cx = startLat + ((endLat - startLat) / 2)
        let cy = startLng + ((endLng - startLng) / 2)

        navigate('/app/editjobadmin', {state: {customer: customer, centerx: cx, centery: cy}, replace: true})
    } else {
      alert('Try again in a moment.')
    }
  }

  function padDigits(number) {
    return Array(Math.max(3 - String(number).length + 1, 0)).join(0) + number;
  }

  function navigaterFunc(path, props) {
    //alert('attempt navigate')
    navigate(path, props)
  }

  return (
    <MyContext.Consumer>{context => (
    <Page
      className={classes.root}
      title="Jobs"
    >
      <Container maxWidth={false} style={{alignItems: 'center', flexDirection: 'column', justifyContent: 'center', display: 'flex'}}>
        <CheckCircle style={{color: 'green', width: 200, height: 200}}/>
        <a style={{fontSize: 24, fontWeight: 'bold'}}>Operation Complete!</a>
        <Typography variant="body2" color="textSecondary">
                {'Garri ID: GAR-' + state.customer.shortId}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                {state.customer.shortIdShipper ? 'Shipper Side ID: GAR-' + padDigits(state.customer.shortIdShipper) : ''}
              </Typography>
              <a style={{fontSize: 24, fontWeight: 'bold'}}></a>
              <Link
              to={state.customer}
              onClick={ () => window.open(Firebase.ip + 'info/' + state.customer.shortId)}
              variant="h6"
            >{'Live Tracking'}</Link>
                          <Link
              to={state.customer}
              onClick={ () => goManage(state.customer.shortId, context.adminJobs)}
              variant="h6"
            >{'Manage Job'}</Link>
                            <Button
          color="primary"
          onClick={() => navigate(-1)}
          size="large"
          id="livetrackbutton"
          type="awjeifj"
          variant="contained"
          style={{marginTop: 15}}
        >
          {'Continue'}
        </Button>

      </Container>
    </Page>)}</MyContext.Consumer>
  );
};

export default JobSummaryView;
