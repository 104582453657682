import React, {useState, useEffect} from 'react';
import { Link as RouterLink, useNavigate, useLocation} from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import AddBox from '@material-ui/icons/AddBox';
import Cancel from '@material-ui/icons/Cancel';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import {
  Box,
  Button,
  Checkbox,
  Container,
  FormHelperText,
  Link,
  TextField,
  Typography,
  makeStyles,
  CircularProgress, 
  Grid,
  Select,
  MenuItem
} from '@material-ui/core';
import Page from 'src/components/Page';
import parse from 'autosuggest-highlight/parse';
import throttle from 'lodash/throttle';
import Firebase from 'src/Firebase'

import DataFetcher from 'src/DataFetcher'
import UserJobsWrapper from './UserJobsWrapper';
import UserDriversWrapper from './UserDriversWrapper'
import MyContext from 'src/MyContext';
var moment = require('moment');

const filter = createFilterOptions();

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/


const autocompleteService = { current: null };
const geocoder = {current: null};

const bankOptions = [{'name': 'Awash International Bank', 'value': 0}, 
{'name': 'Commercial Bank of Ethiopia', 'value': 1}, 
{'name': 'Bank of Abyssinia', 'value': 2}, 
{'name': 'Dashen Bank', 'value': 3}, 
{'name': 'Oromia International Bank', 'value': 4}, 
{'name': 'Cooperative Bank of Oromia', 'value': 5}, ]

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  }
}));


const EditUserViewSecondary = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  
  const [valueTwo, setValueTwo] = React.useState(null);
  const [inputValue, setInputValue] = React.useState('');
  const [options, setOptions] = React.useState([]);
  const [optionsTwo, setOptionsTwo] = React.useState([]);
  const [numVal, setNumVal] = React.useState(false);
  const [actions, setActions] = React.useState(null);
  const [locationStart, setLocationStart] = React.useState(null);
  const [locationEnd, setLocationEnd] = React.useState(null);
  const [finalSet, setFinalSet] = React.useState(null);
  const [bankValue, setBankValue] = React.useState(null);
  const [changedBankInfo, setChangedBankInfo] = React.useState(false);
  const [accountNumber, setAccountNumber] = React.useState(null);
  const [tableItems, setTableItems] = React.useState(DataFetcher.transportersList)
  const [isAuthenticating, setIsAuthenticating] = React.useState(false);

  const loaded = React.useRef(false);
  const {state} = useLocation();
  const [value, setValue] = React.useState({associationId: state.associationId ? state.associationId : '0',
companyName: state.associationName ? state.associationName : 'None' });



  function editUser(values, setSubmitting) {
    console.log(values)

    setIsAuthenticating(true)
    console.log(values)
    //alert(JSON.stringify(values))
    console.log(Firebase.userInfo)
    //Firebase.gt()
    var v = values
    if (v['dob']){
      v['dob'] = Date.parse(v['dob'])
    }
    if (v['secondaryPhones'] && v['secondaryPhones'].length > 0) {
      var wrongFormat = false
      v['secondaryPhones'] = v['secondaryPhones'].filter((item, idx) => {
        if (item.length != 13 && item.length !=12) {
          wrongFormat = true
        }
        return (item != '+251' && item != '' && item != '+1')
      })
      if (wrongFormat) {
        alert('Extra phone numbers should being with +251 followed by 9 digits, or +1 followed by 10 digits.')
        setIsAuthenticating(false)
        return 
      }
    }

    var oldSecondaryPhones = []
    if (state.secondaryPhones) {
      state.secondaryPhones.map((item, idx) => {
        if (v['secondaryPhones'].includes(item)) {
  
        } else {
          oldSecondaryPhones.push(item)
        }
      })
    }



    if (v.userType == 'Transporter') {
      v['userType'] = 1
      delete values['assignedTransporter']
      delete values['username']
      delete values['password']
    } else if (v.userType == 'Shipper') {

      if (values['username'].length > 0 && values['password'].length < 8) {
        alert('Password must be at least 8 characters long.')
        setIsAuthenticating(false)
        setSubmitting(false)
        return 
      }
      v['userType'] = 2
      delete values['assignedTransporter']

    } else if (v.userType == 'Driver') {
      v['userType'] = 3
      delete values['username']
      delete values['password']
      if (values.assignedTransporter != state.assignedTransporter) {
        v['oldTransporterUid'] = state.assignedTransporter
      }
    } else if (v.userType == 'Other') {
      v['userType'] = 4
    }
    v['userId'] = state.userId

    if (v['associationId'] != state.associationId) {
      v['doUpdateAssoc'] = true 
      v['oldAssociationId'] = state.associationId
    } 

    var send = {}
    send['updateBody'] = v 
    if (oldSecondaryPhones.length > 0) {
      send['oldSecondaryPhones'] = oldSecondaryPhones
    }

    if (changedBankInfo) {
      var bankUpdate = {}
      bankUpdate['bank'] = bankValue 
      bankUpdate['account'] = accountNumber
      send['bankUpdate'] = bankUpdate
    }
    send['userId'] = state.userId
    console.log(v)
    //alert(JSON.stringify(send))
    let suc = function (x) {
      if (x == 500) {
        alert('Error updating user.')
      } else if (x == 501) {
        alert('This username is already in use, please pick a new one.')
      } else {
        navigate(-1)
      }
      setIsAuthenticating(false)
      setSubmitting(false)

      //window.open(Firebase.adminip + 'v/' + state.customer.quoteId)
    }

    let f  = function () {
      setIsAuthenticating(false)
      setSubmitting(false)
      alert('Error saving changes. Check internet connection and try again.')
    }

    Firebase.adminEditUser(send, (x) =>suc(x), () => f())

  }

  function getTransporterOptions(adminUsers) {


    let ret = adminUsers.filter((item, idx) => {
      return item.userType == 1 && item.companyName
    })
    return [{'userId': '0', 'companyName': 'Unassigned'}].concat(ret)

  }

  function getAssociationOptions(adminAssoc) {
    
    return [{'associationId': '0', 'companyName': 'None'}].concat(adminAssoc)
  }

  function updateSecondaryPhones(init, val, idxUp, updateFunc) {
    var v = init.map((item, idx) => {
      if (idx == idxUp) {
        return val
      } 
      return item 
    })

    updateFunc('secondaryPhones', v)
  }
  

  function renderUserType(type) {
    if (type == 1) {
      return 'Transporter'
    } else if (type == 2) {
      return 'Shipper'
    } else if (type == 3) {
      return 'Driver'
    } else if (type == 4) {
      return 'Other'
    }
  }

  useEffect(() => {
    let f = () => {
      //alert('xf')
    }
    let s = (dat) => {
      //alert(JSON.stringify(dat))
      if (dat != null && dat.info != null) {
        setBankValue(dat.info.bank)
        setAccountNumber(dat.info.account)
      }
    }
    var datpack = {}
    datpack['userId'] = state.userId
    Firebase.retrieveUserSecrets(datpack, (x) => s(x), () => f())
  }, []);

  if (false && isAuthenticating) {
    return (<Page
      className={classes.root}
      title="Request Quote"
      backgroundColor={classes.root.backgroundColor}
    ><div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}><CircularProgress/></div></Page>)
  }


  return (<div>{isAuthenticating ?  <Page
    className={classes.root}
    title="Request Quote"
    backgroundColor={classes.root.backgroundColor}
  ><div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}><CircularProgress/></div></Page> :   <MyContext.Consumer>{context => (<Page
    className={classes.root}
    height='100%'
    title="Request Quote"
    id="ff2"
    backgroundColor={classes.root.backgroundColor}
  >
    <Box
      display="flex"
      flexDirection="column"
      id="bb2"
      justifyContent="center"
      backgroundColor={classes.root.backgroundColor}

    >
      <Container id="cc2"  backgroundColor={classes.root.backgroundColor}>
        <Formik
        id='f2'
          initialValues={{
           firstName: state.firstName,
           userType: renderUserType(state.userType),
            companyName: state.companyName,
            assignedTransporter: state.assignedTransporter ? state.assignedTransporter : '0',
            verified: state.verified,
            tin: state.tin, 
            middleName: state.middleName,
            lastName: state.lastName,
            dob: state.dob ? moment(state.dob).format('yyyy-MM-DD') : '', 
            industry: state.industry,
            addressLine1: state.addressLine1,
            addressLine2: state.addressLine2,
            yoe: state.yoe,
            gender: state.gender ? state.gender : 'Male',
            license: state.license,
            username: state.username ? state.username: '',
            password: state.password ? state.password: '',
            associationId: state.associationId,
            associationName: state.associationName,
            ownerOperator: state.ownerOperator,
            secondaryPhones: state.secondaryPhones
          }}
          validationSchema={
            Yup.object().shape({
              companyName: Yup.string().max(255).required('Company name required'),
              firstName: Yup.string().max(255).required('Name required'),
              
              userType: Yup.string().max(255).required('User type required')
            })
          }
          onSubmit={(values) => {
            console.log('trysub')
            console.log(values)
            editUser(values)
            //verifyCode(values.code)
            //navigate('/app/dashboard', { replace: true });
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting2,
            setSubmitting,
            setFieldValue,
            touched,
            values
          }) => (
            <form id='fu2' onSubmit={handleSubmit}>
                  <div id='fkx1' style={{marginTop: 5, flexDirection: 'row', display: 'flex'}}>
                    <div style={{width: state.userType == 3 || values.ownerOperator ? '50%' : ''}}>
              <Box mb={3}>
                <Typography
                  color="textPrimary"
                  variant="h2"
                >
                  Update User
                </Typography>
              </Box>

              <TextField
                fullWidth
                disabled={true}
                id="gxg"
                label="Phone"
                margin="normal"
                name="xfaf"
                onBlur={handleBlur}
                onChange={(e) => console.log('x')}
                value={state.phone}
                variant="outlined"
              />
              {values.secondaryPhones && values.secondaryPhones.length > 0 ? 
              <div>
                {values.secondaryPhones.map((item, idx) => {
                               return <div><div style={{flexDirection : 'row', display: 'flex'}}><TextField
                                fullWidth
                                id={'secondary ' + idx}
                                label={'Phone ' + (idx + 2)}
                                margin="normal"
                                name={'secondary ' + idx}
                                onBlur={handleBlur}
                                onChange={(e) => updateSecondaryPhones(values.secondaryPhones, e.target.value, idx, (x, y) => setFieldValue(x,y))}
                                value={values.secondaryPhones[idx]}
                                variant="outlined"
                              />
                              <Cancel style={{marginTop: 28}} onClick={(e) => {
                                setFieldValue('secondaryPhones', values.secondaryPhones.filter((item, idxInner) => {
                                  return idx != idxInner
                                }))
                              }}/>
                              </div>
                                              {state.phoneDict && values.secondaryPhones[idx] in state.phoneDict && state.phoneDict[values.secondaryPhones[idx]].userId != state.userId ? 
                                              <Typography>This phone number is already assigned to another user.</Typography> : null}</div>
                })}
              </div>
              : null}
              <AddBox onClick={(event) => {
                if (!values.secondaryPhones) {
                  setFieldValue('secondaryPhones', ['+251'])
                } else {
                  var v = values.secondaryPhones
                  v.push('+251')
                  setFieldValue('secondaryPhones', v)
                }
              }}></AddBox>

              <TextField
                error={Boolean(touched.companyName && errors.companyName)}
                fullWidth
                id="companyName"
                helperText={touched.companyName && errors.companyName}
                label="Company"
                margin="normal"
                name="companyName"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.companyName}
                variant="outlined"
              />

<TextField
                error={Boolean(touched.firstName && errors.firstName)}
                fullWidth
                id="firstName"
                helperText={touched.firstName && errors.firstName}
                label="First Name"
                margin="normal"
                name="firstName"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.firstName}
                variant="outlined"
              />
              <TextField
                error={Boolean(touched.middleName && errors.middleName)}
                fullWidth
                id="middleName"
                helperText={touched.middleName && errors.middleName}
                label="Middle Name"
                margin="normal"
                name="middleName"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.middleName}
                variant="outlined"
              />
              <TextField
                error={Boolean(touched.lastName && errors.lastName)}
                fullWidth
                id="lastName"
                helperText={touched.lastName && errors.lastName}
                label="Last Name"
                margin="normal"
                name="lastName"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.lastName}
                variant="outlined"
              />
              <TextField select 
          fullWidth
          label="Gender"
          margin="normal"
          id="gender"
          name="gender"
          variant="outlined"
          value={values.gender}
          defaultValue={values.gender}
          onChange={handleChange}
        >
          {['Male', 'Female'].map((item, idx) => {
            return (<MenuItem value={item}>{item}</MenuItem>)
          })}
        </TextField>
{values.userType == 'Shipper' || values.userType == 'Transporter' ? 
<Autocomplete
      value={value}
      onChange={(event, newValue) => {
        if (typeof newValue === 'string') {
          setValue({
            companyName: newValue,
          });
          setFieldValue('associationId', newValue.associationId)
          setFieldValue('associationName', newValue.companyName)
        } else if (newValue && newValue.inputValue) {
          // Create a new value from the user input
          setValue({
            companyName: newValue.inputValue,
          });
          setFieldValue('associationId', -1)
          setFieldValue('associationName', newValue.inputValue)
        } else {
          setValue(newValue);
          if (newValue == null) {
            setFieldValue('associationId', '0')
            setFieldValue('associationName', 'None')
          } else {
            setFieldValue('associationId', newValue.associationId)
            setFieldValue('associationName', newValue.companyName)
          }
          
        }
        //console.log(event.target.value)
        //console.log(newValue)
      }}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);

        // Suggest the creation of a new value
        if (params.inputValue !== '') {
          filtered.push({
            inputValue: params.inputValue,
            companyName: `Add "${params.inputValue}"`,
          });
        }

        return filtered;
      }}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      id="free-solo-with-text-demo"
      options={getAssociationOptions(context.associations)}
      getOptionLabel={(option) => {
        // Value selected with enter, right from the input
        if (typeof option === 'string') {
          return option;
        }
        // Add "xxx" option created dynamically
        if (option.inputValue) {
          return option.inputValue;
        }
        // Regular option
        return option.companyName;
      }}
      renderOption={(option) => option.companyName}
      freeSolo
      renderInput={(params) => (
        <TextField {...params} error={Boolean(touched.associationId && errors.associationId)}
        fullWidth
        id="associationId"
        helperText={touched.associationId && errors.associationId}
        label="Association"
        margin="normal"
        name="associationId"
        onBlur={handleBlur}
        onChange={(e) => {
          console.log(e.target)
        }}
        value={values.associationId}
        variant="outlined"/>
      )}
    />
: null}



{state.userType == 3 ?           <Autocomplete
  id="combo-box-transporter"
  options={getTransporterOptions(context.users)}
  getOptionLabel={(option) => option.companyName}
  value={{'userId': values.assignedTransporter, 'companyName': values.companyName}}
  defaultValue={{'userId': state.assignedTransporter ? state.assignedTransporter : 0, 
'companyName': state.assignedTransporter ? state.companyName : 'Unassigned'}} 
  disabled={Firebase.IS_ASSOCIATION}
  onChange={(e, value) => {
    if (value != null) {
      setFieldValue('assignedTransporter', value.userId)
      setFieldValue('companyName', value.companyName)
    } else {
      setFieldValue('assignedTransporter', '0')
      setFieldValue('companyName', 'Unassigned')
    }

  }}
  renderInput={(params) => <TextField {...params}  fullWidth
  label="Choose Transporter"
  margin="normal"
  id="assignedTransporter"
  disabled={Firebase.IS_ASSOCIATION}
  name="transporter"
  variant="outlined"
  value={values.assignedTransporter}
  defaultValue={values.assignedTransporter} />}
/> : <div/>}

<TextField select 
          fullWidth
          disabled={Firebase.IS_ASSOCIATION}
          label="User Type"
          margin="normal"
          id="userType"
          name="userType"
          variant="outlined"
          value={values.userType}
          defaultValue={values.userType}
          onChange={handleChange}
        >
          {['Transporter','Driver', 'Shipper', 'Other'].map((item, idx) => {
            return (<MenuItem value={item}>{item}</MenuItem>)
          })}
        </TextField>

          {values.userType == 'Shipper' ? <div>
          <TextField
                error={Boolean(touched.tin && errors.tin)}
                fullWidth
                id="tin"
                helperText={touched.tin && errors.tin}
                label="TIN"
                margin="normal"
                name="tin"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.tin}
                variant="outlined"
              />
          <TextField
                error={Boolean(touched.industry && errors.industry)}
                fullWidth
                id="industry"
                helperText={touched.industry && errors.industry}
                label="Industry"
                margin="normal"
                name="industry"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.industry}
                variant="outlined"
              />
                            <TextField
                error={Boolean(touched.addressLine1 && errors.addressLine1)}
                fullWidth
                id="addressLine1"
                helperText={touched.addressLine1 && errors.addressLine1}
                label="Address Line 1"
                margin="normal"
                name="addressLine1"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.addressLine1}
                variant="outlined"
              />
              <TextField
                error={Boolean(touched.addressLine2 && errors.addressLine2)}
                fullWidth
                id="addressLine2"
                helperText={touched.addressLine2 && errors.addressLine2}
                label="Address Line 2"
                margin="normal"
                name="addressLine2"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.addressLine2}
                variant="outlined"
              />


          </div> : null}

          {values.userType == 'Shipper' ? <div>
          <TextField
                error={Boolean(touched.username && errors.username)}
                fullWidth
                id="username"
                helperText={touched.username && errors.username}
                label="Username"
                margin="normal"
                name="username"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.username}
                variant="outlined"
              />
              <TextField
                error={Boolean(touched.password && errors.password)}
                fullWidth
                id="password"
                helperText={touched.password && errors.password}
                label="Password"
                margin="normal"
                name="password"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.password}
                variant="outlined"
              />
            </div> : null}

          {values.userType == 'Transporter' ? <div>
          <TextField
                error={Boolean(touched.tin && errors.tin)}
                fullWidth
                id="tin"
                helperText={touched.tin && errors.tin}
                label="TIN"
                margin="normal"
                name="tin"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.tin}
                variant="outlined"
              />
                            <TextField
                error={Boolean(touched.addressLine1 && errors.addressLine1)}
                fullWidth
                id="addressLine1"
                helperText={touched.addressLine1 && errors.addressLine1}
                label="Address Line 1"
                margin="normal"
                name="addressLine1"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.addressLine1}
                variant="outlined"
              />
              <TextField
                error={Boolean(touched.addressLine2 && errors.addressLine2)}
                fullWidth
                id="addressLine2"
                helperText={touched.addressLine2 && errors.addressLine2}
                label="Address Line 2"
                margin="normal"
                name="addressLine2"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.addressLine2}
                variant="outlined"
              />
                      <Box
                  alignItems="center"
                  display="flex"
                  ml={-1}
                >
                  <Checkbox
                    checked={values.ownerOperator}
                    name="ownerOperator"
                    onChange={handleChange}
                  />
                  <Typography
                    color="textSecondary"
                    variant="body1"
                  >
                    User is an Owner Operator
                  </Typography>
                </Box>

          </div> : null}
          {values.userType == 'Driver' ? <div>
          <TextField
        label="Date of Birth"
        type="date"
        variant="outlined"
        margin="normal"
        name="dob"
        onBlur={handleBlur}
        onChange={handleChange}
        value={values.dob}
        error={Boolean(touched.dob && errors.dob)}
        InputLabelProps={{
          shrink: true,
        }}
      />
                    <TextField
                error={Boolean(touched.yoe && errors.yoe)}
                fullWidth
                id="yoe"
                helperText={touched.yoe && errors.yoe}
                label="Years of Experience"
                margin="normal"
                name="yoe"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.yoe}
                variant="outlined"
                type="number"
              />

        <TextField
                error={Boolean(touched.license && errors.license)}
                fullWidth
                id="license"
                helperText={touched.license && errors.license}
                label="License Number"
                margin="normal"
                name="license"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.license}
                variant="outlined"
              />
          </div> : null}

          <TextField select
          fullWidth
          label="Bank"
          margin="normal"
          id="bank"
          name="bank"
          variant="outlined"
          value={bankValue}
          onChange={(e) => {
            setBankValue(e.target.value)
            setChangedBankInfo(true)
          }}
          InputLabelProps={{
            shrink: true,
            }}
        >
          {bankOptions.map((item, idx) => {
            return (<MenuItem value={item.value}>{item.name}</MenuItem>)
          })}
        </TextField>

        <TextField
                error={Boolean(touched.bankNumber && errors.bankNumber)}
                fullWidth
                id="bankNumber"
                type="number"
                helperText={touched.bankNumber && errors.bankNumber}
                label="Account Number"
                margin="normal"
                name="bankNumber"
                onBlur={handleBlur}
                onChange={(e) => {
                  setAccountNumber(e.target.value)
                  setChangedBankInfo(true)
                }}
                InputLabelProps={{
                  shrink: true,
                  }}
                value={accountNumber}
                variant="outlined"
              />


        <Box
                  alignItems="center"
                  display="flex"
                  ml={-1}
                >
                  <Checkbox
                    checked={values.verified}
                    name="verified"
                    onChange={handleChange}
                  />
                  <Typography
                    color="textSecondary"
                    variant="body1"
                  >
                    Verified User
                  </Typography>
                </Box>

              <Box my={2}>
                <Button
                  color="primary"
                  disabled={isSubmitting2}
                  fullWidth
                  size="large"
                  id="sendButtonNext"
                  variant="contained"
                  onClick={() => editUser(values, setSubmitting)}
                >
                  Confirm
                </Button>
              </Box>
              
              </div>
              <div>
              
              {state.userType == 1 ?
              <div>
                 <Box mb={3}>
                <Typography
                  color="textPrimary"
                  variant="h2"
                >
                  Driver Management (User Fleet)
                </Typography>
              </Box>
              <UserDriversWrapper targetUid={state.userId}/>
              </div>
              : null}
                            {state.userType == 3 || values.ownerOperator?
              <div>
                 <Box mb={3}>
                <Typography
                  color="textPrimary"
                  variant="h2"
                >
                  Job Management (Driver)
                </Typography>
              </Box>
              <UserJobsWrapper targetUid={state.userId}/>
              </div>
              : null}
              </div>
              </div>
              
            </form>
          )}
        </Formik>
      </Container>
    </Box>
  </Page>)}</MyContext.Consumer>}</div>)
};
//<UserJobsWrapper targetUid={state.userId}/>
export default EditUserViewSecondary;
