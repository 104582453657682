import React, { PropTypes, Component } from 'react';
import ReactDOM from 'react-dom';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import SvgIcon from '@material-ui/core/SvgIcon'
import Popup from 'reactjs-popup'
import { withStyles, makeStyles} from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { Search as SearchIcon } from 'react-feather';
import {
    ChevronsDown, ChevronsUp
  } from 'react-feather';

const StyledTableCell = withStyles(theme => ({
    head: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const searchTargetOptions = {
    'shortId': {
        'name': 'ID',
        'val': 'shortId'
    },
    'commodity': {
        'name': 'Commodity',
        'val': 'commodity'
    },
    'startName': {
        'name': 'Pickup',
        'val': 'startName'
    },
    'endName': {
        'name': 'Dropoff',
        'val': 'endName'
    },
    'creationDate': {
        'name': 'Created',
        'val': 'creationDate'
    },
    'state': {
        'name': 'Status',
        'val': 'state'
    }

}


const searchTargetOptionsAdmin = {
    'shortId': {
        'name': 'ID',
        'val': 'shortId'
    },
    'transporter': {
        'name': 'Transporter',
        'val': 'transporter'
    },
    'driver': {
        'name': 'Driver',
        'val': 'driver'
    },
    'vehiclePlate': {
        'name': 'Vehicle',
        'val': 'vehiclePlate'
    },
    'vehicleTrailerPlate': {
        'name': 'Vehicle Trailer',
        'val': 'vehicleTrailerPlate'
    },
    'commodity': {
        'name': 'Commodity',
        'val': 'commodity'
    },
    'startName': {
        'name': 'Pickup',
        'val': 'startName'
    },
    'endName': {
        'name': 'Dropoff',
        'val': 'endName'
    },
    'creationDate': {
        'name': 'Created',
        'val': 'creationDate'
    },
    'state': {
        'name': 'Status',
        'val': 'state'
    }

}


const searchTargetOptionsLimitedAdmin = {
    'shortId': {
        'name': 'ID',
        'val': 'shortId'
    },
    'vehiclePlate': {
        'name': 'Vehicle',
        'val': 'vehiclePlate'
    },
    'vehicleTrailerPlate': {
        'name': 'Vehicle Trailer',
        'val': 'vehicleTrailerPlate'
    },
    'commodity': {
        'name': 'Commodity',
        'val': 'commodity'
    },
    'startName': {
        'name': 'Pickup',
        'val': 'startName'
    },
    'endName': {
        'name': 'Dropoff',
        'val': 'endName'
    },
    'creationDate': {
        'name': 'Created',
        'val': 'creationDate'
    },
    'state': {
        'name': 'Status',
        'val': 'state'
    }

}



class SearchWrapper extends Component {


    static defaultProps = {
        headerColor: 'black',
        bodyColor: 'black',
    };

    constructor(props) {
        super(props);

        this.state = {
            value: this.props.defaultValue,
            open: false,
            popRef: null,
            sortAscending: false,
            sortDescending: false,
            searchTarget: this.props.defaultSearchOption ? this.props.defaultSearchOption : 'shortId',
            searchTargetName: this.props.defaultSearchName ? this.props.defaultSearchName : 'ID',
            searchTargetOptions: this.props.searchOptions ? this.props.searchOptions : this.props.isShipper ? searchTargetOptions : this.props.isLimited < 1 ? searchTargetOptionsAdmin : searchTargetOptionsLimitedAdmin,
            textValue: ''
        }

        this.topRef = null
    }

    componentDidMount() {
        //alert(this.props.defaultValue)
    }

    openPopover(target) {
        //console.log('target opening ')
        //console.log(target)
        this.setState({
        popRef: target, 
        open: true
})
    }

    adjustSortDescending() {
        if (this.state.sortDescending) {
            this.setState({
                sortDescending: false
            })
        } else {
            this.setState({
                sortDescending: true,
                sortAscending: false
            })
            this.props.sortSet(this.props.itemkey, false)
        }
    }

    adjustSortAscending() {
        if (this.state.sortAscending) {
            this.setState({
                sortAscending: false
            })
        } else {
            this.setState({
                sortAscending: true,
                sortDescending: false
            })
            this.props.sortSet(this.props.itemkey, true)
        }
    }

    closePopover() {
        //console.log('closing pop')
        //console.log('event')
        this.setState({ open: false, popRef: null })
    }

    attemptClose(event) {

            this.closePopover()

    }

    updateSearch(val) {
        this.setState({
            textValue: val
        })
        if (val == '') {
            this.props.deactivate(this.state.searchTarget)
        } else {
            this.props.activate({ key: this.state.searchTarget, condition: 't', target: val, name: this.state.searchTargetName })
        }

    }

    render() {


        return (
            <Box mt={3}>
        <Card>
          <CardContent>
            <Box maxWidth={1000}>
                <div style={{flexDirection: 'row', display: 'flex'}}>
                <Select
                width={200}
          minWidth={100}
          label="Cargo Type"
          margin="normal"
          id="cargoType"
          name="cargoType"
          variant="outlined"
          value={this.state.searchTarget}
          onChange={(e) => {
            console.log(e)
            console.log(this.state.searchTargetOptions)
            this.setState({
                searchTarget: e.target.value,
                searchTargetName: this.state.searchTargetOptions[e.target.value].name,
                textValue: ''
            })
            //setFieldValue('cargoTypeDisp', e.target.value.name)
          }}
        >
          {Object.keys(this.state.searchTargetOptions).map((item, idx) => {
            return (<MenuItem value={this.state.searchTargetOptions[item].val}>{this.state.searchTargetOptions[item].name}</MenuItem>)
          })}
        </Select>
        <TextField
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SvgIcon
                        fontSize="small"
                        color="action"
                      >
                        <SearchIcon />
                      </SvgIcon>
                    </InputAdornment>
                  )
                }}
                value={this.state.textValue}
                placeholder={"Search by " + this.state.searchTargetName}
                variant="outlined"
                onChange={(event) => this.updateSearch(event.target.value)}
              />

                </div>
              
            </Box>
          </CardContent>
        </Card>
      </Box>


        );
    }
}
const headerStyle = {
    backgroundColor: 'rgb(67,67,67)', flexWrap: 'never', whiteSpace: 'noWrap'
}

/*   <div onClick={() => this.adjustSortDescending()} style={{ margin: 0, display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: this.state.sortDescending ? 'orange' : 'white', border: this.state.sortDescending ? '' : '1px solid black', borderRadius: 30, padding: 0, height: 30, padding: 5 }}>
                                <p>Descending</p>
                            </div>*/

/*
 * 
 * 
 *             */

export default SearchWrapper;