import React, { useState, useRef, useEffect } from 'react';
import {useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Avatar,
  Box,
  Card,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,

  Button,
  Link,

  MenuItem,
  CircularProgress
} from '@material-ui/core';
import { TextField } from "@mui/material";

import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import getInitials from 'src/utils/getInitials';
import {X, Layers} from 'react-feather';
import HeaderComponent from 'src/utils/HeaderComponent';
import SearchWrapper from 'src/utils/SearchWrapper';
import TableAssist from 'src/utils/TableAssist';
import Firebase from 'src/Firebase'
import AddBarleyFieldDialog from 'src/utils/AddBarleyFieldDialog';

import VoidNoteDialog from 'src/views/barley/BarleyListView/VoidNoteDialog';
import { makeStyles, darken, lighten } from '@material-ui/core/styles';
import { DataGrid, GridToolbar, GridToolbarContainer, GridToolbarColumnsButton, GridToolbarFilterButton, GridToolbarDensitySelector, GridToolbarExport } from '@mui/x-data-grid';


function CustomToolbar(props) {

  if (props.allowExport) {
    return       <GridToolbarContainer >
    <GridToolbarColumnsButton />
    <GridToolbarFilterButton />
    <GridToolbarDensitySelector/>
    <GridToolbarExport/>
  </GridToolbarContainer>
  } else {
    return (
      <GridToolbarContainer >
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector/>
      </GridToolbarContainer>
    );
  }

}

const filter = createFilterOptions();


const movies = [
  {
    id: 1,
    movie: "HP"
  },
  {
    id: 2,
    movie: "Matrix"
  },
  {
    id: 3,
    movie: "LOTR"
  }
];

var columns = [
  { field: 'id', headerName: 'ID', width: 90 },
  {
    field: 'firstName',
    headerName: 'First name',
    width: 150,
    editable: true,
  },
  {
    field: 'lastName',
    headerName: 'Last name',
    width: 150,
    editable: true,
  },
  {
    field: 'age',
    headerName: 'Age',
    type: 'number',
    width: 110,
    editable: true,
  },
  {field: 'date',
headerName: 'Date',
type: 'date',
width: 150,
editable: true},
  {
    field: 'fullName',
    headerName: 'Full name',
    description: 'This column has a value getter and is not sortable.',
    sortable: false,
    width: 160,
    valueGetter: (params) =>
      `${params.getValue(params.id, 'firstName') || ''} ${
        params.getValue(params.id, 'lastName') || ''
      }`,
  },
];

var rows = [
  { id: 1, lastName: 'Snow', firstName: 'Jon', age: 35 },
  { id: 2, lastName: 'Lannister', firstName: 'Cersei', age: 42 },
  { id: 3, lastName: 'Lannister', firstName: 'Jaime', age: 45 },
  { id: 4, lastName: 'Stark', firstName: 'Arya', age: 16 },
  { id: 5, lastName: 'Targaryen', firstName: 'Daenerys', age: null, date: Date.now() },
  { id: 6, lastName: 'Melisandre', firstName: null, age: 150 },
  { id: 7, lastName: 'Clifford', firstName: 'Ferrara', age: 44 },
  { id: 8, lastName: 'Frances', firstName: 'Rossini', age: 36 },
  { id: 9, lastName: 'Roxie', firstName: 'Harvey', age: 65 },
];


/*const useStyles = makeStyles((theme) => {


  return {
    root: {
      '& .super-app-theme--Open': {
        backgroundColor: 'red',
        '&:hover': {
          backgroundColor: 'blue',
        },
        'hover': {
          backgroundColor: 'yellow',
        },
      },
      '& .super-app-theme--Filled': {
        backgroundColor: 'green',
        '&:hover': {
          backgroundColor: 'yellow',
        },
        'hover': {
          backgroundColor: 'yellow',
        },
      },
      '& .super-app-theme--Filled:hover': {
        backgroundColor: 'green',
        
      },
      '& .super-app-theme--Open:hover': {
        backgroundColor: 'green',
        
      },

    },


  };
});*/

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiDataGrid-columnsContainer": {
      backgroundColor: "rgb(150,150,150)",
      color: '#ffffff'
    },
    '& .super-app-theme--Void': {
      backgroundColor: 'red',
      '&:hover': {
        backgroundColor: 'rgb(200, 0, 0) !important',
      },
    },

    /*'& .super-app-theme--1': {
      backgroundColor: 'red',
      '&:hover': {
        backgroundColor: 'red',
      },
    },*/
  },
  avatar: {
    marginRight: theme.spacing(2)
  }
}));

/*
          renderEditCell: (params) => {              
<Autocomplete
                id={params.id}
                options={movies}
                style={{height: 200}}
                autoHighlight
                getOptionLabel={(option) => option.movie}
                value={params.getValue(params.id, "movie")}
                renderInput={(params) => <TextField {...params} style={{ width: 150, height: 150}}  autoFocus  />}
              />
          }
*/



const DispatchTable = ({ className, dispatchFields, dispatchList, barleyInCustomObjects, context, ...rest }) => {
  const classes = useStyles();
  const [selectedCustomerIds, setSelectedCustomerIds] = useState([]);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);
  const [text_filters, set_text_filters] = useState([]);
  const [sortOrder, setSortOrder] = useState('creationDate');
  const [sortDirection, setSortDirection] = useState(false);
  const [addColumnDialog, setAddColumnDialog] = useState(false);
  const [rows, setRows] = useState(dispatchList);
  const [selection, setSelection] = useState([]);
  const [loadingDict, setLoadingDict] = useState({});
  const [voidDialog, setAddVoidDialog] = useState(false);
  const docDiaRef = useRef(null);

  const navigate = useNavigate();


  const [sortModel, setSortModel] = React.useState([
    {
      field: 'creationDate',
      sort: 'desc',
    },
  ]);

  async function doEdit(role) {

    if (selection.length != 1) {
      alert('Select a single item to edit.')
      return 
    } else {
      var r = null 
      rows.map((row, idx) => {
        if (row.id == selection[0]) {
          r = row
        }
      })

      console.log(r)
      if (r.voided) {
        alert('Cannot edit voided items.')
        return
      }

      
      if (role != 'Admin') {
        let firebaseServerTimeStamp = await Firebase.getServerTimestamp({})
        if (!firebaseServerTimeStamp || !firebaseServerTimeStamp.success || !firebaseServerTimeStamp.timestamp ) {
          alert('Connection issue, try again.')
          return 
        }
        let creationDateTimeStamp = firebaseServerTimeStamp.timestamp 
        if (creationDateTimeStamp - r.creationDate > (1000 * 60 * 10)) {
          alert('You cannot edit waybills more than 10 minutes after their creation.')
          return 
        }
      }




      var newVal = r
      navigate('/app/editdispatch', {state: {customer: newVal, fields: dispatchFields}})

    }

    
  }

  async function getValueForRef(t, ref) {
    console.log('try get value for ref')
    let result = await t.get(ref)
    var value = null 
    if (result.exists) {
      console.log('result does exist')
      value = result.data()
    } else {
      console.log('receive error now')
      const errM = {'msg': "Train or operation from existing dispatch does not exist."}
      console.log(errM)
      throw errM
    }
    return value 
  }


  async function txntest() {
    /*if (selection.length != 1) {
      alert('Select a single item to void.')
      return 
    } else {
      var r = null 
      rows.map((row, idx) => {
        if (row.id == selection[0]) {
          r = row
        }
      })

      console.log(r)



      var newVal = r
      var oldNumericSave = r.dispatchNumber
      var newValId = r.id
  
      var docRef = Firebase.firestore.collection('projects').doc(Firebase.userProject).collection("arrivals").doc(newValId)
      var docRef2 = Firebase.firestore.collection('projects').doc(Firebase.userProject).collection("arrivals").doc(newValId)

      let transaction = await Firebase.firestore.runTransaction(async t => {


        let baseRef1 = await t.get(docRef)
        let baseRef2 = await t.get(docRef2)
        let baseRef3 = await t.get(docRef2)
        console.log(baseRef1)
        console.log(baseRef2)
        console.log(baseRef3)

        let ax = await getValueForRef(t, Firebase.firestore.collection('projects').doc(Firebase.userProject).collection("arrivals").doc('wiw'))

        await t.update(docRef ,{'driverphone': 'jg924jg924'});
        await t.update(docRef ,{'transporterphone': '44444'});
        await t.update(docRef2 ,{'dlnumber': '@@@@4'});

        return text_filters
        

        }).then(result => {
          
          console.log('Transaction success!' + result);
          return {'transaction': true, 'newVal': newVal, 'oldNumericSave': oldNumericSave, 'newValId': newValId}
        }).catch(err => {
          console.log('Transaction failure:', err);
          return {'transaction': null, 'failMessage': err}
        });
  
        console.log(transaction)

    }*/
  }

  async function doNowExtreme(r) {
    console.log('do now ext for ')
    console.log(r)
    var newVal = r
        var oldNumericSave = r.dispatchNumber
        var newValId = r.id
    
        let s = async () => {
            var datPack = {}
            const imageRef = Firebase.storage.ref('test').child('pdf_for_wab_' + oldNumericSave + '.pdf')
            let v = await imageRef.getDownloadURL();
            console.log(v)
            console.log('got it')
            datPack['pdflink'] = v 
            datPack['dispatchId'] = newValId
            let snone = () => console.log('x')
            Firebase.provideWaybillLink(datPack, snone, snone)
            //window.open(v)
    
        } 
    
        let f = () => {
         
        }
        let s2 = async () => {
          var datPack = {}
          const imageRef = Firebase.storage.ref('test').child('pdf_for_whout_' + oldNumericSave + '.pdf')
          let v = await imageRef.getDownloadURL();
          console.log(v)
          console.log('got it')
          datPack['pdflink'] = v 
          datPack['dispatchId'] = newValId
          let snone = () => console.log('x')
          Firebase.provideWarehouseOutLink(datPack, snone, snone)
          //window.open(v)
  
      } 
  
      let f2 = () => {
       
      }
      var datxx = {}
      datxx['val'] = newVal
      datxx['userId'] = 'test'
      
      Firebase.generateWarehouseOut(datxx, s2, f2)
        
        Firebase.generateWaybill(datxx, s, f)
  }


    async function donow() {
      if (selection.length != 1) {
        /*alert('Select a single item to void.')
        return*/
        rows.map((row, idx) => {
          if (selection.includes(row.id)) {
            doNowExtreme(row)
          }
        } )
      } else {
        var r = null 
        rows.map((row, idx) => {
          if (row.id == selection[0]) {
            r = row
          }
        })

        console.log(r)



        var newVal = r
        var oldNumericSave = r.dispatchNumber
        var newValId = r.id
    
        let s = async () => {
            var datPack = {}
            const imageRef = Firebase.storage.ref('test').child('pdf_for_wab_' + oldNumericSave + '.pdf')
            let v = await imageRef.getDownloadURL();
            console.log(v)
            console.log('got it')
            datPack['pdflink'] = v 
            datPack['dispatchId'] = newValId
            let snone = () => console.log('x')
            Firebase.provideWaybillLink(datPack, snone, snone)
            //window.open(v)
    
        } 
    
        let f = () => {
         
        }
        let s2 = async () => {
          var datPack = {}
          const imageRef = Firebase.storage.ref('test').child('pdf_for_whout_' + oldNumericSave + '.pdf')
          let v = await imageRef.getDownloadURL();
          console.log(v)
          console.log('got it')
          datPack['pdflink'] = v 
          datPack['dispatchId'] = newValId
          let snone = () => console.log('x')
          Firebase.provideWarehouseOutLink(datPack, snone, snone)
          //window.open(v)
  
      } 
  
      let f2 = () => {
       
      }
      var datxx = {}
      datxx['val'] = newVal
      datxx['userId'] = 'test'
      
      Firebase.generateWarehouseOut(datxx, s2, f2)
        
        Firebase.generateWaybill(datxx, s, f)
      }
    }

  async function voidReport(remark) {
    if (selection.length != 1) {
      alert('Select a single item to void.')
      return 
    } else {
      var r = null 
      rows.map((row, idx) => {
        if (row.id == selection[0]) {
          r = row
        }
      })
      var transaction = null 
      console.log(r)
      if (r != null) {

        
        var newVal = r 
        if (newVal.fromWarehouse) {
      

          var oldSet = {'sku': newVal.sku.name}
          oldSet['warehouse'] = newVal.source.name 
    


          let oldStockKey = getDictId(oldSet)

    
          console.log(oldStockKey)

          // Reference of item to void 
          var docRef = Firebase.firestore.collection('projects').doc(Firebase.userProject).collection("arrivals").doc(newVal.id)
        
          // Reference of train for which we will DECREASE sent amounts
          var docRefTrain = newVal['fromVessel'] ? Firebase.firestore.collection('projects').doc(Firebase.userProject).collection("operations").doc(newVal.operation.operationId) : Firebase.firestore.collection('projects').doc(Firebase.userProject).collection("trains").doc(newVal.train.trainId)
          // Reference of stock in the train to INCREASE
          let stockDocRefPending = Firebase.firestore.collection('projects').doc(Firebase.userProject).collection('barleyStocks').doc(oldStockKey)

          var newValId = docRef.id 
          var newQueryResult = null 
          var oldQueryResult = null 
          var oldNumericSave = null;
          var oldNumericValue = null;
          var newNumericValue = null;
          var oldDocRefValue = null
          var oldPendingValue = null 
      
          var trainVal = null;
          var innerPlanRef = null 
          var innerPlanVal = null
          // Reference of inner plan for which we will DECREASE received quantity
          if (newVal['planId'] != null && newVal['innerPlanId'] != null) {
            innerPlanRef = Firebase.firestore.collection('projects').doc(Firebase.userProject).collection('plansInner').doc(newVal['planId']).collection('contents').doc(newVal['innerPlanId'])
          }
    

         transaction = await Firebase.firestore.runTransaction(async t => {
    
          return t.get(docRef).then(async docSnap => {
    
            if (docSnap.exists) {
              oldDocRefValue = docSnap.data()
              if (oldDocRefValue.voided) {
                const errM = {'msg': 'Already voided.'}
                throw errM
              } 
            } else {
              const errM = {'msg': 'Dispatch does not exist.'}
              throw errM
            }

            if (innerPlanRef) {
              let innerPlanRes = await t.get(innerPlanRef)
              if (innerPlanRes.exists) {
                innerPlanVal = innerPlanRes.data()
              }
            }
            let trainRes = await t.get(docRefTrain)
            if (trainRes.exists) {
              trainVal = trainRes.data()
            } else {
              const errM = {'msg': "Train does not exist."}
              throw errM
            }
      
              return t.get(stockDocRefPending)
            }).then(pendingSnap => {
              if (pendingSnap.exists) {
                oldPendingValue = pendingSnap.data()
              } 
              
            return t.update(docRef, {'voided': true, 'voidremark': remark})
          }).then(t => {

            // Update train if needed
            if (trainVal) {
              var newAllocTrain = trainVal.sentAmounts ? trainVal.sentAmounts : {}
              let whid = newVal.warehouse.customId 
              let newAmt = newAllocTrain[whid] ? newAllocTrain[whid] : 0
              let finAmt = Math.max(newAmt - newVal['netweightkg'], 0)
              newAllocTrain[whid] = finAmt 
              t.update(docRefTrain, {'sentAmounts': newAllocTrain})
            }


            // Update inner plan if needed
            // Remove amount that was dispatched out here
            if (innerPlanVal) {
              let current = innerPlanVal.receivedqty ? innerPlanVal.receivedqty : 0
              var updateValInnerPlan = {'receivedqty': Math.max(current - newVal['netweightkg'], 0)}
              t.update(innerPlanRef, updateValInnerPlan)
            }


            if (oldPendingValue === null) {
              oldSet['netweightkg'] = newVal['netweightkg']
              return t.set(stockDocRefPending, oldSet)
            } else {
              var updateValOldPending = {}
              updateValOldPending['netweightkg'] = oldPendingValue['netweightkg'] + newVal['netweightkg']
              return t.update(stockDocRefPending, updateValOldPending);
            }
      
          })
          }).then(result => {
            
            console.log('Transaction success!' + result);
            return {'transaction': true, 'newVal': newVal, 'oldNumericSave': oldNumericSave, 'newValId': newValId}
          }).catch(err => {
            console.log('Transaction failure:', err);
            return {'transaction': null, 'failMessage': err}
          });
    


        } else if (newVal.trainAndWarehouse) {

          var oldSet = {'sku': newVal.sku.name}
          if (newVal['fromVessel']) {
            oldSet['vessel'] = newVal.operation.name 
          } else {
            oldSet['train'] = newVal.train.name 
          }

          var warehouseSet = {'sku': newVal.sku.name}
          warehouseSet['warehouse'] = newVal.train.warehouse.name
      


          let oldStockKey = getDictId(oldSet)
          let warehouseStockKey = getDictId(warehouseSet)
    
    

          // Reference of item to void 
          var docRef = Firebase.firestore.collection('projects').doc(Firebase.userProject).collection("arrivals").doc(newVal.id)
        
          // Reference of train for which we will DECREASE sent amounts
          var docRefTrain = newVal['fromVessel'] ? Firebase.firestore.collection('projects').doc(Firebase.userProject).collection("operations").doc(newVal.operation.operationId) : Firebase.firestore.collection('projects').doc(Firebase.userProject).collection("trains").doc(newVal.train.trainId)
          // Reference of stock in the train to INCREASE
          let stockDocRefPending = Firebase.firestore.collection('projects').doc(Firebase.userProject).collection('barleyStocks').doc(oldStockKey)
          let warehouseDocRefPending = Firebase.firestore.collection('projects').doc(Firebase.userProject).collection('barleyStocks').doc(warehouseStockKey)

          var newValId = docRef.id 
          var newQueryResult = null 
          var oldQueryResult = null 
          var oldNumericSave = null;
          var oldNumericValue = null;
          var newNumericValue = null;
      
          var oldWarehouseValue = null 

          var trainVal = null;
          var innerPlanRef = null 
          var innerPlanVal = null
          // Reference of inner plan for which we will DECREASE received quantity
          if (newVal['planId'] != null && newVal['innerPlanId'] != null) {
            innerPlanRef = Firebase.firestore.collection('projects').doc(Firebase.userProject).collection('plansInner').doc(newVal['planId']).collection('contents').doc(newVal['innerPlanId'])
          }
    

         transaction = await Firebase.firestore.runTransaction(async t => {
    
          return t.get(docRef).then(async docSnap => {
    
            if (docSnap.exists) {
              oldDocRefValue = docSnap.data()
              if (oldDocRefValue.voided) {
                const errM = {'msg': 'Already voided.'}
                throw errM
              } 
            } else {
              const errM = {'msg': 'Dispatch does not exist.'}
              throw errM
            }

            if (innerPlanRef) {
              let innerPlanRes = await t.get(innerPlanRef)
              if (innerPlanRes.exists) {
                innerPlanVal = innerPlanRes.data()
              }
            }
            let trainRes = await t.get(docRefTrain)
            if (trainRes.exists) {
              trainVal = trainRes.data()
            } else {
              const errM = {'msg': "Train does not exist."}
              throw errM
            }

            let warehouseRes = await t.get(warehouseDocRefPending)
            if (warehouseRes.exists) {
              oldWarehouseValue = warehouseRes.data() 
            }
      
              return t.get(stockDocRefPending)
            }).then(pendingSnap => {
              if (pendingSnap.exists) {
                oldPendingValue = pendingSnap.data()
              } 
              
            return t.update(docRef, {'voided': true, 'voidremark': remark})
          }).then(t => {

            // Update train if needed
            if (trainVal) {
              var newAllocTrain = trainVal.sentAmounts ? trainVal.sentAmounts : {}
              let whid = newVal.warehouse.customId 
              let newAmt = newAllocTrain[whid] ? newAllocTrain[whid] : 0
              let finAmt = Math.max(newAmt - newVal['netweightkg'], 0)
              newAllocTrain[whid] = finAmt 
              t.update(docRefTrain, {'sentAmounts': newAllocTrain})
            }


            // Update inner plan if needed
            // Remove amount that was dispatched out here
            if (innerPlanVal) {
              let current = innerPlanVal.receivedqty ? innerPlanVal.receivedqty : 0
              var updateValInnerPlan = {'receivedqty': Math.max(current - newVal['netweightkg'], 0)}
              t.update(innerPlanRef, updateValInnerPlan)
            }

            if (oldWarehouseValue === null) {
              warehouseSet['netweightkg'] = newVal['netweightkgwh']
              t.set(warehouseDocRefPending, warehouseSet)
            } else {
              var updateValWarehouse = {}
              updateValWarehouse['netweightkg'] = oldWarehouseValue['netweightkg'] + newVal['netweightkgwh']
              t.update(warehouseDocRefPending, updateValWarehouse);
            }


            if (oldPendingValue === null) {
              oldSet['netweightkg'] = newVal['netweightkg']
              return t.set(stockDocRefPending, oldSet)
            } else {
              var updateValOldPending = {}
              updateValOldPending['netweightkg'] = oldPendingValue['netweightkg'] + newVal['netweightkg']
              return t.update(stockDocRefPending, updateValOldPending);
            }
      
          })
          }).then(result => {
            
            console.log('Transaction success!' + result);
            return {'transaction': true, 'newVal': newVal, 'oldNumericSave': oldNumericSave, 'newValId': newValId}
          }).catch(err => {
            console.log('Transaction failure:', err);
            return {'transaction': null, 'failMessage': err}
          });


        } else {



          var oldSet = {'sku': newVal.sku.name}
          if (newVal['fromVessel']) {
            oldSet['vessel'] = newVal.operation.name 
          } else {
            oldSet['train'] = newVal.train.name 
          }

      


          let oldStockKey = getDictId(oldSet)

    
    

          // Reference of item to void 
          var docRef = Firebase.firestore.collection('projects').doc(Firebase.userProject).collection("arrivals").doc(newVal.id)
        
          // Reference of train for which we will DECREASE sent amounts
          var docRefTrain = newVal['fromVessel'] ? Firebase.firestore.collection('projects').doc(Firebase.userProject).collection("operations").doc(newVal.operation.operationId) : Firebase.firestore.collection('projects').doc(Firebase.userProject).collection("trains").doc(newVal.train.trainId)
          // Reference of stock in the train to INCREASE
          let stockDocRefPending = Firebase.firestore.collection('projects').doc(Firebase.userProject).collection('barleyStocks').doc(oldStockKey)

          var newValId = docRef.id 
          var newQueryResult = null 
          var oldQueryResult = null 
          var oldNumericSave = null;
          var oldNumericValue = null;
          var newNumericValue = null;
      
          var trainVal = null;
          var innerPlanRef = null 
          var innerPlanVal = null
          // Reference of inner plan for which we will DECREASE received quantity
          if (newVal['planId'] != null && newVal['innerPlanId'] != null) {
            innerPlanRef = Firebase.firestore.collection('projects').doc(Firebase.userProject).collection('plansInner').doc(newVal['planId']).collection('contents').doc(newVal['innerPlanId'])
          }
    

         transaction = await Firebase.firestore.runTransaction(async t => {
    
          return t.get(docRef).then(async docSnap => {
    
            if (docSnap.exists) {
              oldDocRefValue = docSnap.data()
              if (oldDocRefValue.voided) {
                const errM = {'msg': 'Already voided.'}
                throw errM
              } 
            } else {
              const errM = {'msg': 'Dispatch does not exist.'}
              throw errM
            }

            if (innerPlanRef) {
              let innerPlanRes = await t.get(innerPlanRef)
              if (innerPlanRes.exists) {
                innerPlanVal = innerPlanRes.data()
              }
            }
            let trainRes = await t.get(docRefTrain)
            if (trainRes.exists) {
              trainVal = trainRes.data()
            } else {
              const errM = {'msg': "Train does not exist."}
              throw errM
            }
      
              return t.get(stockDocRefPending)
            }).then(pendingSnap => {
              if (pendingSnap.exists) {
                oldPendingValue = pendingSnap.data()
              } 
              
            return t.update(docRef, {'voided': true, 'voidremark': remark})
          }).then(t => {

            console.log(trainVal)
            console.log(oldPendingValue)
            console.log(newVal)
            console.log(oldSet)



            // Update train if needed
            if (trainVal) {
              var newAllocTrain = trainVal.sentAmounts ? trainVal.sentAmounts : {}
              let whid = newVal.warehouse.customId 
              let newAmt = newAllocTrain[whid] ? newAllocTrain[whid] : 0
              let finAmt = Math.max(newAmt - newVal['netweightkg'], 0)
              newAllocTrain[whid] = finAmt 
              t.update(docRefTrain, {'sentAmounts': newAllocTrain})
            }


            // Update inner plan if needed
            // Remove amount that was dispatched out here
            if (innerPlanVal) {
              let current = innerPlanVal.receivedqty ? innerPlanVal.receivedqty : 0
              var updateValInnerPlan = {'receivedqty': Math.max(current - newVal['netweightkg'], 0)}
              t.update(innerPlanRef, updateValInnerPlan)
            }


            if (oldPendingValue === null) {
              oldSet['netweightkg'] = newVal['netweightkg']
              return t.set(stockDocRefPending, oldSet)
            } else {
              var updateValOldPending = {}
              updateValOldPending['netweightkg'] = oldPendingValue['netweightkg'] + newVal['netweightkg']
              return t.update(stockDocRefPending, updateValOldPending);
            }
      
          })
          }).then(result => {
            
            console.log('Transaction success!' + result);
            return {'transaction': true, 'newVal': newVal, 'oldNumericSave': oldNumericSave, 'newValId': newValId}
          }).catch(err => {
            console.log('Transaction failure:', err);
            return {'transaction': null, 'failMessage': err}
          });


        }





  
        Firebase.trackMixpanel('voidDispatch', {'result': transaction, 'id': r.id})


      }
    }
  } 
  function constructStockQuery(val, ref) {
    var query = ref.where('warehouse', '==', val['warehouse']).where('uncategorized', '==', val['uncategorized'])
    if ('variety' in val) {
      query = query.where('variety', '==', val['variety'])
    }
    if ('moisture' in val) {
      query = query.where('moisture', '==', val['moisture'])
    }
    if ('purity' in val) {
      query = query.where('purity', '==', val['purity'])
    }
    
    return query 
  }


  function getDictId(dct) {
    var keys = Object.keys(dct)
    keys.sort()
    var s = ''
    for (var i = 0; i < keys.length; i++) {
      let key = keys[i]
      if (i > 0) {
        s += '-'
      }
      s += key 
      s += ':'
      s += dct[key]
    }
    s += '-yearcode:25'
    return s 
  }

  function determineStockMembership(val) {

    /**
     *  1. Traveler // Variety purity >=90% // Moisture =<15%
        2. Traveler //  Variety purity <90% // Moisture =<15%
        3. Traveler // Variety all purity // Moisture >15%
        4. Fatima // Variety purity >=90% // Moisture =<15%
        5. Fatima // Variety purity <90% // Moisture =<15%
        6. Fatima //  Variety all purity // Moisture >15%
        7. Planet // Variety purity >=90% // Moisture =<15%
        8. Planet // Variety purity <90% // Moisture =<15%
        9. Planet //  Variety all purity // Moisture >15%
        10. All other varieties // Variety all purity // Moisture =<15%
        11. All other varieties // Variety all purity // Moisture >15%
     */
    var warehouse = 'None'
    if (val.warehouse && val.warehouse.name) {
      warehouse = val.warehouse.name
    }

    var uncategorized = false 
    
    if (!val.moisture || !val.variety) {
      // if moisture or variety is not defined, we are done, we just need uncategorized to be true
      uncategorized = true 
      return {'uncategorized': true, 'warehouse': warehouse}
    } 

    var variety = val.variety
      if (val.variety == 'Traveler' || val.variety == 'Fatima' || val.variety == 'Planet') {
        variety = val.variety 
      } else {
        variety = 'other'
      }

    if (val.moisture > 15) {
      // if moisture is above 15, the only other characteristic we need is variety
      // this accounts for the following options, where purity does not matter:
      /*
      3. Traveler // Variety all purity // Moisture >15%
      6. Fatima //  Variety all purity // Moisture >15%
      9. Planet //  Variety all purity // Moisture >15%
      11. All other varieties // Variety all purity // Moisture >15%
      */

      return {'uncategorized': false, 'warehouse': warehouse, 'variety': variety, 'moisture': 'above'}
    } else if (!val.purity) {
      // if purity is not defined and moisture is <= 15
      // this makes it case 10 IF variety is already at 'other', else it becomes uncategorized
      if (variety == 'other') {
        // 10. All other varieties // Variety all purity // Moisture =<15%
        return {'uncategorized': false, 'warehouse': warehouse, 'variety': variety, 'moisture': 'below'}
      } else {
        return {'uncategorized': true, 'warehouse': warehouse}
      }

    } else {
      // purity, variety, and moisture are all defined
      // moisture is below 

      if (variety == 'other') {
        // 10. All other varieties // Variety all purity // Moisture =<15%
        return {'uncategorized': false, 'warehouse': warehouse, 'variety': variety, 'moisture': 'below'}
      }

      var purity = 'above'
      if (val.purity < 90) {
        purity = 'below'
      }
      /*
      1. Traveler // Variety purity >=90% // Moisture =<15%
      2. Traveler //  Variety purity <90% // Moisture =<15%
      4. Fatima // Variety purity >=90% // Moisture =<15%
      5. Fatima // Variety purity <90% // Moisture =<15%
      7. Planet // Variety purity >=90% // Moisture =<15%
      8. Planet // Variety purity <90% // Moisture =<15%
      */
      return {'uncategorized': false, 'warehouse': warehouse, 'variety': variety, 'moisture': 'below', 'purity': purity }
    }

  }


  async function queryNewOldTransaction(newVal, oldVal, newId, oldId) {

    let oldQuery = Firebase.firestore.collection('barleyStocks').doc(newId)
    let newQuery = Firebase.firestore.collection('barleyStocks').doc(oldId)
    var oldQueryId = null 
    var newQueryId = null 
    var oldQueryResult = null 
    var newQueryResult = null 

    let transaction = await Firebase.firestore.runTransaction(t => {
      let newNumericValue;
      let oldNumericValue;
      return t.get(oldQuery )
      .then(querySnapshot => {

        if (querySnapshot.exists) {
          oldQueryResult = querySnapshot.data()

        } else {
          oldQueryResult = null 
        }
        return t.get(newQuery);
      }).then(querySnapshotNew => {

        if (querySnapshotNew.exists) {
          newQueryResult = querySnapshotNew.data()
          var updateVal = {}
          updateVal['netweightkg'] = newQueryResult['netweightkg'] + newVal['netweightkg']
          return t.update(newQuery,updateVal);
        } else {
          newQueryResult = null 
          return t.set(newQuery, newVal)
        }
      }).then(t => {

        if (oldQueryResult == null) {
          throw "Old stock does not exist."
        } else {
          var updateValOld = {}
          updateValOld['netweightkg'] = oldQueryResult['netweightkg'] - oldVal['netweightkg']
          return t.update(oldQuery ,updateValOld);
        }


      });

    }).then(result => {
          
      console.log('Transaction success!' + result);
      return 'tsuccess'
    }).catch(err => {
      console.log('Transaction failure:', err);
      return null
    });
  }

  async function queryNewOld(newVal, oldVal) { 

    // case where the queries differ, in a custom case where they are the same, we could simplify and not have
    // to query twice 

    let oldQuery = constructStockQuery(oldVal, Firebase.firestore.collection('barleyStocks'))
    let newQuery = constructStockQuery(newVal, Firebase.firestore.collection('barleyStocks'))

    var oldQueryId = null 
    var newQueryId = null 
    var oldQueryResult = await oldQuery.get()
    .then((querySnapshot) => {
      if (querySnapshot.docs && querySnapshot.docs.length == 1) {
        oldQueryId = querySnapshot.docs[0].id
        return querySnapshot.docs[0].data()
      } else {
        console.log('no snap')
        return null
      }
    })
    .catch((error) => {
      return null
        console.log("Error getting documents: ", error);
    }); 
    
    
    if (oldQueryResult != null) {
      // increment the past one
      console.log('we have an old result here')
      console.log(oldQueryResult)
      var updateValOld = {}
      updateValOld['netweightkg'] = oldQueryResult['netweightkg'] - oldVal['netweightkg']
      await Firebase.firestore.collection('barleyStocks').doc(oldQueryId).update(updateValOld).then((d) => {

      }).catch((e) => {
        console.log('errorno')
        console.log(e)
      })
    } else {
      console.log('no old result found')
    }


    var newQueryResult = await newQuery.get()
    .then((querySnapshot) => {
      if (querySnapshot.docs && querySnapshot.docs.length == 1) {
        newQueryId = querySnapshot.docs[0].id 
        return querySnapshot.docs[0].data()
      } else {
        console.log('no snap')
        return null
      }
    })
    .catch((error) => {
      return null
        console.log("Error getting documents: ", error);
    });    

    console.log('qids old and new ' + oldQueryId + ' ' + newQueryId)
    console.log('new result is ')
    console.log(newQueryResult)
    if (newQueryResult == null) {
      // create a whole new one
      let newDocRef = Firebase.firestore.collection('barleyStocks').doc()
      await newDocRef.set(newVal).then((d) => {

      }).catch((e) => {
        console.log('errorno')
        console.log(e)
      })
    } else {
      // increment the new one
      var updateVal = {}
      updateVal['netweightkg'] = newQueryResult['netweightkg'] + newVal['netweightkg']
      await Firebase.firestore.collection('barleyStocks').doc(newQueryId).update(updateVal).then((d) => {

      }).catch((e) => {
        console.log('errorno')
        console.log(e)
      })

    }

  }

function compareDictionaries( d1, d2 ) {
    // quick check for the same object
    if( d1 == d2 )
        return true;

    // check for null
    if( d1 == null || d2 == null )
        return false;

    // go through the keys in d1 and check if they're in d2 - also keep a count
    var count = 0;
    for( var key in d1 )
    {
        // check if the key exists
        if( !( key in d2 ) )
            return false;

        // check that the values are the same
        if( d1[key] != d2[key] )
            return false;

        count++;
    }

    // now just make sure d2 has the same number of keys
    var count2 = 0;
    for( key in d2 )
        count2++;

    // return if they're the same size
    return ( count == count2 );
}



  function getAutoOptions(barleyInCustomObjects, k) {
    if (k in barleyInCustomObjects) {
      return Object.keys(barleyInCustomObjects[k]).map((key, id) => {
        return barleyInCustomObjects[k][key]
      })
    }
    return []
  }


  function doApiUpdate(api, id, field, val, event) {
    console.log('incoming update with ')
    console.log(val)
    if (!api) {
      return
    }
    api.setEditCellValue({ id, field, value: val }, event);
    // Check if the event is not from the keyboard
    // https://github.com/facebook/react/issues/7407
    if (event.nativeEvent.clientX !== 0 && event.nativeEvent.clientY !== 0) {
      api.commitCellChange({ id, field });
      api.setCellMode(id, field, 'view');
    }
  }

  function compare2(a,b) {

    let as = a.sortNum ? a.sortNum : -1
    let bs = b.sortNum ? b.sortNum : -1

    if (as > bs) {
      return -1
    } else if (bs > as) {
      return 1
    } else {
      return 0
    }
  }

  function getOpField(op) {
    var s = ''
    if (op.bolnumber) {
      s += op.bolnumber
    }
    if (op.operationnumber) {
      s += ' ' + op.operationnumber
    }
    if (op.name) {
      s += ' ' + op.name
    }
    return s
  }

  function numericValueNoComma(params) {
    if (params.value) {
      return params.value + ''
    } else {
      return params.value
    }
    //return params.value + ''
  }
  
  function dateValueGetter(params) {
    return params.value ? moment(params.value).format('DD/MM/YYYY HH:mm') : ''
  }

  function categoryGetter(params) {
    if (params.row['category'] && params.row['category']['name']) {
      return JSON.stringify(params.row['category']['name']).replace('\"', '').replace('\"', '')
    } else {
      return ''
    }
  }

  function renderColumns(fields) {
    return fields.sort((a,b) => compare2(a,b)).map((item, idx) => {

      if (item.fieldKey == 'approval') {
        return ({field: item.fieldKey, headerName: item.fieldName, width: 'approval' in item? 150 : 200, editable: false,
        type: item.fieldType != 'text' ? item.fieldType : null,
        renderCell: (params) => {
          if (params.value == 'approved') {
            return 'Approved'
          } else if (params.value == 'rejected') {
            return 'Declined'
          } else if (params.id in loadingDict) {
            return <CircularProgress/>
          }

          return <div style={{flexDirection: 'row'}}>
            <Button
          color="primary"
          variant="contained"
          onClick={ () => {
           doApprove(params.id, params.row)
          }}
        >
         Approve
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={ () => {
            doReject(params.id, params.row)
          }}
        >
         Decline
        </Button>
            </div>
        }
      })
      }

      if (item.fieldKey == 'operation') {
        return ({field: item.fieldKey, headerName: item.fieldName, width: item.width ? item.width : 150, editable: false,
  
  valueGetter: (params) => {

    if (params.row['operation']) {
      return params.row['operation'].operationnumber
    } else {
      return ''
    }

  } })
}

      if (item.fieldType == 'custom') {
        return {field: item.fieldKey, headerName: item.fieldName, width: item.width ? item.width : 200, editable: false,
           valueGetter: ({ value }) => value && value.name ? item.fieldKey != 'warehouse' ? value.name : value.name + '-' + value.union: '',
          renderEditCell: (params) => {              
            return  <Autocomplete
            value={params.value}
            onChange={(event, newValue) => {
              console.log(newValue)
              console.log(params)
              console.log(typeof newValue)
              //console.log(newValue.inputValue)
              if (typeof newValue === 'string') {

                //setFieldValue(fields[fieldIdx].fieldKey, {'customId': newValue.customId, 'name': newValue.name})
                doApiUpdate(params.api, params.id, item.fieldKey, {'customId': newValue.customId, 'name': newValue.name}, event)

              } else if (newValue && newValue.inputValue) {
                // Create a new value from the user input

                //setFieldValue(fields[fieldIdx].fieldKey, {'customId':-1, 'name': newValue.inputValue})
                doApiUpdate(params.api, params.id, item.fieldKey, {'customId': -1, 'name': newValue.inputValue}, event)
              } else {

                if (newValue == null) {
                  //setFieldValue(fields[fieldIdx].fieldKey, {'customId': '0', 'name': 'None'})
                  doApiUpdate(params.api, params.id, item.fieldKey, {'customId': '0', 'name': 'None'}, event)
                } else {
                  doApiUpdate(params.api, params.id, item.fieldKey, {'customId': newValue.customId, 'name': newValue.name}, event)
                  //setFieldValue(fields[fieldIdx].fieldKey, {'customId': newValue.customId, 'name': newValue.inputValue})
                }
              }
              //console.log(event.target.value)
              //console.log(newValue)
            }}
            filterOptions={(options, params) => {
              const filtered = filter(options, params);
      
              // Suggest the creation of a new value
              if (params.inputValue !== '') {
                filtered.push({
                  inputValue: params.inputValue,
                  name: `Add "${params.inputValue}"`,
                });
              }
      
              return filtered;
            }}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            id="free-solo-with-text-demo"
            options={getAutoOptions(barleyInCustomObjects, item.fieldKey)}
            getOptionLabel={(option) => {
              // Value selected with enter, right from the input
              if (typeof option === 'string') {
                return option;
              }
              // Add "xxx" option created dynamically
              if (option.inputValue) {
                return option.inputValue;
              }
              // Regular option
              return option.name;
            }}
            renderOption={(option) => option.name}
            freeSolo
            renderInput={(params) => <TextField {...params} style={{ width: 150, height: 150}}  autoFocus  />}
          />}
          
                      
        }
      } else if (item.fieldType == 'list') {
        return {field: item.fieldKey, headerName: item.fieldName, width: item.width ? item.width : 150, editable: false,
          type: 'singleSelect', valueOptions: item.listOptions                      
        }
      }

      if (item.fieldKey == 'warehouseinlink') {
        return ({field: item.fieldKey, headerName: item.fieldName, width: item.width ? item.width : 150, editable: false,
        type: item.fieldType != 'text' ? item.fieldType : null,
        renderCell: (params) => {
          if (params.value && params.value.length > 0) {
            return   <Link
            to={params.value}
            style={{cursor: 'pointer'}}
            onClick={ () => window.open(params.value)}
            variant="h6"
          >{'View'}</Link>
          } else {
            return <div/>
          }
        }
      })
      }


      if (item.fieldKey == 'warehouseoutlink' ) {
        return ({field: item.fieldKey, headerName: item.fieldName, width: item.width ? item.width : 150, editable: false,
        type: item.fieldType != 'text' ? item.fieldType : null,
        renderCell: (params) => {
          if (params.row['fromWarehouse'] || params.row['trainAndWarehouse']) {
          //if (params.row['warehouseinlink'] && params.row['warehouseinlink'].length) {
            return   <Link
            to={params.value}
            style={{cursor: 'pointer', textDecorationLine: params.row['voided'] ? 'line-through' : 'none'}}
            onClick={async () => {
              let b = await Firebase.getSignedUrl({'bucket': 'test', 'file': 'pdf_for_whout_' + params.row['dispatchNumber'] + '.pdf'}) 
              if (b && b.length && b.length > 0) {
                window.open(b[0])
              } else {
                alert('Error retrieving file.')
              }

            }}
            variant="h6"
          >{[params.row['dispatchNumber']]}</Link>
         /* } else {
            return params.value 
          }*/
          } else {
            return ''
          }


        }, 
      })
      }

      if (item.fieldKey == 'dispatchNumber' ) {
        return ({field: item.fieldKey, headerName: item.fieldName, width: item.width ? item.width : 150, editable: false,
        type: item.fieldType != 'text' ? item.fieldType : null,
        renderCell: (params) => {

          //if (params.row['warehouseinlink'] && params.row['warehouseinlink'].length) {
            return  <div style={{display: 'flex', flexDirection: 'row'}}> <Link
            to={params.value}
            style={{cursor: 'pointer', textDecorationLine: params.row['voided'] ? 'line-through' : 'none'}}
            onClick={async () => {
              let b = await Firebase.getSignedUrl({'bucket': 'test', 'file': 'pdf_for_wab_' + params.row['dispatchNumber'] + '.pdf'}) 
              if (b && b.length && b.length > 0) {
                window.open(b[0])
              } else {
                alert('Error retrieving file.')
              }

            }}
            variant="h6"
          >{[params.value]}</Link>
            {'editCommittedBy' in params.row ?
            <Layers  onClick={() => navigate('/app/dispatchhistory', {state: {id: params.id, current_data: params.row, dispatchFields: dispatchFields}})}/>
            : null}
          </div>
         /* } else {
            return params.value 
          }*/
        }, 
      })
      }

      if (item.fieldKey == 'category') {
        return ({field: item.fieldKey, headerName: item.fieldName, width: item.width ? item.width : 150, editable: false,
        type: item.fieldType != 'text' ? item.fieldType : null, valueGetter: categoryGetter
      })
      }

      if (item.fieldType == 'date' && item.fieldKey == 'creationDate') {
        return ({field: item.fieldKey, headerName: item.fieldName, width: item.width ? item.width : 150, editable: false,
          type: item.fieldType != 'text' ? item.fieldType : null, valueFormatter: (params) => {
            return params.value ? moment(params.value).format('DD/MM/YYYY') : ''
          }, renderCell: (params) => {
            return params.value ? moment(params.value).format('DD/MM/YYYY HH:mm') : ''
          }})
      } else if (item.fieldType == 'date' && item.fieldKey == 'creationDateHour') {

        return ({field: item.fieldKey, headerName: item.fieldName, width: item.width ? item.width : 150, editable: false,
          type: item.fieldType != 'text' ? item.fieldType : null, hide: true, valueGetter: (params) => {
            return params && params.row && params.row['creationDate'] ? moment(params.row['creationDate']).format('HH:mm') : ''
          }})
      } else if (item.fieldType == 'date') {
        return ({field: item.fieldKey, headerName: item.fieldName, width: item.width ? item.width : 150, editable: false,
          type: item.fieldType != 'text' ? item.fieldType : null, valueFormatter: (params) => {
            return params.value ? moment(params.value).format('DD/MM/YYYY HH:mm') : ''
          }})
      }

      if (item.fieldType == 'number') {
        return ({field: item.fieldKey, headerName: item.fieldName, width: item.width ? item.width : 150, editable: false,
          type: item.fieldType != 'text' ? item.fieldType : null,
        valueGetter: numericValueNoComma })
      }




      if (item.fieldKey == 'displaybol') {
                return ({field: item.fieldKey, headerName: item.fieldName, width: item.width ? item.width : 150, editable: false,
          type: item.fieldType != 'text' ? item.fieldType : null,
          valueGetter: (params) => {
  
            if (params.row['operation']) {
              return params.row['operation'].bolnumber
            } else {
              return ''
            }
  
          } })
      }
      if (item.fieldKey == 'displaydeclaration') {
        return ({field: item.fieldKey, headerName: item.fieldName, width: item.width ? item.width : 150, editable: false,
        type: item.fieldType != 'text' ? item.fieldType : null,
        valueGetter: (params) => {

          if (params.row['operation']) {
            return params.row['operation'].declarationnumber
          } else {
            return ''
          }

        } })
      }

      return ({field: item.fieldKey, headerName: item.fieldName, width: item.width ? item.width : 150, editable: false,
      type: item.fieldType != 'text' ? item.fieldType : null})
    })
  }


  async function tryNewColumn(dat) {
    let result = await Firebase.requestNewColumn({'project': Firebase.userProject, 'dat': dat, 'fieldsCollection': 'arrivalFields'})
    console.log(result)
    if (!result) {
      alert('This field already exists.')
    }
  }

  async function doApprove(id, v) {

    var ldc = loadingDict 
    ldc[id] = 1
    setLoadingDict(ldc)

    let result = await Firebase.doApprove({'id': id, 'values': v})
    
    ldc = loadingDict 
    if (id in ldc) {
      delete ldc[id]
    }
    setLoadingDict(ldc)
  } 

  async function doReject(id, v) {

    var ldc = loadingDict 
    ldc[id] = 1
    setLoadingDict(ldc)
    let result = await Firebase.doReject({'id': id, 'values': v})
    ldc = loadingDict 
    if (id in ldc) {
      delete ldc[id]
    }
    setLoadingDict(ldc)
  }


  async function updateRow(id, updateVal, field) {

    if (field.fieldType === 'custom' && updateVal[field.fieldKey].customId == -1) {
      var trueUpdate = updateVal 
      var docRef = Firebase.firestore.collection("Dispatch").doc(id);
      let docRefNext = Firebase.firestore.collection('barleyInCustomObjects').doc('contents').collection(field.fieldKey).doc()
      trueUpdate[field.fieldKey]['customId'] = docRefNext.id
      console.log('here is trueupdate')
      console.log(trueUpdate)

      var batch = Firebase.firestore.batch()
      batch.set(docRefNext, {'name': trueUpdate[field.fieldKey].name}  )
      batch.update(docRef, trueUpdate)
      let transaction = await batch.commit().then(result => {
        
        console.log('Batch success!' + result);
        return true
      }).catch(err => {
        console.log('Batch failure:', err);
        return false
      });
      return transaction

    } else {
      var docRef = Firebase.firestore.collection("Dispatch").doc(id);
      let ret = await docRef.update(updateVal).then(() => {
        console.log("Document successfully updated!");
        return true 
        })
        .catch((error) => {
            // The document probably doesn't exist.
            console.error("Error updating document: ", error);
            console.log('falseret')
            return false 
        });
        return ret 
    }
  }

  async function onCellChange(value, dispatchFields)  {
    // Get the row
    console.log('osc')
    console.log(value)
    console.log(value.field)
    console.log(value.value)
    if (!value.value || value.value == 'undefined') {
      console.log('retnowundef')
      return
    }
    //console.log(value)

    const rowIndex = rows.findIndex(row => row.id === value.id);
    const fieldIndex = dispatchFields.findIndex(item => value.field === item.fieldKey)

    if (rowIndex >= 0 && fieldIndex >= 0) {
      const row = rows[rowIndex];
      const field = dispatchFields[fieldIndex]
      // Validate if changed
      
      if (!(value.field in row) || (value.field in row && row[value.field] !== value.value)) {
        const data = { [value.field]: value.value };
        console.log('did change, here is data')
        console.log(data)
        console.log(row.id)
        // Sending to API
        const newRows = [...rows];
        //await prepUpdate(row, value.field, value.value)
        var ret = null 
        ret = await updateRow(row.id, data, field)
        /*if (value.field == 'warehouse' || value.field == 'moisture' || value.field == 'purity' || value.field == 'netweightkg' || value.field == 'variety') {
          ret = await updateRowAndStocks(row.id, data, field, row, value.field, value.value)
        } else {
          ret = await updateRow(row.id, data, field)
        }*/
        if (ret) {
          newRows[rowIndex][value.field] = value.value;
        } else {
          console.log('oldset')
          console.log('ent val is')
          console.log(row[value.field])
          console.log(value.field)
          console.log(row)
          if (value.field in row) {
            newRows[rowIndex][value.field] = row[value.field];
          } else {
            delete newRows[rowIndex][value.field]
          }

        }
        console.log(newRows)
        setRows(newRows);
        /*Api.product.update(data).then(res => {
          const newRows = [...rows];

          if (res.success) {
            // Change to new value
            newRows[rowIndex][value.field] = value.value;
          } else {
            // Change to old value
            newRows[rowIndex][value.field] = row[value.field];
          }

          setRows(newRows);
        });*/
      } else {
        console.log(rows)
        console.log('no change')
      }
    }
  };

  function hasCreationField(r) {
    var f = false 
    r.map((item, idx) => {
      if (item.fieldKey == 'creationDate') {
        f = true 
      }
    })

    return f
  }


  useEffect(() => {
    setRows(dispatchList);
  }, [dispatchList]);



  return (
    <div>
        <AddBarleyFieldDialog
        open={addColumnDialog}
        ref={docDiaRef}
        onClose={() => setAddColumnDialog(false)}
        alertTitle={'Add A New Field'}
        alertCancelText={'Cancel'}
        alertConfirmText={'Confirm'}
        onConfirm={(x) => tryNewColumn(x)}
        />
                <VoidNoteDialog
        open={voidDialog}
        onClose={() => setAddVoidDialog(false)}
        alertTitle={'Void Barley Transaction'}
        alertCancelText={'Cancel'}
        alertConfirmText={'Confirm'}
        onConfirm={(x) => voidReport(x ? x : 'REMARK')}
        />
<div style={{flexDirection: 'row', display: 'flex', justifyContent: 'space-between'}}>
{context.warehouseRole != 'Finance' && context.warehouseRole != 'Viewier' ? 
<Button
          color="primary"
          variant="contained"
          style={{marginBottom: 5}}
          onClick={() => navigate('/app/newDispatch', {state: {fields: dispatchFields}})}
        >
         {'+ Dispatch Truck'}
        </Button>
: null}

        <div style={{flexDirection: 'row'}}>
        {context.warehouseRole == 'Admin' ?
        <Button
        color="primary"
        variant="contained"
        style={{marginBottom: 5}}
        onClick={ () => {
         setAddColumnDialog(true)
        }}
      >
       Add Column
      </Button>
        : null}
                    {context.warehouseRole == 'Admin' || context.warehouseRole == 'Admin' || context.warehouseRole == 'Facility' || context.warehouseRole == 'Warehouse Manager' ? 
       <Button
       color="primary"
       variant="contained"
       style={{marginBottom: 5}}
       onClick={() => 
        {
          setAddVoidDialog(true)
       }}
     >
      Void Selected Entry
     </Button>
      : null} 

{context.warehouseRole == 'Admin' || context.warehouseRole == 'Admin' || context.warehouseRole == 'Facility' || context.warehouseRole == 'Warehouse Manager' ? 
       <Button
       color="primary"
       variant="contained"
       style={{marginBottom: 5}}
       onClick={() => 
        {
          donow()
       }}
     >
      Regenerate
     </Button>
      : null} 

                          {context.warehouseRole == 'Admin' || context.warehouseRole == 'Facility' ? 
       <Button
       color="primary"
       variant="contained"
       style={{marginBottom: 5}}
       onClick={() => 
        doEdit(context.warehouseRole)}
     >
      Edit
     </Button>
      : null} 


        </div>

  </div>




  {hasCreationField(dispatchFields) ? 

          <div style={{height: 500, width: '99%'}}>





            <DataGrid
  
        className={classes.root}
        rows={rows}
        columns={renderColumns(dispatchFields)}
        density={'compact'}
        pageSize={20}
        rowsPerPageOptions={[20]}
        onCellEditCommit={(p, e, d) => onCellChange(p, dispatchFields)}
        hideFooterPagination={false}
        hideFooter={false}
        checkboxSelection={true}
        disableSelectionOnClick
        sortModel={sortModel}
        onFilterModelChange={(mo) => {
          context.fetchAll( 'arrivals', 'arrivalList', 'arrivalDict', 'arrivalId')

          Firebase.trackMixpanel('filterDispatch', mo)

        }}
        onPageChange={(page) => {
          context.fetchAll( 'arrivals', 'arrivalList', 'arrivalDict', 'arrivalId')
        }}
        getRowClassName={(params) => `super-app-theme--${params.row.voided ? 'Void' : 'NotVoid'}`}
        onSelectionModelChange={(newSelection) => {
          setSelection(newSelection);
        }}
        components={{
          Toolbar: CustomToolbar,
        }}
        componentsProps={{ toolbar: { allowExport: context.warehouseRole == 'Admin' || context.warehouseRole == 'Facility' } }}
      />
      </div>
      : null}
    </div>
  )
};

DispatchTable.propTypes = {
  className: PropTypes.string,
  customers: PropTypes.array.isRequired
};

export default DispatchTable;

/*

       {hasCreationField(dispatchFields) ?
           <div style={{ height: 400, width: '100%' }} className={classes.root}>
           <DataGrid
        rows={rows}
        columns={renderColumns(dispatchFields)}
             getRowClassName={(params) => `super-app-theme--${params.row.voided ? 'Open' : 'Filled'}`}
           />
         </div>
      
      : null}*/
