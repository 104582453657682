import React, {Component} from 'react';
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField"
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { TimePicker } from "@material-ui/pickers";


const isFirefox = typeof InstallTrigger !== 'undefined';
var moment = require('moment');


class DateTimeMaster extends Component {


    static defaultProps = {
        headerColor: 'black',
        bodyColor: 'black',
    };

    constructor(props) {
        super(props);
        this.state = {
          value: this.props.value,
          firefoxTime: this.props.value ? moment(this.props.value) : Date.now()
        }

    }

    componentDidMount() {
        //alert(this.props.defaultValue)
    }

    momentFormat(date) {
      return moment(date).format('yyyy-MM-DD') + 'T' + moment(date).format('HH:mm')
    }
    
    momentFormatDate(date) {
      return moment(date).format('yyyy-MM-DD')
    }
    
    momentFormatFirefox(date, time) {
      let s = moment(date).format('yyyy-MM-DD') + 'T' + moment(time).format('HH:mm')
      this.props.onChange(s)
      return s
    }
   

    render() {
        return (
<div> {isFirefox ? 
            <Grid container >
            <TextField
        disabled={this.props.disabled}
        label={this.props.label}
        type="date"
        variant="outlined"
        margin="normal"
        name="startDate"
        onChange={(e) => {
        
         this.setState({
           value: this.momentFormatFirefox(e.target.value, this.state.firefoxTime)
         })
        }}
        value={this.state.value == '' ? this.state.value : this.momentFormatDate(this.state.value)}
        InputLabelProps={{
        shrink: true,
        }}
        />
        
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <TimePicker
        disabled={this.props.disabled}
        inputProps={{
          style: {
            height: 20,
            paddingTop: 35,
            paddingBottom: 18, paddingLeft: 5
          },
        }}
                clearable
                onChange={(e) => {
                  console.log(e)
                  if (this.state.value != '') {
                    this.setState({
                      value: this.momentFormatFirefox(this.state.value, e)
                    })
                   
                  }
                  this.setState({
                    firefoxTime: e
                  })
                  //setFieldValue('earlyPick', momentFormatFirefox(e.target.value, firefoxTimePickup))
                }}
                value={this.state.firefoxTime}
              /></MuiPickersUtilsProvider>
        </Grid>
           :      
           
           <Grid container justify="space-between">
             <TextField
             disabled={this.props.disabled}
        label={this.props.label}
        type="datetime-local"
        formatDate="DD/MM/YYYY"
        margin="normal"
        variant="outlined"
        name="startDate"
        onChange={(e) => {
          this.setState({
            value: e.target.value 
          })
          this.props.onChange(e.target.value)
        }}
        value={this.state.value}
        InputLabelProps={{
        shrink: true,
        }}
        />
        </Grid>}</div>   
        )

    }
}
const headerStyle = {
    backgroundColor: 'rgb(67,67,67)', flexWrap: 'never', whiteSpace: 'noWrap'
}

/*   <div onClick={() => this.adjustSortDescending()} style={{ margin: 0, display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: this.state.sortDescending ? 'orange' : 'white', border: this.state.sortDescending ? '' : '1px solid black', borderRadius: 30, padding: 0, height: 30, padding: 5 }}>
                                <p>Descending</p>
                            </div>*/

/*
 * 
 * 
 *             */

export default DateTimeMaster;