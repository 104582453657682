import React, {Component} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import MuiPhoneNumber from 'material-ui-phone-number'
import Firebase from 'src/Firebase'
import ChipInput from 'material-ui-chip-input'
import MyContext from 'src/MyContext'

const outOptions = [
  {'name': 'Traveler // Variety purity >=90% // Moisture =<15%', 'customId': 0, 'query': {'variety': 'Traveler', 'moisture': 'below', 'purity': 'above', 'uncategorized': false}},
  {'name': 'Traveler //  Variety purity <90% // Moisture =<15%', 'customId': 1, 'query': {'variety': 'Traveler', 'moisture': 'below', 'purity': 'below', 'uncategorized': false}},
  {'name': 'Traveler // Variety all purity // Moisture >15%', 'customId': 2, 'query': {'variety': 'Traveler', 'moisture': 'above', 'uncategorized': false}},
  {'name': 'Fatima // Variety purity >=90% // Moisture =<15%', 'customId': 3, 'query': {'variety': 'Fatima', 'moisture': 'below', 'purity': 'above', 'uncategorized': false}},
  {'name': 'Fatima // Variety purity <90% // Moisture =<15%', 'customId': 4, 'query': {'variety': 'Fatima', 'moisture': 'below', 'purity': 'below', 'uncategorized': false}},
  {'name': 'Fatima //  Variety all purity // Moisture >15%', 'customId': 5, 'query': {'variety': 'Fatima', 'moisture': 'above', 'uncategorized': false}},
  {'name': 'Planet // Variety purity >=90% // Moisture =<15%', 'customId': 6, 'query': {'variety': 'Planet', 'moisture': 'below', 'purity': 'above', 'uncategorized': false}},
  {'name': 'Planet // Variety purity <90% // Moisture =<15%', 'customId': 7, 'query':  {'variety': 'Planet', 'moisture': 'below', 'purity': 'below', 'uncategorized': false}},
  {'name': 'Planet //  Variety all purity // Moisture >15%', 'customId': 8, 'query': {'variety': 'Planet', 'moisture': 'above', 'uncategorized': false}},
  {'name': 'All other varieties // Variety all purity // Moisture =<15%', 'customId': 9, 'query': {'variety': 'other', 'moisture': 'below', 'uncategorized': false}},
  {'name': 'All other varieties // Variety all purity // Moisture >15%', 'customId': 10, 'query': {'variety': 'other', 'moisture': 'above', 'uncategorized': false}},
  {'name': 'Uncategorized', 'customId': 11, 'query': {'uncategorized': true}}]

class AddStockDialog extends Component {


    static defaultProps = {
        headerColor: 'black',
        bodyColor: 'black',
    };

    constructor(props) {
        super(props);

        let sfz = this.props.documentName
        this.state = {
            value: this.props.defaultValue,
            open: false,
            popRef: null,
            sortAscending: false,
            sortDescending: false,
            notificationType: '',
            notificationHeader: '',
            notificationBody: sfz,
            phoneNumber: '',
            listChips: [],
            category: null,
            facility: null, 
            available: 0,
            pending: 0,
            transit: 0
        }

        this.topRef = null
    }

    componentDidMount() {
        //alert('mounted')
    }

    async tryConfirm() {
      this.setState({loading: true})
      var newSet = {'sku': this.state.sku.name}
      newSet['warehouse'] = this.state.facility.name
      let v = await Firebase.addStock({'project': Firebase.userProject, 'newSet': newSet, 'available': this.state.available,
      'pending': this.state.pending, 'transit': this.state.transit, 'author': Firebase.uid, 'yearCodeForFiltering': '23'})
      //this.props.onConfirm(this.state)
      if (v && v.error) {
        alert(v.error)
      }
      this.props.onClose()
      this.setState({loading: false})
    }


    handleAddChip(chip) {
      var chips = this.state.listChips
      this.setState({
          listChips: chips.concat(chip),
          chipAdded: true 
      })
  }

  handleDeleteChip(chip, idx) {
      let chips = this.state.listChips.filter((item, ii) => {
          return (ii != idx)
      })

      this.setState({
          listChips: chips
      })
  }

  getAutoOptions(context, k) {
    if (k in context.barleyInCustomObjects) {
      return Object.keys(context.barleyInCustomObjects[k]).map((key, id) => {
        return context.barleyInCustomObjects[k][key]
      })
    }
    return []
  }

    render() {
        return (
          <MyContext.Consumer>{context => (
            <Dialog
            open={this.props.open}
            onClose={() => this.props.onClose()}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{this.props.alertTitle}</DialogTitle>
            <DialogContent>
            <TextField
            select 
            fullWidth
            style={{margin: 5}}
            label={'SKU'}
            onChange={(e) => this.setState({sku: e.target.value})}
            value={this.state.sku}
            variant="outlined">
            {this.getAutoOptions(context, 'sku').map((item, idx) => {
              return (<MenuItem value={item}>{item.name}</MenuItem>)
            })}
            </TextField>
            <TextField
            select 
            fullWidth
            style={{margin: 5}}
            label={'Facility'}
            onChange={(e) => this.setState({facility: e.target.value})}
            value={this.state.facility}
            variant="outlined">
            {this.getAutoOptions(context, 'warehouse').map((item, idx) => {
              return (<MenuItem value={item}>{item.name}</MenuItem>)
            })}
            </TextField>
            <TextField
              type={'number'}
              fullWidth
              style={{margin: 5}}
              label={'Available Stock'}
              onChange={(e) => {
                console.log(e.target.value)
                this.setState({
                  available: parseInt(e.target.value)
                })
              }}
              value={this.state.available}
              variant="outlined">
            </TextField>
            <TextField
              type={'number'}
              fullWidth
              style={{margin: 5}}
              label={'Pending Stock'}
              onChange={(e) => {
                console.log(e.target.value)
                this.setState({
                  pending: parseInt(e.target.value)
                })
              }}
              value={this.state.pending}
              variant="outlined">
            </TextField>
            <TextField
              type={'number'}
              fullWidth
              style={{margin: 5}}
              label={'In Transit Stock'}
              onChange={(e) => {
                console.log(e.target.value)
                this.setState({
                  transit: parseInt(e.target.value)
                })
              }}
              value={this.state.transit}
              variant="outlined">
            </TextField>
            </DialogContent>
            {this.state.loading ?
            <CircularProgress/>  
          :
          <DialogActions>
          <Button
          onClick={() => this.props.onClose()} color="primary">
            {this.props.alertCancelText}
          </Button>
          <Button 
          disabled={this.state.sku == null || this.state.sku.name == null || this.state.facility == null || this.state.facility.name == null}
          onClick={() => this.tryConfirm()} disabeld={this.state.fieldType == 'list' && this.state.listChips.length < 1} color="primary" autoFocus>
            {this.props.alertConfirmText}
          </Button>
        </DialogActions>
          }
           
          </Dialog>
          )}</MyContext.Consumer>
        )

    }
}
const headerStyle = {
    backgroundColor: 'rgb(67,67,67)', flexWrap: 'never', whiteSpace: 'noWrap'
}

/*   <div onClick={() => this.adjustSortDescending()} style={{ margin: 0, display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: this.state.sortDescending ? 'orange' : 'white', border: this.state.sortDescending ? '' : '1px solid black', borderRadius: 30, padding: 0, height: 30, padding: 5 }}>
                                <p>Descending</p>
                            </div>*/

/*
 * 
 * 
 *             */

export default AddStockDialog;