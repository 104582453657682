import React, {useState} from 'react';
import {
  Container,
  Grid,
  makeStyles,
  CircularProgress,
  Typography,
  TextField,
  MenuItem,
  Button
} from '@material-ui/core';
import Page from 'src/components/Page';
import Budget from './Budget';
import LatestOrders from './LatestOrders';
import LatestProducts from './LatestProducts';
import Sales from './Sales';
import TasksProgress from './TasksProgress';
import TotalCustomers from './TotalCustomers';
import TotalProfit from './TotalProfit';
import TrafficByDevice from './TrafficByDevice';
import DashboardCard from './DashboardCard';
import MyContext from 'src/MyContext';
import JobCard from './JobCard';
import Toolbar from '../../../shipper/Toolbar';
import DashboardCardHolder from './DashboardCardHolder';
import JobCardHolder from './JobCardHolder';
import { Doughnut } from 'react-chartjs-2';
import DoughnutBarley from './DoughnutBarley';
import QuickInfo from './QuickInfo';
import AdminMetricsHolder from './AdminMetricsHolder';
import TableContext from 'src/TableContext'
import BarleyStocks from './BarleyStocks';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import AddStockDialog from './AddStockDialog';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const Dashboard = (data) => {
  const classes = useStyles();
  const [totalBarley, setTotalBarley] = React.useState(0);
  const [source, setSource] = React.useState({'customId': '-1', 'id': '-1', 'name': 'All Facilities'})
  const [addStockDialog, setAddStockDialog] = React.useState(false)

  function getAutoOptions(context, k) {
    if (k in context.barleyInCustomObjects) {
      var xf = Object.keys(context.barleyInCustomObjects[k]).map((key, id) => {
        return context.barleyInCustomObjects[k][key]
      }).filter((item, idd) => {
        return item.warehousetype == 'Internal'
      })
      xf.push({'customId': '-1', 'id': '-1', 'name': 'All Facilities'})
      return xf
    }
    return [{'customId': '-1', 'id': '-1', 'name': 'All Facilities'}]
  }


  function getTotalBarley(ls) {
    var sm = 0
    ls.map((item, idx) => {
      if (item.netweightkg) {
        sm = sm + item.netweightkg
      }
    })
    if (sm != totalBarley) {
      setTotalBarley(sm)
    }
    return 'Total Barley Received: ' + sm + 'kg'
  }

  function getAllByCategory(bDct, pDct, tDct, customObjets) {

    var categoryDict = {}

    Object.keys(bDct).map((warehouse, idx) => {
      let itemOuter = bDct[warehouse]
      Object.keys(itemOuter).map((key, idxx) => {
        let targ = itemOuter[key]
        var total = 0
        var available = 0
        var pending = 0
        var transit = 0
        total += targ.netweightkg ? targ.netweightkg : 0
        available += targ.netweightkg ? targ.netweightkg : 0

        if (warehouse in pDct) {
          if (key in pDct[warehouse]) {
            let pendingItem = pDct[warehouse][key]
            if (pendingItem.netweightkg) {
              total += pendingItem.netweightkg ? pendingItem.netweightkg : 0
              pending = pendingItem.netweightkg ? pendingItem.netweightkg : 0
            }
          }
        }
    
        if (warehouse in tDct) {
          if (key in tDct[warehouse]) {
            let pendingItem = tDct[warehouse][key]
            if (pendingItem.netweightkg) {
              transit = pendingItem.netweightkg ? pendingItem.netweightkg : 0
            }
          }
        }
        var xkey = key//.replace('warehouse:' + warehouse,'')
        if (xkey in categoryDict) {
          categoryDict[xkey]['total'] += total 
          categoryDict[xkey]['pending'] += pending 
          categoryDict[xkey]['available'] += available 
          categoryDict[xkey]['transit'] += transit
        } else {
          categoryDict[xkey] = {'total': total, 'pending': pending, 'available': available, 'transit': transit, 'titleKey': targ, 'warehouse': warehouse}
        }
      })
    })

    return categoryDict 
  }

  function getWarehouseByCategory(bDct, pDct, tDct, warehouse) {

    
    var categoryDict = {}
    let itemOuter = bDct[warehouse]
    if (!itemOuter) {
      return categoryDict
    }
      Object.keys(itemOuter).map((key, idxx) => {
        let targ = itemOuter[key]
        var total = 0
        var available = 0
        var pending = 0
        var transit = 0
        total += targ.netweightkg
        available += targ.netweightkg

        if (warehouse in pDct) {
          if (key in pDct[warehouse]) {
            let pendingItem = pDct[warehouse][key]
            if (pendingItem.netweightkg) {
              total += pendingItem.netweightkg
              pending = pendingItem.netweightkg
            }
          }
        }
    
        if (warehouse in tDct) {
          if (key in tDct[warehouse]) {
            let pendingItem = tDct[warehouse][key]
            if (pendingItem.netweightkg) {
              transit = pendingItem.netweightkg
            }
          }
        }

        if (key in categoryDict) {
          categoryDict[key]['total'] += total 
          categoryDict[key]['pending'] += pending 
          categoryDict[key]['available'] += available 
          categoryDict[key]['transit'] += transit
        } else {
          categoryDict[key] = {'total': total, 'pending': pending, 'available': available, 'transit': transit, 'titleKey': targ, 'warehouse': warehouse}
        }
      })
    

    return categoryDict 
  }


  function getBarleyStockTitle(item) {
    var variety = ''
    var purity = ''
    var moisture = ''
    if (item.variety == 'other') {
      variety = 'All other varieties'
    } else {
      variety = item.variety 
    }
    if (item.uncategorized) {
      return 'Uncategorized'
    } else if (item.moisture == 'above') {
      return variety + ' // Variety all purity // Moisture > 15%'
    } else if (item.variety == 'other') {
      return variety + ' // Variety all purity // Moisture <= 15%'
    } else {
      if (item.purity == 'above') {
        purity = 'Variety purity >= 90%'
      } else if (item.purity == 'below') {
        purity = 'Variety purity < 90%'
      } 
      return variety + ' // ' + purity + ' // Moisture <=15%'
    }
  }

  function getTrainStatus(item, key) {
    var isTrain = false 
    var trainName = null 
    var trainSku = null 
    var splitted = key.split('-')
    splitted.map((item, idx) => {
      let set = item.split(':')
      if (set[0] && set[1] && set[0] == 'train') {
        isTrain = true 
        trainName = set[1]
      }
      if (set[0] && set[1] && set[0] == 'sku') {
        trainSku = set[1]
      }
    })
    if (isTrain) {
      return {'trainName': trainName + ' ('+ trainSku + ')', 'trainBase': trainName}
    } 
    return false 
    
  }

  function renderContents(bDct, pDct, tDct, customObjects, source) {
    var ct = {}
    if (!source) {
      return null
    }
    if (source.name == 'All Facilities') {
      ct = getAllByCategory(bDct, pDct, tDct, customObjects)
    } else {
      ct = getWarehouseByCategory(bDct, pDct, tDct, source.name)
    }
    return Object.keys(ct).map((key, idx) => {
      let item = ct[key]
      return <Grid
      item
      lg={3}
      sm={6}
      xl={3}
      xs={12}
    > <DoughnutBarley stockItem={item} nameProp={item.titleKey.sku} isTrain={getTrainStatus(item, key)} sourceProp={source}/> </Grid>
    })
  }

  function getLastOnlineData(onlineStatusDict, customObjectsDict, sourceVal) {
    var text = ''
    var colorVal = 'black'
    if ('warehouse' in customObjectsDict || !sourceVal || !onlineStatusDict) {
      let targ = sourceVal.name
      if (targ == 'None') {
        text = ''
      } else if (targ == 'All Facilities') {
      
        let allWh = Object.keys(customObjectsDict['warehouse']).map((key, idx) => {
          return customObjectsDict['warehouse']['name']
        })

        var didNotFindAll = false 
        var allOnline = true
        var lastOnline = Date.now() 

        allWh.map((item, idx) => {
          if (item in onlineStatusDict) {
            let inside = onlineStatusDict[item]
            if (inside.status != 'online') {
              allOnline = false 
              if (inside.lastOnline) {
                lastOnline = Math.min(lastOnline, inside.lastOnline)
              } else {
                didNotFindAll = true
              }
             
            }

          } else {
            didNotFindAll = true 
          }
        })

        if (didNotFindAll) {
          text = 'Last Synced All Facilities: Unknown'
        } else if (allOnline) {
          text = 'All Facilities Currently Online'
          colorVal = 'rgb(0,246,0)'
        } else {
          text = 'All Facilities Were Last Online: ' +  moment(lastOnline).format('DD/MM/YYYY HH:mm:ss')
          colorVal = 'rgb(242,201,0)'
        }

      } else {
        if (targ in onlineStatusDict) {
          let dat = onlineStatusDict[targ]
          if (dat.status == 'online') {
            text = targ + ' Online Now'
            colorVal = 'rgb(0,246,0)'
          } else if (dat.lastOnline) {
            text = targ + ' Last Synced: ' +  moment(dat.lastOnline).format('DD/MM/YYYY HH:mm:ss')
            colorVal = 'rgb(242,201,0)'
          } else {
            text = 'Last Sycned Data From ' + targ + ': Unknown'
          }
        } else {
          text = 'Last Sycned Data From ' + targ + ': Unknown'
        }
         
      }

    } else {
      text = 'Last Synced Data: Unknown'
    }

    return <Typography variant='h3' style={{marginLeft: 20, marginTop: 5, color: colorVal}}>
      {text}
    </Typography>
  }

    return (
      <MyContext.Consumer>{context => (

        <div>
          {context.targetWarehouse == -1 || true ?
           <Page
           className={classes.root}
           title="Dashboard"
         >
                           <AddStockDialog
        open={addStockDialog}
        onClose={() => setAddStockDialog(false)}
        alertTitle={'Add Stock'}
        alertCancelText={'Cancel'}
        alertConfirmText={'Confirm'}
        onConfirm={(x) => alert('x')}
        />
   
           <div style={{marginBottom: 10, marginRight: 20, flexDirection: 'row', display: 'flex'}}>
           <Typography variant='h1' style={{marginLeft: 20}}>
             {'Summary'}
           </Typography>
                 <Autocomplete
           id="request"
           options={getAutoOptions(context, 'warehouse')}
           getOptionLabel={(option) => option.name}
           value={source}
           onChange={(e, value) => {
             if (value != null) {
             
               setSource(value)
             }
           }}
           renderInput={(params) => <TextField {...params}
           style={{'width': 250, marginLeft: 10, marginTop: -5}}
           label="Facility"
           margin="normal"
           id="request"
           name="request"
           variant="outlined"
           value={source}
           defaultValue={source} />}
         />
                 <Button
          color="primary"
          variant="contained"
          style={{marginLeft: 5, marginTop: 5, height: 40}}
          onClick={() => setAddStockDialog(true)}
        >
         {'Add Stock'}
        </Button>


             {getLastOnlineData(context.onlineStatusDict, context.barleyInCustomObjects, source)}
          

           </div>
           {false && context.userInfo && context.userInfo.firstName ? <Typography variant='h2' style={{marginTop: 1, marginLeft: 6}}>{'Welcome, ' + context.userInfo.firstName + '!'}</Typography> : null}
           <Container maxWidth={false}>
               <Grid
               container
               spacing={3}
             >
   
          
   
           {renderContents(context.barleyStocksDict, context.barleyStocksPendingDict, context.barleyStocksTransitDict, context.barleyInCustomObjects,  source)}
   
             </Grid>
   
           </Container>
         </Page>
        
        : 
        
        <Page
        className={classes.root}
        title="Dashboard"
      >

        <div style={{marginBottom: 10, marginRight: 20, flexDirection: 'row', display: 'flex'}}>
        <Typography variant='h1' style={{marginLeft: 20}}>
          {context.targetWarehouse ? 'Facility Summary: ' + context.targetWarehouse.name : 'Loading...'}
        </Typography>
        </div>
        {false && context.userInfo && context.userInfo.firstName ? <Typography variant='h2' style={{marginTop: 1, marginLeft: 6}}>{'Welcome, ' + context.userInfo.firstName + '!'}</Typography> : null}
        <Container maxWidth={false}>
            <Grid
            container
            spacing={3}
          >

       

        {renderContents(context.barleyStocksDict, context.barleyStocksPendingDict, context.barleyStocksTransitDict, context.barleyInCustomObjects, context.targetWarehouse)}

          </Grid>

        </Container>
      </Page>

        }

        </div>



 
  
  
      )}</MyContext.Consumer>
      
    );

  
};

/**
 * 
 *  {Object.keys(context.barleyStocksDict).map((key, idx) => {
          let item = context.barleyStocksDict[key]
          return             <Grid
          item
          lg={4}
          md={6}
          xl={3}
          xs={12}
        >
         <BarleyStocks contents={item} warehouse={key} pDct={context.barleyStockspDct} tDct={context.barleyStockstDct}/></Grid>
        })}
 * 
 */
//<JobCardHolder/> was remove for shipper, it held all those job cards
export default Dashboard;
