import React, {useState} from 'react';
import { Link as RouterLink, useNavigate, useLocation} from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';

import LocationOnIcon from '@material-ui/icons/LocationOn';
import {
  Box,
  Button,
  Checkbox,
  Container,
  FormHelperText,
  Link,
  TextField,
  Typography,
  makeStyles,
  Grid,
  Select,
  MenuItem,
  CircularProgress,
  Stepper,
  StepLabel,
  Step,
} from '@material-ui/core';
import Page from 'src/components/Page';
import parse from 'autosuggest-highlight/parse';
import throttle from 'lodash/throttle';
import Firebase from 'src/Firebase'
import MuiPhoneNumber from 'material-ui-phone-number'
import { parseWithOptions } from 'date-fns/fp';
import MyContext from 'src/MyContext';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { arrayOf } from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import AddSupplierDialog from './AddSupplierDialog';
import BarleyInSummaryDialog from './BarleyInSummaryDialog';
import { isNull } from 'lodash';

const filter = createFilterOptions();
const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
const outOptions = [
  {'name': 'Traveler // Variety purity >=90% // Moisture =<15%', 'customId': 0, 'query': {'variety': 'Traveler', 'moisture': 'below', 'purity': 'above', 'uncategorized': false}},
  {'name': 'Traveler //  Variety purity <90% // Moisture =<15%', 'customId': 1, 'query': {'variety': 'Traveler', 'moisture': 'below', 'purity': 'below', 'uncategorized': false}},
  {'name': 'Traveler // Variety all purity // Moisture >15%', 'customId': 2, 'query': {'variety': 'Traveler', 'moisture': 'above', 'uncategorized': false}},
  {'name': 'Fatima // Variety purity >=90% // Moisture =<15%', 'customId': 3, 'query': {'variety': 'Fatima', 'moisture': 'below', 'purity': 'above', 'uncategorized': false}},
  {'name': 'Fatima // Variety purity <90% // Moisture =<15%', 'customId': 4, 'query': {'variety': 'Fatima', 'moisture': 'below', 'purity': 'below', 'uncategorized': false}},
  {'name': 'Fatima //  Variety all purity // Moisture >15%', 'customId': 5, 'query': {'variety': 'Fatima', 'moisture': 'above', 'uncategorized': false}},
  {'name': 'Planet // Variety purity >=90% // Moisture =<15%', 'customId': 6, 'query': {'variety': 'Planet', 'moisture': 'below', 'purity': 'above', 'uncategorized': false}},
  {'name': 'Planet // Variety purity <90% // Moisture =<15%', 'customId': 7, 'query':  {'variety': 'Planet', 'moisture': 'below', 'purity': 'below', 'uncategorized': false}},
  {'name': 'Planet //  Variety all purity // Moisture >15%', 'customId': 8, 'query': {'variety': 'Planet', 'moisture': 'above', 'uncategorized': false}},
  {'name': 'All other varieties // Variety all purity // Moisture =<15%', 'customId': 9, 'query': {'variety': 'other', 'moisture': 'below', 'uncategorized': false}},
  {'name': 'All other varieties // Variety all purity // Moisture >15%', 'customId': 10, 'query': {'variety': 'other', 'moisture': 'above', 'uncategorized': false}},
  {'name': 'Uncategorized', 'customId': 11, 'query': {'uncategorized': true}}]


  const steps = ['Header Details', 'Reception Details', 'Agronomy Details'];

const fieldsSort = {'supplier': 1, 'financesn': 2, 'suppliergdn': 3, 
'cluster': 4, 'zone': 5, 'woreda': 6, 'kebele': 7, 'weighbridge': 8,
'variety': 1, 'purity': 2, 'moisture': 3,
'25mm': 4, '2225mm': 5, '22mm': 6, 'totaladmixture': 7, 'wildoats': 8, 'diseased': 9,
'green': 10, 'infested': 11, 'sprouted': 12, 'green': 13, 'grade': 14, 'description': 15, 'stacknumber': 16}

const stepFields = {
  0: ['supplier', 'financesn', 'woreda', 'cluster', 'kebele',  'weighbridge', 'cluster', 'zone', 'suppliergdn'],
  1: ['vehicleweight', 'vehicletare', 'numberofbags', 'platenumber','trucktype', 'deliveredby', 'inspectedby', 'warehouse', 'gateticketnumber', 'entryticketnumber'],
  2: ['description','stacknumber', '2225mm', '22mm', '25mm', 'diseased', 'infested', 'sprouted', 'green', 'moisture', 'purity', 'totaladmixture', 'wildoats', 'variety', 'grade']
}

const autocompleteService = { current: null };
const geocoder = {current: null};
const specOptions = {'variety': 1, 'moisture': 1, 'purity': 1, 'vehicleweight': 1, 'vehicletare': 1}


const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  }
}));


const NewBarleyInView = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const {state} = useLocation();
  const [loading, setLoading] = React.useState(false);
  const [autoValues, setAutoValues] = React.useState(state.saveVal ? state.saveVal : {});
  const [category, setCategory] = React.useState(null);
  const [activeStep, setActiveStep] = React.useState(0);
  const [addSupplierDialog, setAddSupplierDialog] = React.useState(false);
  const [barleySummaryDialog, setBarleySummaryDialog] = React.useState(false);

  const handleNext = () => {


    setActiveStep((prevActiveStep) => prevActiveStep + 1);

  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  function nextButtonEnabled(step, vals, context) {
    let stpf = stepFields[step]
    var ret = true 
    stpf.map((item, idx) => {
      if (item == 'warehouse' && context.targetWarehouse != -1 && context.targetWarehouse && context.targetWarehouse.name) {
        return 
      }

      if (item != 'suppliergdn' && vals[item] == null) {
        
        if (item == 'financesn' && 'supplier' in vals && vals['supplier']['financesn'] == null){
          return 
        }
        
        console.log('disabled for')
        console.log(item)
        console.log(vals[item])

        ret = false 
      }
    })

    if (step == 1 && (vals.netweightkg == null || vals.netweightkg <= 0)) {
      ret = false 
    }


    return ret 
  }

  function compareDictionaries( d1, d2 ) {
    // quick check for the same object
    if( d1 == d2 )
        return true;

    // check for null
    if( d1 == null || d2 == null )
        return false;

    // go through the keys in d1 and check if they're in d2 - also keep a count
    var count = 0;
    for( var key in d1 )
    {
        // check if the key exists
        if( !( key in d2 ) )
            return false;

        // check that the values are the same
        if( d1[key] != d2[key] )
            return false;

        count++;
    }

    // now just make sure d2 has the same number of keys
    var count2 = 0;
    for( key in d2 )
        count2++;

    // return if they're the same size
    return ( count == count2 );
}


  function determineStockMembership(val) {

    /**
     *  1. Traveler // Variety purity >=90% // Moisture =<15%
        2. Traveler //  Variety purity <90% // Moisture =<15%
        3. Traveler // Variety all purity // Moisture >15%
        4. Fatima // Variety purity >=90% // Moisture =<15%
        5. Fatima // Variety purity <90% // Moisture =<15%
        6. Fatima //  Variety all purity // Moisture >15%
        7. Planet // Variety purity >=90% // Moisture =<15%
        8. Planet // Variety purity <90% // Moisture =<15%
        9. Planet //  Variety all purity // Moisture >15%
        10. All other varieties // Variety all purity // Moisture =<15%
        11. All other varieties // Variety all purity // Moisture >15%
     */
    var warehouse = 'None'
    if (val.warehouse && val.warehouse.name) {
      warehouse = val.warehouse.name
    }

    var uncategorized = false 
    
    if (!val.moisture || !val.variety) {
      // if moisture or variety is not defined, we are done, we just need uncategorized to be true
      uncategorized = true 
      return {'uncategorized': true, 'warehouse': warehouse}
    } 

    var variety = val.variety
      if (val.variety == 'Traveler' || val.variety == 'Fatima' || val.variety == 'Planet') {
        variety = val.variety 
      } else {
        variety = 'other'
      }

    if (val.moisture > 15) {
      // if moisture is above 15, the only other characteristic we need is variety
      // this accounts for the following options, where purity does not matter:
      /*
      3. Traveler // Variety all purity // Moisture >15%
      6. Fatima //  Variety all purity // Moisture >15%
      9. Planet //  Variety all purity // Moisture >15%
      11. All other varieties // Variety all purity // Moisture >15%
      */

      return {'uncategorized': false, 'warehouse': warehouse, 'variety': variety, 'moisture': 'above'}
    } else if (!val.purity) {
      // if purity is not defined and moisture is <= 15
      // this makes it case 10 IF variety is already at 'other', else it becomes uncategorized
      if (variety == 'other') {
        // 10. All other varieties // Variety all purity // Moisture =<15%
        return {'uncategorized': false, 'warehouse': warehouse, 'variety': variety, 'moisture': 'below'}
      } else {
        return {'uncategorized': true, 'warehouse': warehouse}
      }

    } else {
      // purity, variety, and moisture are all defined
      // moisture is below 

      if (variety == 'other') {
        // 10. All other varieties // Variety all purity // Moisture =<15%
        return {'uncategorized': false, 'warehouse': warehouse, 'variety': variety, 'moisture': 'below'}
      }

      var purity = 'above'
      if (val.purity < 90) {
        purity = 'below'
      }
      /*
      1. Traveler // Variety purity >=90% // Moisture =<15%
      2. Traveler //  Variety purity <90% // Moisture =<15%
      4. Fatima // Variety purity >=90% // Moisture =<15%
      5. Fatima // Variety purity <90% // Moisture =<15%
      7. Planet // Variety purity >=90% // Moisture =<15%
      8. Planet // Variety purity <90% // Moisture =<15%
      */
      return {'uncategorized': false, 'warehouse': warehouse, 'variety': variety, 'moisture': 'below', 'purity': purity }
    }

  }

  function getDictId(dct) {
    var keys = Object.keys(dct)
    keys.sort()
    var s = ''
    for (var i = 0; i < keys.length; i++) {
      let key = keys[i]
      if (i > 0) {
        s += '-'
      }
      s += key 
      s += ':'
      s += dct[key]
    }

    return s 
  }


  function getType(t) {
    if (t == 'date') {
      return 'date'
    } else if (t == 'number') {
      return 'number' 
    } else if (t == 'list' || t == 'custom') {
      return t
    } else {
      return null
    }
  }


  function updateAutoValues(k, v) {
    var o = autoValues
    o[k] = v 
    setAutoValues(o)
  }

  function getAutoOptions(context, k) {
    if (k in context.barleyInCustomObjects) {
      return Object.keys(context.barleyInCustomObjects[k]).map((key, id) => {
        return context.barleyInCustomObjects[k][key]
      })
    }
    return []
  }

  function compare2(a,b) {

    let as = fieldsSort[a] ? fieldsSort[a] : 100
    let bs = fieldsSort[b] ? fieldsSort[b] : 100
    //console.log('here we have ' + as + ' for ' + a)
    if (as > bs) {
      return 1
    } else if (bs > as) {
      return -1
    } else {
      return 0
    }
  }

  function specialUpdate(key, val, setFieldValue, values) {

    setFieldValue(key, val)
    if (key == 'vehicleweight') {
      if (values.vehicletare) {
        setFieldValue('netweightkg', val - values.vehicletare)
      }

    } else if (key == 'vehicletare') {
      if (values.vehicleweight) {
        setFieldValue('netweightkg', values.vehicleweight - val)
      }
    } else {

      var newSet = values 
      newSet[key] = val 
      var stockMembership = determineStockMembership(newSet)
      if ('warehouse' in stockMembership) {
        delete stockMembership['warehouse']
      }
      var ret = null 
      outOptions.map((item, idx) => {
        if (compareDictionaries(item.query, stockMembership)) {
          ret = item 
        }
      })
      console.log(stockMembership)
      console.log(ret)
      if (ret != null) {
        setCategory(ret)
      }
    }

    
  } 

  function renderOnlySku(fields, touched, errors, handleBlur, handleChange, values, setFieldValue, context) {
    let fieldIdx = 0
    if (!fields || !fields[fieldIdx]) {
      return null
    }
    return <Autocomplete
    value={autoValues[fields[fieldIdx].fieldKey]}
    fullWidth
    onChange={(event, newValue) => {
      if (typeof newValue === 'string') {
        updateAutoValues(fields[fieldIdx].fieldKey, {name: newValue})
        setFieldValue(fields[fieldIdx].fieldKey, newValue/*{'customId': newValue.customId, 'name': newValue.name}*/)
        if (fields[fieldIdx].fieldKey == 'supplier') {
          Firebase.sharedSupplierFields.map((sharedKey, sharedIdx) => {
            if (sharedKey in newValue) {
              setFieldValue(sharedKey, newValue[sharedKey])
            }
          })
          if ('name' in newValue) {
            setFieldValue('clientname', newValue['name'])
          }
        }
      } else if (newValue && newValue.inputValue) {
        // Create a new value from the user input
        updateAutoValues(fields[fieldIdx].fieldKey, {name: newValue.inputValue})
        setFieldValue(fields[fieldIdx].fieldKey, {'customId':-1, 'name': newValue.inputValue})
      } else {
        updateAutoValues(fields[fieldIdx].fieldKey, newValue)
        if (newValue == null) {
          setFieldValue(fields[fieldIdx].fieldKey, {'customId': '0', 'name': 'None'})
        } else {
          setFieldValue(fields[fieldIdx].fieldKey, newValue/*{'customId': newValue.customId, 'name': newValue.name}*/)
          if (fields[fieldIdx].fieldKey == 'supplier') {
            Firebase.sharedSupplierFields.map((sharedKey, sharedIdx) => {
              if (sharedKey in newValue) {
                setFieldValue(sharedKey, newValue[sharedKey])
              }
            })
            if ('name' in newValue) {
              setFieldValue('clientname', newValue['name'])
            }
          }
        }
      }
      //console.log(event.target.value)
      //console.log(newValue)
    }}
    filterOptions={(options, params) => {
      const filtered = filter(options, params);

      // Suggest the creation of a new value
      if (params.inputValue !== '') {
        filtered.push({
          inputValue: params.inputValue,
          name: `Add "${params.inputValue}"`,
        });
      }

      return filtered;
    }}
    selectOnFocus
    clearOnBlur
    handleHomeEndKeys
    id="free-solo-with-text-demo"
    options={getAutoOptions(context, fields[fieldIdx].fieldKey)}
    getOptionLabel={(option) => {
      // Value selected with enter, right from the input
      if (typeof option === 'string') {
        return option;
      }
      // Add "xxx" option created dynamically
      if (option.inputValue) {
        return option.inputValue;
      }
      // Regular option
      return option.name ? option.name : '';
    }}
    renderOption={(option) => option.name ? option.name : ''}
    freeSolo
    renderInput={(params) => (
      <TextField {...params}  error={Boolean(touched[fields[fieldIdx].fieldKey] && errors[fields[fieldIdx].fieldKey])}
      fullWidth
      key={fields[fieldIdx].fieldKey}
      id={fields[fieldIdx].fieldKey}
      helperText={touched[fields[fieldIdx].fieldKey] && errors[fields[fieldIdx].fieldKey]}
      label={fields[fieldIdx].fieldName}
      margin="normal"
      style={{margin: 10}}
      name={fields[fieldIdx].fieldKey}
      onBlur={handleBlur}
      onChange={handleChange}
      value={values[fields[fieldIdx].fieldKey]}
      variant="outlined"/>
    )}
  />
  }

  function renderOnlySupplier(fields, touched, errors, handleBlur, handleChange, values, setFieldValue, context) {
    let fieldIdx = 0
    if (!fields || !fields[fieldIdx]) {
      return null
    }
    return <div style={{flexDirection: 'row', display: 'flex'}}><Autocomplete
    value={autoValues[fields[fieldIdx].fieldKey]}
    style={{width: 200}}
    onChange={(event, newValue) => {
      if (typeof newValue === 'string') {
        updateAutoValues(fields[fieldIdx].fieldKey, {name: newValue})
        setFieldValue(fields[fieldIdx].fieldKey, newValue/*{'customId': newValue.customId, 'name': newValue.name}*/)
        if (fields[fieldIdx].fieldKey == 'supplier') {
          Firebase.sharedSupplierFields.map((sharedKey, sharedIdx) => {
            if (sharedKey in newValue) {
              setFieldValue(sharedKey, newValue[sharedKey])
            }
          })
          if ('name' in newValue) {
            setFieldValue('clientname', newValue['name'])
          }
        }
      } else if (newValue && newValue.inputValue) {
        // Create a new value from the user input
        updateAutoValues(fields[fieldIdx].fieldKey, {name: newValue.inputValue})
        setFieldValue(fields[fieldIdx].fieldKey, {'customId':-1, 'name': newValue.inputValue})
      } else {
        updateAutoValues(fields[fieldIdx].fieldKey, newValue)
        if (newValue == null) {
          setFieldValue(fields[fieldIdx].fieldKey, {'customId': '0', 'name': 'None'})
        } else {
          setFieldValue(fields[fieldIdx].fieldKey, newValue/*{'customId': newValue.customId, 'name': newValue.name}*/)
          if (fields[fieldIdx].fieldKey == 'supplier') {
            Firebase.sharedSupplierFields.map((sharedKey, sharedIdx) => {
              if (sharedKey in newValue) {
                setFieldValue(sharedKey, newValue[sharedKey])
              }
            })
            if ('name' in newValue) {
              setFieldValue('clientname', newValue['name'])
            }
          }
        }
      }
      //console.log(event.target.value)
      //console.log(newValue)
    }}
    filterOptions={(options, params) => {
      const filtered = filter(options, params);

      // Suggest the creation of a new value
      if (params.inputValue !== '') {
        filtered.push({
          inputValue: params.inputValue,
          name: `Add "${params.inputValue}"`,
        });
      }

      return filtered;
    }}
    selectOnFocus
    clearOnBlur
    handleHomeEndKeys
    id="free-solo-with-text-demo"
    options={getAutoOptions(context, fields[fieldIdx].fieldKey)}
    getOptionLabel={(option) => {
      // Value selected with enter, right from the input
      if (typeof option === 'string') {
        return option;
      }
      // Add "xxx" option created dynamically
      if (option.inputValue) {
        return option.inputValue;
      }
      // Regular option
      return option.name ? option.name : '';
    }}
    renderOption={(option) => option.name ? option.name : ''}
    freeSolo
    renderInput={(params) => (
      <TextField {...params}  error={Boolean(touched[fields[fieldIdx].fieldKey] && errors[fields[fieldIdx].fieldKey])}
      fullWidth
      key={fields[fieldIdx].fieldKey}
      id={fields[fieldIdx].fieldKey}
      helperText={touched[fields[fieldIdx].fieldKey] && errors[fields[fieldIdx].fieldKey]}
      label={fields[fieldIdx].fieldName}
      margin="normal"
      style={{margin: 5, width: '100%'}}
      name={fields[fieldIdx].fieldKey}
      onBlur={handleBlur}
      onChange={handleChange}
      value={values[fields[fieldIdx].fieldKey]}
      variant="outlined"/>
    )}
  />
                            <Button
                        color="primary"
                        style={{margin: 5, marginLeft: 20, width: 185, alignSelf: 'center'}}
                        disabled={loading}
                        id="sendButtonNext"
                        variant="contained"
                        onClick={() => setAddSupplierDialog(true)}
                      >
                        (Add New Supplier)
                      </Button>
                      </div>

  }

  function matchTarget(context) {
    var ret = {'name': 'Loading...'}
    let k = 'warehouse'
    if (k in context.barleyInCustomObjects) {
      Object.keys(context.barleyInCustomObjects[k]).map((key, id) => {
        if (context.barleyInCustomObjects[k][key].name == context.targetWarehouse.name) {
          ret = context.barleyInCustomObjects[k][key]
        }
      })
    }
    return ret
  }


  function renderContent(fieldsIn, touched, errors, handleBlur, handleChange, values, setFieldValue, context) {
    var fields = fieldsIn 
    fieldsIn.sort((a, b) => compare2(a.fieldKey,b.fieldKey))
    
    var columns = Math.floor(fields.length / 3)
    if (columns * 3 < fields.length) {
      columns += 1
    }
    var colrow = [...Array(columns).keys()]
    var rows = [0,1,2]
    return <div>
      {colrow.map((item, idx) => {
        return <div style={{display: 'flex', flexDirection: 'row'}}>
        {rows.map((inner, innerI) => {
          let fieldIdx = (3*item) + inner 

          if (fieldIdx < fields.length) {
            let tp = getType(fields[fieldIdx].fieldType)
            if (fields[fieldIdx].fieldKey == 'warehouse' && context.targetWarehouse != -1 && context.targetWarehouse && context.targetWarehouse.name) {
              return <Autocomplete
              disabled={true}
              value={matchTarget(context)}
              fullWidth
              onChange={(event, newValue) => {
                if (typeof newValue === 'string') {
                  updateAutoValues(fields[fieldIdx].fieldKey, {name: newValue})
                  setFieldValue(fields[fieldIdx].fieldKey, newValue/*{'customId': newValue.customId, 'name': newValue.name}*/)
                  if (fields[fieldIdx].fieldKey == 'supplier') {
                    Firebase.sharedSupplierFields.map((sharedKey, sharedIdx) => {
                      if (sharedKey in newValue) {
                        setFieldValue(sharedKey, newValue[sharedKey])
                      }
                    })
                    if ('name' in newValue) {
                      setFieldValue('clientname', newValue['name'])
                    }
                  }
                } else if (newValue && newValue.inputValue) {
                  // Create a new value from the user input
                  updateAutoValues(fields[fieldIdx].fieldKey, {name: newValue.inputValue})
                  setFieldValue(fields[fieldIdx].fieldKey, {'customId':-1, 'name': newValue.inputValue})
                } else {
                  updateAutoValues(fields[fieldIdx].fieldKey, newValue)
                  if (newValue == null) {
                    setFieldValue(fields[fieldIdx].fieldKey, {'customId': '0', 'name': 'None'})
                  } else {
                    setFieldValue(fields[fieldIdx].fieldKey, newValue/*{'customId': newValue.customId, 'name': newValue.name}*/)
                    if (fields[fieldIdx].fieldKey == 'supplier') {
                      Firebase.sharedSupplierFields.map((sharedKey, sharedIdx) => {
                        if (sharedKey in newValue) {
                          setFieldValue(sharedKey, newValue[sharedKey])
                        }
                      })
                      if ('name' in newValue) {
                        setFieldValue('clientname', newValue['name'])
                      }
                    }
                  }
                }
                //console.log(event.target.value)
                //console.log(newValue)
              }}
              filterOptions={(options, params) => {
                const filtered = filter(options, params);
        
                // Suggest the creation of a new value
                if (params.inputValue !== '') {
                  filtered.push({
                    inputValue: params.inputValue,
                    name: `Add "${params.inputValue}"`,
                  });
                }
        
                return filtered;
              }}
              selectOnFocus
              clearOnBlur
              handleHomeEndKeys
              id="free-solo-with-text-demo"
              options={getAutoOptions(context, fields[fieldIdx].fieldKey)}
              getOptionLabel={(option) => {
                // Value selected with enter, right from the input
                if (typeof option === 'string') {
                  return option;
                }
                // Add "xxx" option created dynamically
                if (option.inputValue) {
                  return option.inputValue;
                }
                // Regular option
                return option.name ? option.name : '';
              }}
              renderOption={(option) => option.name ? option.name : ''}
              freeSolo
              renderInput={(params) => (
                <TextField {...params}  error={Boolean(touched[fields[fieldIdx].fieldKey] && errors[fields[fieldIdx].fieldKey])}
                fullWidth
                disabled={true}
                key={fields[fieldIdx].fieldKey}
                id={fields[fieldIdx].fieldKey}
                helperText={touched[fields[fieldIdx].fieldKey] && errors[fields[fieldIdx].fieldKey]}
                label={fields[fieldIdx].fieldName}
                margin="normal"
                style={{margin: 10}}
                name={fields[fieldIdx].fieldKey}
                onBlur={handleBlur}
                onChange={handleChange}
                value={matchTarget(context)}
                variant="outlined"/>
              )}
            />
            }


            if (tp == 'custom') {

             return <Autocomplete
              value={autoValues[fields[fieldIdx].fieldKey]}
              fullWidth
              onChange={(event, newValue) => {
                if (typeof newValue === 'string') {
                  updateAutoValues(fields[fieldIdx].fieldKey, {name: newValue})
                  setFieldValue(fields[fieldIdx].fieldKey, newValue/*{'customId': newValue.customId, 'name': newValue.name}*/)
                  if (fields[fieldIdx].fieldKey == 'supplier') {
                    Firebase.sharedSupplierFields.map((sharedKey, sharedIdx) => {
                      if (sharedKey in newValue) {
                        setFieldValue(sharedKey, newValue[sharedKey])
                      }
                    })
                    if ('name' in newValue) {
                      setFieldValue('clientname', newValue['name'])
                    }
                  }
                } else if (newValue && newValue.inputValue) {
                  // Create a new value from the user input
                  updateAutoValues(fields[fieldIdx].fieldKey, {name: newValue.inputValue})
                  setFieldValue(fields[fieldIdx].fieldKey, {'customId':-1, 'name': newValue.inputValue})
                } else {
                  updateAutoValues(fields[fieldIdx].fieldKey, newValue)
                  if (newValue == null) {
                    setFieldValue(fields[fieldIdx].fieldKey, {'customId': '0', 'name': 'None'})
                  } else {
                    setFieldValue(fields[fieldIdx].fieldKey, newValue/*{'customId': newValue.customId, 'name': newValue.name}*/)
                    if (fields[fieldIdx].fieldKey == 'supplier') {
                      Firebase.sharedSupplierFields.map((sharedKey, sharedIdx) => {
                        if (sharedKey in newValue) {
                          setFieldValue(sharedKey, newValue[sharedKey])
                        }
                      })
                      if ('name' in newValue) {
                        setFieldValue('clientname', newValue['name'])
                      }
                    }
                  }
                }
                //console.log(event.target.value)
                //console.log(newValue)
              }}
              filterOptions={(options, params) => {
                const filtered = filter(options, params);
        
                // Suggest the creation of a new value
                if (params.inputValue !== '') {
                  filtered.push({
                    inputValue: params.inputValue,
                    name: `Add "${params.inputValue}"`,
                  });
                }
        
                return filtered;
              }}
              selectOnFocus
              clearOnBlur
              handleHomeEndKeys
              id="free-solo-with-text-demo"
              options={getAutoOptions(context, fields[fieldIdx].fieldKey)}
              getOptionLabel={(option) => {
                // Value selected with enter, right from the input
                if (typeof option === 'string') {
                  return option;
                }
                // Add "xxx" option created dynamically
                if (option.inputValue) {
                  return option.inputValue;
                }
                // Regular option
                return option.name ? option.name : '';
              }}
              renderOption={(option) => option.name ? option.name : ''}
              freeSolo
              renderInput={(params) => (
                <TextField {...params}  error={Boolean(touched[fields[fieldIdx].fieldKey] && errors[fields[fieldIdx].fieldKey])}
                fullWidth
                key={fields[fieldIdx].fieldKey}
                id={fields[fieldIdx].fieldKey}
                helperText={touched[fields[fieldIdx].fieldKey] && errors[fields[fieldIdx].fieldKey]}
                label={fields[fieldIdx].fieldName}
                margin="normal"
                style={{margin: 10}}
                name={fields[fieldIdx].fieldKey}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values[fields[fieldIdx].fieldKey]}
                variant="outlined"/>
              )}
            />



            } else if (tp == 'list') {
              let opts = fields[fieldIdx].listOptions

              return <TextField
              select 
              key={fields[fieldIdx].fieldKey}
              error={Boolean(touched[fields[fieldIdx].fieldKey] && errors[fields[fieldIdx].fieldKey])}
              fullWidth
              id={fields[fieldIdx].fieldKey}
              helperText={touched[fields[fieldIdx].fieldKey] && errors[fields[fieldIdx].fieldKey]}
              label={fields[fieldIdx].fieldName}
              margin="normal"
              style={{margin: 10}}
              name={fields[fieldIdx].fieldKey}
              onBlur={handleBlur}
              onChange={fields[fieldIdx].fieldKey in specOptions ? (e) => specialUpdate(fields[fieldIdx].fieldKey, e.target.value, setFieldValue, values) : handleChange}
              value={values[fields[fieldIdx].fieldKey]}
              InputLabelProps={{
                shrink: true,
                }}
              variant="outlined">
              {opts.map((item, idx) => {
                return (<MenuItem value={item}>{item}</MenuItem>)
              })}
            </TextField>
            } else {
              return <TextField
              error={Boolean(touched[fields[fieldIdx].fieldKey] && errors[fields[fieldIdx].fieldKey])}
              fullWidth
              key={fields[fieldIdx].fieldKey}
              style={{margin: 10}}
              id={fields[fieldIdx].fieldKey}
              helperText={touched[fields[fieldIdx].fieldKey] && errors[fields[fieldIdx].fieldKey]}
              label={fields[fieldIdx].fieldName}
              margin="normal"
              name={fields[fieldIdx].fieldKey}
              onBlur={handleBlur}
              onChange={fields[fieldIdx].fieldKey in specOptions ? (e) => specialUpdate(fields[fieldIdx].fieldKey, e.target.value, setFieldValue, values) : handleChange}
              value={values[fields[fieldIdx].fieldKey]}
              variant="outlined"
              type={getType(fields[fieldIdx].fieldType)}
              InputLabelProps={{
                shrink: true,
                }}
            />
            }

          }
          
          return null 

        })}
       </div>
      })}
    </div>
  }

    async function doSubmitDraft(v) {
      setLoading(true)
      let rf = Firebase.firestore.collection('projects').document(Firebase.userProject).collection('barleyIn').doc()
      var newVal = v 
      newVal['id'] = rf.id
      newVal['barleyInId'] = rf.id 
      newVal['creationDate'] = Date.now()
      await rf.set(newVal).then((a) => {
        return null 
      }).catch((e) => {
        return null 
      })
      setLoading(false)
      navigate(-1)
    }

    async function doSubmit(context, vxx) {
      //let v = null
      setLoading(true)
      var v = vxx 

      var receivedby = null
      if ('warehousesoftwareusers' in context.barleyInCustomObjects) {
        Object.keys(context.barleyInCustomObjects['warehousesoftwareusers']).map((key, id) => {
          if (key == Firebase.uid) {
            receivedby = context.barleyInCustomObjects['warehousesoftwareusers'][key].name
            v['receivedby'] = receivedby
          }
        })
      }

      if (context.targetWarehouse != -1 && context.targetWarehouse && context.targetWarehouse.name ) {
        v['warehouse'] = matchTarget(context)
      }

      if (Firebase.ONLINE) {
  

        let transaction = await Firebase.createBarleyIn({'project': Firebase.userProject, 'values': v, 'fields': state.fields})
        if (transaction && transaction.transaction != null) {
          var newVal = transaction.newVal 
          var oldNumericSave = transaction.oldNumericSave
          var newValId = transaction.newValId
  
          let s = async () => {
              var datPack = {}
              const imageRef = Firebase.storage.ref('test').child('pdf_for_gdn_' + oldNumericSave + '.pdf')
              let v = await imageRef.getDownloadURL();
              console.log(v)
              console.log('got it')
              datPack['pdflink'] = v 
              datPack['barleyInId'] = newValId
              let snone = () => console.log('x')
              Firebase.provideGdnLink(datPack, snone, snone)
              //window.open(v)
  
          } 
      
          let f = () => {
           
          }
          var datxx = {}
          datxx['val'] = newVal
          datxx['userId'] = 'test'
          Firebase.generateWaybill(datxx, s, f)
          //Firebase.generateGdn(datxx, s, f)
  
        }
  
        setLoading(false)
        Firebase.trackMixpanel('createBarleyInOnline', {'result': transaction})
        if (!transaction || transaction.transaction == null) {
          alert('Error creating report. Try again.')
        } else {
          navigate('/app/creationsummary', {state: {headerText: 'Intake: ' + transaction.newVal.grn, secondaryText: 'Successfully created order.' }, replace: true})
          //navigate(-1)
        }
        console.log(transaction)


      } else {


        console.log('running without func')
       
      var newVal = v 
      if (newVal['purity'] == '') {
        delete newVal['purity']
      }
      if (newVal['moisture'] == '') {
        delete newVal['moisture']
      }
      newVal['creationDate'] = Date.now()

      var newSet = determineStockMembership(newVal)
      let stockKey = getDictId(newSet)
      if ('netweightkg' in newVal) {
        newSet['netweightkg'] = newVal['netweightkg']
      } else {
        newSet['netweightkg'] = 0
      }


      let docRef1 = Firebase.firestore.collection('barleyConstants').doc('grnNumber');
      let docRef2 = newVal.id ? Firebase.firestore.collection('barleyIn').doc(newVal.id) : Firebase.firestore.collection('barleyIn').doc()
      let stockDocRef = Firebase.firestore.collection('barleyStocks').doc(stockKey)
      let newValId = docRef2.id
      // check if we have to create any new field objects
      var customCreations = {}
      state.fields.map((field, idx) => {
        if (field.fieldType == 'custom') {
          if (field.fieldKey in newVal && newVal[field.fieldKey]['customId'] == -1) {
            // must create this val 
            customCreations[field.fieldKey] =  newVal[field.fieldKey]['name']
          }
        }
      })
      if (Object.keys(customCreations).length > 0) {
        let docRef3 = Firebase.firestore.collection('barleyInCustomObjects')
      }

      let arrayOfMemberIds = Object.keys(customCreations)
      console.log(arrayOfMemberIds)
      console.log(newVal)
      var oldNumericSave = null
      var newQueryId = null 
      var newQueryResult = null

      var t = Firebase.firestore.batch()
      console.log('setting batch')
      oldNumericSave = uuidv4()
      arrayOfMemberIds.forEach(function(memberId) {
        let docRefNext = Firebase.firestore.collection('barleyInCustomObjects').doc('contents').collection(memberId).doc()
        t = t.set(
          docRefNext,
          { name: customCreations[memberId] }
        )
        console.log('setting id of ' + docRefNext.id)
        newVal[memberId] = {'customId': docRefNext.id, 'name': customCreations[memberId]}
      });
      newVal['grn'] = oldNumericSave
      t.set(docRef2 ,newVal);
      t.set(Firebase.firestore.collection('offlineBarleyStocks').doc(oldNumericSave), 
      {'stockKey': stockKey, 'newSet': newSet, 'newVal': newVal})
      console.log('prep commit batch')
      let transaction = t.commit().then(result => {
        
        console.log('Batch success!' + result);
        return true
      }).catch(err => {
        console.log('Batch failure:', err);
        return false
      });
      console.log(transaction)
      console.log('passed transcation point')

      if (transaction != null) {


        let s = async () => {
            var datPack = {}
            const imageRef = Firebase.storage.ref('test').child('pdf_for_gdn_' + oldNumericSave + '.pdf')
            let v = await imageRef.getDownloadURL();
            console.log(v)
            console.log('got it')
            datPack['pdflink'] = v 
            datPack['barleyInId'] = newValId
            let snone = () => console.log('x')
            Firebase.provideGdnLink(datPack, snone, snone)
            //window.open(v)

        } 
    
        let f = () => {
         
        }
        var datxx = {}
        datxx['val'] = newVal
        datxx['userId'] = 'test'
        
        Firebase.generateGdn(datxx, s, f)

      }

      setLoading(false)
      if (transaction == null) {
        alert('Error creating report. Try again.')
      } else {
        navigate(-1)
      }
      console.log(transaction)
      }

    }

    function adjustFields(totalVal, setFunc) {
      let val = totalVal.query
      console.log(val)
      if (val.uncategorized) {
        setFunc('purity', '')
        setFunc('moisture', '')
        setFunc('variety', null)
        console.log('set all nul')
      }
      if (val.variety && val.variety == 'other') {
        setFunc('variety', val.variety)
      } else if(val.variety) {
        console.log('setting func variety')
        console.log(val.variety)
        setFunc('variety', val.variety)
      }
      if (val.moisture && val.moisture == 'above') {
        setFunc('moisture', 16)
      } else if(val.moisture && val.moisture == 'below') {
        setFunc('moisture', 15)
      }
      if(val.purity && val.purity == 'above') {
        setFunc('purity', 90)
      } else if(val.purity && val.purity == 'below') {
        setFunc('purity', 89)
      }

    
/**
 *        
 */

    }

    function precisionRound(num) {

      return +num.toFixed(2)
    }


    function genSum(values) {
     let agr = ['2225mm', '22mm', '25mm', 'diseased', 'infested', 'sprouted', 'green', 'totaladmixture', 'wildoats'] 
     var notfilled = false 
     var sum = 0
     agr.map((key, idx) => {
        if (values[key]) {
          sum += values[key]
          sum = precisionRound(sum)
        } else {
          notfilled = true 
        }
      })
 
        if (sum != 100) {
          return 'Agronomy Total: ' + sum + '% (Warning! Should equal 100%!)'
        } else {
          return 'Agronomy Total:  ' + sum + '%'
        }

    
    }

    function  dictToList(d) {
      if (d) {
       return Object.keys(d).map((key, idx) => {
         return d[key]
       })
      } else {
        return []
      }
    }
  


  return (
    <MyContext.Consumer>{context => (
  <Page
    className={classes.root}
    height='100%'
    title="New Intake"
    id="ff2"
    backgroundColor={classes.root.backgroundColor}
  >

            <AddSupplierDialog
            context={context}
            fields={dictToList(context.barleyInCustomObjectFields['supplier'])}
        open={addSupplierDialog}
        onClose={() => setAddSupplierDialog(false)}
        alertTitle={'Add Supplier'}
        alertCancelText={'Cancel'}
        alertConfirmText={'Confirm'}
        onConfirm={(x) => setAddSupplierDialog(false)}
        />
    <Box
      display="flex"
      flexDirection="column"
      id="bb2"

      backgroundColor={classes.root.backgroundColor}

    >
      <Container id="cc2"  backgroundColor={classes.root.backgroundColor}>
        <Formik
        id='f2'
          initialValues={state.saveVal ? state.saveVal : {
            variety: null,
            moisture: null,
            purity: null,
          }}
          validationSchema={
            Yup.object().shape({

            })
          }
          onSubmit={(values) => {
            doSubmit(values)
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting2,
            setSubmitting, 
            touched,
            values,
            setFieldValue
          }) => (
            <form id='fu2' onSubmit={handleSubmit}>
                  <div id='fkx1' style={{marginTop: 5, alignItems: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
                  <BarleyInSummaryDialog
        open={barleySummaryDialog}
        value={values}
        fields={state.fields}
        onClose={() => setBarleySummaryDialog(false)}
        alertTitle={'Intake Summary'}
        alertCancelText={'Cancel'}
        alertConfirmText={'Confirm and Print'}
        onConfirm={(x) => doSubmit(context, values)}
        />
              <Box mb={3}>
                <Typography
                  color="textPrimary"
                  variant="h2"
                >
                  Create New Intake Report
                </Typography>
              </Box>

      <Stepper activeStep={activeStep} style={{width: '100%', marginBottom: 15}}>
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>

            </Step>
          );
        })}
      </Stepper>

         <div style={{alignSelf: 'center', width: '50%', alignItems: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>

              {activeStep == 2 ?              <div>{renderOnlySku(state.fields.filter((item, idx) => {
               return item.fieldKey == 'sku'
             }), touched, errors, handleBlur, handleChange, values, setFieldValue, context)}</div> : null}

              {activeStep == 0 ? 
              <div>
              {renderOnlySupplier(state.fields.filter((item, idx) => {
               return item.fieldKey == 'supplier'
             }), touched, errors, handleBlur, handleChange, values, setFieldValue, context)}

              </div>
              : null}
          
             {renderContent(state.fields.filter((item, idx) => {
               return stepFields[activeStep].includes(item.fieldKey) && item.fieldKey != 'grn' && item.fieldKey != 'supplier' && (true || (item.fieldKey in specOptions || item.fieldKey == 'warehouse' || item.fieldKey == 'netweightkg'))
             }), touched, errors, handleBlur, handleChange, values, setFieldValue, context)}
              
              {activeStep == 1 ? 
              <div>
                <Typography>
                  {'Net Weight: ' + (values.netweightkg ? values.netweightkg : 0) + 'kg'}
                </Typography>
                </div>

                : null}


{activeStep == 2 ? 
              <div>
                <Typography>
                  {genSum(values)}
                </Typography>
                </div>

                : null} 
              <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>


              <Button
                                     color="primary"
                                     disabled={activeStep == 0}
                                     size="large"
                                     id="sendButtonNext"
                                     variant="contained"
                                     onClick={handleBack}
                                     style={{margin: 5, width: 150}}
                                   >
                                     Back
                                   </Button>



             {activeStep < steps.length - 1 ? 
                                     <Button
                                     color="primary"
                                     disabled={loading || !nextButtonEnabled(activeStep, values, context)}
                                     size="large"
                                     id="sendButtonNext"
                                     variant="contained"
                                     onClick={handleNext}
                                     style={{margin: 5, width: 150}}
                                   >
                                     Next
                                   </Button>
            : 
            <Box my={2} style={{alignSelf: 'center', flexDirection: 'column', display: 'flex'}}>
                          <Button
              color="primary"
              style={{margin: 5, width: 150}}
              disabled={loading}
              id="sendButtonNext"
              variant="contained"
              onClick={() => doSubmitDraft(values)}
            >
             Save Draft
            </Button>
            <Button
              color="primary"
              style={{margin: 5, width: 150}}
              disabled={loading || !nextButtonEnabled(activeStep, values, context)}
              id="sendButtonNext"
              variant="contained"
              onClick={() => setBarleySummaryDialog(true)}
            >
              Confirm and Print
            </Button>
          </Box>
            }
            


          </Box>

</div>
     

</div>




              {loading ? <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: 50}}><CircularProgress/></div> : null}

              
            </form>
          )}
        </Formik>
      </Container>
    </Box>
  </Page>
        )}</MyContext.Consumer>
  )
};

export default NewBarleyInView;
