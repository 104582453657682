

import React, {useState, useRef} from 'react';
import { Link as RouterLink, useNavigate, useLocation} from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import Autocomplete from '@material-ui/lab/Autocomplete';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import {
  Box,
  Button,
  Checkbox,
  Container,
  FormHelperText,
  Link,
  TextField,
  Typography,
  makeStyles,
  Grid,
  Select,
  MenuItem,
  InputLabel,
  CircularProgress
} from '@material-ui/core';
import Page from 'src/components/Page';
import parse from 'autosuggest-highlight/parse';
import throttle from 'lodash/throttle';
import Firebase from 'src/Firebase';
import DataFetcher from 'src/DataFetcher';
import QuoteStatus from 'src/QuoteStatus';
import HoverMarker from 'src/HoverMarker';
import GoogleMapReact from 'google-map-react';
import JobMasterView from 'src/utils/JobMasterView';
import MyContext from 'src/MyContext'
var moment = require('moment');

const useStyles = makeStyles((theme) => ({
    root: {
      backgroundColor: theme.palette.background.dark,
      paddingBottom: theme.spacing(3),
      paddingTop: theme.spacing(3),
    }
  }));
  

const CreateLTCAdmin = () => {
    const classes = useStyles();
    const navigate = useNavigate();
    const {state} = useLocation();
    const [isAuthenticating, setIsAuthenticating] = React.useState(false);


    function doSubmit(values, lst, lett) {
        setIsAuthenticating(true)
        console.log(values)
        console.log(Firebase.userInfo)
    
        var v = values 
        
        let suc = function () {
          setIsAuthenticating(false)
          navigate(-1)
          //window.open(Firebase.adminip + 'v/' + state.customer.quoteId)
        }
    
        let f  = function () {
          setIsAuthenticating(false)
          alert('Error saving changes. Check internet connection and try again.')
        }
    
        Firebase.createNewLongTermContract(v, lst, lett, Firebase.uid, suc, f)
    
    
      }

      if (isAuthenticating) {
        return (<Page
          className={classes.root}
          title="Request Quote"
          backgroundColor={classes.root.backgroundColor}
        ><div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}><CircularProgress/></div></Page>)
      }
     
      return (
        <MyContext.Consumer>{context => (
      <JobMasterView ltcCreation={true} editQuoteShipper={false} transporterAssignment={true} adminEdit={true} provideQuote={false} privateQuote={false} publicQuote={false} quoteOffer={false} evaluateBid={false} parentSubmit={(v, ls, le) => doSubmit(v, ls, le)} parentState={{}} jobProps={{
        shippers: context.users.filter((item, idx) => {return item.userType == 2 }), editable: true, priceEditable: true, showPrices: true, showAssignments: false, showTransporterPrice: true, transporterPriceEditable: true}} />
      )}</MyContext.Consumer>)
    

};

/*

        

*/

export default CreateLTCAdmin;