import React from 'react';
import { Navigate } from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout';
import MainLayout from 'src/layouts/MainLayout';
import AccountView from 'src/views/account/AccountView';
import CustomerListView from 'src/views/customer/CustomerListView';
import DashboardView from 'src/views/reports/DashboardView';
import LoginView from 'src/views/auth/LoginView';
import NotFoundView from 'src/views/errors/NotFoundView';
import ProductListView from 'src/views/product/ProductListView';
import RegisterView from 'src/views/auth/RegisterView';
import SettingsView from 'src/views/settings/SettingsView';
import PublicQuoteView from 'src/views/auth/PublicQuoteView';
import NewQuoteView from './views/customer/CustomerListView/NewQuoteView';
import EditQuoteView from './views/customer/CustomerListView/EditQuoteView';
import EditQuoteViewAdmin from './views/customer/CustomerListView/EditQuoteViewAdmin';
import SummaryQuoteView from './views/customer/CustomerListView/SummaryQuoteView';
import TransporterTableMainView from './views/custom/TransporterTableMainView';
import GenericLocationWrapper from './views/custom/GenericLocationWrapper';
import UsersListView from './views/user/UserListView';
import NewUserView from './views/user/UserListView/NewUserView';
import JobSummaryPane from './views/custom/JobSummaryPane';
import EditUserView from './views/user/UserListView/EditUserView'
import VehiclesListView from './views/vehicle/VehicleListView'
import NewVehicleView from './views/vehicle/VehicleListView/NewVehicleView'
import EditVehicleView from './views/vehicle/VehicleListView/EditVehicleView'
import EvaluateBidView from './views/customer/CustomerListView/EvaluateBidView';
import EditJobViewAdmin from './views/customer/CustomerListView/EditJobViewAdmin';
import LoadingView from 'src/LoadingView';
import ShipperOnlyQuoteTable from './shipper/ShipperOnlyQuoteTable';
import BaseWrapper from './shipper/BaseWrapper';
import EditVehicleGPSWox from './views/vehicle/VehicleListView/EditVehicleGPSWox';
import LoginViewAdmin from './views/auth/LoginViewAdmin';
import AdminMap from './views/custom/AdminMap';
import EditJobAssignAdmin from './views/customer/CustomerListView/EditJobAssignAdmin';
import CreateLTCAdmin from './views/customer/CustomerListView/CreateLTCAdmin';
import PrivacyPolicy from './components/PrivacyPolicy';
import TermsOfUse from './components/TermsOfUse';
import ContractListView from './views/contracts/ContractListView';
import EditLTCAdmin from './views/customer/CustomerListView/EditLTCAdmin';
import CreateJobForShipper from './views/customer/CustomerListView/CreateJobForShipper';
import LoginViewAassociation from './views/auth/LoginViewAssociation';
import NewAssociationView from './views/association/AssociationListView/NewAssociationView';
import EditAssociationView from './views/association/AssociationListView/EditAssociationView';
import AssociationListView from './views/association/AssociationListView';
import AdminMapWrapper from './views/custom/AdminMapWrapper';


function keyMap(lst, key, val, cond, val2=null) {
  return lst.filter((item, idx) => {

    if (cond == '<') {
      return item[key] < val
    } else if (cond == '>') {
      return item[key] > val 
    } else if (cond == '=') {
      return item[key] == val
    } else if (cond == 'bound') {
      return item[key] > val && item[key] < val2 
    }
  })
}


const routes = (isLoggedIn, tableItems, userInfo, cargoTypes, navigation) => [
  {
    path: 'app',
    element:  isLoggedIn == -1 ? <LoadingView/> : isLoggedIn == 1 ? <DashboardLayout userInfo={userInfo}/> : <Navigate to="/login"/>,
    children: [
      { path: 'account', element: <AccountView userInfo={userInfo}/> },
      { path: 'customers', element: <CustomerListView listprop={tableItems}/> },
      { path: 'shipperquotes', element: <BaseWrapper keyProp={1} customers={keyMap(tableItems, 'state', 0, '=')}/>},
      { path: 'shipperoffers', element: <BaseWrapper keyProp={2}  customers={keyMap(tableItems, 'state', 1, '=')}/>},
      { path: 'shipperscheduled', element: <BaseWrapper keyProp={3} customers={keyMap(tableItems, 'state', 1, 'bound', 7)}/>},
      { path: 'shipperprogress', element: <BaseWrapper keyProp={4} customers={keyMap(tableItems, 'state', 6, 'bound', 10)}/>},
      { path: 'dashboard', element: <DashboardView userInfo={userInfo}/> },
      { path: 'map', element: <AdminMapWrapper/>},
      { path: 'users', element: <UsersListView /> },
      { path: 'contracts', element: <ContractListView /> },
      { path: 'vehicles', element: <VehiclesListView /> },
      { path: 'products', element: <ProductListView /> },
      { path: 'settings', element: <SettingsView /> },
      { path: 'newuser', element: <NewUserView/>},
      { path: 'quote', element: <NewQuoteView /> },
      { path: 'createforshipper', element: <CreateJobForShipper/> },
      { path: 'makeltc', element: <CreateLTCAdmin/> },
      { path: 'editltc', element: <EditLTCAdmin/> },
      { path: 'editquote', element: <EditQuoteView /> },
      { path: 'editquoteadmin', element: <EditQuoteViewAdmin/>},
      { path: 'assigntransporter', element: <EditJobAssignAdmin/>},
      { path: 'editjobadmin', element: <EditJobViewAdmin/>},
      { path: 'evaluatebid', element: <EvaluateBidView/>},
      { path: 'confirmquote', element: <SummaryQuoteView/>},
      { path: 'managetransporter', element: <GenericLocationWrapper testx={'siejef'} locType={1}/>},
      { path: 'edituser', element: <EditUserView/>},
      { path: 'newvehicle', element: <NewVehicleView/>},
      { path: 'editvehicle', element: <EditVehicleView/>},
      { path: '*', element: <Navigate to="/404" /> }
    ]
  },
  {
    path: '/info/*',
    element:<GenericLocationWrapper testx={'siejef'} locType={2}/>
  },
  {
    path: '/v/*',
    element: isLoggedIn == 1 ? <GenericLocationWrapper testx={'siejef'} locType={0}/> : <MainLayout />
  },
  {
    path: '/privacypolicy',
    element : <PrivacyPolicy/>
  },
  {
    path: '/termsofuse',
    element : <TermsOfUse/>
  },
  {
    path: '/',
    element: isLoggedIn == 1 ? <Navigate to="/app/customers"/> : <MainLayout />,
    children: [
      { path: 'login', element: <LoginViewAassociation /> },
      { path: '404', element: <NotFoundView /> },
      { path: '/', element: <Navigate to="/app/dashboard" /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  },

];

/**
 * 
 * State list
 * -1: quote rejected
 * 0: unassigned, fresh quote
 * 1: quote frozen, sent to shipper for confirmation
 * 2: quote confirmed by shipper
 */

export default routes;
