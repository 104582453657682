import React, {Component} from 'react'
import DashboardCard from './DashboardCard';
import MyContext from 'src/MyContext';

export default class DashboardCardHolder extends Component {

    constructor(props) {
        super(props)

        
    }

    render() {
        return (        <MyContext.Consumer>{context => (
            <DashboardCard 
            
            title={this.props.title} 
                count={context[this.props.count].length} 
                bodyText={this.props.bodyText}
                colorProp={this.props.colorProp}
                navTarget={this.props.navTarget}
            />
              )}</MyContext.Consumer>)

    }


}