import { truncate } from 'lodash';
import React, {Component} from 'react'
import Firebase from './Firebase';
import MyContext from './MyContext';
import TableAssist from './utils/TableAssist'


const WAIT_THRESH = 5;
const sleep = (milliseconds) => {
    return new Promise(resolve => setTimeout(resolve, milliseconds))
  }

export default class ContextDataLoader {

    constructor(isAdmin, isAssoc) {
        this.state = {
            innerPlansDict: {},
            planList: [],
            planDict: {},
            trainList: [],
            trainDict: {},
            arrivalList: [],
            arrivalDict: {},
            operationList: [],
            operationDict: {},
            planFields: [],
            trainFields: [],
            arrivalFields: [],
            operationFields: [],
            barleyInFields: [],
            barleyOutFields: [],
            transferBarleyInFields: [],
            transferBarleyOutFields: [],
            barleyOutList: [],
            barleyOutDict: {},
            barleyInList: [],
            barleyInDict: {},
            transferBarleyInList: [],
            transferBarleyInDict: {},
            transferBarleyOutList: [],
            transferBarleyOutDict: {},
            adminJobsList: [],
            adminJobsDict: {},
            usersList: [],
            usersDict: {},
            vehiclesList: [],
            vehiclesDict: {},
            contractsList: [],
            contractsDict: {},
            webNotifications: {},
            contractsAdminList: [],
            contractsAdminDict: {},
            associationsAdminList: [],
            associationsAdminDict: {},
            freightOrdersList: [],
            freightOrdersDict: {},
            freightOrdersListAdmin: [],
            freightOrdersDictAdmin: {},
            freightOrdersListShipper: [],
            freightOrdersDictShipper: {},
            invoicesList: [],
            invoicesDict: {},
            paymentsList: [],
            paymentsDict: {},
            invoicesListShipper: [],
            invoicesDictShipper: {},
            report_errors: false,
            userInfo: {},
            localityMap: {},
            barleyInCustomObjects: {},
            barleyInCustomObjectFields: {},
            barleyStocksDict: {},
            barleyStocksPendingDict: {},
            barleyStocksTransitDict: {},
            transporterMappingDict: {},
            onlineStatusDict: {},
            netConnected: false,
            wentOffline: null,
            warehouseRole: null,
            targetWarehouse: null,
            targetWarehouseSet: {},
            warehouseUserInfo: {}
        }


        this.showOldItems = false 
        this.listeners = {}
        this.didInit = false
        this.admin = isAdmin
        this.assoc = isAssoc
        this.lastFire = new Date()
        this.parentReference = null 
        this.loadingInterval = null 
        this.numCalls = 0
        this.allowParentUpdates = false 
        this.barleyListener = null 
        this.fieldsListener = null 
        this.barleyOutListener = null 
        this.fieldsOutListener = null 
        this.customObjectListener = null 
        this.barleyStocksDict = null 
        this.transferBarleyInListener = null 
        this.transferBarleyOutListener = null 
        this.barleyStocksTransitListener = null 
        this.onlineStatusDictListener = null 
        this.transporterMappingListener = null 
        this.innerPlanListenerDict = {}
        this.customObjectListenerDict = {}
        this.customObjectFieldListenerDict = {}
        this.connectedRef = null 
        this.userStatusDatabaseRef = null 
        this.userRoleListener = null 
        this.simpleListeners = {}

        this.minCreationKeyDict = {'arrivals': 999999999999999}
        this.didFetchAllDict = {}

        this.targetWarehouseValue = null 
        this.targetWarehouseSet = {}

        
    }

    componentDidMount() {
        if (Firebase.requiresContextInit) {
            this.didInit()
        }
    }

    componentWillUnmount() {
        if (this.didInit) {
            this.reset()
            this.didInit = false 
        }
    }


    setState(state) {
        //console.log('trying to set state with state')
        //console.log(state)
        Object.keys(state).map((item, idx) => {
            this.state[item] = state[item]
        })
        if (this.allowParentUpdates) {
            if (this.parentReference != null) {
                this.parentReference.updateState(this.state)
            }
        }
    }

    async getLimitedAccessData() {
        var limitedAccessData = await Firebase.getReducedInfo({'userId': Firebase.uid})
        if (limitedAccessData && limitedAccessData.info) {
            var useDict = {}
            var vehicleDict = {}
            var assocDict = {}
            limitedAccessData.info.users.map((item, idx) => {
                useDict[item.userId] = this.renderUserName(item)
            })
            limitedAccessData.info.vehicles.map((item, idx) => {
                vehicleDict[item.vehicleId] = 1
            })
            limitedAccessData.info.associations.map((item, idx) => {
                assocDict[item.associationId] = 1
            })
            this.setState({
                usersList: limitedAccessData.info.users,
                usersDict: useDict,
                associationsAdminList: limitedAccessData.info.associations,
                associationsAdminDict: assocDict,
                vehiclesList: limitedAccessData.info.vehicles,
                vehiclesDict: vehicleDict
            })
            //alert(JSON.stringify(limitedAccessData.info))
        }
    }

    fetchAll(targetKey, listTarget, dictTarget, idTarget) {
        if (this.didFetchAllDict[targetKey]) {
            console.log('exit because already fetched for ' + targetKey)
            return 
        }
        console.log('do not exit because do not already fetched for ' + targetKey)
        this.didFetchAllDict[targetKey] = true 
        let self = this 
        let listener = this.prepareProperCollectionReference(targetKey, targetKey == 'arrivals' ? 'train.warehouse.name' : 'warehouse.name', this.targetWarehouseSet).orderBy('creationDate', 'desc').startAfter(this.minCreationKeyDict[targetKey]).get().then((snapshot, err) => {
            if (err) {
                console.log('here error')
                console.log(err)
            }
            console.log('so snapshot')
            console.log(err)
            console.log(snapshot)
            snapshot.forEach((doc, err) => {
                //console.log('starting to try doc')
                if (err) {
                    console.log('doc change error!!')
                    console.log(err)
                }
                if (doc.id && doc.data()) {
                    var dat = doc.data()   
                    dat[idTarget] = doc.id
                    dat['id'] = doc.id
                    if (dat['creationDate'] && dat['creationDate'] < self.minCreationKeyDict[targetKey]) {
                        self.minCreationKeyDict[targetKey] = dat['creationDate']
                    } 
                    if (doc.id in self.state[dictTarget]) {
                        self.functionalStateListUpdate(dat, listTarget, idTarget)
                    } else {
                        
                        self.functionalStateListAdd(dat, listTarget, dictTarget, idTarget)
                    }
                }

            });
          });
    }

    applyYearFilterIfNeeded(key, ref) {

        if (this.showOldItems) {
            return ref 
        } else if (key == 'operations' || key == 'arrivals' || key == 'trains' || key == 'transferBarleyIn') {
            console.log(ref.where('yearCodeForFiltering', '==', '24'))
            return ref.where('yearCodeForFiltering', '==', '24')
        } else {
            return ref
        }
    }

    prepareProperCollectionReference(key, warehouseKey, warehouseVal) {



        if (warehouseVal == -1 || key == 'operations' || key == 'plans' || key == "barleyInCustomObjects/contents/warehouse") {
            return this.applyYearFilterIfNeeded(key, Firebase.firestore.collection('projects').doc(Firebase.userProject).collection(key))
        } else  {
            if (warehouseVal && typeof warehouseVal != 'string' && Object.keys(warehouseVal).length > 0) {
                return this.applyYearFilterIfNeeded(key, Firebase.firestore.collection('projects').doc(Firebase.userProject).collection(key).where(warehouseKey, 'in', Object.keys(warehouseVal)))
            } else {
                return this.applyYearFilterIfNeeded(key, Firebase.firestore.collection('projects').doc(Firebase.userProject).collection(key).where(warehouseKey, '==','null'))
            }
            
        } /*else {

            return Firebase.firestore.collection('projects').doc(Firebase.userProject).collection(key).where(warehouseKey, '==', warehouseVal)
        }*/
    }

    registerFieldListener(listenerKey, targetKey, stateKey) {
        let self = this 
        let listener = Firebase.firestore.collection('projects').doc(Firebase.userProject).collection(targetKey).onSnapshot((snapshot) => {
            // ...
            var v = {}
            v[stateKey] = snapshot.docs.map(doc => doc.data())
            self.setState(v)
        }, (error) => {
            // ...
        });
        this.simpleListeners[listenerKey] = listener
    }


    registerObjectListener(listenerKey, targetKey, listTarget, dictTarget, idTarget, limit = null) {

        if (limit != null) {
            let self = this
            let listener = this.prepareProperCollectionReference(targetKey, targetKey == 'arrivals' ? 'train.warehouse.name' : 'warehouse.name', this.targetWarehouseSet).orderBy('creationDate', 'desc').startAfter(this.minCreationKeyDict['arrivals']).limit(500).onSnapshot((snapshot) => {
                snapshot.docChanges().forEach((change) => {
                console.log('here is that other change')
                console.log(change)
                console.log(change.doc.data())
                
                    if (change.type === "added") {
                    var dat = change.doc.data()
                    dat[idTarget] = change.doc.id 
                    dat['id'] = change.doc.id
                    //console.log('here we are with an added doc')
                    //console.log(dat)
                    if (change.doc.id in self.state[dictTarget]) {
                        self.functionalStateListUpdate(dat, listTarget, idTarget)
                    } else {
                        self.functionalStateListAdd(dat, listTarget, dictTarget, idTarget)
                        if (targetKey == 'plans') {
                            self.innerPlanListenerDict[change.doc.id] = Firebase.firestore.collection('projects').doc(Firebase.userProject).collection('plansInner').doc(change.doc.id).collection('contents').onSnapshot((innerSnap) => {
                                var customTemp = {}
                                innerSnap.docs.map((inDoc, idx2) => {
                                    customTemp[inDoc.id] = inDoc.data()
                                    customTemp[inDoc.id]['customId'] = inDoc.id
                                    customTemp[inDoc.id]['id'] = inDoc.id
                                })
                                var tempx = self.state.innerPlansDict
                                tempx[change.doc.id] = customTemp
                                self.setState({
                                    innerPlansDict: tempx
                                })
    
                            }, (error) => {
    
                            })
                        }
                    }
                      //console.log(change.doc.id)
                      //console.log("New city: ", change.doc.data());
                  }
                  if (change.type === "modified") {
                    var dat = change.doc.data()
                    console.log('and change but request id')
                    dat[idTarget] = change.doc.id 
                    dat['id'] = change.doc.id
                    console.log('and now with a changed doc')
                    console.log(change.doc)
                    if (change.doc.id in self.state[dictTarget]) {
                        self.functionalStateListUpdate(dat, listTarget, idTarget)
                    } else {
                        self.functionalStateListAdd(dat, listTarget, dictTarget, idTarget)
                    }
                     // console.log("Modified city: ", change.doc.data());
                  }
                  if (change.type === "removed") {
                    console.log('REMOVING AN ITEM TARGET OF ' + change.doc.id)
                    var nextList = self.state[listTarget].filter((item, idx) => {       
                        return (item[idTarget] != change.doc.id)
                    })
            
                    var existingDict = self.state[dictTarget]
                    delete existingDict[change.doc.id]
                    var retNext = {}
                    retNext[listTarget] = nextList 
                    retNext[dictTarget] = existingDict

                    console.log('enw new lienght is ' + nextList.length)

                    self.setState(retNext)

                    self.listenToSingleItem(targetKey, change.doc.id, 'arrivalList', 'arrivalDict', 'arrivalId')
                      //console.log("Removed city: ", change.doc.data());
                  }
                });
              });
              this.simpleListeners[listenerKey] = listener
        } else {
            
            let self = this
            self.didFetchAllDict[targetKey] = true
            let listener = this.prepareProperCollectionReference(targetKey, targetKey == 'arrivals' ? 'train.warehouse.name' : 'warehouse.name', this.targetWarehouseSet).onSnapshot((snapshot) => {
                snapshot.docChanges().forEach((change, err) => {
                    if (err) {
                        console.log('doc change error!!')
                        console.log(err)
                    }
                  if (change.type === "added") {
                    var dat = change.doc.data()
                    dat[idTarget] = change.doc.id 
                    dat['id'] = change.doc.id
                    //console.log('here we are with an added doc')
                    //console.log(dat)
                    if (change.doc.id in self.state[dictTarget]) {
                        self.functionalStateListUpdate(dat, listTarget, idTarget)
                    } else {
                        self.functionalStateListAdd(dat, listTarget, dictTarget, idTarget)
                        if (targetKey == 'plans') {
                            self.innerPlanListenerDict[change.doc.id] = Firebase.firestore.collection('projects').doc(Firebase.userProject).collection('plansInner').doc(change.doc.id).collection('contents').onSnapshot((innerSnap) => {
                                var customTemp = {}
                                innerSnap.docs.map((inDoc, idx2) => {
                                    customTemp[inDoc.id] = inDoc.data()
                                    customTemp[inDoc.id]['customId'] = inDoc.id
                                    customTemp[inDoc.id]['id'] = inDoc.id
                                })
                                var tempx = self.state.innerPlansDict
                                tempx[change.doc.id] = customTemp
                                self.setState({
                                    innerPlansDict: tempx
                                })
    
                            }, (error) => {
    
                            })
                        }
                    }
                      //console.log(change.doc.id)
                      //console.log("New city: ", change.doc.data());
                  }
                  if (change.type === "modified") {
                    var dat = change.doc.data()
                    console.log('and change but request id')
                    dat[idTarget] = change.doc.id 
                    dat['id'] = change.doc.id
                    console.log('and now with a changed doc')
                    console.log(change.doc)
                    if (change.doc.id in self.state[dictTarget]) {
                        self.functionalStateListUpdate(dat, listTarget, idTarget)
                    } else {
                        self.functionalStateListAdd(dat, listTarget, dictTarget, idTarget)
                    }
                     // console.log("Modified city: ", change.doc.data());
                  }
                  if (change.type === "removed") {
    
                    var nextList = self.state[listTarget].filter((item, idx) => {       
                        return (item[idTarget] != change.doc.id)
                    })
            
                    var existingDict = self.state[dictTarget]
                    delete existingDict[change.doc.id]
                    var retNext = {}
                    retNext[listTarget] = nextList 
                    retNext[dictTarget] = existingDict
                    self.setState(retNext)
    
                      //console.log("Removed city: ", change.doc.data());
                  }
                });
              });
              this.simpleListeners[listenerKey] = listener
        }


       

    }

    async init(parentReference) {

        if (this.didInit) {
            return
        }
        let self = this
        this.didInit = true 
        this.parentReference = parentReference




        
        Firebase.database.ref('users/' + Firebase.uid).on('value', (val) => {
            if (val.exists()) {
                Firebase.userInfo = val.val()
                self.setState({
                    userInfo: val.val()
                })
            } else {
                self.setState({
                    userInfo: {}
                })
            }
        })

        if (this.assoc) {
            let tempUserAssoc = await Firebase.database.ref('users/' + Firebase.uid).once('value').then((datax) => {
            if (datax.exists() && 'associationId' in datax.val()) {
                return datax.val().associationId
            } else {
                return 'unset'
            }

        }).catch((error) => {
            console.log('error')
            return 'unset'
        })
            this.loadListDualRef('associationShipperRequests/' + tempUserAssoc, 'shipperRequests', 'adminJobsList', 'adminJobsDict', 'quoteId')
            this.loadListDualRef('associationUsers/' + tempUserAssoc, 'users', 'usersList', 'usersDict', 'userId')
            this.loadListDualRef('associationVehicles/' + tempUserAssoc, 'vehicles', 'vehiclesList', 'vehiclesDict', 'vehicleId')
            this.loadListDualRef('associationLongTermContracts/' + tempUserAssoc, 'longTermContracts', 'contractsAdminList', 'contractsAdminDict', 'contractId')
       

            Firebase.database.ref('webNotifications/admin').on('value',  (val) => {
                if (val.exists()) {
                    self.setState({
                        webNotifications: val.val()
                    })
                    //alert(val.val())
                } else {
                    self.setState({
                        webNotifications: {}
                    })
                }
            }, function (error) {

            })
        } else if (this.admin) {

            // Determine if this account is access-limited
            let limitedAccess = await Firebase.database.ref('adminRoleBasic/' + Firebase.uid).once('value').then((datax) => {
                if (datax.exists()) {
                    return datax.val()
                } else {
                    return null
                }}).catch((error) => {
                    return null
                })
                //alert(limitedAccess)
            if (limitedAccess == null) {
                // Full admin access
                this.parentReference.setState({
                    isLimited: -1
                })
            } else if (limitedAccess == 0) {

                // Level 0 admin access
                await this.getLimitedAccessData()
                this.parentReference.setState({
                    isLimited: 0
                })

            } else if (limitedAccess == 1) {

                // Level 1 admin access
                this.parentReference.setState({
                    isLimited: 1
                })
                //alert('is not limited access')
            } 
            this.loadListDualRef('shipperRequests', 'shipperRequests', 'adminJobsList', 'adminJobsDict', 'quoteId')
            this.loadListDualRef('users', 'users', 'usersList', 'usersDict', 'userId')
            this.loadListDualRef('associations', 'associations', 'associationsAdminList', 'associationsAdminDict', 'associationId')
            this.loadListDualRef('vehicles', 'vehicles', 'vehiclesList', 'vehiclesDict', 'vehicleId')
            this.loadListDualRef('longTermContracts', 'longTermContracts', 'contractsAdminList', 'contractsAdminDict', 'contractId')
            this.loadListDualRef('shipperFreightOrders', 'shipperFreightOrders', 'freightOrdersListAdmin', 'freightOrdersDictAdmin', 'freightOrderId')
            this.loadListDualRef('invoices', 'invoices', 'invoicesList', 'invoicesDict', 'invoiceId')
            this.loadListDualRef('payments', 'payments', 'paymentsList', 'paymentsDict', 'paymentId')
       
            Firebase.database.ref('constants/localityMap').on('value', (val) => {
                if (val.exists()) {
                   
                    self.setState({
                        localityMap: val.val()
                    })
                } else {
                    self.setState({
                        localityMap: {}
                    })
                }
            })

            Firebase.database.ref('webNotifications/admin').on('value',  (val) => {
                if (val.exists()) {
                    self.setState({
                        webNotifications: val.val()
                    })
                    //alert(val.val())
                } else {
                    self.setState({
                        webNotifications: {}
                    })
                }
            }, function (error) {

            })
        } else {
            let userProject = await Firebase.getUserProject({})
            console.log(userProject)
            Firebase.setUserProject(userProject.value)
            console.log('here is up')
            console.log(Firebase.userProject)
               //alert(Firebase.uid)
            if (Firebase.uid == '9lB6rtBWCUbBTNfXIxCpnnqYNnC2' || Firebase.uid == 'APKKqWkvy5gJVc37frabNP9Y1Oi1' || Firebase.uid == 'OLmlbv7MPRSAIgxVvo4ccYKfIR53') {
                this.targetWarehouseValue = -1
                self.setState({
                    warehouseRole: 'Admin',
                    targetWarehouse: -1
                })
            } else {
                
                let tempRes = await Firebase.firestore.collection('projects').doc(Firebase.userProject).collection("barleyInCustomObjects/contents/warehousesoftwareusers/").doc(Firebase.uid).get()
                let tempData = tempRes.data()
                console.log('here we got some tempdata')
                console.log(tempData)
                if(tempData.role == 'Factory' || tempData.role == 'Warehouse' || tempData.role == 'Facility') {
                    this.targetWarehouseValue = tempData && tempData.warehouse && tempData.warehouse.name ? tempData.warehouse.name : ''
                    this.targetWarehouseSet = tempData.targetWarehouseSet ? tempData.targetWarehouseSet : {}
                    this.showOldItems = tempData.showOldItems ? tempData.showOldItems : false
                } else {
                    console.log('negative 1 set')
                    this.targetWarehouseValue = -1
                    this.targetWarehouseSet = -1
                    this.showOldItems = tempData.showOldItems ? tempData.showOldItems : false
                    console.log(this.targetWarehouseValue)
                }

                this.userRoleListener = Firebase.firestore.collection('projects').doc(Firebase.userProject).collection("barleyInCustomObjects/contents/warehousesoftwareusers/").doc(Firebase.uid).onSnapshot(doc => {
                    if (doc.exists) {
                        self.setState({
                            warehouseRole: doc.data().role,
                            targetWarehouse: doc.data().role == 'Admin' || doc.data().role == 'Warehouse Manager' ? -1 : doc.data().warehouse,
                            targetWarehouseSet: doc.data().targetWarehouseSet ? doc.data().targetWarehouseSet : {},
                            warehouseUserInfo: doc.data()
                        })
                    } else {
                        self.setState({
                            warehouseRole: null,
                            targetWarehouse: null,
                            targetWarehouseSet: {}
                        })
                    }
               });
            }
            

            this.userStatusDatabaseRef = Firebase.database.ref('/userConnectStatusWarehouse/' + Firebase.uid)
            var isOfflineForDatabase = {
                state: 'offline',
                last_changed: Firebase.databaseSimple.ServerValue.TIMESTAMP,
                warehouse: this.targetWarehouseValue
            };
            
            var isOnlineForDatabase = {
                state: 'online',
                last_changed: Firebase.databaseSimple.ServerValue.TIMESTAMP,
                warehouse: this.targetWarehouseValue
            };
            
            this.connectedRef = Firebase.database.ref(".info/connected");
                this.connectedRef.on("value", (snap) => {
                if (snap.val() === true) {
                    this.userStatusDatabaseRef.set(isOnlineForDatabase);
                    this.userStatusDatabaseRef.onDisconnect().set(isOfflineForDatabase).then(function() {
                        // The promise returned from .onDisconnect().set() will
                        // resolve as soon as the server acknowledges the onDisconnect() 
                        // request, NOT once we've actually disconnected:
                        // https://firebase.google.com/docs/reference/js/firebase.database.OnDisconnect
                
                        // We can now safely set ourselves as 'online' knowing that the
                        // server will mark us as offline once we lose connection.
                        self.userStatusDatabaseRef.set(isOnlineForDatabase);
                    });

                    self.setState({netConnected: true})
                } else {


                    self.setState({netConnected: false, wentOffline: Date.now()})
                }
                });

            
            this.customObjectListener = Firebase.firestore.collection('projects').doc(Firebase.userProject).collection("barleyInCustomObjectsList").onSnapshot((snapshot) => {
                // ...
                var customSet = {}

                snapshot.docs.map((doc, idx) => {
                    let d = doc.data()
                    if (!(d.name in self.customObjectListenerDict)) {

                        if (d.name == 'warehouse') {
                            self.customObjectListenerDict[d.name] = this.prepareProperCollectionReference("barleyInCustomObjects/contents/" + d.name, 'name', this.targetWarehouseSet).onSnapshot((innerSnap) => {
                                var customTemp = {}
                                innerSnap.docs.map((inDoc, idx2) => {
                                    customTemp[inDoc.id] = inDoc.data()
                                    customTemp[inDoc.id]['customId'] = inDoc.id
                                    customTemp[inDoc.id]['id'] = inDoc.id
                                })
                                var tempx = self.state.barleyInCustomObjects
                                tempx[d.name] = customTemp
                                self.setState({
                                    barleyInCustomObjects: tempx
                                })
                                /*console.log('did set now')
                                console.log('here was name')
                                console.log(d.name)
                                console.log('here was custom')
                                console.log(customTemp)
                                console.log(tempx)*/
                            }, (error) => {
    
                            })
                        } else {
                            self.customObjectListenerDict[d.name] = Firebase.firestore.collection('projects').doc(Firebase.userProject).collection("barleyInCustomObjects/contents/" + d.name).onSnapshot((innerSnap) => {
                                var customTemp = {}

                                innerSnap.docs.map((inDoc, idx2) => {
                                    customTemp[inDoc.id] = inDoc.data()
                                    customTemp[inDoc.id]['customId'] = inDoc.id
                                    customTemp[inDoc.id]['id'] = inDoc.id
                                })
                                var tempx = self.state.barleyInCustomObjects
                                tempx[d.name] = customTemp
                                self.setState({
                                    barleyInCustomObjects: tempx
                                })
                                /*console.log('did set now')
                                console.log('here was name')
                                console.log(d.name)
                                console.log('here was custom')
                                console.log(customTemp)
                                console.log(tempx)*/
                            }, (error) => {
    
                            })
                        }
                    }
                    if (!(d.name in self.customObjectFieldListenerDict)) {
                        self.customObjectFieldListenerDict[d.name] = Firebase.firestore.collection('projects').doc(Firebase.userProject).collection("barleyInCustomObjects/fields/" + d.name).onSnapshot((innerSnap) => {
                            var customTemp = {}
                            innerSnap.docs.map((inDoc, idx2) => {
                                customTemp[inDoc.id] = inDoc.data()
                                customTemp[inDoc.id]['customId'] = inDoc.id
                            })
                            var tempx = self.state.barleyInCustomObjectFields
                            tempx[d.name] = customTemp
                            self.setState({
                                barleyInCustomObjectFields: tempx
                            })
                            /*console.log('did set now')
                            console.log('here was name')
                            console.log(d.name)
                            console.log('here was custom')
                            console.log(customTemp)
                            console.log(tempx)*/
                        }, (error) => {

                        })
                    }
                })
                console.log('here is custom set')
                console.log(customSet)

            }, (error) => {
                // ...
            });

            this.registerFieldListener('planFields', 'planFields', 'planFields')
            this.registerFieldListener('operationFields', 'operationFields', 'operationFields')
            this.registerFieldListener('trainFields', 'trainFields', 'trainFields')
            this.registerFieldListener('arrivalFields', 'arrivalFields', 'arrivalFields')
            this.registerObjectListener('trains', 'trains', 'trainList', 'trainDict', 'trainId')
            this.registerObjectListener('arrivals', 'arrivals', 'arrivalList', 'arrivalDict', 'arrivalId', 500)
            this.registerObjectListener('operations', 'operations', 'operationList', 'operationDict', 'operationId')
            this.registerObjectListener('plans', 'plans', 'planList', 'planDict', 'planId')


            this.fieldsListener = Firebase.firestore.collection('projects').doc(Firebase.userProject).collection("barleyInFields").onSnapshot((snapshot) => {
                // ...
                self.setState({
                    barleyInFields: snapshot.docs.map(doc => doc.data())
                })
            }, (error) => {
                // ...
            });

            this.fieldsOutListener = Firebase.firestore.collection('projects').doc(Firebase.userProject).collection("barleyOutFields").onSnapshot((snapshot) => {
                // ...
                self.setState({
                    barleyOutFields: snapshot.docs.map(doc => doc.data())
                })
            }, (error) => {
                // ...
            });

            this.fieldsListener = Firebase.firestore.collection('projects').doc(Firebase.userProject).collection("transferBarleyInFields").onSnapshot((snapshot) => {
                // ...
                self.setState({
                    transferBarleyInFields: snapshot.docs.map(doc => doc.data())
                })
            }, (error) => {
                // ...
            });

            this.fieldsOutListener = Firebase.firestore.collection('projects').doc(Firebase.userProject).collection("transferBarleyOutFields").onSnapshot((snapshot) => {
                // ...
                self.setState({
                    transferBarleyOutFields: snapshot.docs.map(doc => doc.data())
                })
            }, (error) => {
                // ...
            });


            this.onlineStatusDictListener = Firebase.firestore.collection('projects').doc(Firebase.userProject).collection('userOnlineRecords').onSnapshot((snapshot) => {
                snapshot.docChanges().forEach((change) => {
                  if (change.type === "added" || change.type == 'modified') {
                    var dat = change.doc.data()
                    var retNext = self.state.onlineStatusDict
                    retNext[change.doc.id] = dat
                    var stateNext = {}
                    stateNext['onlineStatusDict'] = retNext
                    self.setState(stateNext)
                  }
                });
              });


            this.barleyStocksListener = this.showOldItems ? Firebase.firestore.collection('projects').doc(Firebase.userProject).collection('barleyStocks').onSnapshot((snapshot) => {
                snapshot.docChanges().forEach((change) => {
                  if (change.type === "added" || change.type == 'modified') {
                    var dat = change.doc.data()
                    let warehouse = dat['warehouse']
                    var retNext = self.state.barleyStocksDict
                    if (warehouse in self.state.barleyStocksDict) {
                        retNext[warehouse][change.doc.id] = dat
                    } else {
                        retNext[warehouse] = {}
                        retNext[warehouse][change.doc.id] = dat

                    }
                    var stateNext = {}
                    stateNext['barleyStocksDict'] = retNext
                    self.setState(stateNext)
                   
                  }
                });
              }) : Firebase.firestore.collection('projects').doc(Firebase.userProject).collection('barleyStocks').where('yearCodeForFiltering', '==', '24').onSnapshot((snapshot) => {
                snapshot.docChanges().forEach((change) => {
                  if (change.type === "added" || change.type == 'modified') {
                    var dat = change.doc.data()
                    let warehouse = dat['warehouse']
                    var retNext = self.state.barleyStocksDict
                    if (warehouse in self.state.barleyStocksDict) {
                        retNext[warehouse][change.doc.id] = dat
                    } else {
                        retNext[warehouse] = {}
                        retNext[warehouse][change.doc.id] = dat

                    }
                    var stateNext = {}
                    stateNext['barleyStocksDict'] = retNext
                    self.setState(stateNext)
                   
                  }
                });
              })

              this.barleyStocksPendingListener = Firebase.firestore.collection('projects').doc(Firebase.userProject).collection('barleyStocksPending').onSnapshot((snapshot) => {
                snapshot.docChanges().forEach((change) => {
                  if (change.type === "added" || change.type == 'modified') {
                    var dat = change.doc.data()
                    let warehouse = dat['warehouse']
                    var retNext = self.state.barleyStocksPendingDict
                    if (warehouse in self.state.barleyStocksPendingDict) {
                        retNext[warehouse][change.doc.id] = dat
                    } else {
                        retNext[warehouse] = {}
                        retNext[warehouse][change.doc.id] = dat

                    }
                    var stateNext = {}
                    stateNext['barleyStocksPendingDict'] = retNext
                    self.setState(stateNext)
                   
                  }
                });
              });

              this.barleyStocksTransitListener = Firebase.firestore.collection('projects').doc(Firebase.userProject).collection('barleyStocksTransit').onSnapshot((snapshot) => {
                snapshot.docChanges().forEach((change) => {
                  if (change.type === "added" || change.type == 'modified') {
                    var dat = change.doc.data()
                    let warehouse = dat['warehouse']
                    var retNext = self.state.barleyStocksTransitDict
                    if (warehouse in self.state.barleyStocksTransitDict) {
                        retNext[warehouse][change.doc.id] = dat
                    } else {
                        retNext[warehouse] = {}
                        retNext[warehouse][change.doc.id] = dat

                    }
                    var stateNext = {}
                    stateNext['barleyStocksTransitDict'] = retNext
                    self.setState(stateNext)
                   
                  }
                });
              });

              this.transporterMappingListener = Firebase.firestore.collection('projects').doc(Firebase.userProject).collection('mapTransporterNameToPhone').onSnapshot((snapshot) => {
                snapshot.docChanges().forEach((change) => {
                  if (change.type === "added" || change.type == 'modified') {
                    var retNext = self.state.transporterMappingDict
                    if (change.doc.id in self.state.transporterMappingDict) {
                        retNext[change.doc.id] = change.doc.data()
                    } else {
                        retNext[change.doc.id] = {}
                        retNext[change.doc.id] = change.doc.data()

                    }
                    var stateNext = {}
                    //console.log('HERE WE HAVE IT!!!!!')
                    //console.log(retNext)
                    stateNext['transporterMappingDict'] = retNext
                    self.setState(stateNext)
                   
                  }
                });
              });

            if (this.targetWarehouseValue != -1) {
                this.extraBarleyOutListener = this.prepareProperCollectionReference('barleyOut', 'source', this.targetWarehouseValue).onSnapshot((snapshot) => {
                    snapshot.docChanges().forEach((change) => {
                      if (change.type === "added") {
                        var dat = change.doc.data()
                        dat['barleyOutId'] = change.doc.id 
                        dat['id'] = change.doc.id
                        //console.log('here we are with an added doc')
                        //console.log(dat)
                        if (change.doc.id in self.state['barleyOutDict']) {
                            self.functionalStateListUpdate(dat, 'barleyOutList', 'barleyOutId')
                        } else {
                            self.functionalStateListAdd(dat, 'barleyOutList', 'barleyOutDict', 'barleyOutId')
                        }
                          //console.log(change.doc.id)
                          //console.log("New city: ", change.doc.data());
                      }
                      if (change.type === "modified") {
                        var dat = change.doc.data()
                        console.log('and change but request id')
                        dat['barleyOutId'] = change.doc.id 
                        dat['id'] = change.doc.id
                        console.log('and now with a changed doc')
                        console.log(change.doc)
                        if (change.doc.id in self.state['barleyOutDict']) {
                            self.functionalStateListUpdate(dat, 'barleyOutList', 'barleyOutId')
                        } else {
                            self.functionalStateListAdd(dat, 'barleyOutList', 'barleyOutDict', 'barleyOutId')
                        }
                         // console.log("Modified city: ", change.doc.data());
                      }
                      if (change.type === "removed") {
    
                        var nextList = self.state['barleyOutList'].filter((item, idx) => {       
                            return (item['barleyOutId'] != change.doc.id)
                        })
                
                        var existingDict = self.state['barleyOutDict']
                        delete existingDict[change.doc.id]
                        var retNext = {}
                        retNext['barleyOutList'] = nextList 
                        retNext['barleyOutDict'] = existingDict
                        self.setState(retNext)
    
                          //console.log("Removed city: ", change.doc.data());
                      }
                    });
                  });
            }

            this.barleyOutListener = this.prepareProperCollectionReference('barleyOut', 'warehouse.name', this.targetWarehouseValue).onSnapshot((snapshot) => {
                snapshot.docChanges().forEach((change) => {
                  if (change.type === "added") {
                    var dat = change.doc.data()
                    dat['barleyOutId'] = change.doc.id 
                    dat['id'] = change.doc.id
                    //console.log('here we are with an added doc')
                    //console.log(dat)
                    if (change.doc.id in self.state['barleyOutDict']) {
                        self.functionalStateListUpdate(dat, 'barleyOutList', 'barleyOutId')
                    } else {
                        self.functionalStateListAdd(dat, 'barleyOutList', 'barleyOutDict', 'barleyOutId')
                    }
                      //console.log(change.doc.id)
                      //console.log("New city: ", change.doc.data());
                  }
                  if (change.type === "modified") {
                    var dat = change.doc.data()
                    console.log('and change but request id')
                    dat['barleyOutId'] = change.doc.id 
                    dat['id'] = change.doc.id
                    console.log('and now with a changed doc')
                    console.log(change.doc)
                    if (change.doc.id in self.state['barleyOutDict']) {
                        self.functionalStateListUpdate(dat, 'barleyOutList', 'barleyOutId')
                    } else {
                        self.functionalStateListAdd(dat, 'barleyOutList', 'barleyOutDict', 'barleyOutId')
                    }
                     // console.log("Modified city: ", change.doc.data());
                  }
                  if (change.type === "removed") {

                    var nextList = self.state['barleyOutList'].filter((item, idx) => {       
                        return (item['barleyOutId'] != change.doc.id)
                    })
            
                    var existingDict = self.state['barleyOutDict']
                    delete existingDict[change.doc.id]
                    var retNext = {}
                    retNext['barleyOutList'] = nextList 
                    retNext['barleyOutDict'] = existingDict
                    self.setState(retNext)

                      //console.log("Removed city: ", change.doc.data());
                  }
                });
              });

            this.barleyListener = this.prepareProperCollectionReference('barleyIn', 'warehouse.name', this.targetWarehouseValue).onSnapshot((snapshot) => {
                snapshot.docChanges().forEach((change) => {
                  if (change.type === "added") {
                    var dat = change.doc.data()
                    dat['barleyInId'] = change.doc.id 
                    dat['id'] = change.doc.id
                    //console.log('here we are with an added doc')
                    //console.log(dat)
                    if (change.doc.id in self.state['barleyInDict']) {
                        self.functionalStateListUpdate(dat, 'barleyInList', 'barleyInId')
                    } else {
                        self.functionalStateListAdd(dat, 'barleyInList', 'barleyInDict', 'barleyInId')
                    }
                      //console.log(change.doc.id)
                      //console.log("New city: ", change.doc.data());
                  }
                  if (change.type === "modified") {
                    var dat = change.doc.data()
                    console.log('and change but request id')
                    dat['barleyInId'] = change.doc.id 
                    dat['id'] = change.doc.id
                    console.log('and now with a changed doc')
                    console.log(change.doc)
                    if (change.doc.id in self.state['barleyInDict']) {
                        self.functionalStateListUpdate(dat, 'barleyInList', 'barleyInId')
                    } else {
                        self.functionalStateListAdd(dat, 'barleyInList', 'barleyInDict', 'barleyInId')
                    }
                     // console.log("Modified city: ", change.doc.data());
                  }
                  if (change.type === "removed") {

                    var nextList = self.state['barleyInList'].filter((item, idx) => {       
                        return (item['barleyInId'] != change.doc.id)
                    })
            
                    var existingDict = self.state['barleyInDict']
                    delete existingDict[change.doc.id]
                    var retNext = {}
                    retNext['barleyInList'] = nextList 
                    retNext['barleyInDict'] = existingDict
                    self.setState(retNext)

                      //console.log("Removed city: ", change.doc.data());
                  }
                });
              });

            if (this.targetWarehouseValue != -1) {
                this.extraTransferBarleyOutListener = this.prepareProperCollectionReference('transferBarleyOut', 'warehouse.name', this.targetWarehouseValue).onSnapshot((snapshot) => {
                    snapshot.docChanges().forEach((change) => {
                      if (change.type === "added") {
                        var dat = change.doc.data()
                        dat['transferBarleyOutId'] = change.doc.id 
                        dat['id'] = change.doc.id
                        //console.log('here we are with an added doc')
                        //console.log(dat)
                        if (change.doc.id in self.state['transferBarleyOutDict']) {
                            self.functionalStateListUpdate(dat, 'transferBarleyOutList', 'transferBarleyOutId')
                        } else {
                            self.functionalStateListAdd(dat, 'transferBarleyOutList', 'transferBarleyOutDict', 'transferBarleyOutId')
                        }
                          //console.log(change.doc.id)
                          //console.log("New city: ", change.doc.data());
                      }
                      if (change.type === "modified") {
                        var dat = change.doc.data()
                        console.log('and change but request id')
                        dat['transferBarleyOutId'] = change.doc.id 
                        dat['id'] = change.doc.id
                        console.log('and now with a changed doc')
                        console.log(change.doc)
                        if (change.doc.id in self.state['transferBarleyOutDict']) {
                            self.functionalStateListUpdate(dat, 'transferBarleyOutList', 'transferBarleyOutId')
                        } else {
                            self.functionalStateListAdd(dat, 'transferBarleyOutList', 'transferBarleyOutDict', 'transferBarleyOutId')
                        }
                         // console.log("Modified city: ", change.doc.data());
                      }
                      if (change.type === "removed") {
    
                        var nextList = self.state['transferBarleyOutList'].filter((item, idx) => {       
                            return (item['transferBarleyOutId'] != change.doc.id)
                        })
                
                        var existingDict = self.state['transferBarleyOutDict']
                        delete existingDict[change.doc.id]
                        var retNext = {}
                        retNext['transferBarleyOutList'] = nextList 
                        retNext['transferBarleyOutDict'] = existingDict
                        self.setState(retNext)
    
                          //console.log("Removed city: ", change.doc.data());
                      }
                    });
                  });
            }

              this.transferBarleyOutListener = this.prepareProperCollectionReference('transferBarleyOut', 'source', this.targetWarehouseValue).onSnapshot((snapshot) => {
                snapshot.docChanges().forEach((change) => {
                  if (change.type === "added") {
                    var dat = change.doc.data()
                    dat['transferBarleyOutId'] = change.doc.id 
                    dat['id'] = change.doc.id
                    //console.log('here we are with an added doc')
                    //console.log(dat)
                    if (change.doc.id in self.state['transferBarleyOutDict']) {
                        self.functionalStateListUpdate(dat, 'transferBarleyOutList', 'transferBarleyOutId')
                    } else {
                        self.functionalStateListAdd(dat, 'transferBarleyOutList', 'transferBarleyOutDict', 'transferBarleyOutId')
                    }
                      //console.log(change.doc.id)
                      //console.log("New city: ", change.doc.data());
                  }
                  if (change.type === "modified") {
                    var dat = change.doc.data()
                    console.log('and change but request id')
                    dat['transferBarleyOutId'] = change.doc.id 
                    dat['id'] = change.doc.id
                    console.log('and now with a changed doc')
                    console.log(change.doc)
                    if (change.doc.id in self.state['transferBarleyOutDict']) {
                        self.functionalStateListUpdate(dat, 'transferBarleyOutList', 'transferBarleyOutId')
                    } else {
                        self.functionalStateListAdd(dat, 'transferBarleyOutList', 'transferBarleyOutDict', 'transferBarleyOutId')
                    }
                     // console.log("Modified city: ", change.doc.data());
                  }
                  if (change.type === "removed") {

                    var nextList = self.state['transferBarleyOutList'].filter((item, idx) => {       
                        return (item['transferBarleyOutId'] != change.doc.id)
                    })
            
                    var existingDict = self.state['transferBarleyOutDict']
                    delete existingDict[change.doc.id]
                    var retNext = {}
                    retNext['transferBarleyOutList'] = nextList 
                    retNext['transferBarleyOutDict'] = existingDict
                    self.setState(retNext)

                      //console.log("Removed city: ", change.doc.data());
                  }
                });
              });

            this.transferBarleyInListener = this.prepareProperCollectionReference('transferBarleyIn', 'warehouse.name', this.targetWarehouseSet).onSnapshot((snapshot) => {
                snapshot.docChanges().forEach((change) => {
                  if (change.type === "added") {
                    var dat = change.doc.data()
                    dat['transferBarleyInId'] = change.doc.id 
                    dat['id'] = change.doc.id
                    //console.log('here we are with an added doc')
                    //console.log(dat)
                    if (change.doc.id in self.state['transferBarleyInDict']) {
                        self.functionalStateListUpdate(dat, 'transferBarleyInList', 'transferBarleyInId')
                    } else {
                        self.functionalStateListAdd(dat, 'transferBarleyInList', 'transferBarleyInDict', 'transferBarleyInId')
                    }
                      //console.log(change.doc.id)
                      //console.log("New city: ", change.doc.data());
                  }
                  if (change.type === "modified") {
                    var dat = change.doc.data()
                    console.log('and change but request id')
                    dat['transferBarleyInId'] = change.doc.id 
                    dat['id'] = change.doc.id
                    console.log('and now with a changed doc')
                    console.log(change.doc)
                    if (change.doc.id in self.state['transferBarleyInDict']) {
                        self.functionalStateListUpdate(dat, 'transferBarleyInList', 'transferBarleyInId')
                    } else {
                        self.functionalStateListAdd(dat, 'transferBarleyInList', 'transferBarleyInDict', 'transferBarleyInId')
                    }
                     // console.log("Modified city: ", change.doc.data());
                  }
                  if (change.type === "removed") {

                    var nextList = self.state['transferBarleyInList'].filter((item, idx) => {       
                        return (item['transferBarleyInId'] != change.doc.id)
                    })
            
                    var existingDict = self.state['transferBarleyInDict']
                    delete existingDict[change.doc.id]
                    var retNext = {}
                    retNext['transferBarleyInList'] = nextList 
                    retNext['transferBarleyInDict'] = existingDict
                    self.setState(retNext)

                      //console.log("Removed city: ", change.doc.data());
                  }
                });
              });

            this.loadListDualRef('shipperRequestsByUid/' + Firebase.uid, 'shipperRequests', 'adminJobsList', 'adminJobsDict', 'quoteId')
            this.loadListDualRef('contractsByUser/' + Firebase.uid, 'longTermContracts', 'contractsList', 'contractsDict', 'contractId')
            this.loadListDualRef('freightOrdersByUid/' + Firebase.uid, 'shipperFreightOrders', 'freightOrdersListShipper', 'freightOrdersDictShipper', 'freightOrderId')
            this.loadListDualRef('invoicesByShipper/' + Firebase.uid, 'invoices', 'invoicesListShipper', 'invoicesDictShipper', 'invoiceId')
            Firebase.database.ref('webNotifications/' + Firebase.uid).on('value',  (val) => {
                if (val.exists()) {
                    self.setState({
                        webNotifications: val.val()
                    })
                } else {
                    self.setState({
                        webNotifications: {}
                    })
                }
            }, function (error) {

            })
        }

        this.loadingInterval = setInterval(function() {
            console.log(self.testStr)
            let curDat = new Date()
            let dif = curDat - self.lastFire
            console.log(curDat)
            console.log(self.lastFire)
            if ((dif) > 250) {
                if (self.parentReference != null) {
                    //console.log(' did load in time ' + dif)
                    //console.log(self.state)
                    self.allowParentUpdates = true 
                    self.parentReference.updateState(self.state)
                    clearInterval(self.loadingInterval)
                }
                //clearInterval(self.loadingInterval)
            } else {
                console.log('did not load in time because time was ' + dif )
            }
        }, 250)
    }

    async reset() {
        this.didInit = false 
        this.parentReference = null 
        Firebase.setUserProject('none')

        Object.keys(this.simpleListeners).map((key, id) => {
            let lst = this.simpleListeners[key]
            if (lst) {
                lst()
            }
        })

        if (this.barleyListener) {
            this.barleyListener()
        }
        if (this.fieldsListener) {
            this.fieldsListener()
        }
        if (this.customObjectListener) {
            this.customObjectListener()
        }
        if (this.barleyStocksListener) {
            this.barleyStocksListener()
        }
        if (this.transferBarleyOutListener) {
            this.transferBarleyOutListener()
        }
        if (this.transferBarleyInListener) {
            this.transferBarleyInListener()
        }
        if (this.barleyStocksPendingListener) {
            this.barleyStocksPendingListener()
        }
        if (this.barleyStocksTransitListener) {
            this.barleyStocksTransitListener()
        }
        if (this.transporterMappingListener) {
            this.transporterMappingListener()
        }
        if (this.extraBarleyOutListener) {
            this.extraBarleyOutListener()
        }
        if (this.extraTransferBarleyOutListener) {
            this.extraTransferBarleyOutListener()
        }
        if (this.onlineStatusDictListener) {
            this.onlineStatusDictListener()
        }
        if (this.connectedRef) {
            this.connectedRef.off('value')
        }
        if (this.userStatusDatabaseRef) {
            console.log('sendoffline')
            console.log({
                state: 'offline',
                last_changed: Firebase.databaseSimple.ServerValue.TIMESTAMP,
                warehouse: this.targetWarehouseValue
            })
            let a = await this.userStatusDatabaseRef.set({
                state: 'offline',
                last_changed: Firebase.databaseSimple.ServerValue.TIMESTAMP,
                warehouse: this.targetWarehouseValue
            }).then((d) => {
                console.log('did it yes')
                return null
            }).catch((e) => {
                console.log('error out failed')
                console.log(e)
                return null 
            })
            this.userStatusDatabaseRef.off('value')
        }
        if (this.userRoleListener) {
            this.userRoleListener()
        }
        Object.keys(this.customObjectListenerDict).map((item, idx) => {
            if (item && item in this.customObjectListenerDict) {
                
                this.customObjectListenerDict[item]()
            }
        })
        Object.keys(this.innerPlanListenerDict).map((item, idx) => {
            if (item && item in this.innerPlanListenerDict) {
                this.innerPlanListenerDict[item]()
            }
        })
        Object.keys(this.customObjectFieldListenerDict).map((item, idx) => {
            if (item && item in this.customObjectFieldListenerDict) {
                
                this.customObjectFieldListenerDict[item]()
            }
        })
        Object.keys(this.listeners).map((item, idx) => {
            this.listeners[item].off('value')
        })
        if (this.assoc) {
            console.log(this.state.userInfo.associationId)
            //var this.state.userInfo.associationId = this.state.userInfo.associationId
            //console.log(this.state.userInfo.associationId)
            var offlist = ['associationShipperRequests/' + this.state.userInfo.associationId, 'associationUsers/' + this.state.userInfo.associationId, 'associationVehicles/' + this.state.userInfo.associationId, 'associationLongTermContracts/' + this.state.userInfo.associationId]
            console.log(offlist)
            offlist.map((item, idx) => {
                console.log('try mapping right now for ')
                console.log(item)
                Firebase.database.ref(item).off('child_added')
                Firebase.database.ref(item).off('child_removed')
            })
            //Firebase.database.ref('shipperRequests').off('child_added')
            //Firebase.database.ref('shipperRequests').off('child_removed')
            Firebase.database.ref('webNotifications/admin').off('value')
        } else if (this.admin) {
            ['shipperRequests', 'users', 'vehicles', 'longTermContracts', 'associations', 'shipperFreightOrders', 'payments'].map((item, idx) => {
                Firebase.database.ref(item).off('child_added')
                Firebase.database.ref(item).off('child_removed')
            })
            //Firebase.database.ref('shipperRequests').off('child_added')
            //Firebase.database.ref('shipperRequests').off('child_removed')
            Firebase.database.ref('webNotifications/admin').off('value')
        } else {
            Firebase.database.ref('shipperRequestsByUid/' + Firebase.uid).off('child_added')
            Firebase.database.ref('shipperRequestsByUid/' + Firebase.uid).off('child_removed')
            Firebase.database.ref('contractsByUser/' + Firebase.uid).off('child_added')
            Firebase.database.ref('contractsByUser/' + Firebase.uid).off('child_removed')
            Firebase.database.ref('freightOrdersByUid/' + Firebase.uid).off('child_added')
            Firebase.database.ref('freightOrdersByUid/' + Firebase.uid).off('child_removed')
            Firebase.database.ref('invoicesByShipper/' + Firebase.uid).off('child_added')
            Firebase.database.ref('invoicesbyShipper/' + Firebase.uid).off('child_removed')
            Firebase.database.ref('webNotifications/' + Firebase.uid).off('value')
        }
        Firebase.database.ref('users/' + Firebase.uid).off('value')
    }

    renderUserName(v) {
        var rets = ''
        if ('firstName' in v) {
          rets += v.firstName
        } 
        if ('middleName' in v) {
          rets += ' ' + v.middleName
        } 
        if ('lastName' in v) {
          rets += ' ' + v.lastName
        }
        if ('phone' in v) {
            rets += ' (' + v.phone + ')'
        }
        return rets
      }

      padDigits(number) {
        return Array(Math.max(3 - String(number).length + 1, 0)).join(0) + number;
      }

      renderIdDisplay(customer) {

        var shortId = customer.shortId 
        var shipperSideId = null 
        if ('shortIdShipper' in customer) {
          shipperSideId = this.padDigits(customer.shortIdShipper)
        }
        var internalShipperId = null 
        if ('internalShipperId' in customer) {
          internalShipperId = customer.internalShipperId
        }
    
        if (shipperSideId == null) {
          return 'GAR-' + shortId 
        } else if (internalShipperId == null) {
          return 'GAR-' + shortId + ' (' + shipperSideId + ')'
        } else {
          return internalShipperId + '-' + shipperSideId 
        }
    
      }

    async functionalStateListAdd( v, listTarget, dictTarget, idTarget) {



        
        //console.log('try doing a functional update outer for ' + v[idTarget] )
        var prevState = this.state
        var temp = prevState[listTarget]
        var temp2 = prevState[dictTarget]
        /*console.log(v)
        console.log(listTarget)
        console.log(dictTarget)
        console.log(idTarget)
        console.log(temp)*/
        temp = temp.concat([v])
        //console.log(temp)
        if (idTarget == 'userId') {
            temp2[v[idTarget]] = this.renderUserName(v)
        } else if (idTarget == 'quoteId') {
            //alert(TableAssist.renderIdDisplay(v))
            temp2[v[idTarget]] = this.renderIdDisplay(v)
        } else if (idTarget == 'operationId' || idTarget == 'planId') {
            temp2[v[idTarget]] = v  
        } else {
            temp2[v[idTarget]] = 1
        }

        var retvar = {}
        this.state[listTarget] = temp 
        this.state[dictTarget] = temp2 

        /*console.log('now adding value now')
        console.log(listTarget)
        console.log(v)
        console.log(this.state[listTarget].length)*/

        if (this.allowParentUpdates) {
            if (this.parentReference != null) {
                //console.log('do send update!!!')
                this.parentReference.updateState(this.state)
            }
        }

        //return retvar//{ tableItems: temp, existingDict: temp2 }
    
}

async functionalStateListUpdate( v, listTarget, idTarget) {

        //console.log('try doing a functional update outer for ' + key )
        //this.setState(function (prevState, props) {
        var prevState = this.state 
        var temp = prevState[listTarget].map((item, idx) => {
        if (item[idTarget] != v[idTarget]) {
        return item
        }
        return v
        })

        
        this.state[listTarget] = temp 
        //return retvar//{ tableItems: temp }
        //})
        if (this.allowParentUpdates) {
            if (this.parentReference != null) {
                this.parentReference.updateState(this.state)
            }
        }

        
}


async listenToSingleItem(collection, document, listTarget, dictTarget, idTarget, ignoreFilter = false ) {
    //console.log('here is listen to SINGLE ITEM ' + document)
    let self = this 
    self.lastFire = new Date()
    //console.log('listen now to single ' + collection + '/' + document + self.lastFire)
    try {
       // console.log('actually try to listen now!!!')
        let listener = Firebase.firestore.collection('projects').doc(Firebase.userProject).collection(collection).doc(document).onSnapshot((doc, erro) => {
            //console.log('INSIDE LISTEN NOW!!!')
            //console.log(erro)
            if (erro) {
                console.log('there was an error!!!')
                console.log(erro)
            }
            //console.log('actually listening to single item!!')
            self.lastFire = new Date()
            //console.log(doc.exists)
            //console.log('does it exist')
            if (doc.exists) {
                var dats = doc.data()
                dats[idTarget] = doc.id 
                dats['id'] = doc.id
                if (document in self.state[dictTarget]) {
                    self.functionalStateListUpdate(dats, listTarget, idTarget)
                } else {
                    self.functionalStateListAdd(dats, listTarget, dictTarget, idTarget, ignoreFilter)
                }
            } else {
                // deleted
                var nextList = self.state[listTarget].filter((item, idx) => {       
                    return (item[idTarget] != document)
                })
        
                var existingDict = self.state[dictTarget]
                delete existingDict[document]
                var retNext = {}
                retNext[listTarget] = nextList 
                retNext[dictTarget] = existingDict
                self.setState(retNext)
            }
        });
        console.log('exit out from listen now???')

    this.simpleListeners[collection + '/' + document] = listener
    } catch(ee) {
        console.log('ERROR LISTENING!!')
        console.log(ee)
    }

}

async loadSingleItemByKey(secondary_target, secondary_key, listTarget, dictTarget, idTarget) {
        this.numCalls += 1
        //console.log(this.numCalls)
        if (this.numCalls > WAIT_THRESH) {
            await sleep(Math.floor(Math.random() * 200))
            
        }
        console.log('done sleeping')
        this.lastFire = new Date()
        let self = this
        //console.log(secondary_target + '/' + secondary_key)
        this.listeners[secondary_target + '/' + secondary_key] = Firebase.database.ref(secondary_target + '/' + secondary_key)
        //console.log('here is shipperRequests/' + secondary_key)
        this.listeners[secondary_target + '/' + secondary_key].on("value", async (inner_snapshot) => {
        if (inner_snapshot.exists()) {
            //alert('do exist')
        if (inner_snapshot.val()[idTarget] in self.state[dictTarget]) {
          //alert('this is an update')
        self.functionalStateListUpdate(inner_snapshot.val(), listTarget, idTarget)
        } else {
          //alert('this is an add')
            //console.log('performing add for ' + inner_snapshot.val()[idTarget])
        self.functionalStateListAdd(inner_snapshot.val(), listTarget, dictTarget, idTarget)
        }

        } else {
            //alert('noexist ' + secondary_target + '/' + secondary_key)
        //console.log('container found to not exist for ' + secondary_key)
        }
        }, function (error) {
        console.log('error fetching container for ' + secondary_key)
        console.log(error)
        })
}

loadListDualRef(firebase_primary_target, firebase_secondary_target, listTarget, dictTarget, idTarget) {
        // Maintain reference to main component for state updates
        let self = this
        console.log(firebase_primary_target)
        console.log(firebase_secondary_target)
        if (listTarget == 'transporterAssignedList') {
            //alert(firebase_primary_target)
        }
        let addlist = Firebase.database.ref(firebase_primary_target).on("child_added", async (inner_snapshot, prevkey) => {
           
        // Get key of secondary item
        if (listTarget == 'transporterAssignedList') {
            //alert(inner_snapshot.val())
        }
        if (inner_snapshot.exists()) {
        let secondary_key = inner_snapshot.key
        self.loadSingleItemByKey(firebase_secondary_target, secondary_key, listTarget, dictTarget, idTarget)
        } else {

        }
        //self.loadSingleItemByKey(firebase_secondary_target, secondary_key)

        }, function (errorObject) {
        if (self.state.report_errors) {
        alert(errorObject)
        }
        console.log("The read failed: " + errorObject.code);
        });

        Firebase.database.ref(firebase_primary_target).on("child_removed", (snapshot) => {
        let secondary_key = snapshot.key

        Firebase.database.ref(firebase_secondary_target + '/' + secondary_key).off("value")
        //alert(JSON.stringify(self.state[listTarget]))
        console.log('try to do remove fsilter operaationn for ' + firebase_secondary_target + '/' + secondary_key)

        var nextList = self.state[listTarget].filter((item, idx) => {
            console.log('filter comparison of ' + item[idTarget] + ' to ' + secondary_key)
            return (item[idTarget] != secondary_key)
        })

        var existingDict = self.state[dictTarget]
        delete existingDict[secondary_key]
        var retNext = {}
        retNext[listTarget] = nextList 
        retNext[dictTarget] = existingDict
        self.setState(retNext)

        }, function (errorObject) {
        if (self.state.report_errors) {
        alert(errorObject)
        }
        console.log("The read failed: " + errorObject.code);
        });
}

render() {

    if (this.admin) {
        return (    <MyContext.Provider value={{
            adminJobs: this.state.adminJobsList,
            jobsStateZero: this.state.adminJobsList.filter((item, idx) => {return item.state == 0}),
            jobsStateOne: this.state.adminJobsList.filter((item, idx) => {return item.state == 1}),
            jobsStateTwo: this.state.adminJobsList.filter((item, idx) => {return item.state == 2}),
            jobsStateThree: this.state.adminJobsList.filter((item, idx) => {return item.state == 3}),
            jobsStateFour: this.state.adminJobsList.filter((item, idx) => {return item.state == 4}),
            jobsStateFive: this.state.adminJobsList.filter((item, idx) => {return item.state == 5}),
            jobsStateSix: this.state.adminJobsList.filter((item, idx) => {return item.state == 6}),
            jobsStateSeven: this.state.adminJobsList.filter((item, idx) => {return item.state == 7}),
            jobsStateEight: this.state.adminJobsList.filter((item, idx) => {return item.state == 8}),
            jobsStateNine: this.state.adminJobsList.filter((item, idx) => {return item.state == 9}),
            users: this.state.usersList,
            vehicles: this.state.vehiclesList,
            webNotifications: this.state.webNotifications,
            isAdmin: true,
            adminContracts: this.state.contractsAdminList,
            userInfo: this.state.userInfo
        }}>
            {this.props.children}
        </MyContext.Provider>)
    } else {
        return (    <MyContext.Provider value={{
            adminJobs: this.state.adminJobsList,
            jobsStateQuote: this.state.adminJobsList.filter((item, idx) => {return item.state == 0}),
            jobsStateOffer: this.state.adminJobsList.filter((item, idx) => {return item.state == 1 || item.state == -1}),
            jobsStateScheduled: this.state.adminJobsList.filter((item, idx) => {return item.state > 1 && item.state < 7}),
            jobsStateRoute: this.state.adminJobsList.filter((item, idx) => {return item.state > 6 && item.state < 9}),
            jobsStateCompleted: this.state.adminJobsList.filter((item, idx) => {return item.state >= 9}),
            webNotifications: this.state.webNotifications,
            contracts: this.state.contractsList,
            isAdmin: false,
            userInfo: this.state.userInfo
        }}>
            {this.props.children}
        </MyContext.Provider>)
    }

   

}

}