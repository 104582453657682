import React, { useState, useRef, useEffect } from 'react';
import {useNavigate, useLocation } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Avatar,
  Box,
  Card,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  makeStyles,
  Button,
  Link,
  MenuItem,
} from '@material-ui/core';
import { TextField } from "@mui/material";
import Page from 'src/components/Page';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import getInitials from 'src/utils/getInitials';
import {X} from 'react-feather';
import HeaderComponent from 'src/utils/HeaderComponent';
import SearchWrapper from 'src/utils/SearchWrapper';
import TableAssist from 'src/utils/TableAssist';
import Firebase from 'src/Firebase'
import MyContext from 'src/MyContext';
import AddBarleyFieldDialog from 'src/utils/AddBarleyFieldDialog';
import { DataGrid, GridToolbar, GridToolbarContainer, GridToolbarColumnsButton, GridToolbarFilterButton, GridToolbarDensitySelector, GridToolbarExport } from '@mui/x-data-grid';
import AuthDialog from './AuthDialog'
import AddRowDialog from './AddRowDialog';

const filter = createFilterOptions();

function CustomToolbar(props) {

  if (props.allowExport) {
    return <GridToolbar/>
  } else {
    return (
      <GridToolbarContainer >
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector/>
      </GridToolbarContainer>
    );
  }

}

const movies = [
  {
    id: 1,
    movie: "HP"
  },
  {
    id: 2,
    movie: "Matrix"
  },
  {
    id: 3,
    movie: "LOTR"
  }
];

var columns = [
  { field: 'id', headerName: 'ID', width: 90 },
  {
    field: 'firstName',
    headerName: 'First name',
    width: 150,
    editable: true,
  },
  {
    field: 'lastName',
    headerName: 'Last name',
    width: 150,
    editable: true,
  },
  {
    field: 'age',
    headerName: 'Age',
    type: 'number',
    width: 110,
    editable: true,
  },
  {field: 'date',
headerName: 'Date',
type: 'date',
width: 150,
editable: true},
  {
    field: 'fullName',
    headerName: 'Full name',
    description: 'This column has a value getter and is not sortable.',
    sortable: false,
    width: 160,
    valueGetter: (params) =>
      `${params.getValue(params.id, 'firstName') || ''} ${
        params.getValue(params.id, 'lastName') || ''
      }`,
  },
];

var rows = [
  { id: 1, lastName: 'Snow', firstName: 'Jon', age: 35 },
  { id: 2, lastName: 'Lannister', firstName: 'Cersei', age: 42 },
  { id: 3, lastName: 'Lannister', firstName: 'Jaime', age: 45 },
  { id: 4, lastName: 'Stark', firstName: 'Arya', age: 16 },
  { id: 5, lastName: 'Targaryen', firstName: 'Daenerys', age: null, date: Date.now() },
  { id: 6, lastName: 'Melisandre', firstName: null, age: 150 },
  { id: 7, lastName: 'Clifford', firstName: 'Ferrara', age: 44 },
  { id: 8, lastName: 'Frances', firstName: 'Rossini', age: 36 },
  { id: 9, lastName: 'Roxie', firstName: 'Harvey', age: 65 },
];


const useStyles = makeStyles((theme) => ({
  root: {},
  avatar: {
    marginRight: theme.spacing(2)
  }
}));


/*
          renderEditCell: (params) => {              
<Autocomplete
                id={params.id}
                options={movies}
                style={{height: 200}}
                autoHighlight
                getOptionLabel={(option) => option.movie}
                value={params.getValue(params.id, "movie")}
                renderInput={(params) => <TextField {...params} style={{ width: 150, height: 150}}  autoFocus  />}
              />
          }
*/



const SubPlanTable = ({ className, customKey, objectFields, objectList, barleyInCustomObjects, context, workflow, customFieldsKey, stateProp, ...rest }) => {
  const classes = useStyles();
  const [selectedCustomerIds, setSelectedCustomerIds] = useState([]);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);
  const {state} = useLocation();
  const [text_filters, set_text_filters] = useState([]);
  const [sortOrder, setSortOrder] = useState('creationDate');
  const [sortDirection, setSortDirection] = useState(false);
  const [addColumnDialog, setAddColumnDialog] = useState(false);
  const [rows, setRows] = useState(objectList);
  const [selection, setSelection] = useState([]);
  const [passwordDialog, setPasswordDialog] = React.useState(false);
  const [addRowDialog, setAddRowDialog] = React.useState(false);
  const [targetName, setTargetName] = React.useState('')
  const [targetId, setTargetId] = React.useState('')
  const docDiaRef = useRef(null);

  const navigate = useNavigate();
  const [sortModel, setSortModel] = React.useState([
    {
      field: 'creationDate',
      sort: 'desc',
    },
  ]);


  function getAutoOptions(barleyInCustomObjects, k) {
    //console.log('check now ' + k)
    //console.log(barleyInCustomObjects)
    if (k in barleyInCustomObjects) {
      //console.log('fucking yes')
      //console.log(barleyInCustomObjects[k])
      let opts =  Object.keys(barleyInCustomObjects[k]).map((key, id) => {
        return barleyInCustomObjects[k][key]
      })
      if (k == 'sku') {
        return opts.filter((item, idx) => {
          return item && stateProp && stateProp.stateVal && stateProp.stateVal.product && item.product && (item.product.name == stateProp.stateVal.product.name)
        })
      } else {
        return opts
      }

    }
    //console.log('fucking no')
    //console.log(barleyInCustomObjects[k])
    return []
  }


  function doApiUpdate(api, id, field, val, event) {
    console.log('incoming update with ')
    console.log(val)
    if (!api) {
      return
    }
    api.setEditCellValue({ id, field, value: val }, event);
    // Check if the event is not from the keyboard
    // https://github.com/facebook/react/issues/7407
    if (event.nativeEvent.clientX !== 0 && event.nativeEvent.clientY !== 0) {
      api.commitCellChange({ id, field });
      api.setCellMode(id, field, 'view');
    }
  }

  function compare2(a,b) {
    if (customKey == 'supplier') {
      let as = a.sortNum ? a.sortNum : -1
      let bs = b.sortNum ? b.sortNum : -1
  
      if (as > bs) {
        return -1
      } else if (bs > as) {
        return 1
      } else {
        return 0
      }
    }

    if (customKey == 'warehouse') {
      if (a.fieldKey == 'name') {
        return -1
      } else if (b.fieldKey != 'name' && (a.fieldKey == 'locationid' )) {
        return -1
      } {
        return 0
      }
    }

  }


  function dateValueGetter(params) {
    return params.value ? moment(params.value).format('DD/MM/YYYY HH:mm') : ''
  }

  function renderColumns(fields, wh, context) {
    var f = fields 
    if (customKey == 'supplier' || customKey == 'warehouse') {
      f.sort((a,b) => compare2(a,b))
    }

    if (customKey == 'plans') {
      f = f.filter((item, idx) => {
        return item.fieldKey != 'allocations'
      })
    }

    let resA =  f.map((item, idx) => {


      if (item.fieldKey == 'union') {
        return ({field: item.fieldKey, headerName: item.fieldName, width: item.width ? item.width : 150, editable: false,
  type: item.fieldType != 'text' ? item.fieldType : null,
  valueGetter: (params) => {

    if (params.row['warehouse']) {
      return params.row['warehouse'].union
    } else {
      return ''//'xx ' + params.row['warehouse']
    }

  } })
}


      if (item.fieldType == 'custom') {
        return {field: item.fieldKey, headerName: item.fieldName, width: item.width ? item.width : 150, editable: 'editable' in item && context.warehouseRole == 'Admin' ? item.editable : false,
           valueGetter: ({ value }) => value && value.name ? value.name : '',
          sortComparator: (v1, v2, param1, param2) =>{
            console.log('xfa')
            console.log(v1)
            console.log(v2)
            console.log(param1)
            console.log(param2)
            return 1
          },
          renderEditCell: (params) => {              
            return  <Autocomplete
            value={params.value}
            onChange={(event, newValue) => {
              console.log(newValue)
              console.log(params)
              console.log(typeof newValue)
              //console.log(newValue.inputValue)
              if (typeof newValue === 'string') {

                //setFieldValue(fields[fieldIdx].fieldKey, {'customId': newValue.customId, 'name': newValue.name})
                let vx = item.fieldKey == 'warehouse' ? {'customId': newValue.customId, 'name': newValue.name, 'union': newValue.union} : {'customId': newValue.customId, 'name': newValue.name}
                doApiUpdate(params.api, params.id, item.fieldKey, vx, event)

              } else if (newValue && newValue.inputValue) {
                // Create a new value from the user input

                //setFieldValue(fields[fieldIdx].fieldKey, {'customId':-1, 'name': newValue.inputValue})
                doApiUpdate(params.api, params.id, item.fieldKey, {'customId': -1, 'name': newValue.inputValue}, event)
              } else {

                if (newValue == null) {
                  //setFieldValue(fields[fieldIdx].fieldKey, {'customId': '0', 'name': 'None'})
                  doApiUpdate(params.api, params.id, item.fieldKey, {'customId': '0', 'name': 'None'}, event)
                } else {
                  let vx = item.fieldKey == 'warehouse' ? {'customId': newValue.customId, 'name': newValue.name, 'union': newValue.union} : {'customId': newValue.customId, 'name': newValue.name}
                  doApiUpdate(params.api, params.id, item.fieldKey, vx, event)
                  //setFieldValue(fields[fieldIdx].fieldKey, {'customId': newValue.customId, 'name': newValue.inputValue})
                }
              }
              //console.log(event.target.value)
              //console.log(newValue)
            }}
            filterOptions={(options, params) => {
              const filtered = filter(options, params);
      
              // Suggest the creation of a new value
              if (params.inputValue !== '') {
                filtered.push({
                  inputValue: params.inputValue,
                  name: `Add "${params.inputValue}"`,
                });
              }
      
              return filtered;
            }}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            id="free-solo-with-text-demo"
            options={getAutoOptions(barleyInCustomObjects, item.fieldKey)}
            getOptionLabel={(option) => {
              // Value selected with enter, right from the input
              if (typeof option === 'string') {
                return option;
              }
              // Add "xxx" option created dynamically
              if (option.inputValue) {
                return option.inputValue;
              }
              // Regular option
              return option.name;
            }}
            renderOption={(option) => item.fieldKey == 'warehouse' ? option.name + ' / ' + option.union : option.name}
            freeSolo
            renderInput={(params) => <TextField {...params} style={{ width: 150, height: 150}}  autoFocus  />}
          />}
          
                      
        }
      } else if (item.fieldType == 'list') {
        return {field: item.fieldKey, headerName: item.fieldName, width: item.width ? item.width : 150, editable: 'editable' in item && (context.warehouseRole == 'Admin' || (customKey == 'supplier' && context.warehouseRole == 'Finance')) ? item.editable : false,
          type: 'singleSelect', valueOptions: item.listOptions                      
        }
      } else if (item.fieldKey == 'bolFile' ) {
        return ({field: item.fieldKey, headerName: item.fieldName, width: item.width ? item.width : 150, editable: false,
        type: item.fieldType != 'text' ? item.fieldType : null,
        renderCell: (params) => {
          if (!params.row['bolFile']) {
            return null 
          }
          //if (params.row['gdnlink'] && params.row['gdnlink'].length) {
            return   <Link
            to={params.value}
            onClick={ async () => {
              let b = await Firebase.getSignedUrl({'bucket': 'BOL-', 'file': params.row['bolFile']}) 
              if (b && b.length && b.length > 0) {
                window.open(b[0])
              } else {
                alert('Error retrieving file.')
              }
            }/*window.open(params.row['gdnlink'])*/}
            variant="h6"
          >{'View BoL'}</Link>
          /*} else {
            return params.value 
          }*/
        }, 
      })
      }

      if (item.fieldKey == 'edit') {
        return ({field: item.fieldKey, headerName: item.fieldName, width: item.width ? item.width : 150, editable: 'editable' in item && context.warehouseRole == 'Admin' ? item.editable : false,
        type: item.fieldType != 'text' ? item.fieldType : null, renderCell: (params) => {
          if (params.row['noreset']) {
            return ''
          }
          return <Button
          color="primary"
          variant="contained"
          onClick={ () => navigate('/app/edituser', {state: {fields: objectFields, stateVal: params.row}})}
        >
         Edit User
        </Button>
        } })
      }

      if (item.fieldKey == 'password') {
        return ({field: item.fieldKey, headerName: item.fieldName, width: item.width ? item.width : 150, editable: 'editable' in item && context.warehouseRole == 'Admin' ? item.editable : false,
        type: item.fieldType != 'text' ? item.fieldType : null, renderCell: (params) => {
          if (params.row['noreset']) {
            return ''
          }
          return <Button
          color="primary"
          variant="contained"
          onClick={ () => {
            setTargetName(params.row['name'])
            setTargetId(params.id)
            setPasswordDialog(true)
          }}
        >
         Reset Password
        </Button>
        } })

      }
      if (item.fieldType == 'date') {
        return ({field: item.fieldKey, headerName: item.fieldName, width: item.width ? item.width : 150, editable: false,
          type: item.fieldType != 'text' ? item.fieldType : null, valueFormatter: (params) => {
            return params.value ? moment(params.value).format('DD/MM/YYYY HH:mm') : ''
          }})
      }

      if (item.fieldKey == 'receivedqty') {
        return ({field: item.fieldKey, headerName: item.fieldName, width: item.width ? item.width : 150, editable: 'editable' in item && (context.warehouseRole == 'Admin' || (customKey == 'supplier' && context.warehouseRole == 'Finance')) ? item.editable : false,
        type: item.fieldType != 'text' ? item.fieldType : null,       valueGetter: (params) => {
          if (params.row['receivedqty'] ) {
            return (params.row['receivedqty'] / 100).toFixed(2)
          } 
          return null 
  
        }})
      }
      return ({field: item.fieldKey, headerName: item.fieldName, width: item.width ? item.width : 150, editable: 'editable' in item && (context.warehouseRole == 'Admin' || (customKey == 'supplier' && context.warehouseRole == 'Finance')) ? item.editable : false,
      type: item.fieldType != 'text' ? item.fieldType : null, })
    })


    if (customKey == 'plans' || customKey == 'trains') {
     
      var whRes = wh.map((item, idx) => {
        return {field: item.customId, headerName: '(Alloc) ' +  item.name, width:150, editable: false, type: 'text',
      valueGetter: (params) => {
        if (params.row['allocations'] && params.row['allocations'][item.customId]) {
          return params.row['allocations'] && params.row['allocations'][item.customId]
        } 
        return null 

      }}
      })

      return whRes.concat(resA)
    } else {
      return resA 
    }
  }


  async function tryNewColumn(dat) {


    let result = await Firebase.requestNewColumn({'project': Firebase.userProject, 'dat': dat, 'fieldsCollection': workflow ? customFieldsKey : "barleyInCustomObjects/fields/" + customKey})
    console.log(result)
    if (!result) {
      alert('This field already exists.')
    }
  }

 


  async function updateRow(id, updateVal, field) {

    var docRef = Firebase.firestore.collection('projects').doc(Firebase.userProject).collection('plansInner').doc(customKey).collection('contents').doc(id)
    await docRef.update(updateVal)
    return true

    /*let transaction = await Firebase.updateRowGeneric({'id': id, 'updateVal': updateVal, 'field': field, 'collectionKey': workflow ? customKey : "barleyInCustomObjects/contents/" + customKey, 'project': Firebase.userProject})
    console.log('transaction')
    console.log(transaction)
    if (transaction && transaction.transaction) {
      return true 
    } else if (transaction && transaction.failureMessage) {
      alert(transaction.failureMessage)
    }
    return false */


  }

  async function onCellChange(value, objectFields)  {
    // Get the row
    console.log('osc')
    console.log(value)
    console.log(value.field)
    console.log(value.value)
    if (value !== 0 && (!value.value || value.value == 'undefined')) {
      console.log('retnowundef')
      return
    }
    //console.log(value)

    const rowIndex = rows.findIndex(row => row.id === value.id);
    const fieldIndex = objectFields.findIndex(item => value.field === item.fieldKey)

    if (rowIndex >= 0 && fieldIndex >= 0) {
      const row = rows[rowIndex];
      const field = objectFields[fieldIndex]
      // Validate if changed
      
      if (!(value.field in row) || (value.field in row && row[value.field] !== value.value)) {
        const data = { [value.field]: value.value };
        console.log('did change, here is data')
        console.log(data)
        console.log(row.id)



        if (value.field == 'sku' || value.field == 'warehouse') {
          if (typeof(value.value) == 'string') {
            // exit early to prevent custom bug
            console.log('EXITEARLY')
            const newRows = [...rows];

          
            newRows[rowIndex][value.field] = row[value.field];

             setRows(newRows);
             return
          }
        }
        // Sending to API
        const newRows = [...rows];
        //await prepUpdate(row, value.field, value.value)
        var ret = null 

          ret = await updateRow(row.id, data, field)
        console.log('result of ret is as follows')
        console.log(ret)
        if (ret) {
          newRows[rowIndex][value.field] = value.value;
        } else {
          console.log('oldset')
          console.log('ent val is')
          console.log(row[value.field])
          if (value.field in row) {
            newRows[rowIndex][value.field] = row[value.field];
          } else {
            delete newRows[rowIndex][value.field]
          }

        }
        console.log(newRows)
        setRows(newRows);
        /*Api.product.update(data).then(res => {
          const newRows = [...rows];

          if (res.success) {
            // Change to new value
            newRows[rowIndex][value.field] = value.value;
          } else {
            // Change to old value
            newRows[rowIndex][value.field] = row[value.field];
          }

          setRows(newRows);
        });*/
      } else {
        console.log(rows)
        console.log('no change')
      }
    }
  };

  async function updatePass(x) {
    if (!x || x.length < 8) {
      alert('Invalid password option.')
      return 
    }

    await Firebase.warehouseEditUser({'userId': targetId, 'updateBody': {'password': x}, 'project': Firebase.userProject} )
    alert('Updated password!')
  }

  function hasCreationField(r) {
    if (customKey == 'plans' || customKey == 'operations' || customKey == 'trains') {
      var f = false 
      r.map((item, idx) => {
        if (item.fieldKey == 'creationDate') {
          f = true 
        }
      })
  
      return f
    }
    return true

  }

  async function addRow(vbase) {

    let tempResRef = await Firebase.firestore.collection('projects').doc(Firebase.userProject).collection("plansInner").doc(customKey).get()
    let tempRes = tempResRef.data()
    //alert(JSON.stringify(tempRes))
    var vsend = vbase 
    vsend['creationDate'] = Date.now()

    //alert(tempRes)
    if (tempRes) {
      var docRef = Firebase.firestore.collection('projects').doc(Firebase.userProject).collection('plansInner').doc(customKey).collection('contents').doc()
      var id = docRef.id 
      vsend['id'] = id 
      vsend['customId'] = id
      await docRef.set(vsend)
      Firebase.trackMixpanel('addPlanRowOnline', {'result': id})
    } else {
      await Firebase.firestore.collection('projects').doc(Firebase.userProject).collection("plansInner").doc(customKey).set({'base': 1})
      var docRef = Firebase.firestore.collection('projects').doc(Firebase.userProject).collection('plansInner').doc(customKey).collection('contents').doc()
      var id = docRef.id 
      vsend['id'] = id 
      vsend['customId'] = id
      await docRef.set(vsend)
      Firebase.trackMixpanel('addPlanRowOnline', {'result': id})
    }



  }

  async function deleteRow() {
    if (selection.length != 1) {
      alert('Select a single row to delete.')
      return 
    } else {
      var r = null 
      rows.map((row, idx) => {
        if (row.id == selection[0]) {
          r = row
        }
      })
      
      if (r != null) {
        var docRef = Firebase.firestore.collection('projects').doc(Firebase.userProject).collection('plansInner').doc(customKey).collection('contents').doc(r.id)
        await docRef.delete()
        Firebase.trackMixpanel('deletePlanRowOnline', {'result': r})
      }
    }
  }


  useEffect(() => {
    setRows(objectList);
  }, [objectList]);



  return (
    <MyContext.Consumer>{context => (
    <div>
        <Page
    className={classes.root}
    height='100%'
    title={stateProp && stateProp.stateVal && stateProp.stateVal.product ? 'Plan: ' + stateProp.stateVal.name 
    + ' (' + stateProp.stateVal.product.name + ')' : 'Plan'}
    id="ff2"
    backgroundColor={classes.root.backgroundColor}
  >
                            <AddRowDialog
            context={context}
           
        open={addRowDialog}
        onClose={() => setAddRowDialog(false)}
        addRow={(v) => addRow(v)}
        alertTitle={'Add Row'}
        alertCancelText={'Cancel'}
        alertConfirmText={'Confirm'}
        product={stateProp && stateProp.stateVal ? stateProp.stateVal.product : null}
        onConfirm={(x) => setAddRowDialog(false)}
        />
      {customKey == 'warehousesoftwareusers'
      ? 
      <AuthDialog
  open={passwordDialog}
  targetName={targetName}
  onClose={() => setPasswordDialog(false)}
  alertTitle={'Approve Request'}
  alertCancelText={'Cancel'}
  alertConfirmText={'Confirm'}
  onConfirm={(x) => {
    updatePass(x.password)
  }}
  />
      : null
      }
        <AddBarleyFieldDialog
        open={addColumnDialog}
        ref={docDiaRef}
        onClose={() => setAddColumnDialog(false)}
        alertTitle={'Add A New Field'}
        alertCancelText={'Cancel'}
        alertConfirmText={'Confirm'}
        onConfirm={(x) => tryNewColumn(x)}
        />

<div style={{flexDirection: 'row', display: 'flex', justifyContent: 'space-between', marginBottom: 5}}>


        {true ?
        <Button
        color="primary"
        variant="contained"
        onClick={ () => {
         setAddRowDialog(true)/*(addRow()*/
        }}
      >
       Add Row
      </Button>
      : null}

        {stateProp && stateProp.stateVal && stateProp.stateVal.product ? 
              <Typography variant={'h2'} style={{marginRight: 10}}>{'Plan: ' + stateProp.stateVal.name 
              + ' (' + stateProp.stateVal.product.name + ')'}</Typography>
      : null}

{true ?
        <Button
        color="primary"
        variant="contained"
        onClick={ () => {
          deleteRow()
        }}
      >
       Delete Row
      </Button>
      : null}





      </div>
      {hasCreationField(objectFields) ? 
          <div style={{height: 550, width: '99%'}}>

              <DataGrid
              style={{backgroundColor: 'white'}}
              density='compact'
          rows={rows}
          columns={renderColumns(objectFields, getAutoOptions(context.barleyInCustomObjects, 'warehouse'), context)}
          pageSize={20}
          rowsPerPageOptions={[20]}
          onCellEditCommit={(p, e, d) => onCellChange(p, objectFields)}
          hideFooterPagination={false}
          hideFooter={false}
          checkboxSelection
          disableSelectionOnClick
          onSelectionModelChange={(newSelection) => {
            setSelection(newSelection);
          }}
          components={{
            Toolbar: CustomToolbar,
          }}
          componentsProps={{ toolbar: { allowExport: context.warehouseRole == 'Admin' || context.warehouseRole == 'Finance' } }}
        />
 

      </div>
      : null }
      </Page>
    </div>
    )}</MyContext.Consumer>
  )
};

SubPlanTable.propTypes = {
  className: PropTypes.string,
  customers: PropTypes.array.isRequired
};

export default SubPlanTable;
