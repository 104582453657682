import React, {useState} from 'react';
import { Link as RouterLink, useNavigate} from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import Autocomplete from '@material-ui/lab/Autocomplete';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import {
  Box,
  Button,
  Checkbox,
  Container,
  FormHelperText,
  Link,
  TextField,
  Typography,
  makeStyles,
  Grid,
  Select,
  MenuItem,
  InputLabel,
  CircularProgress
} from '@material-ui/core';
import Page from 'src/components/Page';
import parse from 'autosuggest-highlight/parse';
import throttle from 'lodash/throttle';
import Firebase from 'src/Firebase'
import DataFetcher from 'src/DataFetcher'
import JobMasterView from 'src/utils/JobMasterView';


const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/



const autocompleteService = { current: null };
const geocoder = {current: null};



const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  }
}));


const NewQuoteView = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  const [value, setValue] = React.useState(null);
  const [valueTwo, setValueTwo] = React.useState(null);
  const [inputValue, setInputValue] = React.useState('');
  const [options, setOptions] = React.useState([]);
  const [optionsTwo, setOptionsTwo] = React.useState([]);
  const [numVal, setNumVal] = React.useState(false);
  const [actions, setActions] = React.useState(null);
  const [locationStart, setLocationStart] = React.useState(null);
  const [locationEnd, setLocationEnd] = React.useState(null);
  const [finalSet, setFinalSet] = React.useState(null);
  const [tableItems, setTableItems] = React.useState(DataFetcher.cargoTypeData);
  const [isAuthenticating, setIsAuthenticating] = React.useState(false);
  //alert(JSON.stringify(DataFetcher.cargoTypeData))
  const loaded = React.useRef(false);
  /*if (typeof window !== 'undefined' && !loaded.current) {
    if (!document.querySelector('#google-maps')) {
      loadScript(
        'https://maps.googleapis.com/maps/api/js?key=AIzaSyAkPLP3zHG-2kctVakLZwdUQHqeLVO0Onw&libraries=places',
        document.querySelector('head'),
        'google-maps',
      );
    }

    loaded.current = true;
  }*/

  function doSubmit(values, locStart, locEnd) {
    setIsAuthenticating(true);
    console.log(values)
    console.log(Firebase.userInfo)

    var v = values 
    v['shipperName'] = Firebase.userInfo.companyName
    v['userPhone'] = Firebase.userInfo.phone
    let suc = function () {
      setIsAuthenticating(false)
      navigate(-1)
    }

    let f  = function () {
      setIsAuthenticating(false)
      alert('Error saving changes. Check internet connection and try again.')
    }
    Firebase.newQuoteAuth(v, locStart, locEnd, Firebase.uid, suc, f)
    //navigate(-1)
  }

  React.useEffect(() => {
    


  }, []);

  if (isAuthenticating) {
    return (<Page
      className={classes.root}
      title="Request Quote"
      backgroundColor={classes.root.backgroundColor}
    ><div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}><CircularProgress/></div></Page>)
  }

  return (<JobMasterView editQuoteShipper={false} provideQuote={false} privateQuote={true} publicQuote={false} quoteOffer={false} evaluateBid={false} parentSubmit={(v, ls, le) => doSubmit(v, ls, le)} parentState={{}} jobProps={{editable: true, priceEditable: false, showPrices: false, showAssignments: false}} />)

  
};

export default NewQuoteView;
