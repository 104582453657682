import React, {useState, useReducer} from 'react';
import { Link as RouterLink, useNavigate, useLocation} from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';

import LocationOnIcon from '@material-ui/icons/LocationOn';
import {
  Box,
  Button,
  Checkbox,
  Container,
  FormHelperText,
  Link,
  TextField,
  Typography,
  makeStyles,
  Grid,
  Select,
  MenuItem,
  Stepper,
  StepLabel,
  Step,
  CircularProgress
} from '@material-ui/core';
import Page from 'src/components/Page';
import parse from 'autosuggest-highlight/parse';
import {X as XIcon} from 'react-feather';
import throttle from 'lodash/throttle';
import Firebase from 'src/Firebase'
import MuiPhoneNumber from 'material-ui-phone-number'
import { parseWithOptions } from 'date-fns/fp';
import MyContext from 'src/MyContext';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { arrayOf } from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';


const filter = createFilterOptions();
const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
/*

Traveler // Variety purity >=90% // Moisture =<15%
Traveler //  Variety purity <90% // Moisture =<15%
Traveler // Variety all purity // Moisture >15%
Fatima // Variety purity >=90% // Moisture =<15%
Fatima // Variety purity <90% // Moisture =<15%
Fatima //  Variety all purity // Moisture >15%
Planet // Variety purity >=90% // Moisture =<15%
Planet // Variety purity <90% // Moisture =<15%
Planet //  Variety all purity // Moisture >15%
All other varieties // Variety all purity // Moisture =<15%
All other varieties // Variety all purity // Moisture >15%
*/


const outOptions = [
{'name': 'Traveler // Variety purity >=90% // Moisture =<15%', 'customId': 0, 'query': {'variety': 'Traveler', 'moisture': 'below', 'purity': 'above', 'uncategorized': false}},
{'name': 'Traveler //  Variety purity <90% // Moisture =<15%', 'customId': 1, 'query': {'variety': 'Traveler', 'moisture': 'below', 'purity': 'below', 'uncategorized': false}},
{'name': 'Traveler // Variety all purity // Moisture >15%', 'customId': 2, 'query': {'variety': 'Traveler', 'moisture': 'above', 'uncategorized': false}},
{'name': 'Fatima // Variety purity >=90% // Moisture =<15%', 'customId': 3, 'query': {'variety': 'Fatima', 'moisture': 'below', 'purity': 'above', 'uncategorized': false}},
{'name': 'Fatima // Variety purity <90% // Moisture =<15%', 'customId': 4, 'query': {'variety': 'Fatima', 'moisture': 'below', 'purity': 'below', 'uncategorized': false}},
{'name': 'Fatima //  Variety all purity // Moisture >15%', 'customId': 5, 'query': {'variety': 'Fatima', 'moisture': 'above', 'uncategorized': false}},
{'name': 'Planet // Variety purity >=90% // Moisture =<15%', 'customId': 6, 'query': {'variety': 'Planet', 'moisture': 'below', 'purity': 'above', 'uncategorized': false}},
{'name': 'Planet // Variety purity <90% // Moisture =<15%', 'customId': 7, 'query':  {'variety': 'Planet', 'moisture': 'below', 'purity': 'below', 'uncategorized': false}},
{'name': 'Planet //  Variety all purity // Moisture >15%', 'customId': 8, 'query': {'variety': 'Planet', 'moisture': 'above', 'uncategorized': false}},
{'name': 'All other varieties // Variety all purity // Moisture =<15%', 'customId': 9, 'query': {'variety': 'other', 'moisture': 'below', 'uncategorized': false}},
{'name': 'All other varieties // Variety all purity // Moisture >15%', 'customId': 10, 'query': {'variety': 'other', 'moisture': 'above', 'uncategorized': false}},
{'name': 'Uncategorized', 'customId': 11, 'query': {'uncategorized': true}}]
const autocompleteService = { current: null };
const geocoder = {current: null};

const specOptions = {'numberofbags': 1, 'averagebagweight': 1}

const sharedKeys = ['drivername', 'driverphone', 'platenumber', 'transportservice', 'weighbridge']

const steps = ['Basic Details', 'Cargo Details'];
const stepFields = {
  0: [],
  1: ['numberofbags', 'averagebagweight', 'inspectedby', 'wagonnumber', 'laborergroup'],
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  }
}));


const NewBarleyOutView = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const {state} = useLocation();
  const [loading, setLoading] = React.useState(false);
  const [autoValues, setAutoValues] = React.useState({});
  const [source, setSource] = React.useState(null);
  const [category, setCategory] = React.useState(null);
  const [sku, setSku] = React.useState(null);
  const [destination, setDestination] = React.useState(null);
  const [amount, setAmount] = React.useState(null);
  const [transferOut, setTransferOut] = React.useState(null);
  const [activeStep, setActiveStep] = React.useState(0);
  const [arrivalType, setArrivalType] = React.useState('Train')
  const [train, setTrain] = React.useState(null);
  const [isFinal, setIsFinal] = React.useState(false);
  const [operation, setOperation] = React.useState(null)
  const [laborers, setLaborers] = React.useState([{'name': '', 'qty': ''}])
  const [ignored, forceUpdate] = useReducer(x => x + 1, 0);
 



  const handleNext = () => {


    setActiveStep((prevActiveStep) => prevActiveStep + 1);

  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  
  function determineStockMembership(val) {

    /**
     *  1. Traveler // Variety purity >=90% // Moisture =<15%
        2. Traveler //  Variety purity <90% // Moisture =<15%
        3. Traveler // Variety all purity // Moisture >15%
        4. Fatima // Variety purity >=90% // Moisture =<15%
        5. Fatima // Variety purity <90% // Moisture =<15%
        6. Fatima //  Variety all purity // Moisture >15%
        7. Planet // Variety purity >=90% // Moisture =<15%
        8. Planet // Variety purity <90% // Moisture =<15%
        9. Planet //  Variety all purity // Moisture >15%
        10. All other varieties // Variety all purity // Moisture =<15%
        11. All other varieties // Variety all purity // Moisture >15%
     */
    var warehouse = 'None'
    if (val.warehouse && val.warehouse.name) {
      warehouse = val.warehouse.name
    }

    var uncategorized = false 
    
    if (!val.moisture || !val.variety) {
      // if moisture or variety is not defined, we are done, we just need uncategorized to be true
      uncategorized = true 
      return {'uncategorized': true, 'warehouse': warehouse}
    } 

    var variety = val.variety
      if (val.variety == 'Traveler' || val.variety == 'Fatima' || val.variety == 'Planet') {
        variety = val.variety 
      } else {
        variety = 'other'
      }

    if (val.moisture > 15) {
      // if moisture is above 15, the only other characteristic we need is variety
      // this accounts for the following options, where purity does not matter:
      /*
      3. Traveler // Variety all purity // Moisture >15%
      6. Fatima //  Variety all purity // Moisture >15%
      9. Planet //  Variety all purity // Moisture >15%
      11. All other varieties // Variety all purity // Moisture >15%
      */

      return {'uncategorized': false, 'warehouse': warehouse, 'variety': variety, 'moisture': 'above'}
    } else if (!val.purity) {
      // if purity is not defined and moisture is <= 15
      // this makes it case 10 IF variety is already at 'other', else it becomes uncategorized
      if (variety == 'other') {
        // 10. All other varieties // Variety all purity // Moisture =<15%
        return {'uncategorized': false, 'warehouse': warehouse, 'variety': variety, 'moisture': 'below'}
      } else {
        return {'uncategorized': true, 'warehouse': warehouse}
      }

    } else {
      // purity, variety, and moisture are all defined
      // moisture is below 

      if (variety == 'other') {
        // 10. All other varieties // Variety all purity // Moisture =<15%
        return {'uncategorized': false, 'warehouse': warehouse, 'variety': variety, 'moisture': 'below'}
      }

      var purity = 'above'
      if (val.purity < 90) {
        purity = 'below'
      }
      /*
      1. Traveler // Variety purity >=90% // Moisture =<15%
      2. Traveler //  Variety purity <90% // Moisture =<15%
      4. Fatima // Variety purity >=90% // Moisture =<15%
      5. Fatima // Variety purity <90% // Moisture =<15%
      7. Planet // Variety purity >=90% // Moisture =<15%
      8. Planet // Variety purity <90% // Moisture =<15%
      */
      return {'uncategorized': false, 'warehouse': warehouse, 'variety': variety, 'moisture': 'below', 'purity': purity }
    }

  }

  function getDictId(dct) {
    var keys = Object.keys(dct)
    keys.sort()
    var s = ''
    for (var i = 0; i < keys.length; i++) {
      let key = keys[i]
      if (i > 0) {
        s += '-'
      }
      s += key 
      s += ':'
      s += dct[key]
    }

    s += '-yearcode:25'

    return s 
  }


  function getType(t) {
    if (t == 'date') {
      return 'date'
    } else if (t == 'number') {
      return 'number' 
    } else if (t == 'list' || t == 'custom') {
      return t
    } else {
      return null
    }
  }

  function updateAutoValues(k, v) {
    var o = autoValues
    o[k] = v 
    setAutoValues(o)
  }

  function getAutoOptions(context, k) {
    if (k in context.barleyInCustomObjects) {
      return Object.keys(context.barleyInCustomObjects[k]).map((key, id) => {
        return context.barleyInCustomObjects[k][key]
      })
    }
    return []
  }


  function specialUpdate(key, val, setFieldValue, values) {

    setFieldValue(key, val)
    if (key == 'numberofbags') {
      if (values.averagebagweight) {
        setAmount(val * values.averagebagweight)
      }

    } else if (key == 'averagebagweight') {
      if (values.numberofbags) {
        setAmount(val * values.numberofbags)
      }
    }

    
  } 

  function renderContent(fields, touched, errors, handleBlur, handleChange, values, setFieldValue, context) {
    var columns = Math.floor(fields.length / 3)
    if (columns * 3 < fields.length) {
      columns += 1
    }
    var colrow = [...Array(columns).keys()]
    var rows = [0,1,2]
    return <div>
      {colrow.map((item, idx) => {
        return <div style={{display: 'flex', flexDirection: 'row'}}>
        {rows.map((inner, innerI) => {
          let fieldIdx = (3*item) + inner 

          if (fieldIdx < fields.length) {
            let tp = getType(fields[fieldIdx].fieldType)
            if (tp == 'custom') {

             return <Autocomplete
              value={autoValues[fields[fieldIdx].fieldKey]}
              fullWidth
              onChange={(event, newValue) => {
                if (typeof newValue === 'string') {
                  updateAutoValues(fields[fieldIdx].fieldKey, {name: newValue})
                  setFieldValue(fields[fieldIdx].fieldKey, {'customId': newValue.customId, 'name': newValue.name})

                } else if (newValue && newValue.inputValue) {
                  // Create a new value from the user input
                  updateAutoValues(fields[fieldIdx].fieldKey, {name: newValue.inputValue})
                  setFieldValue(fields[fieldIdx].fieldKey, {'customId':-1, 'name': newValue.inputValue})
                } else {
                  updateAutoValues(fields[fieldIdx].fieldKey, newValue)
                  if (newValue == null) {
                    setFieldValue(fields[fieldIdx].fieldKey, {'customId': '0', 'name': 'None'})
                  } else {
                    setFieldValue(fields[fieldIdx].fieldKey, {'customId': newValue.customId, 'name': newValue.name})
                  }
                }
                //console.log(event.target.value)
                //console.log(newValue)
              }}
              filterOptions={(options, params) => {
                const filtered = filter(options, params);
        
                // Suggest the creation of a new value
                if (params.inputValue !== '') {
                  filtered.push({
                    inputValue: params.inputValue,
                    name: `Add "${params.inputValue}"`,
                  });
                }
        
                return filtered;
              }}
              selectOnFocus
              clearOnBlur
              handleHomeEndKeys
              id="free-solo-with-text-demo"
              options={getAutoOptions(context, fields[fieldIdx].fieldKey)}
              getOptionLabel={(option) => {
                // Value selected with enter, right from the input
                if (typeof option === 'string') {
                  return option;
                }
                // Add "xxx" option created dynamically
                if (option.inputValue) {
                  return option.inputValue;
                }
                // Regular option
                return option.name;
              }}
              renderOption={(option) => option.name}
              freeSolo
              renderInput={(params) => (
                <TextField {...params}  error={Boolean(touched[fields[fieldIdx].fieldKey] && errors[fields[fieldIdx].fieldKey])}
                fullWidth
                key={fields[fieldIdx].fieldKey}
                id={fields[fieldIdx].fieldKey}
                helperText={touched[fields[fieldIdx].fieldKey] && errors[fields[fieldIdx].fieldKey]}
                label={fields[fieldIdx].fieldName}
                margin="normal"
                name={fields[fieldIdx].fieldKey}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values[fields[fieldIdx].fieldKey]}
                variant="outlined"/>
              )}
            />



            } else if (tp == 'list') {
              let opts = fields[fieldIdx].listOptions
              return <TextField
              select 
              error={Boolean(touched[fields[fieldIdx].fieldKey] && errors[fields[fieldIdx].fieldKey])}
              fullWidth
              key={fields[fieldIdx].fieldKey}
              id={fields[fieldIdx].fieldKey}
              helperText={touched[fields[fieldIdx].fieldKey] && errors[fields[fieldIdx].fieldKey]}
              label={fields[fieldIdx].fieldName}
              margin="normal"
              name={fields[fieldIdx].fieldKey}
              onBlur={handleBlur}
              onChange={handleChange}
              value={values[fields[fieldIdx].fieldKey]}
              variant="outlined">
              {opts.map((item, idx) => {
                return (<MenuItem value={item}>{item}</MenuItem>)
              })}
            </TextField>
            } else {
              return <TextField
              error={Boolean(touched[fields[fieldIdx].fieldKey] && errors[fields[fieldIdx].fieldKey])}
              fullWidth
              id={fields[fieldIdx].fieldKey}
              key={fields[fieldIdx].fieldKey}
              helperText={touched[fields[fieldIdx].fieldKey] && errors[fields[fieldIdx].fieldKey]}
              label={fields[fieldIdx].fieldName}
              margin="normal"
              name={fields[fieldIdx].fieldKey}
              onBlur={handleBlur}
              onChange={fields[fieldIdx].fieldKey in specOptions ? (e) => specialUpdate(fields[fieldIdx].fieldKey, e.target.value, setFieldValue, values) : handleChange}
              value={values[fields[fieldIdx].fieldKey]}
              variant="outlined"
              type={getType(fields[fieldIdx].fieldType)}
              InputLabelProps={{
                shrink: true,
                }}
            />
            }

          }
          
          return null 

        })}
       </div>
      })}
    </div>
  }

  async function doSubmitTrain(v, context) {

    let firebaseServerTimeStamp = await Firebase.getServerTimestamp({})
    if (!firebaseServerTimeStamp || !firebaseServerTimeStamp.success || !firebaseServerTimeStamp.timestamp ) {
      alert('Connection issue, try again.')
      return 
    }
    
    let creationDateTimeStamp = firebaseServerTimeStamp.timestamp 

    if (sku == null || amount == null || amount <= 0 || ((destination.warehousetype != 'Port' && train == null ) || operation == null) || destination == null) {
      alert('Please select a corresponding transfer out request.')
      return
    }
    let typeKey = 'IN'
    var transaction = null

    if (Firebase.ONLINE) {

      setLoading(true)

      var consignee = null
      if (operation && operation.yearlyPlans) {
        operation.yearlyPlans.map((item, idx) => {
            consignee = context.planDict[item].consignee
        })
        //return s
      }

      var newVal = v 
      newVal['yearCodeForFiltering'] = '25'
      newVal['consignee'] = consignee
      newVal['train'] = train
      newVal['sku'] = sku 
      newVal['isFinal'] = isFinal
      newVal['operation'] = operation
      newVal['laborers'] = laborers
      newVal['receivername'] = context.warehouseUserInfo ? context.warehouseUserInfo.name : ''
      newVal['creationDate'] = creationDateTimeStamp
      newVal['creationDateLocal'] = Date.now()
      newVal['warehouse'] = destination
      var newSet = {'sku': sku.name}
      newSet['warehouse'] = destination.name 
      var oldSet = {'sku': sku.name}
      if (destination.warehousetype == 'Port') {
        oldSet['vessel'] = operation.name 
        newVal['fromVessel'] = true
      } else {
        oldSet['train'] = train.name 
      }


      // SCRATCH@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
      if (newVal['purity'] === '') {
        delete newVal['purity']
      }
      if (newVal['moisture'] === '') {
        delete newVal['moisture']
      }

      let stockKey = getDictId(newSet)
      let oldStockKey = getDictId(oldSet)

      newVal['finalweightkg'] = parseInt(amount) 
      newVal['netweightkg'] = parseInt(amount)

      let yearCode = '25' // moment(Date.now()).format('YY')
    // transfer in number
    let docRef1 = Firebase.firestore.collection('projects').doc(Firebase.userProject).collection('barleyConstants').doc(yearCode + '-transferInNumber');

      // reference of old barley transfer out request, which should be updated to be in completed state 
      //var docRefOld = Firebase.firestore.collection('projects').doc(Firebase.userProject).collection('transferBarleyOut').doc(transferOut.id)
      // reference of new barley transfer in request, which will be created now
      var docRef = Firebase.firestore.collection('projects').doc(Firebase.userProject).collection("transferBarleyIn").doc()
    
      // Reference of stock in the train to decrease
      let stockDocRefPending = Firebase.firestore.collection('projects').doc(Firebase.userProject).collection('barleyStocks').doc(oldStockKey)
      // Reference of stock in new warehouse (this will be increased)
      let stockDocRef = Firebase.firestore.collection('projects').doc(Firebase.userProject).collection('barleyStocks').doc(stockKey)
      var newValId = docRef.id 
      var newQueryResult = null 
      var oldQueryResult = null 
      var oldNumericSave = null;
      var oldTransferValue = null; 
      var oldNumericValue = null;
      var newNumericValue = null;
  

      var customCreations = {}
      state.fields.map((field, idx) => {
      if (field.fieldType === 'custom') {
          if (field.fieldKey in newVal && newVal[field.fieldKey]['customId'] === -1) {
          // must create this val 
          customCreations[field.fieldKey] =  newVal[field.fieldKey]['name']
          }
      }
      })

      let arrayOfMemberIds = Object.keys(customCreations)
      
      let bd = context.barleyInCustomObjects['warehouse']
      var mayHaveShortCode = null
      if (newVal['fromVessel']) {
        if (operation.warehouse && operation.warehouse.customId && operation.warehouse.customId in bd) {
          mayHaveShortCode = bd[operation.warehouse.customId]
        }
      } else {
        if (train.warehouse && train.warehouse.customId && train.warehouse.customId in bd) {
          mayHaveShortCode = bd[train.warehouse.customId]
        }
      }


      let shortCode = mayHaveShortCode && mayHaveShortCode['shortcode'] ? mayHaveShortCode['shortcode'] : null

      var updCode = {}
     transaction = await Firebase.firestore.runTransaction(t => {

      return t.get(docRef1)
      .then(doc => {

        if (shortCode) {
          if (shortCode in doc.data()) {
            newNumericValue = doc.data()[shortCode] + 1;  //You calculate the new value
            let shortCodeValue = shortCode + '-' + typeKey + Firebase.padDigits(doc.data()[shortCode]) + '-' + yearCode
            oldNumericValue = shortCodeValue
            oldNumericSave = shortCodeValue
            updCode[shortCode] = newNumericValue
            //return t.update(docRef1 , updCode);
          } else {
            newNumericValue = 2;  //You calculate the new value
            let shortCodeValue = shortCode + '-' + typeKey + Firebase.padDigits(1) + '-' + yearCode
            oldNumericValue = shortCodeValue
            oldNumericSave = shortCodeValue
            updCode[shortCode] = newNumericValue
           // return t.update(docRef1 , updCode);
          }
          
        } else {
          newNumericValue = doc.data().value + 1;  //You calculate the new value
          oldNumericValue = typeKey + Firebase.padDigits(doc.data().value) + '-' + yearCode
          oldNumericSave = oldNumericValue
          updCode['value'] = newNumericValue
          //return t.update(docRef1 , {value : newNumericValue});
        }

        return t.get(stockDocRef )
      }).then(querySnapshotNew => {
          // Get new stock ref for the in transit barley to dest
          if (querySnapshotNew.exists) {
            newQueryResult = querySnapshotNew.data()
  
          } else {
            newQueryResult = null 
          }
  
         /* return t.get(docRefOld)
          
        })
        .then(querySnapshotTransfer => {
          // Get new stock ref for the in transit barley to dest
          if (querySnapshotTransfer.exists) {
            oldTransferValue = querySnapshotTransfer.data()
  
          } else {
            const errM = {'msg': "Transfer out request does not exist."}
            throw errM
          }*/
  
          return t.get(stockDocRefPending)
          
        }).then(querySnapshotOld => {
          // Get old stock ref for the pending barley to remove from source
          if (querySnapshotOld.exists) {
            oldQueryResult = querySnapshotOld.data()
          } else {
              const errM = {'msg': "Pending stock does not exist."}
              console.log('SKIPPING ERROR')
              console.log(errM)
              //throw errM
          }
            // update custom IDs if needed
            arrayOfMemberIds.forEach(function(memberId) {
                let docRefNext = Firebase.firestore.collection('projects').doc(Firebase.userProject).collection('barleyInCustomObjects').doc('contents').collection('projects').doc(Firebase.userProject).collection(memberId).doc()
                console.log('here t first')
                console.log(t)
                t = t.set(
                docRefNext,
                { name: customCreations[memberId] }
                )
                console.log('setting id of ' + docRefNext.id)
                newVal[memberId] = {'customId': docRefNext.id, 'name': customCreations[memberId]}
                
            });
            return t
            }).then(t => {
              // make the new transfer out item 
            newVal['transferInNumber'] = oldNumericValue
            //newVal['transferOutNumber'] = transferOut['transferOutNumber']
            return t.set(docRef ,newVal);
            })/*.then(t => {
              // make the new transfer out item 
            if (oldTransferValue.status == 'Completed') {
              const errM = {'msg': "This transfer has already been completed."}
              throw errM
            }
            return t.update(docRefOld ,{'status': 'Completed'});
            })*/.then(t => {
              return t.update(docRef1 , updCode);
            }).then(t => {
            // Update the pending stock

            var updateValOld = {}
            if (!newVal['finalweightkg']) {
              const errM = {'msg': "No barley being transferred."}
              throw errM
            }
            if (!oldQueryResult || !oldQueryResult['netweightkg'] || oldQueryResult['netweightkg'] < newVal['finalweightkg']) {
              console.log('completely skipping error for old result barley oldqueryresult etc train')
              
              //const errM = {'msg': "Not enough barley in transit. Amount in transit is " + oldQueryResult['netweightkg'] + "kg, while trying to transfer " + newVal['finalweightkg'] + "kg."}
              //throw errM
            }
            updateValOld['netweightkg'] = (oldQueryResult && oldQueryResult['netweightkg'] ? oldQueryResult['netweightkg'] : 0) - newVal['netweightkg']
            updateValOld['yearCodeForFiltering'] = '25'
            if (oldQueryResult) {
              return t.update(stockDocRefPending ,updateValOld);
            } else {
              return t.set(stockDocRefPending ,updateValOld);
            }

  
          })
          .then(t => {
            // update the transit stock
            if (newQueryResult === null) {
              // Set netweightkg accordingly here
              newSet['netweightkg'] = parseInt(amount)
              newSet['yearCodeForFiltering'] = '25'
              return t.set(stockDocRef, newSet)
            } else {
              var updateValNew = {}
              if (!newVal['finalweightkg']) {
                const errM = {'msg': "No barley being transferred."}
                throw errM
              }
              updateValNew['netweightkg'] = newQueryResult['netweightkg'] + newVal['finalweightkg']
              updateValNew['yearCodeForFiltering'] = '25'
              return t.update(stockDocRef, updateValNew);
            }
          });
      }).then(result => {
        
        console.log('Transaction success!' + result);
        return {'transaction': true, 'newVal': newVal, 'oldNumericSave': oldNumericSave, 'newValId': newValId}
      }).catch(err => {
        console.log('Transaction failure:', err);
        return {'transaction': null, 'failMessage': err}
      });

      Firebase.trackMixpanel('createTransferInOnline', {'result': transaction})








      // SCRATCH@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@



    } else {
      setLoading(true)
      alert('no offline implementation yet')

    }
    console.log('here transact')
    console.log(transaction)
  
    if (transaction && transaction.transaction != null) {
      newVal = transaction.newVal 
      var oldNumericSave = transaction.oldNumericSave
      var newValId = transaction.newValId
  
      let s = async () => {
          var datPack = {}
          const imageRef = Firebase.storage.ref('test').child('pdf_for_whin_' + oldNumericSave + '.pdf')
          let v = await imageRef.getDownloadURL();
          console.log(v)
          console.log('got it')
          datPack['pdflink'] = v 
          datPack['transferBarleyInId'] = newValId
          let snone = () => console.log('x')
          Firebase.provideWarehouseInLink(datPack, snone, snone)
          //window.open(v)
  
      } 
  
      let f = () => {
       
      }
      var datxx = {}
      datxx['val'] = newVal
      datxx['userId'] = 'test'
      
      Firebase.generateWarehouseIn(datxx, s, f)
  }
  setLoading(false)
  if (transaction && transaction.transaction == null && transaction.failMessage) {
    alert(transaction.failMessage.msg)
  } else {
    //navigate(-1)
    navigate('/app/creationsummary', {state: {headerText: 'Arrival at Destination: ' + transaction.oldNumericSave, secondaryText: 'Successfully created report.' }, replace: true})
  }
  }
  async function doSubmitWarhouse(v) {

    let firebaseServerTimeStamp = await Firebase.getServerTimestamp({})
    if (!firebaseServerTimeStamp || !firebaseServerTimeStamp.success || !firebaseServerTimeStamp.timestamp ) {
      alert('Connection issue, try again.')
      return 
    }
    
    let creationDateTimeStamp = firebaseServerTimeStamp.timestamp 

    if (sku == null || amount == null || amount <= 0 || transferOut == null) {
      alert('Please select a corresponding transfer out request.')
      return
    }
    let typeKey = 'IN'
    var transaction = null

    if (Firebase.ONLINE) {

      setLoading(true)


      var newVal = v 
      Object.keys(transferOut).map((key, idx) => {
        if (key != 'id' && key != 'vehicleweight' && key != 'vehicletare') {
          newVal[key] = transferOut[key]
        }
      })
      newVal['isFinal'] = true
      newVal['yearCodeForFiltering'] = '25'
      newVal['creationDate'] = creationDateTimeStamp
      newVal['creationDateLocal'] = Date.now()
      newVal['operation'] = operation
      var newSet = {'sku': sku.name}
      newSet['warehouse'] = transferOut['warehouse']['name']

      // SCRATCH@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
      if (newVal['purity'] === '') {
        delete newVal['purity']
      }
      if (newVal['moisture'] === '') {
        delete newVal['moisture']
      }

      let stockKey = getDictId(newSet)

      newVal['finalweightkg'] = parseInt(amount) 
      let oldNewKg = newVal['netweightkg']
      newVal['netweightkg'] = newVal['finalweightkg']
      let yearCode = '25' // moment(Date.now()).format('YY')
    // transfer in number
    let docRef1 = Firebase.firestore.collection('projects').doc(Firebase.userProject).collection('barleyConstants').doc(yearCode + '-transferInNumber');

      // reference of old barley transfer out request, which should be updated to be in completed state 
      var docRefOld = Firebase.firestore.collection('projects').doc(Firebase.userProject).collection('transferBarleyOut').doc(transferOut.id)
      // reference of new barley transfer in request, which will be created now
      var docRef = Firebase.firestore.collection('projects').doc(Firebase.userProject).collection("transferBarleyIn").doc()
      // Reference of stock in transit to new warehouse (this will be decreased)
      let stockDocRefPending = Firebase.firestore.collection('projects').doc(Firebase.userProject).collection('barleyStocksTransit').doc(stockKey)
      // Reference of stock in new warehouse (this will be increased)
      let stockDocRef = Firebase.firestore.collection('projects').doc(Firebase.userProject).collection('barleyStocks').doc(stockKey)
      var newValId = docRef.id 
      var newQueryResult = null 
      var oldQueryResult = null 
      var oldNumericSave = null;
      var oldTransferValue = null; 
      var oldNumericValue = null;
      var newNumericValue = null;
  

      var customCreations = {}
      state.fields.map((field, idx) => {
      if (field.fieldType === 'custom') {
          if (field.fieldKey in newVal && newVal[field.fieldKey]['customId'] === -1) {
          // must create this val 
          customCreations[field.fieldKey] =  newVal[field.fieldKey]['name']
          }
      }
      })

      let arrayOfMemberIds = Object.keys(customCreations)
      let shortCode = transferOut['warehouse'] && transferOut['warehouse']['shortcode'] ? transferOut['warehouse']['shortcode'] : null
      var updCode = {}
     transaction = await Firebase.firestore.runTransaction(t => {

      return t.get(docRef1)
      .then(doc => {

        if (shortCode) {
          if (shortCode in doc.data()) {
            newNumericValue = doc.data()[shortCode] + 1;  //You calculate the new value
            let shortCodeValue = shortCode + '-' + typeKey + Firebase.padDigits(doc.data()[shortCode]) + '-' + yearCode
            oldNumericValue = shortCodeValue
            oldNumericSave = shortCodeValue
            updCode[shortCode] = newNumericValue
            //return t.update(docRef1 , updCode);
          } else {
            newNumericValue = 2;  //You calculate the new value
            let shortCodeValue = shortCode + '-' + typeKey + Firebase.padDigits(1) + '-' + yearCode
            oldNumericValue = shortCodeValue
            oldNumericSave = shortCodeValue
            updCode[shortCode] = newNumericValue
           // return t.update(docRef1 , updCode);
          }
          
        } else {
          newNumericValue = doc.data().value + 1;  //You calculate the new value
          oldNumericValue = typeKey + Firebase.padDigits(doc.data().value) + '-' + yearCode
          oldNumericSave = oldNumericValue
          updCode['value'] = newNumericValue
          //return t.update(docRef1 , {value : newNumericValue});
        }

        return t.get(stockDocRef )
      }).then(querySnapshotNew => {
          // Get new stock ref for the in transit barley to dest
          if (querySnapshotNew.exists) {
            newQueryResult = querySnapshotNew.data()
  
          } else {
            newQueryResult = null 
          }
  
          return t.get(docRefOld)
          
        })
        .then(querySnapshotTransfer => {
          // Get new stock ref for the in transit barley to dest
          if (querySnapshotTransfer.exists) {
            oldTransferValue = querySnapshotTransfer.data()
  
          } else {
            const errM = {'msg': "Transfer out request does not exist."}
            throw errM
          }
  
          return t.get(stockDocRefPending)
          
        }).then(querySnapshotOld => {
          // Get old stock ref for the pending barley to remove from source
          if (querySnapshotOld.exists) {
            oldQueryResult = querySnapshotOld.data()
          } else {
              const errM = {'msg': "Pending stock does not exist."}
              throw errM
          }
            // update custom IDs if needed
            arrayOfMemberIds.forEach(function(memberId) {
                let docRefNext = Firebase.firestore.collection('projects').doc(Firebase.userProject).collection('barleyInCustomObjects').doc('contents').collection('projects').doc(Firebase.userProject).collection(memberId).doc()
                console.log('here t first')
                console.log(t)
                t = t.set(
                docRefNext,
                { name: customCreations[memberId] }
                )
                console.log('setting id of ' + docRefNext.id)
                newVal[memberId] = {'customId': docRefNext.id, 'name': customCreations[memberId]}
                
            });
            return t
            }).then(t => {
              // make the new transfer out item 
            newVal['transferInNumber'] = oldNumericValue
            newVal['transferOutNumber'] = transferOut['transferOutNumber']
            return t.set(docRef ,newVal);
            }).then(t => {
              // make the new transfer out item 
            if (oldTransferValue.status == 'Completed') {
              const errM = {'msg': "This transfer has already been completed."}
              throw errM
            }
            return t.update(docRefOld ,{'status': 'Completed'});
            }).then(t => {
              return t.update(docRef1 , updCode);
            }).then(t => {
            // Update the pending stock

            var updateValOld = {}
            if (!newVal['finalweightkg']) {
              const errM = {'msg': "No barley being transferred."}
              throw errM
            }
            if (!oldQueryResult['netweightkg'] || oldQueryResult['netweightkg'] < newVal['finalweightkg']) {
              const errM = {'msg': "Not enough barley in transit. Amount in transit is " + oldQueryResult['netweightkg'] + "kg, while trying to transfer " + newVal['finalweightkg'] + "kg."}
              throw errM
            }
            updateValOld['netweightkg'] = oldQueryResult['netweightkg'] - oldNewKg
            updateValOld['yearCodeForFiltering'] = '25'
            return t.update(stockDocRefPending ,updateValOld);
  
          })
          .then(t => {
            // update the transit stock
            if (newQueryResult === null) {
              // Set netweightkg accordingly here
              newSet['netweightkg'] = parseInt(amount)
              newSet['yearCodeForFiltering'] = '25'
              return t.set(stockDocRef, newSet)
            } else {
              var updateValNew = {}
              if (!newVal['finalweightkg']) {
                const errM = {'msg': "No barley being transferred."}
                throw errM
              }
              updateValNew['netweightkg'] = newQueryResult['netweightkg'] + newVal['finalweightkg']
              updateValNew['yearCodeForFiltering'] = '25'
              return t.update(stockDocRef, updateValNew);
            }
          });
      }).then(result => {
        
        console.log('Transaction success!' + result);
        return {'transaction': true, 'newVal': newVal, 'oldNumericSave': oldNumericSave, 'newValId': newValId}
      }).catch(err => {
        console.log('Transaction failure:', err);
        return {'transaction': null, 'failMessage': err}
      });

      Firebase.trackMixpanel('createTransferInOnline', {'result': transaction})








      // SCRATCH@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@



    } else {
      setLoading(true)
      alert('no offline implementation yet')

    }
    console.log('here transact')
    console.log(transaction)
  
    if (transaction && transaction.transaction != null) {
      newVal = transaction.newVal 
      var oldNumericSave = transaction.oldNumericSave
      var newValId = transaction.newValId
  
      let s = async () => {
          var datPack = {}
          const imageRef = Firebase.storage.ref('test').child('pdf_for_whin_' + oldNumericSave + '.pdf')
          let v = await imageRef.getDownloadURL();
          console.log(v)
          console.log('got it')
          datPack['pdflink'] = v 
          datPack['transferBarleyInId'] = newValId
          let snone = () => console.log('x')
          Firebase.provideWarehouseInLink(datPack, snone, snone)
          //window.open(v)
  
      } 
  
      let f = () => {
       
      }
      var datxx = {}
      datxx['val'] = newVal
      datxx['userId'] = 'test'
      
      Firebase.generateWarehouseIn(datxx, s, f)
  
    }

  setLoading(false)
  if (transaction && transaction.transaction == null && transaction.failMessage) {
    alert(transaction.failMessage.msg)
  } else {
    //navigate(-1)
    navigate('/app/creationsummary', {state: {headerText: 'Arrival At Destination: ' + transaction.oldNumericSave, secondaryText: 'Successfully created report.' }, replace: true})
  }
  //console.log(transaction)
  //console.log(transaction)


  }

  function updateLaborers(k, idx, v) {
    var wag = laborers.map((item, id) => {
      if (id == idx) {
        var ret = item 
        ret[k] = v 
        return ret 
      }
      return item 
    })
    setLaborers(wag)
  }


  function renderLaborers(laborers) {
    return <div style={{width: '50%'}}>
  {laborers.map((item, idx) => {
    return <div>
                    <TextField
              id={'laborerName'}
              key={idx + 'laborerName'}
              
              label={'Laborer Group'}
              margin="normal"
              name={'laborerName'}
              onChange={(e) => updateLaborers('name', idx, e.target.value)}
              value={laborers[idx].name}
              variant="outlined"
              type={'text'}
              InputLabelProps={{
                shrink: true,
                }}
            />
                                <TextField
              id={'laborerQty'}
              key={idx + 'laborerQty'}
              label={'Amount Moved by Group'}
              margin="normal"
              name={'wagonQty'}
              onChange={(e) => updateLaborers('qty', idx, e.target.value)}
              value={laborers[idx].qty}
              variant="outlined"
              type={'number'}
              InputLabelProps={{
                shrink: true,
                }}
            />

            <XIcon  style={{marginTop: 30}} onClick={(e) => {
              setLaborers(laborers.filter((item, idxx) => {
                return idxx != idx
              }))
            }}/>
      </div>
  })}
  <Button
                       color="primary"
                    
                       size="large"
                       id="sendButtonNext"
                       variant="contained"
                       onClick={() => {
                         var wag = laborers 
                         wag.push({'name': '', 'qty': ''})
                         setLaborers(wag)
                         forceUpdate()
                       }}
                       style={{margin: 5, width: 150}}
                     >
                       Add Group
                     </Button>
  
</div>
  }


  return (
    <MyContext.Consumer>{context => (
  <Page
    className={classes.root}
    height='100%'
    title="New Transfer In"
    id="ff2"
    backgroundColor={classes.root.backgroundColor}
  >
    <Box
      display="flex"
      flexDirection="column"
      id="bb2"
      backgroundColor={classes.root.backgroundColor}

    >
      <Container id="cc2"  backgroundColor={classes.root.backgroundColor}>
        <Formik
        id='f2'
          initialValues={{
            averagebagweight: 50
          }}
          validationSchema={
            Yup.object().shape({

            })
          }
          onSubmit={(values) => {
            //doSubmit(values)
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting2,
            setSubmitting, 
            touched,
            values,
            setFieldValue
          }) => (
            <form id='fu2' onSubmit={handleSubmit}>
                  <div id='fkx1' style={{marginTop: 5}}>
              <Box mb={3}>
                <Typography
                  color="textPrimary"
                  variant="h2"
                >
                 {isFinal ? 'Process Arrival' : 'Process Transfer In'}
                </Typography> 
              </Box>

              <Stepper activeStep={activeStep} style={{width: '100%', marginBottom: 15}}>
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>

            </Step>
          );
        })}
      </Stepper>

      {activeStep == 0 ? 

<div>



{arrivalType == 'Train' 
? 
<div>

<Autocomplete
  id="destination"
  disabled={false}
  options={getAutoOptions(context, 'warehouse').filter((item, idx) => {
    return item && (item.warehousetype == 'Internal' || item.warehousetype == 'Port') && (context.targetWarehouse == -1 || item.name in context.targetWarehouseSet)
  })}
  getOptionLabel={(option) => option.name}
  value={destination}
  onChange={(e, value) => {   
    if (value != null) {
      setDestination(value)
    }
  }}
  renderInput={(params) => <TextField {...params}
  disabled={false}
  label="Warehouse"
  margin="normal"
  id="destination"
  name="destination"
  variant="outlined"
  value={destination}
  defaultValue={destination} />}
/>

<Autocomplete
  id="operation"
  disabled={false}
  options={context.operationList}
  getOptionLabel={(option) => option.operationnumber + ' - ' + option.name}
  value={operation}
  onChange={(e, value) => {   
    if (value != null) {
      setOperation(value)
      setTrain(null)
      setSku(null)
    }
  }}
  renderInput={(params) => <TextField {...params}
  disabled={false}
  label="Operation"
  margin="normal"
  id="operation"
  name="operation"
  variant="outlined"
  value={operation}
  defaultValue={operation} />
  
  
  }
/>

{destination && destination.warehousetype == 'Port' ? null

: <Autocomplete
id="train"
disabled={state.requestPreset != null}
options={context.trainList.filter((item, idx) => {
  return operation == null || (item.operations && item.operations.includes(operation.operationId))
})}
getOptionLabel={(option) => option.name}
value={train}
onChange={(e, value) => {
  
  if (value != null) {
   

     setTrain(value)
     if (value.warehouse) {
      setDestination(value.warehouse)
     } else {
       setDestination(null)
     }
  }
  

}}
renderInput={(params) => <TextField {...params}
disabled={state.requestPreset != null}
label="Train"
margin="normal"
id="train"
name="train"
variant="outlined"
value={train}
defaultValue={train} />}
/>

}


<Autocomplete
  id="sku"
  disabled={false}
  options={getAutoOptions(context, 'sku').filter((item, idx) => {
    return operation == null || (operation.sku && item && operation.sku.name == item.name)
  })}
  getOptionLabel={(option) => option.name}
  value={sku}
  onChange={(e, value) => {   
    if (value != null) {
      setSku(value)
    }
  }}
  renderInput={(params) => <TextField {...params}
  disabled={false}
  label="SKU"
  margin="normal"
  id="sku"
  name="sku"
  variant="outlined"
  value={sku}
  defaultValue={sku} />}
/>



  </div>
: null}
{arrivalType == 'Warehouse'
? 
<div>
<Autocomplete
  id="request"
  options={context.transferBarleyOutList.filter((item, idx) => {
    return item.status == "In Progress" && (context.targetWarehouse == -1 ||
      (context.targetWarehouseSet && item.warehouse && item.warehouse.name in context.targetWarehouseSet)) && !item.voided
  })}
  getOptionLabel={(option) => option && option.warehouse ? '[' + option.transferOutNumber + '] ' + option.warehouse.name + ' (' + option.netweightkg + 'kg)' : 'option'}
  value={transferOut}
  onChange={(e, value) => {
    if (value != null) {

      if (value.sku) {
        console.log('setting')
      console.log(value.sku)
        setSku(value.sku)
      }

      setTransferOut(value)
      sharedKeys.map((sk, idx) => {
        if (sk in value) {
          setFieldValue(sk, value[sk])
        }
      })
    }
  }}
  renderInput={(params) => <TextField {...params}
  label="Pending Transfer"
  margin="normal"
  id="request"
  name="request"
  variant="outlined"
  value={transferOut}
  defaultValue={transferOut} />}
/>

<Autocomplete
  id="sku"
  disabled={true}
  options={getAutoOptions(context, 'sku')}
  getOptionLabel={(option) => option.name}
  value={sku}
  onChange={(e, value) => {   
    if (value != null) {
      setSku(value)
    }
  }}
  renderInput={(params) => <TextField {...params}
  disabled={true}
  label="SKU"
  margin="normal"
  id="sku"
  name="sku"
  variant="outlined"
  value={sku}
  defaultValue={sku} />}
/>

  </div>
: null}


  </div>


: <div style={{display: 'flex', flexDirection: 'row'}}>
  
  <div>{renderContent(state.fields.filter((item, idx) => {
  return stepFields[activeStep].includes(item.fieldKey) && item.fieldKey != 'displaybol' && item.fieldKey != 'operation' && item.fieldKey != 'laborergroup'
}), touched, errors, handleBlur, handleChange, values, setFieldValue, context)}</div>

{renderLaborers(laborers)}
</div>}
</div>

{activeStep == 1 ? 
              <div>
                <Typography>
                  {'Net Weight: ' + (amount ? amount : 0) + 'kg'}
                </Typography>
                </div>

                : null}

<Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'row', pt: 2 }}>


<Button
                       color="primary"
                       disabled={activeStep == 0}
                       size="large"
                       id="sendButtonNext"
                       variant="contained"
                       onClick={handleBack}
                       style={{margin: 5, width: 150}}
                     >
                       Back
                     </Button>



{activeStep < steps.length - 1 ? 
                       <Button
                       color="primary"
                       disabled={loading || arrivalType == null ||  (arrivalType == 'Warehouse' && transferOut == null) || (arrivalType == 'Train' && ((train == null && destination && destination.warehousetype != 'Port') || sku == null || destination == null))}
                       size="large"
                       id="sendButtonNext"
                       variant="contained"
                       onClick={handleNext}
                       style={{margin: 5, width: 150}}
                     >
                       Next
                     </Button>
: 
<Box my={2} style={{alignSelf: 'center', flexDirection: 'column', display: 'flex'}}>

<Button
color="primary"
style={{margin: 5, width: 150}}
disabled={loading || amount == null || amount <= 0}
id="sendButtonNext"
variant="contained"
onClick={() => {
  if (arrivalType == 'Train') {
    doSubmitTrain(values, context)
  } else if (arrivalType == 'Warehouse') {
    doSubmitWarhouse(values)
  }
} }
>
Confirm
</Button>
</Box>
}
</Box>

              {loading ? <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: 50}}><CircularProgress/></div> : null}

              
            </form>
          )}
        </Formik>
      </Container>
    </Box>
  </Page>
        )}</MyContext.Consumer>
  )
};

export default NewBarleyOutView;
