import React, {useState, useRef} from 'react';
import { Link as RouterLink, useNavigate, useLocation} from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import Autocomplete from '@material-ui/lab/Autocomplete';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import {
  Box,
  Button,
  Checkbox,
  Container,
  FormHelperText,
  Link,
  TextField,
  Typography,
  makeStyles,
  Grid,
  Select,
  MenuItem,
  InputLabel,
  CircularProgress
} from '@material-ui/core';
import Page from 'src/components/Page';
import parse from 'autosuggest-highlight/parse';
import throttle from 'lodash/throttle';
import Firebase from 'src/Firebase';
import DataFetcher from 'src/DataFetcher';
import QuoteStatus from 'src/QuoteStatus';
import HoverMarker from 'src/HoverMarker';
import GoogleMapReact from 'google-map-react';
import JobMasterView from 'src/utils/JobMasterView';
import MyContext from 'src/MyContext'
var moment = require('moment');


const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

function loadScript(src, position, id) {
  if (!position) {
    return;
  }

  const script = document.createElement('script');
  script.setAttribute('async', '');
  script.setAttribute('id', id);
  script.src = src;
  position.appendChild(script);
}

const autocompleteService = { current: null };
const geocoder = {current: null};
const directionsService = {current: null};



const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  }
}));


const EditJobViewAdmin = () => {
  const classes = useStyles();
  const navigate = useNavigate();


  const [value, setValue] = React.useState(null);
  const [valueTwo, setValueTwo] = React.useState(null);
  const [inputValue, setInputValue] = React.useState('');
  const [options, setOptions] = React.useState([]);
  const [optionsTwo, setOptionsTwo] = React.useState([]);
  const [numVal, setNumVal] = React.useState(false);
  const [actions, setActions] = React.useState(null);
  const [locationStart, setLocationStart] = React.useState(null);
  const [locationEnd, setLocationEnd] = React.useState(null);
  const [finalSet, setFinalSet] = React.useState(null);
  const [tableItems, setTableItems] = React.useState(DataFetcher.cargoTypeData);
  const [transporterOptions, setTransporterOptions] = React.useState(DataFetcher.transportersList)
  const [driversOptions, setDriversOptions] = React.useState(DataFetcher.driversList)
  const {state} = useLocation();
  const [startLat, setStartLat] = React.useState(state.customer.startPos.lat);
  const [startLng, setStartLng] = React.useState(state.customer.startPos.lng);
  const [isAuthenticating, setIsAuthenticating] = React.useState(false);


  const [center, setCenter] = React.useState({
    lat: state.centerx,
    lng: state.centery
  })

  const [zoom, setZoom] = React.useState(7)
  const [zoomUpdate, setZoomUpdate] = React.useState(7);

  const mapHolder = useRef(null);

  const loaded = React.useRef(false);
  /*if (typeof window !== 'undefined' && !loaded.current) {
    if (!document.querySelector('#google-maps')) {
      loadScript(
        'https://maps.googleapis.com/maps/api/js?key=AIzaSyAkPLP3zHG-2kctVakLZwdUQHqeLVO0Onw&libraries=places',
        document.querySelector('head'),
        'google-maps',
      );
    }

    loaded.current = true;
  }*/

  function doSubmit(values, lst, lett) {
    setIsAuthenticating(true)
    console.log(values)
    console.log(Firebase.userInfo)

    var v = values 
    v['quoteId'] = state.customer.quoteId
    if (v.roundTripLink != state.customer.roundTripLink) {
      v['oldRoundTripLink'] = state.customer.roundTripLink
      //alert('change ' + v.roundTripLink + ' ' + v.oldRoundTripLink)
    }
    let suc = function () {
      setIsAuthenticating(false)
      var nsend = v
      nsend['shortId'] = state.customer.shortId 
      nsend['shortIdShipper'] = state.customer.shortIdShipper
      navigate('/app/actionsummary', {state: {customer: nsend}, replace: true})
      // navigate(-1)
    }

    let f  = function () {
      setIsAuthenticating(false)
      alert('Error saving changes. Check internet connection and try again.')
    }

    Firebase.editQuoteAuth(v, lst, lett, Firebase.uid, suc, f)


  }

  const fetch = React.useMemo(
    () =>
      throttle((request, callback) => {
        if (autocompleteService.current) {
          autocompleteService.current.getPlacePredictions(request, callback);
        }

      }, 200),
    [],
  );

  function mathRound(num) {
    if (num < 0.01) {
      return num 
    }

    return Math.round((num + Number.EPSILON) * 100) / 100
  }


function latRad(lat) {
  var sin = Math.sin(lat * Math.PI / 180);
  var radX2 = Math.log((1 + sin) / (1 - sin)) / 2;
  return Math.max(Math.min(radX2, Math.PI), -Math.PI) / 2;
}

  function handleApiLoaded(map, maps) {
    console.log('LOADED THE API@@@@')
    if (!autocompleteService.current) {
      autocompleteService.current = new maps.places.AutocompleteService();
      geocoder.current = new maps.Geocoder();

      const directionsService = new maps.DirectionsService();
      const directionsDisplay = new maps.DirectionsRenderer();
      const bounds = new maps.LatLngBounds()
      console.log('here a holder')
      console.log(mapHolder)
      console.log(state.customer.startPos)
      console.log(state.customer.endPos)
      let latDif = Math.abs(latRad(state.customer.startPos.lat) - latRad(state.customer.endPos.lat))
      let lngDif = Math.abs(state.customer.startPos.lng - state.customer.endPos.lng)
      console.log('heredifs')
      console.log(lngDif)
      console.log(latDif)
      //let latDifRaf = letRad(latDif)
      //let lngDifRad = 

      let latFrac = latDif / Math.PI 
      let lngFrac = lngDif / 360 


      let lngZoom = Math.log(1/latFrac) / Math.log(2)
      let latZoom = Math.log(1/lngFrac) / Math.log(2)
      console.log(latFrac)
      console.log(lngFrac)
      console.log(Math.log(1/latFrac) / Math.log(2))
      console.log(Math.log(1/lngFrac) / Math.log(2))

      setZoom(Math.min(lngZoom, latZoom))



      //setZoom(getBoundsZoomLevel(bounds, { height: mapHolder.current.clientHeight, width: mapHolder.current.clientWidth }))

      let strstart = state.customer.startPos.lat + ', ' + state.customer.startPos.lng
      let strend = state.customer.endPos.lat + ', ' + state.customer.endPos.lng
      let strkey = strstart + '_' + strend
      console.log(strkey)
      directionsService.route({
        origin: strstart,
        destination: strend,
        travelMode: 'DRIVING'
      }, (response, status) => {
        if (status === 'OK') {
          console.log(response)
          console.log(response.routes[0].legs[0].start_location.lat())
          console.log(response.routes[0].legs[0].start_location.lng())

          setStartLat(response.routes[0].legs[0].start_location.lat())
          setStartLng(response.routes[0].legs[0].start_location.lng())
          //console.log(response.overview_path[0].lat())
          //console.log(response.overview_path[0].lng())
          //Firebase.cacheRoute(strkey.replaceAll('.','@'), response)

          directionsDisplay.setDirections(response);
          console.log(response.routes[0].overview_path, 'Ruta')
          const routePolyline = new maps.Polyline({
            path: response.routes[0].overview_path
          });
          routePolyline.setMap(map);


        } else {
          window.alert('Directions request failed due to ' + status);
          }
        });
    }
  }

  React.useEffect(() => {
    let active = true;

    console.log('state')
    console.log(state)

    setLocationStart(state.customer.startPos)
    setLocationEnd(state.customer.endPos)

    console.log(moment(state.customer.endDateEarly).format('yyyy-MM-DDThh:mm'))
    /*if (!autocompleteService.current && window.google) {
      autocompleteService.current = new window.google.maps.places.AutocompleteService();
      geocoder.current = new window.google.maps.Geocoder();
      console.log(autocompleteService.current)
    }
    if (!autocompleteService.current || !geocoder.current) {
      return undefined;
    }*/

    if (inputValue === '') {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetch({ input: inputValue }, (results) => {

      console.log(results)
      if (active) {
        let newOptions = [];

        if (value) {
          newOptions = [value];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      }
    });

    if (typeof window !== 'undefined' && typeof Firebase.auth !== 'undefined') {
      console.log('dothis')
      window.recaptchaVerifier = new Firebase.firebaseRef.auth.RecaptchaVerifier('get-quote-button', {
        'size': 'invisible',
        'callback': function(response) {
          // reCAPTCHA solved, allow signInWithPhoneNumber.
          //phoneVerify(2);
          alert('solved')
        }
      });
    }


    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);

  if (isAuthenticating) {
    return (<Page
      className={classes.root}
      title="Manage Job"
      backgroundColor={classes.root.backgroundColor}
    ><div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}><CircularProgress/></div></Page>)
  }
  return (
    <MyContext.Consumer>{context => (
      <JobMasterView context={context} isLimited={context.isLimited} limitedAccessFunction={context.limitedAccessFunction} adminAssocs={context.associations} adminFreightOrders={context.freightOrders} adminVehicles={context.vehicles} adminUsers={context.users} isLimited={context.isLimited} editQuoteShipper={false} statusUpdates={true} adminEdit={context.isLimited <= 0} provideQuote={false} privateQuote={false} publicQuote={false} quoteOffer={false} evaluateBid={false} parentSubmit={(v, ls, le) => doSubmit(v, ls, le)} parentState={state} jobProps={{editable: false, priceEditable: true, showPrices: context.isLimited <= 0, showAssignments: context.isLimited <= 0, showTransporterPrice: context.isLimited <= 0, transporterPriceEditable: true}} />
      )}</MyContext.Consumer>
 )

  return (
    <Page
      className={classes.root}
      title="Request Quote"
      backgroundColor={classes.root.backgroundColor}
    >
      <Formik
            initialValues={{
              price: state.customer.price,
              transporterPrice: state.customer.transporterPrice ? state.customer.transporterPrice : 0,
              cargoName: state.customer.cargo,
              pickupLocation: state.customer.startName,
              dropoffLocation: state.customer.endName,
              earlyDrop: moment(state.customer.endDateEarly).format('yyyy-MM-DDThh:mm'),
              earlyPick: moment(state.customer.startDateEarly).format('yyyy-MM-DDThh:mm'),
              cargoType: state.customer.cargoType,
              cargoFields: state.customer.cargoFields,
              assignedDriver: state.customer.assignedDriver ? state.customer.assignedDriver : '0',
              assignedTransporter: state.customer.assignedTransporter ? state.customer.assignedTransporter : '0',
              trueWeight: state.customer.trueWeight,
              pricePerQuintal: state.customer.price && state.customer.trueWeight ? mathRound(state.customer.price / (state.customer.trueWeight * 10)) : 0
            }}
            validationSchema={
              Yup.object().shape({
                cargoName: Yup.string().max(255).required('Cargo is required'),
                pickupLocation: Yup.string().max(255).required('Pickup location is required'),
                dropoffLocation: Yup.string().max(255).required('Dropoff location is required'),
                earlyPick: Yup.string().max(255).required('Pickup and dropoff times are required.'),
                earlyDrop: Yup.string().max(255).required('Pickup and dropoff times are required.'),
                cargoType: Yup.string().max(255).required('Cargo type is required'),
                trueWeight: Yup.number().min(.00001).required('Weight (metric tons) is required')
              })
            }
            onSubmit={(values, acts) => {
              //alert('tt')
              //console.log('sub')
              //setNumVal(false)
              doSubmit(values)
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
              setFieldValue
            }) => (
              <form onSubmit={handleSubmit}>
      <div style={{display: 'flex', flexDirection: 'row'}}>
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        backgroundColor={classes.root.backgroundColor}
 
      >
        <Container maxWidth="sm" backgroundColor={classes.root.backgroundColor}>
          
                <Box mb={3}>
                  <Typography
                    color="textPrimary"
                    variant="h2"
                  >{'Update Quote #' + state.customer.quoteId}
                  </Typography>
                  
                </Box>
                <QuoteStatus customerState={state.customer.state}/>

                <TextField
                  error={Boolean(touched.phone && errors.phone)}
                  fullWidth
                  label="Price"
                  margin="normal"
                  name="price"

                  onChange={(e) => {
                    if (values.trueWeight <= 0) {
                      alert('Weight cannot be zero or less.')
                    } else {
                      setFieldValue('price', e.target.value)
                      setFieldValue('pricePerQuintal', mathRound(e.target.value / (values.trueWeight * 10)))
                    }
                    
                  }}
                  type="number"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={values.price}
                  variant="outlined"
                />
                 <TextField
                  error={Boolean(touched.phone && errors.phone)}
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                  label="Price/Quintal"
                  margin="normal"
                  name="price/quintal"

                  onChange={(e) => {
                    if (values.trueWeight <= 0) {
                      alert('Weight cannot be zero or less.')
                    } else {
                      setFieldValue('price', e.target.value * (values.trueWeight * 10))
                      setFieldValue('pricePerQuintal', e.target.value)
                    }
                    
                  }
                    }
                  type="number"
                  value={values.pricePerQuintal}
                  variant="outlined"
                />
                                <TextField
                  error={Boolean(touched.phone && errors.phone)}
                  fullWidth
                  helperText={touched.phone && errors.phone}
                  label="Transporter Payment"
                  margin="normal"
                  name="transporterPrice"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="number"
                  value={values.transporterPrice}
                  variant="outlined"
                />
<InputLabel>Cargo</InputLabel>
                  <Select
          fullWidth
          label="Cargo Type"
          margin="normal"
          id="cargoType"
          name="cargoType"
          variant="outlined"
          value={values.cargoType}
          onChange={(e) => {
            console.log(e)
            setFieldValue('cargoType', e.target.value)
            setFieldValue('cargoName', tableItems[e.target.value].name)
            //setFieldValue('cargoTypeDisp', e.target.value.name)
          }}
        >
          {Object.keys(tableItems).map((item, idx) => {
            return (<MenuItem value={tableItems[item].val}>{tableItems[item].name}</MenuItem>)
          })}

        </Select>
        {values.cargoType != '' ? <div>{Object.keys(tableItems[values.cargoType].fields).map((item, idx) => {
            return <TextField
            error={Boolean(touched.cargoName && errors.cargoName)}
            fullWidth
            helperText={touched.cargoName && errors.cargoName}
            label={tableItems[values.cargoType].fields[item].name}
            margin="normal"
            name={tableItems[values.cargoType].fields[item].val}
            onBlur={handleBlur}
            onChange={(e) => {
              var temp = values.cargoFields
              temp[item] = e.target.value 
              setFieldValue('cargoFields', temp)
            }}
            value={values.cargoFields[item]}
            variant="outlined"
          />
          })}</div>: <div/>}
                  <TextField
                  error={Boolean(touched.trueWeight && errors.trueWeight)}
                  fullWidth
                  helperText={touched.trueWeight && errors.trueWeight}
                  label="Weight (Metric Tons)"
                  margin="normal"
                  name="trueWeight"
                  type="number"
                  onBlur={handleBlur}
                  onChange={(e) => {
                    if (e.target.value <= 0) {
                      alert('Weight cannot be zero or less.')
                    } else {
                      setFieldValue('trueWeight', e.target.value)
                      setFieldValue('pricePerQuintal', mathRound(values.price / (e.target.value * 10)) )
                    } 
                  }
                  }
                  value={values.trueWeight}
                  variant="outlined"
                />
                <Autocomplete
      id="pickupLocation"
      name="pickupLocation"
      style={{ width: '100%' }}
      getOptionLabel={(option) => (typeof option === 'string' ? option : option.description)}
      filterOptions={(x) => x}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={values.pickupLocation}
      onChange={(event, newValue) => {
        setOptions(newValue ? [newValue, ...options] : options);
        
        setValue(newValue);
        if (geocoder.current != null && newValue != null) {
          geocoder.current.geocode({ 
            'placeId': newValue.place_id
        }, 
        function(responses, status) {
            if (status == 'OK') {
                var lat = responses[0].geometry.location.lat();
                var lng = responses[0].geometry.location.lng();
                setLocationStart({'lat': lat, 'lng': lng})
                console.log(lat, lng);
            }
        });
        }
        setFieldValue('pickupLocation', newValue.description)

      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => (
        <TextField {...params} label="Dropoff Location" variant="outlined" margin="normal" fullWidth onBlur={handleBlur}
        name="dropoffLocation"
        error={Boolean(touched.dropoffLocation && errors.dropoffLocation)}
        value={values.dropoffLocation}/>
      )}
      renderOption={(option) => {
        const matches = option.structured_formatting.main_text_matched_substrings;
        const parts = parse(
          option.structured_formatting.main_text,
          matches.map((match) => [match.offset, match.offset + match.length]),
        );

        return (
          <Grid container alignItems="center">
            <Grid item>
              <LocationOnIcon className={classes.icon} />
            </Grid>
            <Grid item xs>
              {parts.map((part, index) => (
                <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                  {part.text}
                </span>
              ))}

              <Typography variant="body2" color="textSecondary">
                {option.structured_formatting.secondary_text}
              </Typography>
            </Grid>
          </Grid>
        );
      }}
    />
                <Grid container justify="space-between">
                      <TextField
        label="Earliest Pickup"
        type="datetime-local"
        variant="outlined"
        margin="normal"
        name="earlyPick"
        onBlur={handleBlur}
        onChange={handleChange}
        value={values.earlyPick}
        error={Boolean(touched.earlyPick && errors.earlyPick)}
        className={classes.textField}
        InputLabelProps={{
          shrink: true,
        }}
      />
         </Grid>
<Autocomplete
      id="dropoffLocation"
      name="dropoffLocation"
      style={{ width: '100%' }}
      getOptionLabel={(option) => (typeof option === 'string' ? option : option.description)}
      filterOptions={(x) => x}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={values.dropoffLocation}
      onChange={(event, newValue) => {
        setOptions(newValue ? [newValue, ...options] : options);
        
        setValueTwo(newValue);
        if (geocoder.current != null && newValue != null) {
          geocoder.current.geocode({ 
            'placeId': newValue.place_id
        }, 
        function(responses, status) {
            if (status == 'OK') {
                var lat = responses[0].geometry.location.lat();
                var lng = responses[0].geometry.location.lng();
                setLocationEnd({'lat': lat, 'lng': lng})
                console.log(lat, lng);
            }
        });
        }
       
        setFieldValue('dropoffLocation', newValue.description)
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => (
        <TextField {...params} label="Pickup Location" variant="outlined" margin="normal" fullWidth onBlur={handleBlur}

        name="pickupLocation"
        error={Boolean(touched.pickupLocation && errors.pickupLocation)}
        value={values.pickupLocation}/>
      )}
      renderOption={(option) => {
        const matches = option.structured_formatting.main_text_matched_substrings;
        const parts = parse(
          option.structured_formatting.main_text,
          matches.map((match) => [match.offset, match.offset + match.length]),
        );

        return (
          <Grid container alignItems="center">
            <Grid item>
              <LocationOnIcon className={classes.icon} />
            </Grid>
            <Grid item xs>
              {parts.map((part, index) => (
                <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                  {part.text}
                </span>
              ))}

              <Typography variant="body2" color="textSecondary">
                {option.structured_formatting.secondary_text}
              </Typography>
            </Grid>
          </Grid>
        );
      }}
    />

                          
      <Grid container justify="space-between">
              <TextField
        label="Earliest Dropoff"
        type="datetime-local"
        margin="normal"
        variant="outlined"
        name="earlyDrop"
        onBlur={handleBlur}
        onChange={handleChange}
        className={classes.textField}
        error={Boolean(touched.earlyDrop && errors.earlyDrop)}
        value={values.earlyDrop}
        InputLabelProps={{
        shrink: true,
        }}
        />
        </Grid>
                
                {Boolean(touched.policy && errors.policy) && (
                  <FormHelperText error>
                    {errors.policy}
                  </FormHelperText>
                )}
                <Box my={2}>
                  <Button
                    color="primary"
                    disabled={isSubmitting}
                    fullWidth
                    size="large"
                    id="get-quote-button"
                    type="submit"
                    variant="contained"
                  >
                    Save Changes
                  </Button>
                </Box>
                

        </Container>
      </Box>
      < div id='mapElementId' ref={mapHolder} style={{ height: 300, width: '100%' }}>
      <GoogleMapReact
          bootstrapURLKeys={{ key: 'AIzaSyAkPLP3zHG-2kctVakLZwdUQHqeLVO0Onw', libraries: 'places' }}
          defaultCenter={center}
          defaultZoom={zoom}
          center={center}
          zoom={zoom}
          onChange={(x) => setZoomUpdate(x.zoom)}
          yesIWantToUseGoogleMapApiInternals={true}
          onClick={(val) => console.log(val)}
          onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
      >

        <HoverMarker zoomSet={zoomUpdate} lat={startLat} lng={startLng} text={'Location'} idx={0} clickMarker={() => console.log('rmm')} />
        <HoverMarker zoomSet={zoomUpdate} lat={state.customer.endPos.lat} lng={state.customer.endPos.lng} text={'Location'} idx={1} clickMarker={() => console.log('rmm')} />
      </GoogleMapReact>
      <InputLabel>Transporter</InputLabel>
      <Select
      disabled
          fullWidth
          label="Transporter"
          margin="normal"
          id="assignedTransporter"
          name="assignedTransporter"
          variant="outlined"
          value={values.assignedTransporter}
          onChange={handleChange}

        >
          {transporterOptions.map((item, idx) => {
            return (<MenuItem value={item.userId}>{item.companyName}</MenuItem>)
          })}
        </Select>
        <InputLabel>Driver</InputLabel>
        <Select
        disabled
          fullWidth
          label="Driver"
          margin="normal"
          id="assignedDriver"
          name="assignedDriver"
          variant="outlined"
          value={values.assignedDriver}
          onChange={handleChange}
        >
          {driversOptions.map((item, idx) => {
            return (<MenuItem value={item.userId}>{item.companyName}</MenuItem>)
          })}
        </Select>
        { state.customer.documentList ? <div style={{flexDirection: 'column', display: 'flex'}}><InputLabel>Driver Documents</InputLabel>{
            state.customer.documentList.map((item, idx) => {
              return <Link
              to={item}
              onClick={() => window.open(item)}
              variant="h6"
            >{'Document ' + (idx + 1)}</Link>
            })
          }</div> : <InputLabel>No Load Documents Uploaded Yet</InputLabel>}

  </div>
      </div>
      </form>
      )}
    </Formik>
    </Page>
  );
};

/*

        

*/

export default EditJobViewAdmin;
