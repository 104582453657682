import React, { Component } from "react";
// nodejs library that concatenates classes

// @material-ui/core components
import { makeStyles, withStyles } from "@material-ui/core/styles";

// @material-ui/icons


//const useStyles = makeStyles(styles);
//const classes = makeStyles(styles);


class TermsOfUse extends Component {

    


    constructor() {
        super();


    }

    componentDidMount() {
        
    }

    componentWillUnmount() {
        
    }




    render() {

        const { classes } = this.props
        return (
            <div>

                <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                    <div style={styles.stylepriv1}>GARRI LOGISTICS TERMS OF SERVICE</div>
                    <div style={styles.stylepriv2}>Last updated: 01/02/2020</div>
                    </div>

            <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'center', flexDirection: 'column' }}>

                    <div style={styles.stylepriv2}>1.	Introduction</div>
                    <div style={styles.stylepriv3}>Welcome to Garri ("Company", "we", "our", "us")! As you have just clicked our Terms of Service, please pause, grab a cup of coffee and carefully read the following pages. It will take you approximately 20 minutes.</div>
                    <div style={styles.stylepriv3}>These Terms of Service ("Terms", "Terms of Service") govern your use of our web pages located at http://www.garrilogistics.com operated by Garri.</div>
                    <div style={styles.stylepriv3}>Our Privacy Policy also governs your use of our Service and explains how we collect, safeguard and disclose information that results from your use of our web pages. Please read it here https://shipper.garrilogistics.com/privacypolicy.</div>
                    <div style={styles.stylepriv3}>Your agreement with us includes these Terms and our Privacy Policy ("Agreements"). You acknowledge that you have read and understood Agreements, and agree to be bound of them.</div>
                    <div style={styles.stylepriv3}>If you do not agree with (or cannot comply with) Agreements, then you may not use the Service, but please let us know by emailing at info@garrilogistics.com so we can try to find a solution. These Terms apply to all visitors, users and others who wish to access or use Service.</div>
                    <div style={styles.stylepriv3}>Thank you for being responsible.</div>
                    <div style={styles.stylepriv2}>2.	Communications</div>
                    <div style={styles.stylepriv3}>By creating an Account on our Service, you agree to subscribe to newsletters, marketing or promotional materials and other information we may send. However, you may opt out of receiving any, or all, of these communications from us by following the unsubscribe link or by emailing at.</div>
                    <div style={styles.stylepriv2}>3.	Purchases</div>
                    <div style={styles.stylepriv3}>If you wish to purchase any product or service made available through Service ("Purchase"), you may be asked to supply certain information relevant to your Purchase including, without limitation, your credit card number, the expiration date of your credit card, your billing address, and your shipping information.</div>
                    <div style={styles.stylepriv3}>You represent and warrant that: (i) you have the legal right to use any credit card(s) or other payment method(s) in connection with any Purchase; and that (ii) the information you supply to us is true, correct and complete.</div>
                    <div style={styles.stylepriv3}>We may employ the use of third party services for the purpose of facilitating payment and the completion of Purchases. By submitting your information, you grant us the right to provide the information to these third parties subject to our Privacy Policy.</div>
                    <div style={styles.stylepriv3}>We reserve the right to refuse or cancel your order at any time for reasons including but not limited to: product or service availability, errors in the description or price of the product or service, error in your order or other reasons.</div>
                    <div style={styles.stylepriv3}>We reserve the right to refuse or cancel your order if fraud or an unauthorized or illegal transaction is suspected. </div>
                    <div style={styles.stylepriv2}>4.	Fee Changes</div>
                    <div style={styles.stylepriv3}>Garri, in its sole discretion and at any time, may modify Subscription fees for the Subscriptions. Any Subscription fee change will become effective at the end of the then-current Billing Cycle.</div>
                    <div style={styles.stylepriv3}>Garri will provide you with a reasonable prior notice of any change in Subscription fees to give you an opportunity to terminate your Subscription before such change becomes effective.</div>
                    <div style={styles.stylepriv3}>Your continued use of Service after Subscription fee change comes into effect constitutes your agreement to pay the modified Subscription fee amount.</div>
                    <div style={styles.stylepriv2}>5.	Refunds</div>
                    <div style={styles.stylepriv3}>Except when required by law, paid Subscription fees are non-refundable.</div>
                    <div style={styles.stylepriv2}>6.	Content</div>
                    <div style={styles.stylepriv3}>Our Service allows you to post, link, store, share and otherwise make available certain information, text, graphics, videos, or other material ("Content"). You are responsible for Content that you post on or through Service, including its legality, reliability, and appropriateness.</div>
                    <div style={styles.stylepriv3}>By posting Content on or through Service, You represent and warrant that: (i) Content is yours (you own it) and/or you have the right to use it and the right to grant us the rights and license as provided in these Terms, and (ii) that the posting of your Content on or through Service does not violate the privacy rights, publicity rights, copyrights, contract rights or any other rights of any person or entity. We reserve the right to terminate the account of anyone found to be infringing on a copyright.</div>
                    <div style={styles.stylepriv3}>You retain any and all of your rights to any Content you submit, post or display on or through Service and you are responsible for protecting those rights. We take no responsibility and assume no liability for Content you or any third party posts on or through Service. However, by posting Content using Service you grant us the right and license to use, modify, publicly perform, publicly display, reproduce, and distribute such Content on and through Service. You agree that this license includes the right for us to make your Content available to other users of Service, who may also use your Content subject to these Terms.</div>
                    <div style={styles.stylepriv3}>Garri has the right but not the obligation to monitor and edit all Content provided by users.</div>
                    <div style={styles.stylepriv3}>In addition, Content found on or through this Service are the property of Garri or used with permission. You may not distribute, modify, transmit, reuse, download, repost, copy, or use said Content, whether in whole or in part, for commercial purposes or for personal gain, without express advance written permission from us.</div>
                    <div style={styles.stylepriv2}>7.	Prohibited Uses</div>
                    <div style={styles.stylepriv3}>You may use Service only for lawful purposes and in accordance with Terms. You agree not to use Service:</div>
                    <div style={styles.stylepriv3}>(a)	In any way that violates any applicable national or international law or regulation.</div>
                    <div style={styles.stylepriv3}>(b)	For the purpose of exploiting, harming, or attempting to exploit or harm minors in any way by exposing them to inappropriate content or otherwise.</div>
                    <div style={styles.stylepriv3}>(c)	To transmit, or procure the sending of, any advertising or promotional material, including any "junk mail", "chain letter," "spam," or any other similar solicitation.</div>
                    <div style={styles.stylepriv3}>(d)	To impersonate or attempt to impersonate Company, a Company employee, another user, or any other person or entity.</div>
                    <div style={styles.stylepriv3}>(e)	In any way that infringes upon the rights of others, or in any way is illegal, threatening, fraudulent, or harmful, or in connection with any unlawful, illegal, fraudulent, or harmful purpose or activity.</div>
                    <div style={styles.stylepriv3}>(f)	To engage in any other conduct that restricts or inhibits anyone's use or enjoyment of Service, or which, as determined by us, may harm or offend Company or users of Service or expose them to liability.</div>
                    <div style={styles.stylepriv3}>Additionally, you agree not to:</div>
                    <div style={styles.stylepriv3}>(a)	Use Service in any manner that could disable, overburden, damage, or impair Service or interfere with any other party's use of Service, including their ability to engage in real time activities through Service.</div>
                    <div style={styles.stylepriv3}>(b)	Use any robot, spider, or other automatic device, process, or means to access Service for any purpose, including monitoring or copying any of the material on Service.</div>
                    <div style={styles.stylepriv3}>(c)	Use any manual process to monitor or copy any of the material on Service or for any other unauthorized purpose without our prior written consent.</div>
                    <div style={styles.stylepriv3}>(d)	Use any device, software, or routine that interferes with the proper working of Service.</div>
                    <div style={styles.stylepriv3}>(e)	Introduce any viruses, trojan horses, worms, logic bombs, or other material which is malicious or technologically harmful.</div>
                    <div style={styles.stylepriv3}>(f)	Attempt to gain unauthorized access to, interfere with, damage, or disrupt any parts of Service, the server on which Service is stored, or any server, computer, or database connected to Service.</div>
                    <div style={styles.stylepriv3}>(g)	Attack Service via a denial-of-service attack or a distributed denial-of-service attack.</div>
                    <div style={styles.stylepriv3}>(h)	Take any action that may damage or falsify Company rating.</div>
                    <div style={styles.stylepriv3}>(i)	Otherwise attempt to interfere with the proper working of Service.</div>
                    <div style={styles.stylepriv2}>8.	Analytics</div>
                    <div style={styles.stylepriv3}>We may use third-party Service Providers to monitor and analyze the use of our Service.</div>
                    <div style={styles.stylepriv3}>Google Analytics</div>
                    <div style={styles.stylepriv3}>Google Analytics is a web analytics service offered by Google that tracks and reports website traffic. Google uses the data collected to track and monitor the use of our Service. This data is shared with other Google services. Google may use the collected data to contextualise and personalise the ads of its own advertising network.</div>
                    <div style={styles.stylepriv3}>For more information on the privacy practices of Google, please visit the Google Privacy Terms web page: https://policies.google.com/privacy?hl=en</div>
                    <div style={styles.stylepriv3}>We also encourage you to review the Google's policy for safeguarding your data: https://support.google.com/analytics/answer/6004245.</div>
                    <div style={styles.stylepriv3}>Firebase</div>
                    <div style={styles.stylepriv3}>Firebase is analytics service provided by Google Inc.</div>
                    <div style={styles.stylepriv3}>You may opt-out of certain Firebase features through your mobile device settings, such as your device advertising settings or by following the instructions provided by Google in their Privacy Policy: https://policies.google.com/privacy?hl=en</div>
                    <div style={styles.stylepriv3}>For more information on what type of information Firebase collects, please visit the Google Privacy Terms web page: https://policies.google.com/privacy?hl=en</div>
                    <div style={styles.stylepriv3}>Mixpanel</div>
                    <div style={styles.stylepriv3}>Mixpanel is provided by Mixpanel Inc.</div>
                    <div style={styles.stylepriv3}>You can prevent Mixpanel from using your information for analytics purposes by opting-out. To opt-out of Mixpanel service, please visit this page: https://mixpanel.com/optout/</div>
                    <div style={styles.stylepriv3}>For more information on what type of information Mixpanel collects, please visit the Terms of Use page of Mixpanel: https://mixpanel.com/terms/</div>
                    <div style={styles.stylepriv2}>9.	No Use By Minors</div>
                    <div style={styles.stylepriv3}>Service is intended only for access and use by individuals at least eighteen (18) years old. By accessing or using any of Company, you warrant and represent that you are at least eighteen (18) years of age and with the full authority, right, and capacity to enter into this agreement and abide by all of the terms and conditions of Terms. If you are not at least eighteen (18) years old, you are prohibited from both the access and usage of Service.</div>
                    <div style={styles.stylepriv2}>10.	Accounts</div>
                    <div style={styles.stylepriv3}>When you create an account with us, you guarantee that you are above the age of 18, and that the information you provide us is accurate, complete, and current at all times. Inaccurate, incomplete, or obsolete information may result in the immediate termination of your account on Service.</div>
                    <div style={styles.stylepriv3}>You are responsible for maintaining the confidentiality of your account and password, including but not limited to the restriction of access to your computer and/or account. You agree to accept responsibility for any and all activities or actions that occur under your account and/or password, whether your password is with our Service or a third-party service. You must notify us immediately upon becoming aware of any breach of security or unauthorized use of your account.</div>
                    <div style={styles.stylepriv3}>You may not use as a username the name of another person or entity or that is not lawfully available for use, a name or trademark that is subject to any rights of another person or entity other than you, without appropriate authorization. You may not use as a username any name that is offensive, vulgar or obscene.</div>
                    <div style={styles.stylepriv3}>We reserve the right to refuse service, terminate accounts, remove or edit content, or cancel orders in our sole discretion.</div>
                    <div style={styles.stylepriv2}>11.	Intellectual Property</div>
                    <div style={styles.stylepriv3}>Service and its original content (excluding Content provided by users), features and functionality are and will remain the exclusive property of Garri and its licensors. Service is protected by copyright, trademark, and other laws of the United States. Our trademarks and trade dress may not be used in connection with any product or service without the prior written consent of Garri.</div>
                    <div style={styles.stylepriv2}>12.	Copyright Policy</div>
                    <div style={styles.stylepriv3}>We respect the intellectual property rights of others. It is our policy to respond to any claim that Content posted on Service infringes on the copyright or other intellectual property rights ("Infringement") of any person or entity.</div>
                    <div style={styles.stylepriv3}>If you are a copyright owner, or authorized on behalf of one, and you believe that the copyrighted work has been copied in a way that constitutes copyright infringement, please submit your claim via email to info@garrilogistics.com, with the subject line: "Copyright Infringement" and include in your claim a detailed description of the alleged Infringement as detailed below, under "DMCA Notice and Procedure for Copyright Infringement Claims"</div>
                    <div style={styles.stylepriv3}>You may be held accountable for damages (including costs and attorneys' fees) for misrepresentation or bad-faith claims on the infringement of any Content found on and/or through Service on your copyright.</div>
                    <div style={styles.stylepriv2}>13.	DMCA Notice and Procedure for Copyright Infringement Claims</div>
                    <div style={styles.stylepriv3}>You may submit a notification pursuant to the Digital Millennium Copyright Act (DMCA) by providing our Copyright Agent with the following information in writing (see 17 U.S.C 512(c)(3) for further detail):</div>
                    <div style={styles.stylepriv3}>(a)	an electronic or physical signature of the person authorized to act on behalf of the owner of the copyright's interest;</div>
                    <div style={styles.stylepriv3}>(b)	a description of the copyrighted work that you claim has been infringed, including the URL (i.e., web page address) of the location where the copyrighted work exists or a copy of the copyrighted work;</div>
                    <div style={styles.stylepriv3}>(c)	identification of the URL or other specific location on Service where the material that you claim is infringing is located;</div>
                    <div style={styles.stylepriv3}>(d)	your address, telephone number, and email address;</div>
                    <div style={styles.stylepriv3}>(e)	a statement by you that you have a good faith belief that the disputed use is not authorized by the copyright owner, its agent, or the law;</div>
                    <div style={styles.stylepriv3}>(f)	a statement by you, made under penalty of perjury, that the above information in your notice is accurate and that you are the copyright owner or authorized to act on the copyright owner's behalf.</div>
                    <div style={styles.stylepriv3}>You can contact our Copyright Agent via email at info@garrilogistics.com</div>
                    <div style={styles.stylepriv2}>14.	Error Reporting and Feedback</div>
                    <div style={styles.stylepriv3}>You may provide us either directly at info@garrilogistics.com or via third party sites and tools with information and feedback concerning errors, suggestions for improvements, ideas, problems, complaints, and other matters related to our Service ("Feedback"). You acknowledge and agree that: (i) you shall not retain, acquire or assert any intellectual property right or other right, title or interest in or to the Feedback; (ii) Company may have development ideas similar to the Feedback; (iii) Feedback does not contain confidential information or proprietary information from you or any third party; and (iv) Company is not under any obligation of confidentiality with respect to the Feedback. In the event the transfer of the ownership to the Feedback is not possible due to applicable mandatory laws, you grant Company and its affiliates an exclusive, transferable, irrevocable, free-of-charge, sub-licensable, unlimited and perpetual right to use (including copy, modify, create derivative works, publish, distribute and commercialize) Feedback in any manner and for any purpose.</div>
                    <div style={styles.stylepriv3}>The third party sites and tools mentioned above include the following:</div>
                    <div style={styles.stylepriv3}>Firebase Crashlytics</div>
                    <div style={styles.stylepriv3}>Firebase Crashlytics is bug reporting service provided by Google Inc.</div>
                    <div style={styles.stylepriv3}>You may opt-out of certain Firebase features through your mobile device settings, such as your device advertising settings or by following the instructions provided by Google in their Privacy Policy: https://policies.google.com/privacy?hl=en</div>
                    <div style={styles.stylepriv3}>For more information on what type of information Firebase collects, please visit the Google Privacy Terms web page: https://policies.google.com/privacy?hl=en</div>
                    <div style={styles.stylepriv2}>15.	Links To Other Web Sites</div>
                    <div style={styles.stylepriv3}>Our Service may contain links to third party web sites or services that are not owned or controlled by Garri.</div>
                    <div style={styles.stylepriv3}>Garri has no control over, and assumes no responsibility for the content, privacy policies, or practices of any third party web sites or services. We do not warrant the offerings of any of these entities/individuals or their websites.</div>
                    <div style={styles.stylepriv3}>YOU ACKNOWLEDGE AND AGREE THAT Garri SHALL NOT BE RESPONSIBLE OR LIABLE, DIRECTLY OR INDIRECTLY, FOR ANY DAMAGE OR LOSS CAUSED OR ALLEGED TO BE CAUSED BY OR IN CONNECTION WITH USE OF OR RELIANCE ON ANY SUCH CONTENT, GOODS OR SERVICES AVAILABLE ON OR THROUGH ANY SUCH THIRD PARTY WEB SITES OR SERVICES.</div>
                    <div style={styles.stylepriv3}>WE STRONGLY ADVISE YOU TO READ THE TERMS OF SERVICE AND PRIVACY POLICIES OF ANY THIRD PARTY WEB SITES OR SERVICES THAT YOU VISIT.</div>
                    <div style={styles.stylepriv2}>16.	Disclaimer Of Warranty </div>
                    <div style={styles.stylepriv3}>THESE SERVICES ARE PROVIDED BY COMPANY ON AN "AS IS" AND "AS AVAILABLE" BASIS. COMPANY MAKES NO REPRESENTATIONS OR WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED, AS TO THE OPERATION OF THEIR SERVICES, OR THE INFORMATION, CONTENT OR MATERIALS INCLUDED THEREIN. YOU EXPRESSLY AGREE THAT YOUR USE OF THESE SERVICES, THEIR CONTENT, AND ANY SERVICES OR ITEMS OBTAINED FROM US IS AT YOUR SOLE RISK.</div>
                    <div style={styles.stylepriv3}>NEITHER COMPANY NOR ANY PERSON ASSOCIATED WITH COMPANY MAKES ANY WARRANTY OR REPRESENTATION WITH RESPECT TO THE COMPLETENESS, SECURITY, RELIABILITY, QUALITY, ACCURACY, OR AVAILABILITY OF THE SERVICES. WITHOUT LIMITING THE FOREGOING, NEITHER COMPANY NOR ANYONE ASSOCIATED WITH COMPANY REPRESENTS OR WARRANTS THAT THE SERVICES, THEIR CONTENT, OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE SERVICES WILL BE ACCURATE, RELIABLE, ERROR-FREE, OR UNINTERRUPTED, THAT DEFECTS WILL BE CORRECTED, THAT THE SERVICES OR THE SERVER THAT MAKES IT AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS OR THAT THE SERVICES OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE SERVICES WILL OTHERWISE MEET YOUR NEEDS OR EXPECTATIONS.</div>
                    <div style={styles.stylepriv3}>COMPANY HEREBY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, STATUTORY, OR OTHERWISE, INCLUDING BUT NOT LIMITED TO ANY WARRANTIES OF MERCHANTABILITY, NON-INFRINGEMENT, AND FITNESS FOR PARTICULAR PURPOSE.</div>
                    <div style={styles.stylepriv3}>THE FOREGOING DOES NOT AFFECT ANY WARRANTIES WHICH CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW.</div>
                    <div style={styles.stylepriv2}>17.	Limitation Of Liability</div>
                    <div style={styles.stylepriv3}>EXCEPT AS PROHIBITED BY LAW, YOU WILL HOLD US AND OUR OFFICERS, DIRECTORS, EMPLOYEES, AND AGENTS HARMLESS FOR ANY INDIRECT, PUNITIVE, SPECIAL, INCIDENTAL, OR CONSEQUENTIAL DAMAGE, HOWEVER IT ARISES (INCLUDING ATTORNEYS' FEES AND ALL RELATED COSTS AND EXPENSES OF LITIGATION AND ARBITRATION, OR AT TRIAL OR ON APPEAL, IF ANY, WHETHER OR NOT LITIGATION OR ARBITRATION IS INSTITUTED), WHETHER IN AN ACTION OF CONTRACT, NEGLIGENCE, OR OTHER TORTIOUS ACTION, OR ARISING OUT OF OR IN CONNECTION WITH THIS AGREEMENT, INCLUDING WITHOUT LIMITATION ANY CLAIM FOR PERSONAL INJURY OR PROPERTY DAMAGE, ARISING FROM THIS AGREEMENT AND ANY VIOLATION BY YOU OF ANY FEDERAL, STATE, OR LOCAL LAWS, STATUTES, RULES, OR REGULATIONS, EVEN IF COMPANY HAS BEEN PREVIOUSLY ADVISED OF THE POSSIBILITY OF SUCH DAMAGE. EXCEPT AS PROHIBITED BY LAW, IF THERE IS LIABILITY FOUND ON THE PART OF COMPANY, IT WILL BE LIMITED TO THE AMOUNT PAID FOR THE PRODUCTS AND/OR SERVICES, AND UNDER NO CIRCUMSTANCES WILL THERE BE CONSEQUENTIAL OR PUNITIVE DAMAGES. SOME STATES DO NOT ALLOW THE EXCLUSION OR LIMITATION OF PUNITIVE, INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE PRIOR LIMITATION OR EXCLUSION MAY NOT APPLY TO YOU.</div>
                    <div style={styles.stylepriv2}>18.	Termination</div>
                    <div style={styles.stylepriv3}>We may terminate or suspend your account and bar access to Service immediately, without prior notice or liability, under our sole discretion, for any reason whatsoever and without limitation, including but not limited to a breach of Terms.</div>
                    <div style={styles.stylepriv3}>If you wish to terminate your account, you may simply discontinue using Service.</div>
                    <div style={styles.stylepriv3}>All provisions of Terms which by their nature should survive termination shall survive termination, including, without limitation, ownership provisions, warranty disclaimers, indemnity and limitations of liability.</div>
                    <div style={styles.stylepriv2}>19.	Governing Law</div>
                    <div style={styles.stylepriv3}>These Terms shall be governed and construed in accordance with the laws of State of California without regard to its conflict of law provisions.</div>
                    <div style={styles.stylepriv3}>Our failure to enforce any right or provision of these Terms will not be considered a waiver of those rights. If any provision of these Terms is held to be invalid or unenforceable by a court, the remaining provisions of these Terms will remain in effect. These Terms constitute the entire agreement between us regarding our Service and supersede and replace any prior agreements we might have had between us regarding Service.</div>
                    <div style={styles.stylepriv2}>20.	Changes To Service</div>
                    <div style={styles.stylepriv3}>We reserve the right to withdraw or amend our Service, and any service or material we provide via Service, in our sole discretion without notice. We will not be liable if for any reason all or any part of Service is unavailable at any time or for any period. From time to time, we may restrict access to some parts of Service, or the entire Service, to users, including registered users.</div>
                    <div style={styles.stylepriv2}>21.	Amendments To Terms</div>
                    <div style={styles.stylepriv3}>We may amend Terms at any time by posting the amended terms on this site. It is your responsibility to review these Terms periodically.</div>
                    <div style={styles.stylepriv3}>Your continued use of the Platform following the posting of revised Terms means that you accept and agree to the changes. You are expected to check this page frequently so you are aware of any changes, as they are binding on you.</div>
                    <div style={styles.stylepriv3}>By continuing to access or use our Service after any revisions become effective, you agree to be bound by the revised terms. If you do not agree to the new terms, you are no longer authorized to use Service.</div>
                    <div style={styles.stylepriv2}>22.	Waiver And Severability</div>
                    <div style={styles.stylepriv3}>No waiver by Company of any term or condition set forth in Terms shall be deemed a further or continuing waiver of such term or condition or a waiver of any other term or condition, and any failure of Company to assert a right or provision under Terms shall not constitute a waiver of such right or provision.</div>
                    <div style={styles.stylepriv3}>If any provision of Terms is held by a court or other tribunal of competent jurisdiction to be invalid, illegal or unenforceable for any reason, such provision shall be eliminated or limited to the minimum extent such that the remaining provisions of Terms will continue in full force and effect.</div>
                    <div style={styles.stylepriv2}>23.	Acknowledgement</div>
                    <div style={styles.stylepriv3}>BY USING SERVICE OR OTHER SERVICES PROVIDED BY US, YOU ACKNOWLEDGE THAT YOU HAVE READ THESE TERMS OF SERVICE AND AGREE TO BE BOUND BY THEM.</div>
                    <div style={styles.stylepriv2}>24.	Contact Us</div>
                    <div style={styles.stylepriv3}>Please send your feedback, comments, requests for technical support:</div>
                    <div style={styles.stylepriv3}>By email: info@garrilogistics.com.</div>


                </div>
                </div>
        );
    }
}

const styles = {

    stylepriv1: {
        fontWeight: 'bold',
        fontSize: 24, 
        color: 'black'
    }, 
    stylepriv2: {
        fontWeight: 'bold',
        fontSize: 16,
        color: 'black',
        margin: 20,
        marginLeft: 30
    },
    stylepriv3: {

        fontSize: 14,
        color: 'black',
        margin: 5,
        marginLeft: 30
    }

}

const chat = {
    display: "flex",
    flex: 3,
    flexDirection: "column",
    borderWidth: "1px",
    borderColor: "#ccc",
    borderRightStyle: "solid",
    borderLeftStyle: "solid",
    height: 500,//window.innerHeight * 0.8,//'80%',
    width: 300,//window.innerWidth * 0.3,//'30%'

    }

const button = {
    borderRadius: 5,
    width: 250,
    height: 40,
    background: 'rgb(47,191,80)',
    color: 'white',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 0, 

}
const buttonDec = {
    borderRadius: 5,
    width: 250,
    height: 40,
    background: 'rgb(244,128,36)',
    color: 'white',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 0, 

}

export default TermsOfUse;