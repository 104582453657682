import React, {useEffect, useState} from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Card,
  CardContent,
  Grid,
  Typography,
  colors,
  makeStyles,
  Stepper,
  Step,
  StepLabel, 
  StepContent 
} from '@material-ui/core';
import QuoteStatus from 'src/QuoteStatus'
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import MoneyIcon from '@material-ui/icons/Money';
import moment from 'moment';
import Firebase from 'src/Firebase'
import TableAssist from 'src/utils/TableAssist'

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%'
  },
  avatar: {
    backgroundColor: colors.red[600],
    height: 56,
    width: 56
  },
  differenceIcon: {
    color: colors.red[900]
  },
  differenceValue: {
    color: colors.red[900],
    marginRight: theme.spacing(1)
  }
}));

function renderStatus(item) {
  let s = item.state 
  if (item.isDelayed) {
    return 'Temporarily Delayed'
  }
  if (s == 0) {
    return 'Quote Requested'
  } else if (s==1) {
    return 'Quote Offered'
  } else if (s==2) {
    return 'Quote Accepted'
  } else if (s==3) {
    return 'Offered to Transporters'
  } else if (s==4) {
    return 'Transporter Placed Bid'
  } else if (s==5) {
    return 'Transporter Accepted Job'
  } else if (s==6) {
    return 'Job Assigned To Driver'
  } else if (s==7) {  
    return 'Driver en Route'
  } else if (s==8) {
    return 'Driver Loaded Truck'
  } else if (s==9) {
    return 'Driver Delivered Load'
  } else {
    return 'N/A'
  }
}

function getColor(s) {

  var color = 'rgb(222,222,222)'

  if (s == 1) {
    
    color = 'rgb(63,81,181)'
  } else if (s == 2){

    color = '#F32013'
  } else if (s == 3) {

    color = 'rgb(255,12,39)'
  } else if (s==4) {

    color = '#F32013'
  } else if (s==5) {

    color = 'rgb(237,28,36)'
  } else if (s == 6) {

    color = 'rgb(128,255,255)'
  } else if (s == 7) {

    color = 'rgb(0,128,0)'
  } else if (s == 8) {

    color = 'rgb(128,64,0)'
  } else if (s==9) {

    color = 'rgb(67,160,71)'
  }
  return color 
}

function renderNameNoCountry(name, t1, t2) {
  if (t1 != null && t2 != null) {
    if (t1 == t2) {
      if (name.endsWith(t1) && t1 != name && name.length > 2 + t1.length) {
        return name.substring(0, name.length - (2 + t1.length)) 
      }
    }
  }
  return name
}

function padDigits(number) {
  return Array(Math.max(3 - String(number).length + 1, 0)).join(0) + number;
}

function getIdDisplay(val) {
  if ('internalShipperId' in val && 'shortIdShipper' in val) {
    return val.internalShipperId + '-' + padDigits(val.shortIdShipper)
  } else {
    return val.shortId
  }
}

const JobCard = ({ className, etest, itemProp, isAdmin, resistProp, ...rest }) => {
  const classes = useStyles();
  const [txt, setTxt] = useState({'ww': 45, 'aa': 'jfia3i'})
  const [num, setNum] = useState(53)

  useEffect(() => {

  });

  return (
    <Card
      className={clsx(classes.root, className)}
      style={{position: 'relative',}}
      {...rest}

    >
      <CardContent style={{ display: 'flex', flexDirection: 'column'}}>
        <Grid
          container
          flexDirection="column"
          spacing={3}
        >
          <Grid item>
            <Typography
              color="textSecondary"
              gutterBottom
              variant="h3"
            >
              {itemProp.roundTripLink && itemProp.roundTripLink != -1 ?
              getIdDisplay(itemProp) + ' (Roundtrip: ' + itemProp.internalShipperId + '-' + TableAssist.padDigits(itemProp.shortIdShipperLINK) + ')'
              : getIdDisplay(itemProp)}
            </Typography>
            <div style={{display: 'inline-block', backgroundColor: getColor(itemProp.state), alignItems: 'center', justifyContent: 'center',
       borderRadius: 10,  height: 40, padding: 10}}><a style={{color: 'white', fontSize: 14}}>{renderStatus(itemProp)}</a></div>
           <Stepper style={{backgroundColor: 'white', borderRadius: 10}}  orientation="vertical">
           <Step active={true} key={0}>
            <StepLabel>{renderNameNoCountry(itemProp.startName, itemProp.startCountry, itemProp.endCountry)}</StepLabel>
            <StepContent>{moment(itemProp.startDateEarly).format('DD/MM/YYYY h:mm a')}</StepContent>
          </Step>
          <Step active={true} key={1}>
            <StepLabel>{renderNameNoCountry(itemProp.endName, itemProp.startCountry, itemProp.endCountry)}</StepLabel>
            <StepContent>{moment(itemProp.endDateEarly).format('DD/MM/YYYY h:mm a')}</StepContent>
          </Step>
        </Stepper>
          </Grid>

        </Grid>
        <div style={{alignSelf: 'center'}}onClick={isAdmin ? () => window.open(Firebase.adminip + 'info/' + TableAssist.renderIdValue(itemProp)) : () => window.open(Firebase.ip + 'info/' + TableAssist.renderIdValue(itemProp))} style={{display: 'inline-block', backgroundColor: 'rgb(252,197,37)', alignItems: 'center', justifyContent: 'center', display: 'flex',
       borderRadius: 10,  height: 35, width: 'calc(100% - 10px)', padding: 10,position: 'absolute', bottom: 5, left:5, right: 5}}><a style={{color: 'white', fontSize: 14}}>{itemProp.state < 9 ? 'Live Track' : 'View Summary'}</a></div>

       
      </CardContent>
    </Card>
  );
};

JobCard.propTypes = {
  className: PropTypes.string
};

export default JobCard;
