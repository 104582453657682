import React, { useState, useEffect } from 'react';
import {useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Avatar,
  Box,
  Card,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  makeStyles
} from '@material-ui/core';
import getInitials from 'src/utils/getInitials';

const useStyles = makeStyles((theme) => ({
  root: {},
  avatar: {
    marginRight: theme.spacing(2)
  }
}));

const TransporterTable = ({ className, customers, initialSelectedIds, updateParentIds, ...rest }) => {
  const classes = useStyles();


  const selIdStart = customers.map((item, idx) =>{
    if (item.companyName == 'WGHS') {
      return (item.userId)
    }

  })


  const [selectedCustomerIds, setSelectedCustomerIds] = useState(initialSelectedIds);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);
  const navigate = useNavigate();

  const handleSelectAll = (event) => {
    let newSelectedCustomerIds;

    if (event.target.checked) {
      newSelectedCustomerIds = customers.map((customer) => customer.userId);
    } else {
      newSelectedCustomerIds = [];
    }

    setSelectedCustomerIds(newSelectedCustomerIds);
    updateParentIds(newSelectedCustomerIds);
  };

  const handleSelectOne = (event, id) => {
    const selectedIndex = selectedCustomerIds.indexOf(id);
    let newSelectedCustomerIds = [];

    if (selectedIndex === -1) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(selectedCustomerIds, id);
    } else if (selectedIndex === 0) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(selectedCustomerIds.slice(1));
    } else if (selectedIndex === selectedCustomerIds.length - 1) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(selectedCustomerIds.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(
        selectedCustomerIds.slice(0, selectedIndex),
        selectedCustomerIds.slice(selectedIndex + 1)
      );
    }

    setSelectedCustomerIds(newSelectedCustomerIds);
    updateParentIds(newSelectedCustomerIds);
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  function renderUserName(v) {
    var rets = ''
    if ('firstName' in v) {
      rets += v.firstName
    } 
    if ('middleName' in v) {
      rets += ' ' + v.middleName
    } 
    if ('lastName' in v) {
      rets += ' ' + v.lastName
    }
    return rets
  }

  function renderAssignedStat(customer) {
    if (initialSelectedIds.indexOf(customer.userId) !== -1){
      return <div style={{backgroundColor: 'cyan', alignItems: 'center', justifyContent: 'center', display: 'flex',
       borderRadius: 10, width: 120, height: 40}}><a style={{color: 'white', fontSize: 20}}>Offered</a></div>
    } else {
      return <div style={{backgroundColor: 'red', alignItems: 'center', justifyContent: 'center', display: 'flex',
       borderRadius: 10, width: 120, height: 40}}><a style={{color: 'white', fontSize: 20}}>Not Offered</a></div>
    }
  }

  useEffect(() => {
    setSelectedCustomerIds(initialSelectedIds)
}, [initialSelectedIds])

  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <PerfectScrollbar>
        <Box minWidth={1050}>
          <Table>
            <TableHead>
              <TableRow>
              <TableCell padding="checkbox">
                  <Checkbox
                    checked={selectedCustomerIds.length === customers.length}
                    color="primary"
                    indeterminate={
                      selectedCustomerIds.length > 0
                      && selectedCustomerIds.length < customers.length
                    }
                    onChange={handleSelectAll}
                  />
                </TableCell>
              <TableCell>
                  Transporter Name
                </TableCell>
                <TableCell>
                  Offer Status
                </TableCell>
                <TableCell>
                  Transporter Phone
                </TableCell>
                <TableCell>
                  Average Rating
                </TableCell>
                <TableCell>
                  Transporter User ID
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {customers.slice(page * limit, page * limit + limit).map((customer) => (
                <TableRow
                  hover
                  key={customer.userId}
                  selected={selectedCustomerIds.indexOf(customer.userId) !== -1}
                >
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={selectedCustomerIds.indexOf(customer.userId) !== -1}
                      onChange={(event) => handleSelectOne(event, customer.userId)}
                      value="true"
                    />
                  </TableCell>
                  <TableCell>
                    {renderUserName(customer)}
                  </TableCell>
                  <TableCell >
                    {renderAssignedStat(customer)}
                  </TableCell>
                  <TableCell>
                    {customer.phone}
                  </TableCell>
                  <TableCell>
                    {'Not Rated'}
                  </TableCell>
                  <TableCell>
                    {customer.userId}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={customers.length}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[5, 10, 25]}
      />
    </Card>
  );
};

TransporterTable.propTypes = {
  className: PropTypes.string,
  customers: PropTypes.array.isRequired
};

export default TransporterTable;
