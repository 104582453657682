import React from 'react';
import {useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Button,
  Card,
  CardContent,
  TextField,
  InputAdornment,
  SvgIcon,
  Tooltip,
  makeStyles
} from '@material-ui/core';
import Help from '@material-ui/icons/Help';
import { Search as SearchIcon } from 'react-feather';
import MyContext from 'src/MyContext';

const useStyles = makeStyles((theme) => ({
  root: {},
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  }
}));



const Toolbar = ({ className, ...rest }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  return (
    <MyContext.Consumer>{context => (
    <div
      className={clsx(classes.root, className)}
      {...rest}
    >
            {context.isLimited >= 0 ? null :
      <Box
        display="flex"
        justifyContent="flex-end"
      >


              <div style={{marginLeft: 10, marginRight: 2}}>        
              <Button
                color="primary"
                variant="contained"
                onClick={ () => {
                  navigate('/app/createforshipper');
                }}
              >
                Create Job For Shipper
              </Button></div>
              <div style={{marginLeft: 10, marginRight: 2}}>        <Button
                color="primary"
                variant="contained"
                onClick={ () => {
                  navigate('/app/makeltc');
                }}
              >
                New Long Term Contract
              </Button>
              </div>
    

      </Box>      
        }
    </div>
    )}</MyContext.Consumer>
  );
};

Toolbar.propTypes = {
  className: PropTypes.string
};

export default Toolbar;
