import React, {useState} from 'react';
import { Link as RouterLink, useNavigate, useLocation} from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';

import LocationOnIcon from '@material-ui/icons/LocationOn';
import {
  Box,
  Button,
  Checkbox,
  Container,
  FormHelperText,
  Link,
  TextField,
  Typography,
  makeStyles,
  Grid,
  Select,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  InputLabel
} from '@material-ui/core';
import Page from 'src/components/Page';
import AddBox from '@material-ui/icons/AddBox';
import Cancel from '@material-ui/icons/Cancel';
import Edit from '@material-ui/icons/Edit';
import parse from 'autosuggest-highlight/parse';
import throttle from 'lodash/throttle';
import Firebase from 'src/Firebase'
import MuiPhoneNumber from 'material-ui-phone-number'
import MyContext from 'src/MyContext'
import CircularProgress from '@material-ui/core/CircularProgress';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import NameDocumentDialog from './NameDocumentDialog';
const filter = createFilterOptions();


const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/


const autocompleteService = { current: null };
const geocoder = {current: null};



const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  }
}));


const AddUserDialog = (props) => {
  const navigate = useNavigate();
  const [dialogWorking, setDialogWorking] = React.useState(false)
  const [value, setValue] = React.useState(null);
  const [uploadsHappening, setUploadsHappening] = React.useState(false);
  const docDiaRef = React.useRef(null);
  const inputRef = React.useRef(null);
  const [showDocumentDialog, setShowDocumentDialog] = React.useState(false)
  const [documentPlaceholder, setDocumentPlaceholder] = React.useState('')
  const [targetDocumentIndex, setTargetDocumentIndex] = React.useState(-1)


  function getAssociationId(userId, users) {
    var ret = null 
    users.map((item, idx) => {
      if (item.userId == userId) {
        ret = item.associationId
      }
    })
    return ret 
  } 

  function getAssociationOptionName(userId, users, assocs) {
    var ret = null 
    let assocId = getAssociationId(userId, users)
    if (assocId != null) {
      assocs.map((item, idx) => {
        if (item.associationId == assocId) {
          ret = item.companyName
        }
      })
    }

    return ret 
  }

  function getAssociationOptions(adminAssoc) {
    
    return [{'associationId': '0', 'companyName': 'None'}].concat(adminAssoc)
  }

  function getCompanyName(id, users) {
    var companyName = 'Unassigned'
    users.map((item, idx) => {
      if (item.userId == id) {
        companyName = item.companyName 
      }
    })
    return companyName 
  }

  function tryConfirm() {

  }

  function updateDocumentName(documentList, x, setFunction) {
    if (x == '') {
      return 
    }
    var newList = documentList.map((item, idx) => {
      if (idx == targetDocumentIndex) {
        var newItem = item 
        if (item.linkval) {
          newItem['docname'] = x 
        } else {
          newItem = {}
          newItem['linkval'] = item 
          newItem['docName'] = x
        }
        return newItem
      }
      return item 
    })
    setFunction('documentList', newList)
  }

  function addUser(values) {
    console.log(values)
    console.log(Firebase.userInfo)
    //alert(JSON.stringify(values))

    setDialogWorking(true)
    //Firebase.gt()
    var v = values 
    if (v['dob']) {
      v['dob'] = Date.parse(v['dob'])
    }

    if (v.userType == 'Transporter') {
      v['userType'] = 1
    } else if (v.userType == 'Shipper') {
      v['userType'] = 2
    } else if (v.userType == 'Driver') {
      v['userType'] = 3
      if (v['assignedTransporter'] != null && v['assignedTransporter'] != '0') {
        v['targetTransporter'] = v['assignedTransporter']
        
      } else {
        v['targetTransporter'] = null
      }
    }
    v['registrationPhone'] = v.phone.toString()
    v['phone'] = v.phone.toString()
    console.log(v)
   
    let s = async (x) => {
      
      if (x.result == -1) {
        setDialogWorking(false)
        alert('A user with this phone number already exists.')
      } else {
        if (props.limitedAccessFunction) {
          await props.limitedAccessFunction()
        }
        setDialogWorking(false)
        let newData = x.update 
        props.onConfirm(newData)
        props.onClose()

      }
    }

    let f = () => {
      setDialogWorking(false)
      alert('Could not create user.')
    }

    Firebase.adminAddUser(v, (x) => s(x), () => f())

  }

  async function uploadImageByUri(uri, idx, quoteId, mime = 'image/jpg') {

    var bucket = 'admin_for_userdoc_' + quoteId 
  
    let name = Date.now() + '_' + idx + '.jpg'
  
    const imageRef = Firebase.storage.ref(bucket).child(name)
    let aa = await imageRef.put(uri, { contentType: mime }).then(() => {
        return imageRef.getDownloadURL()
    }).then((url) => {
        return url
    })
        .catch((error) => {
  
            alert('Error uploading files. Check internet connection')
            console.log("error" + error);
        })
    console.log('w' + aa)
    return aa
  }
  
  async function handleSelectedFolder(event, docList, setFunction) {
      
  
    console.log(event)
    console.log(event.target)
    console.log(event.target.files)
    var targetData = Array.from(event.target.files)
    
    setUploadsHappening(true)
  
    var promises = await targetData.map(async (x, idx) => {
      console.log(x)
      let res = await uploadImageByUri(x,  idx, Date.now() + '-' + Math.random() + '-' + Firebase.uid)
      var xf = {}
      if (x.name) {
        xf['docname'] = x.name
      } else {
        xf['docname'] = 'Document ' + (idx + docList.length + 1)
      }
  
      xf['linkval'] = res
      return xf 
    });
    let newarr = await Promise.all(promises)
  
    var newList = docList.concat(newarr)
    setFunction('documentList', newList)
    setUploadsHappening(false)
    //this.uploadFile(targetFile)
  }
  

  return (
    <MyContext.Consumer>{context => (
      <div>
      <Formik
      enableReinitialize
id='f2'
  initialValues={{
   firstName: '',
   phone: '',
   userType: props.userType,
    companyName: props.assignedTransporter ? getCompanyName(props.assignedTransporter, context.users ? context.users : []) : '',
    verified: false,
    assignedTransporter: props.assignedTransporter && props.userType == 'Driver' ? props.assignedTransporter : null,
    associationId: props.assignedTransporter ? getAssociationId(props.assignedTransporter, context.users ? context.users : []) : null,
    associationName: props.assignedTransporter ? getAssociationOptionName(props.assignedTransporter, context.users ? context.users : [], context.associations ? context.associations: []) : null,
    documentList: []
  }}
  validationSchema={
    Yup.object().shape({
      companyName: Yup.string().max(255).required('Company name required'),
      firstName: Yup.string().max(255).required('Name required'),
      phone: Yup.string().max(255).required('Phone number required'),
      userType: Yup.string().max(255).required('User type required')
    })
  }
  onSubmit={(values) => {
    //alert('try')
    console.log('trysub')
    console.log(values)
    addUser(values)
    //verifyCode(values.code)
    //navigate('/app/dashboard', { replace: true });
  }}
>
  {({
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    isSubmitting2,
    touched,
    values,
    setFieldValue
  }) => (
    <form id='fu2' onSubmit={handleSubmit}>
            <Dialog
            open={props.open}
            onClose={() => props.onClose()}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
               <NameDocumentDialog
        open={showDocumentDialog}
        ref={docDiaRef}
        onClose={() => setShowDocumentDialog(false)}
        alertTitle={'Set document name, or leave blank for default name \"Document ' + (targetDocumentIndex + 1) + '\"'}
        alertCancelText={'Cancel'}
        alertConfirmText={'Confirm'}
        documentName={documentPlaceholder}
        onConfirm={(x) => updateDocumentName(values.documentList, x, (x,y) => setFieldValue(x,y))}
        />
            <DialogTitle id="alert-dialog-title">{props.alertTitle}</DialogTitle>
            <DialogContent>   
              <DialogContentText id="alert-dialog-description">
                {dialogWorking ? 'Performing Operation...' : props.alertBody}
                {dialogWorking ? <div style={{alignItems: 'center', justifyContent: 'center'}}><CircularProgress/></div> : <div/>}
              </DialogContentText>



              <MuiPhoneNumber defaultCountry={'et'}
                regions={['america','africa']}
                onlyCountries={['us', 'et']}
                autoFormat={false}
                disableAreaCodes={true}
                  error={Boolean(touched.email && errors.email)}
                  fullWidth
                  helperText={touched.email && errors.email}
                  label="Phone"
                  id="phone"
                  margin="normal"
                  name="phone"
                  onBlur={handleBlur}
                  onChange={(e) => setFieldValue('phone', e)}
                  value={values.phone}
                  variant="outlined"
                />

                {values.phone in props.phoneDict ? <a>This user already exists.</a> : null}

<TextField
                error={Boolean(touched.firstName && errors.firstName)}
                fullWidth
                id="firstName"
                helperText={touched.firstName && errors.firstName}
                label="First Name"
                margin="normal"
                name="firstName"
                onBlur={handleBlur}
                onChange={(e) => {
                  setFieldValue('firstName', e.target.value)
                  if (props.userType == 'Transporter') {
                    setFieldValue('companyName', e.target.value + ' ' + values.lastName + ' Transport')
                  }
                  
                }}
                value={values.firstName}
                variant="outlined"
              />

              <TextField
                error={Boolean(touched.lastName && errors.lastName)}
                fullWidth
                id="lastName"
                helperText={touched.lastName && errors.lastName}
                label="Last Name"
                margin="normal"
                name="lastName"
                onBlur={handleBlur}
                onChange={(e) => {
                  setFieldValue('lastName', e.target.value)
                  if (props.userType == 'Transporter') {
                    setFieldValue('companyName', values.firstName + ' ' + e.target.value + ' Transport')
                  }
                  
                }}
                value={values.lastName}
                variant="outlined"
              />

              {props.userType == 'Transporter' ? 
              <div>
                <Autocomplete
      value={value}
      onChange={(event, newValue) => {
        if (typeof newValue === 'string') {
          setValue({
            companyName: newValue,
          });
          setFieldValue('associationId', newValue.associationId)
          setFieldValue('associationName', newValue.companyName)
        } else if (newValue && newValue.inputValue) {
          // Create a new value from the user input
          setValue({
            companyName: newValue.inputValue,
          });
          setFieldValue('associationId', -1)
          setFieldValue('associationName', newValue.inputValue)
        } else {
          setValue(newValue);
          if (newValue == null) {
            setFieldValue('associationId', '0')
            setFieldValue('associationName', 'None')
          } else {
            setFieldValue('associationId', newValue.associationId)
            setFieldValue('associationName', newValue.companyName)
          }
        }
        //console.log(event.target.value)
        //console.log(newValue)
      }}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);

        // Suggest the creation of a new value
        if (params.inputValue !== '') {
          filtered.push({
            inputValue: params.inputValue,
            companyName: `Add "${params.inputValue}"`,
          });
        }

        return filtered;
      }}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      id="free-solo-with-text-demo"
      options={getAssociationOptions(context.associations)}
      getOptionLabel={(option) => {
        // Value selected with enter, right from the input
        if (typeof option === 'string') {
          return option;
        }
        // Add "xxx" option created dynamically
        if (option.inputValue) {
          return option.inputValue;
        }
        // Regular option
        return option.companyName;
      }}
      renderOption={(option) => option.companyName}
      freeSolo
      renderInput={(params) => (
        <TextField {...params} error={Boolean(touched.associationId && errors.associationId)}
        fullWidth
        id="associationId"
        helperText={touched.associationId && errors.associationId}
        label="Association"
        margin="normal"
        name="associationId"
        onBlur={handleBlur}
        onChange={(e) => {
          console.log(e.target)
        }}
        value={values.associationId}
        variant="outlined"/>
      )}
    />
              <Box
              alignItems="center"
              display="flex"
              ml={-1}
            >
              <Checkbox
                checked={values.ownerOperator}
                name="ownerOperator"
                onChange={handleChange}
              />
              <Typography
                color="textSecondary"
                variant="body1"
              >
                User is an Owner Operator
              </Typography>
            </Box>
                </div>
              : null}


{ values.documentList && values.documentList.length > 0 ? <div style={{flexDirection: 'column', display: 'flex'}}><InputLabel>User Documents</InputLabel>{
            values.documentList.map((item, idx) => {
              return <div style={{flexDirection: 'row'}}><img style={{width: 50, height: 50}} src={item.linkval ? item.linkval : item}/><Link
              to={item}
              onClick={item.linkval ? () => window.open(item.linkval) : () => window.open(item)}
              variant="h6"
            >{item.docname ? item.docname : 'Document ' + (idx + 1)}</Link><div><Edit  style={{marginBottom: -5}} onClick={(e) => {
              if (docDiaRef == null || docDiaRef.current == null) {
                return
              }
              setTargetDocumentIndex(idx)
            
              if (item.docname && item.docname != '') {
                docDiaRef.current.setState({
                  notificationBody: item.docname
                })
                //setDocumentPlaceholder(item.docname)
              } else {
                docDiaRef.current.setState({
                  notificationBody: ''
                })
                //setDocumentPlaceholder('')
              }
              setShowDocumentDialog(true)

            }}/><Cancel onClick={() => {
              let xxs = values.documentList.filter((iteminner, idxinner) => {
                return idxinner != idx 
              })
              setFieldValue('documentList', xxs)
            }}/></div></div>
            })
            
          }

          </div> : <div><InputLabel>No Documents Uploaded Yet</InputLabel>
          

          </div>}
          <div>
        <input id="myInputFolder" ref={inputRef} style={{ display: 'none' }} type="file" accept="image/x-png,image/gif,image/jpeg" webkitdirectory multiple onChange={(e) => handleSelectedFolder(e, values.documentList, (x, y) => setFieldValue(x, y))} />
    <div style={{display: 'flex', flexDirection: 'row'}}><AddBox onClick={(event) => inputRef.current.click()}></AddBox>

    </div>
    {uploadsHappening ? <div><CircularProgress/><a style={{marginLeft: 5}}>Uploading file(s), please wait...</a></div> : null}
    </div>

            </DialogContent>
            <DialogActions>
              <Button onClick={() => props.onClose()} color="primary">
                {props.alertCancelText}
              </Button>
              <Button onClick={() => addUser(values)} color="primary" autoFocus>
                {props.alertConfirmText}
              </Button>
            </DialogActions>
          </Dialog>
          </form>
          )}
        </Formik>
        </div>
    )}
          </MyContext.Consumer>
        )

    };

/*   <div onClick={() => this.adjustSortDescending()} style={{ margin: 0, display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: this.state.sortDescending ? 'orange' : 'white', border: this.state.sortDescending ? '' : '1px solid black', borderRadius: 30, padding: 0, height: 30, padding: 5 }}>
                                <p>Descending</p>
                            </div>*/

/*
 * 
 * 
 *             */

export default AddUserDialog;