import React, {useState, useRef} from 'react';
import { Link as RouterLink, useNavigate, useLocation} from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import DateFnsUtils from '@date-io/date-fns';
import {
  Box,
  Button,
  Checkbox,
  Container,
  FormHelperText,
  Link,
  TextField,
  Typography,
  makeStyles,
  Grid,
  Select,
  MenuItem,
  InputLabel,
  CircularProgress,
  Tooltip,
  InputAdornment,
  FormControl,
  FormControlLabel,
} from '@material-ui/core';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { TimePicker } from "@material-ui/pickers";
import Help from '@material-ui/icons/Help';
import Edit from '@material-ui/icons/Edit';
import Cancel from '@material-ui/icons/Cancel';
import AddBox from '@material-ui/icons/AddBox';
import Page from 'src/components/Page';
import parse from 'autosuggest-highlight/parse';
import throttle from 'lodash/throttle';
import Firebase from 'src/Firebase';
import DataFetcher from 'src/DataFetcher';
import QuoteStatus from 'src/QuoteStatus';
import HoverMarker from 'src/HoverMarker';
import MuiPhoneNumber from 'material-ui-phone-number'
import GoogleMapReact from 'google-map-react';
import { mapValues } from 'lodash';
import ContractDetails from './ContractDetails';
import SendUpdate from './SendUpdate';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import { TrainRounded } from '@material-ui/icons';
import NameDocumentDialog from './NameDocumentDialog';
import AddUserDialog from './AddUserDialog';
import AddVehicleDialog from './AddVehicleDialog';
import QuickInvoiceDialog from './QuickInvoiceDialog';
import TableAssist from './TableAssist';
var moment = require('moment');

const filter = createFilterOptions();
const isFirefox = typeof InstallTrigger !== 'undefined';
const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/


function loadScript(src, position, id) {
  if (!position) {
    return;
  }

  const script = document.createElement('script');
  script.setAttribute('async', '');
  script.setAttribute('id', id);
  script.src = src;
  position.appendChild(script);
}


const autocompleteService = { current: null };
const geocoder = {current: null};
const directionsService = {current: null};
const directionsDisplay = {current: null};
const bounds = {current: null};



const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  }
}));


const JobMasterView = (props) => {
  //let props = propso
  //alert(props.hover)
  //alert(JSON.stringify(props.parentState))
  const classes = useStyles();
  const navigate = useNavigate();

  const inputRef = React.useRef(null);
  const inputRefTwo = React.useRef(null);
  const docDiaRef = React.useRef(null);
  const docDiaRefContract = React.useRef(null);
  const vehicleDialogRef = React.useRef(null);
  const [uploadsHappening, setUploadsHappening] = React.useState(false);
  const [uploadsHappeningContract, setUploadsHappeningContract] = React.useState(false);
  const [map, setMap] = React.useState(null);
  const [maps, setMaps] = React.useState(null);
  const [value, setValue] = React.useState(null);
  const [valueTwo, setValueTwo] = React.useState(null);
  const [pdfLoading, setPdfLoading] = React.useState(null);
  const [inputValue, setInputValue] = React.useState('');
  const [options, setOptions] = React.useState([]);
  const [optionsTwo, setOptionsTwo] = React.useState([]);
  const [numVal, setNumVal] = React.useState(false);
  const [actions, setActions] = React.useState(null);
  const [locationStart, setLocationStart] = React.useState(null);
  const [locationEnd, setLocationEnd] = React.useState(null);
  const [locationStartCountry, setLocationStartCountry] = React.useState(null);
  const [locationEndCountry, setLocationEndCountry] = React.useState(null);
  const [finalSet, setFinalSet] = React.useState(null);
  const [tableItems, setTableItems] = React.useState(DataFetcher.cargoTypeData);
  const [transporterOptions, setTransporterOptions] = React.useState(DataFetcher.transportersList)
  const [driversOptions, setDriversOptions] = React.useState(DataFetcher.driversList)
  const {state} = useLocation();
  const [startLat, setStartLat] = React.useState(props.parentState.customer ? props.parentState.customer.startPos.lat : 9.033138);
  const [startLng, setStartLng] = React.useState(props.parentState.customer ? props.parentState.customer.startPos.lng : 38.750076);
  const [endLat, setEndLat] = React.useState(props.parentState.customer ? props.parentState.customer.endPos.lat : 9.033138);
  const [endLng, setEndLng] = React.useState(props.parentState.customer ? props.parentState.customer.endPos.lng : 38.750076);
  const [drawnPoly, setDrawnPoly] = React.useState(false);
  const [isAuthenticating, setIsAuthenticating] = React.useState(false);
  const [lastPolyline, setLastPolyline] = React.useState(null);
  const [distance, setDistance] = React.useState(null);
  const [weightMetric, setWeightMetric] = React.useState('quintal');
  const [priceMetric, setPriceMetric] = React.useState('perquintal');
  const [editable, setEditable] = React.useState(props.jobProps.editable);
  const [priceEditable, setPriceEditable] = React.useState(props.jobProps.priceEditable);
  const [transporterPriceEditable, setTransporterPriceEditable] = React.useState(props.jobProps.transporterPriceEditable);
  const [transporterPriceMetric, setTransporterPriceMetric] = React.useState('perquintal');
  const [firefoxTimePickup, setFirefoxTimePickup] = React.useState(props.parentState.customer && props.parentState.customer.startDateEarly ?  moment(props.parentState.customer.startDateEarly) : Date());
  const [firefoxTimeDropoff, setFirefoxTimeDropoff] = React.useState(props.parentState.customer && props.parentState.customer.endDateEarly ?  moment(props.parentState.customer.endDateEarly) : Date());;
  const [firefoxTimeStartDate, setFirefoxTimeStartDate] = React.useState(props.parentState.customer && props.parentState.customer.startDate ?  moment(props.parentState.customer.startDate) : Date());
  const [firefoxTimeEndDate, setFirefoxTimeEndDate] = React.useState(props.parentState.customer && props.parentState.customer.endDate ?  moment(props.parentState.customer.endDate) : Date());;
  const [adminAutoAssign, setAdminAutoAssign] = React.useState(false)
  const [mapSelection, setMapSelection] = React.useState(null)
  const [showSendUpdate, setShowSendUpdate] = React.useState(false)
  const [showDocumentDialog, setShowDocumentDialog] = React.useState(false)
  const [showDocumentDialogContract, setShowDocumentDialogContract] = React.useState(false)
  const [documentPlaceholder, setDocumentPlaceholder] = React.useState('')
  const [targetDocumentIndex, setTargetDocumentIndex] = React.useState(-1)
  const [targetDocumentIndexContract, setTargetDocumentIndexContract] = React.useState(-1)
  const [showAllVehicles, setShowAllVehicles] = React.useState(true)
  const [doneTimeUpdate, setDoneTimeUpdate] = React.useState(false)
  const [driverProgress, setDriverProgress] = React.useState(props.parentState && props.parentState.customer && props.parentState.customer.state > 5 ? props.parentState.customer.state : -1)
  const [didChangeProgress, setDidChangeProgress] = React.useState(false)
  const [addUserDialog, setAddUserDialog] = React.useState(false)
  const [addVehicleDialog, setAddVehicleDialog] = React.useState(false)
  const [quickInvoiceDialog, setQuickInvoiceDialog] = React.useState(false)
  const [addUserType, setAddUserType] = React.useState('Transporter')
  const [mustChange, setMustChange] = React.useState(1);
  const [addUserFunction, setAddUserFunction] = React.useState(() => (x, y) => console.log('weefj9e'))
  const [baseSet, setBaseSet] = React.useState({})
  const [vehicleLevelUpdateFunction, setVehicleLevelUpdateFunction] = React.useState(() => (x,y,z) => console.log('xx'))
  const [didSetLocStart, setDidSetLocStart] = React.useState(false)
  const [didSetLocEnd, setDidSetLocEnd] = React.useState(false) 
  const [localityStart, setLocalityStart] = React.useState(props.parentState.customer ? props.parentState.customer.localityStart : null)
  const [localityEnd, setLocalityEnd] = React.useState(props.parentState.customer ? props.parentState.customer.localityEnd : null)
  const loaded = React.useRef(false);
  if (typeof window !== 'undefined' && !loaded.current) {

    if (false && !document.querySelector('#google-maps')) {
      loadScript(
        'https://maps.googleapis.com/maps/api/js?key=AIzaSyAkPLP3zHG-2kctVakLZwdUQHqeLVO0Onw&libraries=places',
        document.querySelector('head'),
        'google-maps',
      );
    }

    loaded.current = true;
  }

  const [center, setCenter] = React.useState({
    lat: state && state.centerx ? state.centerx : startLat,
    lng: state && state.centery ? state.centery : startLng
  })

  const [zoom, setZoom] = React.useState(7)
  const [zoomUpdate, setZoomUpdate] = React.useState(7);

  const mapHolder = useRef(null);

  const packagingOptions = [{'val': 'Sack/Bag (100kg)', 'name': 'Sack/Bag (100kg)'}, {'val': 'Sack/Bag (50kg)', 'name': 'Sack/Bag (50kg)'}, {'val': 'Container (20ft)', 'name': 'Container (20ft)'},
{'val': 'Container (40ft)', 'name': 'Container (40ft)'}, {'val': 'Pallet', 'name': 'Pallet'}, {'val': 'Machinery', 'name': 'Machinery', 'val': 'Bulk', 'name': 'Bulk'},
{'val': 'Machinery', 'name': 'Machinery'}, {'val': 'Other', 'name': 'Other'}]

  //const loaded = React.useRef(false);
  /*if (typeof window !== 'undefined' && !loaded.current) {
    if (!document.querySelector('#google-maps')) {
      loadScript(
        'https://maps.googleapis.com/maps/api/js?key=AIzaSyAkPLP3zHG-2kctVakLZwdUQHqeLVO0Onw&libraries=places',
        document.querySelector('head'),
        'google-maps',
      );
    }
    loaded.current = true;
  }*/

  function precisionRound(num) {
    //console.log(num)
   //console.log(num.toFixed(2))
    
    return +num.toFixed(2)
  }

  // for hardcoding a placeholder weight value, i.e. when creating from freight orders
  function renderProperPrice(customer, xval) {
    if (customer.pricePerQuintal) {
      return mathRound(customer.pricePerQuintal * (xval * 10))
    } else {
      return customer.price
    }
  }

  
  // for hardcoding a placeholder weight value, i.e. when creating from freight orders
  function renderProperTransporterPrice(customer, xval) {
    if (customer.transporterPricePerQuintal) {
      return mathRound(customer.transporterPricePerQuintal * (xval * 10))
    } else {
      return customer.transporterPrice
    }
  }

  async function uploadImageByUri(uri, idx, driver, quoteId, mime = 'image/jpg') {

    var bucket = ''
    if (!driver || driver == '0') {
      bucket = 'admin_for_' + quoteId
    } else {
      bucket = driver 
    }
    let suffixType = uri.type == 'application/pdf' ? 'pdf' : 'jpg'

    let name = Date.now() + '_' + idx + '.' + suffixType

    const imageRef = Firebase.storage.ref(bucket).child(name)
    let aa = await imageRef.put(uri, { contentType: uri.type }).then(() => {
        return imageRef.getDownloadURL()
    }).then((url) => {
        return url
    })
        .catch((error) => {

            alert('Error uploading files. Check internet connection')
            console.log("error" + error);
        })
    console.log('w' + aa)
    return aa
}
  
  async function handleSelectedFolder(event, docList, setFunction) {
    
      if (!props.parentState.customer) {
        alert('You cannot add documents at this time for this job.')
        return 
      }
      console.log(event)
      console.log(event.target)
      console.log(event.target.files)
      var targetData = Array.from(event.target.files)
      
      setUploadsHappening(true)

      var promises = await targetData.map(async (x, idx) => {
        console.log(x)
        let res = await uploadImageByUri(x,  idx, props.parentState.customer.driver, props.parentState.customer.quoteId)
        var xf = {}
        if (x.name) {
          xf['docname'] = x.name
        } else {
          xf['docname'] = 'Document ' + (idx + docList.length + 1)
        }

        xf['linkval'] = res
        xf['isPdf'] = x.type == 'application/pdf'
        return xf 
      });
      let newarr = await Promise.all(promises)

      var newList = docList.concat(newarr)
      setFunction('documentList', newList)
      setUploadsHappening(false)
      //this.uploadFile(targetFile)
  }


  async function handleSelectedFolderContract(event, docList, setFunction) {
    
    if (!props.parentState.customer) {
      alert('You cannot add documents at this time for this job.')
      return 
    }
    console.log(event)
    console.log(event.target)
    console.log(event.target.files)
    var targetData = Array.from(event.target.files)
    
    setUploadsHappeningContract(true)

    var promises = await targetData.map(async (x, idx) => {
      console.log(x)
      let res = await uploadImageByUri(x,  idx, props.parentState.customer.shipper, props.parentState.customer.contractId)
      var xf = {}
      if (x.name) {
        xf['docname'] = x.name
      } else {
        xf['docname'] = 'Document ' + (idx + docList.length + 1)
      }

      xf['linkval'] = res
      xf['isPdf'] = x.type == 'application/pdf'
      return xf 
    });
    let newarr = await Promise.all(promises)

    var newList = docList.concat(newarr)
    setFunction('contractDocumentList', newList)
    setUploadsHappeningContract(false)
    //this.uploadFile(targetFile)
}

  function atLeastOneImage(dlst) {
    if (!dlst) {
      return false 
    }
    var noImg = true 
    dlst.map((item, idx) => {
      if (!item.isPdf) {
        noImg = false
      }
    })
    return !noImg
  }

  function renderUserNameForDisplay(v, ast) {
    if (!v.userId || v.userId == '0') {
      return 'Unassigned'
    }

    var rets = ''
    if ('firstName' in v) {
      rets += v.firstName
    } 
    if ('middleName' in v) {
      rets += ' ' + v.middleName
    } 
    if ('lastName' in v) {
      rets += ' ' + v.lastName
    }

    if (v.userId == ast && v.ownerOperator) {
      rets += ' (Owner Operator)'
    }

    if (v.phone) {
      rets += ' (' + v.phone + ')'
    }

    return rets
  }

  function renderUserName(v, ast) {
    if (!v.userId || v.userId == '0') {
      return 'Unassigned'
    }

    var rets = ''
    if ('firstName' in v) {
      rets += v.firstName
    } 
    if ('middleName' in v) {
      rets += ' ' + v.middleName
    } 
    if ('lastName' in v) {
      rets += ' ' + v.lastName
    }

    if (v.userId == ast && v.ownerOperator) {
      rets += ' (Owner Operator)'
    }

    return rets
  }

  function getTransporterOptions() {

    if (!props.adminEdit) {
      if (props.parentState.customer && props.parentState.customer.assignedTransporter && props.parentState.customer.assignedTransporterName) {
        return [{'userId': props.parentState.customer.assignedTransporter, 'companyName': props.parentState.customer.assignedTransporterName}]
      } else {
        return [{'userId': '0', 'companyName': 'Unassigned'}]
      }
    }

    let ret = props.adminUsers.filter((item, idx) => {
      return item.userType == 1 && item.companyName
    })
    return [{'userId': '0', 'companyName': 'Unassigned'}].concat(ret)

  }

  function renderUserCompanyName(v) {
    var vx = v.companyName
    if (v.phone) {
      vx += ' (' + v.phone + ')'
    }

    return vx
  }

  function getUserCompanyName(id) {

    if (id == '0') {
      return 'Unassigned'
    }

    // this should only ever be returning assigned transporter name
    if (!props.adminEdit) {
      if (props.parentState.customer && props.parentState.customer.assignedTransporter && props.parentState.customer.assignedTransporterName) {
        return  props.parentState.customer.assignedTransporterName
      } else {
        return 'Unassigned'
      }
    }


    var n = ''
    props.adminUsers.map((item, idx) => {
      if (item.userId == id) {
        n = item.companyName
        if (item.phone) {
          n += ' (' + item.phone + ')'
        }
      }

    })
    
    return n
  }

  function getUserDriver(id) {
    if (id == '0') {
      return {'userId': '0'}
    }


    if (!props.adminEdit) {
      if (props.parentState.customer && props.parentState.customer.assignedDriver && props.parentState.customer.assignedDriverName) {
        return {'userId': props.parentState.customer.assignedDriver, 'firstName': props.parentState.customer.assignedDriverName}
      } else {
        return {'userId': '0', 'firstName': 'Unassigned'}
      }
    }

    var t = {}
    props.adminUsers.map((item, idx) => {
      if (item.userId == id) {
        t = item 
      }
    })
    return t 
  }

  function getVehicleOptions(showAll, assignedTransporter) {
    if (!props.adminEdit) {
      if (props.parentState.customer && props.parentState.customer.vehicleId && props.parentState.customer.vehiclePlate) {
        return [{'vehicleId': props.parentState.customer.vehicleId, 'companyName': props.parentState.customer.vehiclePlate}]
      } else {
        return [{'vehicleId': '0', 'plate': ''}]
      }
    }

    let ret = props.adminVehicles.filter((item, idx) => {
      return item.vehicleId && item.plate && (showAll || assignedTransporter == '0' || !assignedTransporter || assignedTransporter == item.assignedTransporter)
    })
    
    //console.log(JSON.stringify([{'vehicleId': '0', 'vehiclePlate': 'Unassigned'}].concat(ret)))
    return [{'vehicleId': '0', 'plate': ''}].concat(ret)

  }

  function getDriverOptions(assignedTransporter) {

    if (!props.adminEdit) {
      if (props.parentState.customer && props.parentState.customer.assignedDriver && props.parentState.customer.assignedDriverName) {
        return [{'userId': props.parentState.customer.assignedDriver, 'firstName': props.parentState.customer.assignedDriverName}]
      } else {
        return [{'userId': '0', 'firstName': 'Unassigned'}]
      }
    }

    if (assignedTransporter != '0') {
      var ret = props.adminUsers.filter((item, idx) => {
        return (item.userType == 3 && (item.assignedTransporter == assignedTransporter || item.assignedTransporterId == assignedTransporter)) || (assignedTransporter == item.userId && item.ownerOperator)
      })
      return [{'userId': '0', 'firstName': 'Unassigned'}].concat(ret)
    }

    return [{'userId': '0', 'firstName': 'Unassigned'}]
  }

  function getUserPhone(id) {
    if (!props.adminEdit) {
      return ''
    }

    if (props.adminUsers) {
      var ret = ''
      props.adminUsers.map((item, idx) => {
        if (item.userId == id) {
          ret = item.phone 
        }
      })
      return ret 
    }

    return ''

  }

  function renderOptionName(jobx) {
    //alert(JSON.stringify(jobx))
    if (!jobx || jobx == -1) {
      return 'None'
    }
    var job = jobx 
    if (!jobx.quoteId) {
      props.context.adminJobs.map((item, idx) => {
        if (item.quoteId == jobx) {
          job = item 
        }
      })
    }
    if (!job.quoteId) {
      return ''
    }
    if (job.quoteId == -1) {
      return 'None'
    }
    var s = TableAssist.renderIdDisplay(job)
    s += ' (Transporter: ' + job.assignedTransporterName + ') (Driver: ' + job.assignedDriverName + ') (Vehicle: ' + job.vehiclePlate + ')'
    return s
  }


  function doSubmit(values, setSubmitting,  metadata=false) {

    if (localityStart != null) {
      values['localityStart'] = localityStart
    }
    if (localityEnd != null) {
      values['localityEnd'] = localityEnd
    }

    if (props.adminEdit && (values.oldAssignedDriver != null || values.oldAssignedTransporter != null || props.isFreightOrderTemplate)) {
      /*alert('transporter ' + values.assignedTransporter + ' old transporter ' + values.oldAssignedTransporter + 
      ' driver ' + values.assignedDriver + ' old driver ' + values.oldAssignedDriver + ' ' + props.parentState.customer.state)*/
      if ((values.oldAssignedTransporter || values.oldAssignedDriver) && (props.parentState.customer && props.parentState.customer.state >= 7)){
        alert('You cannot change the driver or transporter once the driver is en route.')
        setSubmitting(false)
        return
      }
      if (values.oldAssignedTransporter != null || (props.isFreightOrderTemplate && values.assignedTransporter != null && values.assignedTransporter.length > 9)) {
        var name = ''
        getTransporterOptions().map((item, idx) => {
          if (item.userId == values.assignedTransporter) {
            name = item.companyName
          }
        })
        if (name != '') {
          values['assignedTransporterName'] = name 
        } else {
          values['assignedTransporterName'] = 'Garri Transporter'
        }
      }
      if ((values.oldAssignedDriver != null || (props.isFreightOrderTemplate && values.assignedDriver != null && values.assignedDriver.length > 9)) && values.assignedTransporter != null) {
        var name = ''
        //console.log('nameTRYYY@')
        getDriverOptions(values.assignedTransporter).map((item, idx) => {
          //console.log(item.userId + ' vs ' + values.assignedDriver)
          if (item.userId == values.assignedDriver) {
            name = renderUserName(item, '-1')
          }
          //console.log(name)
        })
        //console.log(name)
        if (name != '') {
          values['assignedDriverName'] = name 
        } else {
          values['assignedDriverName'] = 'Garri Driver'
        }
      }


      
      if (values.oldAssignedTransporter == values.assignedTransporter) {
        delete values['oldAssignedTransporter']
      }
      if (values.oldAssignedDriver == values.assignedTransporter) {
        delete values['oldAssignedDriver']
      }

      

      
      //setSubmitting(false)
      //return
    }

    if (values.assignedDriver != null && values.assignedDriver != '0' 
    && values.assignedDriver != 'undefined' && values.assignedDriver.length > 9) {
      if (didChangeProgress && driverProgress != props.parentState.customer.state && driverProgress > 5) {
        //alert('setting')
        values['progressSetValue'] = driverProgress
      } else {
        //alert('not setting')
      }
    }

    if (props.createForShipper && (values.shipper == null || !values.shipper)) {
      alert('Must choose a shipper for the job.')
      setSubmitting(false)
      return 
    }

    if (props.ltcCreation && values.workOrderJob && (values.endDate == '' || values.startDate == '')) {
      alert('Must choose a start and end date for the contract.')
      setSubmitting(false)
      return 
    } else if ( props.ltcCreation && values.workOrderJob &&  (moment(values.endDate).valueOf() <= moment(values.startDate).valueOf())) {
        alert('End date cannot occur before start date.')
        setSubmitting(false)
        return 
    }

    if (props.ltcCreation && (values.shipper == null || !values.shipper)) {
      alert('Must choose a shipper for the contract.')
      setSubmitting(false)
      return 
    }

      if (values.trueWeight <= 0 || values.trueWeight == '' || values.trueWeight == null ) {
        alert('Weight must be greater than zero.')
        setSubmitting(false)
        return
      }
  
  
  
      if ((props.jobProps.transporterPriceEditable || props.ltcCreation) && (values.transporterPrice <= 0 || values.transporterPrice == '' || !values.transporterPrice) ) {
        alert('Must set a transporter price')
        setSubmitting(false)
        return
      }
  
      if (props.provideQuote || props.ltcCreation || props.createForShipper) {
        if (values.price == '' || values.price <= 0) {
          alert('You must provide a price for the quote.')
          setSubmitting(false)
          return
        } 
      }

      if (props.ltcEdit) {
        if (props.parentState.customer.totalWeightUsed) {
          values['totalWeightUsed'] = props.parentState.customer.totalWeightUsed
        }
      }


    if (props.createForShipper) {
      // Need to get shipper name, phone, and ID
      var targetShipper = null 
      props.jobProps.shippers.map((item, idx) => {
        if (item.userId == values.shipper) {
          targetShipper = item 
        }
      })
      if (targetShipper == null) {
        alert('Error assinging to this shipper. Please contact Garri.')
        setSubmitting(false)
        return 
      }
      values['userId'] = values.shipper 
      values['userPhone'] = targetShipper.phone 
      values['shipperName'] = targetShipper.companyName
    }


    var vsend = values 
    if (distance != null) {
      values['distance'] = distance 
    }
    if (locationStartCountry != null) {
      values['startCountry'] = locationStartCountry
    }
    if (locationEndCountry != null) {
      values['endCountry'] = locationEndCountry
    }

   if (moment(values.earlyDrop).valueOf() <= moment(values.earlyPick).valueOf()) {
     alert('Dropoff cannot occur before pickup.')
     setSubmitting(false)
     return 
   }
   //alert(JSON.stringify(locationStart))
   //return 
   if (props.isLimited == 1) {
    vsend = {}
    vsend['documentList'] = values['documentList']
    if (values['progressSetValue']) {
      vsend['progressSetValue'] = values['progressSetValue']
    }
    
    vsend['limitedAccessUpdate'] = 1
 }

    props.parentSubmit(vsend, locationStart, locationEnd, metadata)
  }

  function calculateRemainingPayment(values) {
    if (!values.trueWeight || values.trueWeight <= 0) {
      return 'Cannot calculate payment without weight.'
    }
    if (!values.transporterPricePerQuintal) {
      return 'Cannot calculate payment without transporter price.'
    }

    var weightVal = values.trueWeight
    if (values.finalWeight && values.finalWeight > 0 && values.finalWeight != '') {
      weightVal = values.finalWeight
    }

    // Total quintals of the load
    var weightQuintals = weightVal * 10 

    var totalPay = weightQuintals * values.transporterPricePerQuintal

    if (values.advancePayment && values.advancePayment > 0 && values.advancePayment != '') {
      var totalPay = totalPay - values.advancePayment
    }

    return 'Calculated Remaining Payment: ETB ' + precisionRound(totalPay)
  }
  
  function getPdfDoc(values) {

    setPdfLoading(true)
    var datPack = {}
    datPack['friendlyId'] = TableAssist.renderIdDisplay(props.parentState.customer)
    datPack['quoteId'] = props.parentState.customer.quoteId
    datPack['userId'] = Firebase.uid 
    datPack['documentList'] = values.documentList.filter((item, idx) => {
      return !item.isPdf
    })

    let s = async (ret) => {
      console.log(s)
      console.log('here is ret')
      console.log(ret)
      if (ret.hasLink) {
        window.open(ret.pdflink)
      } else {
        const imageRef = Firebase.storage.ref(Firebase.uid).child('pdf_for_job_' + props.parentState.customer.quoteId + '.pdf')
        let v = await imageRef.getDownloadURL();
        console.log(v)
        console.log('got it')
        datPack['pdflink'] = v 
        let snone = () => console.log('x')
        Firebase.providePdfLink(datPack, snone, snone)
        window.open(v)
      }
      setPdfLoading(false)
    } 

    let f = () => {
      setPdfLoading(false)
      alert('Error retrieving pdf.')
    }

    
    Firebase.getPdfDoc(datPack, s, f)

  }

  function navigateToUser(userId) {
    if (props.adminUsers) {
      var targ = null 
      props.adminUsers.map((item, idx) => {
        if (item.userId == userId) {
          targ = item 
        }
      })
      if (targ != null) {
        navigate('/app/edituser', {state: targ})
      }
    }
  }

  function countryCheck(item) {
    console.log(item)
    //console.log(item.length)
    if (!item.length || item.length < 1) {
      return null
    }
    var retCountry = null
    var retLocality = null 
    for (var i=0; i < item.length; i++) {
      let temp = item[i]
      if ('types' in temp && temp.types.length && temp.types.length > 0) {
        //console.log(temp.types.length)
        for (var j=0; j < temp.types.length; j++) {
          //console.log(temp.types[j])
          if (temp.types[j] === 'country') {

            retCountry = temp.long_name
          } else if (temp.types[j] == 'locality') {
            retLocality = temp.long_name
          }
        }
      }
    }
    return {'country': retCountry, 'locality': retLocality}
  }

  function getLiveTrackText(customer, isCurrentJob) {
    if (isCurrentJob) {
      // This is the live track button for this job
      if (customer.state >= 9) {
        return 'View History: '
      } else if (customer.state > 6) {
        return 'Live Track: '
      } else {
        return 'Check Status: '
      }
    } else {
      // This is the live track button for the next job 
      if (customer.shortIdShipper > customer.shortIdShipperLINK) {
        // This is the second job 
        if (customer.state > 6) {
          return 'View History: '
        } else {
          return 'Check Status: '
        }
      } else {
        return 'Check Status: '
      }

    }
  }

  function getShipper(props) {
    var s = '' 
    if( props.parentState && props.parentState.customer && props.parentState.customer.shipper) {
 
      props.adminUsers.map((item, idx) => {
        if (item.userId == props.parentState.customer.shipper) {
          s = item.companyName
        }
      })

    }
    if (s == '') {
      return ''
    }
    return 'Shipper: ' + s 
  }

  function getTitleShow(props) {

    if (props.createFreightOrder && props.asAdmin) {
      return ( <Box mb={3}>
        <Typography
          color="textPrimary"
          variant="h2"
        >{'Create Freight Order From Contract'}
        </Typography>
        <Typography
          color="textPrimary"
          variant="h4"
          style={{marginTop: 10}}
        >{getShipper(props)}
        </Typography>
      </Box>)
    }

    if (props.createFreightOrder) {
      return ( <Box mb={3}>
        <Typography
          color="textPrimary"
          variant="h2"
        >{'Create Freight Order From Contract'}
        </Typography>
        
      </Box>)
    }

    if (props.editQuoteShipper) {
      return ( <Box mb={3}>
        <Typography
          color="textPrimary"
          variant="h2"
        >{props.parentState.customer ? 'Update Quote # ' + TableAssist.renderIdDisplay(props.parentState.customer) : ''}
        </Typography>
        <QuoteStatus customerState={props.parentState.customer ? props.parentState.customer.state : 0}/>
      </Box>)
    }

    if (props.adminEdit || props.isLimited == 1) {
      return ( <Box mb={3}>
        <Typography
          color="textPrimary"
          variant="h2"
        >{props.parentState.customer ? 'Job # ' + TableAssist.renderIdDisplay(props.parentState.customer) : ''}
        </Typography>
        <QuoteStatus customerState={props.parentState.customer ? props.parentState.customer.state : 0}/>
      </Box>)
    }

    if (props.privateQuote || props.publicQuote) {
      return (
        <Box mb={3}>
        <Typography
          color="textPrimary"
          variant="h2"
        >
          Get a quote
        </Typography>
        <Typography
          color="textSecondary"
          gutterBottom
          variant="body2"
        >
          Request a quote for your shipment. You will be contacted via phone within 24 hours
        </Typography>
      </Box>
      )
    }
  }

  function getActionButton(props, isSubmitting, values, handleChange, touched, errors, setSubmitting, resetForm, validateForm) {
    
    if (props.viewFreightOrder) {
      return <div id="get-quote-button"/>
    }
    
    if (props.ltcCreation || props.createForShipper ) {
      return (<Box my={2}>
        <Button
          color="primary"
          
          fullWidth
          size="large"
          id="get-quote-button"
          type="submit"
          variant="contained"
          onClick={() => validateForm().then((x) => {
            if (Object.keys(x).length > 0) {
             alert('Please fix missing values before proceeding.')
             setSubmitting(false)
            } else {

             doSubmit(values, setSubmitting,  true)
            }
           })}
        >
         {'Confirm Contract'}
        </Button>
        {props.ltcEdit ? 
              <Button
              key="fkingbueeoagn12"
                color="primary"    
                id="get-quote-button"
                fullWidth
                size="large"
                variant="contained"
                style={{marginTop: 5}}
                onClick={() => 
                  {
                    setBaseSet(values)
                    setQuickInvoiceDialog(true)
                  }
                  }
              >
                Create Pro Forma Invoice
              </Button>
        : null}
        </Box>)
    }

    if (props.editQuoteShipper) {
      return (<Box my={2}>
        <Button
          color="primary"
          
          fullWidth
          size="large"
          id="get-quote-button"
          type="haha123"
          variant="contained"
        >
          Back
        </Button>
      </Box>)
    }

    if (props.privateQuote) {
      return (
        <div>
        <Box
        alignItems="center"
        display="flex"
        ml={-1}
      >
        <Checkbox
          checked={values.policy}
          name="policy"
          onChange={handleChange}
        />
        <Typography
          color="textSecondary"
          variant="body1"
        >
          I have read the
          {' '}
          <Link
            color="primary"
            component={RouterLink}
            to="/termsofuse"
            rel="noopener noreferrer" target="_blank"
            underline="always"
            variant="h6"
          >
            Terms and Conditions
          </Link>
        </Typography>
      </Box>
      {Boolean(touched.policy && errors.policy) && (
        <FormHelperText error>
          {errors.policy}
        </FormHelperText>
      )}
      <div id='fkwi3e'>
      <Box my={2}>
        <Button
          color="primary"
          
          width={'30%'}
          size="large"
          id="get-quote-button"
          type="haha123"
          variant="contained"
        >
          Get Quote
        </Button>
      </Box>
      </div></div>
      )
    }

    if (props.publicQuote) {
      return (
        <div>
        <Box
        alignItems="center"
        display="flex"
        ml={-1}
      >
        <Checkbox
          checked={values.policy}
          name="policy"
          onChange={handleChange}
        />
        <Typography
          color="textSecondary"
          variant="body1"
        >
          I have read the
          {' '}
          <Link
            color="primary"
            component={RouterLink}
            to="/termsofuse"
            rel="noopener noreferrer" target="_blank"
            underline="always"
            variant="h6"
          >
            Terms and Conditions
          </Link>
        </Typography>
      </Box>
      {Boolean(touched.policy && errors.policy) && (
        <FormHelperText error>
          {errors.policy}
        </FormHelperText>
      )}
      <div id='fkwi3e'>
      <Box my={2}>
        <Button
          color="primary"
          
          width={'30%'}
          size="large"
          id="get-quote-button"
          type="haha123"
          variant="contained"
        >
          Sign Up And Get Quote
        </Button>
      </Box>
      <Typography
        color="textSecondary"
        variant="body1"
      >
        Have an account?
        {' '}
        <Link
          component={RouterLink}
          to="/login"
          variant="h6"
        >
          Sign in
        </Link>
      </Typography></div></div>
      )
    }

    if (props.fromLTC && !props.createFreightOrder) {
      return <div>
        {!props.createFreightOrder ? 
        <div>
          {
        editable ? <div>
          
        <Box my={2}>
        <Button
          color="primary"
          
          fullWidth
          size="large"
          id="get-quote-button"
          type="haha123"
          variant="contained"
          onClick={() => validateForm().then((x) => {
            if (Object.keys(x).length > 0) {
             alert('Please fix missing values before proceeding.')
             setSubmitting(false)
            } else {

             doSubmit(values, setSubmitting,  true)
            }
           })}
        >
         {props.createFreightOrder ? 'Create Freight Order From Contract' : 'Create Job With Changes'}
        </Button>
        </Box>
        <Box my={2}>
        <Button
        key="aj203e9jamjjfjf99993j"
          color="primary"
          
          fullWidth
          size="large"
          id="get-quote-button"
          variant="contained"
          onClick={(e) => {
            resetForm()
            setEditable(false)
          }}
        >
          Cancel Changes
        </Button>
      </Box></div> : <div><Box my={2}>
      <Button
      key="fkingbueeoagn12"
        color="primary"
        
        id="get-quote-button"
        fullWidth
        size="large"
        variant="contained"
        onClick={() => setEditable(true)}
      >
        Edit Info
      </Button>
    </Box>
    
    <Box my={2}>
    <Button
      color="primary"
      
      fullWidth
      size="large"
      id="get-quote-button"
      type="haha123"
      variant="contained"
      onClick={() => validateForm().then((x) => {
        if (Object.keys(x).length > 0) {
         alert('Please fix missing values before proceeding.')
         setSubmitting(false)
        } else {

         doSubmit(values, setSubmitting,  true)
        }
       })}
    >
     {props.createFreightOrder ? 'Create Freight Order From Contract' : 'Create Job'}
    </Button>
    </Box></div>
      }
        </div>
        : null}

      </div>
    }

    if (props.fromLTC) {
      return (<div>
        


        <Box my={2}>
        <Button
          color="primary"
          
          fullWidth
          size="large"
          id="get-quote-button"
          type="haha123"
          variant="contained"
          onClick={() => validateForm().then((x) => {
            if (Object.keys(x).length > 0) {
             alert('Please fix missing values before proceeding.')
             setSubmitting(false)
            } else {

             doSubmit(values, setSubmitting,  true)
            }
           })}
        >
         {props.createFreightOrder ? 'Create Freight Order From Contract' : 'Create Job'}
        </Button>
        </Box>
       </div>
      )
    }

    if (props.createForShipper) {
      return (<div>
        <Box my={2}>
        <Button
          color="primary"
          
          fullWidth
          size="large"
          id="get-quote-button"
          type="submit"
          variant="contained"
          onClick={() => validateForm().then((x) => {
           if (Object.keys(x).length > 0) {
            alert('Please fix missing values before proceeding.')
            setSubmitting(false)
           } else {

            doSubmit(values, setSubmitting,  true)
           }
          })}
        >
         {'Create Job on Behalf of Shipper'}
        </Button>
        </Box>
       </div>
      )
    }

    if (props.quoteOffer) {
      return (<div>
        <Box my={2}>
        <Button
          color="primary"
          
          fullWidth
          size="large"
          id="get-quote-button"
          type="haha123"
          variant="contained"
          onClick={() => validateForm().then((x) => {
            if (Object.keys(x).length > 0) {
             alert('Please fix missing values before proceeding.')
             setSubmitting(false)
            } else {
             doSubmit(values, setSubmitting,  true)
            }
           })}
        >
         {'Accept Quote: ETB ' + values.pricePerQuintal + '/quintal (Total: ETB ' + values.price + ')'}
        </Button>
        </Box>
        <Box my={2}>
        <Button
          color="primary"
          
          fullWidth
          size="large"
          id="get-quote-button2"
          type="haha123"
          variant="contained"
          onClick={() => validateForm().then((x) => {
            if (Object.keys(x).length > 0) {
             alert('Please fix missing values before proceeding.')
             setSubmitting(false)
            } else {
             doSubmit(values, setSubmitting,  false)
            }
           })}
        >
          Reject Quote
        </Button>
      </Box></div>
      )
    }

    if (props.evaluateBid) {
      return (
        <div>
             <Box my={2}>
                  <Button
                    color="primary"
                    
                    fullWidth
                    size="large"
                    id="get-quote-button"
                    type="haha123"
                    variant="contained"
                    onClick={() => validateForm().then((x) => {
                      if (Object.keys(x).length > 0) {
                       alert('Please fix missing values before proceeding.')
                       setSubmitting(false)
                      } else {
                       
                       doSubmit(values, setSubmitting,  true)
                      }
                     })}
                  >
                    
                    {'Accept Transporter Bid: ' + mathRound(state.customer.bidAmount / precisionRound(values.trueWeight * 10)) + '/quintal (Total ' + state.customer.bidAmount + ' ETB)'}
                  </Button>
                  </Box>
                  <Box my={2}>
                  <Button
                    color="primary"
                    
                    fullWidth
                    size="large"
                    id="get-quote-button"
                    type="haha123"
                    variant="contained"
                    onClick={() => validateForm().then((x) => {
                      if (Object.keys(x).length > 0) {
                       alert('Please fix missing values before proceeding.')
                       setSubmitting(false)
                      } else {
                       doSubmit(values, setSubmitting,  false)
                      }
                     })}
                  >
                    Reject Transporter Bid
                  </Button>
                </Box>
        </div>
      )
    }

    if (props.provideQuote) {
      return (<div> {
        editable ? <div><Box my={2}>
        <Button
        key="aj203e9jamjjfjf99993j"
          color="primary"
          
          fullWidth
          size="large"
          id="get-quote-button"
          variant="contained"
          onClick={() => validateForm().then((x) => {
            if (Object.keys(x).length > 0) {
             alert('Please fix missing values before proceeding.')
             setSubmitting(false)
            } else {
             doSubmit(values, setSubmitting,  false)
            }
           })}
        >
          Save Changes
        </Button>
        </Box>
        <Box my={2}>
        <Button
        key="aj203e9jamjjfjf99993j"
          color="primary"
          
          fullWidth
          size="large"
          id="get-quote-button"
          variant="contained"
          onClick={(e) => {
            resetForm()
            setEditable(false)
          }}
        >
          Cancel Changes
        </Button>
      </Box></div> : <Box my={2}>
      <Button
      key="fkingbueeoagn12"
        color="primary"
        
        id="get-quote-button"
        fullWidth
        size="large"
        variant="contained"
        onClick={() => setEditable(true)}
      >
        Edit Info
      </Button>
    </Box>
      }
                      <Box my={2}>
                  <Button
                    color="primary"
                    
                    fullWidth
                    size="large"
                    id="get-quote-button"
                    onClick={() => validateForm().then((x) => {
                      if (Object.keys(x).length > 0) {
                       alert('Please fix missing values before proceeding.')
                       setSubmitting(false)
                      } else {
                       doSubmit(values, setSubmitting,  true)
                      }
                     })}
                    variant="contained"
                  >
                    Send Quote to Shipper
                  </Button>
                </Box>
      </div>)
    }

    
    if (props.transporterAssignment) {
      return (<div><Box my={2}>
        <Button
        key="aj203e9jamjjfjf99993j"
          color="primary"
          
          fullWidth
          size="large"
          id="get-quote-button"
          variant="contained"
          onClick={() => validateForm().then((x) => {
            if (Object.keys(x).length > 0) {
             alert('Please fix missing values before proceeding.')
             setSubmitting(false)
            } else {
             doSubmit(values, setSubmitting,  false)
            }
           })}
        >
          Save Changes And Offer Job
        </Button>
        </Box>
        <Box my={2}>
        <Button
        key="aj203e9jamjjfjf99993j"
          color="primary"
          
          fullWidth
          size="large"
          id="get-quote-button"
          variant="contained"
          onClick={(e) => {
            resetForm()
            setEditable(false)
          }}
        >
          Cancel Changes
        </Button>
      </Box></div> 
      )
    }

    if (props.adminEdit) {
      return (<div> {
        editable ? <div><Box my={2}>
        <Button
        key="aj203e9jamjjfjf99993j"
          color="primary"
          
          fullWidth
          size="large"
          id="get-quote-button"
          variant="contained"
          onClick={() => validateForm().then((x) => {
            if (Object.keys(x).length > 0) {
             alert('Please fix missing values before proceeding.')
             setSubmitting(false)
            } else {
             doSubmit(values, setSubmitting,  false)
            }
           })}
        >
          Save Changes
        </Button>
        </Box>
        <Box my={2}>
        <Button
        key="aj203e9jamjjfjf99993j"
          color="primary"
          
          fullWidth
          size="large"
          id="get-quote-button"
          variant="contained"
          onClick={(e) => {
            resetForm()
            setEditable(false)
          }}
        >
          Cancel Changes
        </Button>
      </Box></div> : <Box my={2}>
      <Button
      key="fkingbueeoagn12"
        color="primary"
        
        id="get-quote-button"
        fullWidth
        size="large"
        variant="contained"
        onClick={() => setEditable(true)}
      >
        Edit Info
      </Button>
    </Box>
      }

      </div>)
    }

    if (props.isLimited == 1) {
      return <Box my={2}>
        <Button
        key="aj203e9jamjjfjf99993j"
          color="primary"
          
          fullWidth
          size="large"
          id="get-quote-button"
          variant="contained"
          onClick={() => validateForm().then((x) => {
            if (Object.keys(x).length > 0) {
             alert('Please fix missing values before proceeding.')
             setSubmitting(false)
            } else {
             doSubmit(values, setSubmitting,  false)
            }
           })}
        >
          Save Changes
        </Button>
        </Box>
    }

  }

  const fetch = React.useMemo(
    () =>
      throttle((request, callback) => {
        if (autocompleteService.current) {
          autocompleteService.current.getPlacePredictions(request, callback);
        }

      }, 200),
    [],
  );

  function mathRound(num) {
    if (num < 0.01) {
      return num 
    }

    return Math.round((num + Number.EPSILON) * 100) / 100
  }

function latRad(lat) {
  var sin = Math.sin(lat * Math.PI / 180);
  var radX2 = Math.log((1 + sin) / (1 - sin)) / 2;
  return Math.max(Math.min(radX2, Math.PI), -Math.PI) / 2;
}

function updateMapDraw(startPos, endPos, mapObj, mapsObj ) {

  if (startPos == null || endPos == null || directionsDisplay.current == null || directionsService.current == null) {
    return 
  }


  console.log('here a holder')
  console.log(mapHolder)
  console.log(startPos)
  console.log(endPos)
  let latDif = Math.abs(latRad(startPos.lat) - latRad(endPos.lat))
  let lngDif = Math.abs(startPos.lng - endPos.lng)
  console.log('heredifs')
  console.log(lngDif)
  console.log(latDif)
  //let latDifRaf = letRad(latDif)
  //let lngDifRad = 

  let latFrac = latDif / Math.PI 
  let lngFrac = lngDif / 360 


  let lngZoom = Math.log(1/latFrac) / Math.log(2)
  let latZoom = Math.log(1/lngFrac) / Math.log(2)
  console.log(latFrac)
  console.log(lngFrac)
  console.log(Math.log(1/latFrac) / Math.log(2))
  console.log(Math.log(1/lngFrac) / Math.log(2))
  //alert('new zoom ' + Math.min(lngZoom, latZoom))
  setZoom(Math.min(lngZoom, latZoom))

  let cx = startPos.lat + ((endPos.lat - startPos.lat) / 2)
  let cy = startPos.lng + ((endPos.lng - startPos.lng) / 2)
  setCenter({lat: cx, lng: cy})

  //setZoom(getBoundsZoomLevel(bounds, { height: mapHolder.current.clientHeight, width: mapHolder.current.clientWidth }))

  let strstart = startPos.lat + ', ' + startPos.lng
  let strend = endPos.lat + ', ' + endPos.lng
  let strkey = strstart + '_' + strend
  console.log(strkey)
  directionsService.current.route({
    origin: strstart,
    destination: strend,
    travelMode: 'DRIVING'
  }, (response, status) => {
    if (status === 'OK') {
      console.log(response)
      console.log(response.routes[0].legs[0].start_location.lat())  
      console.log(response.routes[0].legs[0].start_location.lng())

      setStartLat(response.routes[0].legs[0].start_location.lat())
      setStartLng(response.routes[0].legs[0].start_location.lng())
      setEndLat(response.routes[0].legs[0].end_location.lat())
      setEndLng(response.routes[0].legs[0].end_location.lng())
      //console.log(response.overview_path[0].lat())
      //console.log(response.overview_path[0].lng())
      //Firebase.cacheRoute(strkey.replaceAll('.','@'), response)

      directionsDisplay.current.setDirections(response);
      console.log(response.routes[0].overview_path)
      console.log('drawpoly')
      const routePolyline = new mapsObj.Polyline({
        path: response.routes[0].overview_path
      });
      if (lastPolyline != null) {
        lastPolyline.setMap(null)
      }
      routePolyline.setMap(mapObj);

      let mainRoute = response.routes[0]
      var retMiles = 0
      mainRoute.legs.map((leg, legIdx) => {
          let distMeters = leg.distance.value
          let distMiles = distMeters / 1000
          retMiles += distMiles
      })

      let milesStr = (Math.round(retMiles * 10) / 10) + ' Km'
      let dist = (Math.round(retMiles * 10) / 10)


      setLastPolyline(routePolyline)
      setDrawnPoly(true)
      setDistance(dist)

    } else {
      console.log('Directions request failed due to ' + status);
      //window.alert('Directions request failed due to ' + status);
      }
    });
}

function momentFormat(date) {
  return moment(date).format('yyyy-MM-DD') + 'T' + moment(date).format('HH:mm')
}

function momentFormatDate(date) {
  return moment(date).format('yyyy-MM-DD')
}

function momentFormatFirefox(date, time) {
  return moment(date).format('yyyy-MM-DD') + 'T' + moment(time).format('HH:mm')
}

function prepVehiclePlateDict(users) {
  var pdict = {}
  users.map((item, idx) => {
    pdict[item.plate] = item 
  })
  return pdict

}


function prepUserPhoneDict(users) {
  var pdict = {}
  users.map((item, idx) => {
    pdict[item.phone] = item 
    if (item.secondaryPhones && item.secondaryPhones.length > 0) {
      item.secondaryPhones.map((inner, inidx) => {
        pdict[inner] = item 
      })
    }
  })
  return pdict
}


  function handleApiLoaded(newMap, newMaps) {
   console.log('api load')
   //alert(newMap)
    setMap(newMap)
    setMaps(newMaps)

    if (!autocompleteService.current || !drawnPoly) {
      autocompleteService.current = new newMaps.places.AutocompleteService();
      geocoder.current = new newMaps.Geocoder();

      directionsService.current = new newMaps.DirectionsService();
      directionsDisplay.current = new newMaps.DirectionsRenderer();
      bounds.current  = new newMaps.LatLngBounds()
      if (props.parentState.customer) {
        updateMapDraw(props.parentState.customer.startPos, props.parentState.customer.endPos, newMap, newMaps);
      }
      
    }
  }

  function updateDocumentName(documentList, x, setFunction) {
    if (x == '') {
      return 
    }
    var newList = documentList.map((item, idx) => {
      if (idx == targetDocumentIndex) {
        var newItem = item 
        if (item.linkval) {
          newItem['docname'] = x 
        } else {
          newItem = {}
          newItem['linkval'] = item 
          newItem['docName'] = x
        }
        return newItem
      }
      return item 
    })
    setFunction('documentList', newList)
  }

  function updateDocumentNameContract(documentList, x, setFunction) {
    if (x == '') {
      return 
    }
    var newList = documentList.map((item, idx) => {
      if (idx == targetDocumentIndexContract) {
        var newItem = item 
        if (item.linkval) {
          newItem['docname'] = x 
        } else {
          newItem = {}
          newItem['linkval'] = item 
          newItem['docName'] = x
        }
        return newItem
      }
      return item 
    })
    setFunction('contractDocumentList', newList)
  }

  function getContractsEditable(contracts, shipper) {
    let j = contracts.filter((item, idx) => {
      return item.shipper == shipper 
    })
    return j.length > 0
  }

  function dateNextHour(addDay) {
    var d = new Date()
    d.setMinutes(d.getMinutes() + 60)
    d.setMinutes(0)
    if (addDay) {
      d.setMinutes(d.getMinutes() + 1440 )
    }
    return momentFormat(d)
  }

  function onCreateUser(value) {
    if (addUserType == 'Transporter') {
      if (props.parentState.customer && props.parentState.customer.assignedTransporter) {
        // If there was already an assigned transporter, we have to track the old one
        addUserFunction('oldAssignedTransporter', props.parentState.customer.assignedTransporter)
      }   
      // If there was not already an assigned transporter, we are good to go
      addUserFunction('assignedTransporter', value.userId)
      
  
      if (props.parentState.customer && props.parentState.customer.assignedTransporter == value.userId) {
        // If we are setting transporter back to same transporter, we can also set driver back to same driver
        //alert('case 1')
        addUserFunction('assignedDriver', props.parentState.customer.assignedDriver)
      } else if (props.parentState.customer && props.parentState.customer.assignedDriver) {
        // Otherwise we have to set assigned driver to unassigned
        //alert('case 2 ' + props.parentState.customer.oldAssignedDriver)
        addUserFunction('oldAssignedDriver', props.parentState.customer.assignedDriver)
        addUserFunction('assignedDriver', '0')
      } else {
        //alert('case 3')
        addUserFunction('assignedDriver', '0')
      }
    } else {
      if (props.parentState.customer && props.parentState.customer.assignedDriver) {
        // If there is already an assigned driver, we have to track the old one
        addUserFunction('oldAssignedDriver', props.parentState.customer.assignedDriver)
      } 
      // If there is not an assigned driver yet, we can freely set a new one without worrying about the old one
      addUserFunction('assignedDriver', value.userId)
    }

  }

  function onCreateVehicle(value) {
    //alert(JSON.stringify(value))
    addUserFunction('vehicleId', value.vehicleId)
    addUserFunction('vehiclePlate', value.plate)
    addUserFunction('vehicleTrailerPlate', value.trailerPlate)
  }

  function generateProFormaInvoice(values, setInvoiceLoading, stateval) {
    setInvoiceLoading(true)
    let jll = [...Array(stateval.numTrips).keys()];
    var jobSet = []
    jll.map((item, idx) => {
      var vv = values
      vv['startName'] = vv.pickupLocation
      vv['endName'] = vv.dropoffLocation
      jobSet.push(vv)
    })
    

    var userId = props.parentState.customer.shipper
    //alert(userId)
    var jobList = {'numTrips': stateval.numTrips, 'jobs': jobSet, 'terms': stateval.terms, 'notes': stateval.notes, 'user': userId}
    
    let s = async function (retval) {
      if (retval.code == -1) {
        alert('User not specified for invoicing.')
        setInvoiceLoading(false)
        return 
      }
      if (retval.code == -2) {
        alert('At least one selected job is already invoiced.')
        setInvoiceLoading(false)
        return 
      }
      console.log('succ')
      const imageRef = Firebase.storage.ref(retval.invoiceUid).child('pdf_for_pfinvoice_' + retval.invoiceNumber + '.pdf')
      let v = await imageRef.getDownloadURL();
      console.log(v)
      console.log('got it')
      var updateSet = {'invoiceId': retval.invoiceId, 'pdflink': v}

      let s1 = async function () {
        setInvoiceLoading(false)
      }
      let f2 = async function() {
        setInvoiceLoading(false)
      }
      //s1()
      //window.open(v)
      Firebase.provideInvoiceLink(updateSet, s1, f2)

      window.open(v)
    }
    let f = function () {
      setInvoiceLoading(false)
      console.log('failed')
    }
    Firebase.proFormaInvoice(jobList, s, f)
  }

  function setActiveFreightOrder(values, freightOrderId, setFunc, freightOrders, map, maps) {
    if (values.freightOrderId == freightOrderId) {
      return 
    }

    var contract = null 
    let j = freightOrders.map((item, idx) => {
      if (item.freightOrderId == freightOrderId) {
        contract = item 
      }
    })

    if (contract == null) {
      alert('Error setting freight order.')
      return 
    }
    //setFunc('price', mathRound(contract.pricePerQuintal * (values.trueWeight * 10)) )
    //setFunc('transporterPrice', mathRound(contract.transporterPricePerQuintal * (values.trueWeight * 10)) )
    //let newv = mathRound(contract.pricePerQuintal * (values.trueWeight * 10))
    //let newvo = contract.pricePerQuintal * (values.trueWeight * 10)
    //alert(contract.pricePerQuintal + ' and ' + values.trueWeight + ' and ' + newv + ' ' + newvo)

    setFunc('oldFreightOrderId', values.freightOrderId)
    setFunc('freightOrderId', freightOrderId)
    setFunc('price', mathRound(contract.pricePerQuintal * (values.trueWeight * 10)) )
    setFunc('transporterPrice', mathRound(contract.transporterPricePerQuintal * (values.trueWeight * 10)) )
    //setFunc('price', mathRound(values.pricePerQuintal * (xweight * 10)) )
    //setFunc('transporterPrice', contract.transporterPrice)
    setFunc('packaging', contract.packaging)
    setFunc('commodity', contract.commodity)
    setFunc('dimension', contract.dimension)
    setFunc('oversize', contract.oversize)
    setFunc('pickupLocation', contract.startName)
    setFunc('dropoffLocation', contract.endName)
    setFunc('earlyDrop', momentFormat(contract.earlyDrop))
    setFunc('earlyPick', momentFormat(contract.earlyPick))
    setFunc('cargoType', contract.cargoType)
    setFunc('cargoFields', contract.cargoFields)
    //setFunc('trueWeight', contract.trueWeight)
    setFunc('pricePerQuintal', contract.pricePerQuintal)
    setFunc('transporterPricePerQuintal', contract.transporterPricePerQuintal)
    setFunc('contractId', contract.contractId)

    setLocationStart({'lat': contract.startPos.lat, 'lng': contract.startPos.lng})
    setLocationEnd({'lat': contract.endPos.lat, 'lng': contract.endPos.lng})
    updateMapDraw({'lat': contract.startPos.lat, 'lng': contract.startPos.lng}, {'lat': contract.endPos.lat, 'lng': contract.endPos.lng}, map, maps)
  }

  function setActiveLtc(ltcId, setFunc, contracts, map, maps) {

    var contract = null 
    let j = contracts.map((item, idx) => {
      if (item.contractId == ltcId) {
        contract = item 
      }
    })

    if (contract == null) {
      alert('Error setting contract.')
      return 
    }
    setFunc('ltcValue', ltcId)
    setFunc('price', contract.price)
    setFunc('transporterPrice', contract.transporterPrice)
    setFunc('packaging', contract.packaging)
    setFunc('commodity', contract.commodity)
    setFunc('dimension', contract.dimension)
    setFunc('oversize', contract.oversize)
    setFunc('pickupLocation', contract.startName)
    setFunc('dropoffLocation', contract.endName)
    setFunc('earlyDrop', momentFormat(contract.earlyDrop))
    setFunc('earlyPick', momentFormat(contract.earlyPick))
    setFunc('cargoType', contract.cargoType)
    setFunc('cargoFields', contract.cargoFields)
    setFunc('trueWeight', contract.trueWeight)
    setFunc('pricePerQuintal', contract.pricePerQuintal)
    setFunc('transporterPricePerQuintal', contract.transporterPricePerQuintal)
    setFunc('contractId', contract.contractId)
    if (contract.workOrderJob) {
      setFunc('workOrderJob', true)
      setFunc('contractEndDate', momentFormatDate(contract.endDate))
      setFunc('contractStartDate', momentFormatDate(contract.startDate))
      setFunc('contractUsed', contract.totalWeightUsed)
      setFunc('contractTotal', contract.totalWeightValue)
    }

    setLocationStart({'lat': contract.startPos.lat, 'lng': contract.startPos.lng})
    setLocationEnd({'lat': contract.endPos.lat, 'lng': contract.endPos.lng})
    updateMapDraw({'lat': contract.startPos.lat, 'lng': contract.startPos.lng}, {'lat': contract.endPos.lat, 'lng': contract.endPos.lng}, map, maps)
  }

  React.useEffect(() => {
    //console.log(props.parentState.customer)
    if (!didSetLocStart && props.parentState && props.parentState.customer && props.parentState.customer.startPos) {
      setLocationStart(props.parentState.customer.startPos)
      setDidSetLocStart(true)
    }
    if (!didSetLocEnd && props.parentState && props.parentState.customer && props.parentState.customer.endPos) {
      setLocationEnd(props.parentState.customer.endPos)
      setDidSetLocEnd(true)
    }
    
    let active = true;
    //alert(JSON.stringify(tableItems))
    if (!autocompleteService.current && window.google) {
      autocompleteService.current = new window.google.maps.places.AutocompleteService();
      geocoder.current = new window.google.maps.Geocoder();
      console.log(autocompleteService.current)
    }
    if (!autocompleteService.current || !geocoder.current) {
      return undefined;
    }

    if (inputValue === '') {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetch({ input: inputValue }, (results) => {

      console.log(results)
      if (active) {
        let newOptions = [];

        if (value) {
          newOptions = [value];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      }
    });

    if (typeof window !== 'undefined' && typeof Firebase.auth !== 'undefined') {
      console.log('dothis')
      window.recaptchaVerifier = new Firebase.firebaseRef.auth.RecaptchaVerifier('get-quote-button', {
        'size': 'invisible',
        'callback': function(response) {
          // reCAPTCHA solved, allow signInWithPhoneNumber.
          //phoneVerify(2);
          alert('solved')
        }
      });
    }

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);

  if (isAuthenticating) {
    return (<Page
      className={classes.root}
      title="Job Management"
      backgroundColor={classes.root.backgroundColor}
    ><div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}><CircularProgress/></div></Page>)
  }



  return (
    <Page
      className={classes.root}
      title="Job Management"
      backgroundColor={classes.root.backgroundColor}
    >
      <Formik
            initialValues={{
              shipper: props.parentState.customer ? props.parentState.customer.shipper : '',
              contractName: props.parentState.customer ? props.parentState.customer.contractName : '',
              price: props.fillTimes && props.parentState.customer ? renderProperPrice(props.parentState.customer, 40) : props.parentState.customer ? props.parentState.customer.price : '',
              transporterPrice: props.fillTimes && props.parentState.customer ? renderProperTransporterPrice(props.parentState.customer, 40) : props.parentState.customer ? props.parentState.customer.transporterPrice : '',
              packaging: props.parentState.customer ? props.parentState.customer.packaging : '',
              commodity: props.parentState.customer ? props.parentState.customer.commodity : '',
              dimension: props.parentState.customer ? props.parentState.customer.dimension : '',
              oversize: props.parentState.customer ? props.parentState.customer.oversize : false,
              pickupLocation: props.parentState.customer ? props.parentState.customer.startName : '',
              dropoffLocation: props.parentState.customer ? props.parentState.customer.endName : '',
              earlyDrop: props.fillTimes ? dateNextHour(true) : (props.parentState.customer ? momentFormat(props.parentState.customer.endDateEarly) : ''),
              earlyPick: props.fillTimes ? dateNextHour(false) : (props.parentState.customer ? momentFormat(props.parentState.customer.startDateEarly) : ''),
              endDate: props.parentState.customer ? momentFormat(props.parentState.customer.endDate) : '',
              startDate: props.parentState.customer ? momentFormat(props.parentState.customer.startDate) : '',
              cargoType: props.parentState.customer ? props.parentState.customer.cargoType : '',
              cargoFields: props.parentState.customer ? props.parentState.customer.cargoFields : '',
              assignedDriver: props.parentState.customer && props.parentState.customer.assignedDriver ? props.parentState.customer.assignedDriver : '0',
              assignedTransporter: props.parentState.customer && props.parentState.customer.assignedTransporter ? props.parentState.customer.assignedTransporter : '0',
              oldAssignedDriver: null,
              oldAssignedTransporter: null,
              oldFreightOrderId: null,
              contractDocumentList: props.contractDocumentList ? props.contractDocumentList : [],
              documentList: props.parentState.customer && props.parentState.customer.documentList ? props.parentState.customer.documentList : [],
              trueWeight: props.fillTimes ? 40 : (props.parentState.customer && !(props.fromLTC && props.parentState && props.parentState.customer && props.parentState.customer.workOrderJob) ? props.parentState.customer.trueWeight : ''),
              pricePerQuintal: props.parentState.customer && props.parentState.customer.pricePerQuintal ? props.parentState.customer.pricePerQuintal : 0,
              transporterPricePerQuintal: props.parentState.customer && props.parentState.customer.transporterPricePerQuintal ? props.parentState.customer.transporterPricePerQuintal : 0,
              notAsQuote: false,
              sendText: true,
              roundTripLink: props.parentState.customer ? props.parentState.customer.roundTripLink : -1,
              countThisJob: props.parentState.customer ? props.parentState.customer.countThisJob : true,
              workOrderJob : props.ltcCreation || (props.fromLTC && props.parentState && props.parentState.customer && props.parentState.customer.workOrderJob) ? true : false,
              contractId: props.parentState.customer ? props.parentState.customer.contractId : null,
              contractStartDate: (props.fromLTC && props.parentState && props.parentState.customer && props.parentState.customer.startDate) ? momentFormatDate(props.parentState.customer.startDate) : '',
              contractEndDate: (props.fromLTC && props.parentState && props.parentState.customer && props.parentState.customer.endDate) ? momentFormatDate(props.parentState.customer.endDate) : '',
              contractUsed: (props.fromLTC && props.parentState && props.parentState.customer && props.parentState.customer.totalWeightUsed) ? props.parentState.customer.totalWeightUsed : 0,
              contractTotal: (props.fromLTC && props.parentState && props.parentState.customer && props.parentState.customer.totalWeightValue) ? props.parentState.customer.totalWeightValue : 0,
              vehicleId: props.parentState.customer && props.parentState.customer.vehicleId ? props.parentState.customer.vehicleId : '0', 
              vehiclePlate: props.parentState.customer && props.parentState.customer.vehiclePlate ? props.parentState.customer.vehiclePlate : 'Unassigned',
              vehicleTrailerPlate: props.parentState.customer && props.parentState.customer.vehicleTrailerPlate ? props.parentState.customer.vehicleTrailerPlate : '',
              advancePayment: props.parentState.customer && props.parentState.customer.advancePayment ? props.parentState.customer.advancePayment : '',
              finalWeight: props.parentState.customer && props.parentState.customer.finalWeight ? props.parentState.customer.finalWeight : '',
              jobIsPaid: props.parentState.customer && props.parentState.customer.jobIsPaid ? props.parentState.customer.jobIsPaid : '',
              paymentMethod: props.parentState.customer && props.parentState.customer.paymentMethod ? props.parentState.customer.paymentMethod : 'check',
              paymentMethodInitial: props.parentState.customer && props.parentState.customer.paymentMethodInitial ? props.parentState.customer.paymentMethodInitial : 'cash',
              checkNumber: props.parentState.customer && props.parentState.customer.checkNumber ? props.parentState.customer.checkNumber : '',
              adminNotes: props.parentState.customer && props.parentState.customer.adminNotes ? props.parentState.customer.adminNotes : '',
              shipperNotes: props.parentState.customer && props.parentState.customer.shipperNotes ? props.parentState.customer.shipperNotes : '',
              freightOrderId: props.parentState.customer ? props.parentState.customer.freightOrderId : null,
              gzfawgs: false ? 's' : 'b',
              gzfawga: false ? 's' : 'b',
              gzfawg: false ? 's' : 'b',

            }}
            validationSchema={
              Yup.object().shape({
                commodity: Yup.string().max(255).required('Commodity is required'),
                packaging: Yup.string().max(255).required('Packaging is required'),
                pickupLocation: Yup.string().max(255).required('Pickup location is required'),
                dropoffLocation: Yup.string().max(255).required('Dropoff location is required'),
                earlyPick: Yup.string().max(255).required('Pickup and dropoff times are required.'),
                earlyDrop: Yup.string().max(255).required('Pickup and dropoff times are required.'),
                trueWeight: Yup.number().required('Weight is required.')
              })
            }
            onSubmit={(values, acts) => {
              //alert('tt')
              //console.log('sub')
              //setNumVal(false)
              doSubmit(values)
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              setSubmitting,
              touched,
              values,
              setFieldValue,
              resetForm,
              validateForm
            }) => (
              <form onSubmit={handleSubmit}>

        <Container maxWidth={'90%'} backgroundColor={classes.root.backgroundColor}>
        <QuickInvoiceDialog
        open={quickInvoiceDialog}
        onClose={() => setQuickInvoiceDialog(false)}
        alertTitle={'Create Pro Forma Invoice'}
        alertCancelText={'Cancel'}
        alertConfirmText={'Confirm'}
        onConfirm={(x) => generateProFormaInvoice(values, (y) => setQuickInvoiceDialog(y), x)}
        base={baseSet}
        />
        <NameDocumentDialog
        open={showDocumentDialog}
        ref={docDiaRef}
        onClose={() => setShowDocumentDialog(false)}
        alertTitle={'Set document name, or leave blank for default name \"Document ' + (targetDocumentIndex + 1) + '\"'}
        alertCancelText={'Cancel'}
        alertConfirmText={'Confirm'}
        documentName={documentPlaceholder}
        onConfirm={(x) => updateDocumentName(values.documentList, x, (x,y) => setFieldValue(x,y))}
        />
                <NameDocumentDialog
        open={showDocumentDialogContract}
        ref={docDiaRefContract}
        onClose={() => setShowDocumentDialogContract(false)}
        alertTitle={'Set document name, or leave blank for default name \"Document ' + (targetDocumentIndexContract + 1) + '\"'}
        alertCancelText={'Cancel'}
        alertConfirmText={'Confirm'}
        documentName={documentPlaceholder}
        onConfirm={(x) => updateDocumentNameContract(values.contractDocumentList, x, (x,y) => setFieldValue(x,y))}
        />
                <AddUserDialog
        open={addUserDialog}
        limitedAccessFunction={props.limitedAccessFunction ? props.limitedAccessFunction : null}
        onClose={() => setAddUserDialog(false)}
        phoneDict={prepUserPhoneDict(props.adminUsers ? props.adminUsers : [])}
        userType={addUserType}
        assignedTransporter={values.assignedTransporter}
        alertTitle={'Add User'}
        alertCancelText={'Cancel'}
        alertConfirmText={'Confirm'}
        onConfirm={(x) => onCreateUser(x)}
        />
                        <AddVehicleDialog
        open={addVehicleDialog}
        limitedAccessFunction={props.limitedAccessFunction ? props.limitedAccessFunction : null}
        updateFunction={(x) => setVehicleLevelUpdateFunction(x)}
        adminUsers={props.adminUsers ? props.adminUsers : []}
        adminAssocs={props.adminAssocs ? props.adminAssocs : []}
        onClose={() => setAddVehicleDialog(false)}
        plateDict={prepVehiclePlateDict(props.adminVehicles ? props.adminVehicles : [])}
        assignedTransporter={values.assignedTransporter}
        alertTitle={'Add Vehicle'}
        alertCancelText={'Cancel'}
        alertConfirmText={'Confirm'}
        onConfirm={(x) => onCreateVehicle(x)}
        />
        <div style={{flexDirection: 'row', display: 'flex'}}>
          <div style={{'flexDirection': 'column'}}>


                {getTitleShow(props)}



                <a style={{fontSize: 24, fontWeight: 'bold'}}>Cargo Details</a>
                <div style={{flexDirection: 'row'}}>
                <TextField
                  error={Boolean(touched.commodity && errors.commodity)}
                  disabled={!editable && !props.jobProps.datesEditable}
                  width={'71%'}
                  style={{marginRight: 30}}
                  label="Commodity"
                  margin="normal"
                  name="commodity"
                  onChange={handleChange}
                  value={values.commodity}
                  variant="outlined"
                />
                <TextField
                  error={Boolean(touched.trueWeight && errors.trueWeight)}
                  width={'71%'}
                  style={{marginRight: 30}}
                  helperText={touched.trueWeight && errors.trueWeight}
                  label={props.ltcCreation && values.workOrderJob ? "Total Weight" : "Weight"}
                  margin="normal"
                  name="trueWeight"
                  disabled={!editable && !props.jobProps.datesEditable}
                  type="number"
                  InputProps={{
                    endAdornment: <InputAdornment position="start">

<Select
          fullWidth
          label="Cargo Type"
          margin="normal"
          id="cargoType"
          name="cargoType"
          disabled={!editable}
          value={weightMetric}
          onChange={(e) => {
            setWeightMetric(e.target.value)
            //setFieldValue('cargoTypeDisp', e.target.value.name)
          }}
        >
          {[ {'name': 'Quintals', 'val': 'quintal'}, {'name': 'Tons', 'val': 'ton'}].map((item, idx) => {
            return (<MenuItem value={item.val}>{item.name}</MenuItem>)
          })}
        </Select>

                    </InputAdornment>,
                  }}
                  onBlur={handleBlur}
                  onChange={(e) => {
                    if (false && e.target.value <= 0) {
                      alert('Weight cannot be zero or less.')
                    } else {

                      let xweight = weightMetric == 'ton' ? e.target.value : e.target.value / 10
                      if (e.target.value == '') {
                        setFieldValue('trueWeight', '')
                      } else {
                        setFieldValue('trueWeight', xweight)
                      }

                      if (e.target.value > 0) {
                        if (props.fromLTC || true) {
                          setFieldValue('price', mathRound(values.pricePerQuintal * (xweight * 10)) )
                          setFieldValue('transporterPrice', mathRound(values.transporterPricePerQuintal * (xweight * 10)) )
                        } else {
                          setFieldValue('pricePerQuintal', mathRound(values.price / (xweight * 10)) )
                          setFieldValue('transporterPricePerQuintal', mathRound(values.price / (xweight * 10)) )
                        }
                        
                      }
                      
                    } 
                  }
                  }
                  value={values.trueWeight == '' ? '' : weightMetric == 'ton' ? values.trueWeight : precisionRound(values.trueWeight * 10)}
                  variant="outlined"
                />
                </div>
                <div style={{flexDirection: 'row'}}>
                  <FormControl style={{width : '71%', marginRight: 20}}>
                <TextField select
                              disabled={!editable}
                width={'30%'}
          label="Packaging"
          margin="normal"
          id="packaging"
          variant="outlined"
          name="packaging"
          value={values.packaging}
          onChange={handleChange}
        >
          {packagingOptions.map((item, idx) => {
            return (<MenuItem value={item.val}>{item.name}</MenuItem>)
          })}
        </TextField>
        </FormControl>      
 
                </div>

                {!((values.workOrderJob && props.ltcCreation) || props.createFreightOrder) ? null : <div> {isFirefox ? 
    <Grid container >
    <TextField
                  disabled={!editable && !props.jobProps.datesEditable}
label={props.createFreightOrder ? "Order Start" : "Contract Start"}
type="date"
variant="outlined"
margin="normal"
name="startDate"
onBlur={handleBlur}
onChange={(e) => {

  setFieldValue('startDate', momentFormatFirefox(e.target.value, firefoxTimeStartDate))
  setFieldValue('earlyPick', momentFormatFirefox(e.target.value, firefoxTimeStartDate))
}}
value={values.startDate == '' ? values.startDate : momentFormatDate(values.startDate)}
error={Boolean(touched.startDate&& errors.startDate)}
className={classes.textField}
InputLabelProps={{
shrink: true,
}}
/>

<MuiPickersUtilsProvider utils={DateFnsUtils}>
<TimePicker
disabled={!editable && !props.jobProps.datesEditable}
inputProps={{
  style: {
    height: 20,
    paddingTop: 35,
    paddingBottom: 18, paddingLeft: 5
  },
}}
error={Boolean(touched.startDate && errors.startDate)}
        clearable
        onChange={(e) => {
          console.log(e)
          if (values.startDate != '') {
            setFieldValue('startDate', momentFormatFirefox(values.startDate, e))
            setFieldValue('earlyPick', momentFormatFirefox(values.startDate, e))
          }
          setFirefoxTimeStartDate(e)
          //setFieldValue('earlyPick', momentFormatFirefox(e.target.value, firefoxTimePickup))
        }}
        value={firefoxTimeStartDate}
      /></MuiPickersUtilsProvider>
</Grid>
   :      
   
   <Grid container justify="space-between">
     <TextField
     disabled={!editable && !props.jobProps.datesEditable}
label={props.createFreightOrder ? "Order Start Date" : "Contract Start"}
type="datetime-local"
margin="normal"
variant="outlined"
name="startDate"
onBlur={handleBlur}
onChange={(e) => {
  setFieldValue('startDate', e.target.value)
  setFieldValue('earlyPick', e.target.value)
}}
className={classes.textField}
error={Boolean(touched.startDate && errors.startDate)}
value={values.startDate}
InputLabelProps={{
shrink: true,
}}
/>
</Grid>}</div>}     

                {!((values.workOrderJob && props.ltcCreation) || props.createFreightOrder) ? null : <div> {isFirefox ? 
    <Grid container >
    <TextField
                  disabled={!editable && !props.jobProps.datesEditable}
label={props.createFreightOrder ? "Order End Date" : "Contract End"}
type="date"
variant="outlined"
margin="normal"
name="endDate"
onBlur={handleBlur}
onChange={(e) => {

  setFieldValue('endDate', momentFormatFirefox(e.target.value, firefoxTimeEndDate))
  setFieldValue('earlyDrop', momentFormatFirefox(e.target.value, firefoxTimeEndDate))
}}
value={values.endDate == '' ? values.endDate : momentFormatDate(values.endDate)}
error={Boolean(touched.endDate&& errors.endDate)}
className={classes.textField}
InputLabelProps={{
shrink: true,
}}
/>

<MuiPickersUtilsProvider utils={DateFnsUtils}>
<TimePicker
disabled={!editable && !props.jobProps.datesEditable}
inputProps={{
  style: {
    height: 20,
    paddingTop: 35,
    paddingBottom: 18, paddingLeft: 5
  },
}}
error={Boolean(touched.endDate && errors.endDate)}
        clearable
        onChange={(e) => {
          console.log(e)
          if (values.endDate != '') {
            setFieldValue('endDate', momentFormatFirefox(values.endDate, e))
            setFieldValue('earlyDrop', momentFormatFirefox(values.endDate, e))
          }
          setFirefoxTimeEndDate(e)
          //setFieldValue('earlyPick', momentFormatFirefox(e.target.value, firefoxTimePickup))
        }}
        value={firefoxTimeEndDate}
      /></MuiPickersUtilsProvider>
</Grid>
   :      
   
   <Grid container justify="space-between">
     <TextField
     disabled={!editable && !props.jobProps.datesEditable}
label={props.createFreightOrder ? "Order End Date" : "Contract End"}
type="datetime-local"
margin="normal"
variant="outlined"
name="endDate"
onBlur={handleBlur}
onChange={(e) => {
  setFieldValue('endDate', e.target.value)
  setFieldValue('earlyDrop', e.target.value)
}}
className={classes.textField}
error={Boolean(touched.endDate && errors.endDate)}
value={values.endDate}
InputLabelProps={{
shrink: true,
}}
/>
</Grid>}</div>}     
           <div style={{flexDirection: 'row'}}>

             
           <a style={{fontSize: 24, fontWeight: 'bold'}}>Pickup Details</a>
                <Autocomplete
                              disabled={!editable}
      id="pickupLocation"
      name="pickupLocation"
      style={{ width : '71%' }}
      getOptionLabel={(option) => (typeof option === 'string' ? option : option.description)}
      filterOptions={(x) => x}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={values.pickupLocation}
      onChange={(event, newValue) => {
        setOptions(newValue ? [newValue, ...options] : options);
        
        setValue(newValue);
        
        if (geocoder.current != null && newValue != null) {
          geocoder.current.geocode({ 
            'placeId': newValue.place_id
        }, 
        function(responses, status) {
            if (status == 'OK') {
              if (responses[0]) {
                let svs = countryCheck(responses[0].address_components)
                if (svs != null && svs.country != null) {
                  setLocationStartCountry(svs.country)
                } 
                if (svs != null && svs.locality != null) {
                  setLocalityStart(svs.locality)
                }
              }
              

                var lat = responses[0].geometry.location.lat();
                var lng = responses[0].geometry.location.lng();
                setLocationStart({'lat': lat, 'lng': lng})
                updateMapDraw({'lat': lat, 'lng': lng}, locationEnd, map, maps)
                console.log(lat, lng);
            }
        });
        }
        if (newValue != null) {
          setFieldValue('pickupLocation', newValue.description)
        }
        

      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => (
        <TextField {...params} label="Pickup Location" variant="outlined" margin="normal" fullWidth onBlur={handleBlur}
        name="pickupLocation"
        disabled={!editable}
        error={Boolean(touched.pickupLocation && errors.pickupLocation)}
        value={values.pickupLocation}/>
      )}
      renderOption={(option) => {
        const matches = option.structured_formatting.main_text_matched_substrings;
        if (!matches) {
          return
        }
        const parts = parse(
          option.structured_formatting.main_text,
          matches.map((match) => [match.offset, match.offset + match.length]),
        );

        return (
          <Grid container alignItems="center">
            <Grid item>
              <LocationOnIcon className={classes.icon} />
            </Grid>
            <Grid item xs>
              {parts.map((part, index) => (
                <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                  {part.text}
                </span>
              ))}

              <Typography variant="body2" color="textSecondary">
                {option.structured_formatting.secondary_text}
              </Typography>
            </Grid>
          </Grid>
        );
      }}
    />
    {(values.workOrderJob && (props.ltcEdit || props.ltcCreation) || props.createFreightOrder) ? null : <div>{isFirefox ? 
    <Grid container >
    <TextField
                  disabled={!editable && !props.jobProps.datesEditable}
label="Earliest Pickup"
type="date"
variant="outlined"
margin="normal"
name="earlyPick"
onBlur={handleBlur}
onChange={(e) => {

  setFieldValue('earlyPick', momentFormatFirefox(e.target.value, firefoxTimePickup))
}}
value={values.earlyPick == '' ? values.earlyPick : momentFormatDate(values.earlyPick)}
error={Boolean(touched.earlyPick && errors.earlyPick)}
className={classes.textField}
InputLabelProps={{
shrink: true,
}}
/>

<MuiPickersUtilsProvider utils={DateFnsUtils}>
<TimePicker
disabled={!editable && !props.jobProps.datesEditable}
inputProps={{
  style: {
    height: 20,
    paddingTop: 35,
    paddingBottom: 18, paddingLeft: 5
  },
}}
error={Boolean(touched.earlyPick && errors.earlyPick)}
        clearable
        onChange={(e) => {
          console.log(e)
          if (values.earlyPick != '') {
            setFieldValue('earlyPick', momentFormatFirefox(values.earlyPick, e))
          }
          setFirefoxTimePickup(e)
          //setFieldValue('earlyPick', momentFormatFirefox(e.target.value, firefoxTimePickup))
        }}
        value={firefoxTimePickup}
      /></MuiPickersUtilsProvider>
</Grid>
    : 
    <Grid container justify="space-between">
                      <TextField
                                    disabled={!editable && !props.jobProps.datesEditable}
        label="Earliest Pickup"
        type="datetime-local"
        variant="outlined"
        margin="normal"
        name="earlyPick"
        onBlur={handleBlur}
        onChange={handleChange}
        value={values.earlyPick}
        error={Boolean(touched.earlyPick && errors.earlyPick)}
        className={classes.textField}
        InputLabelProps={{
          shrink: true,
        }}
      />

         </Grid>
    }</div>}
                    
                
         <a style={{fontSize: 24, fontWeight: 'bold'}}>Dropoff Details</a>
<Autocomplete
              disabled={!editable}
      id="dropoffLocation"
      name="dropoffLocation"
      style={{ width : '71%' }}
      getOptionLabel={(option) => (typeof option === 'string' ? option : option.description)}
      filterOptions={(x) => x}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={values.dropoffLocation}
      onChange={(event, newValue) => {
        setOptions(newValue ? [newValue, ...options] : options);
        
        setValueTwo(newValue);
        if (geocoder.current != null && newValue != null) {
          geocoder.current.geocode({ 
            'placeId': newValue.place_id
        }, 
        function(responses, status) {
          if (status == 'OK') {
            if (responses[0]) {
              let svs = countryCheck(responses[0].address_components)
              if (svs != null && svs.country != null) {
                setLocationEndCountry(svs.country)
              } 
              if (svs != null && svs.locality != null) {
                setLocalityEnd(svs.locality)
              }
            }

              var lat = responses[0].geometry.location.lat();
              var lng = responses[0].geometry.location.lng();
              setLocationEnd({'lat': lat, 'lng': lng})
              updateMapDraw(locationStart, {'lat': lat, 'lng': lng}, map, maps)
              console.log(lat, lng);
          }
      });
      }
      if (newValue != null) {
        setFieldValue('dropoffLocation', newValue.description)
      }
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => (
        <TextField {...params} label="Dropoff Location" variant="outlined" margin="normal" fullWidth onBlur={handleBlur}

        name="dropoffLocation"
        error={Boolean(touched.dropoffLocation && errors.dropoffLocation)}
        value={values.dropoffLocation}/>
      )}
      renderOption={(option) => {
        const matches = option.structured_formatting.main_text_matched_substrings;
        if (!matches) {
          return
        }
        const parts = parse(
          option.structured_formatting.main_text,
          matches.map((match) => [match.offset, match.offset + match.length]),
        );

        return (
          <Grid container alignItems="center">
            <Grid item>
              <LocationOnIcon className={classes.icon} />
            </Grid>
            <Grid item xs>
              {parts.map((part, index) => (
                <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                  {part.text}
                </span>
              ))}

              <Typography variant="body2" color="textSecondary">
                {option.structured_formatting.secondary_text}
              </Typography>
            </Grid>
          </Grid>
        );
      }}
    />

{(values.workOrderJob && (props.ltcCreation || props.ltcEdit) || props.createFreightOrder) ? null : <div> {isFirefox ? 
             <Grid container >
             <TextField
                           disabled={!editable && !props.jobProps.datesEditable}
         label="Earliest Dropoff"
         type="date"
         variant="outlined"
         margin="normal"
         name="earlyDrop"
         onBlur={handleBlur}
         onChange={(e) => {
         
           setFieldValue('earlyDrop', momentFormatFirefox(e.target.value, firefoxTimeDropoff))
         }}
         value={values.earlyDrop == '' ? values.earlyDrop : momentFormatDate(values.earlyDrop)}
         error={Boolean(touched.earlyDrop&& errors.earlyDrop)}
         className={classes.textField}
         InputLabelProps={{
         shrink: true,
         }}
         />
         
         <MuiPickersUtilsProvider utils={DateFnsUtils}>
         <TimePicker
         disabled={!editable && !props.jobProps.datesEditable}
         inputProps={{
           style: {
             height: 20,
             paddingTop: 35,
             paddingBottom: 18, paddingLeft: 5
           },
         }}
         error={Boolean(touched.earlyDrop && errors.earlyDrop)}
                 clearable
                 onChange={(e) => {
                   console.log(e)
                   if (values.earlyDrop != '') {
                     setFieldValue('earlyDrop', momentFormatFirefox(values.earlyDrop, e))
                   }
                   setFirefoxTimeDropoff(e)
                   //setFieldValue('earlyPick', momentFormatFirefox(e.target.value, firefoxTimePickup))
                 }}
                 value={firefoxTimeDropoff}
               /></MuiPickersUtilsProvider>
         </Grid>
            :      
            
            <Grid container justify="space-between">
              <TextField
              disabled={!editable && !props.jobProps.datesEditable}
        label="Earliest Dropoff"
        type="datetime-local"
        margin="normal"
        variant="outlined"
        name="earlyDrop"
        onBlur={handleBlur}
        onChange={handleChange}
        className={classes.textField}
        error={Boolean(touched.earlyDrop && errors.earlyDrop)}
        value={values.earlyDrop}
        InputLabelProps={{
        shrink: true,
        }}
        />
       </Grid>}</div>}           

       </div>


       { values.documentList && values.documentList.length > 0 ? <div style={{flexDirection: 'column', display: 'flex'}}><InputLabel>Driver Documents</InputLabel>{
            values.documentList.map((item, idx) => {
              return <div style={{flexDirection: 'row'}}>{item.isPdf ? null : <img style={{width: 50, height: 50}} src={item.linkval ? item.linkval : item}/>}<Link
              to={item}
              onClick={item.linkval ? () => window.open(item.linkval) : () => window.open(item)}
              variant="h6"
            >{item.docname ? item.docname : 'Document ' + (idx + 1)}</Link>{props.adminEdit || props.isLimited == 1 ? <div><Edit  style={{marginBottom: -5}} onClick={(e) => {
              if (docDiaRef == null || docDiaRef.current == null) {
                return
              }
              setTargetDocumentIndex(idx)
            
              if (item.docname && item.docname != '') {
                docDiaRef.current.setState({
                  notificationBody: item.docname
                })
                //setDocumentPlaceholder(item.docname)
              } else {
                docDiaRef.current.setState({
                  notificationBody: ''
                })
                //setDocumentPlaceholder('')
              }
              setShowDocumentDialog(true)

            }}/><Cancel onClick={() => {
              let xxs = values.documentList.filter((iteminner, idxinner) => {
                return idxinner != idx 
              })
              setFieldValue('documentList', xxs)
            }}/></div> :  null}</div>
            })
            
          }
                    <div style={{flexDirection: 'row', display: 'flex'}}>
          {values.documentList && atLeastOneImage(values.documentList) > 0 ? 
                  <Button
                  color="primary"
                  onClick={() => getPdfDoc(values)}
                  size="large"
                  id="getdocbutton"
                  type="awjwegwaeeifj"
                  variant="contained"
                  disabled={pdfLoading}
                >
                  {'Document(s) Pdf'}
                </Button>  : null
  }{pdfLoading ? <CircularProgress/> : null}

          </div>
          </div> : <div>{props.jobProps.showAssignments || props.isLimited == 1 ? <InputLabel>No Load Documents Uploaded Yet</InputLabel> : <div/>}
          

          </div>}
  {props.adminEdit || props.isLimited == 1 ? 
  <div>
        <input id="myInputFolder" ref={inputRef} style={{ display: 'none' }} type="file" accept="image/x-png,image/gif,image/jpeg,application/pdf" webkitdirectory multiple onChange={(e) => handleSelectedFolder(e, values.documentList, (x, y) => setFieldValue(x, y))} />
    <div style={{display: 'flex', flexDirection: 'row'}}><AddBox onClick={(event) => inputRef.current.click()}></AddBox>

    </div>
    {uploadsHappening ? <div><CircularProgress/><a style={{marginLeft: 5}}>Uploading file(s), please wait...</a></div> : null}
    </div>
   : null}

          {props.publicQuote ? <div style={{flexDirection: 'column', display: 'flex'}}>
          <a style={{fontSize: 24, fontWeight: 'bold'}}>Shipper Details</a>
          <FormControl style={{width : '71%', }}>
          <TextField
                  error={Boolean(touched.shipperName && errors.shipperName)}
                  width={'30%'}
                  helperText={touched.shipperName && errors.shipperName}
                  label="Shipper Name"
                  margin="normal"
                  name="shipperName"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.shipperName}
                  variant="outlined"
                />
          </FormControl>
          <FormControl style={{width : '71%', }}>
          <TextField
                  error={Boolean(touched.shipperEmail && errors.shipperEmail)}
                  width={'30%'}
                  helperText={touched.shipperEmail && errors.shipperEmail}
                  label="Shipper Email"
                  margin="normal"
                  name="shipperEmail"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.shipperEmail}
                  variant="outlined"
                />
          </FormControl>
          <FormControl style={{width : '71%', }}>
          <MuiPhoneNumber defaultCountry={'et'}
                regions={['america','africa']}
                onlyCountries={['us', 'et']}
                autoFormat={false}
                disableAreaCodes={true}
                  error={Boolean(touched.email && errors.email)}
                  width={'30%'}
                  helperText={touched.email && errors.email}
                  label="Phone"
                  id="phone"
                  margin="normal"
                  name="phone"
                  onBlur={handleBlur}
                  onChange={(e) => setFieldValue('phone', e)}
                  value={values.phone}
                  variant="outlined"
                />
          </FormControl>

                
          </div> :<div/>}


        {props.jobProps.showAssignments || adminAutoAssign ? <div style={{flexDirection: 'column', display: 'flex'}}>
          
          <div>
          <FormControl style={{ width: '60%', marginRight: 10}}>
       
        <Autocomplete
  id="assignedTransporter"
  disabled={!editable || Firebase.IS_ASSOCIATION}
  options={getTransporterOptions()}
  getOptionLabel={(option) => renderUserCompanyName(option)}
  value={{'userId': values.assignedTransporter, 'companyName': getUserCompanyName(values.assignedTransporter)}}
  onChange={(e, value) => {
    
    if (value == null) {
      value = {}
      value['userId'] = '0'
    }

    setShowAllVehicles(false)
    
    if (props.parentState.customer && props.parentState.customer.assignedTransporter) {
      // If there was already an assigned transporter, we have to track the old one
      setFieldValue('oldAssignedTransporter', props.parentState.customer.assignedTransporter)
    }   
    // If there was not already an assigned transporter, we are good to go
    setFieldValue('assignedTransporter', value.userId)
    

    if (props.parentState.customer && props.parentState.customer.assignedTransporter == value.userId) {
      // If we are setting transporter back to same transporter, we can also set driver back to same driver
      //alert('case 1')
      setFieldValue('assignedDriver', props.parentState.customer.assignedDriver)
    } else if (props.parentState.customer && props.parentState.customer.assignedDriver) {
      // Otherwise we have to set assigned driver to unassigned
      //alert('case 2 ' + props.parentState.customer.oldAssignedDriver)
      setFieldValue('oldAssignedDriver', props.parentState.customer.assignedDriver)
      setFieldValue('assignedDriver', '0')
    } else {
      //alert('case 3')
      setFieldValue('assignedDriver', '0')
    }
    

  }}
  renderInput={(params) => <TextField {...params}  fullWidth
  label="Transporter"
  disabled={!editable || Firebase.IS_ASSOCIATION}
  margin="normal"
  id="assignedTransporter"
  name="transporter"
  variant="outlined"
  value={values.assignedTransporter}
  defaultValue={values.assignedTransporter} />}
/>
       </FormControl>
      {editable ? <AddBox onClick={() => {
          setAddUserFunction(() => (x,y) => 
            setFieldValue(x,y)
          )
         setAddUserType('Transporter')
         setAddUserDialog(true)

       }}></AddBox> : null} 
       </div>
       <div>
       <FormControl style={{width: '60%', marginRight: 10}}>
        <Autocomplete
  id="assignedDriver"
  disabled={!editable || Firebase.IS_ASSOCIATION}
  options={getDriverOptions(values.assignedTransporter)}
  getOptionLabel={(option) => renderUserNameForDisplay(option, values.assignedTransporter)}
  value={getUserDriver(values.assignedDriver)}
  onChange={(e, value) => {
    
    if (value == null) {
      value = {}
      value['userId'] = '0'
    }
    
    if (props.parentState.customer && props.parentState.customer.assignedDriver) {
      // If there is already an assigned driver, we have to track the old one
      setFieldValue('oldAssignedDriver', props.parentState.customer.assignedDriver)
    } 
    // If there is not an assigned driver yet, we can freely set a new one without worrying about the old one
    setFieldValue('assignedDriver', value.userId)
    

  }}
  renderInput={(params) => <TextField {...params}  fullWidth
  label="Driver"
  disabled={!editable || Firebase.IS_ASSOCIATION}
  margin="normal"
  id="assignedDriver"
  name="driver"
  variant="outlined"
  value={values.assignedDriver}
  defaultValue={values.assignedDriver} />}
/>
        
        </FormControl>
      {editable ?  <AddBox onClick={() => {
        //alert(values.assignedTransporter)
          if (values.assignedTransporter == null || values.assignedTransporter == '0' || values.assignedTransporter.length < 10) {
            alert('Transporter must be selected before adding driver.')
            return 
          }
          setAddUserFunction(() => (x,y) => setFieldValue(x,y))
         setAddUserType('Driver')
         setAddUserDialog(true)
         addUserFunction(('commodity', 'x'))
       }}></AddBox> : null } </div>
        </div> : <div/>}

        {props.jobProps.showAssignments ? <div>
          <FormControl style={{width: '45%', marginRight: 10}}> <Autocomplete
          id="combo-box-vehicle"
          options={getVehicleOptions(showAllVehicles, values.assignedTransporter)}
          renderOption={(option) => option.plate}
          getOptionLabel={(option) => {
            // Value selected with enter, right from the input
            if (typeof option === 'string') {
              return option;
            }
            // Add "xxx" option created dynamically
            if (option.inputValue) {
              return option.inputValue;
            }
            // Regular option
            return option.plate;
          }}
          value={{'vehicleId': values.vehicleId, 'plate': values.vehiclePlate}}
          defaultValue={{'vehicleId': props.parentState.customer && props.parentState.customer.vehicleId ? props.parentState.customer.vehicleId : '0', 
        'plate': props.parentState.customer && props.parentState.customer.vehiclePlate ? props.parentState.customer.vehiclePlate : '', }} 
        disabled={!editable || Firebase.IS_ASSOCIATION}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);
  
          // Suggest the creation of a new value
          if (params.inputValue !== '') {
            filtered.push({
              inputValue: params.inputValue,
              vehicleId: -1, 
              plate: `Add "${params.inputValue}"`,
            });
          }
  
          return filtered;
        }}
        freeSolo 
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
          onChange={(e, value) => {
            if (value && value.inputValue) {
              setFieldValue('vehicleId', -1)
              setFieldValue('vehiclePlate', value.inputValue)
            }
            else if (value != null) {
              setFieldValue('vehicleId', value.vehicleId)
              setFieldValue('vehiclePlate', value.plate)
              if ('trailerPlate' in value) {
                setFieldValue('vehicleTrailerPlate', value.trailerPlate)
              } else {
                setFieldValue('vehicleTrailerPlate', '')
              }
            } else {
              setFieldValue('vehicleId', '0')
              setFieldValue('vehiclePlate', '')
            }

          }}
          renderInput={(params) => <TextField {...params} 
          label="Vehicle"
          margin="normal"
          id="vehicleId"
          disabled={!editable || Firebase.IS_ASSOCIATION}
          name="vehicleId"
          variant="outlined"
          value={values.vehicleId}
          defaultValue={props.parentState.customer && props.parentState.customer.vehicleId ? props.parentState.customer.vehicleId : '0'} />}
        /></FormControl>
              {editable ? <AddBox onClick={() => {
          setAddUserFunction(() => (x,y) => 
            setFieldValue(x,y)
          )
          vehicleLevelUpdateFunction(values.assignedTransporter, props.adminUsers, props.adminAssocs)

         setAddVehicleDialog(true)

       }}></AddBox> : null} 
        <FormControl style={{width: '45%', marginRight: 10}}>
        <TextField
                  error={Boolean(touched.vehicleTrailerPlate && errors.vehicleTrailerPlate)}
                  disabled={!editable || Firebase.IS_ASSOCIATION}
                  label="Trailer Plate"
                  margin="normal"
                  id="vehicleTrailerPlate"
                  name="vehicleTrailerPlate"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.vehicleTrailerPlate}
                  variant="outlined"
                  InputProps={{
                      shrink: true 
                    }}
                />

        </FormControl>
      { props.adminEdit && props.statusUpdates ? 
        <Box
        alignItems="center"
        display="flex"
        ml={-1}
      >
        <Checkbox
        id="showAllVehicles"
          checked={showAllVehicles}
          name="showAllVehicles"
          onChange={(e) =>{
            if (!showAllVehicles) {
              setShowAllVehicles(true)
            }
          }}
        />
        <Typography
          color="textPrimary"
          variant="body1"
        >
          Show All Vehicles
        </Typography>
        <Tooltip placement="right-end" title="If you check this box, all vehicles will be shown. Otherwise only vehicles from the selected transporter will be available.">
                                <Help />
                            </Tooltip>
      </Box> : null 
      }  
        </div> : null}

        {props.jobProps.showPrices ? <div><FormControl style={{marginRight: 10}}> <TextField
                  error={Boolean(touched.phone && errors.phone)}
                  disabled={!priceEditable}
                  fullWidth
                  label="Shipper Price"
                  margin="normal"
                  name="price"
                  onChange={(e) => {
                    if (false && values.trueWeight <= 0) {
                      alert('Weight cannot be zero or less.')
                    } else {
                      console.log(e.target.value)
                      console.log(priceMetric)
                      console.log(values.trueWeight)
                      if (priceMetric == 'total') {
                        setFieldValue('price', e.target.value)

                        if (values.trueWeight > 0) {
                          setFieldValue('pricePerQuintal', mathRound(e.target.value / precisionRound(values.trueWeight * 10)))
                        }
                       
                      } else {
                        setFieldValue('price', e.target.value * values.trueWeight * 10)
                        setFieldValue('pricePerQuintal', e.target.value)
                      }


                    }
                    
                  }}
                  type="number"
                  InputProps={{
                    endAdornment: <InputAdornment position="start">

<Select
          fullWidth
          label="Cargo Type"
          margin="normal"
          id="cargoType"
          name="cargoType"
          disabled={!priceEditable && !props.fromLTC}
          value={priceMetric}
          onChange={(e) => {
            setPriceMetric(e.target.value)
            //setFieldValue('cargoTypeDisp', e.target.value.name)
          }}
        >
          {[ {'name': 'Price/Quintal', 'val': 'perquintal'}, {'name': 'Total Price', 'val': 'total'}].map((item, idx) => {
            return (<MenuItem value={item.val}>{item.name}</MenuItem>)
          })}
        </Select>

                    </InputAdornment>, shrink: true 
                  }}
                  value={values.pricePerQuintal == '' ? '' : priceMetric == 'total' ? values.price : values.pricePerQuintal}
                  variant="outlined"
                />
                
                </FormControl>
      </div> : <div/>}


      {props.jobProps.showTransporterPrice ? <div><FormControl style={{marginRight: 10}}> <TextField
                  error={Boolean(touched.phone && errors.phone)}
                  disabled={!transporterPriceEditable}
                  fullWidth
                  label="Transporter Price"
                  margin="normal"
                  name="transporterPrice"
                  onChange={(e) => {
                    if (false && values.trueWeight <= 0) {
                      alert('Weight cannot be zero or less.')
                    } else {

                      if (transporterPriceMetric == 'total') {
                        setFieldValue('transporterPrice', e.target.value)
                        if (values.trueWeight > 0) {
                          setFieldValue('transporterPricePerQuintal', mathRound(e.target.value / precisionRound(values.trueWeight * 10)))
                        }
                       
                      } else {
                        setFieldValue('transporterPrice', e.target.value * values.trueWeight * 10)
                        setFieldValue('transporterPricePerQuintal', e.target.value)
                      }


                    }
                    
                  }}
                  type="number"
                  InputProps={{
                    endAdornment: <InputAdornment position="start">

<Select
          fullWidth
          label="Cargo Type"
          margin="normal"
          id="cargoType"
          name="cargoType"
          disabled={!transporterPriceEditable}
          value={transporterPriceMetric}
          onChange={(e) => {
            setTransporterPriceMetric(e.target.value)
            //setFieldValue('cargoTypeDisp', e.target.value.name)
          }}
        >
          {[ {'name': 'Price/Quintal', 'val': 'perquintal'}, {'name': 'Total Price', 'val': 'total'}].map((item, idx) => {
            return (<MenuItem value={item.val}>{item.name}</MenuItem>)
          })}
        </Select>

                    </InputAdornment>, shrink: true 
                  }}
                  value={values.transporterPricePerQuintal == '' ? '' : transporterPriceMetric == 'total' ? values.transporterPrice : values.transporterPricePerQuintal}
                  variant="outlined"
                />
                
                </FormControl>
      </div> : <div/>}

      {props.createForShipper ? <div>
        <Box
        alignItems="center"
        display="flex"
        ml={-1}
      >
        <Checkbox
        id="notAsQuote"
          checked={values.notAsQuote}
          name="notAsQuote"
          onChange={handleChange}
        />
        <Typography
          color="textPrimary"
          variant="body1"
        >
          Automatically Make Quote Accepted
        </Typography>
        <Tooltip placement="right-end" title="If you check this box, the shipper will automatically accept the quote, without getting to see it. Use this if you have already agreed on a price. Otherwise, the shipper will need to confirm or reject the quote.">
                                <Help />
                            </Tooltip>
      </Box>
      <Box
        alignItems="center"
        display="flex"
        ml={-1}
      >
        <Checkbox
        id="sendText"
          checked={values.sendText}
          name="sendText"
          onChange={handleChange}
        />
        <Typography
          color="textPrimary"
          variant="body1"
        >
          Send Text Message To Shipper (Recommended)
        </Typography>
        <Tooltip  placement="right-end" title="If you check this box, the shipper will receive a text notifying them of the job creation, which includes the link for live status updates.">
                                <Help />
                            </Tooltip>
      </Box>
      </div>: null}

      {props.adminEdit ? 
      
      <div>
              <Box
        alignItems="center"
        display="flex"
        ml={-1}
      >
        <Checkbox
        id="countThisJob"
          checked={!values.countThisJob}
          name="countThisJob"
          onChange={(e) => {
            setFieldValue('countThisJob', !values.countThisJob)
          }}
        />
        <Typography
          color="textPrimary"
          variant="body1"
        >
          This is a Demo Job
        </Typography>
        <Tooltip  placement="right-end" title="If you check this box, the system will consider this a demo job and not include it in Admin reports on profit, revenue, jobs completed, etc.">
                                <Help />
                            </Tooltip>
      </Box>
      </div>
      : null}


      {props.ltcCreation ?
        <div>
        <Box
  alignItems="center"
  display="flex"
  ml={-1}
>
  <Checkbox
  id="workOrderJob"
    checked={values.workOrderJob}
    name="workOrderJob"
    onChange={(e) => {
      setFieldValue('workOrderJob', !values.workOrderJob)
    }}
  />
  <Typography
    color="textPrimary"
    variant="body1"
  >
    Work Order
  </Typography>
  <Tooltip  placement="right-end" title="Work orders can be created from this contract\'s total balance.">
                          <Help />
                      </Tooltip>
</Box>
</div> 
     : null }


                {getActionButton(props, isSubmitting, values, handleChange, touched, errors, setSubmitting, resetForm, validateForm)}
                
  </div>
                < div id='mapElementId' ref={mapHolder} style={{ height: 500, width: '50%', marginLeft: 10 }}>
                {(!props.ltcCreation && !props.ltcEdit && values.workOrderJob) ? 
<ContractDetails isFreightOrder={props.viewFreightOrder || props.isFreightOrderTemplate} startDate={values.contractStartDate} endDate={values.contractEndDate} used={values.contractUsed * 10} total={values.contractTotal * 10} remaining={(values.contractTotal - values.contractUsed) * 10}/> : null}
      <GoogleMapReact
          bootstrapURLKeys={{ key: 'AIzaSyAkPLP3zHG-2kctVakLZwdUQHqeLVO0Onw', libraries: 'places' }}
          defaultCenter={center}
          defaultZoom={zoom}
          center={center}
          zoom={zoom}
          onChange={(x) => setZoomUpdate(x.zoom)}
          yesIWantToUseGoogleMapApiInternals={true}
          onClick={(val) => {
            console.log(val)
            setMapSelection({lat: val.lat, lng: val.lng})
            console.log(mapSelection)
          }}
          onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
      >
        {values.pickupLocation != '' && values.dropoffLocation != '' ? <HoverMarker zoomSet={zoomUpdate} lat={startLat} lng={startLng} text={'Location'} idx={0} clickMarker={() => console.log('rmm')} /> : <div/>}
        {values.pickupLocation != '' && values.dropoffLocation != '' ? <HoverMarker zoomSet={zoomUpdate} lat={endLat} lng={endLng} text={'Location'} idx={1} clickMarker={() => console.log('rmm')} /> : <div/>}
        {mapSelection == null ? null : <LocalShippingIcon style={{marginTop: -1 * zoomUpdate, marginLeft: -1 * zoomUpdate}} lat={mapSelection.lat} lng={mapSelection.lng}/>}
        </GoogleMapReact>

      <Typography>{distance ? distance + ' km' : ''}</Typography>

      {props.isLimited == 1 ? 
      <div>
        <div>{showSendUpdate ? <SendUpdate valuesProp={values} quoteProp={props.parentState.customer.quoteId} mapSelection={mapSelection} onClose={() => setShowSendUpdate(false)} /> : <div><Button onClick={() => setShowSendUpdate(true)} color="primary" autoFocus>
                {'Send Status Update'}
              </Button>
              </div> }</div>
                <TextField select 
                style={{marginTop: 15, marginBottom: 10}}
                label="Driver Progress"
                variant="outlined"
                disabled={(!editable && props.isLimited != 1) || !values.assignedDriver || values.assignedDriver == '0' || values.assignedDriver.length < 10}
                value={driverProgress}
                onChange={(x) => {
                  setDriverProgress(x.target.value)
                  setDidChangeProgress(true)            
                }}
              >
                { [{'name': 'N/A', 'value': -1}, {'name': 'Driver Assigned', 'value': 6}, {'name': 'Driver en Route', 'value': 7},
      {'name': 'Driver Loaded Truck', 'value': 8}, {'name': 'Driver Delivered Load', 'value': 9}].map((item, idx) => {
                  return (<MenuItem value={item.value}>{item.name}</MenuItem>)
                })}
              </TextField>
              <Typography>{values.vehiclePlate ? 'Vehicle: ' + values.vehiclePlate : 'Vehicle Not Assigned'}</Typography>
              <Typography>{values.vehicleTrailerPlate ? 'Trailer Plate: ' + values.vehicleTrailerPlate : 'Trailer Plate Not Assigned'}</Typography>
              </div>
        : null}

                  {props.adminEdit && props.statusUpdates ? <div>{!props.isFreightOrderTemplate ? <div>{showSendUpdate ? <SendUpdate valuesProp={values} quoteProp={props.parentState.customer.quoteId} mapSelection={mapSelection} onClose={() => setShowSendUpdate(false)} /> : <div><Button onClick={() => setShowSendUpdate(true)} color="primary" autoFocus>
                {'Send Status Update'}
              </Button>
              </div> }</div> : null }
              <Box
        alignItems="center"
        display="flex"
        flexDirection="row"
        ml={-1}
      >
                            <TextField
                error={Boolean(touched.advancePayment && errors.advancePayment)}
                style={{marginRight: 30}}
                id="advancePayment"
                disabled={!editable}
                helperText={touched.advancePayment && errors.advancePayment}
                label="Advance Payment (ETB)"
                margin="normal"
                name="advancePayment"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.advancePayment}
                variant="outlined"
                type="number"
              />
                      <TextField select 
          style={{width: 150}}
          label="Advance Payment Method"
          margin="normal"
          id="paymentMethodInitial"
          name="paymentMethodInitial"
          disabled={!editable}
          value={values.paymentMethodInitial}
          onChange={handleChange}
        >
 {[  {'name': 'None', 'val': '0'}, {'name': 'Cash', 'val': 'cash'},  {'name': 'Check', 'val': 'check'}, {'name': 'Card', 'val': 'card'}, {'name': 'Mobile', 'val': 'mobile'}, {'name': 'Other', 'val': 'other'}].map((item, idx) => {
            return (<MenuItem value={item.val}>{item.name}</MenuItem>)
          })}
        </TextField>
        </Box>
                <TextField
                  error={Boolean(touched.finalWeight && errors.finalWeight)}
                  width={'71%'}
                  style={{marginRight: 30}}
                  helperText={touched.finalWeight && errors.finalWeight}
                  label={"Final Weight"}
                  margin="normal"
                  name="finalWeight"
                  disabled={!editable}
                  type="number"
                  InputProps={{
                    endAdornment: <InputAdornment position="start">

<Select
          fullWidth
          disabled={!editable}
          value={weightMetric}
          onChange={(e) => {
            setWeightMetric(e.target.value)
            //setFieldValue('cargoTypeDisp', e.target.value.name)
          }}
        >
          {[ {'name': 'Quintals', 'val': 'quintal'}, {'name': 'Tons', 'val': 'ton'}].map((item, idx) => {
            return (<MenuItem value={item.val}>{item.name}</MenuItem>)
          })}
        </Select>

                    </InputAdornment>,
                  }}
                  onBlur={handleBlur}
                  onChange={(e) => {
                    if (false && e.target.value <= 0) {
                      alert('Weight cannot be zero or less.')
                    } else {

                      let xweight = weightMetric == 'ton' ? e.target.value : e.target.value / 10
                      if (e.target.value == '') {
                        setFieldValue('finalWeight', '')
                      } else {
                        setFieldValue('finalWeight', xweight)
                      }

                      
                      
                    } 
                  }
                  }
                  value={values.finalWeight == '' ? '' : weightMetric == 'ton' ? values.finalWeight : precisionRound(values.finalWeight * 10)}
                  variant="outlined"
                />

<Box
        alignItems="center"
        display="flex"
        flexDirection="row"
        ml={-1}
      >
                                    <TextField
                error={Boolean(touched.checkNumber && errors.checkNumber)}
                style={{marginRight: 30}}
                id="checkNumber"
                disabled={!editable}
                helperText={touched.checkNumber && errors.checkNumber}
                label="Check Number"
                margin="normal"
                name="checkNumber"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.checkNumber}
                variant="outlined"
      
              />
        <TextField select 
          style={{width: 150}}
          label="Payment Method"
          margin="normal"
          id="paymentMethod"
          name="paymentMethod"
          disabled={!editable}
          value={values.paymentMethod}
          onChange={handleChange}
        >
 {[  {'name': 'None', 'val': '0'}, {'name': 'Cash', 'val': 'cash'},  {'name': 'Check', 'val': 'check'}, {'name': 'Card', 'val': 'card'}, {'name': 'Mobile', 'val': 'mobile'}, {'name': 'Other', 'val': 'other'}].map((item, idx) => {
            return (<MenuItem value={item.val}>{item.name}</MenuItem>)
          })}
        </TextField>
      </Box>
      <Box
        alignItems="center"
        display="flex"
        flexDirection="row"
        ml={-1}
      >
      <Checkbox
        id="jobIsPaid"
        disabled={!editable}
          checked={values.jobIsPaid}
          name="jobIsPaid"
          onChange={(e) => {
            setFieldValue('jobIsPaid', !values.jobIsPaid)
          }}
        />
        <Typography
          color="textPrimary"
          variant="body1"
        >
          Job Has Been Paid In Full
        </Typography>
        </Box>
      <Typography
          color="textPrimary"
          variant="body1"
        >
          {!values.jobIsPaid ? calculateRemainingPayment(values) : ''}
        </Typography>



        <div style={{flexDirection: 'column', display: 'flex'}}>
        <TextField select 
          style={{marginTop: 15, marginBottom: 10}}
          label="Driver Progress"
          variant="outlined"
          disabled={!editable || !values.assignedDriver || values.assignedDriver == '0' || values.assignedDriver.length < 10}
          value={driverProgress}
          onChange={(x) => {
            setDriverProgress(x.target.value)
            setDidChangeProgress(true)            
          }}
        >
          { [{'name': 'N/A', 'value': -1}, {'name': 'Driver Assigned', 'value': 6}, {'name': 'Driver en Route', 'value': 7},
{'name': 'Driver Loaded Truck', 'value': 8}, {'name': 'Driver Delivered Load', 'value': 9}].map((item, idx) => {
            return (<MenuItem value={item.value}>{item.name}</MenuItem>)
          })}
        </TextField>
        <TextField
       multiline
             error={Boolean(touched.shipperNotes && errors.shipperNotes)}
             style={{marginRight: 30}}
             id="shipperNotes"
             disabled={!editable}
             helperText={touched.shipperNotes && errors.shipperNotes}
             label={props.adminEdit ? 'Notes (Shipper)' : 'Notes'}
             margin="normal"
             name="shipperNotes"
             onBlur={handleBlur}
             onChange={handleChange}
             value={values.shipperNotes}
             variant="outlined"
           />
       {props.adminEdit ? 
       <TextField
       multiline
             error={Boolean(touched.adminNotes && errors.adminNotes)}
             style={{marginRight: 30}}
             id="adminNotes"
             disabled={!editable}
             helperText={touched.adminNotes && errors.adminNotes}
             label="Notes (Admin)"
             margin="normal"
             name="adminNotes"
             onBlur={handleBlur}
             onChange={handleChange}
             value={values.adminNotes}
             variant="outlined"
           />
       : null} 
       {props.adminEdit && props.context && props.context.adminJobs ? 
       
<Autocomplete
  id="combo-box-transporter"
  options={props.context.adminJobs}
  getOptionLabel={(option) =>renderOptionName(option)}
  value={values.roundTripLink}
  defaultValue={{'quoteId': -1, 'optionName': 'None'}} 
  onChange={(e, value) => {
    if (value != null) {
      setFieldValue('roundTripLink', value.quoteId)
    } else {
      setFieldValue('roundTripLink', -1)
    }


  }}
  renderInput={(params) => <TextField {...params}  fullWidth
  label="Rountrip Linked Job"
  margin="normal"
  id="roundTripLink"
  name="job"
  variant="outlined"
  value={values.roundTripLink}
  defaultValue={values.roundTripLink} />}
/>
      : null}
       {props.adminEdit && values.freightOrderId && !props.fromLTC && !props.isFreightOrderTemplate ? 
               <TextField select 
               fullWidth
               label="Freight Order"
               margin="normal"
               id="freightOrderId"
               name="freightOrderId"
               disabled={!editable || Firebase.IS_ASSOCIATION}
               value={values.freightOrderId}
               onChange={(e => {
                 setActiveFreightOrder(values, e.target.value, (k, v) => setFieldValue(k,v), props.adminFreightOrders, map, maps)
               })}
             >
               {props.adminFreightOrders.filter((item, idx) => {
                 return item.shipper == values.shipper
               }).map((item, idx) => {
                 return (<MenuItem value={item.freightOrderId}>{'(' + item.shortId + ')' + ' ' + item.pickupLocation + ' to ' + item.dropoffLocation}</MenuItem>)
               })}
             </TextField>
      : null}
        {!props.isLimited && values.assignedTransporter && values.assignedTransporter != '0' ?  
                          <Link
                          onClick={ () => {
                            navigateToUser(values.assignedTransporter)
                          }}
                          variant="h6"
                        >{'Transporter Phone: ' + getUserPhone(values.assignedTransporter)}</Link>
         : null}
          {!props.isLimited && values.assignedDriver && values.assignedDriver != '0' ?                            <Link
                          onClick={ () => {
                            navigateToUser(values.assignedDriver)
                          }}
                          variant="h6"
                        >{'Driver Phone: ' + getUserPhone(values.assignedDriver)}</Link> : null}
          </div>
         
              </div> : null}
              {props.editQuoteShipper ? <div>
{props.parentState && props.parentState.customer && props.parentState.customer.roundTripLink && props.parentState.customer.roundTripLink != -1 ?
<div style={{flexDirection: 'row', display: 'flex'}}>
<Button
                key="plzplei3j9fam"
          color="primary"
          onClick={() => window.open('https://shipper.garrilogistics.com/info/' + TableAssist.renderIdValue(props.parentState.customer))}
          fullWidth
          size="large" style={{margin: 5}}
          variant="contained"
        >
          {getLiveTrackText(props.parentState.customer, true) + props.parentState.customer.internalShipperId + '-' + TableAssist.padDigits(props.parentState.customer.shortIdShipper)}
        </Button>
        <Button
                key="plzplei3j9fam"
          color="primary"
          onClick={() => window.open('https://shipper.garrilogistics.com/info/' + props.parentState.customer.internalShipperId + '-' + TableAssist.padDigits(props.parentState.customer.shortIdShipperLINK))}
          fullWidth
          size="large"
          size="large" style={{margin: 5}}
          variant="contained"
        >
          {getLiveTrackText(props.parentState.customer,false) + props.parentState.customer.internalShipperId + '-' + TableAssist.padDigits(props.parentState.customer.shortIdShipperLINK)}
        </Button>
  </div>

: 
<Button
key="plzplei3j9fam"
color="primary"
onClick={() => window.open('https://shipper.garrilogistics.com/info/' + TableAssist.renderIdValue(props.parentState.customer))}
fullWidth
size="large"
variant="contained"
>
{props.parentState && props.parentState.customer && props.parentState.customer.state >= 5 ? 'Live Track' : 'Check Status'}
</Button>}


              </div> : null}
               

     {props.ltcCreation ? <div style={{marginTop: 5}}><TextField select 
          fullWidth
          label="Shipper"
          margin="normal"
          id="shipper"
          name="shipper"
          disabled={!editable || props.ltcEdit}
          value={values.shipper}
          onChange={handleChange}
        >
          {props.jobProps.shippers.map((item, idx) => {
            return (<MenuItem value={item.userId}>{item.companyName}</MenuItem>)
          })}
        </TextField>
        <TextField
                  error={Boolean(touched.contractName && errors.contractName)}
         
                  helperText={touched.contractName && errors.contractName}
                  label="Contract Name"
                  margin="normal"
                  name="contractName"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.contractName}
                  variant="outlined"
                />

{ values.contractDocumentList && values.contractDocumentList.length > 0 ? <div style={{flexDirection: 'column', display: 'flex'}}><InputLabel>Contract Documents</InputLabel>{
            values.contractDocumentList.map((item, idx) => {
              return <div style={{flexDirection: 'row'}}>{item.isPdf ? null : <img style={{width: 50, height: 50}} src={item.linkval ? item.linkval : item}/>}<Link
              to={item}
              onClick={item.linkval ? () => window.open(item.linkval) : () => window.open(item)}
              variant="h6"
            >{item.docname ? item.docname : 'Document ' + (idx + 1)}</Link>{props.adminEdit || props.isLimited == 1 ? <div><Edit  style={{marginBottom: -5}} onClick={(e) => {
              if (docDiaRefContract == null || docDiaRefContract.current == null) {
                return
              }
              setTargetDocumentIndexContract(idx)
            
              if (item.docname && item.docname != '') {
                docDiaRefContract.current.setState({
                  notificationBody: item.docname
                })
                //setDocumentPlaceholder(item.docname)
              } else {
                docDiaRefContract.current.setState({
                  notificationBody: ''
                })
                //setDocumentPlaceholder('')
              }
              setShowDocumentDialogContract(true)

            }}/><Cancel onClick={() => {
              let xxs = values.contractDocumentList.filter((iteminner, idxinner) => {
                return idxinner != idx 
              })
              setFieldValue('contractDocumentList', xxs)
            }}/></div> :  null}</div>
            })
            
          }

          </div> : <div>{<InputLabel>No Contract Documents Uploaded Yet</InputLabel>}
          

          </div>}

  <div>
        <input id="myInputFolder" ref={inputRefTwo} style={{ display: 'none' }} type="file" accept="image/x-png,image/gif,image/jpeg,application/pdf" webkitdirectory multiple onChange={(e) => handleSelectedFolderContract(e, values.contractDocumentList, (x, y) => setFieldValue(x, y))} />
    <div style={{display: 'flex', flexDirection: 'row'}}><AddBox onClick={(event) => inputRefTwo.current.click()}></AddBox>

    </div>
    {uploadsHappeningContract ? <div><CircularProgress/><a style={{marginLeft: 5}}>Uploading file(s), please wait...</a></div> : null}
    </div>
        </div> : null}


        {props.createForShipper ? <div style={{marginTop: 5}}><TextField select 
          fullWidth
          label="Shipper"
          margin="normal"
          id="shipper"
          name="shipper"
          disabled={!editable || props.ltcEdit}
          value={values.shipper}
          onChange={(e => {
            setFieldValue('shipper', e.target.value)
          })}
        >
          {props.jobProps.shippers.map((item, idx) => {
            return (<MenuItem value={item.userId}>{item.companyName}</MenuItem>)
          })}
        </TextField>
        <TextField select 
          fullWidth
          label="From LTC"
          margin="normal"
          id="ltcValue"
          name="ltcValue"
          disabled={!getContractsEditable(props.jobProps.contracts, values.shipper)}
          value={values.ltcValue}
          onChange={(e => {
            setActiveLtc(e.target.value, (k, v) => setFieldValue(k,v), props.jobProps.contracts, map, maps)
          })}
        >
          {props.jobProps.contracts.filter((item, idx) => {
            return item.shipper == values.shipper
          }).map((item, idx) => {
            return (<MenuItem value={item.contractId}>{item.contractName}</MenuItem>)
          })}
        </TextField>

        </div> : null}
        



       </div>
                </div>


                
                
        </Container>
 
      

      </form>
      )}
    </Formik>
    </Page>
  );
};

/*
         <TextField
                  error={Boolean(touched.dimension && errors.dimension)}
                  disabled={!editable}
                  width={'30%'}
                  style={{marginRight: 30}}
                  label="Dimension"
                  margin="normal"
                  name="dimension"
                  onChange={handleChange}
                  value={values.dimension}
                  variant="outlined"
                />
                <FormControl style={{marginTop: 17, marginBottom: 8}}>
                <FormControlLabel
                label={'Oversize?'}
                control={
                  <Checkbox
                  disabled={!editable}
                    checked={values.oversize}
                    name="oversize"
                    onChange={handleChange}
                  />}
                      /></FormControl>
*/

export default JobMasterView;