import React, {Component} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import MuiPhoneNumber from 'material-ui-phone-number'
import Firebase from 'src/Firebase'
import ChipInput from 'material-ui-chip-input'
import { DataGrid } from '@mui/x-data-grid';
import MyContext from 'src/MyContext'

class ApproveDialog extends Component {


    static defaultProps = {
        headerColor: 'black',
        bodyColor: 'black',
    };

    constructor(props) {
        super(props);

        let sfz = this.props.documentName
        this.state = {
            value: this.props.defaultValue,
            open: false,
            popRef: null,
            sortAscending: false,
            sortDescending: false,
            notificationType: '',
            notificationHeader: '',
            notificationBody: sfz,
            phoneNumber: '',
            listChips: [],
            source: null,
            approvalInfo: this.props.approveInfo,
            allocationValues: {},
            categoryDict: {},
            selection: []
        }

        this.topRef = null
    }

    componentWillReceiveProps(nextProps) {
      // You don't have to do this check first, but it can help prevent an unneeded render
      if (nextProps.approveInfo !== this.state.approvalInfo) {
        this.setState({ approvalInfo: nextProps.approveInfo });
      }
    }

    componentDidMount() {
        //alert('mounted')
    }

    tryConfirm() {
      if (this.state.selection.length < 1) {
        alert('You must select at least one source facility.')
        return 
      }
      var rejectForZero = false
      var rejectForSourceMatch = false
      this.state.selection.map((item, idx) => {
        if (!(item in this.state.allocationValues) || this.state.allocationValues[item] <= 0) {
          rejectForZero = true
        }
        if (item == this.state.approvalInfo.row.warehouse.name) {
          rejectForSourceMatch = true
        }
      })
      if (rejectForSourceMatch) {
        alert('Selected facility should not be the same as destination facility.')
        return
      }
      if (rejectForZero) {
        alert('Some stock should be allocated for each selected facility.')
        return 
      }

      var allocationSum = 0
      Object.keys(this.state.allocationValues).map((key, idx) => {
        allocationSum += this.state.allocationValues[key]
      })
  

      if (allocationSum > this.state.approvalInfo.row.netweightkg) {
        alert('Allocated stock should not exceed requested amount.')
        return
      }
      //if (allocationSum < this.state.approvalInfo.row.netweightkg) {
      //  alert('Allocate enough stock to fill the request.')
      //  return
      //}
      console.log(this.state.selection)
      //this.props.onConfirm(this.state)
      //this.props.onClose()
      let shortCodesDict = this.props.getShortCodes()
      let res = this.state.selection.map((item, idx) => {
        return {'source': item, 
        'netweightkg': this.state.allocationValues[item],
      'shortcode': item in shortCodesDict ? shortCodesDict[item] : null}
      })
      this.props.onConfirm(res)
      this.props.onClose()
    }


    handleAddChip(chip) {
      var chips = this.state.listChips
      this.setState({
          listChips: chips.concat(chip),
          chipAdded: true 
      })
  }

  handleDeleteChip(chip, idx) {
      let chips = this.state.listChips.filter((item, ii) => {
          return (ii != idx)
      })

      this.setState({
          listChips: chips
      })
  }

 getAutoOptions(context, k) {
    if (k in context.barleyInCustomObjects) {
      return Object.keys(context.barleyInCustomObjects[k]).map((key, id) => {
        return context.barleyInCustomObjects[k][key]
      })
    }
    return []
  }

  getDictId(dct) {
    var keys = Object.keys(dct)
    keys.sort()
    var s = ''
    for (var i = 0; i < keys.length; i++) {
      let key = keys[i]
      if (i > 0) {
        s += '-'
      }
      s += key 
      s += ':'
      s += dct[key]
    }

    return s 
  }

  getBarleyStockTitle(item) {
    var variety = ''
    var purity = ''
    var moisture = ''
    if (item.variety == 'other') {
      variety = 'All other varieties'
    } else {
      variety = item.variety 
    }
    if (item.uncategorized) {
      return 'Uncategorized'
    } else if (item.moisture == 'above') {
      return variety + ' // Variety all purity // Moisture > 15%'
    } else if (item.variety == 'other') {
      return variety + ' // Variety all purity // Moisture <= 15%'
    } else {
      if (item.purity == 'above') {
        purity = 'Variety purity >= 90%'
      } else if (item.purity == 'below') {
        purity = 'Variety purity < 90%'
      } 
      return variety + ' // ' + purity + ' // Moisture <=15%'
    }
  }

  onCellChange(value, approvalInfo) {
    console.log(value)
    console.log(this.state.categoryDict[value.id])

    var allocationSum = 0
    Object.keys(this.state.allocationValues).map((key, idx) => {
      allocationSum += this.state.allocationValues[key]
    })


    let oldVal = (value.id in this.state.allocationValues) ? this.state.allocationValues[value.id] : 0

    allocationSum += (value.value - oldVal)

    console.log('allocationsum is ' + allocationSum)

    if (!(value.id in this.state.categoryDict) || this.state.categoryDict[value.id] < value.value) {
      var s = this.state.allocationValues
      s[value.id] = 0
      this.setState({
        allocationValues: s
      })
      console.log('reset')
    } else if (allocationSum > approvalInfo.row.netweightkg) {
      var s = this.state.allocationValues
      s[value.id] = 0
      this.setState({
        allocationValues: s
      })
      console.log('reset ' + approvalInfo.row.netweightkg)
    } else {
      var s = this.state.allocationValues
      s[value.id] = value.value
      this.setState({
        allocationValues: s
      })
    }

  
  }

  compareDictionaries( d1, d2 ) {
    // quick check for the same object
    if( d1 === d2 )
        return true;

    // check for null
    if( d1 === null || d2 === null )
        return false;

    // go through the keys in d1 and check if they're in d2 - also keep a count
    var count = 0;
    for( var key in d1 )
    {
        // check if the key exists
        if( !( key in d2 ) )
            return false;

        // check that the values are the same
        if( d1[key] !== d2[key] )
            return false;

        count++;
    }

    // now just make sure d2 has the same number of keys
    var count2 = 0;
    for( key in d2 )
        count2++;

    // return if they're the same size
    return ( count === count2 );
}

  getColumns() {
    return [
      {
        field: 'facility',
        headerName: 'Facility',
        width: 150,
        editable: false,
      },
      {
        field: 'stock',
        headerName: 'Stock',
        width: 150,
        editable: false,
      },
      {
        field: 'allocation',
        headerName: 'Allocation',
        type: 'number',
        width: 110,
        editable: true,
      },
    ]
  }

  getAllByCategory(bDct, pDct, tDct, ourTarget, omit) {

    var categoryDict = {}

    console.log('our target is ' + ourTarget)

    Object.keys(bDct).map((warehouse, idx) => {
      let itemOuter = bDct[warehouse]
      if (warehouse == 'None' || warehouse == omit) {
        return
      }
      Object.keys(itemOuter).map((key, idxx) => {
        if (key != ourTarget + '-warehouse:' + warehouse) {
          return
        }
        console.log('allow ' + key)
        let targ = itemOuter[key]
        let total = 0
        total += targ.netweightkg ? targ.netweightkg : 0
        //available += targ.netweightkg ? targ.netweightkg : 0

        /*if (warehouse in pDct) {
          if (key in pDct[warehouse]) {
            let pendingItem = pDct[warehouse][key]
            if (pendingItem.netweightkg) {
              total += pendingItem.netweightkg ? pendingItem.netweightkg : 0
              //pending = pendingItem.netweightkg ? pendingItem.netweightkg : 0
            }
          }
        }*/
    
        categoryDict[warehouse] = total
      })
    })
    if (!this.compareDictionaries(categoryDict, this.state.categoryDict)) {
      this.setState({
        categoryDict: categoryDict
      })
    }

    return categoryDict
  }

  getRows(context, row, allocationValues) {
    console.log(row)
    if (row == null || row.sku == null || row.sku.name == null) {
      return [] 
    }
    let query = {'sku': row.sku.name}
    let dest = ''
    console.log(row)
    
    if (row.warehouse && row.warehouse.name) {
      dest = row.warehouse.name 
    } else {
      return []
    }
    console.log(query)
    let cdt = this.getAllByCategory(context.barleyStocksDict, context.barleyStocksPendingDict,
      context.barleyStocksTransitDict, this.getDictId(query), dest)
    
    return Object.keys(cdt).map((key, idx) => {
      let item = cdt[key]
      return {'stock': item, 'facility': key, 'allocation': key in allocationValues ? allocationValues[key] : 0, id: key}
    })

  }

  getDictId(dct) {
    var keys = Object.keys(dct)
    keys.sort()
    var s = ''
    for (var i = 0; i < keys.length; i++) {
      let key = keys[i]
      if (i > 0) {
        s += '-'
      }
      s += key 
      s += ':'
      s += dct[key]
    }

    return s 
  }

  getQty(approvalInfo, allocationValues) {
    if (approvalInfo && approvalInfo.row) {
      var allocationSum = 0
      Object.keys(allocationValues).map((key, idx) => {
        allocationSum += allocationValues[key]
      })

      return 'Remaining to Allocate: ' + (approvalInfo.row.netweightkg - allocationSum)
    } else {
      return ''
    }

  }

    render() {
        return (
          <MyContext.Consumer>{context => (
            <Dialog
            open={this.props.open}
            onClose={() => this.props.onClose()}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{this.props.alertTitle}</DialogTitle>
            <DialogContent>
              <Typography>
              {this.state.approvalInfo && this.state.approvalInfo.row && this.state.approvalInfo.row.warehouse ?
                'Destination: ' + this.state.approvalInfo.row.warehouse.name
              : null}
                </Typography>
              <Typography>
                {this.state.approvalInfo && this.state.approvalInfo.row ?
                'Request For: ' + this.state.approvalInfo.row.netweightkg + 'kg'
              : null}
                </Typography>
                <Typography>
                  {this.getQty(this.state.approvalInfo, this.state.allocationValues)}
                  </Typography>
              <Typography>
                {this.state.approvalInfo && this.state.approvalInfo.row &&
                this.state.approvalInfo.row.sku ? this.state.approvalInfo.row.sku.name : null}
              </Typography>
            <div style={{height: 400, width: 600}}>
            <DataGrid
            style={{backgroundColor: 'white'}}
        rows={this.getRows(context, this.state.approvalInfo && this.state.approvalInfo.row ? this.state.approvalInfo.row : null, this.state.allocationValues)}
        columns={this.getColumns()}
        pageSize={5}
        rowsPerPageOptions={[5]}
        onCellEditCommit={(p, e, d) => this.onCellChange(p, this.state.approvalInfo)}
        hideFooterPagination={false}
        hideFooter={false}
        checkboxSelection={true}
        disableSelectionOnClick
        onSelectionModelChange={(newSelection) => {
          this.setState({selection: newSelection})
          }}
      />
      </div>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => this.props.onClose()} color="primary">
                {this.props.alertCancelText}
              </Button>
              <Button disabled={this.state.selection.length < 1} onClick={() => this.tryConfirm(this.state.source)} color="primary" autoFocus>
                {this.props.alertConfirmText}
              </Button>
            </DialogActions>
          </Dialog>
                )}</MyContext.Consumer>
        )

    }
}
const headerStyle = {
    backgroundColor: 'rgb(67,67,67)', flexWrap: 'never', whiteSpace: 'noWrap'
}

/*   <div onClick={() => this.adjustSortDescending()} style={{ margin: 0, display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: this.state.sortDescending ? 'orange' : 'white', border: this.state.sortDescending ? '' : '1px solid black', borderRadius: 30, padding: 0, height: 30, padding: 5 }}>
                                <p>Descending</p>
                            </div>*/

/*
 * 
 * 
 *             */

export default ApproveDialog;