import React, {useState} from 'react';
import { Link as RouterLink, useNavigate, useLocation} from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import Autocomplete from '@material-ui/lab/Autocomplete';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import {
  Box,
  Button,
  Checkbox,
  Container,
  FormHelperText,
  Link,
  TextField,
  Typography,
  makeStyles,
  Grid,
  Select,
  MenuItem,
  CircularProgress
} from '@material-ui/core';
import Page from 'src/components/Page';
import parse from 'autosuggest-highlight/parse';
import throttle from 'lodash/throttle';
import Firebase from 'src/Firebase'
import DataFetcher from 'src/DataFetcher';


const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/


const autocompleteService = { current: null };
const geocoder = {current: null};



const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  }
}));

const didLoad = {current: false}


const EditVehicleGPSWox = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const {state} = useLocation();

  const [value, setValue] = React.useState(null);
  const [valueTwo, setValueTwo] = React.useState(null);
  const [inputValue, setInputValue] = React.useState('');
  const [options, setOptions] = React.useState([]);
  const [optionsTwo, setOptionsTwo] = React.useState([]);
  const [numVal, setNumVal] = React.useState(false);
  const [actions, setActions] = React.useState(null);
  const [locationStart, setLocationStart] = React.useState(null);
  const [locationEnd, setLocationEnd] = React.useState(null);
  const [finalSet, setFinalSet] = React.useState(null);
  const [loadedGPSWox, setLoadedGPSWox] = React.useState(false);
  const [authenticating, setAuthenticating] = React.useState(true);
  const [newGpsWox, setNewGpsWox] = React.useState(state.gpsWoxId);
  const [gpsWox, setGPSWox] = React.useState([{'id': -1, 'name': 'Unassigned'}])
  const [tableItems, setTableItems] = React.useState(DataFetcher.transportersList)

  const loaded = React.useRef(false);

 function updateGPSWox() {
     var dataPack = {}
     dataPack['vehicleId'] = state.vehicleId
     dataPack['gpsWoxId'] = newGpsWox
     if (state.gpsWoxId && state.gpsWoxId != -1) {
         dataPack['oldGpsWoxId'] = state.gpsWoxId
         dataPack['replace'] = true 
     }

     var gpn = ''
     gpsWox.map((item, idx) => {
         if (item.id == newGpsWox) {
             gpn = item.name
         }
     })
     dataPack['gpsWoxName'] = gpn

     let s = (x) => {
        if (x.data == 1) {
            alert('Another vehicle is assigned to this GPS Wox ID. Please edit that vehicle and remove it from this ID before giving the ID to a new vehicle.')
        } else if (x.data == 200) {
            navigate(-2)
            alert('Successfully updated GPSWox assignment for this vehicle!')
        }
     }

     let f = (x) => {
         alert('Could not update GPS Wox ID.')
     }

     Firebase.editVehicleGPSWox(dataPack, s, f)
}


  React.useEffect(() => {


        let s = (x) => {
            console.log(JSON.stringify(x))
            console.log(loadedGPSWox)
            setGPSWox(gpsWox.concat(x.data))
            setLoadedGPSWox(true)
            didLoad.current = true 
            setAuthenticating(false)
        }
        let f = (x) => {
            console.log(loadedGPSWox)
            setLoadedGPSWox(true)
            didLoad.current = true 
            alert('Error fetching data from GPSWox')
        }

        Firebase.getAllGpsWoxDevices(s, f)


  }, [])

  if (authenticating) {
    return (<Page
        className={classes.root}
        height='100%'
        title="Request Quote"
        id="ff2"
        backgroundColor={classes.root.backgroundColor}
      >
    <div style={{
          display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: 'rgb(244,246,248)', height: '100vh'
      }}>  <Box mb={3}>
      <Typography
        color="textPrimary"
        variant="h2"
      >
          Syncing Data From GPSWox...
      </Typography>
    </Box>
<CircularProgress /></div>
      </Page>)
  }
  


  return (<Page
    className={classes.root}
    height='100%'
    title="Request Quote"
    id="ff2"
    backgroundColor={classes.root.backgroundColor}
  >
    <Box
      display="flex"
      flexDirection="column"
      height="90vh"
      id="bb2"
      justifyContent="center"
      backgroundColor={classes.root.backgroundColor}

    >
       <Container id="cc2" maxWidth="sm" backgroundColor={classes.root.backgroundColor}>
       <Box mb={3}>
                <Typography
                  color="textPrimary"
                  variant="h2"
                >
                    GPS Wox Link For Vehicle
                </Typography>
              </Box>
              <Select
          fullWidth
          label="GPS Wox Vehicle"
          margin="normal"
          id="assignedTransporter"
          name="assignedTransporter"
          variant="outlined"
          value={newGpsWox}
          onChange={(e) => {
            setNewGpsWox(e.target.value)
            console.log(e.target.value)
          }
          }
        >
          {gpsWox.map((item, idx) => {
            return (<MenuItem value={item.id}>{item.name}</MenuItem>)
          })}
        </Select>
        <div style={{marginTop: 10}}>
        <Button
                  color="primary"
                  disabled={state.gpsWoxId == newGpsWox}
                  fullWidth
                  size="large"
                  id="sendButtonNext"
                  variant="contained"
                  onClick={() => updateGPSWox()}
                >
                  Update GPSWox Id
                </Button>
                </div>
      </Container>
    </Box>
  </Page>)
};

export default EditVehicleGPSWox;
