import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useNavigate} from 'react-router-dom';
import { Doughnut } from 'react-chartjs-2';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Typography,
  colors,
  makeStyles,
  useTheme,
  Button
} from '@material-ui/core';
import LaptopMacIcon from '@material-ui/icons/LaptopMac';
import PhoneIcon from '@material-ui/icons/Phone';
import TabletIcon from '@material-ui/icons/Tablet';
import MyContext from 'src/MyContext';
import Edit from '@material-ui/icons/Edit';
import EditStockDialog from './EditStockDialog';

const colorBase = [colors.indigo[500],
colors.red[600],
colors.orange[600], colors.blue[600], colors.green[600], colors.deepPurple[600], colors.pink[600], colors.lightBlue[600],
colors.lightGreen[600], colors.grey[600], colors.amber[600] ]


const useStyles = makeStyles(() => ({
  root: {
    height: '100%'
  }
}));

const DoughnutBarley = ({ className, stockItem, nameProp, targetKey, name, sourceProp, isTrain, ...rest }) => {
  const classes = useStyles();
  const theme = useTheme();
  const navigate = useNavigate();
  const [maxLoc, setMaxLoc] = React.useState('');
  const [maxName, setMaxName] = React.useState('');
  const [maxColor, setMaxColor] = React.useState('black');
  const [editStockDialog, setEditStockDialog] = React.useState(false)

  var chartVar = {}

  function numberWithCommas(xx, isTrain, trainName, context) {

    if (isTrain) {
      var targetTrain = null 
      context.trainList.map((item, idx) => {
        if (item.name == trainName) {
          targetTrain = item 
        }
      })
      if (context && targetTrain && targetTrain.sentAmounts && Object.keys(targetTrain.sentAmounts).length > 0 && targetTrain.allocations && Object.keys(targetTrain.allocations).length > 0) {
        var sentSum = 0
        var allocSum = 0
        Object.keys(targetTrain.sentAmounts).map((key, idx) => {
          sentSum += targetTrain.sentAmounts[key]
        })
        Object.keys(targetTrain.allocations).map((key, idx) => {
          allocSum += targetTrain.allocations[key]
        })

        sentSum = getTons(sentSum)
        var pct = ((sentSum / allocSum) * 100).toFixed(2)
        sentSum = sentSum.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        allocSum = allocSum.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");



        return  '(' + pct + '%) ' + sentSum + ' / ' + allocSum
      }
       
    }

    let x = isTrain && isTrain.trainName ? -1 * xx : xx
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
  

  const options = {
    animation: false,
    cutoutPercentage: 80,
    layout: { padding: 0 },
    legend: {
      display: false
    },
    maintainAspectRatio: false,
    responsive: true,
    tooltips: {
      backgroundColor: theme.palette.background.default,
      bodyFontColor: theme.palette.text.secondary,
      borderColor: theme.palette.divider,
      borderWidth: 1,
      enabled: true,
      footerFontColor: theme.palette.text.secondary,
      intersect: false,
      mode: 'index',
      titleFontColor: theme.palette.text.primary
    }
  };

  const devices = [
    {
      title: 'Desktop',
      value: 63,
      icon: LaptopMacIcon,
      color: colors.indigo[500]
    },
    {
      title: 'Tablet',
      value: 15,
      icon: TabletIcon,
      color: colors.red[600]
    },
    {
      title: 'Mobile',
      value: 23,
      icon: PhoneIcon,
      color: colors.orange[600]
    }
  ];

  const data = {
    datasets: [
      {
        data: [63, 15, 22],
        backgroundColor: [
          colors.indigo[500],
          colors.red[600],
          colors.orange[600]
          
        ],
        borderWidth: 8,
        borderColor: colors.common.white,
        hoverBorderColor: colors.common.white
      }
    ],
    labels: ['Desktop', 'Tablet', 'Mobile']
  };

  function mathRound(a, b) {
    return Math.round((a/b) * 100)
  }

  function getDoughnutData(lst) {

    var pickColor = 0
    var objectDict = {}
    lst.map((item, idx) => {
      if (item[targetKey]) {
        if (item[targetKey] in objectDict) {
          objectDict[item[targetKey]] += 1
        } else {
          objectDict[item[targetKey]] = 1
        }
      }
      
    })

    var dataArr = []
    var colorArr = []
    var labelArr = []
    
    var maxval = 0
    var maxname = ''
    var maxcolor = ''

    Object.keys(objectDict).map((item, idx) => {
      dataArr.push(objectDict[item])
      if (pickColor >= colorBase.length) {
        pickColor = 0
      }
      if (objectDict[item] > maxval) {
        maxval = objectDict[item]
        maxname = item 
        maxcolor = colorBase[pickColor]
      }
      colorArr.push(colorBase[pickColor])
      pickColor += 1
      labelArr.push(item)
      
    })

    setMaxLoc(maxval)
    setMaxName(maxname)
    setMaxColor(maxcolor)
    return { 
      datasets: [{data: dataArr,
      backgroundColor: colorArr,
      borderWidth: 8,
      borderColor: colors.common.white,
      hoverBorderColor: colors.common.white}], labels: labelArr}
    
  }

  function getTons(n) {
    if (isNaN(n) || !n || n == 0) {
      return 0
    }
    return (n / 1000).toFixed(2)
  }

  function genDoughnutData(stockItem) {
    return { 
      datasets: [{data: [getTons(stockItem.available), getTons(stockItem.pending), getTons(stockItem.transit)],
      backgroundColor: [colorBase[0], colorBase[1], colorBase[2]],
      borderWidth: 8,
      borderColor: colors.common.white,
      hoverBorderColor: colors.common.white}], labels: ['Available', 'Pending', 'In Transit']}
  }

  function genTrainData(stockItem, trainName, context) {
    var targetTrain = null 
    context.trainList.map((item, idx) => {
      if (item.name == trainName) {
        targetTrain = item 
      }
    })
    if (context && context.barleyInCustomObjects && context.barleyInCustomObjects['warehouse'] && targetTrain && targetTrain.sentAmounts && Object.keys(targetTrain.sentAmounts).length > 0) {
      var whd = context.barleyInCustomObjects['warehouse']
      var datArr = []
      var colrArr = []
      var labelArr = []
      console.log(whd)
      console.log(context.barleyInCustomObjects)
      console.log(context.barleyInCustomObjects['warehouse'])
      Object.keys(targetTrain.sentAmounts).map((key, idx) => {
        datArr.push(getTons(targetTrain.sentAmounts[key]))
        colrArr.push(colorBase[idx % 8])
        console.log(whd)
        console.log(key)
        console.log(whd[key])
        labelArr.push(whd[key] ? whd[key].name + ' / ' + whd[key].union : 'Unknown')
      })
      return {
        datasets: [{data: datArr,
          backgroundColor: colrArr,
          borderWidth: 8,
          borderColor: colors.common.white,
          hoverBorderColor: colors.common.white}], labels: labelArr}
    } else {
      return { 
        datasets: [{data: [getTons(stockItem.available), getTons(stockItem.pending), getTons(stockItem.transit)],
        backgroundColor: [colorBase[0], colorBase[1], colorBase[2]],
        borderWidth: 8,
        borderColor: colors.common.white,
        hoverBorderColor: colors.common.white}], labels: ['Available', 'Pending', 'In Transit']}
    }
    
  }

  function navTrain(trainName, context) {

    var targetTrain = null 
    context.trainList.map((item, idx) => {
      if (item.name == trainName) {
        targetTrain = item 
      }
    })
    console.log(stockItem)
    console.log(trainName)
    console.log(context.trainList)
    if (targetTrain) {
      navigate('/app/viewplan', {state: {fields: context.trainFields, stateVal: targetTrain, stateId: targetTrain.customId}})
    }
    
  }

  return (
    <MyContext.Consumer>{context => (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
       <EditStockDialog
        open={editStockDialog}
        onClose={() => setEditStockDialog(false)}
        alertTitle={'Edit Stock'}
        alertCancelText={'Cancel'}
        alertConfirmText={'Confirm'}
        nameProp={nameProp}
        valProp={stockItem}
        sourceProp={sourceProp ? sourceProp.name : null}
        onConfirm={(x) => alert('x')}
        />
      <CardHeader title={isTrain && isTrain.trainName ? isTrain.trainName : stockItem.warehouse + ' (' + nameProp  + ')'} />
      <Divider />
      <CardContent>
        <div style={{width: 200}}>
      
          </div>
     
        <Box
          height={300}
          position="relative"
        >
          <Doughnut
            data={isTrain && isTrain.trainBase ? genTrainData(stockItem, isTrain.trainBase, context): genDoughnutData(stockItem)}
            options={options}
          />
        </Box>
        <Box
          display="flex"
          justifyContent="center"
          mt={2}
        >
      
              <Box

              p={1}
              textAlign="center"
            >
              <LaptopMacIcon color="action" />
              <Typography
                color="textPrimary"
                variant="body1"
              >
                {isTrain ? 'Total Dispatched / Planned:' : 'Total Stock:'}
              </Typography>
              {context.warehouseRole == 'Admin' && sourceProp && sourceProp.name != 'All Facilities' ? 
              <div style={{flexDirection: 'row'}}>
                              <Typography
                style={{ color: maxColor }}
                variant={isTrain ? 'h3' : 'h2'}
              >
                {isNaN(stockItem.total) ? '0 Tons' : numberWithCommas(getTons(stockItem.total), isTrain, isTrain.trainBase, context) + ' Tons'}
              </Typography>

              <Edit  style={{marginBottom: -5}} onClick={(e) => setEditStockDialog(true)}/>

              </div>
              :
              <Typography
              style={{ color: maxColor }}
              variant={isTrain ? 'h3' : 'h2'}
            >
              {isNaN(stockItem.total) ? '0 Tons' : numberWithCommas(getTons(stockItem.total),  isTrain, isTrain.trainBase, context) + ' Tons'}
            </Typography>
              }
              {isTrain && isTrain.trainBase ?
              <Button
              color="primary"
              size="large"
              id="sendButtonNext"
              variant="contained"
              onClick={() => navTrain(isTrain.trainBase, context)}
              style={{margin: 5, width: 150}}
            >
              View Train
            </Button>
            : null}

            </Box>
            
            
         
        </Box>
      </CardContent>
    </Card>)}</MyContext.Consumer>
  );
};

DoughnutBarley.propTypes = {
  className: PropTypes.string
};

export default DoughnutBarley;
