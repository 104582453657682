import React from 'react';
import {useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Button,
  Card,
  CardContent,
  TextField,
  InputAdornment,
  SvgIcon,
  makeStyles
} from '@material-ui/core';
import { Search as SearchIcon } from 'react-feather';
import MyContext from 'src/MyContext'
import moment from 'moment'
import Firebase from 'src/Firebase'

const useStyles = makeStyles((theme) => ({
  root: {},
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  }
}));



const Toolbar = ({ className, ...rest }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const inputRef = React.useRef(null);


  function newBarley() {

    Firebase.firestore.collection("barleyIn").add({
      name: "Tokyo",
      country: "Japan",
      num: 42,
      date: Date.now()
  })
  .then((docRef) => {
      console.log("Document written with ID: ", docRef.id);
  })
  .catch((error) => {
      console.error("Error adding document: ", error);
  });
  }

  return (
    <MyContext.Consumer>{context => (<div
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Box
        display="flex"
        justifyContent="flex-end"
      >
      </Box>
     
    </div>)}</MyContext.Consumer>
  );
};

Toolbar.propTypes = {
  className: PropTypes.string
};

export default Toolbar;
