import React, {Component} from 'react'
import Firebase from './Firebase';
import MyContext from './MyContext';
import ContextDataLoader from './ContextDataLoader';
import {debounce} from 'lodash'


const WAIT_THRESH = 5;
const sleep = (milliseconds) => {
    return new Promise(resolve => setTimeout(resolve, milliseconds))
  }

export default class ContextProvider extends Component {

    constructor(props) {
        super(props)
        this.state = {
            innerPlansDict: {},
            planList: [],
            planDict: {},
            trainList: [],
            trainDict: {},
            arrivalList: [],
            arrivalDict: {},
            operationList: [],
            operationDict: {},
            planFields: [],
            trainFields: [],
            arrivalFields: [],
            operationFields: [],
            barleyStocksDict: {},
            barleyStocksPendingDict: {},
            barleyStocksTransitDict: {},
            barleyInCustomObjects: {},
            barleyInCustomObjectFields: {},
            transferBarleyInList: [],
            transferBarlerInDict: {},
            transferBarleyOutList: [],
            transferBarleyOutDict: {},
            barleyInFields: [],
            barleyOutFields: [],
            transferBarleyInFields: [],
            transferBarleyOutFields: [],
            barleyOutList: [],
            barleyOutDict: {},
            barleyInList: [],
            barleyInDict: {},
            adminJobsList: [],
            adminJobsDict: {},
            usersList: [],
            usersDict: {},
            vehiclesList: [],
            vehiclesDict: {},
            contractsList: [],
            contractsDict: {},
            webNotifications: {},
            contractsAdminList: [],
            contractsAdminDict: {},
            associationsAdminList: [],
            associationsAdminDict: {},
            freightOrdersList: [],
            freightOrdersDict: {},
            freightOrdersListAdmin: [],
            freightOrdersDictAdmin: {},
            freightOrdersListShipper: [],
            freightOrdersDictShipper: {},
            invoicesList: [],
            invoicesDict: {},
            paymentsList: [],
            paymentsDict: {},
            invoicesListShipper: [],
            invoicesDictShipper: {},
            transporterMappingDict: {},
            report_errors: false,
            userInfo: {},
            localityMap: {},
            onlineStatusDict: {},
            isLimited: 1,
            netConnected: false,
            wentOffline: null,
            warehouseRole: null,
            targetWarehouse: null,
            warehouseUserInfo: {},
            targetWarehouseSet: {}
        }

        this.listeners = {}
        this.didInit = false
        this.admin = false
        this.assoc = false
        this.lastFire = new Date()
        this.parentReference = null 
        this.loadingInterval = null 
        this.testStr = 'www'
        this.numCalls = 0
        this.dataloader = null 

        this.updateState = debounce((s) => this.updateStateWithDebounce(s), 2000)
        
    }

    checkIntervalFunc(self) {
        console.log(self.testStr)
        let curDat = new Date()
        let dif = curDat - self.lastFire
        console.log(curDat)
        console.log(self.lastFire)
        if ((dif) > 250) {
            if (self.parentReference != null) {
                console.log(' did load in time ' + dif)
                self.parentReference()
            }
            clearInterval(self.loadingInterval)
        } else {
            console.log('did not load in time because time was ' + dif )
        }
    }

    componentDidMount() {
        if (Firebase.requiresContextInit) {
            this.didInit()
        }
    }

    componentWillUnmount() {
        if (this.didInit) {
            this.reset()
            this.didInit = false 
        }
    }

    updateStateWithDebounce(s) {
        this.setState(s)
        if (this.parentReference) {
            this.parentReference()
        }
    }

    async limitedAccessFunction() {
        if (this.dataloader && this.state.limited) {
            await this.dataloader.getLimitedAccessData()
        } else {
            //alert('not limtied')
        }
    }

    async fetchAll(targetKey, listTarget, dictTarget, idTarget) {
        if (this.dataloader) {
            this.dataloader.fetchAll(targetKey, listTarget, dictTarget, idTarget)
        }
    }

    init(parent) {

        if (this.didInit) {
            return
        }
        this.parentReference = parent 
        this.dataloader = new ContextDataLoader(this.admin, this.assoc)
        this.dataloader.init(this)
        return 
        this.parentReference = parent 
        let self = this
        this.didInit = true 
        this.lastFire = new Date()
        this.loadingInterval = setInterval(function() {
            console.log(self.testStr)
            let curDat = new Date()
            let dif = curDat - self.lastFire
            console.log(curDat)
            console.log(self.lastFire)
            if ((dif) > 250) {
                if (self.parentReference != null) {
                    console.log(' did load in time ' + dif)
                    self.parentReference()
                }
                clearInterval(self.loadingInterval)
            } else {
                console.log('did not load in time because time was ' + dif )
            }
        }, 250)
        Firebase.database.ref('users/' + Firebase.uid).on('value', (val) => {
            if (val.exists()) {
                self.setState({
                    userInfo: val.val()
                })
            } else {
                self.setState({
                    userInfo: {}
                })
            }
        })
        if (this.admin) {
            this.loadListDualRef('shipperRequests', 'shipperRequests', 'adminJobsList', 'adminJobsDict', 'quoteId')
            this.loadListDualRef('users', 'users', 'usersList', 'usersDict', 'userId')
            this.loadListDualRef('vehicles', 'vehicles', 'vehiclesList', 'vehiclesDict', 'vehicleId')
            this.loadListDualRef('longTermContracts', 'longTermContracts', 'contractsAdminList', 'contractsAdminDict', 'contractId')
       

            Firebase.database.ref('webNotifications/admin').on('value',  (val) => {
                if (val.exists()) {
                    self.setState({
                        webNotifications: val.val()
                    })
                    //alert(val.val())
                } else {
                    self.setState({
                        webNotifications: {}
                    })
                }
            }, function (error) {

            })
        } else {
            
            this.loadListDualRef('shipperRequestsByUid/' + Firebase.uid, 'shipperRequests', 'adminJobsList', 'adminJobsDict', 'quoteId')
            this.loadListDualRef('contractsByUser/' + Firebase.uid, 'longTermContracts', 'contractsList', 'contractsDict', 'contractId')
        
            Firebase.database.ref('webNotifications/' + Firebase.uid).on('value',  (val) => {
                if (val.exists()) {
                    self.setState({
                        webNotifications: val.val()
                    })
                } else {
                    self.setState({
                        webNotifications: {}
                    })
                }
            }, function (error) {

            })
        }
    }

    reset() {
        this.didInit = false 
        this.parentReference = null 
        this.setState({
            isLimited: 1
        })
        if (this.dataloader != null) {
            this.dataloader.reset()
        }
        return 
        Object.keys(this.listeners).map((item, idx) => {
            this.listeners[item].off('value')
        })

        if (this.admin) {
            ['shipperRequests', 'users', 'vehicles', 'longTermContracts'].map((item, idx) => {
                Firebase.database.ref(item).off('child_added')
                Firebase.database.ref(item).off('child_removed')
            })
            //Firebase.database.ref('shipperRequests').off('child_added')
            //Firebase.database.ref('shipperRequests').off('child_removed')
            Firebase.database.ref('webNotifications/admin').off('value')
        } else {
            Firebase.database.ref('shipperRequestsByUid/' + Firebase.uid).off('child_added')
            Firebase.database.ref('shipperRequestsByUid/' + Firebase.uid).off('child_removed')
            Firebase.database.ref('contractsByUser/' + Firebase.uid).off('child_added')
            Firebase.database.ref('contractsByUser/' + Firebase.uid).off('child_removed')
            Firebase.database.ref('webNotifications/' + Firebase.uid).off('value')
        }
        Firebase.database.ref('users/' + Firebase.uid).off('value')
    }



    async functionalStateListAdd( v, listTarget, dictTarget, idTarget) {


        //console.log('try doing a functional update outer for ' + v[idTarget] )
        this.setState(function (prevState, props) {
        var temp = prevState[listTarget]
        var temp2 = prevState[dictTarget]
        temp.push(v)
        temp2[v[idTarget]] = 1
        var retvar = {}
        retvar[listTarget] = temp 
        retvar[dictTarget] = temp2 

        return retvar//{ tableItems: temp, existingDict: temp2 }
    })
}

async functionalStateListUpdate( v, listTarget, idTarget) {

        //console.log('try doing a functional update outer for ' + key )
        this.setState(function (prevState, props) {
        var temp = prevState[listTarget].map((item, idx) => {
        if (item[idTarget] != v[idTarget]) {
        return item
        }
        return v
        })

        var retvar = {}
        retvar[listTarget] = temp 
        return retvar//{ tableItems: temp }
        })
}

async loadSingleItemByKey(secondary_target, secondary_key, listTarget, dictTarget, idTarget) {
        this.numCalls += 1
        console.log(this.numCalls)
        if (this.numCalls > WAIT_THRESH) {
            await sleep(Math.floor(Math.random() * 200))
            
        }
        console.log('done sleeping')
        this.lastFire = new Date()
        let self = this
        //console.log(secondary_target + '/' + secondary_key)
        this.listeners[secondary_target + '/' + secondary_key] = Firebase.database.ref(secondary_target + '/' + secondary_key)
        //console.log('here is shipperRequests/' + secondary_key)
        this.listeners[secondary_target + '/' + secondary_key].on("value", async (inner_snapshot) => {
        if (inner_snapshot.exists()) {
            //alert('do exist')
        if (inner_snapshot.val()[idTarget] in self.state[dictTarget]) {
          //alert('this is an update')
        self.functionalStateListUpdate(inner_snapshot.val(), listTarget, idTarget)
        } else {
          //alert('this is an add')
            //console.log('performing add for ' + inner_snapshot.val()[idTarget])
        self.functionalStateListAdd(inner_snapshot.val(), listTarget, dictTarget, idTarget)
        }

        } else {
            //alert('noexist ' + secondary_target + '/' + secondary_key)
        //console.log('container found to not exist for ' + secondary_key)
        }
        }, function (error) {
        console.log('error fetching container for ' + secondary_key)
        console.log(error)
        })
}

loadListDualRef(firebase_primary_target, firebase_secondary_target, listTarget, dictTarget, idTarget) {
        // Maintain reference to main component for state updates
        let self = this
        console.log(firebase_primary_target)
        console.log(firebase_secondary_target)
        if (listTarget == 'transporterAssignedList') {
            //alert(firebase_primary_target)
        }
        let addlist = Firebase.database.ref(firebase_primary_target).on("child_added", async (inner_snapshot, prevkey) => {
           
        // Get key of secondary item
        if (listTarget == 'transporterAssignedList') {
            //alert(inner_snapshot.val())
        }
        if (inner_snapshot.exists()) {
        let secondary_key = inner_snapshot.key
        self.loadSingleItemByKey(firebase_secondary_target, secondary_key, listTarget, dictTarget, idTarget)
        } else {

        }
        //self.loadSingleItemByKey(firebase_secondary_target, secondary_key)

        }, function (errorObject) {
        if (self.state.report_errors) {
        alert(errorObject)
        }
        console.log("The read failed: " + errorObject.code);
        });

        Firebase.database.ref(firebase_primary_target).on("child_removed", (snapshot) => {
        let secondary_key = snapshot.key

        Firebase.database.ref(firebase_secondary_target + '/' + secondary_key).off("value")
        //alert(JSON.stringify(self.state[listTarget]))
        console.log('try to do remove fsilter operaationn for ' + firebase_secondary_target + '/' + secondary_key)

        var nextList = self.state[listTarget].filter((item, idx) => {
            console.log('filter comparison of ' + item[idTarget] + ' to ' + secondary_key)
            return (item[idTarget] != secondary_key)
        })

        var existingDict = self.state[dictTarget]
        delete existingDict[secondary_key]
        var retNext = {}
        retNext[listTarget] = nextList 
        retNext[dictTarget] = existingDict
        self.setState(retNext)

        }, function (errorObject) {
        if (self.state.report_errors) {
        alert(errorObject)
        }
        console.log("The read failed: " + errorObject.code);
        });
}

render() {

    if (this.admin && !this.assoc) {
        return (    <MyContext.Provider value={{
            adminJobs: this.state.adminJobsList,
            jobsStateZero: this.state.adminJobsList.filter((item, idx) => {return item.state == 0}),
            jobsStateOne: this.state.adminJobsList.filter((item, idx) => {return item.state == 1}),
            jobsStateTwo: this.state.adminJobsList.filter((item, idx) => {return item.state == 2}),
            jobsStateThree: this.state.adminJobsList.filter((item, idx) => {return item.state == 3}),
            jobsStateFour: this.state.adminJobsList.filter((item, idx) => {return item.state == 4}),
            jobsStateFive: this.state.adminJobsList.filter((item, idx) => {return item.state == 5}),
            jobsStateSix: this.state.adminJobsList.filter((item, idx) => {return item.state == 6}),
            jobsStateSeven: this.state.adminJobsList.filter((item, idx) => {return item.state == 7}),
            jobsStateEight: this.state.adminJobsList.filter((item, idx) => {return item.state == 8}),
            jobsStateNine: this.state.adminJobsList.filter((item, idx) => {return item.state == 9}),
            freightOrders: this.state.freightOrdersListAdmin,
            invoices: this.state.invoicesList,
            payments: this.state.paymentsList,
            users: this.state.usersList,
            usersNameDict: this.state.usersDict,
            jobsIdDict: this.state.adminJobsDict,
            vehicles: this.state.vehiclesList,
            associations: this.state.associationsAdminList,
            webNotifications: this.state.webNotifications,
            isAdmin: true,
            adminContracts: this.state.contractsAdminList,
            userInfo: this.state.userInfo,
            isLimited: this.state.isLimited,
            localityMap: this.state.localityMap,
            limitedAccessFunction: () => this.limitedAccessFunction()
        }}>
            {this.props.children}
        </MyContext.Provider>)
    } else if (this.assoc) {
        return (    <MyContext.Provider value={{
            adminJobs: this.state.adminJobsList,
            jobsStateZero: this.state.adminJobsList.filter((item, idx) => {return item.state == 0}),
            jobsStateOne: this.state.adminJobsList.filter((item, idx) => {return item.state == 1}),
            jobsStateTwo: this.state.adminJobsList.filter((item, idx) => {return item.state == 2}),
            jobsStateThree: this.state.adminJobsList.filter((item, idx) => {return item.state == 3}),
            jobsStateFour: this.state.adminJobsList.filter((item, idx) => {return item.state == 4}),
            jobsStateFive: this.state.adminJobsList.filter((item, idx) => {return item.state == 5}),
            jobsStateSix: this.state.adminJobsList.filter((item, idx) => {return item.state == 6}),
            jobsStateSeven: this.state.adminJobsList.filter((item, idx) => {return item.state == 7}),
            jobsStateEight: this.state.adminJobsList.filter((item, idx) => {return item.state == 8}),
            jobsStateNine: this.state.adminJobsList.filter((item, idx) => {return item.state == 9}),
            users: this.state.usersList,
            vehicles: this.state.vehiclesList,
            webNotifications: this.state.webNotifications,
            isAdmin: true,
            adminContracts: this.state.contractsAdminList,
            userInfo: this.state.userInfo,
            limitedAccessFunction: () => this.limitedAccessFunction()
        }}>
            {this.props.children}
        </MyContext.Provider>)

    } else {
        return (    <MyContext.Provider value={{
            adminJobs: this.state.adminJobsList,
            jobsStateQuote: this.state.adminJobsList.filter((item, idx) => {return item.state == 0}),
            jobsStateOffer: this.state.adminJobsList.filter((item, idx) => {return item.state == 1 || item.state == -1}),
            jobsStateScheduled: this.state.adminJobsList.filter((item, idx) => {return item.state > 1 && item.state < 7}),
            jobsStateRoute: this.state.adminJobsList.filter((item, idx) => {return item.state > 6 && item.state < 9}),
            jobsStateCompleted: this.state.adminJobsList.filter((item, idx) => {return item.state >= 9}),
            webNotifications: this.state.webNotifications,
            freightOrders: this.state.freightOrdersListShipper,
            invoices: this.state.invoicesListShipper,
            contracts: this.state.contractsList,
            isAdmin: false,
            userInfo: this.state.userInfo,
            planFields: this.state.planFields,
            arrivalFields: this.state.arrivalFields,
            operationFields: this.state.operationFields,
            trainFields: this.state.trainFields,
            barleyInFields: this.state.barleyInFields,
            barleyOutFields: this.state.barleyOutFields,
            transferBarleyOutFields: this.state.transferBarleyOutFields,
            transferBarleyInFields: this.state.transferBarleyInFields,
            planList: this.state.planList, 
            planDict: this.state.planDict,
            operationList: this.state.operationList, 
            operationDict: this.state.operationDict,
            trainList: this.state.trainList, 
            arrivalList: this.state.arrivalList,
            barleyInList: this.state.barleyInList,
            barleyOutList: this.state.barleyOutList,
            transferBarleyInList: this.state.transferBarleyInList,
            transferBarleyOutList: this.state.transferBarleyOutList,
            barleyInCustomObjects: this.state.barleyInCustomObjects,
            barleyStocksDict: this.state.barleyStocksDict,
            barleyStocksPendingDict: this.state.barleyStocksPendingDict,
            barleyStocksTransitDict: this.state.barleyStocksTransitDict,
            barleyInCustomObjectFields: this.state.barleyInCustomObjectFields,
            netConnected: this.state.netConnected,
            wentOffline: this.state.wentOffline,
            warehouseRole: this.state.warehouseRole,
            targetWarehouse: this.state.targetWarehouse,
            targetWarehouseSet: this.state.targetWarehouseSet,
            onlineStatusDict: this.state.onlineStatusDict,
            warehouseUserInfo: this.state.warehouseUserInfo,
            innerPlansDict: this.state.innerPlansDict,
            transporterMappingDict: this.state.transporterMappingDict,
            fetchAll: (x,y,z, a) => this.fetchAll(x,y,z, a),
            limitedAccessFunction: () => this.limitedAccessFunction(),
            
        }}>
            {this.props.children}
        </MyContext.Provider>)
    }

   

}

}