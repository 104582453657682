import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  makeStyles,
  Checkbox,
  Typography, 
  Tooltip
} from '@material-ui/core';
import Firebase from 'src/Firebase'
import Help from '@material-ui/icons/Help';
import * as XLSX from 'xlsx';
import moment from 'moment';
import { formControlClasses } from '@mui/material';

const states = [
  {
    value: 'alabama',
    label: 'Alabama'
  },
  {
    value: 'new-york',
    label: 'New York'
  },
  {
    value: 'san-francisco',
    label: 'San Francisco'
  }
];

const useStyles = makeStyles(() => ({
  root: {}
}));

const parseDictDj = {"TruckPlateNo": 'platenumber',
"TrailerPlateNo": 'trailerplatenumber',
"Weight1": 'numberofbags',
"Weight2": 'netweightkg',
"WayBill": "import_seq_wb",
"Transporter ": 'transportservice',
"ADate": 'dispatchdate',
"Driver Name": 'drivername',
"VesselName": 'vn_for_map',
"Destination": 'dst_for_map',
'WAGEN NO': 'wag_or_wh',
"Transporter": 'transportservice',
'DName': 'prepared'


}

/*const parseDict = {"Plate ": 'platenumber',
"Trailer ": 'trailerplatenumber',
"Bags": 'numberofbags',
"Waybill No": "import_seq_wb",
"Transporter ": 'transportservice',
"Dispached Date": 'dispatchdate',
"Driver Name": 'drivername',
"Vessel Name": 'vn_for_map',
"Destination    ": 'dst_for_map',
'ER NO': 'wag_or_wh',
"Transporter": 'transportservice',


}*/

const parseDict = {"Plate": 'platenumber',
"Trailer": 'trailerplatenumber',
"Bags": 'numberofbags',
"W/Bill No": "import_seq_wb",
"Transporter ": 'transportservice',
"Dispached date": 'dispatchdate',
'Dispatched Date': 'dispatchdate',
"Driver Name": 'drivername',
"vessel Name": 'vn_for_map',
'vessel Name': 'vn_for_map',
'Vessel Name': 'vn_for_map',
'Dispached Date': 'dispatchdate',
"Destination": 'dst_for_map',
'ER No': 'wag_or_wh',
"Transporter": 'transportservice',
"Cargo Type": 'cargo_for_map'


}

const parseDictIndode = {"Plate ": 'platenumber',
"Trailer ": 'trailerplatenumber',
"BAGS": 'numberofbags',
"Waybill": "import_seq_wb",
"Transporter ": 'transportservice',
"didpached date": 'dispatchdate',
"Driver Name": 'drivername',
"vessel Name": 'vn_for_map',
"Destination    ": 'dst_for_map',
'Ramark': 'wag_or_wh',
"Transporter": 'transportservice',


}

const ProfileDetails = ({ className, userInfo, warehouseUserInfo, context, ...rest }) => {
  const classes = useStyles();

  const [values, setValues] = useState(warehouseUserInfo);
  const [loading, setLoading] = useState(false)
  const inputRef = React.useRef()



  function getDictId(dct) {
    var keys = Object.keys(dct)
    keys.sort()
    var s = ''
    for (var i = 0; i < keys.length; i++) {
      let key = keys[i]
      if (i > 0) {
        s += '-'
      }
      s += key 
      s += ':'
      s += dct[key]
    }

    return s 
  }


  async function doSubmitTrain(v, context) {

    let typeKey = 'TR'
    var transaction = null

    if (Firebase.ONLINE) {
      var newVal = v 
      setLoading(true)
      let operation = newVal['operation']
      let train = newVal['train']
      let source = newVal['source']
      let sku = newVal['sku']
      let destination = newVal['warehouse']
      let amount = newVal['netweightkg']


      //newVal['dispatchdate'] = newVal.dispatchdate ? moment(newVal.dispatchdate).valueOf() : Date.now()
      newVal['operation'] = operation
      newVal['train'] = train
      newVal['sku'] = sku 
      newVal['warehouse'] = destination 
      newVal['source'] = train


      var newSet = {'sku': sku.name}
      newSet['destination'] = destination.name 
      var oldSet = {'sku': sku.name}
      oldSet['train'] = train.name 

      // SCRATCH@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
      if (newVal['purity'] === '') {
        delete newVal['purity']
      }
      if (newVal['moisture'] === '') {
        delete newVal['moisture']
      }

      let stockKey = getDictId(newSet)
      let oldStockKey = getDictId(oldSet)
      console.log(oldStockKey)
      console.log(stockKey)

      
      let yearCode = moment(Date.now()).format('YY')

    // transfer in number
    let docRef1 = Firebase.firestore.collection('projects').doc(Firebase.userProject).collection('barleyConstants').doc(yearCode + '-dispatchNumber');

      // reference of old barley transfer out request, which should be updated to be in completed state 
      //var docRefOld = Firebase.firestore.collection('projects').doc(Firebase.userProject).collection('transferBarleyOut').doc(transferOut.id)
      // reference of new barley transfer in request, which will be created now
      var docRef = Firebase.firestore.collection('projects').doc(Firebase.userProject).collection("arrivals").doc()
    

      var docRefTrain = Firebase.firestore.collection('projects').doc(Firebase.userProject).collection("trains").doc(train.trainId)

      // Reference of stock in the train to decrease
      let stockDocRefPending = Firebase.firestore.collection('projects').doc(Firebase.userProject).collection('barleyStocks').doc(oldStockKey)
      // Reference of stock in new warehouse (this will be increased)
      let stockDocRef = Firebase.firestore.collection('projects').doc(Firebase.userProject).collection('barleyStocks').doc(stockKey)
      

      
      var newValId = docRef.id 

      var newQueryResult = null 
      var oldQueryResult = null 
      var oldNumericSave = null;
      var oldTransferValue = null; 
      var oldNumericValue = null;
      var newNumericValue = null;

      var trainVal = null;

      var innerPlanRef = null 
      var innerPlanVal = null
      if (newVal['planId'] != null && newVal['innerPlanId'] != null) {
        innerPlanRef = Firebase.firestore.collection('projects').doc(Firebase.userProject).collection('plansInner').doc(newVal['planId']).collection('contents').doc(newVal['innerPlanId'])
      }
      console.log('inne rplan ref')
      console.log(innerPlanRef)
      console.log(newVal)
 
  

      var customCreations = {}

      let arrayOfMemberIds = Object.keys(customCreations)

      let bd = context.barleyInCustomObjects['warehouse']
      var mayHaveShortCode = null
      if (train.warehouse && train.warehouse.customId && train.warehouse.customId in bd) {
        mayHaveShortCode = bd[train.warehouse.customId]
      }

      let shortCode = mayHaveShortCode && mayHaveShortCode['shortcode'] ? mayHaveShortCode['shortcode'] : null
      var updCode = {}
     transaction = await Firebase.firestore.runTransaction(async t => {

      return t.get(docRef1)
      .then(async doc => {

        if (shortCode) {
          if (shortCode in doc.data()) {
            newNumericValue = doc.data()[shortCode] + 1;  //You calculate the new value
            
            let shortCodeValue = shortCode + '-' + typeKey + Firebase.padDigits(doc.data()[shortCode]) + '-' + yearCode
            oldNumericValue = shortCodeValue
            oldNumericSave = shortCodeValue
            updCode[shortCode] = newNumericValue
            //return t.update(docRef1 , updCode);
          } else {
            newNumericValue = 2;  //You calculate the new value
            let shortCodeValue = shortCode + '-' + typeKey + Firebase.padDigits(1) + '-' + yearCode
            oldNumericValue = shortCodeValue
            oldNumericSave = shortCodeValue
            updCode[shortCode] = newNumericValue
           // return t.update(docRef1 , updCode);
          }
          
        } else {
          newNumericValue = doc.data().value + 1;  //You calculate the new value
          oldNumericValue = typeKey + Firebase.padDigits(doc.data().value) + '-' + yearCode
          oldNumericSave = oldNumericValue
          updCode['value'] = newNumericValue
          //return t.update(docRef1 , {value : newNumericValue});
        }
        console.log(shortCode)
        console.log(doc.data())
        console.log(newNumericValue)
        console.log(oldNumericSave)
        console.log(oldNumericSave)

        if (innerPlanRef) {
          let innerPlanRes = await t.get(innerPlanRef)
          if (innerPlanRes.exists) {
            innerPlanVal = innerPlanRes.data()
          }
        }

        let trainRes = await t.get(docRefTrain)
        if (trainRes.exists) {
          trainVal = trainRes.data()
        } else {
          const errM = {'msg': "Train does not exist."}
          throw errM
        }


        return t.get(stockDocRef )
      }).then(querySnapshotNew => {
          // Get new stock ref for the in transit barley to dest
          if (querySnapshotNew.exists) {
            newQueryResult = querySnapshotNew.data()
  
          } else {
            newQueryResult = null 
          }
  
         /* return t.get(docRefOld)
          
        })
        .then(querySnapshotTransfer => {
          // Get new stock ref for the in transit barley to dest
          if (querySnapshotTransfer.exists) {
            oldTransferValue = querySnapshotTransfer.data()
  
          } else {
            const errM = {'msg': "Transfer out request does not exist."}
            throw errM
          }*/
  
          return t.get(stockDocRefPending)
          
        }).then(querySnapshotOld => {
          // Get old stock ref for the pending barley to remove from source
          if (querySnapshotOld.exists) {
            oldQueryResult = querySnapshotOld.data()
          } else {
              const errM = {'msg': "Pending stock does not exist."}
              console.log('SKIPPING ERROR')
              console.log(errM)
              //throw errM
          }
            // update custom IDs if needed
            arrayOfMemberIds.forEach(function(memberId) {
                let docRefNext = Firebase.firestore.collection('projects').doc(Firebase.userProject).collection('barleyInCustomObjects').doc('contents').collection('projects').doc(Firebase.userProject).collection(memberId).doc()
                console.log('here t first')
                console.log(t)
                t = t.set(
                docRefNext,
                { name: customCreations[memberId] }
                )
                console.log('setting id of ' + docRefNext.id)
                newVal[memberId] = {'customId': docRefNext.id, 'name': customCreations[memberId]}
                
            });
            return t
            }).then(t => {



            // Update inner plan if needed
            if (innerPlanVal) {
              let current = innerPlanVal.receivedqty ? innerPlanVal.receivedqty : 0
              var updateValInnerPlan = {'receivedqty': current + parseInt(amount)}
              t.update(innerPlanRef, updateValInnerPlan)
            }



              // make the new transfer out item 
            newVal['dispatchNumber'] = oldNumericValue
            console.log(newVal)


            var newAllocTrain = trainVal.sentAmounts ? trainVal.sentAmounts : {}
            let whid = destination.customId 
            let newAmt = newAllocTrain[whid] ? newAllocTrain[whid] : 0
            let finAmt = newVal['netweightkg'] + newAmt
            newAllocTrain[whid] = finAmt 
            t.update(docRefTrain, {'sentAmounts': newAllocTrain})
            //newVal['transferOutNumber'] = transferOut['transferOutNumber']
            return t.set(docRef ,newVal);
            })/*.then(t => {
              // make the new transfer out item 
            if (oldTransferValue.status == 'Completed') {
              const errM = {'msg': "This transfer has already been completed."}
              throw errM
            }
            return t.update(docRefOld ,{'status': 'Completed'});
            })*/.then(t => {
              return t.update(docRef1 , updCode);
            }).then(t => {
            // Update the pending stock

            var updateValOld = {}
            if (!newVal['finalweightkg']) {
              const errM = {'msg': "No barley being transferred."}
              throw errM
            }
            if (!oldQueryResult || !oldQueryResult['netweightkg'] || oldQueryResult['netweightkg'] < newVal['finalweightkg']) {
              console.log('completely skipping error for old result barley oldqueryresult etc train')
              
              //const errM = {'msg': "Not enough barley in transit. Amount in transit is " + oldQueryResult['netweightkg'] + "kg, while trying to transfer " + newVal['finalweightkg'] + "kg."}
              //throw errM
            }
            updateValOld['netweightkg'] = (oldQueryResult && oldQueryResult['netweightkg'] ? oldQueryResult['netweightkg'] : 0) - newVal['netweightkg']
            if (oldQueryResult) {
              return t.update(stockDocRefPending ,updateValOld);
            } else {
              return t.set(stockDocRefPending ,updateValOld);
            }

  
          })
      }).then(result => {
        
        console.log('Transaction success!' + result);
        return {'transaction': true, 'newVal': newVal, 'oldNumericSave': oldNumericSave, 'newValId': newValId}
      }).catch(err => {
        console.log('Transaction failure:', err);
        return {'transaction': null, 'failMessage': err}
      });

      Firebase.trackMixpanel('createDispatchTrainOnline', {'result': transaction})








      // SCRATCH@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@



    } else {
      setLoading(true)
      alert('no offline implementation yet')

    }
    console.log('here transact')
    console.log(transaction)
  
    if (transaction && transaction.transaction != null) {
      newVal = transaction.newVal 
      var oldNumericSave = transaction.oldNumericSave
      var newValId = transaction.newValId
  
      let s = async () => {
          var datPack = {}
          const imageRef = Firebase.storage.ref('test').child('pdf_for_wab_' + oldNumericSave + '.pdf')
          let v = await imageRef.getDownloadURL();
          console.log(v)
          console.log('got it')
          datPack['pdflink'] = v 
          datPack['dispatchId'] = newValId
          let snone = () => console.log('x')
          Firebase.provideWaybillLink(datPack, snone, snone)
          //window.open(v)
  
      } 
  
      let f = () => {
       
      }
      let s2 = async () => {
        var datPack = {}
        const imageRef = Firebase.storage.ref('test').child('pdf_for_whout_' + oldNumericSave + '.pdf')
        let v = await imageRef.getDownloadURL();
        console.log(v)
        console.log('got it')
        datPack['pdflink'] = v 
        datPack['dispatchId'] = newValId
        let snone = () => console.log('x')
        Firebase.provideWarehouseOutLink(datPack, snone, snone)
        //window.open(v)

    } 

    let f2 = () => {
     
    }
    var datxx = {}
    datxx['val'] = newVal
    datxx['userId'] = 'test'
    
    //Firebase.generateWarehouseOut(datxx, s2, f2)
      
      Firebase.generateWaybill(datxx, s, f)
  
  }

  return transaction
  setLoading(false)
  if (transaction && transaction.transaction == null && transaction.failMessage) {
    alert(transaction.failMessage.msg)
  } else {
    //navigate(-1)
    //navigate('/app/creationsummary', {state: {headerText: 'Dispatch: ' + transaction.oldNumericSave, secondaryText: 'Successfully created report.' }, replace: true})
  }
  }


  async function doSubmitVessel(v, context) {
    /*if (sku == null || amount == null || amount <= 0 || operation == null || destination == null) {
      alert('Please select a corresponding transfer out request.')
      return
    }*/
    let typeKey = 'VE'
    var transaction = null

    if (Firebase.ONLINE) {

      setLoading(true)


      var newVal = v 


      let operation = newVal['operation']
      let train = newVal['train']
      let source = newVal['source']
      let sku = newVal['sku']
      let destination = newVal['warehouse']

      let amount = newVal['netweightkg']
    
      newVal['train'] = train 


      newVal['fromVessel'] = true 
      newVal['dispatchdate'] = newVal.dispatchdate ? moment(newVal.dispatchdate).valueOf() : Date.now()
      newVal['operation'] = operation
      newVal['train'] = operation
      newVal['sku'] = sku 
      newVal['isFinal'] = true
      newVal['warehouse'] = destination 
      newVal['source'] = operation
      //newVal['wagons'] = wagons
      //newVal['laborers'] = laborers
      //newVal['prepared'] = context.warehouseUserInfo ? context.warehouseUserInfo.name : ''
      //newVal['creationDate'] = Date.now()
      //newVal['transporteractual'] = newVal['transportservice']
      //newVal['transportservice'] = newVal['transportservice'] ? newVal['transportservice']['name'] : ''
      //newVal['driveractual'] = newVal['drivername']
      newVal['drivername'] = newVal['drivername'] ? newVal['drivername']['name'] : ''
      //newVal['vehicleactual'] = newVal['platenumber']
      //newVal['platenumber'] = newVal['platenumber'] ? newVal['platenumber']['plate'] : ''
      var newSet = {'sku': sku.name}
      newSet['destination'] = destination.name 
      var oldSet = {'sku': sku.name}
      oldSet['vessel'] = operation.name 

      // SCRATCH@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
      if (newVal['purity'] === '') {
        delete newVal['purity']
      }
      if (newVal['moisture'] === '') {
        delete newVal['moisture']
      }

      let stockKey = getDictId(newSet)
      let oldStockKey = getDictId(oldSet)
      console.log(oldStockKey)
      console.log(stockKey)
      newVal['finalweightkg'] = parseInt(amount) 
      newVal['netweightkg'] = parseInt(amount)
      
      let yearCode = moment(Date.now()).format('YY')

    // transfer in number
    let docRef1 = Firebase.firestore.collection('projects').doc(Firebase.userProject).collection('barleyConstants').doc(yearCode + '-dispatchNumber');

      // reference of old barley transfer out request, which should be updated to be in completed state 
      //var docRefOld = Firebase.firestore.collection('projects').doc(Firebase.userProject).collection('transferBarleyOut').doc(transferOut.id)
      // reference of new barley transfer in request, which will be created now
      var docRef = Firebase.firestore.collection('projects').doc(Firebase.userProject).collection("arrivals").doc()
    

      var docRefTrain = Firebase.firestore.collection('projects').doc(Firebase.userProject).collection("operations").doc(operation.operationId)

      // Reference of stock in the train to decrease
      let stockDocRefPending = Firebase.firestore.collection('projects').doc(Firebase.userProject).collection('barleyStocks').doc(oldStockKey)
      // Reference of stock in new warehouse (this will be increased)
      let stockDocRef = Firebase.firestore.collection('projects').doc(Firebase.userProject).collection('barleyStocks').doc(stockKey)
      

      
      var newValId = docRef.id 

      var newQueryResult = null 
      var oldQueryResult = null 
      var oldNumericSave = null;
      var oldTransferValue = null; 
      var oldNumericValue = null;
      var newNumericValue = null;

      var trainVal = null;

      var innerPlanRef = null 
      var innerPlanVal = null
      if (newVal['planId'] != null && newVal['innerPlanId'] != null) {
        innerPlanRef = Firebase.firestore.collection('projects').doc(Firebase.userProject).collection('plansInner').doc(newVal['planId']).collection('contents').doc(newVal['innerPlanId'])
      }
      console.log('inne rplan ref')
      console.log(innerPlanRef)
      console.log(newVal)
 
  

      var customCreations = {}
      /*state.fields.map((field, idx) => {
      if (field.fieldType === 'custom') {
          if (field.fieldKey in newVal && newVal[field.fieldKey]['customId'] === -1) {
          // must create this val 
          customCreations[field.fieldKey] =  newVal[field.fieldKey]['name']
          }
      }
      })*/

      let arrayOfMemberIds = Object.keys(customCreations)

      let bd = context.barleyInCustomObjects['warehouse']
      var mayHaveShortCode = null
      if (operation.warehouse && operation.warehouse.customId && operation.warehouse.customId in bd) {
        mayHaveShortCode = bd[operation.warehouse.customId]
      }

      var userCode = context.warehouseUserInfo && context.warehouseUserInfo.idwaybill ? context.warehouseUserInfo.idwaybill : '00'

      userCode = newVal.hardcode_usercode_wb ? newVal.hardcode_usercode_wb : userCode 

      let shortCode = mayHaveShortCode && mayHaveShortCode['shortcode'] ? mayHaveShortCode['shortcode'] : null
      var updCode = {}
     transaction = await Firebase.firestore.runTransaction(async t => {

      return t.get(docRef1)
      .then(async doc => {

        if (shortCode) {
          if (shortCode in doc.data()) {
            newNumericValue = newVal.import_seq_wb + 1;  //You calculate the new value
            
            let shortCodeValue = shortCode + '3760' + Firebase.padDigits(newVal.import_seq_wb) + '-' + userCode + '-' + yearCode
            oldNumericValue = shortCodeValue
            oldNumericSave = shortCodeValue
            updCode[shortCode] = newNumericValue
            //return t.update(docRef1 , updCode);
          } else {
            newNumericValue = 2;  //You calculate the new value
            let shortCodeValue = shortCode + '3760' +  Firebase.padDigits(1) + '-' + userCode + '-' + yearCode
            oldNumericValue = shortCodeValue
            oldNumericSave = shortCodeValue
            updCode[shortCode] = newNumericValue
           // return t.update(docRef1 , updCode);
          }
          
        } else {
          newNumericValue = doc.data().value + 1;  //You calculate the new value
          oldNumericValue =  '3760' + Firebase.padDigits(doc.data().value) + '-' + userCode + '-' + yearCode
          oldNumericSave = oldNumericValue
          updCode['value'] = newNumericValue
          //return t.update(docRef1 , {value : newNumericValue});
        }
        console.log(shortCode)
        console.log(doc.data())
        console.log(newNumericValue)
        console.log(oldNumericSave)
        console.log(oldNumericSave)

        if (innerPlanRef) {
          let innerPlanRes = await t.get(innerPlanRef)
          if (innerPlanRes.exists) {
            innerPlanVal = innerPlanRes.data()
          }
        }

        let trainRes = await t.get(docRefTrain)
        if (trainRes.exists) {
          trainVal = trainRes.data()
        } else {
          const errM = {'msg': "Train does not exist."}
          throw errM
        }


        return t.get(stockDocRef )
      }).then(querySnapshotNew => {
          // Get new stock ref for the in transit barley to dest
          if (querySnapshotNew.exists) {
            newQueryResult = querySnapshotNew.data()
  
          } else {
            newQueryResult = null 
          }
  
         /* return t.get(docRefOld)
          
        })
        .then(querySnapshotTransfer => {
          // Get new stock ref for the in transit barley to dest
          if (querySnapshotTransfer.exists) {
            oldTransferValue = querySnapshotTransfer.data()
  
          } else {
            const errM = {'msg': "Transfer out request does not exist."}
            throw errM
          }*/
  
          return t.get(stockDocRefPending)
          
        }).then(querySnapshotOld => {
          // Get old stock ref for the pending barley to remove from source
          if (querySnapshotOld.exists) {
            oldQueryResult = querySnapshotOld.data()
          } else {
              const errM = {'msg': "Pending stock does not exist."}
              console.log('SKIPPING ERROR')
              console.log(errM)
              //throw errM
          }
            // update custom IDs if needed
            arrayOfMemberIds.forEach(function(memberId) {
                let docRefNext = Firebase.firestore.collection('projects').doc(Firebase.userProject).collection('barleyInCustomObjects').doc('contents').collection('projects').doc(Firebase.userProject).collection(memberId).doc()
                console.log('here t first')
                console.log(t)
                t = t.set(
                docRefNext,
                { name: customCreations[memberId] }
                )
                console.log('setting id of ' + docRefNext.id)
                newVal[memberId] = {'customId': docRefNext.id, 'name': customCreations[memberId]}
                
            });
            return t
            }).then(t => {



            // Update inner plan if needed
            if (innerPlanVal) {
              let current = innerPlanVal.receivedqty ? innerPlanVal.receivedqty : 0
              var updateValInnerPlan = {'receivedqty': current + parseInt(amount)}
              t.update(innerPlanRef, updateValInnerPlan)
            }



              // make the new transfer out item 
            newVal['dispatchNumber'] = oldNumericValue
            console.log(newVal)


            var newAllocTrain = trainVal.sentAmounts ? trainVal.sentAmounts : {}
            let whid = destination.customId 
            let newAmt = newAllocTrain[whid] ? newAllocTrain[whid] : 0
            let finAmt = newVal['netweightkg'] + newAmt
            newAllocTrain[whid] = finAmt 
            t.update(docRefTrain, {'sentAmounts': newAllocTrain})
            //newVal['transferOutNumber'] = transferOut['transferOutNumber']
            return t.set(docRef ,newVal);
            })/*.then(t => {
              // make the new transfer out item 
            if (oldTransferValue.status == 'Completed') {
              const errM = {'msg': "This transfer has already been completed."}
              throw errM
            }
            return t.update(docRefOld ,{'status': 'Completed'});
            })*/.then(t => {
              return t.update(docRef1 , updCode);
            }).then(t => {
            // Update the pending stock

            var updateValOld = {}
            if (!newVal['finalweightkg']) {
              const errM = {'msg': "No barley being transferred."}
              throw errM
            }
            if (!oldQueryResult || !oldQueryResult['netweightkg'] || oldQueryResult['netweightkg'] < newVal['finalweightkg']) {
              console.log('completely skipping error for old result barley oldqueryresult etc train')
              
              //const errM = {'msg': "Not enough barley in transit. Amount in transit is " + oldQueryResult['netweightkg'] + "kg, while trying to transfer " + newVal['finalweightkg'] + "kg."}
              //throw errM
            }
            updateValOld['netweightkg'] = (oldQueryResult && oldQueryResult['netweightkg'] ? oldQueryResult['netweightkg'] : 0) - newVal['netweightkg']
            if (oldQueryResult) {
              return t.update(stockDocRefPending ,updateValOld);
            } else {
              return t.set(stockDocRefPending ,updateValOld);
            }

  
          })
      }).then(result => {
        
        console.log('Transaction success!' + result);
        return {'transaction': true, 'newVal': newVal, 'oldNumericSave': oldNumericSave, 'newValId': newValId}
      }).catch(err => {
        console.log('Transaction failure:', err);
        return {'transaction': null, 'failMessage': err}
      });

      Firebase.trackMixpanel('createDispatchVesselOnline', {'result': transaction})








      // SCRATCH@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@



    } else {
      setLoading(true)
      alert('no offline implementation yet')

    }
    console.log('here transact')
    console.log(transaction)
  
    if (transaction && transaction.transaction != null) {
      newVal = transaction.newVal 
      var oldNumericSave = transaction.oldNumericSave
      var newValId = transaction.newValId
  
      let s = async () => {
          var datPack = {}
          const imageRef = Firebase.storage.ref('test').child('pdf_for_wab_' + oldNumericSave + '.pdf')
          let v = await imageRef.getDownloadURL();
          console.log(v)
          console.log('got it')
          datPack['pdflink'] = v 
          datPack['dispatchId'] = newValId
          let snone = () => console.log('x')
          Firebase.provideWaybillLink(datPack, snone, snone)
          //window.open(v)
  
      } 
  
      let f = () => {
       
      }
      let s2 = async () => {
        var datPack = {}
        const imageRef = Firebase.storage.ref('test').child('pdf_for_whout_' + oldNumericSave + '.pdf')
        let v = await imageRef.getDownloadURL();
        console.log(v)
        console.log('got it')
        datPack['pdflink'] = v 
        datPack['dispatchId'] = newValId
        let snone = () => console.log('x')
        Firebase.provideWarehouseOutLink(datPack, snone, snone)
        //window.open(v)

    } 

    let f2 = () => {
     
    }
    var datxx = {}
    datxx['val'] = newVal
    datxx['userId'] = 'test'
    
    //Firebase.generateWarehouseOut(datxx, s2, f2)
      
      Firebase.generateWaybill(datxx, s, f)
  
  }

  return transaction 
  setLoading(false)
  if (transaction && transaction.transaction == null && transaction.failMessage) {
    alert(transaction.failMessage.msg)
  } else {
    //navigate(-1)
    //navigate('/app/creationsummary', {state: {headerText: 'Dispatch: ' + transaction.oldNumericSave, secondaryText: 'Successfully created report.' }, replace: true})
  }
  }


  async function doSubmitWarhouse(v, context) {


    let typeKey = 'WH'
    var transaction = null

    if (Firebase.ONLINE) {
      setLoading(true)


      var newVal = v 

      let operation = newVal['operation']
      let train = newVal['train']
      let source = newVal['source']
      let sku = newVal['sku']
      let destination = newVal['warehouse']

      let amount = newVal['netweightkg']
    
      newVal['train'] = train 

  
      var newSet = {'sku': sku.name}
      newSet['destination'] = destination.name 
      var oldSet = {'sku': sku.name}
      oldSet['warehouse'] = source.name 

      // SCRATCH@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
      if (newVal['purity'] === '') {
        delete newVal['purity']
      }
      if (newVal['moisture'] === '') {
        delete newVal['moisture']
      }

      let stockKey = getDictId(newSet)
      let oldStockKey = getDictId(oldSet)


      let yearCode = moment(Date.now()).format('YY')

    // transfer in number
    let docRef1 = Firebase.firestore.collection('projects').doc(Firebase.userProject).collection('barleyConstants').doc(yearCode + '-dispatchNumber');

      // reference of old barley transfer out request, which should be updated to be in completed state 
      //var docRefOld = Firebase.firestore.collection('projects').doc(Firebase.userProject).collection('transferBarleyOut').doc(transferOut.id)
      // reference of new barley transfer in request, which will be created now
      var docRef = Firebase.firestore.collection('projects').doc(Firebase.userProject).collection("arrivals").doc()
    

      var docRefTrain = newVal['fromVessel'] ? Firebase.firestore.collection('projects').doc(Firebase.userProject).collection("operations").doc(operation.operationId) : Firebase.firestore.collection('projects').doc(Firebase.userProject).collection("trains").doc(train.trainId)//Firebase.firestore.collection('projects').doc(Firebase.userProject).collection("trains").doc(train.trainId)
      // Reference of stock in the train to decrease
      let stockDocRefPending = Firebase.firestore.collection('projects').doc(Firebase.userProject).collection('barleyStocks').doc(oldStockKey)
      // Reference of stock in new warehouse (this will be increased)
      let stockDocRef = Firebase.firestore.collection('projects').doc(Firebase.userProject).collection('barleyStocks').doc(stockKey)
      
      
      var newValId = docRef.id 

      var newQueryResult = null 
      var oldQueryResult = null 
      var oldNumericSave = null;
      var oldTransferValue = null; 
      var oldNumericValue = null;
      var newNumericValue = null;
  
      var trainVal = null;
      var innerPlanRef = null 
      var innerPlanVal = null
      if (newVal['planId'] != null && newVal['innerPlanId'] != null) {
        innerPlanRef = Firebase.firestore.collection('projects').doc(Firebase.userProject).collection('plansInner').doc(newVal['planId']).collection('contents').doc(newVal['innerPlanId'])
      }

      var customCreations = {}


      let arrayOfMemberIds = Object.keys(customCreations)
      let shortCode = source && source['shortcode'] ? source['shortcode'] : null
      var updCode = {}
     transaction = await Firebase.firestore.runTransaction(async t => {

      return t.get(docRef1)
      .then(async doc => {

        if (shortCode) {
          if (shortCode in doc.data()) {
            newNumericValue = doc.data()[shortCode] + 1;  //You calculate the new value
            let shortCodeValue = shortCode + '-' + typeKey +  Firebase.padDigits(doc.data()[shortCode]) + '-' + yearCode
            oldNumericValue = shortCodeValue
            oldNumericSave = shortCodeValue
            updCode[shortCode] = newNumericValue
            //return t.update(docRef1 , updCode);
          } else {
            newNumericValue = 2;  //You calculate the new value
            let shortCodeValue = shortCode + '-' + typeKey + Firebase.padDigits(1) + '-' + yearCode
            oldNumericValue = shortCodeValue
            oldNumericSave = shortCodeValue
            updCode[shortCode] = newNumericValue
           // return t.update(docRef1 , updCode);
          }
          
        } else {
          newNumericValue = doc.data().value + 1;  //You calculate the new value
          oldNumericValue = typeKey + Firebase.padDigits(doc.data().value) + '-' + yearCode 
          oldNumericSave = oldNumericValue
          updCode['value'] = newNumericValue
          //return t.update(docRef1 , {value : newNumericValue});
        }


        if (innerPlanRef) {
          let innerPlanRes = await t.get(innerPlanRef)
          if (innerPlanRes.exists) {
            innerPlanVal = innerPlanRes.data()
          }
        }
        let trainRes = await t.get(docRefTrain)
        if (trainRes.exists) {
          trainVal = trainRes.data()
        } else {
          const errM = {'msg': "Train does not exist."}
          throw errM
        }


        return t.get(stockDocRef )
      }).then(querySnapshotNew => {
          // Get new stock ref for the in transit barley to dest
          if (querySnapshotNew.exists) {
            newQueryResult = querySnapshotNew.data()
  
          } else {
            newQueryResult = null 
          }
  
         /* return t.get(docRefOld)
          
        })
        .then(querySnapshotTransfer => {
          // Get new stock ref for the in transit barley to dest
          if (querySnapshotTransfer.exists) {
            oldTransferValue = querySnapshotTransfer.data()
  
          } else {
            const errM = {'msg': "Transfer out request does not exist."}
            throw errM
          }*/
  
          return t.get(stockDocRefPending)
          
        }).then(querySnapshotOld => {
          // Get old stock ref for the pending barley to remove from source
          if (querySnapshotOld.exists) {
            oldQueryResult = querySnapshotOld.data()
          } else {
              const errM = {'msg': "Pending stock does not exist."}
              console.log('SKIPPING ERROR')
              console.log(errM)
              //throw errM
          }
            // update custom IDs if needed
            arrayOfMemberIds.forEach(function(memberId) {
                let docRefNext = Firebase.firestore.collection('projects').doc(Firebase.userProject).collection('barleyInCustomObjects').doc('contents').collection('projects').doc(Firebase.userProject).collection(memberId).doc()
                console.log('here t first')
                console.log(t)
                t = t.set(
                docRefNext,
                { name: customCreations[memberId] }
                )
                console.log('setting id of ' + docRefNext.id)
                newVal[memberId] = {'customId': docRefNext.id, 'name': customCreations[memberId]}
                
            });
            return t
            }).then(t => {
              // make the new transfer out item 
            newVal['dispatchNumber'] = oldNumericValue
            //newVal['transferOutNumber'] = transferOut['transferOutNumber']
            return t.set(docRef ,newVal);
            })/*.then(t => {
              // make the new transfer out item 
            if (oldTransferValue.status == 'Completed') {
              const errM = {'msg': "This transfer has already been completed."}
              throw errM
            }
            return t.update(docRefOld ,{'status': 'Completed'});
            })*/.then(t => {


            // Update inner plan if needed
            if (innerPlanVal) {
              let current = innerPlanVal.receivedqty ? innerPlanVal.receivedqty : 0
              var updateValInnerPlan = {'receivedqty': current + parseInt(amount)}
              t.update(innerPlanRef, updateValInnerPlan)
            }



              var newAllocTrain = trainVal.sentAmounts ? trainVal.sentAmounts : {}
              let whid = destination.customId 
              let newAmt = newAllocTrain[whid] ? newAllocTrain[whid] : 0
              let finAmt = newVal['netweightkg'] + newAmt
              newAllocTrain[whid] = finAmt 
              t.update(docRefTrain, {'sentAmounts': newAllocTrain})
              return t.update(docRef1 , updCode);
            }).then(t => {
            // Update the pending stock

            var updateValOld = {}
            if (!newVal['finalweightkg']) {
              const errM = {'msg': "No barley being transferred."}
              throw errM
            }
            if (!oldQueryResult || !oldQueryResult['netweightkg'] || oldQueryResult['netweightkg'] < newVal['finalweightkg']) {
              console.log('completely skipping error for old result barley oldqueryresult etc train')
              
              const errM = {'msg': "Not enough stock has been deposited in this warehouse. Amount in warehouse is " + oldQueryResult['netweightkg'] + "kg, while trying to transfer " + newVal['finalweightkg'] + "kg."}
              throw errM
            }
            updateValOld['netweightkg'] = (oldQueryResult && oldQueryResult['netweightkg'] ? oldQueryResult['netweightkg'] : 0) - newVal['netweightkg']
            if (oldQueryResult) {
              return t.update(stockDocRefPending ,updateValOld);
            } else {
              return t.set(stockDocRefPending ,updateValOld);
            }

  
          })
      }).then(result => {
        
        console.log('Transaction success!' + result);
        return {'transaction': true, 'newVal': newVal, 'oldNumericSave': oldNumericSave, 'newValId': newValId}
      }).catch(err => {
        console.log('Transaction failure:', err);
        return {'transaction': null, 'failMessage': err}
      });

      Firebase.trackMixpanel('createDispatchWarehouseOnline', {'result': transaction})








      // SCRATCH@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@


    } else {
      setLoading(true)
      alert('no offline implementation yet')

    }
    console.log('here transact')
    console.log(transaction)
  
    if (transaction && transaction.transaction != null) {
      newVal = transaction.newVal 
      var oldNumericSave = transaction.oldNumericSave
      var newValId = transaction.newValId
  
      let s = async () => {
          var datPack = {}
          const imageRef = Firebase.storage.ref('test').child('pdf_for_wab_' + oldNumericSave + '.pdf')
          let v = await imageRef.getDownloadURL();
          console.log(v)
          console.log('got it')
          datPack['pdflink'] = v 
          datPack['dispatchId'] = newValId
          let snone = () => console.log('x')
          Firebase.provideWaybillLink(datPack, snone, snone)
          //window.open(v)
  
      } 
  
      let f = () => {
       
      }
      var datxx = {}
      datxx['val'] = newVal
      datxx['userId'] = 'test'

      let s2 = async () => {
        var datPack = {}
        const imageRef = Firebase.storage.ref('test').child('pdf_for_whout_' + oldNumericSave + '.pdf')
        let v = await imageRef.getDownloadURL();
        console.log(v)
        console.log('got it')
        datPack['pdflink'] = v 
        datPack['dispatchId'] = newValId
        let snone = () => console.log('x')
        Firebase.provideWarehouseOutLink(datPack, snone, snone)
        //window.open(v)

    } 

    let f2 = () => {
     
    }
    var datxx = {}
    datxx['val'] = newVal
    datxx['userId'] = 'test'
    
    Firebase.generateWarehouseOut(datxx, s2, f2)
      
      Firebase.generateWaybill(datxx, s, f)
  
    }

    return transaction

  setLoading(false)
  if (transaction && transaction.transaction == null && transaction.failMessage) {
    alert(transaction.failMessage.msg)
    
  } else {
    //navigate(-1)
    //navigate('/app/creationsummary', {state: {headerText: 'Arrival At Destination: ' + transaction.oldNumericSave, secondaryText: 'Successfully created report.' }, replace: true})
  }
  //console.log(transaction)
  //console.log(transaction)


  }







  function resetPassword() {
    if (!values.email) {
      alert('You need an email account to reset your password. Please contact an administrator to have an email address added to your account.')
      return
    }

    Firebase.resetPasswordViaEmailWarehouseAuth({'email': values.email})
    alert('An email to reset your password should be on the way to ' + values.email + '. Check your spam folder if you do not receive it in the next few minutes.')

  }

  async function testFb() {
    const snapshot = await Firebase.firestore.collection('projects').doc(Firebase.userProject).collection('arrivals').where('netweightkg', '==', 44000).get()
     console.log(snapshot)
     console.log(snapshot.empty)
     var mx = {}
    let ax = snapshot.docs.map(doc => mx[doc.id] = doc.data());
    console.log(ax)
    console.log(mx)
    let badgeUserDict = await Firebase.firestore.collection('projects').doc(Firebase.userProject).collection('arrivals').doc('4JQPaTBo67na61di5KPn').get().then((inner_snapshot) => {
      console.log(inner_snapshot)
      console.log(inner_snapshot.exists)
      console.log(inner_snapshot.data())
      if (inner_snapshot.exists) {
          return inner_snapshot.data()
      } else {
          return null
      }
  }).catch((error) => {
      return null
  })
  console.log(badgeUserDict)
  console.log(Firebase.firestore)
  let zfa = await Firebase.firestore.collection('projects').doc(Firebase.userProject).collection('arrivals').doc('4JQPaTBo67na61di5KPn').update({'fieldToDelete': Firebase.firestoreSimple.FieldValue.delete()})

  }

  function sendUpdate() {
    var userId = userInfo.userId 

    var dat = {}
    if (!userId) {
      dat['userId'] = Firebase.uid
    } else {
      dat['userId'] = userId 
    }

    dat['updateBody'] = values 
    Firebase.adminEditUser(dat, () => alert('Profile updated'), () => alert('Failed to update profile'))
    //Firebase.trackMixpanel('updateProfile', {'updateDate': values})
  }

  function logoutFirebase() {
    Firebase.auth.signOut().then(function() {
      // Sign-out successful.
      Firebase.resetMixpanel()
    }).catch(function(error) {
      // An error happened.
    })
  }

  function procAdama(dats) {

  }


  function renderPlanInformation(operation, destination, sku, context, setFieldValue, values) {

    if (!operation) {
      return ''
    }
    var s = ''
    var planIds = []
    var consignee = null
    if (operation && operation.yearlyPlans) {
      s = 'Associated Plan(s): '
      operation.yearlyPlans.map((item, idx) => {
        if (idx > 0) {
          s += ', '
        }

        planIds.push(item)
        
        if (item in context.planDict) {
          s += context.planDict[item].name
          consignee = context.planDict[item].consignee
        }
      })
      //return s
    }
    var innerPlanIds = []
    var outerPlanIds = []
    if (destination && sku && destination.union) {
      let nameTarget = destination.name 
      let unionTarget = destination.union 
      let skuTarget = sku.name 
      var priceList = []

      planIds.map((plan, idx) => {
        if (plan in context.innerPlansDict) {
          let innerDict = context.innerPlansDict[plan]
          Object.keys(innerDict).map((key, idx) => {
            let innerPlan = innerDict[key]
            //console.log('check inner plan')
            //console.log(innerPlan)
            if (innerPlan && innerPlan.sku && innerPlan.warehouse
              && innerPlan.sku.name == skuTarget && innerPlan.warehouse.name == nameTarget
              && innerPlan.warehouse.union == unionTarget /*&& innerPlan.unitPrice*/) {
                priceList.push(innerPlan.unitPrice)
                innerPlanIds.push(key)
                outerPlanIds.push(plan)
            }

          })
        }
      })
      /*console.log('INSIDE HERE')
      console.log(planIds)
      console.log(nameTarget)
      console.log(unionTarget)
      console.log(skuTarget)*/
      if (priceList.length > 0 && innerPlanIds.length > 0) {
        var priceStr = '. Planned Price(s) per Quintal: '
        priceList.map((price, idx) => {
          if (idx > 0) {
            priceStr += ', '
          }
          priceStr += price 
        })
        //s += priceStr 
        if (values.unitprice != priceList[0] || values.innerPlanId != innerPlanIds[0] || values.planId != outerPlanIds[0]) {
          /*console.log(values.unitprice)
          console.log(priceList[0])
          console.log(values.innerPlanId)
          console.log(innerPlanIds[0])*/
          setFieldValue('planId', outerPlanIds[0])
          setFieldValue('innerPlanId', innerPlanIds[0])
          setFieldValue('unitprice', priceList[0])

        }

      } else if (values.unitprice != null || values.innerPlanId != null || values.planId != null) {
        //console.log(values.unitprice)
        //console.log(values.innerPlanId)
        setFieldValue('planId', null)
        setFieldValue('innerPlanId', null)
        setFieldValue('unitprice', null)
      
      }
     
    }

    if (consignee != values.consignee) {
      setFieldValue('consignee', consignee)
    }


    /*if (innerPlanIds.length < 1) {
      s += ' Note: There is not a plan corresponding to the current destination, sku, and operation.'
    }*/

    return s

  }


  function getInnerPlan(destination, sku, plan, context) {
    if (destination && sku && destination.union) {
      let nameTarget = destination.name 
      let unionTarget = destination.union 
      let skuTarget = sku.name 
      var priceList = []
      var innerPlanId = null 

        if (plan in context.innerPlansDict) {
          //console.log('inside plan dict here ' + nameTarget + ' ' + unionTarget + ' ' + skuTarget)
          let innerDict = context.innerPlansDict[plan]
          //console.log(Object.keys(innerDict).length)
          Object.keys(innerDict).map((key, idx) => {
            let innerPlan = innerDict[key]
            //console.log('check inner plan')
            //console.log(innerPlan)
            if (innerPlan && innerPlan.sku && innerPlan.warehouse
              && innerPlan.sku.name == skuTarget && innerPlan.warehouse.name == nameTarget
              && innerPlan.warehouse.union == unionTarget /*&& innerPlan.unitPrice*/) {
                innerPlanId = key
            }

          })
        } else {
         console.log('plan not in dict!!')
        }
  
        if (!innerPlanId) {
          console.log('FAIELD FOR ' + nameTarget + ' ' + unionTarget + ' ' + skuTarget)
        }
     
    }

    

    return innerPlanId
  }

  function doConversionSheets(dataSheets, opMap, whMap, sku, context) {

    // fs update ops
    /*var tgtwh = null 
    context.operationList.map((op, idx) => {
      if (op.warehouse) {
        tgtwh = op.warehouse
      }
    })

    context.operationList.map((op, idx) => {
      if (!op.warehouse) {
        Firebase.firestore.collection('projects').doc(Firebase.userProject).collection('operations').doc(op.operationId).update({'warehouse': tgtwh})
      }
    })*/

    var missingWh = {}
    var resultingArrays = dataSheets.map((sheet, idx) => {


      return doConversionDjibouti(sheet, opMap, whMap, sku, context, missingWh)


    })
    console.log(missingWh)
    console.log(whMap)
    return resultingArrays
  }

  
  function doConversionMojo(dataRows, opMap, whMap, sku, context, missingWh) {



    var headers = dataRows[0]
    var rowxAll = []
    var currentName = null 

    const vessel_names_to_ops = {'MV DRAFTZILLA': 'DRAFT ZILLA, V2201', 'DRAFTZILLA': 'DRAFT ZILLA, V2201',
    'MV ERIKOUSSA': 'ERIKOUSSA VOY 27L',
    'ERIKOUSSA': 'ERIKOUSSA VOY 27L',
    'MV VIRGO CONFIDENCE': 'MV VIRGO CONFIDENCE',
    'VIRGO CONFIDENCE': 'MV VIRGO CONFIDENCE',
  'MO GAN SHAN': 'MO GAN SHAN V2201',
'MV MO GAN SHAN': 'MO GAN SHAN V2201',
'CORE IMPERIAL': 'MV CORE IMPERIAL',
'SEA HARMONY': 'SEA HARMONY VOY 55'}

    const tn_names_to_trains = {
      '2ND TRAIN WAGON 37  , VESSEL NAME - M.V DRAFTZILLA ,  OPERATION NO 7411-1155 DECLARATION NO 4-001465/ T4905  FERTILIZER NPSB': 'MV DRAFTZILLA 2ND TRAIN',
      '4TH TRAIN WAGON 37  , VESSEL NAME - M.V DRAFTZILLA ,  OPERATION NO 7411-1155 DECLARATION NO 4-001465/ T4905  FERTILIZER NPSB': 'MV DRAFTZILLA 4TH TRAIN',
      '6TH TRAIN WAGON 37  , VESSEL NAME - M.V VIRGO CONFIDENCE ,  OPERATION NO 7411-1168 DECLARATION NO 4-001942/ T  FERTILIZER NPSB': 'MV VIRGO CONFIDENCE 6TH TRAIN',
      '8TH TRAIN WAGON 37  , VESSEL NAME - M.V VIRGO CONFIDENCE ,  OPERATION NO 7411-1168 DECLARATION NO 4-001942/ T6227 / T4905  FERTILIZER NPSB': 'MV VIRGO CONFIDENCE 8TH TRAIN',
      '10TH TRAIN WAGON 37  , VESSEL NAME - M.V ERIKOUSSA ,  OPERATION NO 7411-1181 DECLARATION NO 4-002340/ T7548  FERTILIZER NPSB': 'ERIKOUSSA VOY 27L 10TH TRAIN',
      '12TH TRAIN WAGON 37  , VESSEL NAME - M.V ERIKOUSSA ,  OPERATION NO 7411-1181 DECLARATION NO 4-002340/ T7548  FERTILIZER NPSB': 'ERIKOUSSA VOY 27L 12TH TRAIN'
    }

    const wh_names_remap = {
      'HAWASA-ELITO': 'HAWASA-SIDAMA ELITO',
      'MODJO-EABC': 'MODJO-EABC WAREHOUSE',
      'ASELA-EABC': 'ASELA-EABC WAREHOUSE',
      'ADAMA-EABC': 'ADAMA-EABC WAREHOUSE',
      'ADAMA-DADEA': 'ADAMA-DEDEA',
      'ADAMA DADEA': 'ADAMA-DEDEA',
      'ADAMA-ARBAGUGU': 'ADAMA-ARBA GUGU',
      'WOLAITA-DAMOT': 'BODITI-DAMOTA',
      'ROBE-SECOMANDO': 'ROBE-SIKOMANDO',
      'KARAT KONSO': 'KONSO-MILTAKUTICIANA',
      'DOYO GENA-ANGACHA': 'DOYOGENA-ANGACHA',
      'SHMASHEMENE-RAYA WAKENA': 'SHASHEMENE-RAYA WAKENA',
      'BALCH-KESEM': 'BALCHI-KESEM',
      'HOSANA-LICHA': 'HOSAENA-LICHA',
      'KONSO-MILIYATA KUTISIYANA': 'KONSO-MILTAKUTICIANA',
      'BISHOFTU-ERER': 'BISHOFTU-ERRER',
      'kARAT-KONSO': 'KONSO-MILTAKUTICIANA',
      'WOLAITA-DAMOT': 'SODO-DAMOTA',
      'WOLAITA-DAMOT ': 'SODO-DAMOTA',
      'GALEMA': 'ADAMA-GALEMA',
      'ADAMA HETOSA': 'ADAMA-HETOSA',
      'ADAMA  HETOSA': 'ADAMA-HETOSA',
      'DENEBA-JIMMA': 'DENEBA-JIMMA UNION',
      'AZENA-ADMAS': 'AZENA-ADMAS',
      'METU-SORGBA': 'METU-SERGOBA',
      'AGARO-JIMMA': 'AGARO-JIMMA UNION',
      'DENABA-JIMMA UNION': 'DENEBA-JIMMA UNION',
      'DENABA-JIMMA UNION': 'DENEBA-JIMMA UNION',
      'DENABA- JIMMA UNION': 'DENEBA-JIMMA UNION',
      'DENABA -JIMMA UNION': 'DENEBA-JIMMA UNION',
      'ASENDABO-JIMMA': 'ASENDABO-JIMMA UNION',
      'OMO NADA-JIMMA': 'OMONADA-JIMMA UNION',
      'GONDER-TSEHAY': 'GONDAR-TSEHAY',
      'BURE-DAMOT': 'BURE-DAMOT',
      'CHELENQO-AFERENKELO': 'CHELENKO-AFREN KALO',
      'DEBERE MARKOS-GOZAMIN': 'DEBRE MARKOS-GOZAMIN',
      'MOTA-MOTA': 'MOTA-MOTA',
      'LIMU GENE-LIMU ENARIA': 'LIMUGENET-LIMU ENARYA',
      'KILAJ/ZEGEM ADMAS': 'KILAJ-ADMAS',
      'JIMMA-JIMMA': 'JIMMA-JIMMA UNION',
      'WOLISO-BECHO WOLISO': 'WOLISO-BECHO WELISO',
      'WOLDIYA-JEMBAR': 'WOLDIA-JEMBER',
      'WORETA-MEGENAGNA': 'WORETA-MEGENAGNA',
      'WERABE-MELIK': 'WORABE-MELIKA AWASH',
      'WELKITE-ADMAS UNION': 'WOLIKITE-ADMAS',
      'TULU BOLO-BECHO WOLISO': 'TULU BOLO-BECHO WELISO',
      'TATEK-ROBE BERGA': 'TATEK-ROBE BERGA',
      'TATEK-AMBO': 'TATEK-AMBO',
      'TOLAY LIMU ENARIA': 'WAYU-TOLAY-LIMU ENARYA',
      'TOLAY (WAYU) LIMU ANARIYA': 'WAYU-TOLAY-LIMU ENARYA',
      'TATEK-CHAFE BULUK': 'TATEK-CHEFE BULIK',
      'WORETA MEGENAGNA': 'WORETA-MEGENAGNA',
      'SHASHEMENE-RAYA WEKANE': 'SHASHEMENE-RAYA WAKENA',
      'SHASHEMENE-DEBUBE FEDERATION': 'SHASHEMENE-DEBUB FEDERATION',
      'SEKORU-JIMMA': 'SOKORU-JIMMA UNION',
      'SERBO-JIMMA': 'SERBO-JIMMA UNION',
      'SAWULA ESPI DICHA': 'SAWLA-ESEPEDICHA',
      'NIFAS MEWECHA-RAS GAYET': 'NEFAS MEWECHA-RAS GAYINT',
      'MUKA TURI-BIFTU SELALE': 'MUKETURI-BIFTU SELALE',
      'MERTO LEMARYAM MOTA': 'MERTO LEMARIAM-MOTA',
      'LIMU GENET-LIMU ENARIA': 'LIMUGENET-LIMU ENARYA',
      'KOMBOLCHA-WEDERA': 'KOMBOLCHA-WODERA',
      'KILAJ ADMAS': 'KILAJ-ADMAS',
      'KERANEYO-MOTA': 'KERANIO-MOTA',
      'HOSANA-LICHA UNION': 'HOSAENA-LICHA',
      'HAWASA-SIDAMA ELTO': 'HAWASA-SIDAMA ELITO',
      'HAMUSIT MEGENAGNA': 'HAMUSIT-MEGENAGNA',
      'GURSUM-AFERENKELO': 'GURSUM-AFREN KALO',
      'GINDEWAYIN-MOTA': 'GINDE WOYIN-MOTA',
      'FINOTESELAM-DAMOT': 'FINOTE SELAM-DAMOT',
      'DOYOGENA ANGACHA': 'DOYOGENA-ANGACHA',
      'DODOLA-RAYA WEKANE': 'DODOLLA-RAYA WAKENA',
      'DENBECHA-DAMOT': 'DEMBECHA-DAMOT',
      'MUKETURI-hBIFTU SELALE': 'MUKETURI-BIFTU SELALE',
      'DEDER-AFRERENKELO': 'DEDER-AFREN KALO',
      'DEBRE BIRHAN-WEDERA': 'DEBRE BIRHAN-WODERA',
      'CHIRO-CHERCHER ODA BULTUM': 'CHIRO-CHERICHER ODA BULTUM',
      'BISHOFTU ERRER': 'BISHOFTU-ERRER',
      'BEDELE-BUNO BEDELE': 'BEDELLE-BUNO BEDELLE',
      'BALCHIT KESEM': 'BALCHI-KESEM',
      'BAHIR DAR-MEREKEB': 'BAHIR DAR-MERKEB',
      'ARBEMINCH GAMGOFA': 'ARBAMINCH-GAMO GOFA',
      'ALI SEKOMANDO': 'ALI-SIKOMANDO',
      'ALEMGENA-MELKA AWASH': 'ALEMGENA-MELIKA AWASH',
      'ADAMA-DEBUB FEDR': 'ADAMA-DEBUB FEDERATION',
      'ADAMA EABC WAREHOUSE': 'ADAMA-EABC WAREHOUSE',
      'WALAITA SODO-DAMOT': 'SODO-DAMOTA',
      'WOLYITA SODO-DAMOT': 'SODO-DAMOTA',
      'DENABA-JIMMA UNION': 'DENEBA-JIMMA UNION',
      'MEKANE EYESUS/ESTE RAS GAYINT': 'ESTE-RAS GAYINT',
      'ENBSE/M/LEMARIAM MOTA': 'MERTO LEMARIAM-MOTA',
      'BAHAR DAR EGISCO': 'BAHIR DAR-EABC WAREHOUSE',
      'ARBAMINCH GAMO ETFRUIT': 'ARBAMINCH-GAMO GOFA',
      'ADDIS ABABA-AISE': 'ADDIS ABABA-EABC WAREHOUSE',
      'ADAMA EGISCO WH': 'ADAMA-EABC WAREHOUSE',
      'A-A EGISCO WH': 'ADDIS ABABA-EABC WAREHOUSE',
      'TATEK HARAGU': 'TATEK-HARAGU',
      'ABRAMINCH-GAMO GOFA': 'ARBAMINCH-GAMO GOFA',
      'ALEMGENA BIFTU SELAL': 'ALEMGENA-BIFTU SELALE',
      'CHIRO BUREKA GALATI': 'CHIRO-BUREKA GALATI',
      'DESSIE KTEMA ERIKUM': 'DESSIE-DESSIE KTEMA',
      'HALABA AMBRICHO': 'HALABA-AMBRICHO',
      'HAROMAYA-AFERENKELO': 'HAROMAYA-AFREN KALO',
      'HAROMIA-AFREN KALO': 'HAROMAYA-AFREN KALO',
      'HAWASSA DEBUB': 'HAWASSA-DEBUB',
      'HAWASSA DEBUB FED.': 'HAWASSA-DEBUB',
      'HIRENA BUREKA GALATI': 'HIRENA-BUREKA GALATI',
      'DENBIDOLO/TORBAN ANFILO': 'DENBI DELO-TORBAN ANFILO',
      'GIMBI/JORJO BIRBIR': 'GIMBI-JORGO BIRBIR',
      'CHANKA/TORBAN ANFILO': 'CHANKA-TORBAN ANFILO',
      'NEKEMT /AISCO/FROM ASSOSA SHARE': 'NEKEMT-EABC WAREHOUSE',
      'ASSOSA/BAMBASI/ASSOSA': 'ASOSA-BAMBASI'
    }
    //console.log(dataRows)
    //console.assert(context.planList.length == 1)
    let plan = context.planList[1]
    console.log('here plan here')
    console.log(plan)

    console.log('here the hell is sku')
    console.log(sku)

    let planId = plan.planId
    var mapHeadersToIdx = {}
    var adamats = whMap['Adama-Train Station']
    var m1 = whMap['Mojo-1-']
    var m2 = whMap['Mojo-2-']

    for (var idx = 0; idx < dataRows.length; idx++) {

      let row = dataRows[idx]
      //console.log(idx)
      //console.log(row)
      if (idx == 1) {
        row.map((rowItem, innerIdx) => {
          mapHeadersToIdx[innerIdx] = rowItem
        })
        //return null 
      } else if (row.length == 1) {
        currentName = row[0]
      } else if (row[0] == "S No" || row.length < 11) {
        console.log('headerskip[')
      } else {
        var fullValue = {}
        let newRow = row.map((rowItem, innerIdx) => {
          let header = mapHeadersToIdx[innerIdx]
          let newKey = parseDict[header]
          var newVal = rowItem
          //console.log(newVal)
          if (newKey == 'vn_for_map') {
            //console.log(vessel_names_to_ops)
            console.log(newVal)
            console.log(rowItem)
            console.log(row)
            console.assert(newVal in vessel_names_to_ops, 'missing vessel name ' + newVal)
            newVal = vessel_names_to_ops[newVal]
            console.assert(newVal in opMap, 'missing from op map ' + newVal)
            newVal = opMap[newVal]

          } else if (newKey == 'dispatchdate') {
            fullValue['baseTimeKey'] = newVal
            //console.log(typeof(newVal))
            if (typeof(newVal) == 'string') {
              if (newVal.includes('2022')) {
                newVal = moment(newVal, 'DD/MM/YYYY').toDate().getTime() + idx
              } else if (newVal.includes('/22'))  {
                newVal = moment(newVal, 'DD/MM/YY').toDate().getTime() + idx
              } else {
                console.asset(false, 'ERROR DATE FUCK')
              }
              
              //console.log('do change time to ' + newVal)
            } else {
              newVal = new Date(Math.round( ((newVal - 25569)*86400*1000) + (1000 * 3600 * 12) + (idx) ) )
              newVal = newVal.getTime()
              newVal = moment(newVal).format('MM/DD/YYYY')
              fullValue['rlfTime'] = newVal
              newVal = moment(newVal, 'DD/MM/YYYY').toDate().getTime() + idx

            }


          } else if (newKey == 'dst_for_map') {
            var keyTarget = newVal.replace(' - ', '-').toUpperCase()
            if (keyTarget.includes('MUKETURI-') && keyTarget.includes('BIFTU SELALE')) {
              alert(keyTarget)
            }
            if (keyTarget != 'DATA IMPORTED') {
              if (keyTarget in wh_names_remap) {
                keyTarget = wh_names_remap[keyTarget]
              }
              console.assert(keyTarget in whMap, 'missing wh loc ' + keyTarget)
              if (!(keyTarget in whMap)) {
                if (keyTarget in missingWh) {
                  missingWh[keyTarget] += 1
                } else {
                  missingWh[keyTarget] = 1
                }

              }
              newVal = whMap[keyTarget]
            }

          } else if (newKey == 'wag_or_wh') {
            let lcnv = newVal.toLowerCase()
            if (lcnv.includes('wh')) {
              fullValue['fromWarehouse'] = true 
            }
          }

          

          
          fullValue[newKey] = newVal
          //rowxAll.push(fullValue)
        })
        
        if (fullValue['dst_for_map'] && fullValue['dst_for_map'] != 'DATA IMPORTED') {

            //console.log(fullValue)
            /*var splitProc = fullValue['import_seq_wb'].split('B3760')
            var endProc = splitProc[1]
            var hyphenProc = endProc.split('-')
            fullValue['old_wbn'] = fullValue['import_seq_wb']
            fullValue['import_seq_wb'] = parseInt(hyphenProc[0])
            fullValue['hardcode_usercode_wb'] = hyphenProc[1]*/

            fullValue['fromVessel'] = true 
            fullValue['fromWarehouse'] = true 
            fullValue['train'] = null

            fullValue['FROM_IMPORT'] = true 
    
            fullValue['creationDate'] = fullValue['dispatchdate']
            fullValue['prepared'] = 'Nigusie'
            fullValue['averagebagweight'] = 50
            fullValue['dlnumber'] = ''
            fullValue['driverphone'] = ''
            fullValue['netweightkg'] = fullValue['averagebagweight'] * parseInt(fullValue['numberofbags'])
            fullValue['finalweightkg'] = fullValue['netweightkg']
            fullValue['operation'] = fullValue['vn_for_map']
            fullValue['warehouse'] = fullValue['dst_for_map']
            fullValue['consignee'] = 'ETHIOPIAN AGRICULTURAL BUSINESS CORPORATION'
            fullValue['unitprice'] = 0
            if (fullValue['wag_or_wh'].includes('WH-2')) {
              fullValue['source'] = m2 
            } else {
              fullValue['source'] = m1
            }

            let setSku = sku.filter((itemsku, idxsku) => {
              return itemsku.name == fullValue['cargo_for_map']
            })
            
            fullValue['sku'] = setSku[0]
            fullValue['planId'] = planId
            //console.log('prep check fullvaue')
            //console.log(fullValue)


            let innerPlanId = getInnerPlan(fullValue['warehouse'],
            fullValue['sku'], planId, context)
            fullValue['innerPlanId'] = innerPlanId
            fullValue['isFinal'] = true 
            console.assert(planId != null && innerPlanId != null, 'found some nulls ' + planId + ' ' + innerPlanId)
            
            
    
            rowxAll.push(fullValue) 


        }
        console.log(fullValue)

      }
    }

    console.log(rowxAll)

    var num_only = rowxAll.filter((item, idx) => {
      return item.import_seq_wb != "AD0018B" && item.import_seq_wb != "AD0125B"
    })
    /*var let_only = rowxAll.filter((item, idx) => {
      return item.import_seq_wb == "AD0018B"
    })
    
    var bllret = let_only[0]
    num_only.push(bllret)*/
    //console.log(weightSum)
    //console.log(tareSum)

    return num_only
  }


  function doConversionDjibouti(dataRows, opMap, whMap, sku, context, missingWh) {



    var headers = dataRows[0]
    var rowxAll = []
    var currentName = null 

    const vessel_names_to_ops = {'MV DRAFTZILLA': 'DRAFT ZILLA, V2201', 'DRAFTZILLA': 'DRAFT ZILLA, V2201',
    'MV ERIKOUSSA': 'ERIKOUSSA VOY 27L',
    'ERIKOUSSA': 'ERIKOUSSA VOY 27L',
    'MV VIRGO CONFIDENCE': 'MV VIRGO CONFIDENCE',
    'VIRGO CONFIDENCE': 'MV VIRGO CONFIDENCE',
  'MO GAN SHAN': 'MO GAN SHAN V2201',
'MV MO GAN SHAN': 'MO GAN SHAN V2201',}

    const tn_names_to_trains = {
      '2ND TRAIN WAGON 37  , VESSEL NAME - M.V DRAFTZILLA ,  OPERATION NO 7411-1155 DECLARATION NO 4-001465/ T4905  FERTILIZER NPSB': 'MV DRAFTZILLA 2ND TRAIN',
      '4TH TRAIN WAGON 37  , VESSEL NAME - M.V DRAFTZILLA ,  OPERATION NO 7411-1155 DECLARATION NO 4-001465/ T4905  FERTILIZER NPSB': 'MV DRAFTZILLA 4TH TRAIN',
      '6TH TRAIN WAGON 37  , VESSEL NAME - M.V VIRGO CONFIDENCE ,  OPERATION NO 7411-1168 DECLARATION NO 4-001942/ T  FERTILIZER NPSB': 'MV VIRGO CONFIDENCE 6TH TRAIN',
      '8TH TRAIN WAGON 37  , VESSEL NAME - M.V VIRGO CONFIDENCE ,  OPERATION NO 7411-1168 DECLARATION NO 4-001942/ T6227 / T4905  FERTILIZER NPSB': 'MV VIRGO CONFIDENCE 8TH TRAIN',
      '10TH TRAIN WAGON 37  , VESSEL NAME - M.V ERIKOUSSA ,  OPERATION NO 7411-1181 DECLARATION NO 4-002340/ T7548  FERTILIZER NPSB': 'ERIKOUSSA VOY 27L 10TH TRAIN',
      '12TH TRAIN WAGON 37  , VESSEL NAME - M.V ERIKOUSSA ,  OPERATION NO 7411-1181 DECLARATION NO 4-002340/ T7548  FERTILIZER NPSB': 'ERIKOUSSA VOY 27L 12TH TRAIN'
    }

    const wh_names_remap = {
      'HAWASA-ELITO': 'HAWASA-SIDAMA ELITO',
      'MODJO-EABC': 'MODJO-EABC WAREHOUSE',
      'ASELA-EABC': 'ASELA-EABC WAREHOUSE',
      'ADAMA-EABC': 'ADAMA-EABC WAREHOUSE',
      'ADAMA-DADEA': 'ADAMA-DEDEA',
      'ADAMA DADEA': 'ADAMA-DEDEA',
      'ADAMA-ARBAGUGU': 'ADAMA-ARBA GUGU',
      'WOLAITA-DAMOT': 'BODITI-DAMOTA',
      'ROBE-SECOMANDO': 'ROBE-SIKOMANDO',
      'KARAT KONSO': 'KONSO-MILTAKUTICIANA',
      'DOYO GENA-ANGACHA': 'DOYOGENA-ANGACHA',
      'SHMASHEMENE-RAYA WAKENA': 'SHASHEMENE-RAYA WAKENA',
      'BALCH-KESEM': 'BALCHI-KESEM',
      'HOSANA-LICHA': 'HOSAENA-LICHA',
      'KONSO-MILIYATA KUTISIYANA': 'KONSO-MILTAKUTICIANA',
      'BISHOFTU-ERER': 'BISHOFTU-ERRER',
      'kARAT-KONSO': 'KONSO-MILTAKUTICIANA',
      'WOLAITA-DAMOT': 'SODO-DAMOTA',
      'WOLAITA-DAMOT ': 'SODO-DAMOTA',
      'GALEMA': 'ADAMA-GALEMA',
      'ADAMA HETOSA': 'ADAMA-HETOSA',
      'ADAMA  HETOSA': 'ADAMA-HETOSA',
      'DENEBA-JIMMA': 'DENEBA-JIMMA UNION',
      'AZENA-ADMAS': 'AZENA-ADMAS',
      'METU-SORGBA': 'METU-SERGOBA',
      'AGARO-JIMMA': 'AGARO-JIMMA UNION',
      'DENABA-JIMMA UNION': 'DENEBA-JIMMA UNION',
      'DENABA-JIMMA UNION': 'DENEBA-JIMMA UNION',
      'DENABA- JIMMA UNION': 'DENEBA-JIMMA UNION',
      'DENABA -JIMMA UNION': 'DENEBA-JIMMA UNION',
      'ASENDABO-JIMMA': 'ASENDABO-JIMMA UNION',
      'OMO NADA-JIMMA': 'OMONADA-JIMMA UNION',
      'GONDER-TSEHAY': 'GONDAR-TSEHAY',
      'BURE-DAMOT': 'BURE-DAMOT',
      'CHELENQO-AFERENKELO': 'CHELENKO-AFREN KALO',
      'DEBERE MARKOS-GOZAMIN': 'DEBRE MARKOS-GOZAMIN',
      'MOTA-MOTA': 'MOTA-MOTA',
      'LIMU GENE-LIMU ENARIA': 'LIMUGENET-LIMU ENARYA',
      'KILAJ/ZEGEM ADMAS': 'KILAJ-ADMAS',
      'JIMMA-JIMMA': 'JIMMA-JIMMA UNION',
      'WOLISO-BECHO WOLISO': 'WOLISO-BECHO WELISO',
      'WOLDIYA-JEMBAR': 'WOLDIA-JEMBER',
      'WORETA-MEGENAGNA': 'WORETA-MEGENAGNA',
      'WERABE-MELIK': 'WORABE-MELIKA AWASH',
      'WELKITE-ADMAS UNION': 'WOLIKITE-ADMAS',
      'TULU BOLO-BECHO WOLISO': 'TULU BOLO-BECHO WELISO',
      'TATEK-ROBE BERGA': 'TATEK-ROBE BERGA',
      'TATEK-AMBO': 'TATEK-AMBO',
      'TOLAY LIMU ENARIA': 'WAYU-TOLAY-LIMU ENARYA',
      'TOLAY (WAYU) LIMU ANARIYA': 'WAYU-TOLAY-LIMU ENARYA',
      'TATEK-CHAFE BULUK': 'TATEK-CHEFE BULIK',
      'WORETA MEGENAGNA': 'WORETA-MEGENAGNA',
      'SHASHEMENE-RAYA WEKANE': 'SHASHEMENE-RAYA WAKENA',
      'SHASHEMENE-DEBUBE FEDERATION': 'SHASHEMENE-DEBUB FEDERATION',
      'SEKORU-JIMMA': 'SOKORU-JIMMA UNION',
      'SERBO-JIMMA': 'SERBO-JIMMA UNION',
      'SAWULA ESPI DICHA': 'SAWLA-ESEPEDICHA',
      'NIFAS MEWECHA-RAS GAYET': 'NEFAS MEWECHA-RAS GAYINT',
      'MUKA TURI-BIFTU SELALE': 'MUKETURI-BIFTU SELALE',
      'MERTO LEMARYAM MOTA': 'MERTO LEMARIAM-MOTA',
      'LIMU GENET-LIMU ENARIA': 'LIMUGENET-LIMU ENARYA',
      'KOMBOLCHA-WEDERA': 'KOMBOLCHA-WODERA',
      'KILAJ ADMAS': 'KILAJ-ADMAS',
      'KERANEYO-MOTA': 'KERANIO-MOTA',
      'HOSANA-LICHA UNION': 'HOSAENA-LICHA',
      'HAWASA-SIDAMA ELTO': 'HAWASA-SIDAMA ELITO',
      'HAMUSIT MEGENAGNA': 'HAMUSIT-MEGENAGNA',
      'GURSUM-AFERENKELO': 'GURSUM-AFREN KALO',
      'GINDEWAYIN-MOTA': 'GINDE WOYIN-MOTA',
      'FINOTESELAM-DAMOT': 'FINOTE SELAM-DAMOT',
      'DOYOGENA ANGACHA': 'DOYOGENA-ANGACHA',
      'DODOLA-RAYA WEKANE': 'DODOLLA-RAYA WAKENA',
      'DENBECHA-DAMOT': 'DEMBECHA-DAMOT',
      'MUKETURI-hBIFTU SELALE': 'MUKETURI-BIFTU SELALE',
      'DEDER-AFRERENKELO': 'DEDER-AFREN KALO',
      'DEBRE BIRHAN-WEDERA': 'DEBRE BIRHAN-WODERA',
      'CHIRO-CHERCHER ODA BULTUM': 'CHIRO-CHERICHER ODA BULTUM',
      'BISHOFTU ERRER': 'BISHOFTU-ERRER',
      'BEDELE-BUNO BEDELE': 'BEDELLE-BUNO BEDELLE',
      'BALCHIT KESEM': 'BALCHI-KESEM',
      'BAHIR DAR-MEREKEB': 'BAHIR DAR-MERKEB',
      'ARBEMINCH GAMGOFA': 'ARBAMINCH-GAMO GOFA',
      'ALI SEKOMANDO': 'ALI-SIKOMANDO',
      'ALEMGENA-MELKA AWASH': 'ALEMGENA-MELIKA AWASH',
      'ADAMA-DEBUB FEDR': 'ADAMA-DEBUB FEDERATION',
      'ADAMA EABC WAREHOUSE': 'ADAMA-EABC WAREHOUSE',
      'WALAITA SODO-DAMOT': 'SODO-DAMOTA',
      'WOLYITA SODO-DAMOT': 'SODO-DAMOTA',
      'DENABA-JIMMA UNION': 'DENEBA-JIMMA UNION',
      'MEKANE EYESUS/ESTE RAS GAYINT': 'ESTE-RAS GAYINT',
      'ENBSE/M/LEMARIAM MOTA': 'MERTO LEMARIAM-MOTA',
      'BAHAR DAR EGISCO': 'BAHIR DAR-EABC WAREHOUSE',
      'ARBAMINCH GAMO ETFRUIT': 'ARBAMINCH-GAMO GOFA',
      'ADDIS ABABA-AISE': 'ADDIS ABABA-EABC WAREHOUSE',
      'ADAMA EGISCO WH': 'ADAMA-EABC WAREHOUSE',
      'A-A EGISCO WH': 'ADDIS ABABA-EABC WAREHOUSE',
      'TATEK HARAGU': 'TATEK-HARAGU',
      'ABRAMINCH-GAMO GOFA': 'ARBAMINCH-GAMO GOFA',
      'ALEMGENA BIFTU SELAL': 'ALEMGENA-BIFTU SELALE',
      'CHIRO BUREKA GALATI': 'CHIRO-BUREKA GALATI',
      'DESSIE KTEMA ERIKUM': 'DESSIE-DESSIE KTEMA',
      'HALABA AMBRICHO': 'HALABA-AMBRICHO',
      'HAROMAYA-AFERENKELO': 'HAROMAYA-AFREN KALO',
      'HAROMIA-AFREN KALO': 'HAROMAYA-AFREN KALO',
      'HAWASSA DEBUB': 'HAWASSA-DEBUB',
      'HAWASSA DEBUB FED.': 'HAWASSA-DEBUB',
      'HIRENA BUREKA GALATI': 'HIRENA-BUREKA GALATI'
    }
    //console.log(dataRows)
    //console.assert(context.planList.length == 1)
    let plan = context.planList[1]
    let planId = plan.planId
    var mapHeadersToIdx = {}
    var adamats = whMap['Adama-Train Station']

    for (var idx = 0; idx < dataRows.length; idx++) {

      let row = dataRows[idx]

      if (idx == 0) {
        row.map((rowItem, innerIdx) => {
          mapHeadersToIdx[innerIdx] = rowItem
        })
        //return null 
      } else if (row.length == 1) {
        currentName = row[0]
      } else if (row[0] == "S.NO") {
        console.log('headerskip[')
      } else {
        var fullValue = {}
        let newRow = row.map((rowItem, innerIdx) => {
          let header = mapHeadersToIdx[innerIdx]
          let newKey = parseDict[header]
          var newVal = rowItem
          //console.log(newVal)
          if (newKey == 'vn_for_map') {
            console.log(vessel_names_to_ops)
            console.log(newVal)
            console.assert(newVal in vessel_names_to_ops, 'missing vessel name ' + newVal)
            newVal = vessel_names_to_ops[newVal]
            console.assert(newVal in opMap, 'missing from op map ' + newVal)
            newVal = opMap[newVal]

          } else if (newKey == 'dispatchdate') {
            fullValue['baseTimeKey'] = newVal
            //console.log(typeof(newVal))
            if (typeof(newVal) == 'string') {
              newVal = moment(newVal, 'YYYY-MM-DD').toDate().getTime() + idx
              //console.log('do change time to ' + newVal)
            } else {
              newVal = new Date(Math.round( ((newVal - 25569)*86400*1000) + (1000 * 3600 * 12) + (idx) ) )
              newVal = newVal.getTime()
              newVal = moment(newVal).format('MM/DD/YYYY')
              fullValue['rlfTime'] = newVal
              newVal = moment(newVal, 'DD/MM/YYYY').toDate().getTime() + idx

            }


          } else if (newKey == 'dst_for_map') {
            var keyTarget = newVal.replace(' - ', '-').toUpperCase()
            if (keyTarget.includes('MUKETURI-') && keyTarget.includes('BIFTU SELALE')) {
              alert(keyTarget)
            }
            if (keyTarget != 'DATA IMPORTED') {
              if (keyTarget in wh_names_remap) {
                keyTarget = wh_names_remap[keyTarget]
              }
              console.assert(keyTarget in whMap, 'missing wh loc ' + keyTarget)
              if (!(keyTarget in whMap)) {
                if (keyTarget in missingWh) {
                  missingWh[keyTarget] += 1
                } else {
                  missingWh[keyTarget] = 1
                }

              }
              newVal = whMap[keyTarget]
            }

          } else if (newKey == 'wag_or_wh') {
            let lcnv = newVal.toLowerCase()
            if (lcnv.includes('wh')) {
              fullValue['fromWarehouse'] = true 
            }
          }

          


          fullValue[newKey] = newVal
          //rowxAll.push(fullValue)
        })

        if (fullValue['dst_for_map'] && fullValue['dst_for_map'] != 'DATA IMPORTED') {

            //console.log(fullValue)
            var splitProc = fullValue['import_seq_wb'].split('B3760')
            var endProc = splitProc[1]
            var hyphenProc = endProc.split('-')
            fullValue['old_wbn'] = fullValue['import_seq_wb']
            fullValue['import_seq_wb'] = parseInt(hyphenProc[0])
            fullValue['hardcode_usercode_wb'] = hyphenProc[1]

            fullValue['FROM_IMPORT'] = true 
    
            fullValue['creationDate'] = fullValue['dispatchdate']
            //fullValue['prepared'] = 'Leyla Mohammed'
            fullValue['averagebagweight'] = 50
            fullValue['dlnumber'] = ''
            fullValue['driverphone'] = ''
            fullValue['netweightkg'] = fullValue['averagebagweight'] * parseInt(fullValue['numberofbags'])
            fullValue['finalweightkg'] = fullValue['netweightkg']
            fullValue['operation'] = fullValue['vn_for_map']
            fullValue['warehouse'] = fullValue['dst_for_map']
            fullValue['consignee'] = 'ETHIOPIAN AGRICULTURAL BUSINESS CORPORATION'
            fullValue['unitprice'] = 0
            fullValue['sku'] = sku 
            fullValue['planId'] = planId
            let innerPlanId = getInnerPlan(fullValue['warehouse'],
            fullValue['sku'], planId, context)
            fullValue['innerPlanId'] = innerPlanId
            fullValue['isFinal'] = true 
            console.assert(planId != null && innerPlanId != null, 'found some nulls ' + planId + ' ' + innerPlanId)
            
            
    
            rowxAll.push(fullValue) 


        }


      }
    }


    var num_only = rowxAll.filter((item, idx) => {
      return item.import_seq_wb != "AD0018B" && item.import_seq_wb != "AD0125B"
    })
    /*var let_only = rowxAll.filter((item, idx) => {
      return item.import_seq_wb == "AD0018B"
    })
    
    var bllret = let_only[0]
    num_only.push(bllret)*/
    //console.log(weightSum)
    //console.log(tareSum)
    
    return num_only
  }


  function doConversion(dataRowsX, opMap, trainMap, whMap, sku, adama, context, planIdx) {

    var dataRows = dataRowsX

    /*if (planIdx == 1) {
    dataRows = dataRows.filter((itemx, idx) =>{
      return idx > 533
    })
    } else if (planIdx == 0) {
      dataRows = dataRows.filter((itemx, idx) =>{
        return idx > 1017
      })
    }*/
    dataRows = dataRows.filter((itemx, idx) =>{
      return idx > 694
    })
    console.log('SHOW DATAROWS HERE@@@')
    console.log(dataRows)

    var headers = dataRows[1]
    var rowxAll = []
    var currentName = null 

    var sqd = {}

    const vessel_names_to_ops = {'MV DRAFTZILLA': 'DRAFT ZILLA, V2201', 'DRAFTZILLA': 'DRAFT ZILLA, V2201',
    'MV ERIKOUSSA': 'ERIKOUSSA VOY 27L',
    'ERIKOUSSA': 'ERIKOUSSA VOY 27L',
    'MV VIRGO CONFIDENCE': 'MV VIRGO CONFIDENCE',
    'VIRGO CONFIDENCE': 'MV VIRGO CONFIDENCE',
  'MO GAN SHAN': 'MO GAN SHAN V2201',
'MV MO GAN SHAN': 'MO GAN SHAN V2201',
'MV YASA SATURN': 'MV YASA SATURN',
'MV ULTRA INTIATOR': 'MV ULTRA INITIATOR',
'MV ENDURO TRADER': 'ENDURO TRADER VO15'}

    const tn_names_to_trains = {
      '2ND TRAIN WAGON 37  , VESSEL NAME - M.V DRAFTZILLA ,  OPERATION NO 7411-1155 DECLARATION NO 4-001465/ T4905  FERTILIZER NPSB': 'MV DRAFTZILLA 2ND TRAIN',
      '4TH TRAIN WAGON 37  , VESSEL NAME - M.V DRAFTZILLA ,  OPERATION NO 7411-1155 DECLARATION NO 4-001465/ T4905  FERTILIZER NPSB': 'MV DRAFTZILLA 4TH TRAIN',
      '6TH TRAIN WAGON 37  , VESSEL NAME - M.V VIRGO CONFIDENCE ,  OPERATION NO 7411-1168 DECLARATION NO 4-001942/ T  FERTILIZER NPSB': 'MV VIRGO CONFIDENCE 6TH TRAIN',
      '8TH TRAIN WAGON 37  , VESSEL NAME - M.V VIRGO CONFIDENCE ,  OPERATION NO 7411-1168 DECLARATION NO 4-001942/ T6227 / T4905  FERTILIZER NPSB': 'MV VIRGO CONFIDENCE 8TH TRAIN',
      '10TH TRAIN WAGON 37  , VESSEL NAME - M.V ERIKOUSSA ,  OPERATION NO 7411-1181 DECLARATION NO 4-002340/ T7548  FERTILIZER NPSB': 'ERIKOUSSA VOY 27L 10TH TRAIN',
      '12TH TRAIN WAGON 37  , VESSEL NAME - M.V ERIKOUSSA ,  OPERATION NO 7411-1181 DECLARATION NO 4-002340/ T7548  FERTILIZER NPSB': 'ERIKOUSSA VOY 27L 12TH TRAIN',
      '14TH TRAIN WAGON 37  , VESSEL NAME - M.V ERIKOUSSA ,  OPERATION NO 7411-1181 DECLARATION NO 4-002340/ T7548  FERTILIZER NPSB': 'MV ERIKOUSSA VOY 27L 14TH TRAIN',
      '16TH TRAIN WAGON 37  , VESSEL NAME - M.V MO GAN SHAN,  OPERATION NO 7411-1191 DECLARATION NO 4-002988/ T10010  FERTILIZER NPSB': 'MO GAN SHAN 16TH TRAIN',
      '18TH TRAIN WAGON 37  , VESSEL NAME - M.V YASA SATURN ,  OPERATION NO 7411-1212 DECLARATION NO 4-3273/ T10688  WHEAT': 'MV YASA SATURN 18th Train',
      '17TH TRAIN WAGON 37  , VESSEL NAME - M.V YASA SATURN ,  OPERATION NO 7411-1212 DECLARATION NO 4-3273/ T10688  WHEAT':  'MV YASA SATURN 17th Train',
    '20TH TRAIN WAGON 37  , VESSEL NAME - M.V YASA SATURN ,  OPERATION NO 7411-1212 DECLARATION NO 4-3273/ T10688  WHEAT': ' MV YASA SATURN 20TH TRAIN',
    '21TH TRAIN WAGON 37  , VESSEL NAME - M.V YASA SATURN ,  OPERATION NO 7411-1212 DECLARATION NO 4-3273/ T10688  WHEAT': ' MV YASA SATURN 21ST TRAIN',
  '19TH TRAIN WAGON 37  , VESSEL NAME - M.V YASA SATURN ,  OPERATION NO 7411-1212 DECLARATION NO 4-3273/ T10688  WHEAT': 'MV YASA SATURN 19th Train',
  '21TH TRAIN WAGON 37  , VESSEL NAME - M.V ULTRA INTIATOR ,  OPERATION NO 7411-1231 DECLARATION NO 4-4035/ T13659  FERTILIZER NPS': 'MV ULTRA INITIATOR 21ST TRAIN',
  '20TH TRAIN WAGON 37  , VESSEL NAME - M.V ULTRA INTIATOR ,  OPERATION NO 7411-1231 DECLARATION NO 4-4035/ T13659  FERTILIZER NPS': 'MV ULTRA INITIATOR 20ST TRAIN',
  '25TH TRAIN WAGON 37  , VESSEL NAME - M.V ULTRA INTIATOR ,  OPERATION NO 7411-1231 DECLARATION NO 4-4035/ T13659  FERTILIZER NPS': 'MV ULTRA INITIATOR 25TH TRAIN',
  '27TH TRAIN WAGON 37  , VESSEL NAME - M.V ENDURO TRADER ,  OPERATION NO 7411-1227 DECLARATION NO 4-3882/ T13645  FERTILIZER NPSB': 'MV ENDURO TRADER 27TH TRAIN'
  }

    const wh_names_remap = {
      'HAWASA-ELITO': 'HAWASA-SIDAMA ELITO',
      'MODJO-EABC': 'MODJO-EABC WAREHOUSE',
      'ASELA-EABC': 'ASELA-EABC WAREHOUSE',
      'ADAMA-EABC': 'ADAMA-EABC WAREHOUSE',
      'ADAMA-EABC ': 'ADAMA-EABC WAREHOUSE',
      'ADAMA-DADEA': 'ADAMA-DEDEA',
      'ADAMA-ARBAGUGU': 'ADAMA-ARBA GUGU',
      'WOLAITA-DAMOT': 'BODITI-DAMOTA',
      'ROBE-SECOMANDO': 'ROBE-SIKOMANDO',
      'KARAT KONSO': 'KONSO-MILTAKUTICIANA',
      'DOYO GENA-ANGACHA': 'DOYOGENA-ANGACHA',
      'SHMASHEMENE-RAYA WAKENA': 'SHASHEMENE-RAYA WAKENA',
      'BALCH-KESEM': 'BALCHI-KESEM',
      'HOSANA-LICHA': 'HOSAENA-LICHA',
      'KONSO-MILIYATA KUTISIYANA': 'KONSO-MILTAKUTICIANA',
      'BISHOFTU-ERER': 'BISHOFTU-ERRER',
      'KARAT-KONSO': 'KONSO-MILTAKUTICIANA',
      'WOLAITA-DAMOT': 'SODO-DAMOTA',
      'WOLAITA-DAMOT ': 'SODO-DAMOTA',
      'GALEMA': 'ADAMA-GALEMA',
      'BORE-MEO BAKO': 'BORE-MI E BOKO',
      'ADAMA': 'ADAMA-ETBC WAREHOUSE',
      'ADAMA ': 'ADAMA-ETBC WAREHOUSE',
      'DODOLA-RAYA WAKENA': 'DODOLLA-RAYA WAKENA',
      'BALCHI-KESEM ': 'BALCHI-KESEM'
    }
    console.log(context.planList.length)
    //console.assert(context.planList.length == 1)
    let plan = context.planList[1]
    let planId = plan.planId
    var mapHeadersToIdx = {}
    var adamats = whMap['Adama-Train Station']

    for (var idx = 0; idx < dataRows.length; idx++) {

      let row = dataRows[idx]
      //console.log(row)
      if (idx == 1) {
        row.map((rowItem, innerIdx) => {
          mapHeadersToIdx[innerIdx] = rowItem
        })
        mapHeadersToIdx[13] = 'ER NO'
        //return null 
      } else if (row.length == 1) {
        currentName = row[0]
      } else if (row[0] == "S.NO") {
        console.log('headerskip[')
      } else {
        var fullValue = {}
        let newRow = row.map((rowItem, innerIdx) => {
          let header = mapHeadersToIdx[innerIdx]
          let newKey = parseDict[header]
          var newVal = rowItem
          //console.log(newVal)
          if (newKey == 'vn_for_map') {
            console.assert(newVal in vessel_names_to_ops, 'missing vessel name ' + newVal)
            newVal = vessel_names_to_ops[newVal]
            console.assert(newVal in opMap, 'missing from op map ' + newVal)
            newVal = opMap[newVal]

          } else if (newKey == 'dispatchdate') {
            fullValue['baseTimeKey'] = newVal
            //console.log(typeof(newVal))
            if (typeof(newVal) == 'string') {
              newVal = moment(newVal, 'DD/MM/YYYY').toDate().getTime() + idx
              //console.log('do change time to ' + newVal)
            } else {
              newVal = new Date(Math.round( ((newVal - 25569)*86400*1000) + (1000 * 3600 * 12) + (idx) ) )
              newVal = newVal.getTime()
              newVal = moment(newVal).format('MM/DD/YYYY')
              fullValue['rlfTime'] = newVal
              newVal = moment(newVal, 'DD/MM/YYYY').toDate().getTime() + idx

            }


          } else if (newKey == 'dst_for_map') {
            var keyTarget = newVal.replace(' - ', '-')
            keyTarget = keyTarget.toUpperCase()
            if (keyTarget in wh_names_remap) {
              keyTarget = wh_names_remap[keyTarget]
            }
            //console.log(keyTarget)
            //console.log(whMap)
            //console.log(wh_names_remap)
            console.assert(keyTarget in whMap, 'missing wh loc ' + keyTarget + ' and upper is ' + keyTarget.toUpperCase())
            newVal = whMap[keyTarget]
          } else if (newKey == 'wag_or_wh') {
            let lcnv = newVal.toLowerCase()
            if (lcnv.includes('wh')) {
              fullValue['fromWarehouse'] = true 
            }
          } else if (newKey == 'cargo_for_map') {
            let target = null
            var skuOptions = getAutoOptions(context, 'sku').filter((item, idx) => {
              if (item.name == newVal) {
                target = item 
              }
            })
            console.assert(target != null, 'target was found to be null ' + newKey + ' and ' + newVal)
            fullValue['sku'] = target 
          }

          


          fullValue[newKey] = newVal
          //rowxAll.push(fullValue)
        })
      

        console.assert(currentName != null, 'current train name is null')
        console.assert(currentName in tn_names_to_trains, 'missing train name mapping ' + currentName)
        var trainNameMap = tn_names_to_trains[currentName]
        //console.log(trainMap)
        console.assert(trainNameMap in trainMap, 'missing actual trian name out of actual train map ' + trainNameMap)

        fullValue['creationDate'] = fullValue['dispatchdate']
        fullValue['train'] = trainMap[trainNameMap]
        fullValue['prepared'] = 'Leyla Mohammed'
        fullValue['averagebagweight'] = 50
        fullValue['dlnumber'] = ''
        fullValue['driverphone'] = ''
        fullValue['netweightkg'] = fullValue['averagebagweight'] * parseInt(fullValue['numberofbags'])
        fullValue['finalweightkg'] = fullValue['netweightkg']
        fullValue['operation'] = fullValue['vn_for_map']
        fullValue['warehouse'] = fullValue['dst_for_map']
        fullValue['consignee'] = 'ETHIOPIAN AGRICULTURAL BUSINESS CORPORATION'
        fullValue['unitprice'] = 0
        //fullValue['sku'] = sku 
        fullValue['planId'] = planId
        let innerPlanId = getInnerPlan(fullValue['warehouse'],
        fullValue['sku'], planId, context)
        fullValue['innerPlanId'] = innerPlanId
        fullValue['isFinal'] = true 
        //console.log(fullValue)
        //console.log(row)
        console.assert(planId != null && innerPlanId != null, 'found some nulls ' + planId + ' ' + innerPlanId)
        
        console.assert(fullValue['creationDate'] !== undefined, 'but creation date is undefined ')
        console.assert(fullValue['operation'] !== undefined, 'but operation is undefined')

        if (fullValue['fromWarehouse']) {
          fullValue['source'] = adama
          //fullValue['source'] = fullValue['warehouse']
        } else {
          fullValue['source'] = fullValue['train']
        }

        if (fullValue.import_seq_wb in sqd) {
          fullValue['import_seq_wb'] = fullValue.import_seq_wb + 'B'
        }
        sqd[fullValue.import_seq_wb] = 1


        rowxAll.push(fullValue) 
      }
    }

    console.log('whole show')
    console.log(rowxAll)
    console.log(dataRows)
    var num_only = rowxAll.filter((item, idx) => {
      //console.log(item)
      let xfa = item.import_seq_wb.replace('AD', '').replace('B','')
      let intx = parseInt(xfa)

      return item.import_seq_wb != "AD0018B" && item.import_seq_wb != "AD0125B" && xfa > 380
    })
    /*var let_only = rowxAll.filter((item, idx) => {
      return item.import_seq_wb == "AD0018B"
    })
    
    var bllret = let_only[0]
    num_only.push(bllret)*/
    //console.log(weightSum)
    //console.log(tareSum)
    
    return num_only
  }

  function doConversionIndode(dataRowsX, opMap, trainMap, whMap, sku, adama, context) {

    var dataRows = dataRowsX

    /*dataRows = dataRows.filter((itemx, idx) =>{
      return idx > 821
    })*/
    console.log(dataRows)

    var headers = dataRows[1]
    var rowxAll = []
    var currentName = null 

    const vessel_names_to_ops = {'MV DRAFTZILLA': 'DRAFT ZILLA, V2201', 'DRAFTZILLA': 'DRAFT ZILLA, V2201',
    'MV ERIKOUSSA': 'ERIKOUSSA VOY 27L',
    'ERIKOUSSA': 'ERIKOUSSA VOY 27L',
    'MV VIRGO CONFIDENCE': 'MV VIRGO CONFIDENCE',
    'VIRGO CONFIDENCE': 'MV VIRGO CONFIDENCE',
  'MO GAN SHAN': 'MO GAN SHAN V2201',
'MV MO GAN SHAN': 'MO GAN SHAN V2201',
'MV YASA SATURN': 'MV YASA SATURN',
'MV ERIKOUSSA(18)': 'ERIKOUSSA VOY 27L',
'MV ERIKOUSSA(19)':  'ERIKOUSSA VOY 27L',
'MV ERIKOUSSA(15)': 'ERIKOUSSA VOY 27L',
'MV VIRGO CONFIDENCE (18)': 'MV VIRGO CONFIDENCE'
}

    const tn_names_to_trains = {
      'MV ERIKOUSSA(18)': 'MV ERIKOUSSA 18TH TRAIN',
'MV ERIKOUSSA(19)':  'MV ERIKOUSSA 19TH TRAIN',
'MV ERIKOUSSA(15)': 'MV ERIKOUSSA 15TH TRAIN',
'MV VIRGO CONFIDENCE (18)': 'MV VIRGO CONFIDENCE 18TH TRAIN',
      '2ND TRAIN WAGON 37  , VESSEL NAME - M.V DRAFTZILLA ,  OPERATION NO 7411-1155 DECLARATION NO 4-001465/ T4905  FERTILIZER NPSB': 'MV DRAFTZILLA 2ND TRAIN',
      '4TH TRAIN WAGON 37  , VESSEL NAME - M.V DRAFTZILLA ,  OPERATION NO 7411-1155 DECLARATION NO 4-001465/ T4905  FERTILIZER NPSB': 'MV DRAFTZILLA 4TH TRAIN',
      '6TH TRAIN WAGON 37  , VESSEL NAME - M.V VIRGO CONFIDENCE ,  OPERATION NO 7411-1168 DECLARATION NO 4-001942/ T  FERTILIZER NPSB': 'MV VIRGO CONFIDENCE 6TH TRAIN',
      '8TH TRAIN WAGON 37  , VESSEL NAME - M.V VIRGO CONFIDENCE ,  OPERATION NO 7411-1168 DECLARATION NO 4-001942/ T6227 / T4905  FERTILIZER NPSB': 'MV VIRGO CONFIDENCE 8TH TRAIN',
      '10TH TRAIN WAGON 37  , VESSEL NAME - M.V ERIKOUSSA ,  OPERATION NO 7411-1181 DECLARATION NO 4-002340/ T7548  FERTILIZER NPSB': 'ERIKOUSSA VOY 27L 10TH TRAIN',
      '12TH TRAIN WAGON 37  , VESSEL NAME - M.V ERIKOUSSA ,  OPERATION NO 7411-1181 DECLARATION NO 4-002340/ T7548  FERTILIZER NPSB': 'ERIKOUSSA VOY 27L 12TH TRAIN',
      '14TH TRAIN WAGON 37  , VESSEL NAME - M.V ERIKOUSSA ,  OPERATION NO 7411-1181 DECLARATION NO 4-002340/ T7548  FERTILIZER NPSB': 'MV ERIKOUSSA VOY 27L 14TH TRAIN',
      '16TH TRAIN WAGON 37  , VESSEL NAME - M.V MO GAN SHAN,  OPERATION NO 7411-1191 DECLARATION NO 4-002988/ T10010  FERTILIZER NPSB': 'MO GAN SHAN 16TH TRAIN',
      '18TH TRAIN WAGON 37  , VESSEL NAME - M.V YASA SATURN ,  OPERATION NO 7411-1212 DECLARATION NO 4-3273/ T10688  WHEAT': 'MV YASA SATURN 18th Train',
      '17TH TRAIN WAGON 37  , VESSEL NAME - M.V YASA SATURN ,  OPERATION NO 7411-1212 DECLARATION NO 4-3273/ T10688  WHEAT':  'MV YASA SATURN 17th Train'
    }

    const wh_names_remap = {
      'HAWASA-ELITO': 'HAWASA-SIDAMA ELITO',
      'MODJO-EABC': 'MODJO-EABC WAREHOUSE',
      'ASELA-EABC': 'ASELA-EABC WAREHOUSE',
      'ADAMA-EABC': 'ADAMA-EABC WAREHOUSE',
      'ADAMA-DADEA': 'ADAMA-DEDEA',
      'ADAMA-ARBAGUGU': 'ADAMA-ARBA GUGU',
      'WOLAITA-DAMOT': 'BODITI-DAMOTA',
      'ROBE-SECOMANDO': 'ROBE-SIKOMANDO',
      'KARAT KONSO': 'KONSO-MILTAKUTICIANA',
      'DOYO GENA-ANGACHA': 'DOYOGENA-ANGACHA',
      'SHMASHEMENE-RAYA WAKENA': 'SHASHEMENE-RAYA WAKENA',
      'BALCH-KESEM': 'BALCHI-KESEM',
      'HOSANA-LICHA': 'HOSAENA-LICHA',
      'KONSO-MILIYATA KUTISIYANA': 'KONSO-MILTAKUTICIANA',
      'BISHOFTU-ERER': 'BISHOFTU-ERRER',
      'KARAT-KONSO': 'KONSO-MILTAKUTICIANA',
      'WOLAITA-DAMOT': 'SODO-DAMOTA',
      'WOLAITA-DAMOT ': 'SODO-DAMOTA',
      'GALEMA': 'ADAMA-GALEMA',
      'BORE-MEO BAKO': 'BORE-MI E BOKO',
      'ADAMA': 'ADAMA-ETBC WAREHOUSE',
      'ADAMA ': 'ADAMA-ETBC WAREHOUSE',
      'NEKEMTE-GIBE DEDESA': 'NEKEMT-GIBE DEDESA',
      'DEMBI DOLO-TORBAN ANEFLO': 'DENBI DELO-TORBAN ANFILO',
      'D/MARKOS-GOZAMEN': 'DEBRE MARKOS-GOZAMIN',
      'MIZAN TEFERI-BENCHI MAJI': 'MIZAN TEFERI-BENCH ANDINET',

    }
    console.log(context.planList.length)
    console.log(context.planList)
    console.assert(context.planList.length == 1)
    let plan = context.planList[1]
    let planId = plan.planId
    var mapHeadersToIdx = {}
    var adamats = whMap['Adama-Train Station']

    for (var idx = 0; idx < dataRows.length; idx++) {

      let row = dataRows[idx]
      //console.log(row)
      if (idx == 1) {
        row.map((rowItem, innerIdx) => {
          mapHeadersToIdx[innerIdx] = rowItem
        })
        //mapHeadersToIdx[13] = 'ER NO'
        //return null 
      } else if (row.length < 10) {
        console.log('topskip')
      } else if (row[0] == "SN") {
        console.log('headerskip[')
      } else {
        var fullValue = {}
        let newRow = row.map((rowItem, innerIdx) => {
          let header = mapHeadersToIdx[innerIdx]
          let newKey = parseDictIndode[header]
          var newVal = rowItem
          //console.log(newVal)
          if (newKey == 'vn_for_map') {

            var currentName = newVal
            console.assert(currentName != null, 'current train name is null')
            console.assert(currentName in tn_names_to_trains, 'missing train name mapping ' + currentName)
            var trainNameMap = tn_names_to_trains[currentName]
            console.assert(trainNameMap in trainMap, 'missing actual trian name out of actual train map ' + trainNameMap)
    

            fullValue['train'] = trainMap[trainNameMap]

            console.assert(newVal in vessel_names_to_ops, 'missing vessel name ' + newVal)
            newVal = vessel_names_to_ops[newVal]
            console.assert(newVal in opMap, 'missing from op map ' + newVal)
            newVal = opMap[newVal]



          } else if (newKey == 'dispatchdate') {
            fullValue['baseTimeKey'] = newVal
            //console.log(typeof(newVal))
            if (typeof(newVal) == 'string') {
              newVal = moment(newVal, 'DD/MM/YYYY').toDate().getTime() + idx
              //console.log('do change time to ' + newVal)
            } else {
              newVal = new Date(Math.round( ((newVal - 25569)*86400*1000) + (1000 * 3600 * 12) + (idx) ) )
              newVal = newVal.getTime()
              newVal = moment(newVal).format('MM/DD/YYYY')
              fullValue['rlfTime'] = newVal
              newVal = moment(newVal, 'DD/MM/YYYY').toDate().getTime() + idx

            }


          } else if (newKey == 'dst_for_map') {
            var keyTarget = newVal.replace(' - ', '-')
            keyTarget = keyTarget.toUpperCase()
            if (keyTarget in wh_names_remap) {
              keyTarget = wh_names_remap[keyTarget]
            }
            console.assert(keyTarget in whMap, 'missing wh loc ' + keyTarget + ' and upper is ' + keyTarget.toUpperCase())
            newVal = whMap[keyTarget]
          } else if (newKey == 'wag_or_wh') {
            let lcnv = newVal.toLowerCase()
            if (lcnv.includes('wh')) {
              fullValue['fromWarehouse'] = true 
            }
          }

          


          fullValue[newKey] = newVal
          //rowxAll.push(fullValue)
        })
      


        fullValue['creationDate'] = fullValue['dispatchdate']

        fullValue['prepared'] = 'Menen'
        fullValue['averagebagweight'] = 50
        fullValue['dlnumber'] = ''
        fullValue['driverphone'] = ''
        fullValue['netweightkg'] = fullValue['averagebagweight'] * parseInt(fullValue['numberofbags'])
        fullValue['finalweightkg'] = fullValue['netweightkg']
        fullValue['operation'] = fullValue['vn_for_map']
        fullValue['warehouse'] = fullValue['dst_for_map']
        fullValue['consignee'] = 'ETHIOPIAN AGRICULTURAL BUSINESS CORPORATION'
        fullValue['unitprice'] = 0
        fullValue['sku'] = sku 
        fullValue['planId'] = planId
        let innerPlanId = getInnerPlan(fullValue['warehouse'],
        fullValue['sku'], planId, context)
        fullValue['innerPlanId'] = innerPlanId
        fullValue['isFinal'] = true 
        //console.log(fullValue)
        //console.log(row)
        console.assert(planId != null && innerPlanId != null, 'found some nulls ' + planId + ' ' + innerPlanId)
        
        
        if (fullValue['fromWarehouse']) {
          fullValue['source'] = adama
          //fullValue['source'] = fullValue['warehouse']
        } else {
          fullValue['source'] = fullValue['train']
        }

        rowxAll.push(fullValue) 
      }
    }

    return rowxAll

    console.log(rowxAll)
    var num_only = rowxAll.filter((item, idx) => {

      let xfa = item.import_seq_wb.replace('AD', '').replace('B','')
      let intx = parseInt(xfa)

      return item.import_seq_wb != "AD0018B" && item.import_seq_wb != "AD0125B" && xfa > 380
    })
    /*var let_only = rowxAll.filter((item, idx) => {
      return item.import_seq_wb == "AD0018B"
    })
    
    var bllret = let_only[0]
    num_only.push(bllret)*/
    //console.log(weightSum)
    //console.log(tareSum)
    
    return num_only
  }

  async function doCreationTransfer(newRows, context) {
   
    const delay = ms => new Promise(res => setTimeout(res, ms));
    console.log('HERE NR')
    console.log(newRows)
    for (var i = 0; i < newRows.length; i++) {
      let targetRow = newRows[i]
      var promiseArray = []
      var promiseArrayTwo = []
      //console.log(targetRow.import_seq_wb)
      var allowCont = true
      //console.log(targetRow)
      if (targetRow == undefined) {
        console.log('fucking undefind')

      }
      var intTarg = targetRow.import_seq_wb//parseInt(targetRow.import_seq_wb.toLowerCase().replace('in', '').replace('ad', '').replace('b', ''))
      console.log(intTarg)
      if ( targetRow != null && allowCont && intTarg > 2 && intTarg >= 20) {
        //console.log(targetRow)
        console.log(targetRow)
        
       /* var res = null 
        if (targetRow.fromWarehouse) {
        res = await doSubmitWarhouse(targetRow, context)
        } else {
        res = await doSubmitTrain(targetRow, context)
        }
        console.log(res)
        if (res && res.transaction == null && res.failMessage) {
          allowCont = false
          alert('ERRRRROR')
          alert(res.failMessage.msg)
        }*/




        /*var res = null 
        res = await doSubmitVessel(targetRow, context)
        console.log(res)
        if (res && res.transaction == null && res.failMessage) {
          allowCont = false
          alert('ERRRRROR')
          alert(res.failMessage.msg)
        
        }

        if (i > 1 && i % 50 == 0) {
          console.log('sleep for 5s at ' + i)
          await delay(5000)
        }*/

    }
  }
}



  function getAutoOptions(context, k) {
    if (k in context.barleyInCustomObjects) {
      return Object.keys(context.barleyInCustomObjects[k]).map((key, id) => {
        return context.barleyInCustomObjects[k][key]
      })
    }
    return []
  }
  
  function printSort(ax) {
    var dict = {}
    console.log(ax)
    ax.map((item, idx) => {
      dict[item.import_seq_wb] = item.import_seq_wb
    })
    console.log(dict)
    return dict 
  }

  function lcNoAd(s) {
    var s2 = s.toLowerCase()
    s2 = s2.replace('ad', '')
    s2 = s2.replace('in', '')
    return s2
  }

  
  function compare2Mojo(a, b) {
        
    let iA = a.import_seq_wb
    let iB = b.import_seq_wb
    /*console.log(a)
    console.log(b)
    var lcA = lcNoAd(aa)
    var lcB = lcNoAd(bb) 

    if (lcA.includes('b')) {
      return 1
    }
    if (lcB.includes('b')) {
      return -1
    }

    let iA = parseInt(lcA)
    let iB = parseInt(lcB) */
    if (iA > iB) {
      return 1
    } else if (iB > iA) {
      return -1
    } else {
      alert('WRONGGGGGGGGGGGGGGGGGGGGGGGGGGGGGg')
      console.log(iA)
      console.log(iB)
      console.log(a)
      console.log(b)
      throw "EWEJFIAWJGWEIJ"
      //throw new Exception('WRONGGGGGGGGGGGGGGGGGGg')
    }

    }

  function compare2(a, b) {
        
    let aa = a.import_seq_wb
    let bb = b.import_seq_wb
    var lcA = lcNoAd(aa)
    var lcB = lcNoAd(bb) 

    if (lcA.includes('b')) {
      return 1
    }
    if (lcB.includes('b')) {
      return -1
    }

    let iA = parseInt(lcA)
    let iB = parseInt(lcB) 
    if (iA > iB) {
      return 1
    } else if (iB > iA) {
      return -1
    } else {
      alert('WRONGGGGGGGGGGGGGGGGGGGGGGGGGGGGGg')
      console.log(iA)
      console.log(iB)
      console.log(a)
      console.log(b)
      throw "EWEJFIAWJGWEIJ"
      //throw new Exception('WRONGGGGGGGGGGGGGGGGGGg')
    }

    }

    function compare2x(a, b) {
        
      let iA = a.import_seq_wb
      let iB = b.import_seq_wb
      
      if (iA > iB) {
        return 1
      } else if (iB > iA) {
        return -1
      } else {
        console.log('wrong ffffffs ')
        console.log(a)
        console.log(b)
        alert('WRONGGGGGGGGGGGGGGGGGGGGGGGGGGGGGg')
        throw "EWEJFIAWJGWEIJ"
        //throw new Exception('WRONGGGGGGGGGGGGGGGGGGg')
      }
  
      }


      async function handleSelectedFolder(e, context) {
    
        e.preventDefault();
    
        var autoOptionsWarehouse = getAutoOptions(context, 'warehouse')
        var skuOptions = getAutoOptions(context, 'sku').filter((item, idx) => {
          return item.name == 'NPSB' || item.name == 'NPS'
        })
        var operationsDict = {}
        var trainsDict = {}
        var whDict = {}
        context.operationList.map((item, idx) => {
          operationsDict[item.name] = item 
        })
        context.trainList.map((item, idx) => {
          trainsDict[item.name] = item
        })
        var adama = null 
        autoOptionsWarehouse.map((item, idx) => {
          whDict[item.name + '-' + item.union] = item
          if (item.name == 'Mojo@2') {
            adama = item 
          }
        })
        console.log(whDict)
        console.log(operationsDict)
        console.log(trainsDict)
        console.log(context.planList)
        console.log(skuOptions)
    
        var files = e.target.files, f = files[0];
        var reader = new FileReader();
        reader.onload = function (e) {
            var data = e.target.result;
            let readedData = XLSX.read(data, {type: 'binary'});
            var parsedData = readedData.SheetNames.map((item, idx) => {
              const wsname = item//= readedData.SheetNames[0];
              const ws = readedData.Sheets[wsname];
      
              /* Convert array to json*/
              var dataParse = XLSX.utils.sheet_to_json(ws, {header:1}).filter((ini, idxi) => {
                return ini.length > 0
              })
    
              console.log(dataParse)
              /*return {'sheetName': wsname, 'sheetValue': dataParse.filter((col, idx) => {
                return col.length > 1
              })}*/ 
              return dataParse
              
              //setFileUploaded(dataParse);
            })
            //console.log(parsedData)
            let targetSheets = parsedData[1]
            console.log(targetSheets)
            
            //let convertedSet = doConversionSheets(parsedData, operationsDict, whDict, skuOptions[0], context)
            //console.log(convertedSet)
            //let resSet = [].concat.apply([], convertedSet)
            //resSet.sort((a, b) => compare2x(a, b))
            //console.log(resSet)
            //doCreationTransfer(resSet, context)
            //let converted = doConversion(targetSheets, operationsDict, trainsDict, whDict, skuOptions, adama, context)
            let converted = doConversionMojo(targetSheets, operationsDict, whDict, skuOptions, context, {})
            console.log(targetSheets.length)
            console.log(converted.length)
            console.log(converted)
            printSort(converted)
            converted.sort((a, b) => compare2Mojo(a, b))
            printSort(converted)
            converted = converted.filter((item, idx) => {
              if (!item) {
                return false 
              }
              return true 
            })
    
            doCreationTransfer(converted, context)
    
    
    
            //let newRows = []
            //var newRows = doConversionTransfer(parsedData[0])
            //let newRows = doConversion(parsedData[0])
            //console.log(newRows)
            //newRows.sort((a,b) => compare2(a,b))
            //console.log(newRows)
            //doCreationTransfer(newRows)
            //doCreation(newRows)
            //navigate('/app/newpaymentfromsheets', {state: {parsedData: prepParsedDataForUsage(parsedData)}})
    
        };
        reader.readAsBinaryString(f)
    }

async function handleSelectedFolderDouble(e, context) {
    
  e.preventDefault();

  var autoOptionsWarehouse = getAutoOptions(context, 'warehouse')
  var skuOptions = getAutoOptions(context, 'sku').filter((item, idx) => {
    return item.name == 'NPS'
  })
  var skuOptions2 = getAutoOptions(context, 'sku').filter((item, idx) => {
    return item.name == 'Wheat'
  })
  var operationsDict = {}
  var trainsDict = {}
  var whDict = {}
  context.operationList.map((item, idx) => {
    operationsDict[item.name] = item 
  })
  context.trainList.map((item, idx) => {
    trainsDict[item.name] = item
  })
  var adama = null 
  autoOptionsWarehouse.map((item, idx) => {
    whDict[item.name + '-' + item.union] = item
    if (item.name == 'Adama-Train Station') {
      adama = item 
    }
  })
  console.log(whDict)
  console.log(operationsDict)
  console.log(trainsDict)
  console.log(context.planList)
  console.log(skuOptions)


  var promiseArrayTwo = []
  console.log(e)
  console.log(e.target)
  console.log(e.target.files)
  var files = Array.from(e.target.files) 

  files.map((item, idx) => {
    let f = item 
      promiseArrayTwo.push(new Promise(async (resolve, reject) => {
        let reader = new FileReader();
  reader.onload = function (e) {
      let data = e.target.result;
      let readedData = XLSX.read(data, {type: 'binary'});
      let parsedData = readedData.SheetNames.map((item, idx) => {
        const wsname = item//= readedData.SheetNames[0];
        const ws = readedData.Sheets[wsname];

        /* Convert array to json*/
        let dataParse = XLSX.utils.sheet_to_json(ws, {header:1}).filter((ini, idxi) => {
          return ini.length > 0
        })

        console.log(dataParse)
        /*return {'sheetName': wsname, 'sheetValue': dataParse.filter((col, idx) => {
          return col.length > 1
        })}*/ 
        return dataParse
        
        //setFileUploaded(dataParse);
      })
      //console.log(parsedData)
      let targetSheets = parsedData[0]
      //console.log(targetSheets)
      //let convertedSet = doConversionSheets(parsedData, operationsDict, whDict, skuOptions[0], context)
      //console.log(convertedSet)
      //let resSet = [].concat.apply([], convertedSet)
      //resSet.sort((a, b) => compare2x(a, b))
      //console.log(resSet)
      //doCreationTransfer(resSet, context)
      let converted = doConversion(targetSheets, operationsDict, trainsDict, whDict, idx == 0 ? skuOptions2[0] : skuOptions[0], adama, context, idx)
      //console.log(targetSheets.length)
      //console.log(converted.length)
      //console.log(converted)
      //printSort(converted)
      converted.sort((a, b) => compare2(a, b))
      console.log('resolve conversion AA ' + item.name)
      resolve(converted)
      //printSort(converted)

     // doCreationTransfer(converted, context)



      //let newRows = []
      //var newRows = doConversionTransfer(parsedData[0])
      //let newRows = doConversion(parsedData[0])
      //console.log(newRows)
      //newRows.sort((a,b) => compare2(a,b))
      //console.log(newRows)
      //doCreationTransfer(newRows)
      //doCreation(newRows)
      //navigate('/app/newpaymentfromsheets', {state: {parsedData: prepParsedDataForUsage(parsedData)}})

  };
  reader.readAsBinaryString(f)
  }))
  })

  let resxf = await Promise.all(promiseArrayTwo)


  console.log(resxf)

  let finalRes = resxf[0].concat(resxf[1])
  finalRes.sort((a, b) => compare2(a, b))
  doCreationTransfer(finalRes, context)

}

  const handleChange = (event) => {
    console.log(event.target.name)
    console.log(event.target.value)
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  const handleChangeEmail = (event) => {
    console.log(event.target.name)
    console.log(event.target.value)
    if (values.dontSendEmail == true) {
      setValues({
        ...values,
        [event.target.name]: false
      });
    } else  {
      setValues({
        ...values,
        [event.target.name]: true
      });
    }

  };

    React.useEffect(() => {
      setValues(warehouseUserInfo)
  }, [warehouseUserInfo])
    return (
    <form
      autoComplete="off"
      noValidate
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Card>
        <CardHeader
          subheader="The information can be edited"
          title={"Profile: " + warehouseUserInfo.name}
        />
        <Divider />
        <CardContent>
          <Grid
            container
            spacing={3}
          >
         
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                disabled
                label="Email Address"
                name="email"
                onChange={handleChange}
                required
                value={values.email}
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                  }}
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                disabled
                label="Phone Number"
                name="phone"
                value={values.phone}
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                  }}
              />
            </Grid>

          </Grid>
        </CardContent>
        <Divider />
        <div>
        <div style={{flexDirection: 'row', padding: 10, alignItems: 'flex-end'}}>

<Button
  color="primary"
  variant="contained"
  style={{marginLeft: 10, marginRight: 10}}
  onClick={() => {sendUpdate()}}
>
  Save Details
</Button>



{false ? <Button
  color="primary"
  variant="contained"
  style={{marginLeft: 10, marginRight: 10}}
  onClick={() => {resetPassword()}}
>
  Reset Password
</Button>
: null}


</div>
<div style={{padding: 10}}>
<Button
  color="primary"
  variant="contained"
  style={{marginLeft: 10, marginRight: 10}}
  onClick={() =>{logoutFirebase()}}
>
  Logout
</Button>




</div>
          </div>
       
       
      </Card>
    </form>
  );
};

ProfileDetails.propTypes = {
  className: PropTypes.string
};

export default ProfileDetails;

/*

<input id="myInputFolder" ref={inputRef} style={{ display: 'none' }} type="file"
accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" onChange={(e) => handleSelectedFolder(e, context)} />
        <Button
          color="primary"
          variant="contained"
          onClick={ () => {
           if (inputRef != null && inputRef.current != null) {
            inputRef.current.click()
           }
          }}
        >
          Import Payment(s)
        </Button>

<input id="myInputFolder" ref={inputRef} style={{ display: 'none' }} type="file" multiple webkitdirectory
accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" onChange={(e) => handleSelectedFolderDouble(e, context)} />
        <Button
          color="primary"
          variant="contained"
          onClick={ () => {
           if (inputRef != null && inputRef.current != null) {
            inputRef.current.click()
           }
          }}
        >
          Import Payment(s)
        </Button>
<input id="myInputFolder" ref={inputRef} style={{ display: 'none' }} type="file"
accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" onChange={(e) => handleSelectedFolder(e, context)} />
        <Button
          color="primary"
          variant="contained"
          onClick={ () => {
           if (inputRef != null && inputRef.current != null) {
            inputRef.current.click()
           }
          }}
        >
          Import Payment(s)
        </Button>
<input id="myInputFolder" ref={inputRef} style={{ display: 'none' }} type="file"
accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" onChange={(e) => handleSelectedFolder(e, context)} />
        <Button
          color="primary"
          variant="contained"
          onClick={ () => {
           if (inputRef != null && inputRef.current != null) {
            inputRef.current.click()
           }
          }}
        >
          Import Payment(s)
        </Button>
<input id="myInputFolder" ref={inputRef} style={{ display: 'none' }} type="file"
accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" onChange={(e) => handleSelectedFolder(e, context)} />
        <Button
          color="primary"
          variant="contained"
          onClick={ () => {
           if (inputRef != null && inputRef.current != null) {
            inputRef.current.click()
           }
          }}
        >
          Import Payment(s)
        </Button>


<input id="myInputFolder" ref={inputRef} style={{ display: 'none' }} type="file"
accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" onChange={(e) => handleSelectedFolder(e, context)} />
        <Button
          color="primary"
          variant="contained"
          onClick={ () => {
           if (inputRef != null && inputRef.current != null) {
            inputRef.current.click()
           }
          }}
        >
          Import Payment(s)
        </Button>
<input id="myInputFolder" ref={inputRef} style={{ display: 'none' }} type="file"
accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" onChange={(e) => handleSelectedFolder(e, context)} />
        <Button
          color="primary"
          variant="contained"
          onClick={ () => {
           if (inputRef != null && inputRef.current != null) {
            inputRef.current.click()
           }
          }}
        >
          Import Payment(s)
        </Button>

*/
