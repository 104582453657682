import React, { useState, useRef, useEffect } from 'react';
import {useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Avatar,
  Box,
  Card,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  makeStyles,
  Button,
  Link,

  MenuItem,
  CircularProgress
} from '@material-ui/core';
import { TextField } from "@mui/material";

import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import getInitials from 'src/utils/getInitials';
import {X} from 'react-feather';
import HeaderComponent from 'src/utils/HeaderComponent';
import SearchWrapper from 'src/utils/SearchWrapper';
import TableAssist from 'src/utils/TableAssist';
import Firebase from 'src/Firebase'
import AddBarleyFieldDialog from 'src/utils/AddBarleyFieldDialog';
import VoidNoteDialog from 'src/views/barley/BarleyListView/VoidNoteDialog';
import { DataGrid, GridToolbar, GridToolbarContainer, GridToolbarColumnsButton, GridToolbarFilterButton, GridToolbarDensitySelector, GridToolbarExport } from '@mui/x-data-grid';


function CustomToolbar(props) {

  if (props.allowExport) {
    return       <GridToolbarContainer >
    <GridToolbarColumnsButton />
    <GridToolbarFilterButton />
    <GridToolbarDensitySelector/>
    <GridToolbarExport />
  </GridToolbarContainer>
  } else {
    return (
      <GridToolbarContainer >
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector/>
      </GridToolbarContainer>
    );
  }

}
const filter = createFilterOptions();


const movies = [
  {
    id: 1,
    movie: "HP"
  },
  {
    id: 2,
    movie: "Matrix"
  },
  {
    id: 3,
    movie: "LOTR"
  }
];

var columns = [
  { field: 'id', headerName: 'ID', width: 90 },
  {
    field: 'firstName',
    headerName: 'First name',
    width: 150,
    editable: true,
  },
  {
    field: 'lastName',
    headerName: 'Last name',
    width: 150,
    editable: true,
  },
  {
    field: 'age',
    headerName: 'Age',
    type: 'number',
    width: 110,
    editable: true,
  },
  {field: 'date',
headerName: 'Date',
type: 'date',
width: 150,
editable: true},
  {
    field: 'fullName',
    headerName: 'Full name',
    description: 'This column has a value getter and is not sortable.',
    sortable: false,
    width: 160,
    valueGetter: (params) =>
      `${params.getValue(params.id, 'firstName') || ''} ${
        params.getValue(params.id, 'lastName') || ''
      }`,
  },
];

var rows = [
  { id: 1, lastName: 'Snow', firstName: 'Jon', age: 35 },
  { id: 2, lastName: 'Lannister', firstName: 'Cersei', age: 42 },
  { id: 3, lastName: 'Lannister', firstName: 'Jaime', age: 45 },
  { id: 4, lastName: 'Stark', firstName: 'Arya', age: 16 },
  { id: 5, lastName: 'Targaryen', firstName: 'Daenerys', age: null, date: Date.now() },
  { id: 6, lastName: 'Melisandre', firstName: null, age: 150 },
  { id: 7, lastName: 'Clifford', firstName: 'Ferrara', age: 44 },
  { id: 8, lastName: 'Frances', firstName: 'Rossini', age: 36 },
  { id: 9, lastName: 'Roxie', firstName: 'Harvey', age: 65 },
];


const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiDataGrid-columnsContainer": {
      backgroundColor: "rgb(150,150,150)",
      color: '#ffffff'
    }
  },
  avatar: {
    marginRight: theme.spacing(2)
  }
}));

/*
          renderEditCell: (params) => {              
<Autocomplete
                id={params.id}
                options={movies}
                style={{height: 200}}
                autoHighlight
                getOptionLabel={(option) => option.movie}
                value={params.getValue(params.id, "movie")}
                renderInput={(params) => <TextField {...params} style={{ width: 150, height: 150}}  autoFocus  />}
              />
          }
*/



const TransferBarleyOutTable = ({ className, transferBarleyOutFields, transferBarleyOutList, barleyInCustomObjects, context, ...rest }) => {
  const classes = useStyles();
  const [selectedCustomerIds, setSelectedCustomerIds] = useState([]);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);
  const [text_filters, set_text_filters] = useState([]);
  const [sortOrder, setSortOrder] = useState('creationDate');
  const [sortDirection, setSortDirection] = useState(false);
  const [addColumnDialog, setAddColumnDialog] = useState(false);
  const [rows, setRows] = useState(transferBarleyOutList);
  const [selection, setSelection] = useState([]);
  const [loadingDict, setLoadingDict] = useState({});
  const [voidDialog, setAddVoidDialog] = useState(false);
  const docDiaRef = useRef(null);

  const navigate = useNavigate();


  const [sortModel, setSortModel] = React.useState([
    {
      field: 'creationDate',
      sort: 'desc',
    },
  ]);

  async function voidReport(remark) {
    if (selection.length != 1) {
      alert('Select a single item to void.')
      return 
    } else {
      var r = null 
      rows.map((row, idx) => {
        if (row.id == selection[0]) {
          r = row
        }
      })
      if (r != null) {

        
        
        let rx = await Firebase.voidTransferBarleyOut({'newVal': r, 'remark': remark})
        if (rx.transaction && rx.transaction.transaction) {
          alert('The specified entry has been voided. The amount has been deducted from the specified stock (if it was not already voided).')
        } else if (rx.transaction && rx.transaction.failMessage) {
          alert(rx.transaction.failMessage.msg)
        } else {
          alert('Error while attempting to void transaction.')
        }
        console.log(rx)
        Firebase.trackMixpanel('voidTransferBarleyOut', {'result': rx, 'id': r.id})

      }
    }
  }

  function constructStockQuery(val, ref) {
    var query = ref.where('warehouse', '==', val['warehouse']).where('uncategorized', '==', val['uncategorized'])
    if ('variety' in val) {
      query = query.where('variety', '==', val['variety'])
    }
    if ('moisture' in val) {
      query = query.where('moisture', '==', val['moisture'])
    }
    if ('purity' in val) {
      query = query.where('purity', '==', val['purity'])
    }
    
    return query 
  }


  function getDictId(dct) {
    var keys = Object.keys(dct)
    keys.sort()
    var s = ''
    for (var i = 0; i < keys.length; i++) {
      let key = keys[i]
      if (i > 0) {
        s += '-'
      }
      s += key 
      s += ':'
      s += dct[key]
    }

    return s 
  }

  function determineStockMembership(val) {

    /**
     *  1. Traveler // Variety purity >=90% // Moisture =<15%
        2. Traveler //  Variety purity <90% // Moisture =<15%
        3. Traveler // Variety all purity // Moisture >15%
        4. Fatima // Variety purity >=90% // Moisture =<15%
        5. Fatima // Variety purity <90% // Moisture =<15%
        6. Fatima //  Variety all purity // Moisture >15%
        7. Planet // Variety purity >=90% // Moisture =<15%
        8. Planet // Variety purity <90% // Moisture =<15%
        9. Planet //  Variety all purity // Moisture >15%
        10. All other varieties // Variety all purity // Moisture =<15%
        11. All other varieties // Variety all purity // Moisture >15%
     */
    var warehouse = 'None'
    if (val.warehouse && val.warehouse.name) {
      warehouse = val.warehouse.name
    }

    var uncategorized = false 
    
    if (!val.moisture || !val.variety) {
      // if moisture or variety is not defined, we are done, we just need uncategorized to be true
      uncategorized = true 
      return {'uncategorized': true, 'warehouse': warehouse}
    } 

    var variety = val.variety
      if (val.variety == 'Traveler' || val.variety == 'Fatima' || val.variety == 'Planet') {
        variety = val.variety 
      } else {
        variety = 'other'
      }

    if (val.moisture > 15) {
      // if moisture is above 15, the only other characteristic we need is variety
      // this accounts for the following options, where purity does not matter:
      /*
      3. Traveler // Variety all purity // Moisture >15%
      6. Fatima //  Variety all purity // Moisture >15%
      9. Planet //  Variety all purity // Moisture >15%
      11. All other varieties // Variety all purity // Moisture >15%
      */

      return {'uncategorized': false, 'warehouse': warehouse, 'variety': variety, 'moisture': 'above'}
    } else if (!val.purity) {
      // if purity is not defined and moisture is <= 15
      // this makes it case 10 IF variety is already at 'other', else it becomes uncategorized
      if (variety == 'other') {
        // 10. All other varieties // Variety all purity // Moisture =<15%
        return {'uncategorized': false, 'warehouse': warehouse, 'variety': variety, 'moisture': 'below'}
      } else {
        return {'uncategorized': true, 'warehouse': warehouse}
      }

    } else {
      // purity, variety, and moisture are all defined
      // moisture is below 

      if (variety == 'other') {
        // 10. All other varieties // Variety all purity // Moisture =<15%
        return {'uncategorized': false, 'warehouse': warehouse, 'variety': variety, 'moisture': 'below'}
      }

      var purity = 'above'
      if (val.purity < 90) {
        purity = 'below'
      }
      /*
      1. Traveler // Variety purity >=90% // Moisture =<15%
      2. Traveler //  Variety purity <90% // Moisture =<15%
      4. Fatima // Variety purity >=90% // Moisture =<15%
      5. Fatima // Variety purity <90% // Moisture =<15%
      7. Planet // Variety purity >=90% // Moisture =<15%
      8. Planet // Variety purity <90% // Moisture =<15%
      */
      return {'uncategorized': false, 'warehouse': warehouse, 'variety': variety, 'moisture': 'below', 'purity': purity }
    }

  }


  async function queryNewOldTransaction(newVal, oldVal, newId, oldId) {

    let oldQuery = Firebase.firestore.collection('barleyStocks').doc(newId)
    let newQuery = Firebase.firestore.collection('barleyStocks').doc(oldId)
    var oldQueryId = null 
    var newQueryId = null 
    var oldQueryResult = null 
    var newQueryResult = null 

    let transaction = await Firebase.firestore.runTransaction(t => {
      let newNumericValue;
      let oldNumericValue;
      return t.get(oldQuery )
      .then(querySnapshot => {

        if (querySnapshot.exists) {
          oldQueryResult = querySnapshot.data()

        } else {
          oldQueryResult = null 
        }
        return t.get(newQuery);
      }).then(querySnapshotNew => {

        if (querySnapshotNew.exists) {
          newQueryResult = querySnapshotNew.data()
          var updateVal = {}
          updateVal['netweightkg'] = newQueryResult['netweightkg'] + newVal['netweightkg']
          return t.update(newQuery,updateVal);
        } else {
          newQueryResult = null 
          return t.set(newQuery, newVal)
        }
      }).then(t => {

        if (oldQueryResult == null) {
          throw "Old stock does not exist."
        } else {
          var updateValOld = {}
          updateValOld['netweightkg'] = oldQueryResult['netweightkg'] - oldVal['netweightkg']
          return t.update(oldQuery ,updateValOld);
        }


      });

    }).then(result => {
          
      console.log('Transaction success!' + result);
      return 'tsuccess'
    }).catch(err => {
      console.log('Transaction failure:', err);
      return null
    });
  }

  async function queryNewOld(newVal, oldVal) { 

    // case where the queries differ, in a custom case where they are the same, we could simplify and not have
    // to query twice 

    let oldQuery = constructStockQuery(oldVal, Firebase.firestore.collection('barleyStocks'))
    let newQuery = constructStockQuery(newVal, Firebase.firestore.collection('barleyStocks'))

    var oldQueryId = null 
    var newQueryId = null 
    var oldQueryResult = await oldQuery.get()
    .then((querySnapshot) => {
      if (querySnapshot.docs && querySnapshot.docs.length == 1) {
        oldQueryId = querySnapshot.docs[0].id
        return querySnapshot.docs[0].data()
      } else {
        console.log('no snap')
        return null
      }
    })
    .catch((error) => {
      return null
        console.log("Error getting documents: ", error);
    }); 
    
    
    if (oldQueryResult != null) {
      // increment the past one
      console.log('we have an old result here')
      console.log(oldQueryResult)
      var updateValOld = {}
      updateValOld['netweightkg'] = oldQueryResult['netweightkg'] - oldVal['netweightkg']
      await Firebase.firestore.collection('barleyStocks').doc(oldQueryId).update(updateValOld).then((d) => {

      }).catch((e) => {
        console.log('errorno')
        console.log(e)
      })
    } else {
      console.log('no old result found')
    }


    var newQueryResult = await newQuery.get()
    .then((querySnapshot) => {
      if (querySnapshot.docs && querySnapshot.docs.length == 1) {
        newQueryId = querySnapshot.docs[0].id 
        return querySnapshot.docs[0].data()
      } else {
        console.log('no snap')
        return null
      }
    })
    .catch((error) => {
      return null
        console.log("Error getting documents: ", error);
    });    

    console.log('qids old and new ' + oldQueryId + ' ' + newQueryId)
    console.log('new result is ')
    console.log(newQueryResult)
    if (newQueryResult == null) {
      // create a whole new one
      let newDocRef = Firebase.firestore.collection('barleyStocks').doc()
      await newDocRef.set(newVal).then((d) => {

      }).catch((e) => {
        console.log('errorno')
        console.log(e)
      })
    } else {
      // increment the new one
      var updateVal = {}
      updateVal['netweightkg'] = newQueryResult['netweightkg'] + newVal['netweightkg']
      await Firebase.firestore.collection('barleyStocks').doc(newQueryId).update(updateVal).then((d) => {

      }).catch((e) => {
        console.log('errorno')
        console.log(e)
      })

    }

  }

function compareDictionaries( d1, d2 ) {
    // quick check for the same object
    if( d1 == d2 )
        return true;

    // check for null
    if( d1 == null || d2 == null )
        return false;

    // go through the keys in d1 and check if they're in d2 - also keep a count
    var count = 0;
    for( var key in d1 )
    {
        // check if the key exists
        if( !( key in d2 ) )
            return false;

        // check that the values are the same
        if( d1[key] != d2[key] )
            return false;

        count++;
    }

    // now just make sure d2 has the same number of keys
    var count2 = 0;
    for( key in d2 )
        count2++;

    // return if they're the same size
    return ( count == count2 );
}

  async function prepUpdate(oldVal, k, v) {

    // copy
    var newVal = JSON.parse(JSON.stringify(oldVal))


    newVal[k] = v 
    var newSet = determineStockMembership(newVal)
    var oldSet = determineStockMembership(oldVal)

    var equalSets = compareDictionaries(newSet, oldSet)
    let newSetId = getDictId(newSet)
    let oldSetId = getDictId(oldSet)
    newSet['netweightkg'] = newVal['netweightkg'] ? newVal['netweightkg'] : 0
    oldSet['netweightkg'] = oldVal['netweightkg'] ? oldVal['netweightkg'] : 0
    console.log('stock membership')
    console.log(newSet)
    console.log(oldSet)

    await queryNewOld(newSet, oldSet)
  }


  function getAutoOptions(barleyInCustomObjects, k) {
    if (k in barleyInCustomObjects) {
      return Object.keys(barleyInCustomObjects[k]).map((key, id) => {
        return barleyInCustomObjects[k][key]
      })
    }
    return []
  }


  function doApiUpdate(api, id, field, val, event) {
    console.log('incoming update with ')
    console.log(val)
    if (!api) {
      return
    }
    api.setEditCellValue({ id, field, value: val }, event);
    // Check if the event is not from the keyboard
    // https://github.com/facebook/react/issues/7407
    if (event.nativeEvent.clientX !== 0 && event.nativeEvent.clientY !== 0) {
      api.commitCellChange({ id, field });
      api.setCellMode(id, field, 'view');
    }
  }

  function compare2(a,b) {

    let as = a.sortNum ? a.sortNum : -1
    let bs = b.sortNum ? b.sortNum : -1

    if (as > bs) {
      return -1
    } else if (bs > as) {
      return 1
    } else {
      return 0
    }
  }


  function numericValueNoComma(params) {
    if (params.value) {
      return params.value + ''
    } else {
      return params.value
    }
    //return params.value + ''
  }

  function dateValueGetter(params) {
    return params.value ? moment(params.value).format('DD/MM/YYYY HH:mm') : ''
  }

  function categoryGetter(params) {
    if (params.row['category'] && params.row['category']['name']) {
      return JSON.stringify(params.row['category']['name']).replace('\"', '').replace('\"', '')
    } else {
      return ''
    }
  }
  

  function renderColumns(fields) {
    return fields.sort((a,b) => compare2(a,b)).map((item, idx) => {

      if (item.fieldKey == 'approval') {
        return ({field: item.fieldKey, headerName: item.fieldName, width: 'approval' in item? 150 : 200, editable: false,
        type: item.fieldType != 'text' ? item.fieldType : null,
        renderCell: (params) => {
          if (params.value == 'approved') {
            return 'Approved'
          } else if (params.value == 'rejected') {
            return 'Declined'
          } else if (params.id in loadingDict) {
            return <CircularProgress/>
          }

          return <div style={{flexDirection: 'row'}}>
            <Button
          color="primary"
          variant="contained"
          onClick={ () => {
           doApprove(params.id, params.row)
          }}
        >
         Approve
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={ () => {
            doReject(params.id, params.row)
          }}
        >
         Decline
        </Button>
            </div>
        }
      })
      }


      if (item.fieldType == 'custom') {
        return {field: item.fieldKey, headerName: item.fieldName, width: item.width ? item.width : 150, editable: false,
          type: item.fieldType != 'text' ? item.fieldType : null, valueGetter: ({ value }) => value && value.name ? value.name : '',
          renderEditCell: (params) => {              
            return  <Autocomplete
            value={params.value}
            onChange={(event, newValue) => {
              console.log(newValue)
              console.log(params)
              console.log(typeof newValue)
              //console.log(newValue.inputValue)
              if (typeof newValue === 'string') {

                //setFieldValue(fields[fieldIdx].fieldKey, {'customId': newValue.customId, 'name': newValue.name})
                doApiUpdate(params.api, params.id, item.fieldKey, {'customId': newValue.customId, 'name': newValue.name}, event)

              } else if (newValue && newValue.inputValue) {
                // Create a new value from the user input

                //setFieldValue(fields[fieldIdx].fieldKey, {'customId':-1, 'name': newValue.inputValue})
                doApiUpdate(params.api, params.id, item.fieldKey, {'customId': -1, 'name': newValue.inputValue}, event)
              } else {

                if (newValue == null) {
                  //setFieldValue(fields[fieldIdx].fieldKey, {'customId': '0', 'name': 'None'})
                  doApiUpdate(params.api, params.id, item.fieldKey, {'customId': '0', 'name': 'None'}, event)
                } else {
                  doApiUpdate(params.api, params.id, item.fieldKey, {'customId': newValue.customId, 'name': newValue.name}, event)
                  //setFieldValue(fields[fieldIdx].fieldKey, {'customId': newValue.customId, 'name': newValue.inputValue})
                }
              }
              //console.log(event.target.value)
              //console.log(newValue)
            }}
            filterOptions={(options, params) => {
              const filtered = filter(options, params);
      
              // Suggest the creation of a new value
              if (params.inputValue !== '') {
                filtered.push({
                  inputValue: params.inputValue,
                  name: `Add "${params.inputValue}"`,
                });
              }
      
              return filtered;
            }}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            id="free-solo-with-text-demo"
            options={getAutoOptions(barleyInCustomObjects, item.fieldKey)}
            getOptionLabel={(option) => {
              // Value selected with enter, right from the input
              if (typeof option === 'string') {
                return option;
              }
              // Add "xxx" option created dynamically
              if (option.inputValue) {
                return option.inputValue;
              }
              // Regular option
              return option.name;
            }}
            renderOption={(option) => option.name}
            freeSolo
            renderInput={(params) => <TextField {...params} style={{ width: 150, height: 150}}  autoFocus  />}
          />}
          
                      
        }
      } else if (item.fieldType == 'list') {
        return {field: item.fieldKey, headerName: item.fieldName, width: item.width ? item.width : 150, editable: false,
          type: 'singleSelect', valueOptions: item.listOptions                      
        }
      }

      if (item.fieldKey == 'warehouseinlink') {
        return ({field: item.fieldKey, headerName: item.fieldName, width: item.width ? item.width : 150, editable: false,
        type: item.fieldType != 'text' ? item.fieldType : null,
        renderCell: (params) => {
          if (params.value && params.value.length > 0) {
            return   <Link
            to={params.value}
            onClick={ () => window.open(params.value)}
            variant="h6"
          >{'View'}</Link>
          } else {
            return <div/>
          }
        }
      })
      }

      if (item.fieldKey == 'transferOutNumber' ) {
        return ({field: item.fieldKey, headerName: item.fieldName, width: item.width ? item.width : 150, editable: false,
        type: item.fieldType != 'text' ? item.fieldType : null,
        renderCell: (params) => {

         // if (params.row['warehouseoutlink'] && params.row['warehouseoutlink'].length) {
            return   <Link
            to={params.value}
            onClick={ async () => {
              let b = await Firebase.getSignedUrl({'bucket': 'test', 'file': 'pdf_for_whout_' + params.row['transferOutNumber'] + '.pdf'}) 
              if (b && b.length && b.length > 0) {
                window.open(b[0])
              } else {
                alert('Error retrieving file.')
              }

            }}
            variant="h6"
          >{[params.value]}</Link>
         /* } else {
            return params.value 
          }*/
        }, 
      })
      }

      if (item.fieldType == 'date' && item.fieldKey == 'creationDate') {
        return ({field: item.fieldKey, headerName: item.fieldName, width: item.width ? item.width : 150, editable: false,
          type: item.fieldType != 'text' ? item.fieldType : null, valueFormatter: (params) => {
            return params.value ? moment(params.value).format('DD/MM/YYYY') : ''
          }, renderCell: (params) => {
            return params.value ? moment(params.value).format('DD/MM/YYYY HH:mm') : ''
          }})
      } else if (item.fieldType == 'date' && item.fieldKey == 'creationDateHour') {

        return ({field: item.fieldKey, headerName: item.fieldName, width: item.width ? item.width : 150, editable: false,
          type: item.fieldType != 'text' ? item.fieldType : null, hide: true, valueGetter: (params) => {
            return params && params.row && params.row['creationDate'] ? moment(params.row['creationDate']).format('HH:mm') : ''
          }})
      } else if (item.fieldType == 'date') {
        return ({field: item.fieldKey, headerName: item.fieldName, width: item.width ? item.width : 150, editable: false,
          type: item.fieldType != 'text' ? item.fieldType : null, valueFormatter: (params) => {
            return params.value ? moment(params.value).format('DD/MM/YYYY HH:mm') : ''
          }})
      }
      
      if (item.fieldKey == 'category') {
        return ({field: item.fieldKey, headerName: item.fieldName, width: item.width ? item.width : 150, editable: false,
        type: item.fieldType != 'text' ? item.fieldType : null, valueGetter: categoryGetter
      })
      }

      if (item.fieldType == 'number') {
        return ({field: item.fieldKey, headerName: item.fieldName, width: item.width ? item.width : 150, editable: false,
          type: item.fieldType != 'text' ? item.fieldType : null,
        valueGetter: numericValueNoComma })
      }


      return ({field: item.fieldKey, headerName: item.fieldName, width: item.width ? item.width : 150, editable: false,
      type: item.fieldType != 'text' ? item.fieldType : null})
    })
  }


  async function tryNewColumn(dat) {
    let result = await Firebase.requestNewColumn({'project': Firebase.userProject, 'dat': dat, 'fieldsCollection': 'transferBarleyOutFields'})
    console.log(result)
    if (!result) {
      alert('This field already exists.')
    }
  }

  async function doApprove(id, v) {

    var ldc = loadingDict 
    ldc[id] = 1
    setLoadingDict(ldc)

    let result = await Firebase.doApprove({'id': id, 'values': v})
    
    ldc = loadingDict 
    if (id in ldc) {
      delete ldc[id]
    }
    setLoadingDict(ldc)
  } 

  async function doReject(id, v) {

    var ldc = loadingDict 
    ldc[id] = 1
    setLoadingDict(ldc)
    let result = await Firebase.doReject({'id': id, 'values': v})
    ldc = loadingDict 
    if (id in ldc) {
      delete ldc[id]
    }
    setLoadingDict(ldc)
  }


  async function updateRow(id, updateVal, field) {

    if (field.fieldType === 'custom' && updateVal[field.fieldKey].customId == -1) {
      var trueUpdate = updateVal 
      var docRef = Firebase.firestore.collection("TransferBarleyOut").doc(id);
      let docRefNext = Firebase.firestore.collection('barleyInCustomObjects').doc('contents').collection(field.fieldKey).doc()
      trueUpdate[field.fieldKey]['customId'] = docRefNext.id
      console.log('here is trueupdate')
      console.log(trueUpdate)

      var batch = Firebase.firestore.batch()
      batch.set(docRefNext, {'name': trueUpdate[field.fieldKey].name}  )
      batch.update(docRef, trueUpdate)
      let transaction = await batch.commit().then(result => {
        
        console.log('Batch success!' + result);
        return true
      }).catch(err => {
        console.log('Batch failure:', err);
        return false
      });
      return transaction

    } else {
      var docRef = Firebase.firestore.collection("TransferBarleyOut").doc(id);
      let ret = await docRef.update(updateVal).then(() => {
        console.log("Document successfully updated!");
        return true 
        })
        .catch((error) => {
            // The document probably doesn't exist.
            console.error("Error updating document: ", error);
            console.log('falseret')
            return false 
        });
        return ret 
    }
  }

  async function onCellChange(value, transferBarleyOutFields)  {
    // Get the row
    console.log('osc')
    console.log(value)
    console.log(value.field)
    console.log(value.value)
    if (!value.value || value.value == 'undefined') {
      console.log('retnowundef')
      return
    }
    //console.log(value)

    const rowIndex = rows.findIndex(row => row.id === value.id);
    const fieldIndex = transferBarleyOutFields.findIndex(item => value.field === item.fieldKey)

    if (rowIndex >= 0 && fieldIndex >= 0) {
      const row = rows[rowIndex];
      const field = transferBarleyOutFields[fieldIndex]
      // Validate if changed
      
      if (!(value.field in row) || (value.field in row && row[value.field] !== value.value)) {
        const data = { [value.field]: value.value };
        console.log('did change, here is data')
        console.log(data)
        console.log(row.id)
        // Sending to API
        const newRows = [...rows];
        //await prepUpdate(row, value.field, value.value)
        var ret = null 
        ret = await updateRow(row.id, data, field)
        /*if (value.field == 'warehouse' || value.field == 'moisture' || value.field == 'purity' || value.field == 'netweightkg' || value.field == 'variety') {
          ret = await updateRowAndStocks(row.id, data, field, row, value.field, value.value)
        } else {
          ret = await updateRow(row.id, data, field)
        }*/
        if (ret) {
          newRows[rowIndex][value.field] = value.value;
        } else {
          console.log('oldset')
          console.log('ent val is')
          console.log(row[value.field])
          console.log(value.field)
          console.log(row)
          if (value.field in row) {
            newRows[rowIndex][value.field] = row[value.field];
          } else {
            delete newRows[rowIndex][value.field]
          }

        }
        console.log(newRows)
        setRows(newRows);
        /*Api.product.update(data).then(res => {
          const newRows = [...rows];

          if (res.success) {
            // Change to new value
            newRows[rowIndex][value.field] = value.value;
          } else {
            // Change to old value
            newRows[rowIndex][value.field] = row[value.field];
          }

          setRows(newRows);
        });*/
      } else {
        console.log(rows)
        console.log('no change')
      }
    }
  };

  function hasCreationField(r) {
    var f = false 
    r.map((item, idx) => {
      if (item.fieldKey == 'creationDate') {
        f = true 
      }
    })

    return f
  }


  useEffect(() => {
    setRows(transferBarleyOutList);
  }, [transferBarleyOutList]);



  return (
    <div>
        <AddBarleyFieldDialog
        open={addColumnDialog}
        ref={docDiaRef}
        onClose={() => setAddColumnDialog(false)}
        alertTitle={'Add A New Field'}
        alertCancelText={'Cancel'}
        alertConfirmText={'Confirm'}
        onConfirm={(x) => tryNewColumn(x)}
        />
                <VoidNoteDialog
        open={voidDialog}
        onClose={() => setAddVoidDialog(false)}
        alertTitle={'Void Barley Transaction'}
        alertCancelText={'Cancel'}
        alertConfirmText={'Confirm'}
        onConfirm={(x) => voidReport(x)}
        />
<div style={{flexDirection: 'row', display: 'flex', justifyContent: 'space-between'}}>
{context.warehouseRole != 'Finance' && context.warehouseRole != 'Viewier' ? 
<Button
          color="primary"
          variant="contained"
          style={{marginBottom: 5}}
          onClick={() => navigate('/app/newTransferBarleyOut', {state: {fields: transferBarleyOutFields}})}
        >
         {'+ Transfer Out'}
        </Button>
: null}

    <div style={{flexDirection: 'row'}}>
    {context.warehouseRole == 'Admin' ?
        <Button
        color="primary"
        variant="contained"
        style={{marginBottom: 5}}
        onClick={ () => {
         setAddColumnDialog(true)
        }}
      >
       Add Column
      </Button>
        : null}
                    {context.warehouseRole == 'Admin' || context.warehouseRole == 'Warehouse' || context.warehouseRole == 'Warehouse Manager' ? 
       <Button
       color="primary"
       variant="contained"
       style={{marginBottom: 5}}
       onClick={() => 
        {
          setAddVoidDialog(true)
       }}
     >
      Void Selected Entry
     </Button>
      : null} 
    </div>


</div>



{hasCreationField(transferBarleyOutFields) ? 

          <div style={{height: 400, width: '99%'}}>
            <DataGrid
            style={{backgroundColor: 'white'}}
        className={classes.root}
        rows={rows}
        columns={renderColumns(transferBarleyOutFields)}
        pageSize={5}
        rowsPerPageOptions={[5]}
        onCellEditCommit={(p, e, d) => onCellChange(p, transferBarleyOutFields)}
        hideFooterPagination={false}
        hideFooter={false}
        checkboxSelection={true}
        disableSelectionOnClick
        sortModel={sortModel}
        onSelectionModelChange={(newSelection) => {
          setSelection(newSelection);
        }}
        components={{
          Toolbar: CustomToolbar,
        }}
        componentsProps={{ toolbar: { allowExport: context.warehouseRole == 'Admin' || context.warehouseRole == 'Finance' } }}
      />
      </div>
      : null }
    </div>
  )
};

TransferBarleyOutTable.propTypes = {
  className: PropTypes.string,
  customers: PropTypes.array.isRequired
};

export default TransferBarleyOutTable;
