import React, {Component} from 'react'
import DashboardCard from './DashboardCard';
import MyContext from 'src/MyContext';
import {
    Container,
    Grid,
    makeStyles,
    CircularProgress,
    Typography
  } from '@material-ui/core';
  import JobCard from './JobCard';

export default class JobCardHolder extends Component {

    constructor(props) {
        super(props)

        
    }

    render() {
        return (        <MyContext.Consumer>{context => (
            <Grid
            container
            spacing={3}
          >
           {context.adminJobs.length < 1 ? <div style={{alignItems: 'center', justifyContent: 'center'}}><div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}></div></div>
          :
          context.adminJobs.reverse().map((item, idx) => {
           return <Grid
            item
            lg={3}
            sm={6}
            xl={3}
            xs={12}
          >
            <JobCard itemProp={item} isAdmin={false}/>
            </Grid>
          }) 
          }
           
          </Grid>
              )}</MyContext.Consumer>)

    }


}