import React, {Component} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField'

class QuickInvoiceDialog extends Component {


    static defaultProps = {
        headerColor: 'black',
        bodyColor: 'black',
    };

    constructor(props) {
        super(props);

        this.state = {
            value: this.props.defaultValue,
            open: false,
            popRef: null,
            sortAscending: false,
            sortDescending: false,
            numTrips: 1,
            notes: '',
            terms: ''
        }

        this.topRef = null
    }

    componentDidMount() {
        //alert(this.props.defaultValue)
    }

   

    render() {
        return (
            <Dialog
            open={this.props.open}
            onClose={() => this.props.onClose()}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{this.props.alertTitle}</DialogTitle>
            <DialogContent>
                
              <DialogContentText id="alert-dialog-description">
                <div style={{display: 'flex', flexDirection: 'column'}}>
                <a>{'Price Per Quintal: ' + this.props.base.pricePerQuintal}</a>
                <a>{'Weight: ' + this.props.base.trueWeight * 10}</a>
                <TextField
                id="numTrips"
                label="Number of Trips"
                margin="normal"
                name="numTrips"
                onChange={(e) => {this.setState({numTrips: e.target.value})}}
                value={this.state.numTrips}
                variant="outlined"
                type="number"
              />
                <a>{'Total: ' + this.props.base.trueWeight * 10 * this.props.base.pricePerQuintal * this.state.numTrips}</a>
                <TextField
                  multiline 
                  label="Notes"
                  margin="normal"
                  name="notes"
                  onChange={(e) => {this.setState({notes: e.target.value})}}
                  value={this.state.notes}
                  variant="outlined"
                />
                                <TextField
                  multiline 
                  label="Terms"
                  margin="normal"
                  name="terms"
                  onChange={(e) => {this.setState({terms: e.target.value})}}
                  value={this.state.terms}
                  variant="outlined"
                />
                
                </div>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => this.props.onClose()} color="primary">
                {this.props.alertCancelText}
              </Button>
              <Button onClick={() => this.props.onConfirm(this.state)} color="primary" autoFocus>
                {this.props.alertConfirmText}
              </Button>
            </DialogActions>
          </Dialog>
        )

    }
}
const headerStyle = {
    backgroundColor: 'rgb(67,67,67)', flexWrap: 'never', whiteSpace: 'noWrap'
}

/*   <div onClick={() => this.adjustSortDescending()} style={{ margin: 0, display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: this.state.sortDescending ? 'orange' : 'white', border: this.state.sortDescending ? '' : '1px solid black', borderRadius: 30, padding: 0, height: 30, padding: 5 }}>
                                <p>Descending</p>
                            </div>*/

/*
 * 
 * 
 *             */

export default QuickInvoiceDialog;