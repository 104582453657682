import React, { useState, useEffect, Component } from 'react';
import {
  Box,
  Container,
  makeStyles
} from '@material-ui/core';
import Page from 'src/components/Page';
import Results from './Results';
import Toolbar from './Toolbar';
import data from './data';
import Firebase from 'src/Firebase.js'
import AssociationVehiclesTable from './AssociationVehiclesTable';
import AssociationContractsTable from './AssociationContractsTable';
import MyContext from 'src/MyContext.js'


class AssociationGenericWrapper extends Component {


  constructor() {
    super()
    this.state = {

      offersDict: {}

    }

    this.offersListener = null

  }

  componentDidMount() {

  }

  componentWillUnmount() {
 
  }


  getJobs(jobs, offersDict) {
    let j = jobs.filter((item, idx) => {
        return item.assignedTransporter == this.props.targetUid || item.quoteId in offersDict 
    }) 
    console.log(j)
    console.log('yea')
    console.log(this.props.targetUid)
    //console.log(jobs)
    console.log(offersDict)
    return j
  }

  getDrivers(users) {
    let j = users.filter((item, idx) => {
      return item.associationId == this.props.targetUid && item.userType != 4 
    })
    return j
  }

  getItemGeneric(users) {
    let j = users.filter((item, idx) => {
      return item.associationId == this.props.targetUid 
    })
    return j
  }




  render() {
    if (this.props.tableType == 0) {
      return (
        <MyContext.Consumer>{context => (
        <AssociationVehiclesTable targetAssociation={this.props.targetUid} customers={this.getItemGeneric(context.vehicles)}/>
        )}</MyContext.Consumer>
      )
    } else if (this.props.tableType == 1){
      return (
        <MyContext.Consumer>{context => (
        <AssociationContractsTable targetAssociation={this.props.targetUid} customers={this.getItemGeneric(context.adminContracts)}/>
        )}</MyContext.Consumer>
      )
    }

  }


}

export default AssociationGenericWrapper