import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import moment from 'moment';
import Page from 'src/components/Page';
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Typography,
  makeStyles,
  TextField,
  Container,
  CircularProgress
} from '@material-ui/core';
import Firebase from 'src/Firebase'
import { useParams } from 'react-router-dom';
import { useLocation } from 'react-router-dom'
import queryString from 'query-string';



const useStyles = makeStyles(() => ({
  root: {},
  avatar: {
    height: 100,
    width: 100
  }
}));

const PasswordReset = ({ className, userInfo, ...rest }) => {
  const classes = useStyles();

  const [password1, setPassword1] = React.useState('')
  const [password2, setPassword2] = React.useState('')
  const [loading, setLoading] = React.useState(false)
  const location = useLocation()
  
  async function doReset() {
    //alert(JSON.stringify(params))
    
    //console.log(location)

    let targetJob = location.pathname.split('/')[2]
    //console.log(targetJob)
    //let qx = queryString.parse(location.search);
    //console.log(qx)
    if (password1 != password2) {
      alert('Passwords must match.')
      return
    }
    if (!password1 || password1.length < 12) {
      alert('Password should be at least 12 characters long.')
      return 
    }
    setLoading(true)
    let vxr = await Firebase.confirmNewPasswordWarehouse({'code': targetJob, 'password': password1})
    setLoading(false)
    if (vxr == -5) {
      alert('This link has been invalidated because you requested another reset link. Please use the newest password reset link.')
    } else if (vxr == -3) {
      alert('Passwords must match and be at least 12 characters long.')
    } else if (vxr == -1) {
      alert('This link is not valid.')
    } else if (vxr == -2) {
      alert('This link has expired. Please request a new one.')
    } else if (vxr == -4) {
      alert('This link has already been used. Please request a new one.')
    }
  }

  return (
    <Page
    className={classes.root}
    height='100%'
    title="Request Barley Out"
    id="ff2"
    backgroundColor={classes.root.backgroundColor}
  >

      <Container id="cc2"  maxWidth={'sm'} style={{display:'flex', justifyContent: 'center', paddingTop: 100}} backgroundColor={classes.root.backgroundColor}>
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <CardContent >
        <Box
          alignItems="center"
          display="flex"
          flexDirection="column"
        >
          <Typography>
            Reset your password for Garri Logisitcs
            </Typography>
                  <TextField
                    fullWidth
                    label="Password"
                    margin="normal"
                    name="password"
                    type="password"
                    onChange={(e) => setPassword1(e.target.value)}
                    value={password1}
                    variant="outlined"
                  />

<TextField
                    fullWidth
                    label="Repeat Password"
                    margin="normal"
                    name="password"
                    type="password"
                    onChange={(e) => setPassword2(e.target.value)}
                    value={password2}
                    variant="outlined"
                  />
{loading ? <CircularProgress/> : <Button
  color="primary"
  variant="contained"
  style={{marginLeft: 10, marginRight: 10}}
  onClick={() => doReset()}
>
  Confirm
</Button>}

        </Box>
      </CardContent>
    </Card>
    </Container>
    </Page>
  );
};

PasswordReset.propTypes = {
  className: PropTypes.string
};

export default PasswordReset;
