import React, {useState} from 'react';
import { Link as RouterLink, useNavigate, useLocation} from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';

import LocationOnIcon from '@material-ui/icons/LocationOn';
import {
  Box,
  Button,
  Checkbox,
  Container,
  FormHelperText,
  Link,
  TextField,
  Typography,
  makeStyles,
  Grid,
  Select,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  InputLabel
} from '@material-ui/core';
import Page from 'src/components/Page';
import AddBox from '@material-ui/icons/AddBox';
import Cancel from '@material-ui/icons/Cancel';
import Edit from '@material-ui/icons/Edit';
import parse from 'autosuggest-highlight/parse';
import throttle from 'lodash/throttle';
import Firebase from 'src/Firebase'
import MuiPhoneNumber from 'material-ui-phone-number'
import MyContext from 'src/MyContext'
import CircularProgress from '@material-ui/core/CircularProgress';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';

import NewSupplierViewCustom from 'src/views/customobjects/CustomObjectsListView/NewSupplierViewCustom';
const filter = createFilterOptions();


const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/


const autocompleteService = { current: null };
const geocoder = {current: null};

const necessaryFields = {
  'name': 1,
  'phone': 1,
  'dlnumber': 1
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  }
}));


const AddFacilityAtTrainDialog = (props) => {
  const navigate = useNavigate();
  const [dialogWorking, setDialogWorking] = React.useState(false)
  const [value, setValue] = React.useState(null);
  const [uploadsHappening, setUploadsHappening] = React.useState(false);
  const docDiaRef = React.useRef(null);
  const inputRef = React.useRef(null);
  const [showDocumentDialog, setShowDocumentDialog] = React.useState(false)
  const [documentPlaceholder, setDocumentPlaceholder] = React.useState('')
  const [targetDocumentIndex, setTargetDocumentIndex] = React.useState(-1)
  const [loading, setLoading] = React.useState(false);
  const [autoValues, setAutoValues] = React.useState({})
  const [sku, setSku] = React.useState(null)
  const [destination, setDestination] = React.useState('')
  const [union, setUnion] = React.useState('')
  const [estimated, setEstimated] = React.useState(0)
  const [region, setRegion] = React.useState('')
  const [zone, setZone] = React.useState('')
  const [plan, setPlan] = React.useState(null)
  

  function getType(t) {
    if (t == 'date') {
      return 'date'
    } else if (t == 'number') {
      return 'number' 
    } else if (t == 'list' || t == 'custom') {
      return t
    } else {
      return null
    }
  }

  function updateAutoValues(k, v) {
    var o = autoValues
    o[k] = v 
    setAutoValues(o)
  }

  function getAutoOptions(context, k) {
    if (k in context.barleyInCustomObjects) {
      return Object.keys(context.barleyInCustomObjects[k]).map((key, id) => {
        return context.barleyInCustomObjects[k][key]
      })
    }
    return []
  }

  function renderContent(fields, touched, errors, handleBlur, handleChange, values, setFieldValue, context) {
    var columns = Math.floor(fields.length / 3)
    if (columns * 3 < fields.length) {
      columns += 1
    }
    var colrow = [...Array(columns).keys()]
    var rows = [0,1,2]
    return <div>
      {colrow.map((item, idx) => {
        return <div style={{display: 'flex', flexDirection: 'row'}}>
        {rows.map((inner, innerI) => {
          let fieldIdx = (3*item) + inner 

          if (fieldIdx < fields.length) {
            let tp = getType(fields[fieldIdx].fieldType)
            if (tp == 'custom') {

             return <Autocomplete
              value={autoValues[fields[fieldIdx].fieldKey]}
              fullWidth
              onChange={(event, newValue) => {
                if (typeof newValue === 'string') {
                  updateAutoValues(fields[fieldIdx].fieldKey, {name: newValue})
                  setFieldValue(fields[fieldIdx].fieldKey, {'customId': newValue.customId, 'name': newValue.name})

                } else if (newValue && newValue.inputValue) {
                  // Create a new value from the user input
                  updateAutoValues(fields[fieldIdx].fieldKey, {name: newValue.inputValue})
                  setFieldValue(fields[fieldIdx].fieldKey, {'customId':-1, 'name': newValue.inputValue})
                } else {
                  updateAutoValues(fields[fieldIdx].fieldKey, newValue)
                  if (newValue == null) {
                    setFieldValue(fields[fieldIdx].fieldKey, {'customId': '0', 'name': 'None'})
                  } else {
                    setFieldValue(fields[fieldIdx].fieldKey, {'customId': newValue.customId, 'name': newValue.name})
                  }
                }
                //console.log(event.target.value)
                //console.log(newValue)
              }}
              filterOptions={(options, params) => {
                const filtered = filter(options, params);
        
                // Suggest the creation of a new value
                if (params.inputValue !== '') {
                  filtered.push({
                    inputValue: params.inputValue,
                    name: `Add "${params.inputValue}"`,
                  });
                }
        
                return filtered;
              }}
              selectOnFocus
              clearOnBlur
              handleHomeEndKeys
              id="free-solo-with-text-demo"
              options={getAutoOptions(context, fields[fieldIdx].fieldKey)}
              getOptionLabel={(option) => {
                // Value selected with enter, right from the input
                if (typeof option === 'string') {
                  return option;
                }
                // Add "xxx" option created dynamically
                if (option.inputValue) {
                  return option.inputValue;
                }
                // Regular option
                return option.name;
              }}
              renderOption={(option) => option.name}
              freeSolo
              renderInput={(params) => (
                <TextField {...params}  error={Boolean(touched[fields[fieldIdx].fieldKey] && errors[fields[fieldIdx].fieldKey])}
                fullWidth
                id={fields[fieldIdx].fieldKey}
                helperText={touched[fields[fieldIdx].fieldKey] && errors[fields[fieldIdx].fieldKey]}
                label={fields[fieldIdx].fieldName}
                margin="normal"
                name={fields[fieldIdx].fieldKey}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values[fields[fieldIdx].fieldKey]}
                InputLabelProps={{
                  shrink: true,
                  }}
                variant="outlined"/>
              )}
            />



            } else if (tp == 'list') {
              let opts = fields[fieldIdx].listOptions
              return <TextField
              select 
              error={Boolean(touched[fields[fieldIdx].fieldKey] && errors[fields[fieldIdx].fieldKey])}
              fullWidth
              id={fields[fieldIdx].fieldKey}
              helperText={touched[fields[fieldIdx].fieldKey] && errors[fields[fieldIdx].fieldKey]}
              label={fields[fieldIdx].fieldName}
              margin="normal"
              name={fields[fieldIdx].fieldKey}
              onBlur={handleBlur}
              onChange={handleChange}
              value={values[fields[fieldIdx].fieldKey]}
              InputLabelProps={{
                shrink: true,
                }}
              variant="outlined">
              {opts.map((item, idx) => {
                return (<MenuItem value={item}>{item}</MenuItem>)
              })}
            </TextField>
            } else {
              return <TextField
              error={Boolean(touched[fields[fieldIdx].fieldKey] && errors[fields[fieldIdx].fieldKey])}
              fullWidth
              id={fields[fieldIdx].fieldKey}
              helperText={touched[fields[fieldIdx].fieldKey] && errors[fields[fieldIdx].fieldKey]}
              label={fields[fieldIdx].fieldName}
              margin="normal"
              name={fields[fieldIdx].fieldKey}
              onBlur={handleBlur}
              onChange={handleChange}
              value={values[fields[fieldIdx].fieldKey]}
              variant="outlined"
              type={getType(fields[fieldIdx].fieldType)}
              InputLabelProps={{
                shrink: true,
                }}
            />
            }

          }
          
          return null 

        })}
       </div>
      })}
    </div>
  }

  function dictToList(d) {
    if (d) {
     return Object.keys(d).map((key, idx) => {
       return d[key]
     })
    } else {
      return []
    }
  }
 

  async function doSubmit(v) {
    if (loading) {
      return 
    }
    setLoading(true)
    var facilitySendDetails = {'name': destination, 'union': union, 'warehousetype': 'External'}
    let transaction = await Firebase.createFacility({'project': Firebase.userProject, 'values': facilitySendDetails, 
    'fields': dictToList(props.context.barleyInCustomObjectFields['warehouse'])})


    if (transaction && transaction.transaction == null && transaction.failureMessage) {
      alert(transaction.failureMessage)
    } else {
      let newDestinationA = await Firebase.firestore.collection('projects').doc(Firebase.userProject).collection('barleyInCustomObjects/contents/warehouse').doc(transaction.newValId).get()
      var newDestination = newDestinationA.data()

      if (newDestination) {
        newDestination['id'] = transaction.newValId
        newDestination['customId'] = transaction.newValId
        var vbase = {'sku': sku, 'warehouse': newDestination, 'zone': zone, 'region': region, 'estimatedQty': estimated}
        let tempResRef = await Firebase.firestore.collection('projects').doc(Firebase.userProject).collection("plansInner").doc(plan.planId).get()
        let tempRes = tempResRef.data()
        //alert(JSON.stringify(tempRes))
        var vsend = vbase 
        vsend['creationDate'] = Date.now()
    
        //alert(tempRes)
        if (tempRes) {
          var docRef = Firebase.firestore.collection('projects').doc(Firebase.userProject).collection('plansInner').doc(plan.planId).collection('contents').doc()
          var id = docRef.id 
          vsend['id'] = id 
          vsend['customId'] = id
          await docRef.set(vsend)
          Firebase.trackMixpanel('addPlanRowOnlineFromDialog', {'result': id})
        } else {
          await Firebase.firestore.collection('projects').doc(Firebase.userProject).collection("plansInner").doc(plan.planId).set({'base': 1})
          var docRef = Firebase.firestore.collection('projects').doc(Firebase.userProject).collection('plansInner').doc(plan.planId).collection('contents').doc()
          var id = docRef.id 
          vsend['id'] = id 
          vsend['customId'] = id
          await docRef.set(vsend)
          Firebase.trackMixpanel('addPlanRowOnlineFromDialog', {'result': id})
        }
  
        props.onClose()

      } else {
        alert('Error creating destination.')
      }
      //navigate(-1)
    }

    setLoading(false)
    
  }


  return (

      <div>
 
            <Dialog
            open={props.open}
            onClose={() => props.onClose()}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >

            <DialogTitle id="alert-dialog-title">{props.alertTitle}</DialogTitle>
            <DialogContent>   
            <Formik
        id='f2'
          initialValues={{

          }}
          validationSchema={
            Yup.object().shape({

            })
          }
          onSubmit={(values) => {
            doSubmit(values)
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting2,
            setSubmitting, 
            touched,
            values,
            setFieldValue
          }) => (
            <form id='fu2' onSubmit={handleSubmit}>
                  <div id='fkx1' style={{marginTop: 5}}>


                  <Autocomplete
  id="plan"
  disabled={false}
  options={props.context.planList.filter((item, idx) => {
    return props.planSelection && props.planSelection.includes(item.planId)
  })}
  getOptionLabel={(option) => option.name}
  value={plan}
  onChange={(e, value) => {   
    if (value != null) {
      setPlan(value)
    }
  }}
  renderInput={(params) => <TextField {...params}
  disabled={false}
  label="Plan"
  margin="normal"
  id="plan"
  name="plan"
  variant="outlined"
  value={plan}
  defaultValue={plan} />}
/>

<Autocomplete
  id="sku"
  disabled={false}
  options={getAutoOptions(props.context, 'sku').filter((item, idx) => {
  return (plan && plan.product && item.product && item.product.customId == plan.product.customId)
})}
  getOptionLabel={(option) => option.name}
  value={sku}
  onChange={(e, value) => {   
    if (value != null) {
      setSku(value)
    }
  }}
  renderInput={(params) => <TextField {...params}
  disabled={false}
  label="SKU"
  margin="normal"
  id="sku"
  name="sku"
  variant="outlined"
  value={sku}
  defaultValue={sku} />}
/>

<TextField
              id={'destination'}
              key={'destination'}
              style={{marginRight: 10}}
              label={'Destination'}
              margin="normal"
              name={'destination'}
              onChange={(e) => setDestination(e.target.value)}
              value={destination}
              variant="outlined"
              type={'text'}
              InputLabelProps={{
                shrink: true,
                }}
            />
                        <TextField
              id={'union'}
              key={'union'}
              style={{marginRight: 10}}
              label={'Union'}
              margin="normal"
              name={'union'}
              onChange={(e) => setUnion(e.target.value)}
              value={union}
              variant="outlined"
              type={'text'}
              InputLabelProps={{
                shrink: true,
                }}
            />
            <TextField
              id={'zone'}
              key={'zone'}
              style={{marginRight: 10}}
              label={'Zone'}
              margin="normal"
              name={'zone'}
              onChange={(e) => setZone(e.target.value)}
              value={zone}
              variant="outlined"
              type={'text'}
              InputLabelProps={{
                shrink: true,
                }}
            />
                        <TextField
              id={'region'}
              key={'region'}
              
              label={'Region'}
              margin="normal"
              name={'region'}
              onChange={(e) => setRegion(e.target.value)}
              value={region}
              variant="outlined"
              type={'text'}
              InputLabelProps={{
                shrink: true,
                }}
            />
<TextField
              id={'wagonName'}
              key={'wagonName'}
              
              label={'Estimated Allocation'}
              margin="normal"
              name={'wagonName'}
              onChange={(e) => setEstimated(e.target.value)}
              value={estimated}
              variant="outlined"
              type={'number'}
              InputLabelProps={{
                shrink: true,
                }}
            />
</div>
              <Box my={2}>
                <Button
                  color="primary"
                  style={{margin: 5}}
                  disabled={loading || plan == null || destination == null || union == null || destination == '' || union == ''}
                  fullWidth
                  size="large"
                  id="sendButtonNext"
                  variant="contained"
                  onClick={() => doSubmit(values)}
                >
                  Confirm
                </Button>
                <Button
                  color="primary"
                  style={{margin: 5}}
                  disabled={loading}
                  fullWidth
                  size="large"
                  id="sendButtonNext"
                  variant="contained"
                  onClick={() => props.onClose()}
                >
                  Cancel
                </Button>
              </Box>

              {loading ? <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: 50}}><CircularProgress/></div> : null}

              
            </form>
          )}
        </Formik>
            </DialogContent>
            <DialogActions>

            </DialogActions>
          </Dialog>

        </div>
        )

    };

/*   <div onClick={() => this.adjustSortDescending()} style={{ margin: 0, display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: this.state.sortDescending ? 'orange' : 'white', border: this.state.sortDescending ? '' : '1px solid black', borderRadius: 30, padding: 0, height: 30, padding: 5 }}>
                                <p>Descending</p>
                            </div>*/

/*
 * 
 * 
 *             */

export default AddFacilityAtTrainDialog;