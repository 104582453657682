import React, { useState, useEffect, Component } from 'react';
import {
  Box,
  Container,
  makeStyles
} from '@material-ui/core';

import MyContext from 'src/MyContext.js'
import AdminMap from './AdminMap';


class AdminMapWrapper extends Component {


  constructor() {
    super()
    this.state = {

      offersDict: {}

    }

    this.offersListener = null

  }

  componentDidMount() {

  }

  componentWillUnmount() {
 
  }




  render() {

    return (
      <MyContext.Consumer>{context => (
      <AdminMap userInfo={context.userInfo}/>
      )}</MyContext.Consumer>
    )
  }


}

export default AdminMapWrapper