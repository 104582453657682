import React from 'react';
import { Navigate } from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout';
import MainLayout from 'src/layouts/MainLayout';
import AccountView from 'src/views/account/AccountView';
import CustomerListView from 'src/views/customer/CustomerListView';
import DashboardView from 'src/views/reports/DashboardView';
import LoginView from 'src/views/auth/LoginView';
import NotFoundView from 'src/views/errors/NotFoundView';
import ProductListView from 'src/views/product/ProductListView';
import RegisterView from 'src/views/auth/RegisterView';
import SettingsView from 'src/views/settings/SettingsView';
import PublicQuoteView from 'src/views/auth/PublicQuoteView';
import NewQuoteView from './views/customer/CustomerListView/NewQuoteView';
import EditQuoteView from './views/customer/CustomerListView/EditQuoteView';
import EditQuoteViewAdmin from './views/customer/CustomerListView/EditQuoteViewAdmin';
import SummaryQuoteView from './views/customer/CustomerListView/SummaryQuoteView';
import TransporterTableMainView from './views/custom/TransporterTableMainView';
import GenericLocationWrapper from './views/custom/GenericLocationWrapper';
import UsersListView from './views/user/UserListView';
import NewUserView from './views/user/UserListView/NewUserView';
import JobSummaryPane from './views/custom/JobSummaryPane';
import EditUserView from './views/user/UserListView/EditUserView'
import VehiclesListView from './views/vehicle/VehicleListView'
import NewVehicleView from './views/vehicle/VehicleListView/NewVehicleView'
import EditVehicleView from './views/vehicle/VehicleListView/EditVehicleView'
import EvaluateBidView from './views/customer/CustomerListView/EvaluateBidView';
import EditJobViewAdmin from './views/customer/CustomerListView/EditJobViewAdmin';
import LoadingView from 'src/LoadingView';
import ShipperOnlyQuoteTable from './shipper/ShipperOnlyQuoteTable';
import BaseWrapper from './shipper/BaseWrapper';
import EditVehicleGPSWox from './views/vehicle/VehicleListView/EditVehicleGPSWox';
import MyContext from './MyContext';
import CreateRequestFromLTC from './views/customer/CustomerListView/CreateRequestFromLTC';
import CreateFreightOrderFromLTC from './views/customer/CustomerListView/CreateFreightOrderFromLTC';
import PrivacyPolicy from './components/PrivacyPolicy';
import TermsOfUse from './components/TermsOfUse';
import FreightOrderListView from './views/freightorders/FreightOrderListView';
import ViewFreightOrder from './views/customer/CustomerListView/ViewFreightOrder';
import ViewInvoiceFast from './views/invoices/InvoiceListView/ViewInvoiceFast';
import InvoiceListView from './views/invoices/InvoiceListView';
import ViewInvoice from './views/invoices/InvoiceListView/ViewInvoice';
import GarriLoadingScreen from 'src/GarriLoadingScreen'
import BarleyListView from './views/barley/BarleyListView';
import NewBarleyInView from './views/barley/BarleyListView/NewBarleyInView';
import BarleyOutListView from './views/barleyout/BarleyOutListView';
import NewBarleyOutView from './views/barleyout/BarleyOutListView/NewBarleyOutView';
import CustomObjectsListView from './views/customobjects/CustomObjectsListView';
import NewSupplierView from './views/customobjects/CustomObjectsListView/NewSupplierView';
import NewTransferBarleyInView from './views/transferbarleyin/TransferBarleyInListView/NewTransferBarleyInView';
import NewTransferBarleyOutView from './views/transferbarleyout/TransferBarleyOutListView/NewTransferBarleyOutView';
import TransferBarleyInListView from './views/transferbarleyin/TransferBarleyInListView';
import TransferBarleyOutListView from './views/transferbarleyout/TransferBarleyOutListView';
import NewFacilityView from './views/customobjects/CustomObjectsListView/NewFacilityView';
import NewUserWarehouseView from './views/customobjects/CustomObjectsListView/NewUserWarehouseView';
import EditUserWarehouseView from './views/customobjects/CustomObjectsListView/EditUserWarehouseView';
import GenericSummaryView from './views/custom/GenericSummaryView';
import PasswordReset from './views/custom/PasswordReset';
import NewSkuView from './views/customobjects/CustomObjectsListView/NewSkuView';
import NewPlanView from './views/customobjects/CustomObjectsListView/NewPlanView';
import NewOperationView from './views/customobjects/CustomObjectsListView/NewOperationView';
import NewTrainView from './views/customobjects/CustomObjectsListView/NewTrainView';
import NewTransferInView from './views/customobjects/CustomObjectsListView/NewTransferInView';
import DispatchListView from './views/transferdispatch/DispatchListView';
import NewDispatchView from './views/transferdispatch/DispatchListView/NewDispatchView';
import SubPlanTableWrapper from './views/customobjects/CustomObjectsListView/SubPlanTableWrapper';
import NewProductView from './views/customobjects/CustomObjectsListView/NewProductView';
import ViewTrainPlanView from './views/customobjects/CustomObjectsListView/ViewTrainPlanView';
import EditTrainView from './views/customobjects/CustomObjectsListView/EditTrainView';
import AnalyticsScreen from './views/reports/DashboardView/AnalyticsScreen'
import EditDispatchView from './views/transferdispatch/DispatchListView/EditDispatchView';
import DispatchHistoryTable from './views/transferdispatch/DispatchListView/DispatchHistoryTable';
import EditDriverWarehouseView from './views/customobjects/CustomObjectsListView/EditDriverWarehouseView';
import EditTransporterWarehouseView from './views/customobjects/CustomObjectsListView/EditTransporterWarehouseView';
import EditVehicleWarehouseView from './views/customobjects/CustomObjectsListView/EditVehicleWarehouseView';

function keyMap(lst, key, val, cond, val2=null) {
  return lst.filter((item, idx) => {

    if (cond == '<') {
      return item[key] < val
    } else if (cond == '>') {
      return item[key] > val 
    } else if (cond == '=') {
      return item[key] == val
    } else if (cond == 'bound') {
      return item[key] > val && item[key] < val2 
    }
  })
}


const routesShipper = (isLoggedIn, tableItems, userInfo, cargoTypes, navigation) => [
  {
    path: 'app',
    element:  isLoggedIn == -1 ? <LoadingView/> : isLoggedIn == 1 ? <DashboardLayout userInfo={userInfo}/> : <Navigate to="/login"/>,
    children: [
      { path: 'account', element: <AccountView userInfo={userInfo}/> },
      { path: 'barley', element: <BarleyListView/>},
      { path: 'barleyout', element: <BarleyOutListView/>},
      { path: 'transferbarleyin', element: <TransferBarleyInListView/>},
      { path: 'transferbarleyout', element: <TransferBarleyOutListView/>},
      { path: 'dashboard', element: <DashboardView userInfo={userInfo}/> },
      { path: 'analytics', element: <AnalyticsScreen/>},
      { path: 'newbarleyin', element: <NewBarleyInView/>},
      { path: 'newbarleyout', element: <NewBarleyOutView/>},
      { path: 'newtransferbarleyin', element: <NewTransferBarleyInView/>},
      { path: 'newtransferbarleyout', element: <NewTransferBarleyOutView/>},
      { path: 'plans', element: <CustomObjectsListView customKey={'plans'} titleKey={'Plans'}
            objectFieldsKey={'planFields'} objectsKey={'planList'} workflow={true}/>},
      { path: 'operations', element: <CustomObjectsListView customKey={'operations'} titleKey={'Operations'}
      objectFieldsKey={'operationFields'} objectsKey={'operationList'} workflow={true}/>},
      { path: 'trains', element: <CustomObjectsListView customKey={'trains'} titleKey={'Trains'}
      objectFieldsKey={'trainFields'} objectsKey={'trainList'} workflow={true}/>},
      { path: 'arrivals', element: <CustomObjectsListView customKey={'arrivals'} titleKey={'Arrivals'}
      objectFieldsKey={'arrivalFields'} objectsKey={'arrivalList'} workflow={true}/>},
      { path: 'facilities', element: <CustomObjectsListView customKey={'warehouse'} titleKey={'Facilities'}/>},
      { path: 'suppliers', element: <CustomObjectsListView customKey={'supplier'} titleKey={'Suppliers'}/>},
      { path: 'skus', element: <CustomObjectsListView customKey={'sku'} titleKey={'SKUs'}/>},
      { path: 'products', element: <CustomObjectsListView customKey={'product'} titleKey={'Products'}/>},
      { path: 'users', element: <CustomObjectsListView customKey={'warehousesoftwareusers'} titleKey={'Users'}/>},
      { path: 'transporters', element: <CustomObjectsListView customKey={'transporters'} titleKey={'Transporters'}/>},
      { path: 'drivers', element: <CustomObjectsListView customKey={'drivers'} titleKey={'Drivers'}/>},
      { path: 'vehicles', element: <CustomObjectsListView customKey={'vehicles'} titleKey={'Vehicles'}/>},
      { path: 'newsupplier', element: <NewSupplierView/>},
      { path: 'newfacility', element: <NewFacilityView/>},
      { path: 'newuser', element: <NewUserWarehouseView/>},
      { path: 'newplan', element: <NewPlanView/>},
      { path: 'newtrain', element: <NewTrainView/>},
      { path: 'edittrain', element: <EditTrainView/>},
      { path: 'newoperation', element: <NewOperationView/>},
      { path: 'newtransferin', element: <NewTransferInView/>},
      { path: 'dispatch', element: <DispatchListView/>},
      { path: 'editdispatch', element: <EditDispatchView/>},
      { path: 'dispatchhistory', element: <DispatchHistoryTable/>},
      { path: 'newdispatch', element: <NewDispatchView/>},
      { path: 'newsku', element: <NewSkuView/>},
      { path: 'newproduct', element: <NewProductView/>},
      { path: 'edituser', element: <EditUserWarehouseView/>},
      { path: 'editdriver', element: <EditDriverWarehouseView/>},
      { path: 'edittransporter', element: <EditTransporterWarehouseView/>},
      { path: 'editvehicle', element: <EditVehicleWarehouseView/>},
      { path: 'creationsummary', element: <GenericSummaryView/>},
      { path: 'editplan', element: <SubPlanTableWrapper/>},
      { path: 'viewplan', element: <ViewTrainPlanView/>},
      { path: '*', element: <Navigate to="/404" /> }
    ]
  },
  {
    path: '/passwordreset/*',
    element: <PasswordReset/>
  },
  {
    path: '/privacypolicy',
    element : <PrivacyPolicy/>
  },
  {
    path: '/termsofuse',
    element : <TermsOfUse/>
  },
  {
    path: '/',
    element: isLoggedIn == 1 ? <Navigate to="/app/dashboard"/> : <MainLayout />,
    children: [
      { path: 'login', element: <LoginView /> },
      { path: '404', element: <NotFoundView /> },
      { path: '/', element: <Navigate to="/app/dashboard" /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  },

];

/**
 * 
 * State list
 * -1: quote rejected
 * 0: unassigned, fresh quote
 * 1: quote frozen, sent to shipper for confirmation
 * 2: quote confirmed by shipper
 */

export default routesShipper;
