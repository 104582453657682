import React, { useState, useEffect } from 'react';
import {
  Box,
  Container,
  makeStyles
} from '@material-ui/core';
import Page from 'src/components/Page';
import Toolbar from './Toolbar';
import Firebase from 'src/Firebase.js'
import BarleyTableWrapper from './BarleyTableWrapper';


const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));


const BarleyListView = () => {
  const classes = useStyles();


  useEffect(() => {
    // Update the document title using the browser API

    
  });


  return (
    <Page
      className={classes.root}
      title="Intake"
      id={'barleyIn'}
    >
      <Container maxWidth={false}>
        <Toolbar />
        <Box mt={3}>
         <div id='fk11'>
          <BarleyTableWrapper />
          </div>
          

        </Box>
      </Container>
    </Page>
  );
};

export default BarleyListView;
