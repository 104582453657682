import React from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Button,
  ListItem,
  makeStyles,
  Collapse,
  Box, 
  List
} from '@material-ui/core';

import {
  AlertCircle as AlertCircleIcon,
  BarChart as BarChartIcon,
  PieChart as PieChartIcon,
  Lock as LockIcon,
  Settings as SettingsIcon,
  ShoppingBag as ShoppingBagIcon,
  User as UserIcon,
  UserPlus as UserPlusIcon,
  Users as UsersIcon,
  Map as MapIcon,
  ArrowRight as ArrowRightIcon,
  ArrowLeft as ArrowLeftIcon,
  Database as DatabaseIcon,
  Truck as TruckIcon,
} from 'react-feather';

import NavItem from './NavItem'

const useStyles = makeStyles((theme) => ({
  item: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0
  },
  button: {
    color: theme.palette.text.secondary,
    fontWeight: theme.typography.fontWeightMedium,
    justifyContent: 'flex-start',
    letterSpacing: 0,
    padding: '10px 8px',
    textTransform: 'none',
    width: '100%'
  },
  icon: {
    marginRight: theme.spacing(1)
  },
  title: {
    marginRight: 'auto'
  },
  active: {
    color: theme.palette.primary.main,
    '& $title': {
      fontWeight: theme.typography.fontWeightMedium
    },
    '& $icon': {
      color: theme.palette.primary.main
    }
  }
}));

const itemsLimited = [
  {
    href: '/app/facilities',
    icon: DatabaseIcon,
    title: 'Facilities'
  },
  {
    href: '/app/products',
    icon: DatabaseIcon,
    title: 'Commodity Types'
  },
  {
    href: '/app/skus',
    icon: DatabaseIcon,
    title: 'SKUs'
  },
  {
    href: '/app/users',
    icon: UsersIcon,
    title: 'Users'
  },
  {
    href: '/app/transporters',
    icon: UsersIcon,
    title: 'Transporters'
  },
  {
    href: '/app/drivers',
    icon: UsersIcon,
    title: 'Drivers'
  },
  {
    href: '/app/vehicles',
    icon: UsersIcon,
    title: 'Vehicles'
  },
  {
    href: '/app/account',
    icon: SettingsIcon,
    title: 'My Account'
  },


];

const NavItemCollapse = ({
  className,
  href,
  icon: Icon,
  title,
  ...rest
}) => {
  const classes = useStyles();
  const [collapse, setCollapse] = React.useState(false);

  return (
    <ListItem
      className={clsx(classes.item, className)}
      disableGutters
      {...rest}
    >
      <div style={{flexDirection: 'column'}}>
      <Button
        activeClassName={classes.active}
        className={classes.button}
        onClick={() => setCollapse(!collapse)}
        to={href}
      >
        {Icon && (
          <Icon
            className={classes.icon}
            size="20"
          />
        )}
        <span className={classes.title}>
          {title}
        </span>
      </Button>
      <Collapse in={collapse} timeout="auto" unmountOnExit>
        <List style={{marginLeft: 20}} component="div" disablePadding>

          {itemsLimited.map((item) => (
            <NavItem
              href={item.href}
              key={item.title}
              title={item.title}
              icon={item.icon}
            />
          ))}
        </List>
      </Collapse>
      </div>
    </ListItem>
  );
};

NavItemCollapse.propTypes = {
  className: PropTypes.string,
  href: PropTypes.string,
  icon: PropTypes.elementType,
  title: PropTypes.string
};

export default NavItemCollapse;
