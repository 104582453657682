import React, {useState} from 'react';
import { Link as RouterLink, useNavigate, useLocation} from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';

import LocationOnIcon from '@material-ui/icons/LocationOn';
import {
  Box,
  Button,
  Checkbox,
  Container,
  FormHelperText,
  Link,
  TextField,
  Typography,
  makeStyles,
  Grid,
  Select,
  MenuItem,
  CircularProgress,
  Stepper,
  StepLabel,
  Step,
} from '@material-ui/core';
import Page from 'src/components/Page';
import parse from 'autosuggest-highlight/parse';
import throttle from 'lodash/throttle';
import Firebase from 'src/Firebase'
import MuiPhoneNumber from 'material-ui-phone-number'
import { parseWithOptions } from 'date-fns/fp';
import MyContext from 'src/MyContext';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { arrayOf } from 'prop-types';
import { v4 as uuidv4 } from 'uuid';

const filter = createFilterOptions();
const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
/*
Traveler // Variety purity >=90% // Moisture =<15%
Traveler //  Variety purity <90% // Moisture =<15%
Traveler // Variety all purity // Moisture >15%
Fatima // Variety purity >=90% // Moisture =<15%
Fatima // Variety purity <90% // Moisture =<15%
Fatima //  Variety all purity // Moisture >15%
Planet // Variety purity >=90% // Moisture =<15%
Planet // Variety purity <90% // Moisture =<15%
Planet //  Variety all purity // Moisture >15%
All other varieties // Variety all purity // Moisture =<15%
All other varieties // Variety all purity // Moisture >15%
*/


const outOptions = [
{'name': 'Traveler // Variety purity >=90% // Moisture =<15%', 'customId': 0, 'query': {'variety': 'Traveler', 'moisture': 'below', 'purity': 'above', 'uncategorized': false}},
{'name': 'Traveler //  Variety purity <90% // Moisture =<15%', 'customId': 1, 'query': {'variety': 'Traveler', 'moisture': 'below', 'purity': 'below', 'uncategorized': false}},
{'name': 'Traveler // Variety all purity // Moisture >15%', 'customId': 2, 'query': {'variety': 'Traveler', 'moisture': 'above', 'uncategorized': false}},
{'name': 'Fatima // Variety purity >=90% // Moisture =<15%', 'customId': 3, 'query': {'variety': 'Fatima', 'moisture': 'below', 'purity': 'above', 'uncategorized': false}},
{'name': 'Fatima // Variety purity <90% // Moisture =<15%', 'customId': 4, 'query': {'variety': 'Fatima', 'moisture': 'below', 'purity': 'below', 'uncategorized': false}},
{'name': 'Fatima //  Variety all purity // Moisture >15%', 'customId': 5, 'query': {'variety': 'Fatima', 'moisture': 'above', 'uncategorized': false}},
{'name': 'Planet // Variety purity >=90% // Moisture =<15%', 'customId': 6, 'query': {'variety': 'Planet', 'moisture': 'below', 'purity': 'above', 'uncategorized': false}},
{'name': 'Planet // Variety purity <90% // Moisture =<15%', 'customId': 7, 'query':  {'variety': 'Planet', 'moisture': 'below', 'purity': 'below', 'uncategorized': false}},
{'name': 'Planet //  Variety all purity // Moisture >15%', 'customId': 8, 'query': {'variety': 'Planet', 'moisture': 'above', 'uncategorized': false}},
{'name': 'All other varieties // Variety all purity // Moisture =<15%', 'customId': 9, 'query': {'variety': 'other', 'moisture': 'below', 'uncategorized': false}},
{'name': 'All other varieties // Variety all purity // Moisture >15%', 'customId': 10, 'query': {'variety': 'other', 'moisture': 'above', 'uncategorized': false}},
{'name': 'Uncategorized', 'customId': 11, 'query': {'uncategorized': true}}]
const autocompleteService = { current: null };
const geocoder = {current: null};

const specOptions = {'vehicleweight': 1, 'vehicletare': 1}


const steps = ['Basic Details', 'Shipment Details', 'Cargo Details'];
const stepFields = {
  0: [],
  1: ['receivername', 'receiverphone','drivername', 'driverphone','platenumber', 'entryticketnumber', 'gateticketnumber','transportservice'],
  2: ['vehicleweight', 'vehicletare', 'numberofbags', 'weighbridge', 'stacknumber', 'inspectedby'],
}



const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  }
}));


const NewTransferBarleyOutView = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const {state} = useLocation();
  const [loading, setLoading] = React.useState(false);
  const [autoValues, setAutoValues] = React.useState({});
  const [source, setSource] = React.useState(state.requestPreset && state.requestPreset.source ? {'name': state.requestPreset.source} : null);
  const [sku, setSku] = React.useState(state.requestPreset && state.requestPreset.sku ? state.requestPreset.sku : null)

  const [destination, setDestination] = React.useState(state.requestPreset && state.requestPreset.warehouse ? state.requestPreset.warehouse : null);
  const [amount, setAmount] = React.useState(null);
  const [request, setRequest] = React.useState(state.requestPreset ? state.requestPreset : null);
  const [activeStep, setActiveStep] = React.useState(0);

  const handleNext = () => {


    setActiveStep((prevActiveStep) => prevActiveStep + 1);

  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };



  function determineStockMembership(val) {

    /**
     *  1. Traveler // Variety purity >=90% // Moisture =<15%
        2. Traveler //  Variety purity <90% // Moisture =<15%
        3. Traveler // Variety all purity // Moisture >15%
        4. Fatima // Variety purity >=90% // Moisture =<15%
        5. Fatima // Variety purity <90% // Moisture =<15%
        6. Fatima //  Variety all purity // Moisture >15%
        7. Planet // Variety purity >=90% // Moisture =<15%
        8. Planet // Variety purity <90% // Moisture =<15%
        9. Planet //  Variety all purity // Moisture >15%
        10. All other varieties // Variety all purity // Moisture =<15%
        11. All other varieties // Variety all purity // Moisture >15%
     */
    var warehouse = 'None'
    if (val.warehouse && val.warehouse.name) {
      warehouse = val.warehouse.name
    }

    var uncategorized = false 
    
    if (!val.moisture || !val.variety) {
      // if moisture or variety is not defined, we are done, we just need uncategorized to be true
      uncategorized = true 
      return {'uncategorized': true, 'warehouse': warehouse}
    } 

    var variety = val.variety
      if (val.variety == 'Traveler' || val.variety == 'Fatima' || val.variety == 'Planet') {
        variety = val.variety 
      } else {
        variety = 'other'
      }

    if (val.moisture > 15) {
      // if moisture is above 15, the only other characteristic we need is variety
      // this accounts for the following options, where purity does not matter:
      /*
      3. Traveler // Variety all purity // Moisture >15%
      6. Fatima //  Variety all purity // Moisture >15%
      9. Planet //  Variety all purity // Moisture >15%
      11. All other varieties // Variety all purity // Moisture >15%
      */

      return {'uncategorized': false, 'warehouse': warehouse, 'variety': variety, 'moisture': 'above'}
    } else if (!val.purity) {
      // if purity is not defined and moisture is <= 15
      // this makes it case 10 IF variety is already at 'other', else it becomes uncategorized
      if (variety == 'other') {
        // 10. All other varieties // Variety all purity // Moisture =<15%
        return {'uncategorized': false, 'warehouse': warehouse, 'variety': variety, 'moisture': 'below'}
      } else {
        return {'uncategorized': true, 'warehouse': warehouse}
      }

    } else {
      // purity, variety, and moisture are all defined
      // moisture is below 

      if (variety == 'other') {
        // 10. All other varieties // Variety all purity // Moisture =<15%
        return {'uncategorized': false, 'warehouse': warehouse, 'variety': variety, 'moisture': 'below'}
      }

      var purity = 'above'
      if (val.purity < 90) {
        purity = 'below'
      }
      /*
      1. Traveler // Variety purity >=90% // Moisture =<15%
      2. Traveler //  Variety purity <90% // Moisture =<15%
      4. Fatima // Variety purity >=90% // Moisture =<15%
      5. Fatima // Variety purity <90% // Moisture =<15%
      7. Planet // Variety purity >=90% // Moisture =<15%
      8. Planet // Variety purity <90% // Moisture =<15%
      */
      return {'uncategorized': false, 'warehouse': warehouse, 'variety': variety, 'moisture': 'below', 'purity': purity }
    }

  }

  function getDictId(dct) {
    var keys = Object.keys(dct)
    keys.sort()
    var s = ''
    for (var i = 0; i < keys.length; i++) {
      let key = keys[i]
      if (i > 0) {
        s += '-'
      }
      s += key 
      s += ':'
      s += dct[key]
    }

    return s 
  }


  function getType(t) {
    if (t == 'date') {
      return 'date'
    } else if (t == 'number') {
      return 'number' 
    } else if (t == 'list' || t == 'custom') {
      return t
    } else {
      return null
    }
  }

  function updateAutoValues(k, v) {
    var o = autoValues
    o[k] = v 
    setAutoValues(o)
  }

  function getAutoOptions(context, k) {
    if (k in context.barleyInCustomObjects) {
      return Object.keys(context.barleyInCustomObjects[k]).map((key, id) => {
        return context.barleyInCustomObjects[k][key]
      })
    }
    return []
  }

  function getRequestOptions(context, k) {
    return context.barleyOutList
  }

  
  function specialUpdate(key, val, setFieldValue, values) {

    setFieldValue(key, val)
    if (key == 'vehicleweight') {
      if (values.vehicletare) {
        setAmount(val - values.vehicletare)
      }

    } else if (key == 'vehicletare') {
      if (values.vehicleweight) {
        setAmount(values.vehicleweight - val)
      }
    }

    
  } 


  function renderContent(fields, touched, errors, handleBlur, handleChange, values, setFieldValue, context) {
    var columns = Math.floor(fields.length / 3)
    if (columns * 3 < fields.length) {
      columns += 1
    }
    var colrow = [...Array(columns).keys()]
    var rows = [0,1,2]
    return <div>
      {colrow.map((item, idx) => {
        return <div style={{display: 'flex', flexDirection: 'row'}}>
        {rows.map((inner, innerI) => {
          let fieldIdx = (3*item) + inner 

          if (fieldIdx < fields.length) {
            let tp = getType(fields[fieldIdx].fieldType)
            if (tp == 'custom') {

             return <Autocomplete
              value={autoValues[fields[fieldIdx].fieldKey]}
              fullWidth
              onChange={(event, newValue) => {
                if (typeof newValue === 'string') {
                  updateAutoValues(fields[fieldIdx].fieldKey, {name: newValue})
                  setFieldValue(fields[fieldIdx].fieldKey, {'customId': newValue.customId, 'name': newValue.name})

                } else if (newValue && newValue.inputValue) {
                  // Create a new value from the user input
                  updateAutoValues(fields[fieldIdx].fieldKey, {name: newValue.inputValue})
                  setFieldValue(fields[fieldIdx].fieldKey, {'customId':-1, 'name': newValue.inputValue})
                } else {
                  updateAutoValues(fields[fieldIdx].fieldKey, newValue)
                  if (newValue == null) {
                    setFieldValue(fields[fieldIdx].fieldKey, {'customId': '0', 'name': 'None'})
                  } else {
                    setFieldValue(fields[fieldIdx].fieldKey, {'customId': newValue.customId, 'name': newValue.name})
                  }
                }
                //console.log(event.target.value)
                //console.log(newValue)
              }}
              filterOptions={(options, params) => {
                const filtered = filter(options, params);
        
                // Suggest the creation of a new value
                if (params.inputValue !== '') {
                  filtered.push({
                    inputValue: params.inputValue,
                    name: `Add "${params.inputValue}"`,
                  });
                }
        
                return filtered;
              }}
              selectOnFocus
              clearOnBlur
              handleHomeEndKeys
              id="free-solo-with-text-demo"
              options={getAutoOptions(context, fields[fieldIdx].fieldKey)}
              getOptionLabel={(option) => {
                // Value selected with enter, right from the input
                if (typeof option === 'string') {
                  return option;
                }
                // Add "xxx" option created dynamically
                if (option.inputValue) {
                  return option.inputValue;
                }
                // Regular option
                return option.name;
              }}
              renderOption={(option) => option.name}
              freeSolo
              renderInput={(params) => (
                <TextField {...params}  error={Boolean(touched[fields[fieldIdx].fieldKey] && errors[fields[fieldIdx].fieldKey])}
                fullWidth
                key={fields[fieldIdx].fieldKey}
                id={fields[fieldIdx].fieldKey}
                helperText={touched[fields[fieldIdx].fieldKey] && errors[fields[fieldIdx].fieldKey]}
                label={fields[fieldIdx].fieldName}
                margin="normal"
                style={{margin: 10}}
                name={fields[fieldIdx].fieldKey}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values[fields[fieldIdx].fieldKey]}
                variant="outlined"/>
              )}
            />



            } else if (tp == 'list') {
              let opts = fields[fieldIdx].listOptions
              return <TextField
              select 
              key={fields[fieldIdx].fieldKey}
              error={Boolean(touched[fields[fieldIdx].fieldKey] && errors[fields[fieldIdx].fieldKey])}
              fullWidth
              id={fields[fieldIdx].fieldKey}
              helperText={touched[fields[fieldIdx].fieldKey] && errors[fields[fieldIdx].fieldKey]}
              label={fields[fieldIdx].fieldName}
              margin="normal"
              style={{margin: 10}}
              name={fields[fieldIdx].fieldKey}
              onBlur={handleBlur}
              onChange={handleChange}
              value={values[fields[fieldIdx].fieldKey]}
              variant="outlined">
              {opts.map((item, idx) => {
                return (<MenuItem value={item}>{item}</MenuItem>)
              })}
            </TextField>
            } else {
              return <TextField
              error={Boolean(touched[fields[fieldIdx].fieldKey] && errors[fields[fieldIdx].fieldKey])}
              fullWidth
              key={fields[fieldIdx].fieldKey}
              style={{margin: 10}}
              id={fields[fieldIdx].fieldKey}
              helperText={touched[fields[fieldIdx].fieldKey] && errors[fields[fieldIdx].fieldKey]}
              label={fields[fieldIdx].fieldName}
              margin="normal"
              name={fields[fieldIdx].fieldKey}
              onBlur={handleBlur}
              onChange={fields[fieldIdx].fieldKey in specOptions ? (e) => specialUpdate(fields[fieldIdx].fieldKey, e.target.value, setFieldValue, values) : handleChange}
              value={values[fields[fieldIdx].fieldKey]}
              variant="outlined"
              type={getType(fields[fieldIdx].fieldType)}
              InputLabelProps={{
                shrink: true,
                }}
            />
            }

          }
          
          return null 

        })}
       </div>
      })}
    </div>
  }



  async function doSubmitAdhoc(v) {

    if (sku == null || sku.name == null || source == null || destination == null || amount == null || amount <= 0) {
      alert('Please select a source, sku, destination, and amount of barley to transfer.')
      return
    }

    if (source.name == destination.name) {
      alert('Source and destination must differ.')
      return
    }

    var transaction = null

    if (Firebase.ONLINE) {

      setLoading(true)


      var newVal = v 
      newVal['netweightkg'] = parseInt(amount) 
      newVal['warehouse'] = destination
      newVal['creationDate'] = Date.now()
      newVal['sku'] = sku
      newVal['requestId'] = '-1'
  
      var baseSet = {'sku': sku.name}
      baseSet['warehouse'] = source.name ? source.name : source
      var newSet = {'sku': sku.name}
      newSet['warehouse'] = destination.name
  
      newVal['source'] = source.name ? source.name : source
      console.log(baseSet)
      console.log(newSet)
      console.log(newVal)


      let shortCode = source.shortcode ? source.shortcode : null
      //let transaction = await Firebase.createTransferBarleyOut({'newVal': newVal, 'newSet': newSet, 'baseSet': baseSet, 'fields': state.fields})
      //console.log('resulting')
      //console.log(transaction)


      // SCRATCH@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@









      var newVal = v 
      if (newVal['purity'] === '') {
        delete newVal['purity']
      }
      if (newVal['moisture'] === '') {
        delete newVal['moisture']
      }
      console.log(newVal)
      var oldSet = {'sku': sku.name}
      oldSet['warehouse'] = newVal['source']
      var newSet = {'sku': sku.name}
      newSet['warehouse'] = newVal['warehouse']['name']
  
      //var oldSet = determineStockMembership(oldQuery)
      //var newSet = determineStockMembership(newQuery)
      let stockKey = getDictId(newSet)
      let oldStockKey = getDictId(oldSet)
      if ('netweightkg' in newVal) {
        newSet['netweightkg'] = newVal['netweightkg']
      } else {
        newSet['netweightkg'] = 0
      }
      newVal['status'] = 'In Progress'
  
      console.log(stockKey)
      console.log(oldStockKey)
      // transfer out number
      let docRef1 = Firebase.firestore.collection('projects').doc(Firebase.userProject).collection('barleyConstants').doc('transferOutNumber');
      // reference of old barley out request, of which we are filling some of the weight order (this will be decreased)
      // NO REQUEST IN THIS INSTANCE
      // var requestRef = Firebase.firestore.collection('projects').doc(Firebase.userProject).collection("barleyOut").doc(request.id)
      // reference of new barley transfer out request, which will be created now
      var docRef = Firebase.firestore.collection('projects').doc(Firebase.userProject).collection("transferBarleyOut").doc()
      // Reference of stock in transit to new warehouse (this will be increased)
      let stockDocRef = Firebase.firestore.collection('projects').doc(Firebase.userProject).collection('barleyStocksTransit').doc(stockKey)
      // Reference of pending stock in source warehouse (this will be decreased)
      // THIS BECOMES MAJOR STOCK OF OLD WAREHOUSE
      let stockDocRefPending = Firebase.firestore.collection('projects').doc(Firebase.userProject).collection('barleyStocks').doc(oldStockKey)
      var newValId = docRef.id 
      var newQueryResult = null 
      var oldQueryResult = null 
      var oldRequestRef = null 
      var oldNumericSave = null;
      var newNumericValue = null;
      var oldNumericValue = null;
  

      var customCreations = {}
      state.fields.map((field, idx) => {
      if (field.fieldType === 'custom') {
          if (field.fieldKey in newVal && newVal[field.fieldKey]['customId'] === -1) {
          // must create this val 
          customCreations[field.fieldKey] =  newVal[field.fieldKey]['name']
          }
      }
      })

      let arrayOfMemberIds = Object.keys(customCreations)
      var updCode = {}
     transaction = await Firebase.firestore.runTransaction(t => {

        return t.get(docRef1)
        .then(doc => {
          // Get proper ID for this transfer out request
        //newNumericValue = doc.data().value + 1;  //You calculate the new value
        //oldNumericValue = doc.data().value
        //oldNumericSave = oldNumericValue

        if (shortCode) {
          if (shortCode in doc.data()) {
            newNumericValue = doc.data()[shortCode] + 1;  //You calculate the new value
            oldNumericValue = shortCode + '-' + doc.data()[shortCode]
            oldNumericSave = shortCode + '-' + doc.data()[shortCode]
            updCode[shortCode] = newNumericValue
            //return t.update(docRef1 , updCode);
          } else {
            newNumericValue = 2;  //You calculate the new value
            oldNumericValue = shortCode + '-' + 1
            oldNumericSave = shortCode + '-' + 1
            updCode[shortCode] = newNumericValue
           // return t.update(docRef1 , updCode);
          }
          
        } else {
          newNumericValue = doc.data().value + 1;  //You calculate the new value
          oldNumericValue = doc.data().value
          oldNumericSave = oldNumericValue
          updCode['value'] = newNumericValue
          //return t.update(docRef1 , {value : newNumericValue});
        }


        return t.get(stockDocRef )
        }).then(querySnapshotNew => {
          // Get new stock ref for the in transit barley to dest
          if (querySnapshotNew.exists) {
            newQueryResult = querySnapshotNew.data()
  
          } else {
            newQueryResult = null 
          }
  
          return t.get(stockDocRefPending)
          
        }).then(querySnapshotOld => {
          // Get old stock ref for the barley to remove from source
          if (querySnapshotOld.exists) {
            oldQueryResult = querySnapshotOld.data()
          } else {
              const errM = {'msg': "Stock does not exist."}
              throw errM
          }
          // update id value
          return t.update(docRef1 , updCode);
          }).then(t => {
            // update custom IDs if needed
            arrayOfMemberIds.forEach(function(memberId) {
                let docRefNext = Firebase.firestore.collection('projects').doc(Firebase.userProject).collection('barleyInCustomObjects').doc('contents').collection('projects').doc(Firebase.userProject).collection(memberId).doc()
                console.log('here t first')
                console.log(t)
                t = t.set(
                docRefNext,
                { name: customCreations[memberId] }
                )
                console.log('setting id of ' + docRefNext.id)
                newVal[memberId] = {'customId': docRefNext.id, 'name': customCreations[memberId]}
                
            });
            return t
            }).then(t => {
              // make the new transfer out item 
            newVal['transferOutNumber'] = oldNumericValue
            return t.set(docRef ,newVal);
            }).then(t => {
            // Update the pending stock

            var updateValOld = {}
            if (!oldQueryResult['netweightkg'] || oldQueryResult['netweightkg'] < newVal['netweightkg']) {
              const errM = {'msg': "Not enough barley. Stock contains " + oldQueryResult['netweightkg'] + "kg, while trying to transfer " + newVal['netweightkg'] + "kg."}
              throw errM
            }
            updateValOld['netweightkg'] = oldQueryResult['netweightkg'] - newVal['netweightkg']
    
            return t.update(stockDocRefPending ,updateValOld);
  
          }).then(t => {
            // update the transit stock
            if (newQueryResult === null) {
              return t.set(stockDocRef, newSet)
            } else {
              var updateValNew = {}
              updateValNew['netweightkg'] = newQueryResult['netweightkg'] + newVal['netweightkg']
              return t.update(stockDocRef, updateValNew);
            }
          });
      }).then(result => {
        
        console.log('Transaction success!' + result);
        return {'transaction': true, 'newVal': newVal, 'oldNumericSave': oldNumericSave, 'newValId': newValId}
      }).catch(err => {
        console.log('Transaction failure:', err);
        return null
      });



      // SCRATCH@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@



    } else {
      setLoading(true)
      alert('no offline implementation yet')

    }

 

  if (transaction && transaction.transaction != null) {
    newVal = transaction.newVal 
    var oldNumericSave = transaction.oldNumericSave
    var newValId = transaction.newValId

    let s = async () => {
        var datPack = {}
        const imageRef = Firebase.storage.ref('test').child('pdf_for_whout_' + oldNumericSave + '.pdf')
        let v = await imageRef.getDownloadURL();
        console.log(v)
        console.log('got it')
        datPack['pdflink'] = v 
        datPack['transferBarleyOutId'] = newValId
        let snone = () => console.log('x')
        Firebase.provideWarehouseOutLink(datPack, snone, snone)
        //window.open(v)

    } 

    let f = () => {
     
    }
    var datxx = {}
    datxx['val'] = newVal
    datxx['userId'] = 'test'
    
    Firebase.generateWarehouseOut(datxx, s, f)

  }

  setLoading(false)
  if (transaction && transaction.transaction == null && transaction.failMessage) {
    alert(transaction.failMessage.msg)
  } else {
    navigate(-1)
  }
  //console.log(transaction)
  //console.log(transaction)


  }



  async function doSubmit(v) {
    if (request == null) {
      doSubmitAdhoc(v)
      return
    }

    if (sku == null || sku.name == null || source == null || destination == null || amount == null || amount <= 0 || request == null) {
      alert('Please select a source, category, destination, and amount of barley to transfer.')
      return
    }

    if (source.name == destination.name) {
      alert('Source and destination must differ.')
      return
    }

    var transaction = null

    if (Firebase.ONLINE) {

      setLoading(true)


      var newVal = v 
      newVal['netweightkg'] = parseInt(amount) 
      newVal['warehouse'] = destination
      newVal['creationDate'] = Date.now()
      newVal['sku'] = sku
      newVal['requestId'] = request.id 
      newVal['requestShortId'] = request.grnOutNumber
  
      var baseSet = {'sku': sku.name}
      baseSet['warehouse'] = source.name ? source.name : source
      var newSet = {'sku': sku.name}
      newSet['warehouse'] = destination.name
  
      newVal['source'] = source.name ? source.name : source
      console.log(baseSet)
      console.log(newSet)
      console.log(newVal)
      //return 
      //let transaction = await Firebase.createTransferBarleyOut({'newVal': newVal, 'newSet': newSet, 'baseSet': baseSet, 'fields': state.fields})
      //console.log('resulting')
      //console.log(transaction)


      // SCRATCH@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@

      console.log('here is this fin trash')
      console.log(request)

      let shortCode = request && request.shortcode ? request.shortcode : null



      var newVal = v 
      if (newVal['purity'] === '') {
        delete newVal['purity']
      }
      if (newVal['moisture'] === '') {
        delete newVal['moisture']
      }
      console.log(newVal)
      var oldSet = {'sku': sku.name}
      oldSet['warehouse'] = newVal['source']
      var newSet = {'sku': sku.name}
      newSet['warehouse'] = newVal['warehouse']['name']
  
      //var oldSet = determineStockMembership(oldQuery)
      //var newSet = determineStockMembership(newQuery)
      let stockKey = getDictId(newSet)
      let oldStockKey = getDictId(oldSet)
      if ('netweightkg' in newVal) {
        newSet['netweightkg'] = newVal['netweightkg']
      } else {
        newSet['netweightkg'] = 0
      }
      newVal['status'] = 'In Progress'
  
      console.log(stockKey)
      console.log(oldStockKey)
      // transfer out number
      let docRef1 = Firebase.firestore.collection('projects').doc(Firebase.userProject).collection('barleyConstants').doc('transferOutNumber');
      // reference of old barley out request, of which we are filling some of the weight order (this will be decreased)
      var requestRef = Firebase.firestore.collection('projects').doc(Firebase.userProject).collection("barleyOut").doc(request.id)
      // reference of new barley transfer out request, which will be created now
      var docRef = Firebase.firestore.collection('projects').doc(Firebase.userProject).collection("transferBarleyOut").doc()
      // Reference of stock in transit to new warehouse (this will be increased)
      let stockDocRef = Firebase.firestore.collection('projects').doc(Firebase.userProject).collection('barleyStocksTransit').doc(stockKey)
      // Reference of pending stock in source warehouse (this will be decreased)
      let stockDocRefPending = Firebase.firestore.collection('projects').doc(Firebase.userProject).collection('barleyStocksPending').doc(oldStockKey)
      var newValId = docRef.id 
      var newQueryResult = null 
      var oldQueryResult = null 
      var oldRequestRef = null 
      var oldNumericSave = null;
      var newNumericValue = null;
      var oldNumericValue = null;
  

      var customCreations = {}
      state.fields.map((field, idx) => {
      if (field.fieldType === 'custom') {
          if (field.fieldKey in newVal && newVal[field.fieldKey]['customId'] === -1) {
          // must create this val 
          customCreations[field.fieldKey] =  newVal[field.fieldKey]['name']
          }
      }
      })
      var updCode = {}
      let arrayOfMemberIds = Object.keys(customCreations)
      
     transaction = await Firebase.firestore.runTransaction(t => {

        return t.get(docRef1)
        .then(doc => {



          if (shortCode) {
            if (shortCode in doc.data()) {
              newNumericValue = doc.data()[shortCode] + 1;  //You calculate the new value
              oldNumericValue = shortCode + '-' + doc.data()[shortCode]
              oldNumericSave = shortCode + '-' + doc.data()[shortCode]
              updCode[shortCode] = newNumericValue
              //return t.update(docRef1 , updCode);
            } else {
              newNumericValue = 2;  //You calculate the new value
              oldNumericValue = shortCode + '-' + 1
              oldNumericSave = shortCode + '-' + 1
              updCode[shortCode] = newNumericValue
             // return t.update(docRef1 , updCode);
            }
            
          } else {
            newNumericValue = doc.data().value + 1;  //You calculate the new value
            oldNumericValue = doc.data().value
            oldNumericSave = oldNumericValue
            updCode['value'] = newNumericValue
            //return t.update(docRef1 , {value : newNumericValue});
          }


          /* Get proper ID for this transfer out request
        if (doc.exists) {
          newNumericValue = doc.data().value + 1
          oldNumericValue = request.grnOutNumber + '-' + doc.data().value
          oldNumericSave = request.grnOutNumber + '-' + doc.data().value
        } else {
          newNumericValue = 2
          oldNumericValue = request.grnOutNumber + '-' + 1
          oldNumericSave = request.grnOutNumber + '-' + 1
        }*/
        
        //  newNumericValue = doc.data().value + 1;  //You calculate the new value
        //oldNumericValue = doc.data().value
        //oldNumericSave = oldNumericValue
        return t.get(requestRef)
        
        }).then(querySnapshotReq => {
          // Get barley request that should be linked to this transfer out request
          if (querySnapshotReq.exists) {
            oldRequestRef = querySnapshotReq.data()
          } else {
            const errM = {'msg': "Request does not exist."}
            throw errM
          }
          return t.get(stockDocRef )
        }).then(querySnapshotNew => {
          // Get new stock ref for the in transit barley to dest
          if (querySnapshotNew.exists) {
            newQueryResult = querySnapshotNew.data()
  
          } else {
            newQueryResult = null 
          }
  
          return t.get(stockDocRefPending)
          
        }).then(querySnapshotOld => {
          // Get old stock ref for the pending barley to remove from source
          if (querySnapshotOld.exists) {
            oldQueryResult = querySnapshotOld.data()
          } else {
              const errM = {'msg': "Pending stock does not exist."}
              throw errM
          }
          // update id value
          //if (newNumericValue == 2) {
          //  return t.set(docRef1 , updCode);
          //} else {
            // assume the reference will already exist, hence commenting the other cases here
            return t.update(docRef1 , updCode);
          //}

          }).then(t => {
            // update custom IDs if needed
            arrayOfMemberIds.forEach(function(memberId) {
                let docRefNext = Firebase.firestore.collection('projects').doc(Firebase.userProject).collection('barleyInCustomObjects').doc('contents').collection('projects').doc(Firebase.userProject).collection(memberId).doc()
                console.log('here t first')
                console.log(t)
                t = t.set(
                docRefNext,
                { name: customCreations[memberId] }
                )
                console.log('setting id of ' + docRefNext.id)
                newVal[memberId] = {'customId': docRefNext.id, 'name': customCreations[memberId]}
                
            });
            return t
            }).then(t => {
              // make the new transfer out item 
            newVal['transferOutNumber'] = oldNumericValue
            return t.set(docRef ,newVal);
            }).then(t => {
            // Update the pending stock

            var updateValOld = {}
            if (!oldQueryResult['netweightkg'] || oldQueryResult['netweightkg'] < newVal['netweightkg']) {
              const errM = {'msg': "Not enough barley. Stock contains " + oldQueryResult['netweightkg'] + "kg, while trying to transfer " + newVal['netweightkg'] + "kg."}
              throw errM
            }
            updateValOld['netweightkg'] = oldQueryResult['netweightkg'] - newVal['netweightkg']
    
            return t.update(stockDocRefPending ,updateValOld);
  
          }).then(t => {
            // Update the old request

            var updateValReq = {}
            if (oldRequestRef.approval == 'rejected') {
              const errM = {'msg': "Request was declined."}
              throw errM 
            }
            let oldRequestUsed = 'usedkg' in oldRequestRef ? oldRequestRef['usedkg'] : 0
            if (!oldRequestRef['netweightkg']) {
              const errM = {'msg': "Request does not have a quota."}
              throw errM 
            }
            let remaining = oldRequestRef['netweightkg'] - oldRequestUsed
            if (remaining < newVal['netweightkg']) {
              const errM = {'msg': "Not enough barley left in request. Request contains " +  remaining + "kg, while trying to tranfer " + newVal['netweightkg'] + "kg."}
              throw errM
            }
            updateValReq['usedkg'] = oldRequestUsed + newVal['netweightkg']
    
            return t.update(requestRef ,updateValReq);
  
          })
          .then(t => {
            // update the transit stock
            if (newQueryResult === null) {
              return t.set(stockDocRef, newSet)
            } else {
              var updateValNew = {}
              updateValNew['netweightkg'] = newQueryResult['netweightkg'] + newVal['netweightkg']
              return t.update(stockDocRef, updateValNew);
            }
          });
      }).then(result => {
        
        console.log('Transaction success!' + result);
        return {'transaction': true, 'newVal': newVal, 'oldNumericSave': oldNumericSave, 'newValId': newValId}
      }).catch(err => {
        console.log('Transaction failure:', err);
        return {'transaction': null, 'failMessage': err}
      });

      Firebase.trackMixpanel('createTransferOutOnline', {'result': transaction})








      // SCRATCH@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@



    } else {
      setLoading(true)
      alert('no offline implementation yet')

    }

 

  if (transaction && transaction.transaction != null) {
    newVal = transaction.newVal 
    var oldNumericSave = transaction.oldNumericSave
    var newValId = transaction.newValId

    let s = async () => {
        var datPack = {}
        const imageRef = Firebase.storage.ref('test').child('pdf_for_whout_' + oldNumericSave + '.pdf')
        let v = await imageRef.getDownloadURL();
        console.log(v)
        console.log('got it')
        datPack['pdflink'] = v 
        datPack['transferBarleyOutId'] = newValId
        let snone = () => console.log('x')
        Firebase.provideWarehouseOutLink(datPack, snone, snone)
        //window.open(v)

    } 

    let f = () => {
     
    }
    var datxx = {}
    datxx['val'] = newVal
    datxx['userId'] = 'test'
    
    Firebase.generateWarehouseOut(datxx, s, f)

  }

  setLoading(false)
  console.log('resulting tx')
  if (transaction && transaction.transaction == null && transaction.failMessage) {
    alert(transaction.failMessage.msg)
  } else {
    navigate('/app/creationsummary', {state: {headerText: 'Barley Transfer Out: ' + transaction.oldNumericSave, secondaryText: 'Successfully created report.' }, replace: true})
    //navigate(-1)
  }
  //console.log(transaction)
  //console.log(transaction)


  }

  function renderOptionName(option) {
    if (option.usedkg) {
      let rem = option.netweightkg - option.usedkg
      return option && option.warehouse ? '[' + option.grnOutNumber + '] ' + option.warehouse.name + ' (' + rem + 'kg / ' + option.netweightkg + 'kg)' : 'option'
    }
    return option && option.warehouse ? '[' + option.grnOutNumber + '] ' + option.warehouse.name + ' (' + option.netweightkg + 'kg / ' + option.netweightkg + 'kg)' : 'option'
  }


  return (
    <MyContext.Consumer>{context => (
  <Page
    className={classes.root}
    height='100%'
    title="Request Barley Out"
    id="ff2"
    backgroundColor={classes.root.backgroundColor}
  >
    <Box
      display="flex"
      flexDirection="column"
      id="bb2"
      backgroundColor={classes.root.backgroundColor}

    >
      <Container id="cc2"  backgroundColor={classes.root.backgroundColor}>
        <Formik
        id='f2'
          initialValues={{

          }}
          validationSchema={
            Yup.object().shape({

            })
          }
          onSubmit={(values) => {
            doSubmit(values)
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting2,
            setSubmitting, 
            touched,
            values,
            setFieldValue
          }) => (
            <form id='fu2' onSubmit={handleSubmit}>
                  <div id='fkx1' style={{alignItems: 'center', marginTop: 5}}>
              <Box mb={3}>
                <Typography
                  color="textPrimary"
                  variant="h2"
                >
                  Process Transfer Out
                </Typography>
               
              </Box>

              <Stepper activeStep={activeStep} style={{width: '100%', marginBottom: 15}}>
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>

            </Step>
          );
        })}
      </Stepper>

{activeStep == 0 ? 

<div>




{state.requestPreset == null ?


<Autocomplete
  id="request"
  disabled={request != null}
  options={getAutoOptions(context, 'warehouse')}
  getOptionLabel={(option) => option.name ? option.name : option}
  value={source}
  onChange={(e, value) => {
    
    if (value != null) {
     
      setSource(value)
    }
    

  }}
  renderInput={(params) => <TextField {...params}
  disabled={request != null}
  label="Source Facility"
  margin="normal"
  id="source"
  name="source"
  variant="outlined"
  value={source}
  defaultValue={source} />}
/>


:
<TextField
            style={{margin: 5}}
              fullWidth
              disabled
              label={'Source Facility'}
              onChange={(e) => setSource(e.target.value)}
              value={source.name}
              variant="outlined">
            </TextField>
}


<Autocomplete
  id="sku"
  disabled={request != null}
  options={getAutoOptions(context, 'sku')}
  getOptionLabel={(option) => option.name}
  value={sku}
  onChange={(e, value) => {   
    if (value != null) {
      setSku(value)
    }
  }}
  renderInput={(params) => <TextField {...params}
  disabled={request != null}
  label="SKU"
  margin="normal"
  id="sku"
  name="sku"
  variant="outlined"
  value={sku}
  defaultValue={sku} />}
/>


            <Autocomplete
  id="destination"
  disabled={request != null}
  options={getAutoOptions(context, 'warehouse')}
  getOptionLabel={(option) => option.name}
  value={destination}
  onChange={(e, value) => {   
    if (value != null) {
      setDestination(value)
    }
  }}
  renderInput={(params) => <TextField {...params}
  disabled={request != null}
  label="Destination"
  margin="normal"
  id="destination"
  name="destination"
  variant="outlined"
  value={destination}
  defaultValue={destination} />}
/>
  </div>

: 

<div>{renderContent(state.fields.filter((item, idx) => {
  return stepFields[activeStep].includes(item.fieldKey)
}), touched, errors, handleBlur, handleChange, values, setFieldValue, context)}</div>
}
</div>
{activeStep == 2 ? 
              <div>
                <Typography>
                  {'Net Weight: ' + (amount ? amount : 0) + 'kg'}
                </Typography>
                </div>

                : null}

<Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'row', pt: 2 }}>


<Button
                       color="primary"
                       disabled={activeStep == 0}
                       size="large"
                       id="sendButtonNext"
                       variant="contained"
                       onClick={handleBack}
                       style={{margin: 5, width: 150}}
                     >
                       Back
                     </Button>



{activeStep < steps.length - 1 ? 
                       <Button
                       color="primary"
                       disabled={loading }
                       size="large"
                       id="sendButtonNext"
                       variant="contained"
                       onClick={handleNext}
                       style={{margin: 5, width: 150}}
                     >
                       Next
                     </Button>
: 
<Box my={2} style={{alignSelf: 'center', flexDirection: 'column', display: 'flex'}}>

<Button
color="primary"
style={{margin: 5, width: 150}}
disabled={loading || amount == null || amount <= 0}
id="sendButtonNext"
variant="contained"
onClick={() => doSubmit(values)}
>
Confirm
</Button>
</Box>
}
</Box>


              {loading ? <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: 50}}><CircularProgress/></div> : null}

              
            </form>
          )}
        </Formik>
      </Container>
    </Box>
  </Page>
        )}</MyContext.Consumer>
  )
};

export default NewTransferBarleyOutView;