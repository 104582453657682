import React, { useState, useEffect, Component } from 'react';
import {
  Box,
  Container,
  makeStyles
} from '@material-ui/core';

import Firebase from 'src/Firebase.js'
import CustomObjectsTable from './CustomObjectsTable';
import MyContext from 'src/MyContext.js'
import { DataGrid } from '@mui/x-data-grid';

const columns = [
  { field: 'id', headerName: 'ID', width: 90 },
  {
    field: 'firstName',
    headerName: 'First name',
    width: 150,
    editable: true,
  },
  {
    field: 'lastName',
    headerName: 'Last name',
    width: 150,
    editable: true,
  },
  {
    field: 'age',
    headerName: 'Age',
    type: 'number',
    width: 110,
    editable: true,
  },
  {
    field: 'fullName',
    headerName: 'Full name',
    description: 'This column has a value getter and is not sortable.',
    sortable: false,
    width: 160,
    valueGetter: (params) =>
      `${params.getValue(params.id, 'firstName') || ''} ${
        params.getValue(params.id, 'lastName') || ''
      }`,
  },
];

const rows = [
  { id: 1, lastName: 'Snow', firstName: 'Jon', age: 35 },
  { id: 2, lastName: 'Lannister', firstName: 'Cersei', age: 42 },
  { id: 3, lastName: 'Lannister', firstName: 'Jaime', age: 45 },
  { id: 4, lastName: 'Stark', firstName: 'Arya', age: 16 },
  { id: 5, lastName: 'Targaryen', firstName: 'Daenerys', age: null },
  { id: 6, lastName: 'Melisandre', firstName: null, age: 150 },
  { id: 7, lastName: 'Clifford', firstName: 'Ferrara', age: 44 },
  { id: 8, lastName: 'Frances', firstName: 'Rossini', age: 36 },
  { id: 9, lastName: 'Roxie', firstName: 'Harvey', age: 65 },
];

class CustomObjectsTableWrapper extends Component {


  constructor() {
    super()
    this.state = {

      tableItems: [],
      existingDict: {}

    }
    this.listeners = {}

  }



 dictToList(d) {
   if (d) {
    return Object.keys(d).map((key, idx) => {
      return d[key]
    })
   } else {
     return []
   }
 }


  render() {
    return (
      <MyContext.Consumer>{context => (
        <div>
          {this.props.workflow ? 
              <CustomObjectsTable customKey={this.props.customKey}
              objectFields={context[this.props.objectFieldsKey]} 
              objectList={context[this.props.objectsKey]} barleyInCustomObjects={context.barleyInCustomObjects}
              context={context} workflow={this.props.workflow} customFieldsKey={this.props.objectFieldsKey}/>
          : 
          <CustomObjectsTable customKey={this.props.customKey} 
          objectFields={this.dictToList(context.barleyInCustomObjectFields[this.props.customKey])} 
          objectList={this.dictToList(context.barleyInCustomObjects[this.props.customKey])} barleyInCustomObjects={context.barleyInCustomObjects}
          context={context} workflow={this.props.workflow}/>
          }


    </div>
      )}</MyContext.Consumer>
    )
  }


}

export default CustomObjectsTableWrapper