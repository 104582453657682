import React, { useEffect } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Button,
  Divider,
  Drawer,
  Hidden,
  List,
  Typography,
  makeStyles
} from '@material-ui/core';
import {
  AlertCircle as AlertCircleIcon,
  BarChart as BarChartIcon,
  PieChart as PieChartIcon,
  Lock as LockIcon,
  Settings as SettingsIcon,
  ShoppingBag as ShoppingBagIcon,
  User as UserIcon,
  UserPlus as UserPlusIcon,
  Users as UsersIcon,
  Map as MapIcon,
  ArrowRight as ArrowRightIcon,
  ArrowLeft as ArrowLeftIcon,
  Database as DatabaseIcon,
  Truck as TruckIcon,
} from 'react-feather';
import { DirectionsBoat, Train, Assignment, Storage } from '@material-ui/icons';
import NavItem from './NavItem';
import NavItemCollapse from './NavItemCollapse'
import moment from 'moment';

const RATING_REQ = 1
const itemsShipper = [
  {
    href: '/app/dashboard',
    icon: PieChartIcon,
    title: 'Dashboard'
  },
  {
    href: '/app/analytics',
    icon: BarChartIcon,
    title: 'Analytics'
  },
  {
    href: '/app/plans',
    icon: Assignment,
    title: 'Yearly Plans'
  },
  {
    href: '/app/operations',
    icon: DirectionsBoat,
    title: 'Vessel Operations'
  },
  {
    href: '/app/trains',
    icon: Train,
    title: 'Train Plans'
  },
  {
    href: '/app/dispatch',
    icon: TruckIcon,
    title: 'Dispatch Trucks'
  },
  {
    href: '/app/transferbarleyin',
    icon: Storage,
    title: 'Transfer In'
  },
  {
    href: '/app/account',
    icon: SettingsIcon,
    title: 'Admin'
  },
];

const itemsFinance = [
  {
    href: '/app/dashboard',
    icon: PieChartIcon,
    title: 'Dashboard'
  },
  {
    href: '/app/analytics',
    icon: BarChartIcon,
    title: 'Analytics'
  },
  {
    href: '/app/plans',
    icon: Assignment,
    title: 'Yearly Plans'
  },
  {
    href: '/app/operations',
    icon: DirectionsBoat,
    title: 'Vessel Operations'
  },
  {
    href: '/app/trains',
    icon: Train,
    title: 'Train Plans'
  },
  {
    href: '/app/dispatch',
    icon: TruckIcon,
    title: 'Dispatch Trucks'
  },
  {
    href: '/app/transferbarleyin',
    icon: Storage,
    title: 'Transfer In'
  },
]

const itemsWarehouse = [
  {
    href: '/app/dashboard',
    icon: PieChartIcon,
    title: 'Dashboard'
  },
  {
    href: '/app/plans',
    icon: ArrowRightIcon,
    title: 'Plans'
  },
  {
    href: '/app/operations',
    icon: ArrowLeftIcon,
    title: 'Operations'
  },
  {
    href: '/app/trains',
    icon: ArrowLeftIcon,
    title: 'Trains'
  },
  {
    href: '/app/transferbarleyin',
    icon: TruckIcon,
    title: 'Arrivals / Transfer In'
  },
  {
    href: '/app/transferbarleyout',
    icon: TruckIcon,
    title: 'Transfer Out'
  },

  {
    href: '/app/suppliers',
    icon: UsersIcon,
    title: 'Suppliers'
  },
  {
    href: '/app/account',
    icon: SettingsIcon,
    title: 'Account'
  },
]

const itemsFactory = [
  {
    href: '/app/dashboard',
    icon: PieChartIcon,
    title: 'Dashboard'
  },
  {
    href: '/app/barleyout',
    icon: ArrowLeftIcon,
    title: 'Request Barley'
  },
  {
    href: '/app/transferbarleyin',
    icon: TruckIcon,
    title: 'Transfer Barley In'
  },
  {
    href: '/app/account',
    icon: SettingsIcon,
    title: 'Account'
  },
]

const itemsAdmin = [
  {
    href: '/app/dashboard',
    icon: BarChartIcon,
    title: 'Home'
  },
  {
    href: '/app/customers',
    icon: UsersIcon,
    title: 'Jobs'
  },
  {
    href: '/app/contracts',
    icon: ShoppingBagIcon,
    title: 'Contracts'
  },
  {
    href: '/app/freightorders',
    icon: ShoppingBagIcon,
    title: 'Freight Orders'
  },
  {
    href: '/app/associations',
    icon: ShoppingBagIcon,
    title: 'Associations'
  },
  {
    href: '/app/users',
    icon: ShoppingBagIcon,
    title: 'Users'
  },
  {
    href: '/app/vehicles',
    icon: ShoppingBagIcon,
    title: 'Vehicles'
  },
  {
    href: '/app/map',
    icon: MapIcon,
    title: 'Map'
  },
  {
    href: '/app/invoices',
    icon: BarChartIcon,
    title: 'Invoices'
  },
  {
    href: '/app/payments',
    icon: BarChartIcon,
    title: 'Payments'
  },
  {
    href: '/app/account',
    icon: UserIcon,
    title: 'Account'
  },


];

const itemsLimitedRole0 = [
  {
    href: '/app/dashboard',
    icon: BarChartIcon,
    title: 'Home'
  },
  {
    href: '/app/freightorders',
    icon: ShoppingBagIcon,
    title: 'Freight Orders'
  },
  {
    href: '/app/customers',
    icon: UsersIcon,
    title: 'Jobs'
  },
  
  {
    href: '/app/account',
    icon: UserIcon,
    title: 'Account'
  },


];

const itemsLimited = [
  {
    href: '/app/dashboard',
    icon: BarChartIcon,
    title: 'Home'
  },
  {
    href: '/app/customers',
    icon: UsersIcon,
    title: 'Jobs'
  },
  
  {
    href: '/app/account',
    icon: UserIcon,
    title: 'Account'
  },


];

const itemsAssoc = [
  {
    href: '/app/dashboard',
    icon: BarChartIcon,
    title: 'Home'
  },
  {
    href: '/app/customers',
    icon: UsersIcon,
    title: 'Jobs'
  },
  {
    href: '/app/contracts',
    icon: ShoppingBagIcon,
    title: 'Contracts'
  },
  {
    href: '/app/users',
    icon: ShoppingBagIcon,
    title: 'Users'
  },
  {
    href: '/app/vehicles',
    icon: ShoppingBagIcon,
    title: 'Vehicles'
  },
  {
    href: '/app/map',
    icon: MapIcon,
    title: 'Map'
  },
  {
    href: '/app/account',
    icon: UserIcon,
    title: 'Account'
  },


];

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)'
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64
  }
}));

const NavBar = ({ onMobileClose, openMobile, userInfo, warehouseUserInfo, isAdmin, isLimited, connected, wentOffline, warehouseRole }) => {
  const classes = useStyles();
  const location = useLocation();

  function getRouteItems(admin, limited) {
    if (!admin) {
      return itemsShipper
    } else if (limited == 1) {
      return itemsLimited
    } else if (limited == 0) {
      return itemsLimitedRole0
    } else {
      return itemsAdmin
    }
  }

  function getRouteItemsWarehouse(warehouseRole) {
    if (warehouseRole == 'Admin') {
      return itemsShipper
    } else if (warehouseRole != null) {
      return itemsFinance
    } else {
      return []
    }
  }

  function renderUserAvatar(v) {
    if ('profilePic' in v) {
      return v.profilePic
    } else {
      return '/static/images/avatars/plc.png'
    }

  }

  function renderUserName(v) {
    var rets = ''
    if ('firstName' in v) {
      rets += v.firstName
    } 
    if ('middleName' in v) {
      rets += ' ' + v.middleName
    } 
    if ('lastName' in v) {
      rets += ' ' + v.lastName
    }
    return rets
  }

  function mathRound(a, b) {
    return Math.round((a/b) * 100) / 100
  }

  function totalRatingCalc(userInfo) {
    var defText = 'No rating yet!'

    if (userInfo && userInfo.totalRatings) {
      if (userInfo.totalRatings < RATING_REQ) {
        return defText
      }

        if (!userInfo.pickupRatings || !userInfo.dropoffRatings) {
          return defText
        }
        return mathRound((userInfo.pickupRatings + userInfo.dropoffRatings), (2 * userInfo.totalRatings)) + ' / 5'
     

    }
    return defText
  }

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const content = (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
    >
      <Box
        alignItems="center"
        display="flex"
        flexDirection="column"
        p={2}
      >
        <Avatar
          className={classes.avatar}
          component={RouterLink}
          src={renderUserAvatar(warehouseUserInfo)}
          to="/app/account"
        />
        <Typography
          className={classes.name}
          variant="h5"
        >
          {warehouseUserInfo.name}
        </Typography>
        <Typography
          color="textSecondary"
          variant="body2"
          style={{color: connected ? 'mediumspringgreen' : 'rgb(242,201,0)'}}
        >
          {userInfo.companyName}
        </Typography>
       {false ? null : <Typography
          color="textSecondary"
          variant="body2"
        >
          {'Role Assignment: ' + warehouseRole}
        </Typography>} 
        <Typography
          color={"textSecondary"}
          variant="body2"
          style={{color: connected ? 'mediumspringgreen' : 'rgb(242,201,0)'}}
        >
          {connected ? 'Online' : wentOffline ? 'Offline Since ' + moment(wentOffline).format('DD/MM/YYYY HH:mm:ss'): 'Offline'}
        </Typography>
        
      </Box>
      <Divider />
      <Box p={2}>
        <List>
          {getRouteItemsWarehouse(warehouseRole).map((item, idx) => {
            if (item.title != 'Admin') {
              return <NavItem
              href={item.href}
              key={item.title}
              title={item.title}
              icon={item.icon}
            />
            } else {
              return <NavItemCollapse
              href={item.href}
              key={item.title}
              title={item.title}
              icon={item.icon}
            />
            }

       })}
        </List>
      </Box>
      <Box flexGrow={1} />
      <Box
        p={2}
        m={2}
        bgcolor="background.dark"
      >
        <Typography
          align="center"
          gutterBottom
          variant="h4"
        >
          {'Built with ❤ by Garri Logistics'}
        </Typography>
        <Typography
          align="center"
          variant="body2"
        >
          Connect with us by phone or email!
        </Typography>
        <Box
          display="flex"
          justifyContent="center"
          mt={2}
        >
          <Button
            color="primary"
            component="a"
            variant="contained"
            onClick={() => window.open('mailto:info@garrilogistics.com')}
          >
            {'Contact Now'}
          </Button>
        </Box>
      </Box>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

NavBar.defaultProps = {
  onMobileClose: () => {},
  openMobile: false
};

export default NavBar;
