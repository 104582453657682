import React, { useState, useEffect, Component } from 'react';
import {
  Box,
  Container,
  makeStyles
} from '@material-ui/core';
import Page from 'src/components/Page';
import Results from './Results';
import Toolbar from './Toolbar';
import data from './data';
import Firebase from 'src/Firebase.js'
import UserJobsTable from './UserJobsTable';
import MyContext from 'src/MyContext.js'


class UserJobsWrapper extends Component {


  constructor() {
    super()
    this.state = {

      offersDict: {}

    }

    this.offersListener = null

  }

  componentDidMount() {
    /*
      let self = this 
      //alert('dotry ' + 'mapTransportersToOffers/' + this.props.targetUid)
      this.offersListener = Firebase.database.ref('mapTransportersToOffers/' + this.props.targetUid)
    this.offersListener.on('value',  (data) => {
        if (!data.exists()) {
            alert('no for ' + 'mapTransportersToOffers/' + this.props.targetUid)
            self.setState({
                offersDict: {}
            })
        } else {
            alert('yes for ' + 'mapTransportersToOffers/' + this.props.targetUid + ' ' + JSON.stringify(data.val()) )
            self.setState({
                offersDict: data.val()
            })
        }
    }, function (error) {
        alert(JSON.stringify(error))
    })*/
  }

  componentWillUnmount() {
    /*
      if (this.offersListener != null) {
          this.offersListener.off('value')
      }*/
  }


  getJobs(jobs, offersDict) {
    let j = jobs.filter((item, idx) => {
        return item.assignedTransporter == this.props.targetUid || item.quoteId in offersDict 
    }) 
    console.log(j)
    console.log('yea')
    console.log(this.props.targetUid)
    //console.log(jobs)
    console.log(offersDict)
    return j
  }

  getJobsDriver(jobs) {
    let j = jobs.filter((item, idx) => {
      return item.assignedDriver == this.props.targetUid
    })

    return j 
  }


  render() {

    return (
      <MyContext.Consumer>{context => (
      <UserJobsTable customers={this.getJobsDriver(context.adminJobs)} users={context.users}/>
      )}</MyContext.Consumer>
    )
  }


}

export default UserJobsWrapper