import React, {useState} from 'react';
import { Link as RouterLink, useNavigate} from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import Autocomplete from '@material-ui/lab/Autocomplete';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import {
  Box,
  Button,
  Checkbox,
  Container,
  FormHelperText,
  Link,
  TextField,
  Typography,
  makeStyles,
  Grid,
  Select,
  MenuItem
} from '@material-ui/core';
import Page from 'src/components/Page';
import parse from 'autosuggest-highlight/parse';
import throttle from 'lodash/throttle';
import Firebase from 'src/Firebase'
import MuiPhoneNumber from 'material-ui-phone-number'


const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/


const autocompleteService = { current: null };
const geocoder = {current: null};



const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  }
}));


const NewAssocationView = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  const [value, setValue] = React.useState(null);
  const [valueTwo, setValueTwo] = React.useState(null);
  const [inputValue, setInputValue] = React.useState('');
  const [options, setOptions] = React.useState([]);
  const [optionsTwo, setOptionsTwo] = React.useState([]);
  const [numVal, setNumVal] = React.useState(false);
  const [actions, setActions] = React.useState(null);
  const [locationStart, setLocationStart] = React.useState(null);
  const [locationEnd, setLocationEnd] = React.useState(null);
  const [finalSet, setFinalSet] = React.useState(null);

  const loaded = React.useRef(false);




  function addAssociation(values, setSubmitting) {
    console.log(values)
    console.log(Firebase.userInfo)
    //Firebase.gt()
    let s = (x) => {
      if (x == 200) {
        navigate(-1)
      } else {
        alert('An association with this username already exists. Please choose a new username.')
        setSubmitting(false)
      }
    } 

    let f = () => {
      setSubmitting(false)
      alert('Could not create association.')
    }

    var v = values 
    v['userType'] = 4
    console.log(v)
    Firebase.adminAddAssociation(v, s, f )

  }

  


  return (<Page
    className={classes.root}
    height='100%'
    title="Request Quote"
    id="ff2"
    backgroundColor={classes.root.backgroundColor}
  >
    <Box
      display="flex"
      flexDirection="column"
      height="90vh"
      id="bb2"
      justifyContent="center"
      backgroundColor={classes.root.backgroundColor}

    >
      <Container id="cc2" maxWidth="sm" backgroundColor={classes.root.backgroundColor}>
        <Formik
        id='f2'
          initialValues={{
           companyName: '',
           phone: '',
           userType: '',
            associationName: '',
            username: '',
            password: '',
            verified: false
          }}
          validationSchema={
            Yup.object().shape({
              companyName: Yup.string().max(255).required('Company name required'),
              phone: Yup.string().max(255).required('Phone number required'),
              username: Yup.string().max(255).required('Username required'),
              password: Yup.string().min(8).max(255).required('Password required. Must be at least 8 characters.')
            })
          }
          onSubmit={(values) => {
            //alert('try')
            console.log('trysub')
            console.log(values)
            addAssociation(values)
            //verifyCode(values.code)
            //navigate('/app/dashboard', { replace: true });
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting2,
            setSubmitting, 
            touched,
            values,
            setFieldValue
          }) => (
            <form id='fu2' onSubmit={handleSubmit}>
                  <div id='fkx1'>
              <Box mb={3}>
                <Typography
                  color="textPrimary"
                  variant="h2"
                >
                  Create New Association
                </Typography>
                

              </Box>

          
              <TextField
                error={Boolean(touched.companyName && errors.companyName)}
                fullWidth
                id="companyName"
                helperText={touched.companyName && errors.companyName}
                label="Company"
                margin="normal"
                name="companyName"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.companyName}
                variant="outlined"
              />

<div id='wi3jife'>
                <MuiPhoneNumber defaultCountry={'et'}
                regions={['america','africa']}
                onlyCountries={['us', 'et']}
                autoFormat={false}
                disableAreaCodes={true}
                  error={Boolean(touched.email && errors.email)}
                  fullWidth
                  helperText={touched.email && errors.email}
                  label="Phone"
                  id="phone"
                  margin="normal"
                  name="phone"
                  onBlur={handleBlur}
                  onChange={(e) => setFieldValue('phone', e)}
                  value={values.phone}
                  variant="outlined"
                />
</div>
<TextField
                error={Boolean(touched.username && errors.username)}
                fullWidth
                id="username"
                helperText={touched.username && errors.username}
                label="Username"
                margin="normal"
                name="username"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.username}
                variant="outlined"
              />
              <TextField
                error={Boolean(touched.password && errors.password)}
                fullWidth
                id="password"
                helperText={touched.password && errors.password}
                label="Password"
                margin="normal"
                name="password"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.password}
                variant="outlined"
              />



        <Box
                  alignItems="center"
                  display="flex"
                  ml={-1}
                >
                  <Checkbox
                    checked={values.verified}
                    name="verified"
                    onChange={handleChange}
                  />
                  <Typography
                    color="textSecondary"
                    variant="body1"
                  >
                    Verified User
                  </Typography>
                </Box>
</div>
              <Box my={2}>
                <Button
                  color="primary"
                  disabled={isSubmitting2}
                  fullWidth
                  size="large"
                  id="sendButtonNext"
                  variant="contained"
                  onClick={() => addAssociation(values, setSubmitting)}
                >
                  Confirm
                </Button>
              </Box>

              
            </form>
          )}
        </Formik>
      </Container>
    </Box>
  </Page>)
};

export default NewAssocationView;
