import Firebase from './Firebase'



export default class DataFetcher {

    static transportersList = [{'userId': '0', 'companyName': 'Unassigned'}]
    static driversList = [{'userId': '0', 'companyName': 'Unassigned'}]
    static existingTransporters = {}
    static existingDrivers = {}
    static transportersListeners = {}
    static driversListeners = {}
    static cargoTypeData = {}
    static didComplete = false;
    static onComplete() {
        console.log('notset')
    }

    static setCargoTypes() {

    }
    
    static fetchCargoTypes() {
        DataFetcher.onComplete()
        DataFetcher.didComplete = true
        Firebase.database.ref('constants/cargotypes').once('value').then((data) => {
            console.log('yhea')
            console.log(data.val())
            DataFetcher.cargoTypeData = data.val()
            DataFetcher.setCargoTypes(DataFetcher.cargoTypeData)

                        if (!DataFetcher.didComplete) {
                DataFetcher.onComplete()
                DataFetcher.didComplete = true
            }
            //alert(JSON.stringify(Firebase.cargoTypeData))
        }).catch((error) => {
           // console.log('error')
        })
    }

    static fetchTransporters() {

        let addlist = Firebase.database.ref('transporterUids').on("child_added", (inner_snapshot, prevkey) => {
            // Get key of secondary item
    
            if (inner_snapshot.exists()) {
                let secondary_key = inner_snapshot.key
                DataFetcher.transportersListeners['users/' + secondary_key] = Firebase.database.ref('users/' + secondary_key)
                //console.log('here is transporters/' + secondary_key)
                DataFetcher.transportersListeners['users/' + secondary_key].on("value", (inner_snapshot) => {
                    if (inner_snapshot.exists()) {
            
                      if (inner_snapshot.val().userId in DataFetcher.existingTransporters) {
                        DataFetcher.transportersList.map((item, idx) => {
                            if (item.userId == inner_snapshot.val().userId) {
                                return inner_snapshot.val()
                            } else {
                                return item 
                            }
                        })
                      } else {
                        DataFetcher.transportersList.push(inner_snapshot.val())
                                    if (!DataFetcher.didComplete) {
                DataFetcher.onComplete()
                DataFetcher.didComplete = true
            }
                      }
                        //console.log(DataFetcher.transportersList)
                    } else {
                        //console.log('container found to not exist for ' + secondary_key)
                    }
                }, function (error) {
                    //console.log('error fetching container for ' + secondary_key)
                    //console.log(error)
                })

            } else {
    
            }
            //self.loadSingleItemByKey(firebase_secondary_target, secondary_key)
    
    
        }, function (errorObject) {
            if (false) {
                alert(errorObject)
            }
           // console.log("The read failed: " + errorObject.code);
        });
    
        Firebase.database.ref('transporterUids').on("child_removed", (snapshot) => {
            let secondary_key = snapshot.key
    
            Firebase.database.ref('transporterUids' + '/' + secondary_key).off("value")
    
            DataFetcher.transportersList = DataFetcher.transportersList.filter((item, idx) => {
                return (item.userId != snapshot.val().userId)
            })
           
            
            delete DataFetcher.existingTransporters[secondary_key]

    
        }, function (errorObject) {
            if (false) {
                alert(errorObject)
            }
           // console.log("The read failed: " + errorObject.code);
        });
    }

    static fetchDrivers() {

        let addlist = Firebase.database.ref('driverUids').on("child_added", (inner_snapshot, prevkey) => {
            // Get key of secondary item
      
            if (inner_snapshot.exists()) {
                let secondary_key = inner_snapshot.key
                DataFetcher.driversListeners['users/' + secondary_key] = Firebase.database.ref('users/' + secondary_key)
                //console.log('here is drivers/' + secondary_key)
                DataFetcher.driversListeners['users/' + secondary_key].on("value", (inner_snapshot) => {
                    console.log('duser')
                    if (inner_snapshot.exists()) {
            
                      if (inner_snapshot.val().userId in DataFetcher.existingDrivers) {
                        DataFetcher.driversList.map((item, idx) => {
                            if (item.userId == inner_snapshot.val().userId) {
                                return inner_snapshot.val()
                            } else {
                                return item 
                            }
                        })
                      } else {
                        DataFetcher.driversList.push(inner_snapshot.val())
                                    if (!DataFetcher.didComplete) {
                DataFetcher.onComplete()
                DataFetcher.didComplete = true
            }
                      }
                        //console.log(DataFetcher.driversList)
                    } else {
                       // console.log('container found to not exist for ' + secondary_key)
                    }
                }, function (error) {
                    //console.log('error fetching container for ' + secondary_key)
                   // console.log(error)
                })
      
            } else {
      
            }
            //self.loadSingleItemByKey(firebase_secondary_target, secondary_key)
      
      
        }, function (errorObject) {
            if (false) {
                alert(errorObject)
            }
            console.log("The read failed: " + errorObject.code);
        });
      
        Firebase.database.ref('driverUids').on("child_removed", (snapshot) => {
            let secondary_key = snapshot.key
      
            Firebase.database.ref('driverUids' + '/' + secondary_key).off("value")
      
            DataFetcher.driversList = DataFetcher.driversList.filter((item, idx) => {
                return (item.userId != snapshot.val().userId)
            })
           
            
            delete DataFetcher.existingDrivers[secondary_key]
      
      
        }, function (errorObject) {
            if (false) {
                alert(errorObject)
            }
           // console.log("The read failed: " + errorObject.code);
        });
      }

    static deactivateTransportersListeners() {
        DataFetcher.transportersListeners.map((item, idx) => {
            var k = DataFetcher.transportersListeners[item]
            k.off('value')
        })
        Firebase.database.ref('transporterUids').off("child_added")
        Firebase.database.ref('transporterUids').off("child_removed")
    }

    static deactivateDriverListeners() {
        DataFetcher.driversListeners.map((item, idx) => {
            var k = DataFetcher.driversListeners[item]
            k.off('value')
        })
        Firebase.database.ref('driverUids').off("child_added")
        Firebase.database.ref('driverUids').off("child_removed")
    }


}