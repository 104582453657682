import React, {useState} from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Container,
  Grid,
  Link,
  TextField,
  Typography,
  makeStyles,
  CircularProgress
} from '@material-ui/core';
import FacebookIcon from 'src/icons/Facebook';
import GoogleIcon from 'src/icons/Google';
import Page from 'src/components/Page';
import Firebase from 'src/Firebase.js';
import MuiPhoneNumber from 'material-ui-phone-number';

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

function setuprecaptcha (phone, confirm, id){
  console.log('trying it')
  console.log(window)
  window.recaptchaVerifier = new Firebase.firebaseRef.auth.RecaptchaVerifier(id, {
      size: 'invisible',
      callback: function (response) {
          console.log("recature resolved")
          this.onSignInSubmit(phone, false, confirm, '');
      }
  });

}
const phoneVerify = (values, isFirst, confirm, id, setVerificationId) => {
  //console.log(phoneNumber)
  //console.log(typeof(phoneNumber))

  /*console.log('try verify')
  if (isFirst) {
    console.log('try tis')
    setuprecaptcha(values, confirm, id)
    console.log('did it')
  }

  var appVerifier = window.recaptchaVerifier;
  console.log(appVerifier)
  Firebase.auth.signInWithPhoneNumber(values.phone.toString(), appVerifier)
  .then(function (confirmationResult) {
    // SMS sent. Prompt user to type the code from the message, then sign the
    // user in with confirmationResult.confirm(code).
    confirm()
    console.log('sent text')
    window.confirmationResult = confirmationResult;
    
    //alert(window.confirmationResult)
  }).catch(function (error) {
    console.log(error)
    // Error; SMS not sent
    // ...
  });*/

  let success = (dxx) => {
    console.log(dxx)
    if (dxx.result == 0) {
      alert('Error sending text message, please double check the phone number you are using.')
     //self.props.navigation.navigate('PhoneCodePrompt', {verificationId: phoneAuthSnapshot.verificationId,
      //  phoneNumber: self.state.phone})
    } else if (dxx.result == 1) {
      setVerificationId(dxx.verificationId)
      confirm()
      
    } else {
      alert('Error sending text message, please double check the phone number you are using.')
    } 
  }

  let fail = (dxx) => {
    alert('Error sending text message, please double check the phone number you are using.')
    //alert(JSON.stringify(dxx))
  }
  Firebase.trackMixpanel('tryPhoneLogin', {'phone': values.phone.toString()})
  Firebase.phoneLogin(values.phone.toString(), success, fail)

}


function verifyCode(code, email, companyName, phone, verificationId) {
  console.log('trying to verify')
  console.log(code)
  console.log(companyName)
  console.log(phone)
  /*window.confirmationResult.confirm(code).then(function (result) {
    // User signed in successfully.
    var user = result.user;
    console.log(user.uid)

    console.log(Firebase.auth.currentUser.uid)

    Firebase.addNewUserIfNeeded(companyName, phone + '', Firebase.auth.currentUser.uid)
    // ...
  }).catch(function (error) {
    console.log(';fcekaf')
    console.log(error)
    alert('bad code')
    // User couldn't sign in (bad verification code?)
    // ...
  });*/



  let success = async (dxx) => {
    //alert(JSON.stringify(dxx))
      if (dxx.result == 0) {
        Firebase.trackMixpanel('failCodeEntry', {'code': code, 'phone': phone, })
        alert('Incorrect code.')
        return 
      } else if (dxx.result == -1) {
        Firebase.trackMixpanel('failCodeEntry', {'code': code, 'phone': phone, })
        alert('Incorrect code.')
        return 
      } else if (dxx.result == -2) {
        Firebase.trackMixpanel('failCodeEntry', {'code': code, 'phone': phone, })
        alert('Incorrect code.')
        return 
      } else {
        // Do something with your new credential, e.g.:
        await Firebase.auth.signInWithCustomToken(dxx.credential)//Firebase.auth.signInWithCredential(dxx.credential)
        let firebase_user_id = Firebase.auth.currentUser.uid;
        Firebase.uid = firebase_user_id
        console.log('FUUUUUUUUUUUUUUUUCK' + firebase_user_id)
        //alert(firebase_user_id)
  
        //var updateBody = {'userId': firebase_user_id, 'phone': selfRef.props.route.params.phoneNumber, 'userType': 3}
        //var datpack = {'updateBody': updateBody}
  
        Firebase.trackMixpanel('successCodeEntry', {'code': code, 'phone': phone, 'uid': Firebase.auth.currentUser.uid})
        Firebase.addNewUserIfNeeded(email, companyName, phone + '', firebase_user_id)
        return
      }

  }

  let fail = (dxx) => {
    Firebase.trackMixpanel('failCodeEntry', {'code': code, 'phone': phone, })
    alert('Incorrect code.')
    return 
  }

  Firebase.codeConfirm(verificationId, code + '', success, fail)
}

async function doEmailReset(email) {
  if (!email) {
    alert('Enter a valid email address.')
    return
  }

  Firebase.resetPasswordViaEmailWarehouseAuth({'email': email})
  alert('An email to reset your password should be on the way to ' + email + '. Check your spam folder if you do not receive it in the next few minutes.')

}

async function userPassLoginShipper(fb_email, fb_pass, setSubmitting, setIsLoading) {
  setIsLoading(true)
  setSubmitting(true)
  Firebase.trackMixpanel('tryUsernameLogin', {'username': fb_email})
  let s = (dret) => {
    var d = dret.data
    if (d.result == 401) {
      setSubmitting(false)
      setIsLoading(false)
      Firebase.trackMixpanel('errorIncorrectUsername', {'username': fb_email})
      alert('Incorrect username, please try again.')
    } else if (d.result == 500) {
      setSubmitting(false)
      setIsLoading(false)
      alert('Error logging in, check internet connection.')
    } else if (d.result == 402) {
      setSubmitting(false)
      setIsLoading(false)
      Firebase.trackMixpanel('errorIncorrectPassword', {'username': fb_email})
      alert('Incorrect password, please try again.')
    } else {
      Firebase.auth.signInWithCustomToken(d.token) .then(function (confirmationResult) {
        //setIsLoading(false)
        Firebase.trackMixpanel('usernameLoginSuccess', {'username': fb_email})
      }).catch(function (error) {
        console.log(error)
        setSubmitting(false)
        setIsLoading(false)
        alert('Incorrect password, please try again.')
        // Error; SMS not sent
        // ...
      });
    }
    return
    


  }
  let f = (d) => {
    console.log(d)
    alert('Error while trying to login. Check internet connection.')
    setSubmitting(false)
    setIsLoading(false)
  }

  try {
    let result = await Firebase.usernamePasswordLoginShipper(fb_email, fb_pass,s ,f )
    /*await Firebase.auth.signInWithEmailAndPassword(fb_email, fb_pass);
    console.log('dididjowaiejf')
    Firebase.setUid(Firebase.auth.currentUser.uid)
    setIsLoading(false)*/
    
  } catch (e) {
    setIsLoading(false)
    setSubmitting(false)
    alert('Error logging in.')
  }
  }

const LoginView = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [codeView, setCodeView] = useState(0);

  const [pass, setpass] = React.useState('ssgf');
  const [phone, setPhone] = React.useState('');
  const [companyName, setCompanyName] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [isLoading, setIsLoading] = React.useState(false);
  const [verificationId, setVerificationId] = React.useState('');

  const handleSubmit = () => {
    console.log('submitted')
    alert('fl')
  }

  async function verifyUserNotExist(ph) {
    let vv = await Firebase.database.ref('mapPhoneToUid/' + ph).once('value').then((data) => {
      if (!data.exists()) {
          return null
      } else {
          return data.val()
      }

  }).catch((error) => {
      return {'error': 'error'}
  })

  if (vv != null) {
    alert('A user with this phone number already exists. If you want to make another account under the same phone number, please call Garri at ##########.')
    return true
  }
  return false
  }

  /*React.useEffect(() => {
    if (typeof window !== 'undefined' && typeof Firebase.auth !== 'undefined') {
      console.log('dothis')
      window.recaptchaVerifier = new Firebase.firebaseRef.auth.RecaptchaVerifier('sendButton', {
        'size': 'invisible',
        'callback': function(response) {
          // reCAPTCHA solved, allow signInWithPhoneNumber.
          //phoneVerify(2);
          //alert('solved')
        }
      });
    }
  })*/


  if (codeView == 1) {
    return (<Page
      className={classes.root}
      height='100%'
      title="Request Quote"
      id="ff1"
      backgroundColor={classes.root.backgroundColor}
    >
      <Box
        display="flex"
        flexDirection="column"
        height="90vh"
        id="bb1"
        justifyContent="center"
        backgroundColor={classes.root.backgroundColor}
 
      >
        <Container id="cc1" maxWidth="sm" backgroundColor={classes.root.backgroundColor}>
          <Formik id="fk1"
            initialValues={{
              code: '',
              policy: false
            }}
            validationSchema={
              Yup.object().shape({
                code: Yup.string().max(255).required('Enter the code from your phone'),
              })
            }
            onSubmit={(values) => {
              console.log('try to verify the code ')
              verifyCode(values.code, email, companyName, phone, verificationId)
              //navigate('/app/dashboard', { replace: true });
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting2,
              touched,
              values
            }) => (
              <form id="fu1"onSubmit={handleSubmit}>
     

                  <Typography
                    color="textSecondary"
                    gutterBottom
                    variant="body2"
                  >
                    We sent a text to verify your number. Enter the code here
                  </Typography>
    

                <TextField
                  error={Boolean(touched.cargoName && errors.cargoName)}
                  fullWidth
                  helperText={touched.cargoName && errors.cargoName}
                  label="Verification Code"
                  margin="normal"
                  name="code"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.code}
                  variant="outlined"
                />
 
                <Box my={2}>
                  <Button
                    color="primary"
                    disabled={isSubmitting2}
                    fullWidth
                    size="large"
                    type="submit"
                    id="codeVerifier"
                    variant="contained"
                  >
                    Confirm
                  </Button>
                </Box>
                <Box my={2}>
                  <Button
                    color="primary"
                    disabled={isSubmitting2}
                    fullWidth
                    id="resender"
                    size="large"
                    type="submit"
                    variant="contained"
                    onClick={() => {
                      //alert('sendagian')
                      phoneVerify({'phone': phone}, false,  () => alert('We sent another code to your phone!'), 'resender', (x) => setVerificationId(x))

                    }
                    }
                  >
                    Resend Text
                  </Button>
                </Box>
                <Typography
                  color="textSecondary"
                  variant="body1"
                >
                  Use different number
                  {' '}
                  <Link
                    component={RouterLink}
                    to="/login"
                    variant="h6"
                  >
                    Go back
                  </Link>
                </Typography>
              </form>
            )}
          </Formik>
        </Container>
      </Box>
    </Page>)
  }

  if (codeView == 2) {
    return (<Page
      className={classes.root}
      height='100%'
      title="Request Quote"
      id="ff2"
      backgroundColor={classes.root.backgroundColor}
    >
      <Box
        display="flex"
        flexDirection="column"
        height="90vh"
        id="bb2"
        justifyContent="center"
        backgroundColor={classes.root.backgroundColor}
 
      >
        <Container id="cc2" maxWidth="sm" backgroundColor={classes.root.backgroundColor}>
          <Formik
          id='f2'
            initialValues={{
             
              companyName: '',
              email: ''
            }}
            validationSchema={
              Yup.object().shape({
                companyName: Yup.string().max(255).required('Company name required')
              })
            }
            onSubmit={(values) => {
              setCompanyName(values.companyName)
              setEmail(values.email)
              setIsLoading(true)
              phoneVerify({'phone': phone}, true,  () => {
                setIsLoading(false)
                setCodeView(1)}, 'sendButtonNext', (x) => setVerificationId(x))
              //verifyCode(values.code)
              //navigate('/app/dashboard', { replace: true });
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting2,
              touched,
              values
            }) => (
              <form id='fu2' onSubmit={handleSubmit}>
                    <div id='fkx1'>
                <Box mb={3}>
                  <Typography
                    color="textPrimary"
                    variant="h2"
                  >
                    Company Name
                  </Typography>
                  

                </Box>
                <Box mb={3}>

                  <Typography
                    color="textSecondary"
                    gutterBottom
                    variant="body2"
                  >
                    What's the name of your company?
                  </Typography>

                </Box>
            
                <TextField
                  error={Boolean(touched.companyName && errors.companyName)}
                  fullWidth
                  id="companyName"
                  helperText={touched.companyName && errors.companyName}
                  label="Company"
                  margin="normal"
                  name="companyName"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.companyName}
                  variant="outlined"
                />
                                <TextField
                  error={Boolean(touched.email && errors.email)}
                  fullWidth
                  id="email"
                  helperText={touched.email && errors.email}
                  label="Email Address"
                  margin="normal"
                  name="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.email}
                  variant="outlined"
                />
              </div>
                <Box my={2}>
                  <Button
                    color="primary"
                    disabled={isSubmitting2}
                    fullWidth
                    size="large"
                    type="submit"
                    id="sendButtonNext"
                    variant="contained"
                  >
                    Confirm
                  </Button>
                </Box>
  
                <Typography
                  color="textSecondary"
                  variant="body1"
                >
                  Use different number
                  {' '}
                  <Link
                    component={RouterLink}
                    to="/login"
                    variant="h6"
                  >
                    Go back
                  </Link>
                </Typography>
                {isLoading ? <div style={{alignItems: 'center', justifyContent: 'center', display: 'flex'}}><CircularProgress/></div> : <div/>}
              </form>
            )}
          </Formik>
        </Container>
      </Box>
    </Page>)
  }

  if (codeView == 3) {
    return (
      <Page
        className={classes.root}
        title="Login"
        id="ff3"
      >
        <Box
          display="flex"
          flexDirection="column"
          height="100%"
          id="bb3"
          justifyContent="center"
        >
          <Container id="cc3" maxWidth="sm">
            <Formik id="fm3"
              initialValues={{
                phone: '',
                email: ''
              }}
              validationSchema={Yup.object().shape({
                email: Yup.string().max(255).required('Username required'),
                password: Yup.string().max(255).required('Password required'),
              })}
              onSubmit={(values, setSubmitting) => {
              
                
              }}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                setSubmitting,
                touched,
                values,
                setFieldValue
              }) => (
                <form id='fu3' onSubmit={handleSubmit}>
                  <Box mb={3}>
                    <Typography
                      color="textPrimary"
                      variant="h2"
                    >
                      Get Started!
                    </Typography>
                   
                  </Box>
                  <Box mb={3}>
       
                    <Typography
                      color="textSecondary"
                      gutterBottom
                      variant="body2"
                    >
                      Login with your username and password
                    </Typography>
                  </Box>
                  
                  <div id='wi3jife'>
                  <TextField
                    error={Boolean(touched.email && errors.email)}
                    fullWidth
                    helperText={touched.email && errors.email}
                    label="Username"
                    margin="normal"
                    name="email"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.email}
                    variant="outlined"
                  />
  </div>
  <div id='wi3jif23e'>
                  <TextField
                    error={Boolean(touched.password && errors.password)}
                    fullWidth
                    helperText={touched.password && errors.password}
                    label="Password"
                    margin="normal"
                    name="password"
                    type="password"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.password}
                    variant="outlined"
                  />
  </div>
                  <Box my={2}>
                    <Button
                      color="primary"
                      disabled={isSubmitting}
                      fullWidth
                      id="sendButton"
                      size="large"
                      onClick={() => userPassLoginShipper(values.email, values.password, setSubmitting, (x) => setIsLoading(x))}
                      variant="contained"
  
                    >
                      Login
                    </Button>
                  </Box>
                  <Box my={2}>
                    <Button
                      color="primary"
                      disabled={isSubmitting}
                      fullWidth
                      id="sendButton"
                      size="large"
                      onClick={() => setCodeView(0)}
                      variant="contained"
  
                    >
                      Go Back
                    </Button>
                  </Box>

                 

                  
                  {isLoading ? <div style={{alignItems: 'center', justifyContent: 'center', display: 'flex'}}><CircularProgress/></div> : <div/>}
                </form>
              )}
            </Formik>
          </Container>
        </Box>
      </Page>
    );
  }

  if (codeView == 4) {
    return (
      <Page
        className={classes.root}
        title="Login"
        id="ff3"
      >
        <Box
          display="flex"
          flexDirection="column"
          height="100%"
          id="bb3"
          justifyContent="center"
        >
          <Container id="cc3" maxWidth="sm">
            <Formik id="fm3"
              initialValues={{
                phone: '',
                email: ''
              }}
              validationSchema={Yup.object().shape({
                email: Yup.string().max(255).required('Username required'),
                password: Yup.string().max(255).required('Password required'),
              })}
              onSubmit={(values, setSubmitting) => {
              
                
              }}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                setSubmitting,
                touched,
                values,
                setFieldValue
              }) => (
                <form id='fu3' onSubmit={handleSubmit}>
                  <Box mb={3}>
                    <Typography
                      color="textPrimary"
                      variant="h2"
                    >
                      Reset Password
                    </Typography>
                   
                  </Box>
                  <Box mb={3}>
       
                    <Typography
                      color="textSecondary"
                      gutterBottom
                      variant="body2"
                    >
                      Enter your email address to receive a password reset link
                    </Typography>
                  </Box>
                  
                  <div id='wi3jife'>
                  <TextField
                    error={Boolean(touched.email && errors.email)}
                    fullWidth
                    helperText={touched.email && errors.email}
                    label="Email Address"
                    margin="normal"
                    name="email"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.email}
                    variant="outlined"
                  />
  </div>
                  <Box my={2}>
                    <Button
                      color="primary"
                      disabled={isSubmitting}
                      fullWidth
                      id="sendButton"
                      size="large"
                      onClick={() => doEmailReset(values.email)}
                      variant="contained"
  
                    >
                      Send Reset Email
                    </Button>
                  </Box>
                  <Box my={2}>
                    <Button
                      color="primary"
                      disabled={isSubmitting}
                      fullWidth
                      id="sendButton"
                      size="large"
                      onClick={() => setCodeView(0)}
                      variant="contained"
  
                    >
                      Go Back
                    </Button>
                  </Box>
                  
                  {isLoading ? <div style={{alignItems: 'center', justifyContent: 'center', display: 'flex'}}><CircularProgress/></div> : <div/>}
                </form>
              )}
            </Formik>
          </Container>
        </Box>
      </Page>
    );
  }

  return (
    <Page
      className={classes.root}
      title="Login"
      id="ff3"
    >
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        id="bb3"
        justifyContent="center"
      >
        <Container id="cc3" maxWidth="sm">
          <Formik id="fm3"
            initialValues={{
              phone: '',
              isSecondButton: false
            }}
            validationSchema={Yup.object().shape({
              phone: Yup.string().max(255).required('Phone numberrequired'),
            })}
            onSubmit={(values, {setSubmitting}) => {
            
              if (values.phone.length < 5) {
                alert('Please enter your phone number before submitting.')
                setSubmitting(false)
                return
              }

              if (values.isSecondButton) {
                  setPhone(values.phone)
                  setCodeView(2)
              } else {
                  setPhone(values.phone)
                  setIsLoading(true)
                  phoneVerify(values, true,  () => {
                    setIsLoading(false)
                    setCodeView(1)}, 'sendButton', (x) => setVerificationId(x))
              }
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              setSubmitting,
              touched,
              values,
              setFieldValue
            }) => (
              <form id='fu3' onSubmit={handleSubmit}>
                <Box mb={3}>
                  <Typography
                    color="textPrimary"
                    variant="h2"
                  >
                    Get Started!
                  </Typography>
                 
                </Box>
                <Box mb={3}>
     
                  <Typography
                    color="textSecondary"
                    gutterBottom
                    variant="body2"
                  >
                    Login using your username and password.
                  </Typography>
                </Box>
                


                <Box my={2}>
                  <Button
                    color="primary"
                    disabled={isSubmitting}
                    fullWidth
                    size="large"
                    variant="contained"
                    onClick={() => setCodeView(3)}
                  >
                    Username Login
                  </Button>
                </Box>
                <Typography
                onClick={() => setCodeView(4)}
                  color="textSecondary"
                  variant="body1"
                  style={{textDecorationLine: 'underline', color: 'blue'}}
                >
                  Reset password
                </Typography>
             
                {isLoading ? <div style={{alignItems: 'center', justifyContent: 'center', display: 'flex'}}><CircularProgress/></div> : <div/>}
              </form>
            )}
          </Formik>
        </Container>
      </Box>
    </Page>
  );
};

export default LoginView;