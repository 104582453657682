import React, { useState, useEffect, Component } from 'react';
import {
  Box,
  Container,
  makeStyles
} from '@material-ui/core';
import Page from 'src/components/Page';
import Results from './Results';
import Toolbar from './Toolbar';
import data from './data';
import Firebase from 'src/Firebase.js'
import VehicleTable from './VehicleTable';
import MyContext from 'src/MyContext.js'


class VehicleTableWrapper extends Component {


  constructor() {
    super()
    this.state = {

      tableItems: [],
      existingDict: {}

    }
    this.listeners = {}

  }



  componentDidMount() {
    //this.loadListDualRef('vehicles', 'vehicles') 
  }

  componentWillUnmount() {
    /*Object.keys(this.listeners).map((key, idx) => {
        try {
            this.listeners[key].off('value')
        } catch (e) {

        }

    })
    Firebase.database.ref('vehicles/').off('child_added')
    Firebase.database.ref('vehicles/').off('child_removed')*/
}

  functionalStateListAdd( v) {

    //console.log('try doing a functional update outer for ' + key )
    this.setState(function (prevState, props) {
      var temp = prevState.tableItems
      var tempDict = prevState.existingDict
      temp.push(v)
      tempDict[v.vehicleId] = v.vehicleId
      return { tableItems: temp, existingDict: tempDict }
    })
}

functionalStateListUpdate( v) {

  //console.log('try doing a functional update outer for ' + key )
  this.setState(function (prevState, props) {
      var temp = prevState.tableItems.map((item, idx) => {
        if (item.vehicleId != v.vehicleId) {
          return item 
        }
        return v 
      })
      return { tableItems: temp }
  })
}

  async loadSingleItemByKey(secondary_target, secondary_key) {
    let self = this
    this.listeners['vehicles/' + secondary_key] = Firebase.database.ref('vehicles/' + secondary_key)
    //console.log('here is   vehicles/' + secondary_key)
    this.listeners['vehicles/' + secondary_key].on("value", (inner_snapshot) => {
        if (inner_snapshot.exists()) {

          if (inner_snapshot.val().vehicleId in self.state.existingDict) {
            self.functionalStateListUpdate(inner_snapshot.val())
          } else {
            self.functionalStateListAdd(inner_snapshot.val())
          }
            
        } else {
            console.log('container found to not exist for ' + secondary_key)
        }
    }, function (error) {
        console.log('error fetching container for ' + secondary_key)
        console.log(error)
    })
}

  loadListDualRef(firebase_primary_target, firebase_secondary_target) {
    // Maintain reference to main component for state updates
    let self = this

    let addlist = Firebase.database.ref(firebase_primary_target).on("child_added", (inner_snapshot, prevkey) => {
        // Get key of secondary item

        if (inner_snapshot.exists()) {
            let secondary_key = inner_snapshot.key
            self.loadSingleItemByKey(firebase_secondary_target, secondary_key)
        } else {

        }
        //self.loadSingleItemByKey(firebase_secondary_target, secondary_key)


    }, function (errorObject) {
        if (this.state.report_errors) {
            alert(errorObject)
        }
        console.log("The read failed: " + errorObject.code);
    });

    Firebase.database.ref(firebase_primary_target).on("child_removed", (snapshot) => {
        let secondary_key = snapshot.key

        Firebase.database.ref(firebase_secondary_target + '/' + secondary_key).off("value")

        var nextList = self.state.tableItems.filter((item, idx) => {
            return (item.vehicleId != snapshot.val().vehicleId)
        })
       
        var existingDict = self.state.existingDict
        delete existingDict[secondary_key]
            self.setState({
                tableItems: nextList,
                existingDict: existingDict
            })

    }, function (errorObject) {
        if (this.state.report_errors) {
            alert(errorObject)
        }
        console.log("The read failed: " + errorObject.code);
    });
  }



  render() {

    return (
      <MyContext.Consumer>{context => (
      <VehicleTable customers={context.vehicles} usersNameDict={context.usersNameDict}/>
      )}</MyContext.Consumer>
    )
  }


}

export default VehicleTableWrapper