import React, { useState, useRef, useEffect } from 'react';
import {useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Avatar,
  Box,
  Card,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  makeStyles,
  Button,
  Link,

  MenuItem
} from '@material-ui/core';
import { TextField } from "@mui/material";

import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import getInitials from 'src/utils/getInitials';
import {X} from 'react-feather';
import HeaderComponent from 'src/utils/HeaderComponent';
import SearchWrapper from 'src/utils/SearchWrapper';
import TableAssist from 'src/utils/TableAssist';
import Firebase from 'src/Firebase'
import AddBarleyFieldDialog from 'src/utils/AddBarleyFieldDialog';
import VoidNoteDialog from './VoidNoteDialog'
import { DataGrid, GridToolbar, GridToolbarContainer, GridToolbarColumnsButton, GridToolbarFilterButton, GridToolbarDensitySelector, GridToolbarExport } from '@mui/x-data-grid';

import { update } from 'lodash';
import Edit from '@material-ui/icons/Edit';

const filter = createFilterOptions();

const agronomyDict = {'grade': 1, 'green': 1, 'moisture': 1, 'purity': 1, 'infested': 1, 
'2225mm': 1, '22mm': 1, '25mm': 1, 'sprouted': 1, 'totaladmixture': 1, 'wildoats': 1, 'diseased': 1}


function CustomToolbar(props) {

  if (props.allowExport) {
    return       <GridToolbarContainer >
    <GridToolbarColumnsButton />
    <GridToolbarFilterButton />
    <GridToolbarDensitySelector/>
    <GridToolbarExport/>
  </GridToolbarContainer>
  } else {
    return (
      <GridToolbarContainer >
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector/>
      </GridToolbarContainer>
    );
  }

}


const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiDataGrid-columnsContainer": {
      backgroundColor: "rgb(150,150,150)",
      color: '#ffffff'
    }
  },
  avatar: {
    marginRight: theme.spacing(2)
  }
}));

/*
          renderEditCell: (params) => {              
<Autocomplete
                id={params.id}
                options={movies}
                style={{height: 200}}
                autoHighlight
                getOptionLabel={(option) => option.movie}
                value={params.getValue(params.id, "movie")}
                renderInput={(params) => <TextField {...params} style={{ width: 150, height: 150}}  autoFocus  />}
              />
          }
*/



const BarleyTable = ({ className, barleyInFields, barleyInList, barleyInCustomObjects, context,...rest }) => {
  const classes = useStyles();
  var refa = React.useRef();
  var clickTarget = null;
  const [selectedCustomerIds, setSelectedCustomerIds] = useState([]);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);
  const [text_filters, set_text_filters] = useState([]);
  const [sortOrder, setSortOrder] = useState('creationDate');
  const [sortDirection, setSortDirection] = useState(false);
  const [addColumnDialog, setAddColumnDialog] = useState(false);
  const [voidDialog, setAddVoidDialog] = useState(false);
  const [voidTarget, setAddVoidTarget] = useState({});
  const [rows, setRows] = useState(barleyInList);
  const [selection, setSelection] = useState([]);
  const docDiaRef = useRef(null);

  const navigate = useNavigate();

  const [sortModel, setSortModel] = React.useState([
    {
      field: 'creationDate',
      sort: 'desc',
    },
  ]);


  function getAutoOptions(barleyInCustomObjects, k) {
    if (k in barleyInCustomObjects) {
      return Object.keys(barleyInCustomObjects[k]).map((key, id) => {
        return barleyInCustomObjects[k][key]
      })
    }
    return []
  }


  function doApiUpdate(api, id, field, val, event) {
    console.log('incoming update with ')
    console.log(val)
    if (!api) {
      return
    }
    api.setEditCellValue({ id, field, value: val }, event);
    // Check if the event is not from the keyboard
    // https://github.com/facebook/react/issues/7407
    if (event.nativeEvent.clientX !== 0 && event.nativeEvent.clientY !== 0) {
      api.commitCellChange({ id, field });
      api.setCellMode(id, field, 'view');
    }
  }

  function compare2(a,b) {

    let as = a.sortNum ? a.sortNum : -1
    let bs = b.sortNum ? b.sortNum : -1

    if (as > bs) {
      return -1
    } else if (bs > as) {
      return 1
    } else {
      return 0
    }
  }

  /*function compare2(a,b) {
    if (a.fieldKey == 'grn') {
      return -1
    } else if (b.fieldKey != 'grn' && (a.fieldKey == 'moisture' || a.fieldKey == 'purity' || a.fieldKey == 'netweightkg' || a.fieldKey == 'variety' || a.fieldKey == 'warehouse' || a.fieldKey == 'weighbridgephoto')) {
      if ((b.fieldKey == 'moisture' || b.fieldKey == 'purity' || b.fieldKey == 'netweightkg' || b.fieldKey == 'variety' || b.fieldKey == 'warehouse' || b.fieldKey == 'weighbridgephoto')) {
        return a.fieldKey > b.fieldKey ? -1 : 1
      } else {
        return -1 
      }
    } {
      return 0
    }
  }*/


  async function uploadImageByUri(uri, mime = 'image/jpg') {
    const imageRef = Firebase.storage.ref('BARLEYIN-').child(clickTarget + '.jpg')
    let aa = await imageRef.put(uri, { contentType: mime }).then(() => {
        return imageRef.getDownloadURL()
    }).then((url) => {
        return url
    })
        .catch((error) => {
            this.setState({
                isAuthenticating: false,
            });
            alert('Error uploading photos. Check internet connection')
            console.log("error" + error);
        })
    console.log('w' + aa)
    return aa
}


  async function handleSelectedFolder(event) {

   
    console.log(event.target.files)
    let resv = await uploadImageByUri(event.target.files[0])
    var nf = {'weighbridgephoto': resv}
    updateRow(clickTarget, nf, 'weighbridgephoto')
    //this.uploadFile(targetFile)

}

function getBagsWeight(params) {
  if (params.row['numberofbags']) {
    let deduction = params.row['numberofbags'] * 0.11
    return deduction.toFixed(2)
  } else {
    return null
  }
}

function getMoistureDeduction(params) {
  if (params.row['netweightkg'] && params.row['moisture'] && params.row['moisture'] > 14.5) {
    let moistureDif = params.row['moisture'] - 14.5 
    let weightMultiplier = moistureDif / 100
    let moistureResult = weightMultiplier * params.row['netweightkg']
    return moistureResult.toFixed(2)
  }
  return null
}

function numericValueNoCommaPercent(params) {
  if (params.value == 0 || params.value) {
    return params.value + '%'
  } else {
    return params.value
  }
  //return params.value + ''
}

function numericValueNoComma(params) {
  if (params.value) {
    return params.value + ''
  } else {
    return params.value
  }
  //return params.value + ''
}

function dateValueGetter(params) {
  //return 'cv22'

  return params.value ? moment(params.value).format('DD/MM/YYYY HH:mm') : ''
}

  function renderColumns(fields) {
    var f = fields 
    f.sort((a,b) => compare2(a,b))
    return f.map((item, idx) => {
      if (item.fieldType == 'custom') {
        return {field: item.fieldKey, headerName: item.fieldName, width: item.width ? item.width : 150, editable: false,
          type: item.fieldType != 'text' ? item.fieldType : null, valueGetter: ({ value }) => value && value.name ? value.name : '',
          renderEditCell: (params) => {              
            return  <Autocomplete
            value={params.value}
            onChange={(event, newValue) => {
              console.log(newValue)
              console.log(params)
              console.log(typeof newValue)
              //console.log(newValue.inputValue)
              if (typeof newValue === 'string') {

                //setFieldValue(fields[fieldIdx].fieldKey, {'customId': newValue.customId, 'name': newValue.name})
                doApiUpdate(params.api, params.id, item.fieldKey, newValue/*{'customId': newValue.customId, 'name': newValue.name}*/, event)

              } else if (newValue && newValue.inputValue) {
                // Create a new value from the user input

                //setFieldValue(fields[fieldIdx].fieldKey, {'customId':-1, 'name': newValue.inputValue})
                doApiUpdate(params.api, params.id, item.fieldKey, {'customId': -1, 'name': newValue.inputValue}, event)
              } else {

                if (newValue == null) {
                  //setFieldValue(fields[fieldIdx].fieldKey, {'customId': '0', 'name': 'None'})
                  doApiUpdate(params.api, params.id, item.fieldKey, {'customId': '0', 'name': 'None'}, event)
                } else {
                  doApiUpdate(params.api, params.id, item.fieldKey, newValue/*{'customId': newValue.customId, 'name': newValue.name}*/, event)
                  //setFieldValue(fields[fieldIdx].fieldKey, {'customId': newValue.customId, 'name': newValue.inputValue})
                }
              }
              //console.log(event.target.value)
              //console.log(newValue)
            }}
            filterOptions={(options, params) => {
              const filtered = filter(options, params);
      
              // Suggest the creation of a new value
              if (params.inputValue !== '') {
                filtered.push({
                  inputValue: params.inputValue,
                  name: `Add "${params.inputValue}"`,
                });
              }
      
              return filtered;
            }}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            id="free-solo-with-text-demo"
            options={getAutoOptions(barleyInCustomObjects, item.fieldKey)}
            getOptionLabel={(option) => {
              // Value selected with enter, right from the input
              if (typeof option === 'string') {
                return option;
              }
              // Add "xxx" option created dynamically
              if (option.inputValue) {
                return option.inputValue;
              }
              // Regular option
              return option.name ? option.name : '';
            }}
            renderOption={(option) => option.name + ' (' + option.supplierid + ')'}
            freeSolo
            renderInput={(params) => <TextField {...params} style={{ width: 150, height: 150}}  autoFocus  />}
          />}
          
                      
        }
      } else if (item.fieldType == 'list') {
        return {field: item.fieldKey, headerName: item.fieldName, width: item.width ? item.width : 150, editable: false,
          type: 'singleSelect', valueOptions: item.listOptions                      
        }
      }

      if (item.fieldKey == 'gdnlink') {
        return ({field: item.fieldKey, headerName: item.fieldName, width: item.width ? item.width : 150, editable: false,
        type: item.fieldType != 'text' ? item.fieldType : null,
        renderCell: (params) => {
          if (params.value && params.value.length > 0) {
            return   <Link
            to={params.value}
            onClick={ () => window.open(params.value)}
            variant="h6"
          >{'View'}</Link>
          } else {
            return <div/>
          }
        }, disableExport: true
      })
      }

      if (item.fieldKey == 'bagsweight') {
        return ({field: item.fieldKey, headerName: item.fieldName, width: item.width ? item.width : 150, editable: false,
          type: item.fieldType != 'text' ? item.fieldType : null,
          valueGetter: getBagsWeight,

        })
      }

      if (item.fieldKey == 'moisturededuction') {
        return ({field: item.fieldKey, headerName: item.fieldName, width: item.width ? item.width : 150, editable: false,
          type: item.fieldType != 'text' ? item.fieldType : null,
          valueGetter: getMoistureDeduction,

        })
      }

      if (item.fieldKey == 'weighbridgephoto') {
        return ({field: item.fieldKey, headerName: item.fieldName, width: item.width ? item.width : 150, editable: false,
          type: item.fieldType != 'text' ? item.fieldType : null,
          renderCell: (params) => {
            if (context.warehouseRole != 'Admin' && context.warehouseRole != 'Warehouse' && context.warehouseRole != 'Silo') {
              return ''
            }

            if ('weighbridgephoto' in params.row) {
              return <div style={{flexDirection: 'row'}}>
              <Link
              to={params.value}
              onClick={ async () => {
                let b = await Firebase.getSignedUrl({'bucket': 'BARLEYIN-', 'file': params.id + '.jpg'}) 
                if (b && b.length && b.length > 0) {
                  window.open(b[0])
                } else {
                  alert('Error retrieving file.')
                }
              }}
              variant="h6"
            >{'View Photo'}</Link>
            <Edit style={{marginBottom: -5}} onClick={() => {
                clickTarget = params.id 
                refa.click()
              }}/>
            </div>
            } else {
              return <Button
              color="primary"
              variant="contained"
              onClick={() => {
                clickTarget = params.id 
                refa.click()
              }}
            >
             {'Add Photo'}
            </Button>
            }

          }, disableExport: true
        })
      }

      
      if (item.fieldKey == 'grn' ) {
        return ({field: item.fieldKey, headerName: item.fieldName, width: item.width ? item.width : 150, editable: false,
        type: item.fieldType != 'text' ? item.fieldType : null,
        renderCell: (params) => {
          if (!params.row['grn']) {
            return <Link
            to={params.value}
            onClick={ () => navigate('/app/newbarleyin', {state: {fields: barleyInFields, saveVal: params.row}})}
            variant="h6"
          >{'Complete Draft'}</Link>
          }

          //if (params.row['gdnlink'] && params.row['gdnlink'].length) {
            return   <Link
            to={params.value}
            onClick={ async () => {
              let b = await Firebase.getSignedUrl({'bucket': 'test', 'file': 'pdf_for_gdn_' + params.row['grn'] + '.pdf'}) 
              if (b && b.length && b.length > 0) {
                window.open(b[0])
              } else {
                alert('Error retrieving file.')
              }
            }/*window.open(params.row['gdnlink'])*/}
            variant="h6"
          >{[params.value]}</Link>
          /*} else {
            return params.value 
          }*/
        }, 
      })
      }

      if (item.fieldType == 'date' && item.fieldKey == 'creationDate') {
        return ({field: item.fieldKey, headerName: item.fieldName, width: item.width ? item.width : 150, editable: false,
          type: item.fieldType != 'text' ? item.fieldType : null, valueFormatter: (params) => {
            return params.value ? moment(params.value).format('DD/MM/YYYY') : ''
          }, renderCell: (params) => {
            return params.value ? moment(params.value).format('DD/MM/YYYY HH:mm') : ''
          }})
      } else if (item.fieldType == 'date' && item.fieldKey == 'creationDateHour') {

        return ({field: item.fieldKey, headerName: item.fieldName, width: item.width ? item.width : 150, editable: false,
          type: item.fieldType != 'text' ? item.fieldType : null, hide: true, valueGetter: (params) => {
            return params && params.row && params.row['creationDate'] ? moment(params.row['creationDate']).format('HH:mm') : ''
          }})
      } else if (item.fieldType == 'date') {
        return ({field: item.fieldKey, headerName: item.fieldName, width: item.width ? item.width : 150, editable: false,
          type: item.fieldType != 'text' ? item.fieldType : null, valueFormatter: (params) => {
            return params.value ? moment(params.value).format('DD/MM/YYYY HH:mm') : ''
          }})
      }

      if (item.fieldType == 'number') {
        return ({field: item.fieldKey, headerName: item.fieldName, width: item.width ? item.width : 150, editable: false,
          type: item.fieldType != 'text' ? item.fieldType : null,
        valueGetter: item.fieldKey in agronomyDict ? numericValueNoCommaPercent : numericValueNoComma })
      }

      return ({field: item.fieldKey, headerName: item.fieldName, width: item.width ? item.width : 150, editable: false,
      type: item.fieldType != 'text' ? item.fieldType : null})
    })
  }


  async function tryNewColumn(dat) {
    let result = await Firebase.requestNewColumn({'project': Firebase.userProject, 'dat': dat, 'fieldsCollection': 'barleyInFields'})
    console.log(result)
    if (!result) {
      alert('This field already exists.')
    }
  }


  


  async function updateRowAndStocks(id, updateVal, field, oldRow, rowKey, rowVal) {

    let result = await Firebase.updateRowAndStocksBarleyIn({'id': id, 'updateVal': updateVal, 'field': field, 'oldRow': oldRow, 'rowKey': rowKey, 'rowVal': rowVal})
    return result
  }


  async function updateRow(id, updateVal, field) {

    let result = Firebase.updateRowBarleyIn({'id': id, 'updateVal': updateVal, 'field': field})
    return result
  }

  async function onCellChange(value, barleyInFields)  {
    // Get the row
    console.log('osc')
    console.log(value)
    console.log(value.field)
    console.log(value.value)
    if (!value.value || value.value == 'undefined') {
      console.log('retnowundef')
      return
    }
    //console.log(value)

    const rowIndex = rows.findIndex(row => row.id === value.id);
    const fieldIndex = barleyInFields.findIndex(item => value.field === item.fieldKey)

    if (rowIndex >= 0 && fieldIndex >= 0) {
      const row = rows[rowIndex];
      const field = barleyInFields[fieldIndex]
      // Validate if changed
      
      if (!(value.field in row) || (value.field in row && row[value.field] !== value.value)) {
        const data = { [value.field]: value.value };
        console.log('did change, here is data')
        console.log(data)
        console.log(row.id)
        // Sending to API
        const newRows = [...rows];
        //await prepUpdate(row, value.field, value.value)
        var ret = null 

        var dependentFields = {}

        if (value.field == 'warehouse' || value.field == 'moisture' || value.field == 'purity' || value.field == 'netweightkg' || value.field == 'variety') {
          ret = await updateRowAndStocks(row.id, data, field, row, value.field, value.value)
        } else {
          var useData = data 
          if (field.fieldKey == 'supplier') {
              let supCheck = useData['supplier']
              Firebase.sharedSupplierFields.map((item, idx) => {
                if (item in supCheck) {
                  useData[item] = supCheck[item]
                  dependentFields[item] = supCheck[item]
                }
              })
              //console.log('changed with field')
              //console.log(updateVal)
          }
          ret = await updateRow(row.id, useData, field)
        }
        if (ret) {
          newRows[rowIndex][value.field] = value.value;
          if (Object.keys(dependentFields).length > 0) {
            Object.keys(dependentFields).map((dependentKey, depIdx) => {
              newRows[rowIndex][dependentKey] = dependentFields[dependentKey]
            })
          }
        } else {
          console.log('oldset')
          console.log('ent val is')
          console.log(row[value.field])
          if (value.field in row) {
            newRows[rowIndex][value.field] = row[value.field];
          } else {
            delete newRows[rowIndex][value.field]
          }

        }
        console.log(newRows)
        setRows(newRows);
        /*Api.product.update(data).then(res => {
          const newRows = [...rows];

          if (res.success) {
            // Change to new value
            newRows[rowIndex][value.field] = value.value;
          } else {
            // Change to old value
            newRows[rowIndex][value.field] = row[value.field];
          }

          setRows(newRows);
        });*/
      } else {
        console.log(rows)
        console.log('no change')
      }
    }
  };


  async function voidReport(remark) {
    if (selection.length != 1) {
      alert('Select a single item to void.')
      return 
    } else {
      var r = null 
      rows.map((row, idx) => {
        if (row.id == selection[0]) {
          r = row
        }
      })
      if (r != null) {

        
        
        let rx = await Firebase.voidBarleyIn({'project': Firebase.userProject, 'newVal': r, 'remark': remark})
        
        if (rx.transaction && rx.transaction.transaction) {
          alert('The specified entry has been voided. The amount has been deducted from the specified stock (if it was not already voided).')
        } else if (rx.transaction && rx.transaction.failMessage) {
          alert(rx.transaction.failMessage.msg)
        } else {
          alert('Error while attempting to void transaction.')
        }
        console.log(rx)
        Firebase.trackMixpanel('voidBarleyIn', {'result': rx, 'id': r.id})
        //alert('The specified entry has been voided. The amount has been deducted from the specified stock (if it was not already voided).')


      }
    }
  }

  function regenGdn() {
    if (selection.length != 1) {
      alert('Select a single item to generate a GDN for.')
      return 
    } else {
      var r = null 
      rows.map((row, idx) => {
        if (row.id == selection[0]) {
          r = row
        }
      })
      if (r != null) {

        let s = async () => {
          var datPack = {}
          const imageRef = Firebase.storage.ref('test').child('pdf_for_gdn_' + r.grn + '.pdf')
          let v = await imageRef.getDownloadURL();
          console.log(v)
          console.log('got it')
          datPack['pdflink'] = v 
          datPack['barleyInId'] = r.id
          let snone = () => console.log('x')
          Firebase.provideGdnLink(datPack, () => alert('New GDN generated for item ' + r.grn), () => alert('Failed to generate new GDN.'))
          //window.open(v)

        } 
    
        let f = () => {
        
        }
        var datxx = {}
        datxx['val'] = r
        datxx['userId'] = 'test'
        
        Firebase.generateGdn(datxx, s, f)


      }
    }
  }

  function hasCreationField(r) {
    var f = false 
    r.map((item, idx) => {
      if (item.fieldKey == 'creationDate') {
        f = true 
      }
    })

    return f
  }


  useEffect(() => {
    setRows(barleyInList);
  }, [barleyInList]);



  return (
    <div>
        <AddBarleyFieldDialog
        open={addColumnDialog}
        ref={docDiaRef}
        onClose={() => setAddColumnDialog(false)}
        alertTitle={'Add A New Barley Intake Field'}
        alertCancelText={'Cancel'}
        alertConfirmText={'Confirm'}
        onConfirm={(x) => tryNewColumn(x)}
        />
                <VoidNoteDialog
        open={voidDialog}
        onClose={() => setAddVoidDialog(false)}
        alertTitle={'Void Barley Transaction'}
        alertCancelText={'Cancel'}
        alertConfirmText={'Confirm'}
        onConfirm={(x) => voidReport(x)}
        />
<div style={{flexDirection: 'row', display: 'flex', justifyContent: 'space-between'}}>

    {context.warehouseRole == 'Admin' || context.warehouseRole == 'Warehouse' || context.warehouseRole == 'Warehouse Manager'
    ?
    <Button
    color="primary"
    variant="contained"
    style={{backgroundColor: 'mediumspringgreen', marginBottom: 5}}
    onClick={() => navigate('/app/newbarleyin', {state: {fields: barleyInFields}})}
  >
   {'+ Intake'}
  </Button>
  : null}

<div>
        {
          context.warehouseRole == 'Admin' ?
          <Button
          color="primary"
          style={{marginBottom: 5}}
          variant="contained"
          onClick={ () => {
           setAddColumnDialog(true)
          }}
        >
         Add Column
        </Button>
          : null
        }

      {false ? 
       <Button
       color="primary"
       variant="contained"
       style={{marginBottom: 5}}
       onClick={() => regenGdn()}
     >
      Regenerate Gdn
     </Button>
      : null} 
            {context.warehouseRole == 'Admin' || context.warehouseRole == 'Admin' || context.warehouseRole == 'Warehouse' || context.warehouseRole == 'Warehouse Manager'? 
       <Button
       color="primary"
       variant="contained"
       style={{marginBottom: 5}}
       onClick={() => 
        {
          setAddVoidDialog(true)
       }}
     >
      Void Selected Entry
     </Button>
      : null} 
      </div>
</div>
      
{hasCreationField(barleyInFields) ? 

<div style={{height: 400, width: '99%'}}>
<input id="myInputFolder" accept="image/png, image/jpeg, image/jpg" ref={(ref) => refa = ref} style={{ display: 'none' }} type="file" onChange={handleSelectedFolder} />
<DataGrid
style={{backgroundColor: 'white'}}
className={classes.root}
rows={rows}
columns={renderColumns(barleyInFields)}
pageSize={5}
rowsPerPageOptions={[5]}
onCellEditCommit={(p, e, d) => onCellChange(p, barleyInFields)}
hideFooterPagination={false}
hideFooter={false}
checkboxSelection={true}
disableSelectionOnClick
sortModel={sortModel}
onSelectionModelChange={(newSelection) => {
setSelection(newSelection);
}}
components={{
  Toolbar: CustomToolbar,
}}
componentsProps={{ toolbar: { allowExport: context.warehouseRole == 'Admin' || context.warehouseRole == 'Finance' } }}
/>
</div>
: null}

    </div>
  )
};

BarleyTable.propTypes = {
  className: PropTypes.string,
  customers: PropTypes.array.isRequired
};

export default BarleyTable;
