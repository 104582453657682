import React, {Component} from 'react'
import Popover from '@material-ui/core/Popover';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';

class MapPopup extends Component {

    constructor() {
        super()

        this.state = {
            popOpen: false
        }

        this.mainRef = null 
    }


    getImageOver(item, d, b) {

        var pic = 'profilePic'
        if (this.props.type === 'driver') {
            

            if ('garri_data' in item && pic in item.garri_data) {
                return  <img style={{width: d, height: d, borderRadius: b,  border: !item.isManual ? '2px solid mediumspringgreen' : ''}}src={item.garri_data[pic]}></img>
            } else {
                return <img style={{width: d, height: d, borderRadius: b, border: !item.isManual ? '2px solid mediumspringgreen' : ''}} src={'/static/images/avatars/plc.png'}></img>
            }

 
        } else {
            pic = 'vehiclePic'
            if ('garri_data' in item && pic in item.garri_data) {
                return  <img style={{width: d, height: d, borderRadius: b}}src={item.garri_data[pic]}></img>
            } else {
               return <LocalShippingIcon style={{width: d, height: d}}/>
            }

        }

    }

    closePop() {
        this.setState({
            popOpen: false
        })
        //alert('tried to close')
    }

    togglePop() {
        this.setState({
            popOpen: !this.state.popOpen
        })
    }

    getUserName(item) {

        if ('garri_data' in item){
            var v = item['garri_data']
            var rets = ''
            if ('firstName' in v) {
              rets += v.firstName
            } 
            if ('middleName' in v) {
              rets += ' ' + v.middleName
            } 
            if ('lastName' in v) {
              rets += ' ' + v.lastName
            }
            return rets
        }

        return ''
    }

    pullField(x, y, z) {
        if (y in x) {
            return x[y][z]
        } else {
            return 'Not Available'
        }
    }

    renderContent(type, item) {
        if (type === 'driver') {
            return (<div style={{display: 'flex', padding: 20, alignItems: 'center', flexDirection: 'column'}}>
                    {this.getImageOver(item, 60, 30)}
                    <a style={{marginTop: 5, marginBottom: 2, fontSize: 24}}>{this.getUserName(item)}</a>
                    <a style={{marginTop: 2, marginBottom: 15, fontSize: 14, fontFamily: 'Helvetica'}}>{'Garri Driver'}</a>
                    <div style={{display: 'flex', flexDirection: 'row'}}>
                <div style={{display: 'flex', flexDirection: 'column', marginRight: 5}}>
                    <a style={{fontFamily: 'Helvetica'}}>{'Phone: ' + this.pullField(item, 'garri_data', 'phone')}</a>
                    <a style={{fontFamily: 'Helvetica'}}>{'Company : ' + this.pullField(item, 'garri_data', 'companyName')}</a>
                    <a style={{fontFamily: 'Helvetica'}}>{'Verified : ' + this.pullField(item, 'garri_data', 'verified')}</a>
                    </div>
                <div style={{display: 'flex', flexDirection: 'column', marginLeft: 5}}>
                <a style={{fontFamily: 'Helvetica'}}>{'Altitude: ' + this.pullField(item, 'coords', 'altitude')}</a>
                    <a style={{fontFamily: 'Helvetica'}}>{'Latitude : ' + this.pullField(item, 'coords', 'latitude')}</a>
                    <a style={{fontFamily: 'Helvetica'}}>{'Longitude : ' + this.pullField(item, 'coords', 'longitude')}</a>
                </div>
                </div>
            </div>)

        } else {
            return (<div style={{display: 'flex', padding: 20, alignItems: 'center', flexDirection: 'column'}}>
            {this.getImageOver(item, 60, 30)}
            <a style={{marginTop: 5, marginBottom: 2, fontSize: 24}}>{item.name}</a>
            <a style={{marginTop: 2, marginBottom: 15, fontSize: 14, fontFamily: 'Helvetica'}}>{'Garri Vehicle'}</a>
            <div style={{display: 'flex', flexDirection: 'row'}}>
                <div style={{display: 'flex', flexDirection: 'column', marginRight: 5}}>
                    <a style={{fontFamily: 'Helvetica'}}>{'Fuel Quantity: ' + this.pullField(item, 'device_data', 'fuel_quantity')}</a>
                    <a style={{fontFamily: 'Helvetica'}}>{'Fuel/KM : ' + this.pullField(item, 'device_data', 'fuel_per_km')}</a>
                    <a style={{fontFamily: 'Helvetica'}}>{'Fuel Price : ' + this.pullField(item, 'device_data', 'fuel_price')}</a>
                    </div>
                <div style={{display: 'flex', flexDirection: 'column', marginLeft: 5}}>
                <a style={{fontFamily: 'Helvetica'}}>{'Speed: ' + this.pullField(item, 'device_data', 'speed')}</a>
                    <a style={{fontFamily: 'Helvetica'}}>{'Latitude : ' + item.lat}</a>
                    <a style={{fontFamily: 'Helvetica'}}>{'Longitude : ' + item.lng}</a>
                </div>
                </div>

    </div>)


        }
    }


    render() {

            if (this.props.item.isManual && this.props.hideManual) {
                return null 
            }

            return <div ref={(v) => this.mainRef =v} onClick={() => this.togglePop()} style={{display: 'flex' }} ><Popover 
            open={this.state.popOpen}
            onClose={() => this.setState({
                popOpen: false 
            })}
            onClick={() => this.closePop()}
            anchorEl={this.mainRef}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          >
            {this.renderContent(this.props.type, this.props.item)}
          </Popover>{this.getImageOver(this.props.item, 30, 15)}</div>



    }

}

export default MapPopup