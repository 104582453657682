import React, { useState, useEffect } from 'react';
import { Link as RouterLink, useNavigate, useLocation} from 'react-router-dom';
import {
  Box,
  Container,
  Typography, 
  Button,
  Link,
  makeStyles,
  Collapse,
  TextField,
  Checkbox,
  MenuItem,
  InputAdornment,
  Select,
  FormControl,
  CircularProgress

} from '@material-ui/core';
import Page from 'src/components/Page';
import CheckCircle from '@material-ui/icons/CheckCircle';
import { Formik } from 'formik';

import Firebase from 'src/Firebase.js'


const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));


const CollapseableSummary = (props) => {
  const classes = useStyles();
  const [weightMetric, setWeightMetric] = React.useState('quintal');
  const [editable, setEditable] = React.useState(true);
  const [transporterPriceMetric, setTransporterPriceMetric] = React.useState('perquintal');
  const [isLoading, setIsLoading] = React.useState(false);

  const navigate = useNavigate();
  const {state} = useLocation();
  useEffect(() => {
    // Update the document title using the browser API

    
  });

  function precisionRound(num) {
    console.log(num)
    console.log(num.toFixed(2))
    
    return +num.toFixed(2)
  }

  function padDigits(number) {
    return Array(Math.max(3 - String(number).length + 1, 0)).join(0) + number;
  }

  function calculateRemainingPayment(values) {
    if (!values.trueWeight || values.trueWeight <= 0) {
      return 'Cannot calculate payment without weight.'
    }
    if (!values.transporterPricePerQuintal) {
      return 'Cannot calculate payment without transporter price.'
    }

    var weightVal = values.trueWeight
    if (values.finalWeight && values.finalWeight > 0 && values.finalWeight != '') {
      weightVal = values.finalWeight
    }

    // Total quintals of the load
    var weightQuintals = precisionRound(weightVal * 10)

    var totalPay = weightQuintals * values.transporterPricePerQuintal

    if (values.advancePayment && values.advancePayment > 0 && values.advancePayment != '') {
      var totalPay = totalPay - values.advancePayment
    }

    return 'Calculated Remaining Payment: ETB ' + precisionRound(totalPay)


  }

  function navigaterFunc(path, props) {
    //alert('attempt navigate')
    navigate(path, props)
  }

  function doSubmit(values, setSubmitting) {
    if (values.quoteId == null) {
      alert('Error updating job.')
      return 
    }
    if (values.userId == null) {
      alert('Error updating job.')
      return 
    }
    setIsLoading(true)
    setSubmitting(true)
    let suc = () => {
      setIsLoading(false)
      setSubmitting(false)
    }
    let f = () => {
      setIsLoading(false)
      setSubmitting(false)
      alert('Failed to update job due to an error.')
    }

    Firebase.editQuoteBasic(values,suc, f)
  }

  function mathRound(num) {
    if (num < 0.01) {
      return num 
    }

    return Math.round((num + Number.EPSILON) * 100) / 100
  }

  function getTransporterPhone(id) {
    if (!props.usersProp || !props.usersProp.length) {
      return ''
    }

    var phone = 'Could not retrive'
    props.usersProp.map((item, idx ) => {
      if (id == item.userId) {
        phone = item.phone
      }
    })

    return phone 
  }

  return (
    <Collapse
    in={props.isOpen}
    timeout="auto"
    unmountOnExit
>


 <Formik
            initialValues={{
              assignedDriver: props.customer && props.customer.assignedDriver ? props.customer.assignedDriver : '0',
              assignedTransporter: props.customer && props.customer.assignedTransporter ? props.customer.assignedTransporter : '0',
              quoteId: props.customer && props.customer.quoteId ? props.customer.quoteId : null,
              trueWeight: props.customer && props.customer.trueWeight ? props.customer.trueWeight : '',
              transporterPricePerQuintal: props.customer && props.customer.transporterPricePerQuintal ? props.customer.transporterPricePerQuintal : 0,
              vehicleId: props.customer && props.customer.vehicleId ? props.customer.vehicleId : '0', 
              vehiclePlate: props.customer && props.customer.vehiclePlate ? props.customer.vehiclePlate : 'Unassigned',
              vehicleTrailerPlate: props.customer && props.customer.vehicleTrailerPlate ? props.customer.vehicleTrailerPlate : '',
              advancePayment: props.customer && props.customer.advancePayment ? props.customer.advancePayment : '',
              finalWeight: props.customer && props.customer.finalWeight ? props.customer.finalWeight : '',
              jobIsPaid: props.customer && props.customer.jobIsPaid ? props.customer.jobIsPaid : '',
              paymentMethod: props.customer && props.customer.paymentMethod ? props.customer.paymentMethod : 'check',
              paymentMethodInitial: props.customer && props.customer.paymentMethodInitial ? props.customer.paymentMethodInitial : 'cash',
              userId: props.customer && props.customer.userId ? props.customer.userId : null,
              checkNumber: props.customer && props.customer.checkNumber ? props.customer.checkNumber : '',
              gzfawg: false ? 's' : 'b',

            }}
            onSubmit={(values, acts) => {
              //alert('tt')
              //console.log('sub')
              //setNumVal(false)
              doSubmit(values)
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              setSubmitting,
              touched,
              values,
              setFieldValue,
              resetForm,
              validateForm
            }) => (
              <form onSubmit={handleSubmit}>

      <Container maxWidth={true} style={{width: '100%', alignItems: 'center', flexDirection: 'column', justifyContent: 'center', display: 'flex'}}>
       
    <div>
    <Box
        alignItems="center"
        display="flex"
        flexDirection="row"
        ml={-1}
      >
        <a>{'Transporter Phone: ' + getTransporterPhone(values.assignedTransporter)}</a>
       
      </Box>
      <Box
        alignItems="center"
        display="flex"
        flexDirection="row"
        ml={-1}
      >
                            <TextField
                error={Boolean(touched.advancePayment && errors.advancePayment)}
                style={{marginRight: 30}}
                id="advancePayment"
                disabled={!editable}
                helperText={touched.advancePayment && errors.advancePayment}
                label="Advance Payment (ETB)"
                margin="normal"
                name="advancePayment"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.advancePayment}
                variant="outlined"
                type="number"
              />
                      <TextField select 
          style={{width: 150}}
          label="Advance Payment Method"
          margin="normal"
          id="paymentMethodInitial"
          name="paymentMethodInitial"
          disabled={!editable}
          value={values.paymentMethodInitial}
          onChange={handleChange}
        >
 {[  {'name': 'None', 'val': '0'}, {'name': 'Cash', 'val': 'cash'},  {'name': 'Check', 'val': 'check'}, {'name': 'Card', 'val': 'card'}, {'name': 'Mobile', 'val': 'mobile'}, {'name': 'Other', 'val': 'other'}].map((item, idx) => {
            return (<MenuItem value={item.val}>{item.name}</MenuItem>)
          })}
        </TextField>
        </Box>

        <Box
        alignItems="center"
        display="flex"
        flexDirection="row"
        ml={-1}
      >   
<TextField
                  error={Boolean(touched.trueWeight && errors.trueWeight)}
                  width={'50%'}
                  style={{marginRight: 30}}
                  helperText={touched.trueWeight && errors.trueWeight}
                  label={props.ltcCreation && values.workOrderJob ? "Total Weight" : "Weight"}
                  margin="normal"
                  name="trueWeight"
                  disabled={true}
                  type="number"
                  InputProps={{
                    endAdornment: <InputAdornment position="start">

<Select
          fullWidth
          label="Cargo Type"
          margin="normal"
          id="cargoType"
          name="cargoType"
          disabled={true}
          value={weightMetric}
          onChange={(e) => {
            setWeightMetric(e.target.value)
            //setFieldValue('cargoTypeDisp', e.target.value.name)
          }}
        >
          {[ {'name': 'Quintals', 'val': 'quintal'}, {'name': 'Tons', 'val': 'ton'}].map((item, idx) => {
            return (<MenuItem value={item.val}>{item.name}</MenuItem>)
          })}
        </Select>

                    </InputAdornment>,
                  }}
                  onBlur={handleBlur}
                  onChange={(e) => {
                    if (false && e.target.value <= 0) {
                      alert('Weight cannot be zero or less.')
                    } else {

                      let xweight = weightMetric == 'ton' ? e.target.value : e.target.value / 10
                      if (e.target.value == '') {
                        setFieldValue('trueWeight', '')
                      } else {
                        setFieldValue('trueWeight', xweight)
                      }

                      if (e.target.value > 0) {
                        if (props.fromLTC || true) {
                          setFieldValue('price', mathRound(values.pricePerQuintal * precisionRound(xweight * 10)) )
                          setFieldValue('transporterPrice', mathRound(values.transporterPricePerQuintal * precisionRound(xweight * 10)) )
                        } else {
                          setFieldValue('pricePerQuintal', mathRound(values.price / precisionRound(xweight * 10)) )
                          setFieldValue('transporterPricePerQuintal', mathRound(values.price / precisionRound(xweight * 10)) )
                        }
                        
                      }
                      
                    } 
                  }
                  }
                  value={values.trueWeight == '' ? '' : weightMetric == 'ton' ? values.trueWeight : precisionRound(values.trueWeight * 10)}
                  variant="outlined"
                />
                <TextField
                  error={Boolean(touched.finalWeight && errors.finalWeight)}
                  width={'71%'}
                  style={{marginRight: 30}}
                  helperText={touched.finalWeight && errors.finalWeight}
                  label={"Final Weight"}
                  margin="normal"
                  name="finalWeight"
                  disabled={!editable}
                  type="number"
                  InputProps={{
                    endAdornment: <InputAdornment position="start">

<Select
          fullWidth
          disabled={!editable}
          value={weightMetric}
          onChange={(e) => {
            setWeightMetric(e.target.value)
            //setFieldValue('cargoTypeDisp', e.target.value.name)
          }}
        >
          {[ {'name': 'Quintals', 'val': 'quintal'}, {'name': 'Tons', 'val': 'ton'}].map((item, idx) => {
            return (<MenuItem value={item.val}>{item.name}</MenuItem>)
          })}
        </Select>

                    </InputAdornment>,
                  }}
                  onBlur={handleBlur}
                  onChange={(e) => {
                    if (false && e.target.value <= 0) {
                      alert('Weight cannot be zero or less.')
                    } else {

                      let xweight = weightMetric == 'ton' ? e.target.value : e.target.value / 10
                      if (e.target.value == '') {
                        setFieldValue('finalWeight', '')
                      } else {
                        setFieldValue('finalWeight', xweight)
                      }

                      
                      
                    } 
                  }
                  }
                  value={values.finalWeight == '' ? '' : weightMetric == 'ton' ? values.finalWeight : precisionRound(values.finalWeight * 10)}
                  variant="outlined"
                />
</Box>
<Box
        alignItems="center"
        display="flex"
        flexDirection="row"
        ml={-1}
      >

<TextField
                error={Boolean(touched.checkNumber && errors.checkNumber)}
                style={{marginRight: 30}}
                id="checkNumber"
                disabled={!editable}
                helperText={touched.checkNumber && errors.checkNumber}
                label="Check Number"
                margin="normal"
                name="checkNumber"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.checkNumber}
                variant="outlined"
              />

        <TextField select 
          style={{width: 150}}
          label="Payment Method"
          margin="normal"
          id="paymentMethod"
          name="paymentMethod"
          disabled={!editable}
          value={values.paymentMethod}
          onChange={handleChange}
        >
 {[  {'name': 'None', 'val': '0'}, {'name': 'Cash', 'val': 'cash'},  {'name': 'Check', 'val': 'check'}, {'name': 'Card', 'val': 'card'}, {'name': 'Mobile', 'val': 'mobile'}, {'name': 'Other', 'val': 'other'}].map((item, idx) => {
            return (<MenuItem value={item.val}>{item.name}</MenuItem>)
          })}
        </TextField>
      </Box>

      <Box
        alignItems="center"
        display="flex"
        flexDirection="row"
        ml={-1}
      >
        <Checkbox
        id="jobIsPaid"
        disabled={!editable}
          checked={values.jobIsPaid}
          name="jobIsPaid"
          onChange={(e) => {
            setFieldValue('jobIsPaid', !values.jobIsPaid)
          }}
        />
        <Typography
          color="textPrimary"
          variant="body1"
        >
          Job Has Been Paid In Full
        </Typography>
        </Box>
      <Box
        alignItems="center"
        display="flex"
        flexDirection="row"
        ml={-1}
      >
      <FormControl style={{marginRight: 10}}> <TextField
                  error={Boolean(touched.phone && errors.phone)}
                  disabled={true}
                  fullWidth
                  label="Transporter Price"
                  margin="normal"
                  name="transporterPrice"
                  onChange={(e) => {
                    if (false && values.trueWeight <= 0) {
                      alert('Weight cannot be zero or less.')
                    } else {

                      if (transporterPriceMetric == 'total') {
                        setFieldValue('transporterPrice', e.target.value)
                        if (values.trueWeight > 0) {
                          setFieldValue('transporterPricePerQuintal', mathRound(e.target.value / precisionRound(values.trueWeight * 10)))
                        }
                       
                      } else {
                        setFieldValue('transporterPrice', e.target.value * precisionRound(values.trueWeight * 10))
                        setFieldValue('transporterPricePerQuintal', e.target.value)
                      }


                    }
                    
                  }}
                  type="number"
                  InputProps={{
                    endAdornment: <InputAdornment position="start">

<Select
          fullWidth
          label="Cargo Type"
          margin="normal"
          id="cargoType"
          name="cargoType"
          disabled={true}
          value={transporterPriceMetric}
          onChange={(e) => {
            setTransporterPriceMetric(e.target.value)
            //setFieldValue('cargoTypeDisp', e.target.value.name)
          }}
        >
          {[ {'name': 'Price/Quintal', 'val': 'perquintal'}, {'name': 'Total Price', 'val': 'total'}].map((item, idx) => {
            return (<MenuItem value={item.val}>{item.name}</MenuItem>)
          })}
        </Select>

                    </InputAdornment>, shrink: true 
                  }}
                  value={values.transporterPricePerQuintal == '' ? '' : transporterPriceMetric == 'total' ? values.transporterPricePerQuintal * precisionRound(values.trueWeight * 10) : values.transporterPricePerQuintal}
                  variant="outlined"
                />
                
                </FormControl>
                <Button
          color="primary"
          onClick={() => doSubmit(values, setSubmitting)}
          disabled={isLoading}
          size="large"
          id="get-quote-button"
          type="awef"
          variant="contained"
        >
          Update Job
        </Button>
        {isLoading ? <CircularProgress/> : null}
        </Box>

      <Typography
          color="textPrimary"
          variant="body1"
        >
          {!values.jobIsPaid ? calculateRemainingPayment(values) : ''}
        </Typography>
              </div>


      </Container>
      </form>
            )}
            </Formik>


    </Collapse>

  );
};

export default CollapseableSummary;
