
import React, { useState, useEffect, Component } from 'react';
import { Link as RouterLink} from 'react-router-dom';
import {
  Box,
  Container,
  makeStyles,
  Button,
  Typography,
  CircularProgress,
  Stepper,
  StepLabel,
  StepContent,
  Step,
  InputLabel,
  Link
} from '@material-ui/core';
import Page from 'src/components/Page';
import TopBar from 'src/layouts/DashboardLayout/TopBar'
import TransporterTable from '../customer/CustomerListView/TransporterTable'
import Firebase from 'src/Firebase.js'
import TableAssist from 'src/utils/TableAssist';
import GoogleMapReact from 'google-map-react';
import { HotTub } from '@material-ui/icons';
import HoverMarker from 'src/HoverMarker';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import {isMobile} from 'react-device-detect';
import moment from 'moment';


class JobSummaryPane extends Component {


  constructor() {
      super()
    //console.log(props)
    this.state = {

      tableItems: [],
      existingDict: {},
      initialSelectedIds: [],
      targetJob: '',
      summaryItems: {},
      driverData: {},
      vehicleData: {},
      vehicleTrackData: {},
      userTrackData: {},
      noExist: false,
      noExistData: false, 
      summaryLoaded: false, 
      jobLoaded: false, 
      actualJobData: {},
      zoomUpdate: 7,
      zoom: 7,
      drawnPoly: false,
      dirPolyRendered: false, 
      dirPolyline: null,
      driveTime: 'none',
      center: {
        lat: 40,
        lng: 40
      }

    }
    this.listeners = {}
    this.autocompleteService = { current: null };
    this.geocoder = {current: null};
    this.directionsService = {current: null};
    this.directionsDisplay = {current: null};
    this.bounds = {current: null};
    this.mapHolder = null;
    

  }

  timeConvert(n) {
    var num = n;
    var hours = (num / 60);
    var rhours = Math.floor(hours);
    var minutes = (hours - rhours) * 60;
    var rminutes = Math.round(minutes);
    if (hours >= 1) {
      return rhours + 'hour(s), ' + rminutes + ' minute(s)'
    }
    return rminutes + ' minute(s)'
    }

  polyRender(jobData, userData, vehicleData, map, maps) {

    console.log('try poly render')
    console.log(userData)
    console.log(vehicleData)
    var latTarget = null 
    var lngTarget = null 
    if (vehicleData != null && vehicleData != {} && Object.keys(vehicleData).length > 0) {
      latTarget = vehicleData.lat 
      lngTarget = vehicleData.lng 
    } else if (userData != null && userData != {} && 'coords' in userData) {
      console.log('set these things')
      latTarget = userData.coords.latitude
      lngTarget = userData.coords.longitude
    } else {
      console.log('dont set???')
    }

    console.log(latTarget)
    console.log(lngTarget)
    console.log(userData.coords)
    console.log(userData != null && userData != {} && 'coords' in userData)
    if (latTarget == null || lngTarget == null) {
      console.log('ret1')
      return 
    }

    if (this.state.actualJobData == null || this.state.actualJobData == {}) {
      console.log('ret11')
      return 
    }

    if (map == null || maps == null ) {
      console.log('ret111')
      return
    }

    if (jobData.state != 7 && jobData.state != 8) {
      return 
    }

    let strstart = latTarget + ', ' + lngTarget
    var strend = this.state.actualJobData.endPos.lat + ', ' + this.state.actualJobData.endPos.lng
    if (jobData.state == 7) {
      strend = jobData.startPos.lat + ', ' + jobData.startPos.lng
    }
    let strkey = strstart + '_' + strend
    console.log(strkey)
    if (this.directionsService.current) {
      this.directionsService.current.route({
        origin: strstart,
        destination: strend,
        travelMode: 'DRIVING'
      }, (response, status) => {
        if (status === 'OK') {
          console.log(response)
          console.log(response.routes[0].legs[0].start_location.lat())  
          console.log(response.routes[0].legs[0].start_location.lng())
    
          /*this.setState({
            startLat: response.routes[0].legs[0].start_location.lat(),
            startLng: response.routes[0].legs[0].start_location.lng(),
            endLat: response.routes[0].legs[0].end_location.lat(),
            endLng: response.routes[0].legs[0].end_location.lng()
          })*/
          //setStartLat(response.routes[0].legs[0].start_location.lat())
          //setStartLng(response.routes[0].legs[0].start_location.lng())
          //setEndLat(response.routes[0].legs[0].end_location.lat())
          //setEndLng(response.routes[0].legs[0].end_location.lng())
  
    
          this.directionsDisplay.current.setDirections(response);
          console.log(response.routes[0].overview_path)
          console.log('drawpoly')
          const routePolyline = new maps.Polyline({
            path: response.routes[0].overview_path
          });
          if (this.state.dirPolyline != null) {
            this.state.dirPolyline.setMap(null)
          }
          if (jobData.state == 7) {
                routePolyline.setOptions({strokeColor: 'rgb(25,188,156)', strokeWeight: 3});
          } else {
            routePolyline.setOptions({strokeColor: 'rgb(66,163,253)', strokeWeight: 3});
          }
          
          routePolyline.setMap(map);
    
          let mainRoute = response.routes[0]
          var retMiles = 0
          var time = 0 
          mainRoute.legs.map((leg, legIdx) => {
              let distMeters = leg.distance.value
              let distMiles = distMeters / 1000
              retMiles += distMiles
              time += leg.duration.text 
          })
          let timeval = this.timeConvert(Math.round(retMiles * 1.92))
    
          let milesStr = (Math.round(retMiles * 10) / 10) + ' Km'
          let dist = (Math.round(retMiles * 10) / 10)
    
          this.setState({
            dirPolyline: routePolyline,
            dirPolyRendered: true, 
            driveTime: timeval
          
          })
  
    
        } else {
          //window.alert('Directions request failed due to ' + status);
          console.log('Directions request failed due to ' + status)
          }
        });
  
    }
   


  }



  async componentDidMount() {
    console.log(this.props)
    console.log(JSON.stringify(this.props))
    console.log(this.props.location.pathname.split('/')[2])
    let targetJob = this.props.location.pathname.split('/')[2]


    //alert(this.props.location.pathname)
    console.log(targetJob)
    this.setState({
        targetJob: targetJob
    })

    console.log(Firebase.database)
    console.log('faj2039993ef99f9f9')
    if (!Firebase.database) {
        Firebase.awfulStuff.push(() => this.badMethod(targetJob))
    } else {
     // console.log('here is ' + targetJob)
        this.badMethod(targetJob)
    }
  }

  deactivateListenerIfNeeded(s) {
    if (s in this.listeners) {
      this.listeners[s].off('value')
    }
  }

  isNumeric(n) {
    return !isNaN(parseFloat(n)) && isFinite(n);
  }

  async badMethod(targetJoba) {
    let self = this 
    var targetJob = targetJoba 


 // First, check to see if there is a hyphen, for X-XXX format
 let hyphensplit = targetJob.split('-')
 console.log('hyphensplit')
 console.log(hyphensplit)
 var gotHyphen = false 
 if (hyphensplit.length > 1) {
   let hyphen_a = hyphensplit[0]
   let hyphen_b = +hyphensplit[1]
   console.log(hyphen_a)
   console.log(hyphen_b)
   let shipperUid = await Firebase.database.ref('mapInternalCodeToUid/' + hyphen_a).once('value').then((data) => {
     if (!data.exists()) {
         return null
     } else {
         return data.val()
     }
   }).catch((error) => {
       return null 
   })
   console.log(shipperUid)
   if (shipperUid != null) {
     let quoteIdVal = await Firebase.database.ref('mapShortIdToQuoteIdShipper/' + shipperUid + '/' + hyphen_b).once('value').then((data) => {
       if (!data.exists()) {
           return null
       } else {
           return data.val()
       }
     }).catch((error) => {
         return null 
     })
     console.log(quoteIdVal)
     if (quoteIdVal != null) {
       gotHyphen = true 
       targetJob = quoteIdVal
     }
   }
 }

    if (!gotHyphen && self.isNumeric(targetJoba) && targetJoba.length < 20) {
      let nextvt = await Firebase.database.ref('mapShortIdToQuoteId/' + targetJoba).once('value').then((data) => {
        if (!data.exists()) {
            return null
        } else {
            return data.val()
        }

    }).catch((error) => {
        return null 
    })
    if (nextvt != null) {
      targetJob = nextvt
    }
    }

    
    //console.log(targ)
    console.log('summaries/' + targetJob)
    Firebase.database.ref('summaries/' + targetJob).on("value", (inner_snapshot) => {
        // Get key of secondary item

        if (inner_snapshot.exists()) {
          console.log(inner_snapshot.val())
            self.setState({
                summaryItems: inner_snapshot.val(),
                noExist: false,
                summaryLoaded: true 
            })
        } else {
          self.setState({
            noExist: true,
            summaryLoaded: false 
          })
          console.log('no exist')
        }
        //self.loadSingleItemByKey(firebase_secondary_target, secondary_key)


    }, function (errorObject) {
        if (this.state.report_errors) {
            alert(errorObject)
        }
        console.log("The read failed: " + errorObject.code);
    });
  
    var authorizationData = {}
    authorizationData['quoteId'] = targetJob
    // give auth to read quote
    await Firebase.authorizeTrackingQuote(authorizationData, () => console.log('succeeded auth req'), () => console.log('failed auth req'))
    Firebase.trackMixpanel('shipperViewSummary', {'jobTarget': targetJoba, 'uid': Firebase.uid})
    Firebase.database.ref('shipperRequests/' + targetJob).on("value", async (inner_snapshot) => {
      // Get key of secondary item
      //alert(inner_snapshot.val())
      if (inner_snapshot.exists()) {
        console.log(inner_snapshot.val())

        if (self.state.dirPolyRendered || (self.state.actualJobData != null && self.state.actualJobData.state != 7 && self.state.actualJobData.state != 8) ) {
          self.polyRender(inner_snapshot.val(), self.state.userTrackData, self.state.vehicleTrackData, self.state.map, self.state.maps)
        }
          self.setState({
              actualJobData: inner_snapshot.val(),
              jobLoaded: true, 
              noExistData: false  
          })



          let itemVal = inner_snapshot.val()
          if (itemVal.state < 9 && itemVal.state > 5 && 'assignedDriver' in itemVal) {
            var authorizationData = {}
            authorizationData['driver'] = itemVal.assignedDriver
            if ('vehicleId' in itemVal && itemVal.vehicleId && itemVal.vehicleId.length && itemVal.vehicleId.length > 2) {
              authorizationData['vehicle'] = itemVal.vehicleId
            }
            await Firebase.authorizeTrackingView(authorizationData, () => console.log('succeeded auth req'), () => console.log('failed auth req'))

          /* this.deactivateListenerIfNeeded('driver')
              this.listeners['driver'] = Firebase.database.ref('users/' + itemVal.assignedDriver)
              this.listeners['driver'].on("value", (driver_snapshot) => {
                if (driver_snapshot.exists()) {
                  self.setState({
                    driverData: driver_snapshot.val()
                  })
                  let driver_snap = driver_snapshot.val()*/
                  if ('vehicleId' in itemVal && itemVal.vehicleId && itemVal.vehicleId.length && itemVal.vehicleId.length > 2) {
                    /*this.deactivateListenerIfNeeded('vehicle')
                    this.listeners['vehicle'] = Firebase.database.ref('vehicles/' + itemVal.vehicleId) 
                    this.listeners['vehicle'].on("value", (vehicle_snapshot) => {
                      if (vehicle_snapshot.exists()) {
                        self.setState({
                          vehicleData: vehicle_snapshot.val()
                        })
                        let vehicle_snap = vehicle_snapshot.val()*/
                        self.deactivateListenerIfNeeded('vehicleTrack')
                        this.listeners['vehicleTrack'] = Firebase.database.ref('trackableLocations/vehicles/' + itemVal.vehicleId)
                        this.listeners['vehicleTrack'].on("value", (loc_snapshot) => {
                          if (loc_snapshot.exists()) {
                            self.setState({
                              vehicleTrackData: loc_snapshot.val()
                            })
                            
                          } else {
                
                          }
                          
                      }, function (errorObject) {
          
                      });
                     /* } else {
            
                      }
                      
                  }, function (errorObject) {
      
                  });*/

                  }
                  self.deactivateListenerIfNeeded('driverTrack')
                  this.listeners['driverTrack'] = Firebase.database.ref('trackableLocations/users/' + itemVal.assignedDriver)
                  this.listeners['driverTrack'].on("value", (loc_snapshot) => {
                    if (loc_snapshot.exists()) {
                      self.setState({
                        userTrackData: loc_snapshot.val()
                      })
                      
                    } else {
          
                    }
                    
                }, function (errorObject) {
    
                });
               /* } else {
      
                }
                
            }, function (errorObject) {

            });*/
           


          } else {

          }

      } else {
        self.setState({
          noExistData: true,
          jobLoaded: false 
        })
        console.log('no exist')
      }
      //self.loadSingleItemByKey(firebase_secondary_target, secondary_key)


  }, function (errorObject) {
      //if (this.state.report_errors) {
      //    alert(errorObject)
      //}
      console.log("The read failed: " + errorObject.code);
  });

  }

  componentWillUnmount() {
    Firebase.database.ref('summaries/' + this.state.targetJob).off('value')
    }

    getState(item) {
      let state = item.summaryType
      if (state == 0) {
        return "Quote Requested"
      } else if (state == 1) {
        return "Quote Offered by Garri"
      } else if (state == 2) {
        if (item.isLtc) {
         return "Job Created From Long Term Contract" 
        }
        return "Quote Accepted"
      } else if (state == 3) {
        return "Job Offered to Transporters"
      } else if (state == 4) {
        return "Transporter Placed Bid"
      } else if (state == 5) {
        return "Transporter Assigned"
      } else if (state == 6) {
        return "Transporter Assigned Job To Driver"
      } else if (state == 7) {
        return "Driver En Route to Load"
      } else if (state == 8) {  
        return "Driver Loaded"
      } else if (state == 9) {
        return "Driver Delivered Load"
      } else if (state == -1) {
        return "Quote Declined By Shipper"
      } else if (state == -50) {
        return item.message
      }
    }

    renderItem(item) {
      return (
        <div style={{borderRadius: 10, backgroundColor: 'white',  justifyContent: 'center', display: 'flex',
        flexDirection: 'column', margin: 10, padding: 10}}>
        
                   <a style={{fontSize: 24, fontWeight: 'bold', marginBottom: 20}}>{this.getState(item)}</a>

                   <a>{moment(item.time).format('DD/MM/YYYY h:mm a')}</a>
                  </div>
      )
    }

    latRad(lat) {
      var sin = Math.sin(lat * Math.PI / 180);
      var radX2 = Math.log((1 + sin) / (1 - sin)) / 2;
      return Math.max(Math.min(radX2, Math.PI), -Math.PI) / 2;
    }

    handleApiLoaded(newMap, newMaps) {
      console.log('api load')
      //alert(newMap)
      this.setState({
        map: newMap, 
        maps: newMaps
      })

       if (!this.autocompleteService.current || !this.state.drawnPoly) {
         this.autocompleteService.current = new newMaps.places.AutocompleteService();
         this.geocoder.current = new newMaps.Geocoder();
   
         this.directionsService.current = new newMaps.DirectionsService();
         this.directionsDisplay.current = new newMaps.DirectionsRenderer();
         this. bounds.current  = new newMaps.LatLngBounds()
         if (this.state.actualJobData) {
           this.updateMapDraw(this.state.actualJobData.startPos, this.state.actualJobData.endPos, newMap, newMaps);
           this.polyRender(this.state.actualJobData, this.state.userTrackData, this.state.vehicleTrackData, newMap, newMaps)
         }
         
       }
     }

     updateMapDraw(startPos, endPos, mapObj, mapsObj ) {
      //alert('upda')
      if (startPos == null || endPos == null || this.directionsDisplay.current == null || this.directionsService.current == null) {
        return 
      }
    
    
      console.log('here a holder')
  
      console.log(startPos)
      console.log(endPos)
      let latDif = Math.abs(this.latRad(startPos.lat) - this.latRad(endPos.lat))
      let lngDif = Math.abs(startPos.lng - endPos.lng)
      console.log('heredifs')
      console.log(lngDif)
      console.log(latDif)
      //let latDifRaf = letRad(latDif)
      //let lngDifRad = 
    
      let latFrac = latDif / Math.PI 
      let lngFrac = lngDif / 360 
    
    
      let lngZoom = Math.log(1/latFrac) / Math.log(2)
      let latZoom = Math.log(1/lngFrac) / Math.log(2)
      console.log(latFrac)
      console.log(lngFrac)
      console.log(Math.log(1/latFrac) / Math.log(2))
      console.log(Math.log(1/lngFrac) / Math.log(2))
      //alert('new zoom ' + Math.min(lngZoom, latZoom))
      this.setState({
        zoom: Math.min(lngZoom, latZoom)
      })
      //setZoom()
    
      let cx = startPos.lat + ((endPos.lat - startPos.lat) / 2)
      let cy = startPos.lng + ((endPos.lng - startPos.lng) / 2)
      //setCenter()
      this.setState({
        center: {lat: cx, lng: cy}
      })
    
      //setZoom(getBoundsZoomLevel(bounds, { height: mapHolder.current.clientHeight, width: mapHolder.current.clientWidth }))
    
      let strstart = startPos.lat + ', ' + startPos.lng
      let strend = endPos.lat + ', ' + endPos.lng
      let strkey = strstart + '_' + strend
      console.log(strkey)
      this.directionsService.current.route({
        origin: strstart,
        destination: strend,
        travelMode: 'DRIVING'
      }, (response, status) => {
        if (status === 'OK') {
          console.log(response)
          console.log(response.routes[0].legs[0].start_location.lat())  
          console.log(response.routes[0].legs[0].start_location.lng())
    
          this.setState({
            startLat: response.routes[0].legs[0].start_location.lat(),
            startLng: response.routes[0].legs[0].start_location.lng(),
            endLat: response.routes[0].legs[0].end_location.lat(),
            endLng: response.routes[0].legs[0].end_location.lng()
          })
          //setStartLat(response.routes[0].legs[0].start_location.lat())
          //setStartLng(response.routes[0].legs[0].start_location.lng())
          //setEndLat(response.routes[0].legs[0].end_location.lat())
          //setEndLng(response.routes[0].legs[0].end_location.lng())

    
          this.directionsDisplay.current.setDirections(response);
          console.log(response.routes[0].overview_path)
          console.log('drawpoly')
          const routePolyline = new mapsObj.Polyline({
            path: response.routes[0].overview_path
          });
          if (this.state.lastPolyline != null) {
            this.state.lastPolyline.setMap(null)
          }
          routePolyline.setMap(mapObj);
    
          let mainRoute = response.routes[0]
          var retMiles = 0
          mainRoute.legs.map((leg, legIdx) => {
              let distMeters = leg.distance.value
              let distMiles = distMeters / 1000
              retMiles += distMiles
          })
    
          let milesStr = (Math.round(retMiles * 10) / 10) + ' Km'
          let dist = (Math.round(retMiles * 10) / 10)
    
          this.setState({
            lastPolyline: routePolyline,
            drawnPoly: true, 
            distance: dist 
          })

    
        } else {
          console.log('Directions request failed due to ' + status)
          //window.alert('Directions request failed due to ' + status);
          }
        });
    }

    padDigits(number) {
      return Array(Math.max(3 - String(number).length + 1, 0)).join(0) + number;
    }

    renderStepContent(item, step) {

      if (step.summaryType == 2 && step.isLtc || step.summaryType == 0) {
        return <div style={{flexDirection: 'column', display: 'flex'}}>
          <a style={{fontSize: 24, fontWeight: 'bold', marginTop: 5, marginBottom: 5}}>Pickup Details</a>
          <a >{item.pickupLocation}</a>
          <a style={{fontSize: 24, fontWeight: 'bold',  marginTop: 5, marginBottom: 5}}>Dropoff Details</a>
          <a>{item.dropoffLocation}</a>
     
        </div>
      } else if (step.summaryType == 3) {
        return <div style={{flexDirection: 'column', display: 'flex'}}>
       
        </div>
      } else if (step.summaryType == 5) {
        return <div style={{flexDirection: 'column', display: 'flex'}}>
        <a >{'The transporter for your job is: ' + item.assignedTransporterName}</a>
        </div>
      } else if (step.summaryType == 6) {
        return <div style={{flexDirection: 'column', display: 'flex'}}>
        <a >{'The driver for your job is: ' + item.assignedDriverName}</a>
        {item.vehiclePlate && item.vehiclePlate != 'Unassigned' && item.vehiclePlate != 'undefined' ? <a>{'Vehicle Plate: ' + item.vehiclePlate}</a> : null}
        </div>
      }  else if (step.summaryType == 7) {
        return <div style={{flexDirection: 'column', display: 'flex'}}>
        <a >{'The driver is en route to the pickup location!'}</a>
        {this.state.driveTime != 'none' && this.state.actualJobData != null && this.state.actualJobData.state == 7 ? <a>{'Driver ETA At Pickup: ' + this.state.driveTime}</a> : null}
        </div>
      }  else if (step.summaryType == 8) {
        return <div style={{flexDirection: 'column', display: 'flex'}}>
        <a >{'The driver has picked up the load!'}</a>
        {this.state.driveTime != 'none' && this.state.actualJobData != null && this.state.actualJobData.state == 8 ? <a>{'Driver ETA At Dropoff: ' + this.state.driveTime}</a> : null}
        </div>
      }  else if (step.summaryType == 9) {
        return <div style={{flexDirection: 'column', display: 'flex'}}>
        <a >{'The driver has deliviered the load!'}</a>
        </div>
      } else {
        return <div/>
      }


    }

    getItemKey(item) {
      if (item.time != null && item.summaryType != null) {
        return item.time + item.summaryType + Date.now()
      } else if (item.summaryType != null) {
        return item.summaryType + Date.now()
      } else if (item.time != null) {
        return item.time + Date.now()
      } else {
        return Date.now()
      }
    }

    renderStep(item, summary) {
         return <Step active={true} key={item.time && item.summaryType ? item.time + item.summaryType + Date.now() : item.summaryType + Date.now()}>
            <StepLabel>{this.getState(summary) + ' (' + moment(summary.time).format('DD/MM/YYYY h:mm a') + ')'}</StepLabel>
            <StepContent>{this.renderStepContent(item, summary)}</StepContent>
          </Step>
    }

    renderSteps(baseItem, summaries) {
      var stepSet = []
      var stepDict = {}
      var lastTransporterAssignmentTime = null 
      Object.keys(summaries).map((sumKey, idx) => {

        let item = summaries[sumKey]
        if (item.summaryType == 3 || item.summaryType == 4 || item.summaryType == 5) {
          return 
        } else {
          stepDict[item.time + '' + item.summaryType] = item 

          // These two cases are to prevent double instance of transporter assigned job to driver
          if (item.summaryType == 6 && lastTransporterAssignmentTime != null ) {
            if (lastTransporterAssignmentTime in stepDict && stepDict[lastTransporterAssignmentTime].summaryType == 6 && (lastTransporterAssignmentTime != item.time + '' + item.summaryType )) {
              delete stepDict[lastTransporterAssignmentTime]
            }
          }
          if (item.summaryType == 6) {
            lastTransporterAssignmentTime = item.time + '' + item.summaryType
          }
        }
        
      })
      var keys = Object.keys(stepDict)
      keys.sort()
      keys.map((item, idx) => {
        stepSet.push(stepDict[item])
      })

      return <Stepper style={{backgroundColor: 'white', borderRadius: 10}}  orientation="vertical">
          {stepSet.map((item, idx) => {
            return this.renderStep(baseItem, item)
          })}
        </Stepper>
    }

    getIdDisplay(val) {
      if ('internalShipperId' in val && 'shortIdShipper' in val) {
        return val.internalShipperId + '-' + this.padDigits(val.shortIdShipper)
      } else {
        return val.shortId
      }
    }

    getLiveTrackText(jobData) {
      if (jobData.shortIdShipper > jobData.shortIdShipperLINK) {
        return 'View First Leg of Trip: '
      } else {
        return 'View Second Leg of Trip: '
      }
    }


  render() {

    if (this.state.noExist) {
      return (
        <Page title={'Job Not Found'}>
         <TopBar />
         <div style={{marginTop: 100, marginLeft: 20}}>
         <Box mb={3}>
                          <Typography
                            color="textPrimary"
                            variant="h2"
                          >
                           Job Status Tracking
                          </Typography>
                         
                        </Box>
                        <Box mb={3}>
             
                         
                        </Box>
                        
         <Box mt={3}>
        
           <div>
           <Typography
                            color="textSecondary"
                            gutterBottom
                            variant="body2"
                          >
                            {'Request Id:' + this.state.targetJob + ' does not exist.'}
                          </Typography>
        
           </div>
         </Box>
               </div>
                </Page>)
    }

    if (!this.state.jobLoaded || !this.state.summaryLoaded) {
        return (<div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}><CircularProgress/></div>)

    }

    return (
<Page title={this.getIdDisplay(this.state.actualJobData)}>
 <TopBar />
 <div style={{marginTop: 100, marginLeft: 20, marginBottom: 20}}>
 <Box mb={3}>
                  <Typography
                    color="textPrimary"
                    variant="h2"
                  >
                   {'Job Status Tracking: ' + this.getIdDisplay(this.state.actualJobData)}
                  </Typography>
                 
                </Box>
                <Box mb={3}>
     {this.state.actualJobData && this.state.actualJobData.roundTripLink && this.state.actualJobData.roundTripLink != -1 ?
             <Button
             key="plzplei3j9fam"
       color="primary"
       onClick={() => window.open('https://shipper.garrilogistics.com/info/' + this.state.actualJobData.internalShipperId + '-' + TableAssist.padDigits(this.state.actualJobData.shortIdShipperLINK))}
       variant="contained"
     >
       {this.getLiveTrackText(this.state.actualJobData) + this.state.actualJobData.internalShipperId + '-' + TableAssist.padDigits(this.state.actualJobData.shortIdShipperLINK)}
     </Button>
     : null}
                  <Typography
                    color="textSecondary"
                    gutterBottom
                    variant="body2"
                  >
                    {'Garri Id: ' + this.state.actualJobData.shortId}
                  </Typography>
                </Box>
                
 <Box mt={3}>

   <div style={{flexDirection: isMobile ? 'column' : 'row', display: 'flex', justifyContent: 'space-between',}}>


     <div style={{flexDirection: 'column', display: 'flex', marginBottom: 20, paddingBottom: 20}}>
     

   {this.renderSteps(this.state.actualJobData, this.state.summaryItems)}

   <Box mb={3}>
                  <Typography
                    color="textPrimary"
                    variant="h2"
                  >
                   Documents
                  </Typography>
                 
                </Box>
            

   { this.state.actualJobData && this.state.actualJobData.documentList ? <div style={{flexDirection: 'column', display: 'flex'}}><InputLabel>Attached Documents</InputLabel>{
            this.state.actualJobData.documentList.map((item, idx) => {
              return <div style={{display: 'flex', flexDirection: 'row'}}>{item.isPdf ? null : <img style={{width: 50, height: 50}} src={item.linkval ? item.linkval : item}/>}<Link
              to={item}
              onClick={item.linkval ? () => window.open(item.linkval) : () => window.open(item)}
              variant="h6"
            >{item.docname ? item.docname : 'Document ' + (idx + 1)}</Link></div>
            })
          }</div> : <div><InputLabel>No Load Documents Uploaded Yet</InputLabel></div>}
</div>

< div id='mapElementId' ref={(r) => this.mapHolder = r} style={{ height: 500, width: isMobile ? '99%' : '50%', marginLeft: 10, }}>
      <GoogleMapReact
          bootstrapURLKeys={{ key: 'AIzaSyAkPLP3zHG-2kctVakLZwdUQHqeLVO0Onw', libraries: 'places' }}
          defaultCenter={this.state.center}
          defaultZoom={this.state.defaultZoom}
          center={this.state.center}
          zoom={this.state.zoom}
          onChange={(x) => this.setState({zoomUpdate: x.zoom})}
          yesIWantToUseGoogleMapApiInternals={true}
          onClick={(val) => console.log(val)}
          onGoogleApiLoaded={({ map, maps }) => this.handleApiLoaded(map, maps)}
      >
        {this.state.actualJobData.pickupLocation != '' && this.state.actualJobData.dropoffLocation != '' ? <HoverMarker zoomSet={this.state.zoomUpdate} lat={this.state.actualJobData.startPos.lat} lng={this.state.actualJobData.startPos.lng} text={'Location'} idx={0} clickMarker={() => console.log('rmm')} /> : <div/>}
        {this.state.actualJobData.pickupLocation != '' && this.state.actualJobData.dropoffLocation != '' ? <HoverMarker zoomSet={this.state.zoomUpdate} lat={this.state.actualJobData.endPos.lat} lng={this.state.actualJobData.endPos.lng} text={'Location'} idx={1} clickMarker={() => console.log('rmm')} /> : <div/>}
        {this.state.vehicleTrackData != {} && this.state.vehicleTrackData != null && Object.keys(this.state.vehicleTrackData).length > 0 ? 
        <div lat={this.state.vehicleTrackData.lat} lng={this.state.vehicleTrackData.lng}>
          <LocalShippingIcon/>
        </div>: null}
        {(this.state.vehicleTrackData == {} || Object.keys(this.state.vehicleTrackData).length < 1) && this.state.userTrackData != {} && this.state.userTrackData != null && this.state.userTrackData.coords != null ? 
        <div lat={this.state.userTrackData.coords.latitude} lng={this.state.userTrackData.coords.longitude}>
          <LocalShippingIcon/>
          {this.polyRender(this.state.actualJobData, this.state.userTrackData, this.state.vehicleTrackData, this.state.map, this.state.maps)}
        
      </div>
        : null}


        </GoogleMapReact>
      <Typography>{this.state.actualJobData.distance ? this.state.actualJobData.distance + ' km' : ''}</Typography>
      
      {this.state.actualJobData.shipperNotes && this.state.actualJobData.shipperNotes != '' ?
      <div style={{marginBottom: 10}}>
                          <Typography
                    color="textPrimary"
                    variant="h4"
                  >
                   Notes
                  </Typography>
                  <a>{this.state.actualJobData.shipperNotes}</a>
        </div>
    : null}
      
      </div>

   </div>
 </Box>
       </div>
        </Page>
    )
  }


}

export default JobSummaryPane;