import React, { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  AppBar,
  Badge,
  Box,
  Hidden,
  IconButton,
  Toolbar,
  makeStyles,
  Typography,
  Menu,
  MenuItem
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import NotificationsIcon from '@material-ui/icons/NotificationsOutlined';
import InputIcon from '@material-ui/icons/Input';
import Logo from 'src/components/Logo';
import Firebase from 'src/Firebase'
import MyContext from 'src/MyContext'


const useStyles = makeStyles(() => ({
  root: {},
  avatar: {
    width: 60,
    height: 60
  }
}));

const TopBar = ({
  className,
  onMobileNavOpen,
  ...rest
}) => {
  const classes = useStyles();
  const [notifications] = useState([]);
  const [menuOpen, setMenuOpen] = useState(false);

  const [notRef, setNotRef] = React.useState(null)
  const navigate = useNavigate()

  return (
    <MyContext.Consumer>{context => (
    <AppBar
      className={clsx(classes.root, className)}
      elevation={0}
      {...rest}
      style={{backgroundColor: context.isAdmin ? 'rgb(255,149,3)' : ''}}
    >
      <Toolbar>
        <RouterLink to="/">
          <Logo />
        </RouterLink>
        <Box flexGrow={1} />
        <Hidden mdDown>
          <IconButton color="inherit">
          
           {false ? <Badge
            badgeContent={Object.keys(context.webNotifications).length}
            color="error"
          >
            <NotificationsIcon ref={notRef} onClick={(event) =>{
              if (Object.keys(context.webNotifications).length > 0) {
                setNotRef(event.currentTarget)
                setMenuOpen(true)
                console.log(notRef)
              }


            }}/>
             <Menu
              id="simple-menu"
              getContentAnchorEl={null}
    anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
    transformOrigin={{ vertical: "top", horizontal: "center" }}
              style={{minWidth: 300,
              maxWidth: 300, width: 300}}
              anchorEl={notRef}
              keepMounted
              open={menuOpen}
              onClose={() => setMenuOpen(false)}
              >{
                Object.keys(context.webNotifications).map((key, idx) => {
                  return <MenuItem style={{whiteSpace: 'normal'}} onClick={() => {
                    navigate(context.webNotifications[key].navPath)
                    setMenuOpen(false)
                    Firebase.deleteWebNotification(key, context.isAdmin)
                  }}><div style={{width: 300, flexDirection: 'row', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <div style={{backgroundColor: 'red', height: 5, width: 17, borderRadius: 2, marginRight: 10}}></div>
                    <a>{context.webNotifications[key].text}</a>
                    </div>
                    </MenuItem>
                })
              }
              </Menu>
          </Badge> : null }


          </IconButton>
    <IconButton color='white'>
      <a style={{color: 'white', fontSize: 16}} onClick={() => Firebase.auth.signOut().then(function() {
                      // Sign-out successful.
                      Firebase.resetMixpanel()
                    }).catch(function(error) {

                      // An error happened.
                    })}>Logout</a>

                  </IconButton>
        </Hidden>
        <Hidden lgUp>
          <IconButton
            color="inherit"
            onClick={onMobileNavOpen}
          >
            <MenuIcon />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
    )}</MyContext.Consumer>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func
};

export default TopBar;
