import React, { useState } from 'react';
import { Link as RouterLink, useNavigate, useLocation} from 'react-router-dom';
import { Document, Page, pdfjs } from 'react-pdf';
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'
import CircularProgress from '@material-ui/core/CircularProgress'
import Link2 from '@material-ui/core/Link'
import TableAssist from 'src/utils/TableAssist'
import Firebase from 'src/Firebase'
import MyContext from 'src/MyContext'
import copy from 'copy-to-clipboard';
import EmailDialog from 'src/utils/EmailDialog'
// core components
import FileCopy from '@material-ui/icons/FileCopy';


pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const ViewInvoice = () =>  {
  const [numPages, setNumPages] = useState(0);
  const [isLoading, setIsLoading] = useState(true)
  const [isContractLoading, setIsContractLoading] = useState(false)
  const [pageNumber, setPageNumber] = useState(1);
  const [archiveAlertOpen, setArchiveAlertOpen] = useState(false)
  const [archiveDialogWorking, setArhivceDialogWorking] = useState(false)

  const {state} = useLocation();
  const [contractId, setAssociatedContract] = React.useState(state.customer.contractId ? state.customer.contractId : -1)
  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setIsLoading(false)
  }

  function sendEmail(x) {
    setArhivceDialogWorking(true)
    var emailData = {}
    emailData['uid'] = state.customer.shipper 
    emailData['subject'] = x.notificationHeader 
    emailData['body'] = x.notificationBody
    emailData['invoiceId'] = state.customer.invoiceNumber
    let s = (x) => {
      setArhivceDialogWorking(false)
      if (x == 200) {
        alert('Successfully sent email.')
      } else {
        alert('Error sending email, user not found.')
      }

     
    }
    let f = () => {
      setArhivceDialogWorking(false)
      alert('Error sending email.')
    }
    Firebase.sendInvoiceEmailAttachment(emailData, (xx) => s(xx), f)
  }

  function updateContractLink() {
    setIsContractLoading(true)
    let s = () => {
      setIsContractLoading(false)
      alert('Successfully updated.')
    }
    let f = () => {
      setIsContractLoading(false)
      alert('Error updating contract.')
    }
    var datPack = {}
    datPack['invoiceId'] = state.customer.invoiceId 
    datPack['contractId'] = contractId 
    Firebase.updateInvoiceContract(datPack, s, f)
  }

  function getUserName(context) {
    var shipper = ''
    context.users.map((item, idx) => {
      if (item.userId == state.customer.shipper) {
        shipper = item.firstName
      }
    })

    return shipper 
  }

  function getUserEmail(context) {
    var shipper = ''
    context.users.map((item, idx) => {
      if (item.userId == state.customer.shipper) {
        shipper = item.email
      }
    })

    return shipper 
  }

  return (
    <MyContext.Consumer>{context => (
    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
           {context.isAdmin ? 
           <EmailDialog open={archiveAlertOpen}
           dialogWorking={archiveDialogWorking}
           onClose={() => setArchiveAlertOpen(false)}
           alertTitle={'Send pro forma invoice as email attachment?'}
           alertBody={'The email will be sent to: ' + getUserEmail(context)}
           name={getUserName(context)}
           routeString={state.customer.trips[0].routeString}
           alertCancelText={'Cancel'}
           alertConfirmText={'Send Email'}
           onConfirm={(x) => sendEmail(x)}
           />
           : null} 
      <div style={{flexDirection: 'row', display: 'flex'}}>
      <Button
            color="primary"
            variant="contained"
            style={{marginTop: 5, marginBottom: 5}}
            onClick={() => window.open(state.customer.pdflink)}
          >
            Export PDF
          </Button>
         {state.customer.proForma && context.isAdmin ?
         <Button
         color="primary"
         variant="contained"
         style={{marginTop: 5, marginBottom: 5}}
         onClick={() => setArchiveAlertOpen(true)}
       >
         Send Email
       </Button>
         : null} 
      </div>
        
          <div style={{display: 'flex', flexDirection: 'row'}}>
              <a style={{marginRight: 5}}>Quick Link: </a>
          <Link2
              to={Firebase.ip + 'invoices/' + state.customer.invoiceNumber}
              onClick={ () => window.open(Firebase.ip + 'invoices/' + state.customer.invoiceNumber)}
              variant="h6"
            >{Firebase.ip + 'invoices/' + state.customer.invoiceNumber}</Link2>
            <FileCopy style={{marginLeft: 5, marginTop: -3}} color="black" onClick={() => copy(Firebase.ip + 'invoices/' + state.customer.invoiceNumber)} />    
        </div>

        {state.customer.proForma && context.isAdmin ?
      <div>
        <div style={{display: 'flex', flexDirection: 'row'}}>
        <TextField select 
    
               disabled={isContractLoading}
               label="Associated Contracts"
               margin="normal"
               id="contractId"
               name="contractId"
               value={contractId}
               onChange={(e => {
                 setAssociatedContract(e.target.value)})}
             >
               {context.adminContracts.filter((item, idx) => {
                 return item.shipper == state.customer.shipper
               }).concat([{'contractId': -1}]).map((item, idx) => {
                 return (<MenuItem value={item.contractId}>{item.contractId == -1 ? 'Unassigned' : '(' + item.contractName + ')' + ' ' + item.pickupLocation + ' to ' + item.dropoffLocation}</MenuItem>)
               })}
             </TextField>
             <Button
            color="primary"
            onClick={() => updateContractLink()}
          >
            Save
          </Button>
          {isContractLoading ? <CircularProgress/> : null}
        </div>

        </div>
        : null  
      }

          
          <Document
          file={state.customer.pdflink}
          onLoadSuccess={onDocumentLoadSuccess}
        >

{isLoading ? 
          <div style={{flexDirection: 'column', display: 'flex'}}>
              <a>Loading PDF Invoice...</a>
              <CircularProgress/>
          </div>
          : 
            <div>{[...Array(numPages).keys()].map((item, idx) => {
                return (
                <div>
   <Page pageNumber={idx + 1} />
   <p>Page {idx + 1} of {numPages}</p>
                </div>
               
                  
                  )
            })}</div>
        }
          
        </Document>

      
    </div>
    )}</MyContext.Consumer>
  );
}

export default ViewInvoice