import React, {useState, useEffect, useRef} from 'react';
import { Link as RouterLink, useNavigate, useLocation} from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';

import LocationOnIcon from '@material-ui/icons/LocationOn';
import {
  Box,
  Button,
  Checkbox,
  Container,
  FormHelperText,
  Link,
  TextField,
  Typography,
  makeStyles,
  Grid,
  Select,
  MenuItem,
  CircularProgress
} from '@material-ui/core';
import Page from 'src/components/Page';
import parse from 'autosuggest-highlight/parse';
import throttle from 'lodash/throttle';
import Firebase from 'src/Firebase'
import DataFetcher from 'src/DataFetcher';
import MyContext from 'src/MyContext';
import TableAssist from 'src/utils/TableAssist';
import JobPaymentComponent from './JobPaymentComponent';
import { TrainOutlined } from '@material-ui/icons';
import PaymentCard from './PaymentCard';
import JobCardPayment from './JobCardPayment';


const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
const filter = createFilterOptions();

const autocompleteService = { current: null };
const geocoder = {current: null};



const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  }
}));


const NewPaymentFromSheetsView = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const jobsRef = useRef([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [fullValues, setFullValues] = React.useState([])
  const [totalNet, setTotalNet] = React.useState({})
  const [arraySet, setArraySet] = React.useState([])
  const [activeSheet, setActiveSheet] = React.useState({})
  const [activeJobs, setActiveJobs] = React.useState([])
  const [activeSheetIdx, setActiveSheetIdx] = React.useState(-1)
  const [activeJobIdx, setActiveJobsIdx] = React.useState(-1)
  const [selectedPayments, setSelectedPayments] = React.useState([])
  const [selectedPaymentsDict, setSelectedPaymentsDict] = React.useState({})
  const [selectedJobs, setSelectedJobs] = React.useState([])
  const [selectedJobsDict, setSelectedJobsDict] = React.useState({})
  const [didRegisterSetFunction, setDidRegisterSetFunction] = React.useState(false)
  const [setFunction, setSetFunction] = React.useState(() => (x,y) => console.log(x + ' ' + y))
  const [fkVar, setFkVar] = React.useState(4)
  const {state} = useLocation();

  function resetAll() {
    setSelectedJobsDict({})
    setSelectedJobs([])
    setSelectedPaymentsDict({})
    setSelectedPayments([])
    if (didRegisterSetFunction) {
      setFunction('associatedJobs', [])
      setTotalNet({})
    }
  }

  function registerSetFunction(func) {
    setSetFunction(() => func)
    setDidRegisterSetFunction(true)
  }

  function mergeSetJobList() {
    var jobList = []
    selectedJobs.map((jobItem, idx) => {
      var job = {}
      job['quoteId'] = jobItem.quoteId
      job['assignedJob'] = jobItem.quoteId
      let targetIdx = selectedJobsDict[jobItem.quoteId]
      var targPay = null 
      if (activeJobs.jobs && activeJobs.jobs.length > targetIdx) {
        targPay = activeJobs.jobs[targetIdx]
        job['deductOnePercent'] = targPay.deductOnePercent
        job['plate'] = jobItem.vehiclePlate 
        job['trailer'] = jobItem.vehicleTrailerPlate
        job['finalWeight'] = targPay.totalWeight 
        job['rate'] = targPay.rate 
        if ( false && targPay.name == jobItem.assignedDriverName) {
          job['recipient'] = jobItem.assignedDriver 
        } else {
          job['recipient'] = jobItem.assignedTransporter
        }
        job['extraDeduction'] = targPay.deductExtra
        job['reactKey'] = jobItem.quoteId
        job['deliveryTime'] = targPay.deliveryDate
        job['accountNumber'] = activeJobs.account
        job['bankValue'] = 2
        jobList.push(job)
      }
    })
    if (didRegisterSetFunction) {
      setFunction('associatedJobs', jobList)
    }

  }

  function addPaymentToSelection(idx) {
    if (selectedPayments.length > selectedJobs.length) {
      alert('Choose a job for the first payment before selecting another.')
      return 
    }
    var v = selectedPayments 
    var d = selectedPaymentsDict
    v.push(idx)
    d[idx] = idx
    
    setSelectedPayments(v)
    setSelectedPaymentsDict(d)
    // state updates don't happen properly if this isn't set for some reason????????????? react sucks
    setFkVar(38)
  }
  function removePaymentFromSelection(idx) {
    var v = selectedPayments 
    var d = selectedPaymentsDict
    v = v.filter((item, ix) => {
      return item != idx
    })
    delete d[idx]
    setSelectedPayments(v)
    setSelectedPaymentsDict(d)
    setFkVar(94)
    
    Object.keys(selectedJobsDict).map((k, id) => {
     
      if (selectedJobsDict[k] == idx) {

        removeJobFromSelection({'quoteId': k})

      }
    })
  } 
  function addJobToSelection(job) {
    if (job.paymentId) {
      alert('This job already has an associated payment.')
      return
    }
    if (selectedPayments.length <= selectedJobs.length) {
      alert('Choose a payment first before adding a job.')
      return 
    }

    var d = selectedJobsDict
    var payIdx = selectedPayments[selectedPayments.length - 1]
    d[job.quoteId] = payIdx
    var v = selectedJobs
    
    v.push(job)
 
    setSelectedJobs(v)
    setSelectedJobsDict(d)
    setFkVar(835)
    mergeSetJobList()

  }

  function removeJobFromSelection(job) {
    var v = selectedJobs
    var d = selectedJobsDict
    v = v.filter((item, ix) => {
      return item.quoteId != job.quoteId 
    })
    delete d[job.quoteId]
    var vnet = totalNet
    delete vnet[job.quoteId]
    setTotalNet(vnet)
    setSelectedJobs(v)
    setSelectedJobsDict(d)
    setFkVar(42)
    mergeSetJobList()
  }

  function tryJobSelection(job) {
    if (job.quoteId in selectedJobsDict) {
      removeJobFromSelection(job)
    } else {
      addJobToSelection(job)
    }
  }
  function tryPaymentSelection(idx) {

    if (idx in selectedPaymentsDict) {

      removePaymentFromSelection(idx)
    } else {
      
      addPaymentToSelection(idx)
    }
  }


  function doCreatePayment(val) {
    setIsLoading(true)
    var v = {}
    v['jobs'] = val

    let s = (xv) => {
      setIsLoading(false)
      if (xv.code != 200) {
        alert('Could not create payment due to rule violation. Error code: ' + xv.code)
        return 
      } else {
        resetAll()
      }
    }

    let f = () => {
      setIsLoading(false)
      alert('Error creating payment.')
    }

    console.log(v)
    Firebase.adminCreatePayment(v, (x) => s(x), f)
  }

  function compare2(aa, bb, cfList) {
    
    var dif1 = 0
    var dif2 = 0
    cfList.map((item, idx) => {
      if (aa.endDateEarly) {
        dif1 += Math.abs(aa.endDateEarly - item )
      }
      if (bb.endDateEarly) {
        dif2 += Math.abs(bb.endDateEarly - item)
      }
    })

  
    if (dif1 < dif2) {
        return -1 
    }
    if (dif2 < dif1) {
        return 1
    }
    return 0;
  }
    
    function sortItems(v) {
    //console.log('try sorting')
    //console.log(v)
    //console.log(s)
    var cfList = []
    if (activeJobs && activeJobs.jobs) {
      activeJobs.jobs.map((item, idx) => {
        if (item.deliveryDate) {
          cfList.push(item.deliveryDate)
        }
      })
    }
    if (cfList.length < 1) {
      return v
    }
    var o = v
    o.sort((a, b) => compare2(a, b, cfList))
    return o
    }

  function submitForPayment(values) {

    var block = false
    var bankBlock = false
    var recipientSet = null
    var blockKey = ''
    var subset = {}
    if (values.associatedJobs.length < 1) {
      alert('Submit at least one job for payment.')
      return
    }
    let map1 = ['assignedJob', 'recipient', 'bankValue', 'accountNumber']
    let map2 = ['rate', 'finalWeight']
    values.associatedJobs.map((item, idx) => {
      console.log('logjob')
      console.log(item)

      map1.map((innerItem, innerIdx) => {
        if (!item[innerItem] || item[innerItem] == '') {
          block = true
          blockKey = innerItem
        }
        subset[innerItem] = item[innerItem]
      })
      map2.map((innerItem, innerIdx) => {
        if (!item[innerItem] || item[innerItem] <= 0 || item[innerItem] == '') {
          block = true
         
          blockKey = innerItem
        }
        subset[innerItem] = item[innerItem]
      })
      if (item.bankValue != 2) {
        
        bankBlock = true
        block = true 
        blockKey = 'BANK'
      }
      if (recipientSet == null) {
        recipientSet = item.recipient
      } else if (recipientSet != item.recipient) {
        block = true 
        blockKey = 'recipient'
      }

    })

    //alert(JSON.stringify(subset))
    if (bankBlock) {
      alert('Currently only bank of Abyssinia is supported for payments.')
      return
    }
    if (block) {
      alert(blockKey + 'Before submitting, make sure that each job has a recipient, bank account, bank account number, rate, and weight specified. In addition, make sure all jobs on the payment were done by the same recipient.')
      return
    }
    //alert(JSON.stringify(values.associatedJobs))
    //return
    doCreatePayment(values.associatedJobs)
  }

  function updateTotalNet(v, k, setFunc) {
    //alert(v + ' ' + k)
    
    if ((k in totalNet) && totalNet[k] == v) {
      return
    }
    var va = totalNet
    va[k] = v

    setTotalNet(va)
    setArraySet([v])
    //setFunc('total', JSON.stringify(va))
    //alert(JSON.stringify(totalNet))
  }

  function precisionRound(num) {
    
    return +num.toFixed(2)
  }

  function sumTotalNet(total) {
    //alert('doing a sum' + JSON.stringify(total))
    var sum = 0
    Object.keys(total).map((item, idx) => {
      sum += total[item]
    })
    return precisionRound(sum) 
  }

  function updateFullValues(val, idx, vassoc, setFunc) {
    //alert(JSON.stringify(val) + ' aaaand ' + idx)
    var vv = vassoc.map((item, idxx) => {
      if (idx == idxx) {
        var nv = item 
        Object.keys(val).map((innerKey, innerIdx) => {
          nv[innerKey] = val[innerKey]
        })
        return nv
      }
      return item 
    }) 

    setFunc('associatedJobs', vv)
  }

  function deleteAtIdx(reactKey, idx, vassoc, setFunc) {
    var v = fullValues.filter((item, idxxx) => {
      return idx != idxxx
    })
    var vv = vassoc.filter((item, idxxx) => {
      return idx != idxxx
    })
    if (reactKey in totalNet) {
      var vnet = totalNet
      delete vnet[reactKey]
      setTotalNet(vnet)
    }
    setFunc('associatedJobs', vv)
  }

  function filterUnpaidJobMatches(adminJobs, matchJobs, usersNameDict) {
    let recipient = matchJobs.recipient

    return adminJobs.filter((adminJob, adminJobIdx) => {
      /*if (adminJob.assignedDriverName != recipient && (adminJob.assignedTransporterName != recipient && usersNameDict[adminJob.assignedTransporter] != recipient)) {
        return false 
      }*/ 

      var canRet = true 
      var matchesAtLeastOne = false 
      if (matchJobs.jobs && matchJobs.jobs.length > 0) {

        matchJobs.jobs.map((innerJob, innerJobIdx) => {
          // need to reset filter here, otherwise it will fail to match any time the payments have different details
          var innerCanRet = true 
          if (innerJob.plate != null) {
            if (innerJob.plate.main && innerJob.plate.main != '' && innerJob.plate.main != adminJob.vehiclePlate) {
              innerCanRet = false
            }
            if (innerJob.plate.trl && innerJob.plate.trl != '' && innerJob.plate.trl != adminJob.vehicleTrailerPlate) {
              innerCanRet = false 
            }
          }
          if (innerJob.rate && adminJob.transporterPricePerQuintal != innerJob.rate) {
            innerCanRet = false 
          }
          if (innerCanRet == true) {
            matchesAtLeastOne = true
          } else {
            canRet = false
          }
        })
      }

      return canRet || matchesAtLeastOne

    })
  }

  useEffect(() => {
    var parsed = state.parsedData 
    if (parsed.length > 0) {
      setActiveSheet(parsed[0])
      setActiveSheetIdx(0)
      if (parsed[0].trueSet && parsed[0].trueSet.length > 0) {
        setActiveJobs(parsed[0].trueSet[0])
        setActiveJobsIdx(0)
      }
    }
  }, []);
  
 

  return ( <MyContext.Consumer>{context => (<Page
    className={classes.root}
    height='100%'
    title="New Payment"
    id="ff2"
    backgroundColor={classes.root.backgroundColor}
  >
        <Box
      display="flex"
      flexDirection="column"
      height="100%"
      id="bb2"
      justifyContent="center"
      backgroundColor={classes.root.backgroundColor}

    >
     
      <Container id="cc2"  backgroundColor={classes.root.backgroundColor}>
        <div>

        {isLoading ? null : 
        <div>        
        <div>    
          {activeSheet != null ? 
          <div>

            <div style={{flexDirection: 'row'}}>

            <Button
          color="primary"
          variant="contained"
          onClick={ () => {
            if (activeSheetIdx > 0) {
              setActiveSheet(state.parsedData[activeSheetIdx - 1])
              setActiveSheetIdx(activeSheetIdx - 1)
              if (state.parsedData[activeSheetIdx - 1].trueSet && state.parsedData[activeSheetIdx - 1].trueSet.length > 0) {
                setActiveJobs(state.parsedData[activeSheetIdx - 1].trueSet[0])
                setActiveJobsIdx(0)
              } else {
                setActiveJobs({})
                setActiveJobsIdx(-1)
              }
              resetAll()
            } else {
              alert('This is the first sheet in the file.')
            }
          }}
        >
          Previous Sheet
        </Button>
            <a style={{marginLeft: 5, marginRight: 5}}>{activeSheet.sheetName + ' (Sheet: ' + (activeSheetIdx + 1) + ' / ' + state.parsedData.length + ')'}</a>
        <Button
          color="primary"
          variant="contained"
          onClick={ () => {
            if (activeSheetIdx < state.parsedData.length -1) {
              setActiveSheet(state.parsedData[activeSheetIdx + 1])
              setActiveSheetIdx(activeSheetIdx + 1)
              
              if (state.parsedData[activeSheetIdx + 1].trueSet && state.parsedData[activeSheetIdx + 1].trueSet.length > 0) {
                setActiveJobs(state.parsedData[activeSheetIdx + 1].trueSet[0])
                setActiveJobsIdx(0)
              } else {
                setActiveJobs({})
                setActiveJobsIdx(-1)
              }
              resetAll()
            } else {
              alert('This is the last sheet in the file.')
            }
          }}
        >
          Next Sheet
        </Button>
            </div>
            <div>
              {activeJobs != [] && activeJobs.jobs ? 
              <div>
    
                      <div style={{flexDirection: 'column', display: 'flex'}}>
                      <a>{'Recipient: ' + activeJobs.recipient}</a>
                      <a>{'Account Number: ' + activeJobs.account}</a>
                        </div>
                <div style={{flexDirection: 'row', display: 'flex'}}>{activeJobs.jobs.map((item, idx) => {
                  return  ( 
                   
                  <PaymentCard key={idx + activeJobs.recipient + activeJobs.account + Date.now()} onClick={() => tryPaymentSelection(idx)} itemProp={item} selection={idx in selectedPaymentsDict ? (1 + selectedPaymentsDict[idx]) : null}/>
                  )
                })}</div>
                  <Button
          color="primary"
          variant="contained"
          onClick={ () => {
            if (activeJobIdx > 0) {
              setActiveJobs(activeSheet.trueSet[activeJobIdx - 1])
              setActiveJobsIdx(activeJobIdx - 1)
              resetAll()
            } else {
              alert('This is the first payment in the sheet.')
            }
          }}
        >
          Previous Payment
        </Button>
                
                <a style={{marginLeft: 5, marginRight: 5}}>{'Payment: ' + (activeJobIdx + 1 ) + ' / ' + activeSheet.trueSet.length}</a>
                <Button
          color="primary"
          variant="contained"
          onClick={ () => {
            if (activeJobIdx < activeSheet.trueSet.length -1) {
              setActiveJobs(activeSheet.trueSet[activeJobIdx + 1])
              setActiveJobsIdx(activeJobIdx + 1)
              resetAll()
            } else {
              alert('This is the last payment in the sheet.')
            }
          }}
        >
          Next Payment
        </Button>

<div style={{flexDirection: 'row', display: 'flex'}}>


        </div>

                </div>
              : null}
              
              </div>
           
          </div>
          : null
        }</div>
                <Typography
                  color="textPrimary"
                  variant="h2"
                >
                  Possible Matches
                </Typography>
        <div style={{flexDirection: 'row', display: 'flex', overflowX: 'auto', overflowY: 'hidden'}}>
          {sortItems(filterUnpaidJobMatches(context.adminJobs, activeJobs, context.usersNameDict)).map((itemVal, idx) => {
            return <div key={itemVal.quoteId + Date.now()} onClick={() => tryJobSelection(itemVal)} style={{width: 300}}><JobCardPayment  itemProp={itemVal} selection={itemVal.quoteId in selectedJobsDict ? (selectedJobsDict[itemVal.quoteId] + 1) : null}/></div>
          })}
        </div>
        </div>}</div>
     

        <Formik

        id='f2'
          initialValues={{
           associatedJobs: [],
           total: 0
          }}
          validationSchema={
            Yup.object().shape({
             
              
            })
          }
          onSubmit={(values) => {
            //alert('x')
            submitForPayment(values)
            //verifyCode(values.code)
            //navigate('/app/dashboard', { replace: true });
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            setFieldValue, 
            isSubmitting2,
            touched,
            values
          }) => (
            <form id='fu2' onSubmit={handleSubmit}>
                  <div id='fkx1' >
                    {isLoading ? <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: 50}}><CircularProgress/></div> : null}
                  <Box mb={3}>
                    <div>{didRegisterSetFunction ? null :
                    registerSetFunction((x,y) => setFieldValue(x,y))}</div>
                <Typography
                  color="textPrimary"
                  variant="h2"
                >
                  New Payment
                </Typography>
                

              </Box>
       <div>
       {values.associatedJobs.map((item, idx) => {

         return (<div key={item.reactKey + 'div'}>
           <JobPaymentComponent sheetMode={true} viewMode={true} parentValue={item} key={item.reactKey} id={item.reactKey} itemId={item.reactKey} deleteThis={() => deleteAtIdx(item.reactKey, idx, values.associatedJobs, (x,y) => setFieldValue(x,y))} parentValuesUpdate={(vals) => {updateFullValues(vals, idx, values.associatedJobs, (x,y) => setFieldValue(x,y))}} sendParentNet={(x) => updateTotalNet(x, item.reactKey, (x,y) => setFieldValue(x,y))} context={context}/></div>)
})}

       </div>
       <Typography>{'Total Payment: ' + sumTotalNet(totalNet)}</Typography>

       <Button
                  color="primary"
                  disabled={isLoading}
                  onClick={() => {
                    var v = values.associatedJobs
                    var fullv = fullValues
                    v.push({'assignedJob': 'empty', 'reactKey': Date.now() + ' ' + Math.random()})
                    setFieldValue('associatedJobs', v)
                  }}
                  id="addJobButton"
                  variant="contained"
                >
                  Add Job
                </Button>

              <Box my={2}>
                <Button
                  color="primary"
                  disabled={isLoading}
                  fullWidth
                  size="large"
                  type="submit"
                  id="sendButtonNext"
                  variant="contained"
                >
                  Confirm
                </Button>
              </Box>

              </div>
            </form>
          )}
        </Formik>
      </Container>
    </Box>
  </Page>)}</MyContext.Consumer>)
};

export default NewPaymentFromSheetsView;
