import React, { useState, useEffect } from 'react';
import {
  Box,
  Container,
  makeStyles
} from '@material-ui/core';
import Page from 'src/components/Page';
import Toolbar from './Toolbar';
import Firebase from 'src/Firebase.js'
import CustomObjectsTableWrapper from './CustomObjectsTableWrapper';


const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));


const CustomObjectsListView = (props, ...rest) => {
  const classes = useStyles();


  useEffect(() => {
    // Update the document title using the browser API
    
  });


  return (
    <Page
      className={classes.root}
      title={props.titleKey ? props.titleKey : 'Admin'}
      id={'barleyIn'}
    >
      <Container maxWidth={false}>
        <Toolbar />
        <Box mt={3}>
         <div id='fk11'>
          <CustomObjectsTableWrapper customKey={props.customKey} workflow={props.workflow} objectsKey={props.objectsKey} objectFieldsKey={props.objectFieldsKey}/>
          </div>
          

        </Box>
      </Container>
    </Page>
  );
};

export default CustomObjectsListView;
