import React, {useState} from 'react';
import { Link as RouterLink, useNavigate, useLocation} from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';

import LocationOnIcon from '@material-ui/icons/LocationOn';
import {
  Box,
  Button,
  Checkbox,
  Container,
  FormHelperText,
  Link,
  TextField,
  Typography,
  makeStyles,
  Grid,
  Select,
  MenuItem,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions
} from '@material-ui/core';
import Help from '@material-ui/icons/Help';
import Cancel from '@material-ui/icons/Cancel';
import Page from 'src/components/Page';
import parse from 'autosuggest-highlight/parse';
import throttle from 'lodash/throttle';
import Firebase from 'src/Firebase'
import MuiPhoneNumber from 'material-ui-phone-number'
import MyContext from 'src/MyContext'
import CircularProgress from '@material-ui/core/CircularProgress';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import TableAssist from 'src/utils/TableAssist';
import DateTimeMaster from 'src/utils/DateTimeMaster';
import { values } from 'lodash';
import moment from 'moment'

const filter = createFilterOptions();


const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/


const autocompleteService = { current: null };
const geocoder = {current: null};

const bankOptions = [{'name': 'Awash International Bank', 'value': 0}, 
{'name': 'Commercial Bank of Ethiopia', 'value': 1}, 
{'name': 'Bank of Abyssinia', 'value': 2}, 
{'name': 'Dashen Bank', 'value': 3}, 
{'name': 'Oromia International Bank', 'value': 4}, 
{'name': 'Cooperative Bank of Oromia', 'value': 5},{'name': 'Not Selected', 'value': ''} ]

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  }
}));


const JobPaymentComponent = (props) => {
  const navigate = useNavigate();
  const [dialogWorking, setDialogWorking] = React.useState(false)
  const [value, setValue] = React.useState(null);
  const [didSendBank, setDidSendBank] = React.useState(false);
  const [recipientOptions, setRecipientOptions] = React.useState(props.viewMode && props.parentValue && props.parentValue.recipient ? [props.parentValue.recipient] : []);
  const formikRef = React.useRef(null);


  function setBankDetails(id, setFunc) {
    setDidSendBank(true)
    let f = () => {
      //alert('xf')
    }
    let s = (dat) => {
      //alert(JSON.stringify(dat))
      if (dat != null && dat.info != null) {
        setFunc('bankValue', dat.info.bank)
        setFunc('accountNumber', dat.info.account)
        props.parentValuesUpdate({'bankValue': dat.info.bank, 'accountNumber': dat.info.account})
      }
    }
    var datpack = {}
    datpack['userId'] = id
    Firebase.retrieveUserSecrets(datpack, (x) => s(x), () => f())
    return null
  }

  function momentFormat(date) {
    return moment(date).format('yyyy-MM-DD') + 'T' + moment(date).format('HH:mm')
  }

  function renderOptionName(job) {
    if (!job.quoteId) {
      return ''
    }
    var s = TableAssist.renderIdDisplay(job)
    s += ' (Transporter: ' + job.assignedTransporterName + ') (Driver: ' + job.assignedDriverName + ') (Vehicle: ' + job.vehiclePlate + ')'
    return s
  }

  function precisionRound(num) {
    
    return +num.toFixed(2)
  }

  function renderFullJob(id, jobs) {
    var v = null
    jobs.map((item, idx) => {
      if (item.quoteId == id) {
        v = item
      }
    })
    return v
  }

  function renderUserName(id, users) {


    var v = null 
    users.map((item, idx) => {
      if (item.userId == id) {
        v = item
      }
    })
    if (!v) {
      return
    }

    if (!v.userId || v.userId == '0') {
      return 'Unassigned'
    }

    var rets = ''
    if ('firstName' in v) {
      rets += v.firstName
    } 
    if ('middleName' in v) {
      rets += ' ' + v.middleName
    } 
    if ('lastName' in v) {
      rets += ' ' + v.lastName
    }

    if (v.ownerOperator) {
      rets += ' (Owner Operator)'
    }

    return rets
  }

  function renderTotal(values) {
    if (!values.finalWeight || values.finalWeight == '' || values.finalWeight < 0 || !values.rate || values.rate == '' || values.rate < 0) {
      return 'Not Set'
    } else {
      return precisionRound(values.finalWeight * values.rate)
    }
  }
  
  function renderNet(values) {
    if (!values.finalWeight || values.finalWeight == '' || values.finalWeight < 0 || !values.rate || values.rate == '' || values.rate < 0) {
      return 'Not Set'
    } else {
      var payment = values.finalWeight * values.rate 
      if (values.deductOnePercent) {
        var onePercent = payment * 0.01
        payment -= onePercent
      }
      if (values.extraDeduction) {
        payment -= values.extraDeduction
      }

      props.sendParentNet(precisionRound(payment))
      return precisionRound(payment)
    }
  }



  return (
    
      <div>
      <Formik
      ref={el => formikRef.current = el}
      enableReinitialize
id='f2'
  initialValues={{
   assignedJob: props.viewMode ? props.parentValue.quoteId : '',
   assignedJobFull: props.viewMode ? renderFullJob(props.parentValue.quoteId, props.context.adminJobs) : {},
   deductOnePercent:  props.viewMode ? props.parentValue.deductOnePercent : true,
   extraDeduction:  props.viewMode ? props.parentValue.extraDeduction : 0,
   reactKey: props.itemId,
   recipient: props.viewMode ? props.parentValue.recipient : '',
   plate: props.viewMode ? props.parentValue.plate : '',
   trailer: props.viewMode ? props.parentValue.trailer : '',
   rate: props.viewMode ? props.parentValue.rate : '',
   finalWeight: props.viewMode ? props.parentValue.finalWeight : '',
   time: props.viewMode ? (props.parentValue.deliveryTime ? props.parentValue.deliveryTime : Date.now()) : Firebase.dateNow,
   bankValue: props.sheetMode ? props.parentValue.bankValue : '',
   accountNumber: props.sheetMode ? props.parentValue.accountNumber : ''
  }}
  validationSchema={
    Yup.object().shape({

    })
  }
  onSubmit={(values) => {
    //alert('try')
    console.log('trysub')
    console.log(values)

    //verifyCode(values.code)
    //navigate('/app/dashboard', { replace: true });
  }}
>
  {({
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    isSubmitting2,
    touched,
    values,
    setFieldValue
  }) => (
    <form id='fu2' onSubmit={handleSubmit}>
<div>
<Autocomplete
  id="combo-box-transporter"
  disabled={props.viewMode && !props.sheetMode}
  options={props.context.adminJobs}
  getOptionLabel={(option) =>renderOptionName(option)}
  value={values.assignedJobFull}
  defaultValue={{'quoteId': 0, 'optionName': ''}} 
  onChange={(e, value) => {
    
    if (value != null) {
      setFieldValue('assignedJob', value.quoteId)
 
      setFieldValue('quoteId', value.quoteId)
      setFieldValue('assignedJobFull', value)
      setFieldValue('plate', value.vehiclePlate)
      setFieldValue('trailer', value.vehicleTrailerPlate)
  
      setFieldValue('rate', value.transporterPricePerQuintal)
      
      setFieldValue('finalWeight', value.trueWeight * 10)
    
      setFieldValue('bankValue', '')
      setFieldValue('accountNumber', '')

      var recip = null
      var recipOpts = []
      if (value.assignedDriver && value.assignedDriver != '' && value.assignedDriver != '0') {
        recipOpts.push(value.assignedDriver)
        recip = value.assignedDriver
        
      }
      if (value.assignedTransporter && value.assignedTransporter != '' && value.assignedTransporter != '0') {
        if (value.assignedTransporter != value.assignedDriver) {
          recipOpts.push(value.assignedTransporter)
          recip = value.assignedTransporter
        }


      }

      setRecipientOptions(recipOpts)
      if (recip && recip != '0') {
        setBankDetails(recip, (x,y) => setFieldValue(x,y))
      }
      //alert('goodupate ' + JSON.stringify(values))
  
      setFieldValue('recipient', recip)
      let sendParent = {'quoteId': value.quoteId, 'assignedJob': value.quoteId, 'recipient': recip,
    'plate': value.vehiclePlate, 'trailer': value.vehicleTrailerPlate, 'rate': value.transporterPricePerQuintal,
  'finalWeight': value.trueWeight * 10, 'deductOnePercent': true, 'deliveryTime': Date.now()}
    props.parentValuesUpdate(sendParent)

    } else {
      //alert('nullvalue')
      setRecipientOptions([]);
      setFieldValue('assignedJob', '')
      setFieldValue('assingedJobFull', {})
      setFieldValue('recipient', '')
      setFieldValue('plate', '')
      setFieldValue('trailer', '')
      setFieldValue('rate', '')
      setFieldValue('finalWeight', '')
      setFieldValue('bankValue', '')
      setFieldValue('accountNumber', '')
      //alert('sadupdate')
      let sendParent = {'quoteId': '', 'assignedJob': '', 'recipient': '',
      'plate': '', 'trailer': '', 'rate': '',
    'finalWeight': ''}
      props.parentValuesUpdate(sendParent)
    }

  }}
  renderInput={(params) => <TextField {...params}  fullWidth
  label="Choose Job"
  margin="normal"
  id="assignedJob"
  name="job"
  variant="outlined"
  value={values.assignedJob}
  defaultValue={values.assignedJob} />}
/>

</div>

{values.assignedJob != '' ?
<div>
  
<div style={{flexDirection: 'row', display:'flex'}}>
<TextField   
                  select    
                  disabled={(props.viewMode && !props.sheetMode)|| recipientOptions.length < 1}
                  label="Recipient"
                  margin="normal"
                  name="recipient"
                  onChange={(e) => {
                    setFieldValue('recipient',e.target.value)
                    setFieldValue('bankValue','')
                    setFieldValue('accountNumber', '')
                    props.parentValuesUpdate({'recipient': e.target.value, 'bankValue': '', 'accountNumber': ''})
                    setBankDetails(e.target.value, (x,y) => setFieldValue(x,y))
                  }}
                  value={values.recipient}
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                    }}
                    >
                    {recipientOptions.map((item, idx) => {
                      return (<MenuItem value={item}>{renderUserName(item, props.context.users)}</MenuItem>)
                    })}
                </TextField>
                <TextField select
              disabled={props.viewMode && !props.sheetMode}
                label="Bank"
                margin="normal"
                id="bankValue"
                name="bankValue"
                variant="outlined"
                value={values.bankValue}
                onChange={(e) => {
                  setFieldValue('bankValue',e.target.value)
                  props.parentValuesUpdate({'bankValue': e.target.value})
                }}
                InputLabelProps={{
                  shrink: true,
                  }}
              >
                {bankOptions.map((item, idx) => {
                  return (<MenuItem value={item.value}>{item.name}</MenuItem>)
                })}
              </TextField>
      
              <TextField
         disabled={props.viewMode && !props.sheetMode}
                      id="accountNumber"
                      type="number"
                      label="Account Number"
                      margin="normal"
                      name="bankNumber"
                      onBlur={handleBlur}
                      onChange={(e) => {
                        setFieldValue('accountNumber', e.target.value)
                        props.parentValuesUpdate({'accountNumber': e.target.value})
                      }}
                      InputLabelProps={{
                        shrink: true,
                        }}
                      value={values.accountNumber}
                      variant="outlined"
                    />
</div>
<div style={{flexDirection: 'row', display: 'flex'}}>
<div>{props.viewMode && !didSendBank ?
setBankDetails(props.parentValue.recipient, (x,y) => setFieldValue(x,y))
: null}</div>


                    <TextField
                    disabled={props.viewMode && !props.sheetMode}
                    id='finalWeight'
                    width={'71%'}
                    style={{marginRight: 30}}
                    label={"Final Weight (Qt)"}
                    margin="normal"
                    name="finalWeight"
                    type="number"
                    onBlur={handleBlur}
                    onChange={(e) => {

                      if (false && e.target.value <= 0) {
                        alert('Weight cannot be zero or less.')
                      } else {
  
                        if (e.target.value == '') {
                          setFieldValue('finalWeight', '')
                          props.parentValuesUpdate({'finalWeight': e.target.value})
                        } else {
                          setFieldValue('finalWeight', e.target.value)
                          props.parentValuesUpdate({'finalWeight': e.target.value})
                        }
 
                    }
                    }}
                    value={values.finalWeight}
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                      }}
                  />
                  <TextField      
                  
                  disabled={true}
                  label="Vehicle"
                  margin="normal"
                  name="plate"
                  onChange={(e) => {
                    setFieldValue('plate', e.target.value)
                    props.parentValuesUpdate({'plate': e.target.value})
                  }}
                  value={values.plate}
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                    }}
                />
                <TextField      
                  disabled={true}
                  label="Trailer"
                  margin="normal"
                  name="trailer"
                  onChange={(e) => {
                    setFieldValue('trailer', e.target.value)
                    props.parentValuesUpdate({'trailer': e.target.value})
                  }}
                  value={values.trailer}
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                    }}
                />
                <TextField      
                disabled={true}
                label="Rate"
                type="number"
                margin="normal"
                name="rate"
                onChange={(e) => {
                  setFieldValue('rate', e.target.value)
                  props.parentValuesUpdate({'rate': e.target.value})
                }}
                value={values.rate}
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                  }}
              />
             {props.viewMode ? null :<Cancel onClick={() => props.deleteThis()
            }/> } 

</div>
<div style={{flexDirection: 'row', display: 'flex'}}>
  <div style={{flexDirection: 'column', display: 'flex'}}>
  <Typography>{'Total (Rate * Final Weight): ' + renderTotal(values)}</Typography>
  <Box
        alignItems="center"
        display="flex"
        ml={-1}
      >
        <Checkbox
        disabled={props.viewMode && !props.sheetMode}
        id="deductOnePercent"
          checked={values.deductOnePercent}
          name="deductOnePercent"
          onChange={(e) => {
            setFieldValue('deductOnePercent', !values.deductOnePercent)
            props.parentValuesUpdate({'deductOnePercent': !values.deductOnePercent})
          }}
        />
        <Typography
          color="textPrimary"
          variant="body1"
        >
          Include 1% Deduction
        </Typography>
        <Tooltip  placement="right-end" title="Deduct 1% from total payment.">
                                <Help />
                            </Tooltip>
      </Box>
  </div>
 
      
      <TextField
      disabled={props.viewMode && !props.sheetMode}
                    width={'71%'}
                    style={{marginRight: 30}}
                    label={"Extra Deduction"}
                    margin="normal"
                    name="extraDeduction"
                    type="number"
                    onBlur={handleBlur}
                    onChange={(e) => {
                      if (false && e.target.value <= 0) {
                        alert('Weight cannot be zero or less.')
                      } else {

                        if (e.target.value == '') {
                          setFieldValue('extraDeduction', '')
                          props.parentValuesUpdate({'extraDeduction': e.target.value})
                        } else {
                          setFieldValue('extraDeduction', e.target.value)
                          props.parentValuesUpdate({'extraDeduction': e.target.value})
                        }
 
                    }
                    }}
                    value={values.extraDeduction}
                    variant="outlined"
                  />
                    <Typography>{'Net Payment: ' + renderNet(values)}</Typography>
                    <DateTimeMaster value={momentFormat(values.time)} label={'Delivery Date'} disabled={props.viewMode && !props.sheetMode} onChange={(e) => {
                      setFieldValue('deliveryTime', moment(e).valueOf())
                      props.parentValuesUpdate({'deliveryTime': moment(e).valueOf()})
                    }}/>
</div>

</div>


: null}
          </form>
          )}
        </Formik>
        </div>
  
        )

    };

/*   <div onClick={() => this.adjustSortDescending()} style={{ margin: 0, display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: this.state.sortDescending ? 'orange' : 'white', border: this.state.sortDescending ? '' : '1px solid black', borderRadius: 30, padding: 0, height: 30, padding: 5 }}>
                                <p>Descending</p>
                            </div>*/

/*
 * 
 * 
 *             */

export default JobPaymentComponent;