import React, {useState} from 'react';
import { Link as RouterLink, useNavigate, useLocation} from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import Autocomplete from '@material-ui/lab/Autocomplete';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import {
  Box,
  Button,
  Checkbox,
  Container,
  FormHelperText,
  Link,
  TextField,
  Typography,
  makeStyles,
  Grid,
  Select,
  MenuItem
} from '@material-ui/core';
import Page from 'src/components/Page';
import parse from 'autosuggest-highlight/parse';
import throttle from 'lodash/throttle';
import Firebase from 'src/Firebase'
import DataFetcher from 'src/DataFetcher'

import { TrendingUpRounded } from '@material-ui/icons';
import MuiPhoneNumber from 'material-ui-phone-number'
import AssocJobsWrapper from './AssocJobsWrapper';
import AssociationUsersWrapper from './AssociationUsersWrapper';
import AssociationGenericWrapper from './AssociationGenericWrapper';
var moment = require('moment');

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/


const autocompleteService = { current: null };
const geocoder = {current: null};



const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  }
}));


const EditAssocationView = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  const [value, setValue] = React.useState(null);
  const [valueTwo, setValueTwo] = React.useState(null);
  const [inputValue, setInputValue] = React.useState('');
  const [options, setOptions] = React.useState([]);
  const [optionsTwo, setOptionsTwo] = React.useState([]);
  const [numVal, setNumVal] = React.useState(false);
  const [actions, setActions] = React.useState(null);
  const [locationStart, setLocationStart] = React.useState(null);
  const [locationEnd, setLocationEnd] = React.useState(null);
  const [finalSet, setFinalSet] = React.useState(null);
  const [tableItems, setTableItems] = React.useState(DataFetcher.transportersList)
  const [jobTable, setJobTable] = React.useState(false);
  const [userTable, setUserTable] = React.useState(false);
  const [vehicleTable, setVehicleTable] = React.useState(false);
  const [contractTable, setContractTable] = React.useState(false);


  const loaded = React.useRef(false);
  const {state} = useLocation();




  function editAssociation(values) {
    console.log(values)
    console.log(Firebase.userInfo)
    //Firebase.gt()
    var v = values 
    v['associationId'] = state.associationId
    console.log(v)
    Firebase.adminEditAssociation(v, () => navigate(-1), () => alert('Could not edit association.'))

  }

  function renderUserType(type) {
    if (type == 1) {
      return 'Transporter'
    } else if (type == 2) {
      return 'Shipper'
    } else if (type == 3) {
      return 'Driver'
    }
  }

  


  return (<Page
    className={classes.root}
    height='100%'
    title="Request Quote"
    id="ff2"
    backgroundColor={classes.root.backgroundColor}
  >
 
      <Container id="cc2"  backgroundColor={classes.root.backgroundColor}>
      <Formik
        id='f2'
          initialValues={{
           companyName: state.companyName,
           phone: state.phone,
           userType: state.userType,
            associationName: state.associationName,
            username: state.username, 
            password: state.password, 
            verified: state.verified
          }}
          validationSchema={
            Yup.object().shape({
              companyName: Yup.string().max(255).required('Company name required'),
              phone: Yup.string().max(255).required('Phone number required'),
              username: Yup.string().max(255).required('Username required'),
              password: Yup.string().min(8).max(255).required('Password required. Must be at least 8 characters.')
            })
          }
          onSubmit={(values) => {
            //alert('try')
            console.log('trysub')
            console.log(values)
            editAssociation(values)
            //verifyCode(values.code)
            //navigate('/app/dashboard', { replace: true });
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting2,
            touched,
            values,
            setFieldValue
          }) => (
            <form id='fu2' onSubmit={handleSubmit}>
                  <div id='fkx1' style={{}}>
              <Box mb={3}>
                <Typography
                  color="textPrimary"
                  variant="h2"
                >
                  Edit Association
                </Typography>
              </Box>
              <div style={{flexDirection: 'row'}}>
              <Button
                  color="primary"
                  style={{marginRight: 5}}
                  size="large"
                  id="sendButtonNext"
                  variant="contained"
                  onClick={() =>{
                    setJobTable(!jobTable)
                    setUserTable(false)
                    setVehicleTable(false)
                    setContractTable(false)
                  }}
                >
                  Edit Jobs
                </Button>
                <Button
                  color="primary"
                  style={{marginRight: 5}}
                  size="large"
                  id="sendButtonNext"
                  variant="contained"
                  onClick={() =>{
                    setJobTable(false)
                    setUserTable(!userTable)
                    setVehicleTable(false)
                    setContractTable(false)
                  }}
                >
                  Edit Users
                </Button>
                <Button
                  color="primary"
                  style={{marginRight: 5}}
                  size="large"
                  id="sendButtonNext"
                  variant="contained"
                  onClick={() =>{
                    setJobTable(false)
                    setUserTable(false)
                    setVehicleTable(!vehicleTable)
                    setContractTable(false)
                  }}
                >
                  Edit Vehicles
                </Button>
                <Button
                  color="primary"
                  style={{marginRight: 5}}
                  size="large"
                  id="sendButtonNext"
                  variant="contained"
                  onClick={() =>{
                    setJobTable(false)
                    setUserTable(false)
                    setVehicleTable(false)
                    setContractTable(!contractTable)
                  }}
                >
                  Edit Contracts
                </Button>

              </div>
              {jobTable ? <div>
                <Typography
                  color="textPrimary"
                  variant="h3"
                >
                  Association Jobs
                </Typography>
                <AssocJobsWrapper targetUid={state.associationId}/>
              </div>: null}
              {userTable ? <div>
                <Typography
                  color="textPrimary"
                  variant="h3"
                >
                  Association Users
                </Typography>
                <AssociationUsersWrapper targetUid={state.associationId}/>
              </div>: null}
              {vehicleTable ? <div>
                <Typography
                  color="textPrimary"
                  variant="h3"
                >
                  Association Vehicles
                </Typography>
                <AssociationGenericWrapper targetUid={state.associationId} tableType={0}/>
              </div>: null}
              {contractTable ? <div>
                <Typography
                  color="textPrimary"
                  variant="h3"
                >
                  Association Contracts
                </Typography>
                <AssociationGenericWrapper targetUid={state.associationId} tableType={1}/>
              </div>: null}
              
              

              <TextField
                error={Boolean(touched.companyName && errors.companyName)}
                fullWidth
                id="companyName"
                helperText={touched.companyName && errors.companyName}
                label="Company"
                margin="normal"
                name="companyName"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.companyName}
                variant="outlined"
              />

<div id='wi3jife'>
                <MuiPhoneNumber defaultCountry={'et'}
                regions={['america','africa']}
                onlyCountries={['us', 'et']}
                autoFormat={false}
                disableAreaCodes={true}
                  error={Boolean(touched.email && errors.email)}
                  fullWidth
                  helperText={touched.email && errors.email}
                  label="Phone"
                  id="phone"
                  margin="normal"
                  name="phone"
                  onBlur={handleBlur}
                  onChange={(e) => setFieldValue('phone', e)}
                  value={values.phone}
                  variant="outlined"
                />
</div>
<TextField
                error={Boolean(touched.username && errors.username)}
                fullWidth
                id="username"
                helperText={touched.username && errors.username}
                label="Username"
                margin="normal"
                disabled={TrendingUpRounded}
                name="username"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.username}
                variant="outlined"
              />
              <TextField
                error={Boolean(touched.password && errors.password)}
                fullWidth
                id="password"
                helperText={touched.password && errors.password}
                label="Password"
                margin="normal"
                name="password"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.password}
                variant="outlined"
              />



        <Box
                  alignItems="center"
                  display="flex"
                  ml={-1}
                >
                  <Checkbox
                    checked={values.verified}
                    name="verified"
                    onChange={handleChange}
                  />
                  <Typography
                    color="textSecondary"
                    variant="body1"
                  >
                    Verified User
                  </Typography>
                </Box>
</div>
              <Box my={2}>
                <Button
                  color="primary"
                  disabled={isSubmitting2}
                  fullWidth
                  size="large"
                  type="submit"
                  id="sendButtonNext"
                  variant="contained"
                >
                  Confirm
                </Button>
              </Box>

              
            </form>
          )}
        </Formik>
      </Container>
    
  </Page>)
};
//<UserJobsWrapper targetUid={state.userId}/>
export default EditAssocationView;
