import React, {useState} from 'react';
import { Link as RouterLink, useNavigate, useLocation} from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';

import LocationOnIcon from '@material-ui/icons/LocationOn';
import {
  Box,
  Button,
  Checkbox,
  Container,
  FormHelperText,
  Link,
  TextField,
  Typography,
  makeStyles,
  Grid,
  Select,
  MenuItem
} from '@material-ui/core';
import Page from 'src/components/Page';
import parse from 'autosuggest-highlight/parse';
import throttle from 'lodash/throttle';
import Firebase from 'src/Firebase'
import MuiPhoneNumber from 'material-ui-phone-number'
import MyContext from 'src/MyContext'
import CircularProgress from '@material-ui/core/CircularProgress';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';

const filter = createFilterOptions();


const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/


const autocompleteService = { current: null };
const geocoder = {current: null};
const bankOptions = [{'name': 'Awash International Bank', 'value': 0}, 
{'name': 'Commercial Bank of Ethiopia', 'value': 1}, 
{'name': 'Bank of Abyssinia', 'value': 2}, 
{'name': 'Dashen Bank', 'value': 3}, 
{'name': 'Oromia International Bank', 'value': 4}, 
{'name': 'Cooperative Bank of Oromia', 'value': 5}, ]


const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  }
}));


const NewUserView = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const {state} = useLocation();
  
  const [valueTwo, setValueTwo] = React.useState(null);
  const [inputValue, setInputValue] = React.useState('');
  const [options, setOptions] = React.useState([]);
  const [optionsTwo, setOptionsTwo] = React.useState([]);
  const [numVal, setNumVal] = React.useState(false);
  const [actions, setActions] = React.useState(null);
  const [locationStart, setLocationStart] = React.useState(null);
  const [locationEnd, setLocationEnd] = React.useState(null);
  const [finalSet, setFinalSet] = React.useState(null);
  const [bankValue, setBankValue] = React.useState(null);
  const [changedBankInfo, setChangedBankInfo] = React.useState(false);
  const [accountNumber, setAccountNumber] = React.useState(null);
  const [acv2, setacv2] = React.useState(null);
  const [isAuthenticating, setIsAuthenticating] = React.useState(false);
  const [value, setValue] = React.useState(state.fromFleet && state.assoc ? state.assoc : null);
  const loaded = React.useRef(false);




  function renderUserName(v) {


    var rets = ''
    if ('firstName' in v && v.firstName) {
      rets += v.firstName
    } 
    if ('middleName' in v && v.middleName) {
      rets += ' ' + v.middleName
    } 
    if ('lastName' in v && v.lastName) {
      rets += ' ' + v.lastName
    }
    if (rets == '') {
      return null
    }
    return rets
  }

  function addUser(values) {
    console.log(values)
    console.log(Firebase.userInfo)
    //alert(JSON.stringify(values))
    //return
    setIsAuthenticating(true)
    //Firebase.gt()
    var v = values 
    if (v['dob']) {
      v['dob'] = Date.parse(v['dob'])
    }

    if (v.userType == 'Transporter') {
      v['userType'] = 1
    } else if (v.userType == 'Shipper') {
      v['userType'] = 2
    } else if (v.userType == 'Driver') {
      v['userType'] = 3
      if (v['assignedTransporter'] != null && v['assignedTransporter'] != '0') {
        v['targetTransporter'] = v['assignedTransporter']
        
      } else {
        v['targetTransporter'] = null
      }
    } else if (v.userType == 'Other') {
      v['userType'] = 4
    }
    v['registrationPhone'] = v.phone.toString()
    v['phone'] = v.phone.toString()
    v['fullName'] = renderUserName(v)
    console.log(v)
    if (changedBankInfo) {
      var bankUpdate = {}
      bankUpdate['bank'] = bankValue 
      bankUpdate['account'] = accountNumber
      v['bankUpdate'] = bankUpdate
    }


    let s = (x) => {
      setIsAuthenticating(false)
      if (x.result == -1) {
        alert('A user with this phone number already exists.')
      } else {
        let newData = x.update 
        if (newData.userType == 1) {
          navigate('/app/usersummary', {state: {customer: newData, replace: true}})
        } else {
          navigate(-1)
        }

      }
    }

    let f = () => {
      setIsAuthenticating(false)
      alert('Could not create user.')
    }

    Firebase.adminAddUser(v, (x) => s(x), () => f())

  }

  
  function getTransporterOptions(adminUsers) {


    let ret = adminUsers.filter((item, idx) => {
      return item.userType == 1 && item.companyName
    })
    return [{'userId': '0', 'companyName': 'Unassigned'}].concat(ret)

  }

  function getTransporterOption(userId, adminUsers) {
    var ret = {'userId': '0', 'companyName': 'Unassigned'}
    adminUsers.map((item, idx) => {
      if (item.userId == userId) {
        ret = {'userId': userId, 'companyName': item.companyName}
      }
    })
    return ret 
  }

  function getAssociationOption(assocId, assocs) {
    var ret = {'associationId': '0', 'companyName': 'None'}
    assocs.map((item, idx) => {
      if (item.associationId == assocId) {
        ret = {'associationId': assocId, 'companyName': item.companyName}
      }
    })
    return ret 
  }

  function getAssociationOptionName(assocId, assocs) {
    var ret = 'None'
    assocs.map((item, idx) => {
      if (item.associationId == assocId) {
        ret = item.companyName
      }
    })
    return ret 
  }

  function getAssociationOptions(adminAssoc) {
    
    return [{'associationId': '0', 'companyName': 'None'}].concat(adminAssoc)
  }
  

  function getCompanyName(id, users) {
    var companyName = 'Unassigned'
    users.map((item, idx) => {
      if (item.userId == id) {
        companyName = item.companyName 
      }
    })
    return companyName 
  }

  if (isAuthenticating) {
    return (<Page
      className={classes.root}
      title="New User"
      backgroundColor={classes.root.backgroundColor}
    ><div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}><CircularProgress/></div></Page>)
  }

  return (
    <MyContext.Consumer>{context => (
  <Page
    className={classes.root}
    height='100%'
    title="Request Quote"
    id="ff2"
    backgroundColor={classes.root.backgroundColor}
  >
    <Box
      display="flex"
      flexDirection="column"
      height="100%"
      id="bb2"
      justifyContent="center"
      backgroundColor={classes.root.backgroundColor}

    >
      <Container id="cc2" maxWidth="sm" backgroundColor={classes.root.backgroundColor}>
        <Formik
        id='f2'
          initialValues={{
           firstName: '',
           phone: '',
           testv: state.fromFleet,
           userType: state.fromFleet ? 'Driver' : '',
            companyName: state.fromFleet && state.customer && state.customer.userId ? getCompanyName(state.customer.userId, context.users) : 'Unassigned',
            verified: false,
            gender: 'Male',
            assignedTransporter: state.fromFleet && state.customer && state.customer.userId ? state.customer.userId : null,
            associationId: state.fromFleet && state.customer && state.customer.associationId ? state.customer.associationId: null,
            associationName: state.fromFleet && state.customer && state.customer.associationId ? getAssociationOptionName(state.customer.associationId, context.associations) : null,
          }}
          validationSchema={
            Yup.object().shape({
              companyName: Yup.string().max(255).required('Company name required'),
              firstName: Yup.string().max(255).required('Name required'),
              phone: Yup.string().max(255).required('Phone number required'),
              userType: Yup.string().max(255).required('User type required')
            })
          }
          onSubmit={(values) => {
            //alert('try')
            console.log('trysub')
            console.log(values)
            addUser(values)
            //verifyCode(values.code)
            //navigate('/app/dashboard', { replace: true });
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting2,
            touched,
            values,
            setFieldValue
          }) => (
            <form id='fu2' onSubmit={handleSubmit}>
                  <div id='fkx1'>
              <Box mb={3}>
                <Typography
                  color="textPrimary"
                  variant="h2"
                >
                  Create New User
                </Typography>
                

              </Box>

              <MuiPhoneNumber defaultCountry={'et'}
                regions={['america','africa']}
                onlyCountries={['us', 'et']}
                autoFormat={false}
                disableAreaCodes={true}
                  error={Boolean(touched.email && errors.email)}
                  fullWidth
                  helperText={touched.email && errors.email}
                  label="Phone"
                  id="phone"
                  margin="normal"
                  name="phone"
                  onBlur={handleBlur}
                  onChange={(e) => setFieldValue('phone', e)}
                  value={values.phone}
                  variant="outlined"
                />

                {values.phone in state.phoneDict ? <Link
              onClick={ () => navigate('/app/edituser', {state: Object.assign({}, state.phoneDict[values.phone], {'phoneDict': state.phoneDict}), replace: true})}
              variant="h6"
            >{'This user already exists. Click here to edit user.'}</Link> : null}


              <Select
          fullWidth
          label="User Type"
          margin="normal"
          id="userType"
          name="userType"
          variant="outlined"
          value={values.userType}
          onChange={handleChange}
        >
          {['Transporter','Driver', 'Shipper', 'Other'].map((item, idx) => {
            return (<MenuItem value={item}>{item}</MenuItem>)
          })}
        </Select>

        <TextField
                error={Boolean(touched.firstName && errors.firstName)}
                fullWidth
                id="firstName"
                helperText={touched.firstName && errors.firstName}
                label="First Name"
                margin="normal"
                name="firstName"
                onBlur={handleBlur}
                onChange={(e) => {
                  setFieldValue('firstName', e.target.value)
                  if (values.userType == 'Transporter') {
                    var rets = e.target.value 
                    if (values.lastName != '') {
                      rets += ' '
                      rets += values.lastName
                    }
                    rets += ' Transport'
                    setFieldValue('companyName', rets)
                  }

                }}
                value={values.firstName}
                variant="outlined"
              />
              <TextField
                error={Boolean(touched.middleName && errors.middleName)}
                fullWidth
                id="middleName"
                helperText={touched.middleName && errors.middleName}
                label="Middle Name"
                margin="normal"
                name="middleName"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.middleName}
                variant="outlined"
              />
              <TextField
                error={Boolean(touched.lastName && errors.lastName)}
                fullWidth
                id="lastName"
                helperText={touched.lastName && errors.lastName}
                label="Last Name"
                margin="normal"
                name="lastName"
                onBlur={handleBlur}
                onChange={(e) => {
                  //alert(values.userType)
                  setFieldValue('lastName', e.target.value)
                  if (values.userType == 'Transporter') {
                    var rets = '' 
                    if (values.firstName != '') {
                      rets += values.firstName
                      rets += ' '
                    }
                    rets += e.target.value
                    rets += ' Transport'
                    setFieldValue('companyName', rets)
                  }

                }}
                value={values.lastName}
                variant="outlined"
              />
              <TextField select 
          fullWidth
          label="Gender"
          margin="normal"
          id="gender"
          name="gender"
          variant="outlined"
          value={values.gender}
          defaultValue={values.gender}
          onChange={handleChange}
        >
          {['Male', 'Female'].map((item, idx) => {
            return (<MenuItem value={item}>{item}</MenuItem>)
          })}
        </TextField>

<div id='wi3jife'>
{values.userType == 'Shipper' || values.userType == 'Transporter' ? <Autocomplete
      value={value}
      onChange={(event, newValue) => {
        if (typeof newValue === 'string') {
          setValue({
            companyName: newValue,
          });
          setFieldValue('associationId', newValue.associationId)
          setFieldValue('associationName', newValue.companyName)
        } else if (newValue && newValue.inputValue) {
          // Create a new value from the user input
          setValue({
            companyName: newValue.inputValue,
          });
          setFieldValue('associationId', -1)
          setFieldValue('associationName', newValue.inputValue)
        } else {
          setValue(newValue);
          if (newValue == null) {
            setFieldValue('associationId', '0')
            setFieldValue('associationName', 'None')
          } else {
            setFieldValue('associationId', newValue.associationId)
            setFieldValue('associationName', newValue.companyName)
          }
        }
        //console.log(event.target.value)
        //console.log(newValue)
      }}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);

        // Suggest the creation of a new value
        if (params.inputValue !== '') {
          filtered.push({
            inputValue: params.inputValue,
            companyName: `Add "${params.inputValue}"`,
          });
        }

        return filtered;
      }}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      id="free-solo-with-text-demo"
      options={getAssociationOptions(context.associations)}
      getOptionLabel={(option) => {
        // Value selected with enter, right from the input
        if (typeof option === 'string') {
          return option;
        }
        // Add "xxx" option created dynamically
        if (option.inputValue) {
          return option.inputValue;
        }
        // Regular option
        return option.companyName;
      }}
      renderOption={(option) => option.companyName}
      freeSolo
      renderInput={(params) => (
        <TextField {...params} error={Boolean(touched.associationId && errors.associationId)}
        fullWidth
        id="associationId"
        helperText={touched.associationId && errors.associationId}
        label="Association"
        margin="normal"
        name="associationId"
        onBlur={handleBlur}
        onChange={(e) => {
          console.log(e.target)
        }}
        value={values.associationId}
        variant="outlined"/>
      )}
    /> : null}

</div>


        {values.userType == 'Shipper' ? <div>
        <TextField
                error={Boolean(touched.companyName && errors.companyName)}
                fullWidth
                id="companyName"
                helperText={touched.companyName && errors.companyName}
                label="Company"
                margin="normal"
                name="companyName"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.companyName}
                variant="outlined"
              />
          <TextField
                error={Boolean(touched.tin && errors.tin)}
                fullWidth
                id="tin"
                helperText={touched.tin && errors.tin}
                label="TIN"
                margin="normal"
                name="tin"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.tin}
                variant="outlined"
              />
          <TextField
                error={Boolean(touched.industry && errors.industry)}
                fullWidth
                id="industry"
                helperText={touched.industry && errors.industry}
                label="Industry"
                margin="normal"
                name="industry"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.industry}
                variant="outlined"
              />
                            <TextField
                error={Boolean(touched.addressLine1 && errors.addressLine1)}
                fullWidth
                id="addressLine1"
                helperText={touched.addressLine1 && errors.addressLine1}
                label="Address Line 1"
                margin="normal"
                name="addressLine1"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.addressLine1}
                variant="outlined"
              />
              <TextField
                error={Boolean(touched.addressLine2 && errors.addressLine2)}
                fullWidth
                id="addressLine2"
                helperText={touched.addressLine2 && errors.addressLine2}
                label="Address Line 2"
                margin="normal"
                name="addressLine2"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.addressLine2}
                variant="outlined"
              />


          </div> : null}
          {values.userType == 'Transporter' ? <div>
          <TextField
                error={Boolean(touched.companyName && errors.companyName)}
                fullWidth
                id="companyName"
                helperText={touched.companyName && errors.companyName}
                label="Company"
                margin="normal"
                name="companyName"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.companyName}
                variant="outlined"
              />
          <TextField
                error={Boolean(touched.tin && errors.tin)}
                fullWidth
                id="tin"
                helperText={touched.tin && errors.tin}
                label="TIN"
                margin="normal"
                name="tin"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.tin}
                variant="outlined"
              />
                            <TextField
                error={Boolean(touched.addressLine1 && errors.addressLine1)}
                fullWidth
                id="addressLine1"
                helperText={touched.addressLine1 && errors.addressLine1}
                label="Address Line 1"
                margin="normal"
                name="addressLine1"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.addressLine1}
                variant="outlined"
              />
              <TextField
                error={Boolean(touched.addressLine2 && errors.addressLine2)}
                fullWidth
                id="addressLine2"
                helperText={touched.addressLine2 && errors.addressLine2}
                label="Address Line 2"
                margin="normal"
                name="addressLine2"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.addressLine2}
                variant="outlined"
              />
   <Box
                  alignItems="center"
                  display="flex"
                  ml={-1}
                >
                  <Checkbox
                    checked={values.ownerOperator}
                    name="ownerOperator"
                    onChange={handleChange}
                  />
                  <Typography
                    color="textSecondary"
                    variant="body1"
                  >
                    User is an Owner Operator
                  </Typography>
                </Box>

          </div> : null}
          {values.userType == 'Driver' ? <div>

          <Autocomplete
  id="combo-box-transporter"
  options={getTransporterOptions(context.users)}
  getOptionLabel={(option) => option.companyName}
  value={{'userId': values.assignedTransporter, 'companyName': values.companyName}}
  defaultValue={{'userId': state.fromFleet && state.customer && state.customer.userId ? state.customer.userId : 0, 
'companyName': state.fromFleet && state.customer && state.customer.userId ? state.customer.companyName : 'Unassigned'}} 
  onChange={(e, value) => {
    if (value != null) {
      setFieldValue('assignedTransporter', value.userId)
      setFieldValue('companyName', value.companyName)
    } else {
      setFieldValue('assignedTransporter', '0')
      setFieldValue('companyName', 'Unassigned')
    }

  }}
  renderInput={(params) => <TextField {...params}  fullWidth
  label="Choose Transporter"
  margin="normal"
  id="assignedTransporter"
  name="transporter"
  variant="outlined"
  value={values.assignedTransporter}
  defaultValue={values.assignedTransporter} />}
/>

          <TextField
        label="Date of Birth"
        type="date"
        variant="outlined"
        margin="normal"
        name="dob"
        onBlur={handleBlur}
        onChange={handleChange}
        value={values.dob}
        error={Boolean(touched.dob && errors.dob)}
        InputLabelProps={{
          shrink: true,
        }}
      />
                    <TextField
                error={Boolean(touched.yoe && errors.yoe)}
                fullWidth
                id="yoe"
                helperText={touched.yoe && errors.yoe}
                label="Years of Experience"
                margin="normal"
                name="yoe"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.yoe}
                variant="outlined"
                type="number"
              />

        <TextField
                error={Boolean(touched.license && errors.license)}
                fullWidth
                id="license"
                helperText={touched.license && errors.license}
                label="License Number"
                margin="normal"
                name="license"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.license}
                variant="outlined"
              />
          </div> : null}

          <TextField select
          fullWidth
          label="Bank"
          margin="normal"
          id="bank"
          name="bank"
          variant="outlined"
          value={bankValue}
          onChange={(e) => {
            setBankValue(e.target.value)
            setChangedBankInfo(true)
          }}
          InputLabelProps={{
            shrink: true,
            }}
        >
          {bankOptions.map((item, idx) => {
            return (<MenuItem value={item.value}>{item.name}</MenuItem>)
          })}
        </TextField>

        <TextField
                error={Boolean(touched.bankNumber && errors.bankNumber)}
                fullWidth
                id="bankNumber"
                type="number"
                helperText={touched.bankNumber && errors.bankNumber}
                label="Account Number"
                margin="normal"
                name="bankNumber"
                onBlur={handleBlur}
                onChange={(e) => {
                  setAccountNumber(e.target.value)
                  setChangedBankInfo(true)
                }}
                InputLabelProps={{
                  shrink: true,
                  }}
                value={accountNumber}
                variant="outlined"
              />

        <Box
                  alignItems="center"
                  display="flex"
                  ml={-1}
                >
                  <Checkbox
                    checked={values.verified}
                    name="verified"
                    onChange={handleChange}
                  />
                  <Typography
                    color="textSecondary"
                    variant="body1"
                  >
                    Verified User
                  </Typography>
                </Box>
</div>
              <Box my={2}>
                <Button
                  color="primary"
                  disabled={isSubmitting2}
                  fullWidth
                  size="large"
                  type="submit"
                  id="sendButtonNext"
                  variant="contained"
                >
                  Confirm
                </Button>
              </Box>

              
            </form>
          )}
        </Formik>
      </Container>
    </Box>
  </Page> )}</MyContext.Consumer>)
};

export default NewUserView;
