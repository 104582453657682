import React, { useState, useEffect } from 'react';
import {
  Box,
  Container,
  makeStyles,
  Switch,
  FormControlLabel,
  Grid
} from '@material-ui/core';
import Page from 'src/components/Page';
//import Results from './Results';
import Toolbar from './Toolbar';
//import data from './data';
import Firebase from 'src/Firebase.js'

import ShipperOnlyQuoteTable from './ShipperOnlyQuoteTable';
import JobCard from '../views/reports/DashboardView/JobCard';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));


const BaseWrapper = (data) => {
  const classes = useStyles();
  const [customers] = useState(data);
  const [toggleSwitch, setToggleSwitch] = useState(data.userInfo && data.userInfo.toggleSwitch ? data.userInfo.toggleSwitch : false);

  useEffect(() => {
    // Update the document title using the browser API
    Firebase.trackMixpanel('shipperViewTable', {'table': getTitle(data.keyProp)})
    //alert(JSON.stringify(data))
  });

  function getTitle(keyProp) {
    if (keyProp == 1) {
      return 'Quote Requests'
    } else if (keyProp == 2) {
      return 'Incoming Quotes'
    } else if (keyProp == 3) {
      return 'Scheduled Shipments'
    } else if (keyProp == 4) {
      return 'In Progress / Delivered'
    } 
    return 'Garri'
  }

  return (
    <Page
      className={classes.root}
      title={getTitle(data.keyProp)}
    >
  

      <Container maxWidth={false}>
        <Toolbar />
        <FormControlLabel
                control={<Switch
                checked={toggleSwitch}
                onChange={(e) => {

                  var dat = {}
                  if (!data.userInfo || !data.userInfo.userId) {
                    dat['userId'] = Firebase.uid
                  } else {
                    dat['userId'] = data.userInfo.userId 
                  }
                  dat['updateBody'] = {}
                  dat['updateBody']['toggleSwitch'] = !toggleSwitch
                  if (dat['userId'] && dat['userId'] != null) {
                    Firebase.adminEditUser(dat, () => console.log(''), () => console.log(''))
                  }
                  
                  setToggleSwitch(!toggleSwitch)
                }}
                color="primary"
                name="checkedB"
                inputProps={{ 'aria-label': 'primary checkbox' }}
              />}
              label="Table View"
        />
  {toggleSwitch || data.customers.length < 1 ?         <Box mt={3}>
         <ShipperOnlyQuoteTable key={'tableKey' + data.keyProp} keyProp={data.keyProp} customers={data.customers}/>
        </Box> : 
    
    <Grid
    container
    spacing={3}
  >{data.customers.map((item, idx) => {
          return <Grid
           item
           lg={3}
           sm={6}
           xl={3}
           xs={12}
         >
           <JobCard itemProp={item}/>
           </Grid>
         }) }</Grid>
   

          }

      </Container>
    </Page>
  );
};

export default BaseWrapper;
