import React, {useState, useEffect, useRef} from 'react';
import { Link as RouterLink, useNavigate, useLocation} from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';

import LocationOnIcon from '@material-ui/icons/LocationOn';
import {
  Box,
  Button,
  Checkbox,
  Container,
  FormHelperText,
  Link,
  TextField,
  Typography,
  makeStyles,
  Grid,
  Select,
  MenuItem,
  CircularProgress
} from '@material-ui/core';
import Page from 'src/components/Page';
import parse from 'autosuggest-highlight/parse';
import throttle from 'lodash/throttle';
import Firebase from 'src/Firebase'
import DataFetcher from 'src/DataFetcher';
import MyContext from 'src/MyContext';
import TableAssist from 'src/utils/TableAssist';
import JobPaymentComponent from './JobPaymentComponent';
import { TrainOutlined } from '@material-ui/icons';


const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
const filter = createFilterOptions();

const autocompleteService = { current: null };
const geocoder = {current: null};



const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  }
}));


const ViewPaymentView = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const jobsRef = useRef([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [fullValues, setFullValues] = React.useState([])
  const [totalNet, setTotalNet] = React.useState({})
  const [arraySet, setArraySet] = React.useState([])
  const {state} = useLocation();


  function updateTotalNet(v, k,setFunc) {
    //alert(v + ' ' + k)
    
    if ((k in totalNet) && totalNet[k] == v) {
      return
    }
    var va = totalNet
    va[k] = v

    setTotalNet(va)
    setArraySet([v])
    //setFunc('total', JSON.stringify(va))
    //alert(JSON.stringify(totalNet))
  }

  
function precisionRound(num) {
    
  return +num.toFixed(2)
}


  function sumTotalNet(total) {
    //alert('doing a sum' + JSON.stringify(total))
    var sum = 0
    Object.keys(total).map((item, idx) => {
      sum += total[item]
    })
    return sum 
  }

  function updateFullValues(val, idx, vassoc, setFunc ) {
    //alert(JSON.stringify(val) + ' aaaand ' + idx)
    var vv = vassoc.map((item, idxx) => {
      if (idx == idxx) {
        var nv = item 
        Object.keys(val).map((innerKey, innerIdx) => {
          nv[innerKey] = val[innerKey]
        })
        return nv
      }
      return item 
    }) 

    setFunc('associatedJobs', vv)
  }

  function deleteAtIdx(reactKey, idx, vassoc, setFunc) {
    var v = fullValues.filter((item, idxxx) => {
      return idx != idxxx
    })
    var vv = vassoc.filter((item, idxxx) => {
      return idx != idxxx
    })
    if (reactKey in totalNet) {
      var vnet = totalNet
      delete vnet[reactKey]
      setTotalNet(vnet)
    }
    setFunc('associatedJobs', vv)
  }
  
 

  return ( <MyContext.Consumer>{context => (<Page
    className={classes.root}
    height='100%'
    title="New Payment"
    id="ff2"
    backgroundColor={classes.root.backgroundColor}
  >
    <Box
      display="flex"
      flexDirection="column"
      height="90vh"
      id="bb2"
      justifyContent="center"
      backgroundColor={classes.root.backgroundColor}

    >
     
      <Container id="cc2"  backgroundColor={classes.root.backgroundColor}>
        <Formik

        id='f2'
          initialValues={{
           associatedJobs: state.customer.jobs,
           total: state.totalPay
          }}
          validationSchema={
            Yup.object().shape({
             
              
            })
          }
          onSubmit={(values) => {
            //alert('x')

            //verifyCode(values.code)
            //navigate('/app/dashboard', { replace: true });
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            setFieldValue, 
            isSubmitting2,
            touched,
            values
          }) => (
            <form id='fu2' onSubmit={handleSubmit}>
                  <div id='fkx1' style={{marginTop: 230}}>
                    {isLoading ? <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: 50}}><CircularProgress/></div> : null}
                  <Box mb={3}>
                <Typography
                  color="textPrimary"
                  variant="h2"
                >
                  {'Payment P-' + TableAssist.padDigits(state.customer.shortIdShipper)}
                </Typography>
                

              </Box>
       <div style={{marginBottom: 20}}>
       {values.associatedJobs.map((item, idx) => {

         return (<div key={item.reactKey + 'div'}>
           <JobPaymentComponent viewMode={true} parentValue={item} key={item.reactKey} id={item.reactKey} itemId={item.reactKey} deleteThis={() => deleteAtIdx(item.reactKey, idx, values.associatedJobs, (x,y) => setFieldValue(x,y))} parentValuesUpdate={(vals) => {updateFullValues(vals, idx, values.associatedJobs, (x,y) => setFieldValue(x,y))}} sendParentNet={(x) => updateTotalNet(x, item.reactKey, (x,y) => setFieldValue(x,y))} context={context}/></div>)
})}

       </div>
       <Typography>{'Total Payment: ' + precisionRound(state.customer.totalPay)}</Typography>



              </div>
            </form>
          )}
        </Formik>
      </Container>
    </Box>
  </Page>)}</MyContext.Consumer>)
};

export default ViewPaymentView;
